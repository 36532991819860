<template>
  <div style="max-width: 850px;">
    <SharingList
      id="portal-users-card"
      :installation-id="installationId"
      :customer-name="customerName"
      :auto-publish-jobs="autoPublishReports"
      :can-edit="canEdit"
      :feature-portal="featurePortal"
    />

    <div id="report-recipients-card" class="fx-card fx-card-content" style="max-width: 850px;">
      <h2 class="fx">
        E-Mail-Empfänger
      </h2>
      <p>
        Erhalten Berichte per E-Mail, haben aber keinen Zugriff auf das Auftraggeberportal.
        <help-article article-id="learn_more_send_reports">
          mehr erfahren
        </help-article>
      </p>

      <EmbeddedContactList
        :init-contacts="reportRecipients"
        :add-installation-id="installationId"
        :remove-installation-id="installationId"
        :show-top-add-button="false"
        :show-bottom-add-button="true"
        :show-empty-state="false"
        list-classes="grid-2-col"
        relation-type="report_recipient"
      />
    </div>
  </div>
</template>

<script>
import FoxtagPaths from 'mixins/foxtag_paths';

import EmbeddedContactList from 'apps/contacts/embedded_list.vue';
import SharingList from '../sharing/index.vue';

export default {
  name: 'SharingTab',
  components: {
    SharingList,
    EmbeddedContactList,
  },
  mixins: [FoxtagPaths],
  props: {
    reportRecipients: {
      type: Array,
      required: true,
    },
    installationId: {
      type: String,
      required: true,
    },
    autoPublishReports: {
      type: Boolean,
      default: false,
    },
    customerName: {
      type: String,
      default: '',
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    featurePortal: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
