<template>
  <div class="fx-modal-content-medium">
    <h2 class="fx">
      Hinweise bearbeiten
    </h2>
    <div style="min-height: 120px;">
      <ServerErrors v-model="serverErrors" title="Änderung konnte nicht gespeichert werden" margin-bottom="0" />
      <label for="job_number">Hinweise</label>

      <textarea
        id="job_instructions_textarea"
        ref="textarea"
        v-model="localJob.instructions"
        style="resize: none;  overflow: hidden; margin-bottom:0; min-height: 100px;"
        placeholder=""
        @input="resize()"
      />
      <br>
      <label for="job_number">Allgemeine Hinweise an der Anlage
        <help-article article-id="job_instructions" :no-style="true"><i class="fas fa-question-circle" /></help-article>
      </label>
      <div>
        {{ installation.note }}
        <span v-if="!installation.note" class="secondary">Keine Hinweise an der Anlage hinterlegt</span>
      </div>
    </div>
    <hr class="fx">

    <div style="text-align:right;">
      <div class="fx-btn-group">
        <button
          class="fx-btn-primary float-right"
          style="margin-left: 0.5rem;"
          @click.prevent="updateJob()"
        >
          Speichern
        </button>

        <button class="fx-btn-secondary float-right" @click.prevent="cancel()">
          Abbrechen
        </button>
      </div>
      <div class="clearfix" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';
import { ref, onMounted } from 'vue';

import ServerErrors from 'components/partials/server_errors.vue';

export default {
  name: 'EditJobInstructionModal',
  components: {
    ServerErrors,
  },
  props: {
    job: {
      type: Object,
      required: true,
    },
    installation: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'updated'],
  setup(props, { emit }) {
    const textarea = ref(null);
    const localJob = ref(JSON.parse(JSON.stringify(props.job)));
    const serverErrors = ref([]);

    const cancel = () => {
      emit('close');
    };

    const updateJob = () => {
      serverErrors.value = [];
      axios.put(`/jobs/${localJob.value.id}`, {
        job: {
          instructions: localJob.value.instructions,
        },
      }).then((response) => {
        emit('updated', response.data.job);
        emit('close');
      }).catch((error) => {
        if (error.response.status === 400) {
          serverErrors.value = error.response.data.jobs;
        } else {
          Flash.error('Auftrag konnte nicht gespeichert werden');
        }
      });
    };

    const resize = () => {
      const element = textarea.value;
      if (element) {
        element.style.height = '18px';
        element.style.height = `${element.scrollHeight}px`;
      }
    };

    onMounted(() => {
      textarea.value.focus();
      resize();
    });

    return {
      textarea,
      cancel,
      localJob,
      serverErrors,
      updateJob,
      resize,
    };
  },
};
</script>
