<template>
  <table v-if="localIncidentStatuses.length" class="material">
    <tr>
      <td class="title" colspan="4" style="width: unset; font-size: 1rem; color: #2C556E; text-transform: uppercase; letter-spacing: 1px; font-weight: normal;">
        STÖRUNGEN
      </td>
    </tr>
    <tr v-for="(incidentStatus, index) in localIncidentStatuses" :key="index">
      <td class="title" style="vertical-align:top; width: unset;">
        #{{ incidentStatus.incident.number }}
      </td>
      <td style="vertical-align:top;">
        <span
          style="font-weight: bold;"
          :title="incidentStatus.incident.incident_type.name"
        >{{ truncate(incidentStatus.incident.incident_type.name, 200) }}</span>
        <div v-if="incidentStatus.incident.severity != 'normal'">
          <span :class="'severity-' + incidentStatus.incident.severity">
            {{ $t('activerecord.attributes.incident.severities.' + incidentStatus.incident.severity) }}
          </span>
        </div>
      </td>
      <td style="vertical-align:top;">
        <span :class="'fx-status incident-' + incidentStatus.status">
          {{ $t('activerecord.attributes.incident_status.statuses.' + incidentStatus.status) }}
        </span>
      </td>
      <td style="vertical-align:top;">
        {{ incidentStatus.note }}
      </td>
    </tr>
  </table>
</template>

<script>
import axios from 'axios';
import { TruncateFormatter } from 'mixins/formatters';

export default {
  name: 'IncidentsForEventTable',
  components: {
  },
  mixins: [TruncateFormatter],
  props: {
    eventId: {
      type: String,
      required: true,
    },
    thingId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      localIncidentStatuses: [],
      loaded: false,
    };
  },
  computed: {
  },
  mounted() {
    this.loadIncidentStatuses();
  },
  methods: {
    loadIncidentStatuses() {
      this.localIncidentStatuses = [];
      this.loaded = false;

      axios.get(`/things/${this.thingId}/events/${this.eventId}/incident_statuses.json`).then((response) => {
        this.localIncidentStatuses = response.data;
      }).catch();
    },
  },
};
</script>
