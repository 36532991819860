<template>
  <section class="fx-page-content">
  <div v-if="initialDataLoaded">
    <section class="header">
      <div class="fx-grid">
        <div class="fx-col">
          <ul class="fx-breadcrumps">
            <li><a href="/portal/locations">Objekte</a></li>
            <li>{{ locationBreadcrumpLabel }}</li>
            <li>
              <a :href="'/portal/shared_installations/' + localSharedInstallation.id">
                {{ localSharedInstallation.installation.installation_type_name }}</a>
            </li>
            <li>Störung melden</li>
          </ul>
        </div>
      </div>
    </section>

    <div v-if="!resultingIncident" class="row">
      <div class="fx-grid">
        <div class="fx-col" style="margin-top: 1rem;">
          <h1 class="fx">
            Störung melden
          </h1>
          <div class="fx-card">
            <table class="material top-align">
              <colgroup>
                <col style="width: 180px;">
                <col>
              </colgroup>
              <tbody>
                <tr class="middle-align" />
                <tr>
                  <th>Service-Unternehmen</th>
                  <td id="incident-details-location">
                    {{ localSharedInstallation.organization_name }}
                  </td>
                </tr>
                <tr>
                  <th>Objekt</th>
                  <td id="incident-details-location">
                    <Location :location="localInstallation.location" :link="false" />
                  </td>
                </tr>
                <tr>
                  <th>Anlage</th>
                  <td id="incident-details-installation">
                    <Installation :installation="localInstallation" :link="false" />
                  </td>
                </tr>
                <tr>
                  <th>Gemeldet von</th>
                  <td id="incident-details-installation">
                    {{ currentUser.fullname }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="fx-card">
            <div class="fx-card-content">
              <ServerErrorsCommand
                v-model="commandServerErrors"
                title="Störung konnte nicht gespeichert werden"
                margin-bottom="1rem"
              />

              <div style="max-width: 800px;">
                <form @submit.prevent="submit">
                  <div class="fx-form-row" style="max-width: 100%">
                    <div class="fx-form-label">
                      <label class="inline mandatory" for="incident_incident_type">Störungstyp</label>
                    </div>
                    <div class="fx-form-content">
                      <select
                        v-model="incidentTypeId"
                        :disabled="!enableIncidentTypeSelect"
                        @change="updateIncidentTypeDetails()"
                      >
                        <option v-for="opt in incidentTypeOptions" :key="opt.value" :value="opt.value">
                          {{ opt.text }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="fx-form-row" style="max-width: 100%">
                    <div class="fx-form-label">
                      <label class="inline mandatory">Schweregrad</label>
                    </div>
                    <div class="fx-form-content">
                      <input
                        id="incident_severity_low"
                        v-model="severity"
                        type="radio"
                        value="low"
                      >
                      <label for="incident_severity_low">
                        {{ $t('activerecord.attributes.incident.severities.low') }}</label>
                      <input
                        id="incident_severity_normal"
                        v-model="severity"
                        type="radio"
                        value="normal"
                      >
                      <label for="incident_severity_normal">
                        {{ $t('activerecord.attributes.incident.severities.normal') }}</label>
                      <input
                        id="incident_severity_high"
                        v-model="severity"
                        type="radio"
                        value="high"
                      >
                      <label for="incident_severity_high">
                        {{ $t('activerecord.attributes.incident.severities.high') }}</label>
                      <input
                        id="incident_severity_notice"
                        v-model="severity"
                        type="radio"
                        value="notice"
                      >
                      <label for="incident_severity_notice">
                        {{ $t('activerecord.attributes.incident.severities.notice') }}</label>
                    </div>
                  </div>

                  <div class="fx-form-row" style="max-width: 100%">
                    <div class="fx-form-label">
                      <label class="inline">Komponente</label>
                    </div>
                    <div class="fx-form-content">
                      <DBBasedSelect
                        v-model="thingId"
                        icon-id="thing"
                        :default-label="$t('comp.select.no-selection')"
                        i18n-base-key="things"
                        :load-options-url="`/select/things?installation_id=${installationId}`"
                        @changed="(value) => { thingId = value; }"
                      />
                      <!-- <SelectThing
                        v-model="thingId"
                        :installation-id="localInstallation.id"
                        nothing-selected-label="Wählen Sie die betroffene Komponente (optional)"
                      /> -->
                      <div class="form-field-hint">
                        Wenn möglich, geben Sie bitte die betroffene Komponente der Anlage an.
                      </div>
                    </div>
                  </div>

                  <div class="fx-form-row" style="max-width: 100%">
                    <div class="fx-form-label">
                      <label class="inline">Foto</label>
                    </div>
                    <div class="fx-form-content">
                      <dashboard
                        :uppy="uppy"
                        :props="{
                          inline: true,
                          hideUploadButton: true,
                          height: 200,
                          width: '100%',
                        }"
                      />
                    </div>
                  </div>

                  <div class="fx-form-row" style="max-width: 100%">
                    <div class="fx-form-label">
                      <label class="inline" for="incident_note">Beschreibung</label>
                    </div>

                    <div class="fx-form-content">
                      <textarea
                        id="incident_note"
                        v-model="description"
                        name="description"
                        rows="18"
                        maxlength="2500"
                      />
                    </div>
                  </div>

                  <div class="fx-form-row" style="margin-bottom: 0; max-width: 100%;">
                    <div class="fx-form-content">
                      <div style="margin-bottom: 1rem;">
                        <button type="submit" class="fx-btn-primary float-right">
                          Störung melden
                        </button>
                        <div class="clearfix" />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="resultingIncident" class="fx-grid">
      <div class="fx-col">
        <empty-state
          :success-icon="true"
          :title="'Störung #' + resultingIncident.number + ' wurde gemeldet'"
          hint="Das Service-Unternehmen wird per E-Mail über die Störung benachrichtigt"
        >
          <a href="/portal/locations" class="fx-btn-skeleton"><i class="fas fa-building" /> Zur Objekt-Übersicht</a>

          <a :href="sharedInstallationPath" class="fx-btn-skeleton"><i class="fas fa-sitemap" /> Zur Anlage</a>

          <a :href="`/portal/incidents/${resultingIncident.id}`" class="fx-btn-skeleton"><i
            class="fas fa-exclamation-triangle"
          /> Zur Störung #{{ resultingIncident.number }}</a>

          <div style="font-size: 0.825rem; margin-top: 1rem;">
            <a @click.prevent="reset">Noch eine Störung für diese Anlage melden</a>
          </div>
        </empty-state>
      </div>
    </div>
  </div>
</section>
</template>

<script>
/* eslint-disable no-undef */
import axios from 'axios';

import Flash from 'flash/index';

import { useCurrentUserStore } from 'stores/current_user';
import Location from 'components/partials/location.vue';
import Installation from 'components/partials/installation.vue';
import DBBasedSelect from 'components/select/db_based_select.vue';
import EmptyState from 'components/empty_state.vue';
import FeatureFlipper from 'mixins/feature_flipper';
import ServerErrorsCommand from 'components/partials/server_errors_command.vue';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/vue';
import AwsS3 from '@uppy/aws-s3';
import German from '@uppy/locales/lib/de_DE';

export default {
  name: 'PortalIncidentNew',
  components: {
    Location,
    Installation,
    EmptyState,
    DBBasedSelect,
    ServerErrorsCommand,
    Dashboard,
  },
  mixins: [FeatureFlipper],
  props: {
    installationId: {
      type: String,
      required: true,
    },
    initThingId: {
      type: String,
      default: null,
    },
  },
  setup() {
    return {
      currentUser: useCurrentUserStore().currentUser,
    };
  },
  data() {
    return {
      initialDataLoaded: false,
      localInstallation: null,
      localSharedInstallation: null,
      thingId: null,
      incidentTypeId: null,
      thingOptions: [],
      incidentTypeOptions: [],
      enableIncidentTypeSelect: false,
      enableThingSelect: false,
      severity: 'normal',
      note: null,
      description: null,
      resultingIncident: null,
      commandServerErrors: {},
    };
  },
  computed: {
    sharedInstallationPath() {
      return `/portal/shared_installations/${this.localSharedInstallation.id}#incidents`;
    },
    locationBreadcrumpLabel() {
      if (this.localSharedInstallation && this.localSharedInstallation.location_number) {
        return this.localSharedInstallation.location_number;
      }
      return this.localSharedInstallation.installation.location_active_name;
    },
    uppy() {
      const loc = German;
      loc.strings.dropPasteFiles = 'Foto hier ablegen oder %{browseFiles}';

      const uppy = new Uppy({
        locale: loc,
        restrictions: {
          autoProceed: true,
          maxFileSize: 1024 * 1000 * 5,
          minFileSize: null,
          maxTotalFileSize: null,
          maxNumberOfFiles: 1,
          minNumberOfFiles: 0,
          allowedFileTypes: ['.png', '.jpg', '.jpeg'],
          requiredMetaFields: [],
        },
      });
      uppy.use(AwsS3, {
        companionUrl: '/', // will call `GET /s3/params` on our app
      });

      uppy.on('upload-success', (file, response) => {
        const uploadedFileData = {
          id: file.meta.key.match(/^cache\/(.+)/)[1], // object key without prefix
          storage: 'cache',
          metadata: {
            size: file.size,
            filename: file.name,
            mime_type: file.type,
          },
        };

        axios.post(
          '/photos.json',
          {
            photo: {
              photoable_id: this.resultingIncident.id,
              photoable_type: 'Incident',
              image: uploadedFileData,
            },
          },
        ).then(() => {
        }).catch(() => {
          Flash.error('Fehler beim Hochladen');
        });
      });

      return uppy;
    },
  },
  mounted() {
    if (this.initThingId && this.initThingId.length) {
      this.thingId = this.initThingId;
    }
    this.loadInstallation();
    this.loadIncidentTypeOptions(this.installationId);
  },
  methods: {
    submit() {
      this.createIncident();
    },
    reset() {
      this.note = null;
      this.resultingIncident = null;
    },
    createIncident() {
      const that = this;

      axios.post('/portal/incidents', {
        installation_id: this.installationId,
        incident_type_id: this.incidentTypeId,
        severity: this.severity,
        thing_id: this.thingId,
        note: this.note,
        description: this.description,
      }).then((response) => {
        this.resultingIncident = response.data;
        this.uppy.upload();
      }).catch((error) => {
        if (error.response.status === 400) {
          that.commandServerErrors = error.response.data;
        } else {
          Flash.error('Störung konnte nicht gespeichert werden');
        }
      });
    },
    loadIncidentTypeOptions(installationId) {
      if (!installationId) {
        this.incidentTypeOptions = [];
        this.enableIncidentTypeSelect = false;
        this.thingOptions = [];
        this.enableThingSelect = false;
        return;
      }
      axios.get(`/installations/${installationId}/thing_options`)
        .then((response) => {
          this.thingOptions = response.data;
          this.enableThingSelect = true;
        })
        .catch();
      axios.get(`/installations/${installationId}/incident_type_options?portal=1`)
        .then((response) => {
          this.incidentTypeOptions = response.data;
          this.incidentTypeId = this.incidentTypeOptions[0].value;
          this.enableIncidentTypeSelect = true;
        }).catch();
    },
    updateIncidentTypeDetails() {
      if (this.incidentTypeId == null) {
        return;
      }

      axios.get(`/incident_types/${this.incidentTypeId}.json`)
        .then((response) => {
          this.severity = response.data.default_severity;
        }).catch();
    },
    loadInstallation() {
      const that = this;
      axios.get(`/portal/incidents/new/?installation_id=${this.installationId}`)
        .then((response) => {
          that.localInstallation = response.data.installation;
          that.localSharedInstallation = response.data.shared_installation;
          that.initialDataLoaded = true;
        }).catch(() => {
          Flash.error('Installation konnte nicht geladen werden');
        });
    },
  },
};
</script>
