<template>
  <table class="material">
    <tr v-for="(dataRow, index) in data" :key="index">
      <template v-if="isGroup(dataRow)">
        <td
          class="title"
          colspan="2"
          style="width: unset; font-size: 1rem; color: #2C556E;
        text-transform: uppercase; letter-spacing: 1px; font-weight: normal;"
        >
          {{ dataRow.label }}
        </td>
      </template>
      <template v-else>
        <td class="title" style="width: unset;">
          {{ dataRow.label }}:
        </td>
        <td v-if="!isGroup(dataRow)">
          <span v-if="dataRow.value != null">{{ localizedValue(dataRow) }}</span>
          <span v-else style="color: #ddd;">nicht ausgefüllt</span>
        </td>
      </template>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'DataTable',
  components: {
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  computed: {
  },
  methods: {
    isGroup(data) {
      return (data.type === 'group');
    },
    localizedValue(data) {
      switch (data.type) {
        case 'yesnona':
          return this.translatedValue(data.value);
        case 'yesno':
          return this.translatedValue(data.value);
        case 'oknokna':
          return this.translatedValue(data.value);
        case 'oknok':
          return this.translatedValue(data.value);
        case 'checkbox':
          return this.translatedValue(data.value);
        case 'numeric':
          return `${data.value} ${data.unit}`;
        default:
          return data.value;
      }
    },
    translatedValue(value) {
      switch (value) {
        case 'yes':
          return 'Ja';
        case 'no':
          return 'Nein';
        case 'ok':
          return 'i.O.';
        case 'nok':
          return 'n.i.O.';
        case 'na':
          return 'n.v.';
        case true:
          return 'Ja';
        case false:
          return 'Nein';
        default:
          return value;
      }
    },
  },
};
</script>
