<template>
  <div>
    <div
      v-for="integration in localIntegrations"
      :key="integration.id"
      style="padding: 10px 14px 10px 20px; margin: 0; font-size: .9rem; border: 1px solid #ccc; border-radius: 4px; background: white;"
    >
      <div class="float-right" style="padding: 0 0 0 .2rem;">
        <drop-down classes="fx-context-circle" :disabled="false">
          <drop-down-item>
            <a @click.prevent="confirmDeleteIntegration(integration)"><i class="far fa-trash-alt fa-fw" />
              Integration löschen</a>
          </drop-down-item>
        </drop-down>
      </div>
      <div>
        <div style="margin-top: 10px; font-weight: 500;">
          {{ integration.label }}
        </div>
        <p>
          System Id: {{ integration.system_id }}<br>
          Ownership-Code: {{ integration.ownership_code }}<br>
          Building-Id: {{ integration.building_id }}<br>
        </p>

        <button class="fx-btn-ico-primary" style="margin-top: .5rem;" @click="openImportModal(installationId, integration)">
          <Icon id="import" /> Komponenten aus
          CLSS importieren
        </button>
      </div>
    </div>

    <template v-if="localIntegrations.length === 0">
      <h3 class="fx">
        Integration hinzufügen
      </h3>
      <ServerErrorsCommand v-model="commandServerErrors" margin-bottom="1rem" title="Fehler beim Verknüpfen der Anlage" />
      <form>
        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline" for="interval-root">Zugangsdaten</label>
          </div>
          <div class="fx-form-content">
            <select v-model="integrationCredentialsId">
              <option
                v-for="credentials in localIntegrationCredentials"
                :key="credentials.id"
                :value="credentials.id"
              >
                {{ credentials.label }}
              </option>
            </select>
          </div>
        </div>
        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline" for="interval-root">Ownership-Code</label>
          </div>
          <div class="fx-form-content">
            <input id="delete-empty-location" v-model="ownershipCode" type="text">
          </div>
        </div>
        <br>
        <TransactionButton
          id="btn-save-installation"
          v-model="loading"
          class="fx-btn-primary"
          title="Anlage verknüpfen"
          @click="mapInstallation()"
        />
      </form>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import confirmViaModal from 'helpers/confirm_modal';
import { useCurrentUserStore } from 'stores/current_user';
import { ref } from 'vue';
import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';

import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';
import TransactionButton from 'components/transaction_button.vue';
import ServerErrorsCommand from 'components/partials/server_errors_command.vue';
import Icon from 'components/icon.vue';

import ImportViaIntegrationModal from './import_via_integration_modal.vue';

export default {
  name: 'InstallationIntegrations',
  components: {
    DropDown,
    DropDownItem,
    TransactionButton,
    ServerErrorsCommand,
    Icon,
  },
  props: {
    installationId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const localIntegrations = ref([]);

    const loadIntegrations = () => {
      axios.get(`/installations/${props.installationId}/integrations`)
        .then((response) => {
          localIntegrations.value = response.data.installation_integrations;
        });
    };

    const deleteIntegration = (integration) => {
      axios.delete(`/installations/${props.installationId}/integrations/${integration.id}`)
        .then(() => {
          loadIntegrations();
        });
    };

    const confirmDeleteIntegration = (integration) => {
      confirmViaModal({
        title: 'Integration löschen?',
        message: 'Sie können nach dem Löschen keinen Daten mehr über diese Integration austauschen.',
      }).then(() => {
        deleteIntegration(integration);
      });
    };

    const openImportModal = (installationId, integration) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: ImportViaIntegrationModal,
            attrs: {
              installationId,
              integration,
              onClose() {
                modalInstance.close();
              },
              onFinished() {
                modalInstance.close();
                window.location.href = `/installations/${this.installationId}#tab-things`;
                window.location.reload(); // reload required since only the local anchor changed
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    return {
      localIntegrations,
      openImportModal,
      loadIntegrations,
      confirmDeleteIntegration,
      currentUser: useCurrentUserStore().currentUser,
    };
  },
  data() {
    return {
      loading: false,
      ownershipCode: null,
      integrationCredentialsId: null,
      localIntegrationCredentials: [],
      commandServerErrors: {},
    };
  },
  mounted() {
    this.loadIntegrationCredentials();
    this.loadIntegrations();
  },
  methods: {
    // ISSUE #1535: TO BE REMOVED IF COMPOSITION API USED
    // confirmStartImport(integration) {
    //   console.log('modal is:', this.$modal);
    //   this.$modal.show('import-via-integration-modal', {
    //     integration,
    //   });
    // },
    mapInstallation() {
      this.loading = true;
      this.commandServerErrors = {};
      if (this.ownershipCode == null || this.ownershipCode.length < 5) {
        this.$nextTick(() => {
          this.loading = false;
        });

        return;
      }

      axios.post(`/installations/${this.installationId}/integrations`, {
        integration_credentials_id: this.integrationCredentialsId,
        ownership_code: this.ownershipCode,
      })
        .then(() => {
          this.loading = false;
          this.loadIntegrations();
        }).catch((error) => {
          this.loading = false;
          this.commandServerErrors = error.response.data;
        });
    },
    loadIntegrationCredentials() {
      const that = this;
      axios.get(`/organizations/${this.currentUser.organization_id}/integration_credentials.json`)
        .then((response) => {
          that.localIntegrationCredentials = response.data.credentials;
          if (that.localIntegrationCredentials.length > 0) {
            that.integrationCredentialsId = that.localIntegrationCredentials[0].id;
          }
        });
    },
  },
};
</script>
