<template>
  <div>
    <div class="repco_preview">
      <template v-if="showForm">
        <div style="background-color: #eee; min-height: 16rem; border-radius: 8px; padding: 1rem;">
          <h3 class="fx">
            {{ name }}
          </h3>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline mandatory" for="customer_name">Überschrift</label>
            </div>
            <div class="fx-form-content">
              <input v-model="localSection.title" maxlength="80" type="text">
            </div>
          </div>

          <div class="fx-form-row">
            <div class="fx-form-content">
              <input id="deployments_enabled" v-model="localSection.deployments_enabled" type="checkbox">
              <label class="inline" for="deployments_enabled">Inbetriebnahmen abdrucken</label><br>

              <input id="removals_enabled" v-model="localSection.removals_enabled" type="checkbox">
              <label class="inline" for="removals_enabled">Außerbetriebnahmen abdrucken</label><br>

              <input id="replacements_enabled" v-model="localSection.replacements_enabled" type="checkbox">
              <label class="inline" for="replacements_enabled">Austausch abdrucken</label>
            </div>
          </div>

          <button class="fx-btn-primary" @click="showForm=false">
            Zurück zur Vorschau
          </button>
        </div>
      </template>

      <!-- PREVIEW foxtag_report -->
      <template v-if="!showForm && !expReport">
        <div :class="previewEngineClass">
          <div class="repco-h2" :style="headlineStyle">
            {{ localSection.title }}
          </div>
          <table class="preview">
            <tr>
              <th style="width: 12%" :style="thStyle">
                Nr.
              </th>
              <th style="width: 26%" :style="thStyle">
                Typ
              </th>
              <th style="width: 50%" :style="thStyle">
                Details
              </th>
              <th style="width: 12%" :style="thStyle">
                Aktion
              </th>
            </tr>
            <tr>
              <td colspan="4" class="repco-group" :style="groupStyle">
                1 Gruppenname
              </td>
            </tr>

            <tr v-if="localSection.deployments_enabled">
              <td class="repco" :style="tdStyle">
                1/1
              </td>
              <td class="repco" :style="tdStyle">
                Typ-Name<br>Hersteller Modell
              </td>
              <td class="repco" :style="tdStyle">
                Gebäude A, Raum 123<br>
                Baujahr: 2012<br>
                <span v-if="showsTags">Foxtag-ID: a3c14d<br></span>
                <span class="secondary italic mt-4 text-sm">weitere Stammdaten ...</span>
              </td>
              <td class="repco" :style="tdStyle">
                Inbetriebnahme
              </td>
            </tr>

            <tr v-if="localSection.removals_enabled">
              <td class="repco" :style="tdStyle">
                1/2
              </td>
              <td class="repco" :style="tdStyle">
                Typ-Name<br>Hersteller Modell
              </td>
              <td class="repco" :style="tdStyle">
                Gebäude B, Raum 231<br>
                Baujahr: 2012<br>
                <span v-if="showsTags">Foxtag-ID: a3c14d<br></span>
                <span class="secondary italic mt-4 text-sm">weitere Stammdaten ...</span>
                <div class="mt-4">
                  <strong>Optionaler Grund für Außerbetriebnahme</strong>
                </div>
              </td>
              <td class="repco" :style="tdStyle">
                Außerbetriebnahme
              </td>
            </tr>

            <tr v-if="localSection.replacements_enabled">
              <td class="repco" rowspan="2" :style="tdStyle">
                1/3
              </td>
              <td class="repco" :style="tdStyle" style="border: 0;">
                <div class="text-sm italic">
                  Inbetriebnahme
                </div>Typ-Name<br>Hersteller Modell
              </td>
              <td class="repco" :style="tdStyle">
                Gebäude C, Raum 3<br>
                Baujahr: 2022<br>
                <span v-if="showsTags">Foxtag-ID: ztwcd4d<br></span>
                <span class="secondary italic mt-4 text-sm">weitere Stammdaten ...</span>
              </td>
              <td class="repco" rowspan="2" :style="tdStyle">
                Austausch
              </td>
            </tr>
            <tr v-if="localSection.replacements_enabled">
              <td class="repco" :style="tdStyle">
                <div class="text-sm italic">
                  Außerbetriebnahme
                </div>Typ-Name<br>Hersteller Modell
              </td>
              <td class="repco" :style="tdStyle">
                Gebäude C, Raum 3<br>
                Baujahr: 2012<br>
                <span v-if="showsTags">Foxtag-ID: b4c14d<br></span>
                <span class="secondary italic mt-4 text-sm">weitere Stammdaten ...</span>
                <div class="mt-4">
                  <strong>Optionaler Grund für Austausch</strong>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </template>

      <!-- PREVIEW exp_report -->
      <template v-if="!showForm && expReport">
        <div :class="previewEngineClass">
          <div class="repco-h2" :style="headlineStyle">
            {{ localSection.title }}
          </div>
          <div class="mb-4">
            <strong>1 Gruppenname</strong>
          </div>
          <table class="preview">
            <tr>
              <th style="width: 12%" :style="thStyle">
                Nr.
              </th>
              <th style="width: 26%" :style="thStyle">
                Typ
              </th>
              <th style="width: 50%" :style="thStyle">
                Details
              </th>
              <th style="width: 12%" :style="thStyle">
                Aktion
              </th>
            </tr>

            <tr v-if="localSection.deployments_enabled">
              <td class="repco" :style="tdStyle">
                1/1
              </td>
              <td class="repco" :style="tdStyle">
                Typ-Name<br>Hersteller Modell
              </td>
              <td class="repco" :style="tdStyle">
                Gebäude A, Raum 123<br>
                Baujahr: 2012<br>
                <span v-if="showsTags">Foxtag-ID: a3c14d<br></span>
                <span class="secondary italic mt-4 text-sm">weitere Stammdaten ...</span>
              </td>
              <td class="repco" :style="tdStyle">
                Inbetriebnahme
              </td>
            </tr>

            <tr v-if="localSection.removals_enabled">
              <td class="repco" :style="tdStyle">
                1/2
              </td>
              <td class="repco" :style="tdStyle">
                Typ-Name<br>Hersteller Modell
              </td>
              <td class="repco" :style="tdStyle">
                Gebäude B, Raum 231<br>
                Baujahr: 2012<br>
                <span v-if="showsTags">Foxtag-ID: a3c14d<br></span>
                <span class="secondary italic mt-4 text-sm">weitere Stammdaten ...</span>
                <div class="mt-4">
                  <strong>Optionaler Grund für Außerbetriebnahme</strong>
                </div>
              </td>
              <td class="repco" :style="tdStyle">
                Außerbetriebnahme
              </td>
            </tr>

            <tr v-if="localSection.replacements_enabled">
              <td class="repco" rowspan="2" :style="tdStyle">
                1/3
              </td>
              <td class="repco" :style="tdStyle" style="border: 0;">
                Typ-Name<br>Hersteller Modell
              </td>
              <td class="repco" :style="tdStyle">
                Gebäude C, Raum 3<br>
                Baujahr: 2022<br>
                <span v-if="showsTags">Foxtag-ID: ztwcd4d<br></span>
                <span class="secondary italic mt-4 text-sm">weitere Stammdaten ...</span>
                <div class="mt-4">
                  <strong>Optionaler Grund für Austausch</strong>
                </div>
              </td>
              <td class="repco" :style="tdStyle">
                Austausch
                <div style="font-size: .75rem;">
                  Inbetriebnahme
                </div>
              </td>
            </tr>
            <tr v-if="localSection.replacements_enabled">
              <td class="repco" style="border: 0;">
                Typ-Name<br>Hersteller Modell
              </td>
              <td class="repco" style="border: 0;">
                Gebäude C, Raum 3<br>
                Baujahr: 2012<br>
                <span v-if="showsTags">Foxtag-ID: b4c14d<br></span>
                <span class="secondary italic mt-4 text-sm">weitere Stammdaten ...</span>
              </td>
              <td style="font-size: .75rem; border: 0;">
                Außerbetriebnahme
              </td>
            </tr>
          </table>
        </div>
      </template>
    </div>

    <Controls
      :index="index"
      :list-length="listLength"
      :name="name"
      :readonly="readonly"
      @settings="showForm = !showForm"
      @add="$emit('add')"
      @delete="$emit('delete')"
      @move-up="$emit('moveUp')"
      @move-down="$emit('moveDown')"
    />
  </div>
</template>

<script>
import Controls from './section_controls.vue';

export default {
  name: 'SectionLifecycleEvents',
  components: {
    Controls,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    colors: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      default: 'In-/Außerbetriebnahmen',
    },
    index: {
      type: Number,
      required: true,
    },
    listLength: {
      type: Number,
      required: true,
    },
    showsTags: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    layoutEngine: {
      type: String,
      default: 'foxtag_report',
    },
  },
  data() {
    return {
      showForm: false,
      localSection: {},
      test: 'OK',
    };
  },
  computed: {
    expReport() {
      return (this.layoutEngine === 'exp_report');
    },
    previewEngineClass() {
      return `preview-${this.layoutEngine}`;
    },
    thStyle() {
      if (this.layoutEngine == 'exp_report') {
        return `background-color: transparent; border-color: #${this.colors.color_lines}; border: 0; border-bottom: 2px solid #${this.colors.color_lines}`;
      }

      return `color: #${this.colors.color_col_headline_text}; background-color: #${this.colors.color_lines}; border: 2px solid #${this.colors.color_lines}`;
    },
    tdStyle() {
      if (this.layoutEngine == 'exp_report') {
        return `color: #${this.colors.color_text}; border: 0; border-top: 2px solid #${this.colors.color_lines}`;
      }

      return `color: #${this.colors.color_text}; border: 2px solid #${this.colors.color_lines}`;
    },
    headlineStyle() {
      return `color: #${this.colors.color_headlines};`;
    },
    groupStyle() {
      return `color: #${this.colors.color_text}; background-color: #${this.colors.color_bg_groups}; border-color: #${this.colors.color_lines}`;
    },
  },
  mounted() {
    this.localSection = this.section;
  },
};
</script>

<style>
  .divider {
    display: flex;
    align-items: center;
    text-align: center;
  }

  /* To show the lines on right
  and left sides of the text */
  .divider::after,
  .divider::before {
    content: "";
    border-top: 1px dashed #888;
    flex: 1;
  }

  /* Space on left and right sides of text */
  .divider:not(:empty)::before {
    margin-right: 0.6rem;
  }

  .divider:not(:empty)::after {
    margin-left: 0.6rem;
  }

</style>
