<template>
  <div>
    <div class="repco_preview" style="min-height: 10rem; padding-top: 4rem;">
      <div class="divider">
        Neue Seite
      </div>
    </div>

    <Controls
      :index="index"
      :list-length="listLength"
      :name="name"
      :has-settings="false"
      :readonly="readonly"
      @settings="showForm = !showForm"
      @add="$emit('add')"
      @delete="$emit('delete')"
      @move-up="$emit('moveUp')"
      @move-down="$emit('moveDown')"
    />
  </div>
</template>

<script>
import Controls from './section_controls.vue';

export default {
  name: 'SectionPagebreak',
  components: {
    Controls,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      default: 'Seitenumbruch',
    },
    index: {
      type: Number,
      required: true,
    },
    listLength: {
      type: Number,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
