<template>
  <div>
    <JobListItem
      v-for="job in localJobs"
      :key="job.id"
      :job="job"
      :allow-editing="allowEditing"
      :show-locate-me="showLocateMe"
      @open-confirm-delete-job-modal="openConfirmDeleteJobModal"
      @open-confirm-cancel-job-modal="cancelViaAPI"
      @change-status="changeStatusViaAPI"
      @locate-me="emitLocateMe"
      @quickview="openQuickView"
    />
    <div v-if="!loaded" style="padding: 1rem; text-align: center;">
      <i class="fx-spinner" />
    </div>
    <div v-if="loaded && moreJobsAvailable" style="padding: 1rem; text-align: center;">
      <button class="fx-btn-skeleton" @click="loadMore()">
        mehr Aufträge anzeigen
      </button>
    </div>
    <slot v-if="showNoResults" name="empty-state">
      keine Aufträge
    </slot>
    <div v-if="error" class="fx-list-empty-state">
      {{ errorMessage }} <a @click.prevent="loadJobs()">Nochmal versuchen</a>
    </div>
    <div v-if="cancelled" class="fx-list-empty-state">
      Anfrage abgebrochen
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import Flash from 'flash/index';
import JobListItem from 'apps/jobs/index/job_list_item.vue';
import { jobQuickView } from 'helpers/quickviews';
import confirmViaModal from 'helpers/confirm_modal';

import { ref } from 'vue';

export default {
  name: 'JobList',
  components: {
    JobListItem,
  },
  props: {
    overduePlannedJobsCount: {
      type: Number,
      default: 0,
    },
    errorMessage: {
      type: String,
      default: 'Die Aufträge konnten nicht geladen werden.',
    },
    queryPath: {
      type: String,
      required: true,
    },
    allowEditing: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 25,
    },
    showLocateMe: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['locate-me', 'changed'],
  setup(props, { emit }) {
    const localJobs = ref([]);

    const findJobIndexInList = (jobId) => _.findIndex(localJobs.value, { id: jobId });

    const patchJobLabels = (job) => {
      localJobs.value[findJobIndexInList(job.id)].labels = job.labels;
    };

    const deletedJobEvent = (job) => {
      const index = findJobIndexInList(job.id);
      localJobs.value.splice(index, 1);
    };

    const deleteViaApi = (job) => {
      axios.delete(`/jobs/${job.id}`).then(() => {
        Flash.info('Auftrag gelöscht');
        deletedJobEvent(job);
        emit('changed');
      }).catch(() => {
        Flash.error('Auftrag konnte nicht gelöscht werden');
      });
    };

    const openQuickView = (jobId) => {
      jobQuickView(jobId).then((changedJob) => {
        patchJobLabels(changedJob);
      });
    };

    const openConfirmDeleteJobModal = (job) => {
      confirmViaModal({
        object: job,
        message: 'Diese Aktion löscht den Auftrag und alle im Auftrag erfassten Daten und Berichte!',
        title: `Auftrag ${job.number} löschen?`,
      }).then(() => {
        deleteViaApi(job);
      });
    };

    return {
      localJobs,
      openQuickView,
      openConfirmDeleteJobModal,
    };
  },
  data() {
    return {
      page: 1,
      loaded: false,
      moreJobsAvailable: false,
      error: false,
      cancelled: false,
    };
  },
  computed: {
    showNoResults() {
      return (!this.error && !this.cancelled && this.loaded && (this.localJobs.length === 0));
    },
  },
  mounted() {
    this.loadJobs();
  },
  methods: {
    loadMore() {
      this.page += 1;
      this.loadJobs();
    },
    reload() {
      this.localJobs = [];
      this.page = 1;
      this.loadJobs();
    },
    loadJobs() {
      this.loaded = false;
      this.error = false;
      this.cancelled = false;
      const that = this;
      axios.get(this.queryPath, {
        params: {
          page: this.page,
          limit: this.limit,
        },
      }).then((response) => {
        that.moreJobsAvailable = (response.data.meta.total_pages > response.data.meta.current_page);
        that.localJobs = that.localJobs.concat(response.data.jobs.slice(0, this.limit));
        that.loaded = true;
      }).catch((err) => {
        that.loaded = true;
        if (err.message === 'Request aborted') {
          that.cancelled = true;
        } else {
          that.error = true;
        }
      });
    },
    cancelViaAPI(job) {
      this.changeStatusViaAPI(job, 'cancelled');
    },
    updatedJobEvent(job) {
      const index = this.findJobIndexInList(job.id);
      this.localJobs.splice(index, 1, job);
    },
    findJobIndexInList(jobId) {
      return _.findIndex(this.localJobs, { id: jobId });
    },
    changeStatusViaAPI(job, status) {
      const that = this;
      axios.post(`/jobs/${job.id}/statuses`, {
        status,
      }).then((response) => {
        Flash.info('Auftragsstatus aktualisiert');
        that.updatedJobEvent(response.data.job);
      }).catch(() => {
        Flash.error('Auftragsstatus konnte nicht aktualisiert werden');
      });
    },
    emitLocateMe(data) {
      this.$emit('locate-me', data);
    },
  },
};
</script>
