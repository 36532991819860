<template>
  <div class="yesnoswitch" :class="classes" :title="title">
    <input
      :id="id"
      :disabled="disabled"
      class="yesnoswitch-checkbox"
      type="checkbox"
      :checked="modelValue"
      @click="$emit('click')"
    >
    <label class="yesnoswitch-label" :for="id">
      <span class="yesnoswitch-inner" />
      <span class="yesnoswitch-switch" />
    </label>
  </div>
</template>

<script>

export default {
  name: 'YesNoSwitch',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  computed: {
    id() {
      return this.$.uid;
    },
    title() {
      return (this.disabled ? 'deaktiviert' : '');
    },
    classes() {
      return (this.disabled ? 'disabled' : '');
    },
  },
};
</script>
