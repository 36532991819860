<template>
  <div>
    <table style="width: 100%">
      <tr>
        <td style="vertical-align: bottom;">
          <h1 class="fx" style="margin-bottom:0;">
            {{ title }}
          </h1>
        </td>
        <td v-if="canEdit" style="text-align: right;">
          <div class="fx-btn-group">
            <button
              v-if="false"
              id="btn-add-location"
              class="fx-btn-primary"
              @click="openEditLocationModal(null)"
            >
              <i
                class="fas fa-plus"
              /> Objekt hinzufügen
            </button>
            <button id="btn-add-installation" class="fx-btn-ico-primary" @click="openAddInstallationModal({})">
              <i
                class="fas fa-plus"
              /> Anlage hinzufügen
            </button>

            <drop-down text="Import/Export" classes="fx-btn-secondary">
              <drop-down-item>
                <a :href="locationImportPath()"><i class="far fa-file-import fw" /> Objekte
                  importieren</a>
              </drop-down-item>
              <drop-down-item v-if="modelValue.customerId == null">
                <a @click="openExportModalLocations"><i
                  class="far fa-file-export fw"
                /> Objekte exportieren</a>
              </drop-down-item>
              <drop-down-item>
                <hr>
              </drop-down-item>
              <drop-down-item>
                <a @click="openExportModalInstallations"><i class="far fa-file-export fw" /> Anlagen
                  exportieren</a>
              </drop-down-item>
              <drop-down-item v-if="modelValue.customerId != null">
                <a @click="openExportModalCustomerInventory"><i
                  class="far fa-file-export fw"
                /> Inventar exportieren</a>
              </drop-down-item>
            </drop-down>

            <drop-down classes="fx-btn-secondary">
              <drop-down-item>
                <a @click="openEditLocationModal(null)"><i class="fas fa-plus fa-fw" />Objekt
                  hinzufügen</a>
              </drop-down-item>
              <drop-down-item>
                <hr>
              </drop-down-item>
              <drop-down-item>
                <a @click="$emit('filter-passive')"><i class="fas fa-sitemap fa-fw" />Passive
                  Anlagen anzeigen</a>
              </drop-down-item>
            </drop-down>
          </div>
        </td>
      </tr>
    </table>
    <div v-if="filterInfo" class="secondary">
      {{ filterInfo }} <a
        v-tippy="{ content: 'Filter zurücksetzen', placement: 'top', duration: 100, delay: [300, null], arrow: true }"
        class="m-4 no-color"
        @click="$emit('reset-search')"
      ><i
        class="fas fa-times-circle"
      /></a>
    </div>
    <Location
      v-for="location in locations"
      :key="location.id"
      :location="location"
      :filter="modelValue"
      :allow-edit="canEdit"
      @add-installation="openAddInstallationModal"
      @edit-location="openEditLocationModal"
      @delete-location="confirmDeleteLocation"
      @quick-view="openQuickView"
    />

    <div v-if="loaded && !locations.length">
      <EmptyState :title="noResultsTitle" :hint="noResultsHint">
        <button v-if="filterApplied" class="fx-btn-secondary" @click="$emit('reset-search')">
          <i
            class="fas fa-times-circle"
          />&nbsp;&nbsp;Filter
          zurücksetzen
        </button>
      </EmptyState>
    </div>
    <Pagination v-model="pagination" i18n-key="activerecord.models.location" @navigate="loadPage" />
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import Flash from 'flash/index';
import openExportModal from 'helpers/open_export_modal';
import confirmViaModal from 'helpers/confirm_modal';
import { jobQuickView } from 'helpers/quickviews';

import { ref, watch } from 'vue';
import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';
import { useCurrentUserStore } from 'stores/current_user';

import FoxtagPaths from 'mixins/foxtag_paths';

import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';
import Pagination from 'components/pagination.vue';
import EmptyState from 'components/empty_state.vue';

import Location from './location.vue';
import AddInstallationModal from './modals/add_installation_modal.vue';
import EditLocationModal from './modals/edit_location_modal.vue';

export default {
  name: 'LocationsList',
  components: {
    EmptyState,
    Pagination,
    Location,
    DropDown,
    DropDownItem,
  },
  mixins: [FoxtagPaths],
  props: {
    showAddLocation: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    hasDueReplacementExport: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    noResultsTitle: {
      type: String,
      default: 'Keine Objekte / Anlagen gefunden',
    },
    noResultsHint: {
      type: String,
      default: 'Mit den aktuellen Filtereinstellungen wurden keine Objekte oder Anlagen gefunden',
    },
    filterInfo: {
      type: String,
      default: null,
    },
    // this is special: not all usages of locations list should try to save/load filter and pagination settings
    paginationFilterStorageKey: {
      type: String,
      default: null,
    },
    filterApplied: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['reset-search', 'filter-passive'],
  setup(props) {
    const loaded = ref(false);
    const locations = ref([]);
    const pagination = ref({ page: 1, limit: 25 });
    const userStore = useCurrentUserStore();

    if (props.paginationFilterStorageKey) {
      pagination.value = { ...userStore.pagination(props.paginationFilterStorageKey) };
    }

    const load = () => {
      loaded.value = false;
      axios.get('/locations/filtered.json', {
        params: {
          page: pagination.value.page,
          limit: pagination.value.limit,
          query: props.modelValue.query,
          zip: props.modelValue.zip,
          dtr: props.modelValue.dueTimerange,
          rtr: props.modelValue.replacementTimerange,
          passive: props.modelValue.passive,
          installation_type_id: props.modelValue.installationTypeId,
          label_id: props.modelValue.labelId,
          customer_id: props.modelValue.customerId,
          technician_id: props.modelValue.technicianId,
        },
      }).then((response) => {
        loaded.value = true;
        locations.value = response.data.locations;
        pagination.value.page = response.data.meta.current_page;
        pagination.value.pageCount = response.data.meta.total_pages;
        pagination.value.totalCount = response.data.meta.total_count;

        if (props.paginationFilterStorageKey) {
          userStore.updatePaginationFromMeta(props.paginationFilterStorageKey, response.data.meta);
          userStore.updateFilter(props.paginationFilterStorageKey, props.modelValue);
        }
      }).catch((err) => {
        if (err.message !== 'Request aborted') {
          Flash.error(err.message);
        }
      });
    };

    const loadPage = (changedPagination) => {
      pagination.value = { ...changedPagination };
      load();
    };

    const search = () => {
      locations.value = [];
      pagination.value.page = 1;
      load();
    };

    const debouncedSearch = _.debounce(() => {
      search();
    }, 250);

    const deleteViaApi = (location) => {
      axios.delete(`/locations/${location.id}.json`).then(() => {
        Flash.info('Objekt gelöscht');
        search();
      }).catch(() => {
        Flash.error('Objekt konnte nicht gelöscht werden');
      });
    };

    // --- MODALS

    const openQuickView = (jobId) => {
      jobQuickView(jobId);
    };

    const openEditLocationModal = (location, customerId = props.modelValue.customerId) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: EditLocationModal,
            attrs: {
              location,
              customerId,
              onClose() {
                modalInstance.close();
              },
              onCreated() {
                search();
              },
              onUpdated() {
                search();
              },
              onAddInstallation(xLocation) {
                // eslint-disable-next-line no-use-before-define
                openAddInstallationModal(xLocation);
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openAddInstallationModal = (location) => {
      let customerId = (location.customer_id || props.modelValue.customerId);
      if (location.customer) {
        customerId = location.customer.id;
      }

      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: AddInstallationModal,
            attrs: {
              location,
              customerId,
              locationId: location.id,
              installationTypeId: props.modelValue.installationTypeId,
              onClose() {
                modalInstance.close();
              },
              onCreated() {
                search();
              },
              onAddLocation(xCustomerId) {
                modalInstance.close();
                openEditLocationModal(null, xCustomerId);
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const confirmDeleteLocation = (location) => {
      confirmViaModal({
        message: 'Diese Aktion kann nicht rückgängig gemacht werden.',
        title: `Objekt ${location.number || ''} löschen?`,
      }).then(() => {
        deleteViaApi(location);
      });
    };

    const openExportModalInstallations = () => openExportModal({
      title: 'Anlagen-Export',
      exporttype: 'installations',
      config: props.modelValue,
    });

    const openExportModalLocations = () => openExportModal({
      title: 'Objekte-Export',
      exporttype: 'locations',
    });

    const openExportModalCustomerInventory = () => openExportModal({
      exportableId: props.modelValue.customerId,
      title: 'Inventar exportieren',
      exporttype: 'customers_installations',
    });

    watch(props.modelValue, () => {
      debouncedSearch();
    }, { deep: true });

    watch(() => props.modelValue, () => {
      debouncedSearch();
    }, { deep: true });

    return {
      locations,
      pagination,
      loadPage,
      openExportModalInstallations,
      openExportModalLocations,
      openExportModalCustomerInventory,
      openQuickView,
      openEditLocationModal,
      openAddInstallationModal,
      confirmDeleteLocation,
      debouncedSearch,
      loaded,
    };
  },
};
</script>
