<template>
  <div v-if="initialDataLoaded">
    <section class="fx-page-content">
      <section class="fx-header">
        <div class="fx-grid">
          <div class="fx-col" style="padding: 0 1rem;">
            <div v-if="localInstallation.next_job_id" class="fx-due-dates-box float-right" style="margin-left: .8rem;">
              <h3>Nächster Auftrag</h3>
              <a :href="nextJobPath">
                {{ truncate(localInstallation.next_job_type_name, 25) }}
                <br>
                {{ nextJobDate }}
              </a>
            </div>

            <div
              v-if="localInstallation.next_job_id == null"
              class="fx-due-dates-box float-right"
              style="margin-left: .8rem;"
            >
              <h3>Nächster Auftrag</h3>
              <a @click="openNewJobModal(localInstallation.id)">Auftrag planen</a>
            </div>

            <div v-if="localInstallation.next_check_maintenance" class="fx-due-dates-box float-right">
              <h3>Fälligkeiten</h3>
              <template v-if="localInstallation.passive">
                Anlage ist passiv
              </template>
              <template v-else>
                <NextCheckInfo
                  :next-date="localInstallation.next_check_maintenance.date"
                  :next-status="localInstallation.next_check_maintenance.status"
                />
                <NextCheckInfo
                  :next-date="localInstallation.next_replacement_maintenance.date"
                  :next-status="localInstallation.next_replacement_maintenance.status"
                  :is-replacement="true"
                />
              </template>
            </div>

            <ul class="fx-breadcrumps">
              <li><a href="/start">Start</a></li>
              <li><a href="/locations">Anlagen</a></li>
              <li>
                <a :href="`/locations/${localInstallation.location_id}`">{{ truncate(localInstallation.location.active_name, 50)
                }}</a>
              </li>
              <li>{{ installationType.name }}</li>
            </ul>

            <div class="fx-header-title">
              <h1 class="fx-header-title">
                <span class="fx-header-title_number">{{ installationTypeNumber }}</span>
                <span class="fx-header-title_name">{{ installationName }}</span>
              </h1>
            </div>

            <button :class="$tabClasses('tab-overview')" @click="$setActiveTab('tab-overview');">
              <i class="fas fa-sitemap" /> Anlage
            </button>
            <button :class="$tabClasses('tab-things')" @click="$setActiveTab('tab-things');">
              <i class="far fa-cube" /> Komponenten <span class="fx-tab-count">{{ localInstallation.things_count }}</span>
            </button>
            <button :class="$tabClasses('tab-jobs')" @click="$setActiveTab('tab-jobs');">
              <i class="far fa-calendar-check" /> Aufträge <span class="fx-tab-count">{{ localInstallation.jobs_count
              }}</span>
            </button>
            <button
              v-if="features.incidentsPro"
              :class="$tabClasses('tab-incidents')"
              @click="$setActiveTab('tab-incidents');"
            >
              <i class="fas fa-exclamation-triangle" /> Störungen <span class="fx-tab-count">{{
                localInstallation.open_incidents_count }}</span>
            </button>
            <button :class="$tabClasses('tab-notes')" @click="$setActiveTab('tab-notes');">
              <i class="fas fa-comment" /> Notizen <span class="fx-tab-count">{{ localInstallation.notes_count }}</span>
            </button>
            <button :class="$tabClasses('tab-attachments')" @click="$setActiveTab('tab-attachments');">
              <i class="fas fa-file" /> Dateien <span class="fx-tab-count">{{ localInstallation.attachments_count
              }}</span>
            </button>
            <button :class="$tabClasses('tab-sharing')" @click="$setActiveTab('tab-sharing');">
              <i class="fas fa-user" /> Freigaben
            </button>
            <button :class="$tabClasses('tab-analysis')" @click="$setActiveTab('tab-analysis');">
              <i class="fas fa-chart-pie-alt" /> Auswertungen
            </button>
          </div>
        </div>
      </section>

      <section class="list-content">
        <div class="fx-grid" style="margin-top: 0rem;">
          <div class="fx-col">
            <div v-if="$isTabInitialized('tab-overview')" v-show="$isTabActive('tab-overview')" class="tab-panel">
              <OverviewTab
                v-model="localInstallation"
                :can-edit="canEdit"
                :preset-labels="presetLabels"
                :installation-type="installationType"
                :customer="customer"
                :has-open-jobs="hasOpenJobs"
                :feature-calendric-intervals="featureCalendricIntervals"
                @changed="loadData()"
              />
            </div>

            <div v-if="$isTabInitialized('tab-things')" v-show="$isTabActive('tab-things')" class="tab-panel">
              <GroupsList
                :installation-id="localInstallation.id"
                :installation-type-id="localInstallation.installation_type_id"
                :start-collapsed="localInstallation.start_collapsed"
                :start-empty-state="localInstallation.start_empty_state"
                :default-group-id="localInstallation.default_group_id"
                :thing-label="installationType.active_thing_label"
                :group-label="installationType.active_group_label"
                :group-separator="installationType.group_separator"
                :tag-label="installationType.active_tag_label"
                :has-central-unit="installationType.has_central_unit"
                :has-stock="installationType.has_stock"
                :custom-dataset="installationType.custom_dataset"
                @changed="reloadCounts"
              />
            </div>

            <div v-if="$isTabInitialized('tab-jobs')" v-show="$isTabActive('tab-jobs')" class="tab-panel">
              <JobsTab :installation-id="localInstallation.id" :can-edit="canEdit" @changed="reloadCounts" />
            </div>

            <div
              v-if="$isTabInitialized('tab-incidents') && features.incidentsPro"
              v-show="$isTabActive('tab-incidents')"
              class="tab-panel"
            >
              <IncidentEmbeddedList :installation-id="localInstallation.id" :can-edit="canEdit" :show-quickview="true" @changed="reloadCounts" />
            </div>

            <div v-if="$isTabInitialized('tab-notes')" v-show="$isTabActive('tab-notes')" class="tab-panel">
              <InstallationNotesIndex :installation-id="localInstallation.id" @changed="reloadCounts" />
            </div>

            <div v-if="$isTabInitialized('tab-attachments')" v-show="$isTabActive('tab-attachments')" class="tab-panel">
              <!-- After talks with RGM also techn. needs to upload files, so can-edit is set to true -->
              <AttachmentsTab :installation-id="localInstallation.id" :can-edit="true" @changed="reloadCounts" />
            </div>

            <div v-if="$isTabInitialized('tab-sharing')" v-show="$isTabActive('tab-sharing')" class="tab-panel">
              <SharingTab
                :installation-id="localInstallation.id"
                :report-recipients="localInstallation.report_recipients"
                :auto-publish-reports="autoPublishReports"
                :can-edit="canEdit"
                :customer-name="customerName"
                :feature-portal="featurePortal"
              />
            </div>

            <div v-if="$isTabInitialized('tab-analysis')" v-show="$isTabActive('tab-analysis')" class="tab-panel">
              <TabAnalysis
                :installation-id="localInstallation.id"
              />
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>
<script>
import axios from 'axios';
import { useCurrentUserStore } from 'stores/current_user';

import FoxtagPaths from 'mixins/foxtag_paths';
import FeatureFlipper from 'mixins/feature_flipper';
import { TruncateFormatter, DateFormatter } from 'mixins/formatters';

import IncidentEmbeddedList from 'apps/incidents/embedded/index.vue';
import NextCheckInfo from 'components/partials/next_check_info.vue';
import openNewJobModal from 'helpers/open_new_job_modal';

import InstallationNotesIndex from '../notes/index.vue';
import GroupsList from '../groups/index.vue';
import JobsTab from './jobs_tab.vue';
import SharingTab from './sharing_tab.vue';
import AttachmentsTab from './attachments_tab.vue';
import OverviewTab from './overview_tab.vue';
import TabAnalysis from './tab_analysis.vue';

export default {
  name: 'ShowInstallation',
  components: {
    InstallationNotesIndex,
    GroupsList,
    JobsTab,
    IncidentEmbeddedList,
    SharingTab,
    AttachmentsTab,
    OverviewTab,
    NextCheckInfo,
    TabAnalysis,
  },
  mixins: [FoxtagPaths, FeatureFlipper, TruncateFormatter, DateFormatter],
  props: {
    installationId: {
      type: String,
      required: true,
    },
    autoPublishReports: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    presetLabels: {
      type: Array,
      required: true,
    },
    hasOpenJobs: {
      type: Boolean,
      required: true,
    },
    featureCalendricIntervals: {
      type: Boolean,
      default: false,
    },
    featurePortal: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {
      openNewJobModal,
      currentUser: useCurrentUserStore().currentUser,
    };
  },
  data() {
    return {
      initialDataLoaded: false,
      activeTab: null,
      initializedTabs: new Set(),
      installationType: {},
      customer: {},
      localInstallation: {
        location: {},
      },
    };
  },
  computed: {
    customerName() {
      if (this.customer) {
        return this.customer.name;
      }
      return '';
    },
    installationName() {
      return this.localInstallation.name || this.localInstallation.location.active_name;
    },
    installationTypeNumber() {
      if (this.localInstallation.number == null) {
        return this.installationType.name;
      }
      return `${this.installationType.name} ${this.localInstallation.number}`;
    },
    nextJobPath() {
      return `/jobs/${this.localInstallation.next_job_id}`;
    },
    nextJobDate() {
      if (this.localInstallation.next_job_planned_at) {
        return this.formatDate(this.localInstallation.next_job_planned_at, 'DD.MM.YYYY');
      }
      return '';
    },
  },
  mounted() {
    this.loadData();
    this.$setActiveTabFromURL('tab-overview');
  },
  methods: {
    reloadCounts() {
      const that = this;
      axios.get(`/installations/${this.localInstallation.id}/counts.json`).then((response) => {
        that.localInstallation.notes_count = response.data.notes_count;
        that.localInstallation.attachments_count = response.data.attachments_count;
        that.localInstallation.things_count = response.data.things_count;
        that.localInstallation.jobs_count = response.data.jobs_count;
        that.localInstallation.open_incidents_count = response.data.open_incidents_count;
      });
    },
    loadData() {
      const that = this;
      axios.get(`/installations/${this.installationId}`)
        .then((response) => {
          that.localInstallation = response.data.installation;
          that.installationType = response.data.installation_type;
          that.customer = response.data.customer;
          that.initialDataLoaded = true;
        });
    },
  },
};
</script>
