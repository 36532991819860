<template>
  <div class="fx-card" style="border: 1px solid #7099C9; margin-bottom: 0.7rem;">
    <div class="fx-card-content">
      <div class="fx-grid">
        <div class="fx-col" style="padding: 0;">
          <h2 class="fx">
            Ihre Testphase ({{ trialRemainingDays }} Tage)
          </h2>

          <p>
            Sie testen Foxtag <span class="license-type-label" :class="licenseTypeClass" style="font-size: 1rem;">{{
              localLicenseType }}</span>
          </p>

          <p>
            <span v-if="trialRemainingDays > 0">Ihre kostenlose Testphase läuft noch {{ trialRemainingDays }} Tage.</span>
            <span v-if="trialRemainingDays < 1"><strong>{{ $t('trial_over') }}</strong></span>
            <br><span v-if="userIsAdmin">Buchen Sie eine Nutzerlizenz, um Foxtag dauerhaft zu nutzen.</span>
          </p>
          <p>
            <a v-if="userIsAdmin" href="/licenses/new" class="fx-btn-ico-primary"><i class="fas fa-badge-check fa-fw" />
              Preise und Buchung</a>
          </p>

          <template v-if="showUpgradeToPro">
            <hr>
            <p>
              Testen Sie den erweiterten Funktionsumfang von Foxtag
              <span class="license-type-label license-type-pro" style="font-size: 1rem;">pro</span>
            </p>
            <button class="fx-btn-primary" @click="upgradeToPro()">
              Jetzt auf PRO wechseln
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import Flash from 'flash/index';
import { useCurrentUserStore } from 'stores/current_user';

export default {
  name: 'TrialCard',
  components: {
  },
  props: {
    trialRemainingDays: {
      type: Number,
      default: 0,
    },
    licenseType: {
      type: String,
      required: true,
    },
    organizationId: {
      type: String,
      required: true,
    },
  },
  setup() {
    return { currentUser: useCurrentUserStore().currentUser };
  },
  data() {
    return {
      localLicenseType: null,
    };
  },
  computed: {
    licenseTypeClass() {
      return `license-type-${this.localLicenseType}`;
    },
    showUpgradeToPro() {
      return ((this.localLicenseType === 'basic') && this.userIsAdmin);
    },
    userIsAdmin() {
      return _.includes(this.currentUser.roles, 'admin');
    },
  },
  mounted() {
    this.localLicenseType = this.licenseType;
  },
  methods: {
    upgradeToPro() {
      const that = this;
      axios.put(`/organizations/${this.organizationId}/upgrade_trial`).then(() => {
        that.localLicenseType = 'pro';
        Flash.info('Wechsel auf Foxtag Pro erfolgreich');
      }).catch(() => {
      });
    },
  },
};
</script>
