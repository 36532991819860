<template>
  <div>
    <div v-if="!editMode && sortedLocalJobArticles.length">
      <div class="fx-card">
        <div class="fx-card-content">
          <div v-if="!editMode" class="button-group float-right">
            <span style="margin-right: 5px;">
              <button :disabled="!canEdit" class="fx-btn-primary" @click="switchToEditMode()">Liste bearbeiten</button>
            </span>

            <DropDown :disabled="!canEdit" text="Import/Export" classes="fx-btn-secondary">
              <DropDownItem>
                <a v-if="canEdit && hasImport" :href="jobArticlesImportPath(jobId)">Liste importieren</a>
                <span v-else class="secondary">Liste importieren (pro)</span>
              </DropDownItem>
              <DropDownItem><a :href="jobArticlesExportPath(jobId, 'xlsx')">Liste exportieren (Excel)</a></DropDownItem>
              <DropDownItem><a :href="jobArticlesExportPath(jobId, 'csv')">Liste exportieren (CSV)</a></DropDownItem>
            </DropDown>
          </div>
          <h2 class="fx">
            {{ title }}
          </h2>
        </div>

        <table v-if="!editMode" class="material">
          <tbody>
            <tr class="material__title">
              <th style="width: 80px;">
                {{ $t('activerecord.attributes.job_article.line_number') }}
              </th>
              <th style="width: 150px;">
                {{ $t('activerecord.attributes.article.number') }}
              </th>
              <th style="width: 200px;">
                {{ $t('activerecord.attributes.article.group') }}
              </th>
              <th>{{ $t('activerecord.attributes.article.name') }}</th>
              <th
                style="width: 80px;
                text-align: right; padding-right: 5px;"
              >
                {{ $t('activerecord.attributes.job_article.quantity') }}
              </th>
              <th style="width: 40px;">
&nbsp;
              </th>
              <th>{{ $t('activerecord.attributes.job_article.comment') }}</th>
            </tr>
            <list-item
              v-for="(jobArticle, index) in localJobArticles"
              :key="index"
              :job-article="jobArticle"
              :index="index"
            />
          </tbody>
        </table>
      </div>
    </div>

    <div v-else-if="!editMode">
      <EmptyState
        title="Keine Artikelpositionen"
        hint="In diesem Auftrag wurden keine Artikelpositionen dokumentiert"
      >
        <div style="margin: 1rem 0;">
          <help-article article-id="learn_more_articles">
            So verwenden Sie Artikel zum Erfassen von Material und Leistungen
          </help-article>
        </div>

        <div class="fx-btn-group">
          <a v-if="canEdit" class="fx-btn-primary" @click="addFirstItem()">Position hinzufügen</a>
          <a
            v-if="canEdit && hasImport"
            :href="jobArticlesImportPath(jobId)"
            class="fx-btn-secondary"
          >Liste importieren</a>
        </div>
      </EmptyState>
    </div>

    <div v-if="editMode">
      <div class="fx-card" style="margin-bottom: 15rem;">
        <div class="fx-card-content">
          <h2 class="fx">
            {{ title }}
          </h2>
        </div>
        <table class="material">
          <tbody>
            <tr class="material__title">
              <th>Artikel</th>
              <th style="width: 120px;">
                {{ $t('activerecord.attributes.job_article.quantity') }}
              </th>
              <th style="width: 400px;">
                {{ $t('activerecord.attributes.job_article.comment') }}
              </th>
              <th style="width: 75px;" />
            </tr>
            <list-item-edit
              v-for="(jobArticle) in editJobArticles"
              :key="jobArticle.id"
              :job-article="jobArticle"
              @delete-item="deleteItem"
            />
            <tr>
              <td style="padding-left: 1.5rem;">
                <a @click="addNewRow()">
                  <i class="fas fa-plus" /> Position hinzufügen</a>
              </td>
            </tr>
          </tbody>
        </table>
        <div style="margin: 1rem;">
          <button class="fx-btn-secondary" @click="clearList()">
            Liste löschen
          </button>
          <div class="float-right">
            <div class="fx-btn-group">
              <TransactionButton
                v-model="saving"
                class="fx-btn-primary"
                title="Änderungen speichern"
                @click="updateAllJobArticles"
              />
              <a class="fx-btn-secondary" @click="editMode=false">abbrechen</a>
            </div>
          </div>
        </div>

        <div class="clearfix" />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import FoxtagPaths from 'mixins/foxtag_paths';
import Flash from 'flash/index';

import TransactionButton from 'components/transaction_button.vue';
import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';
import EmptyState from 'components/empty_state.vue';

import ListItem from './list_item.vue';
import ListItemEdit from './list_item_edit.vue';

export default {
  name: 'JobArticlesList',
  components: {
    ListItem,
    ListItemEdit,
    TransactionButton,
    DropDown,
    DropDownItem,
    EmptyState,
  },
  mixins: [FoxtagPaths],
  props: {
    jobId: {
      type: String,
      required: true,
    },
    jobArticles: {
      type: Array,
      required: true,
    },
    canEdit: {
      type: Boolean,
      required: true,
    },
    hasImport: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  emits: ['changed'],
  data() {
    return {
      localJobArticles: this.jobArticles,
      editJobArticles: [],
      editMode: false,
      newJobArticle: {
        article: {
          id: null,
        },
        quantity: '1',
        comment: null,
      },
      saving: false,
    };
  },
  computed: {
    sortedLocalJobArticles() {
      return this.localJobArticles.filter((jobArticle) => !jobArticle.deleted)
        .sort(this.sortJobArticles());
    },
  },
  methods: {
    clearList() {
      this.editJobArticles = [];
    },
    addFirstItem() {
      this.editMode = true;
      this.switchToEditMode();
      this.addNewRow();
    },
    addNewRow() {
      this.editJobArticles.push(JSON.parse(JSON.stringify(this.newJobArticle)));
    },
    switchToEditMode() {
      this.editJobArticles = JSON.parse(JSON.stringify(this.localJobArticles)); // deep clone array
      this.editMode = true;
    },
    deleteItem(jobArticle) {
      this.editJobArticles.splice(this.findIndex(jobArticle, this.editJobArticles), 1);
    },
    updateAllJobArticles() {
      this.saving = true;
      const formData = new FormData();

      for (let i = 0; i < this.editJobArticles.length; i += 1) {
        const jobArticle = this.editJobArticles[i];
        if (jobArticle.article.id) {
          formData.append('job_articles[][article_id]', jobArticle.article.id);
          formData.append('job_articles[][quantity]', jobArticle.quantity);
          formData.append('job_articles[][comment]', jobArticle.comment);
        }
      }

      const that = this;
      axios.post(`/jobs/${this.jobId}/update_articles`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        that.localJobArticles = response.data.job_articles;
        Flash.info('Änderung gespeichert');
        that.editMode = false;
        that.saving = false;
        this.$emit('changed');
      }).catch(() => {
        Flash.error('Die Änderung konnte nicht gespeichert werden');
        that.saving = false;
      });
    },
    findIndex(jobArticle, list) {
      return _.findIndex(list, { id: jobArticle.id });
    },
    sortJobArticles() {
      return this.orderByArticleProperty('number', 'position');
    },
    // --- refactor this stuff
    orderByArticleProperty(prop) {
      const args = Array.prototype.slice.call(arguments, 1);
      const vm = this;

      return (a, b) => {
        const equality = a.article[prop] - b.article[prop];
        if (equality === 0 && arguments.length > 1) {
          return vm.orderByArticleProperty.apply(null, args)(a, b);
        }
        return equality;
      };
    },
  },
};
</script>
