<template>
  <div>
    <div v-if="localChecklist.length" class="fx-list-row">
      <div class="fx-50px-col" style="width: 30px; margin-left: 1rem">
&nbsp;
      </div>
      <div class="fx-400px-col" style="width: 480px; margin-left: .2rem">
        <label class="inline mandatory">Feldname</label>
      </div>
      <div class="fx-150px-col" style="margin-left: .2rem">
        <label class="inline mandatory">Feldtyp</label>
      </div>
      <div class="fx-100px-col" style="margin-left: .2rem">
        <label class="inline">Einheit</label>
      </div>
      <div class="fx-150px-col" style="margin-left: .2rem">
        <label class="inline">Vorausgefüllt</label>
      </div>
      <div class="fx-50px-col" style="margin-left: .2rem">
        <label class="inline">Pflicht</label>
      </div>
    </div>
    <div v-for="(row, index) in localChecklist" :key="index" class="fx-list-row">
      <div class="fx-50px-col" style="width: 40px; line-height: 40px; margin-left: 1rem;">
        <a v-if="index > 0" @click="moveUp(index)"><i class="fas fa-arrow-up" /></a>
        <i v-else class="fas fa-arrow-up secondary" />
        <a v-if="index < localChecklist.length -1" @click="moveDown(index)"><i class="fas fa-arrow-down" style="margin-left: 8px;" /></a>
        <i v-else class="fas fa-arrow-down secondary" style="margin-left: 8px;" />
      </div>
      <div class="fx-400px-col" style="width: 480px; margin-left: .2rem">
        <input v-model="row.label" type="text" maxlength="500" style="margin: 0;">
      </div>

      <div class="fx-150px-col" style="margin-left: .2rem">
        <select v-model="row.type" style="margin: 0;" @change="changeType(row)">
          <option value="text">
            Text
          </option>
          <option value="numeric">
            Zahl
          </option>
          <option :value="headlineFieldType">
            Überschrift
          </option>
          <option value="checkbox">
            Checkbox
          </option>
          <option value="yesno">
            Ja | Nein
          </option>
          <option value="yesnona">
            Ja | Nein | n.v.
          </option>
          <option value="oknok">
            i.O. | n.i.O.
          </option>
          <option value="oknokna">
            i.O. | n.i.O. | n.v.
          </option>
        </select>
      </div>

      <div class="fx-100px-col" style="margin-left: .2rem">
        <input
          v-model="row.unit"
          type="text"
          style="margin: 0;"
          :disabled="unitDisabled(row)"
        >
      </div>

      <div class="fx-150px-col" style="margin-left: .2rem">
        <template v-if="row.type === 'checkbox'">
          <select v-model="row.value" style="margin: 0;">
            <option value="" />
            <option value="true">
              gecheckt
            </option>
          </select>
        </template>

        <template v-if="row.type === 'yesno' || row.type === 'yesnona'">
          <select v-model="row.value" style="margin: 0;">
            <option value="" />
            <option value="yes">
              mit 'Ja'
            </option>
            <option value="no">
              mit 'Nein'
            </option>
          </select>
        </template>

        <template v-if="row.type === 'oknok' || row.type === 'oknokna'">
          <select v-model="row.value" style="margin: 0;">
            <option value="" />
            <option value="ok">
              mit 'i.O.'
            </option>
            <option value="nok">
              mit 'n.i.O.'
            </option>
          </select>
        </template>

        <template v-if="row.type === 'text' || row.type === 'numeric' || row.type === 'group' || row.type === 'headline'">
          <input type="text" disabled="disabled" style="margin: 0;">
        </template>
      </div>

      <div class="fx-50px-col" style="line-height: 40px; margin-left: .2rem; padding-left: 1rem;">
        <input v-model="row.mandatory" :disabled="mandatoryDisabled(row)" type="checkbox">
      </div>

      <div class="fx-80px-col" style="line-height: 40px;">
        <a v-if="localChecklist.length > 1" @click="removeRow(index)">löschen</a>
      </div>
    </div>
    <div v-if="localChecklist.length < maxRows" class="fx-list-row" style="line-height: 40px;">
      <div class="fx-50px-col" style="width: 30px; margin-left: .2rem" />
      <div class="fx-220px-col">
        <strong><a @click="addRow()">
          <i class="fas fa-plus" /> Feld hinzufügen</a></strong>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'ChecklistDataset',
  components: {
  },
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    maxRows: {
      type: Number,
      default: 50,
    },
    headlineFieldType: {
      type: String,
      default: 'group',
    },
  },
  emits: ['changed'],
  data() {
    return {
      localChecklist: [],
    };
  },
  watch: {
    localChecklist: {
      handler() {
        this.$emit('changed', this.localChecklist);
      },
      deep: true,
    },
  },
  mounted() {
    this.localChecklist = Array.from(this.modelValue, (a) => a);
    if (this.localChecklist.length === 0) {
      this.addRow();
    }
  },
  methods: {
    unitDisabled(row) {
      if (row.type === 'numeric') {
        return false;
      }
      return true;
    },
    mandatoryDisabled(row) {
      if (row.type === 'group' || row.type === 'headline') {
        return true;
      }
      return false;
    },
    changeType(row) {
      row.unit = '';
      row.value = '';
      if (row.type === 'group' || row.type === 'headline') {
        row.mandatory = false;
      }
    },
    moveUp(index) {
      if (index < 1) { return; }

      const tmp = this.localChecklist.slice();
      this.localChecklist[index - 1] = tmp[index];
      this.localChecklist[index] = tmp[index - 1];
      this.$forceUpdate();
    },
    moveDown(index) {
      if (index >= this.localChecklist.length - 1) { return; }

      const tmp = this.localChecklist.slice();
      this.localChecklist[index + 1] = tmp[index];
      this.localChecklist[index] = tmp[index + 1];
      this.$forceUpdate();
    },
    addRow() {
      this.localChecklist.push({
        label: '', type: 'text', unit: null, value: null, mandatory: false,
      });
    },
    removeRow(index) {
      this.localChecklist.splice(index, 1);
    },
  },
};
</script>
