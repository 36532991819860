<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <h2 class="fx">
      Status hinzufügen
    </h2>
    <hr>

    <form @submit.prevent="submit">
      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory" for="incident_status">Status</label>
        </div>
        <div class="fx-form-content">
          <input
            id="incident_status_reported"
            v-model="status"
            type="radio"
            value="reported"
          >
          <label for="incident_status_reported">{{ $t('activerecord.attributes.incident_status.statuses.reported')
          }}</label>
          <input
            id="incident_status_opened"
            v-model="status"
            type="radio"
            value="opened"
          >
          <label for="incident_status_opened">{{ $t('activerecord.attributes.incident_status.statuses.opened') }}</label>
          <input
            id="incident_status_closed"
            v-model="status"
            type="radio"
            value="closed"
          >
          <label for="incident_status_closed">{{ $t('activerecord.attributes.incident_status.statuses.closed') }}</label>
        </div>
      </div>

      <div v-if="status == 'closed'" class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory" for="incident_status">Lösung</label>
        </div>

        <div class="fx-form-content">
          <input
            id="incident_resolution_resolved"
            v-model="resolution"
            type="radio"
            value="resolved"
          >
          <label for="incident_resolution_resolved">{{
            $t('activerecord.attributes.incident_status.resolutions.resolved')
          }}</label>

          <input
            id="incident_resolution_wontfix"
            v-model="resolution"
            type="radio"
            value="wontfix"
          >
          <label for="incident_resolution_wontfix">{{
            $t('activerecord.attributes.incident_status.resolutions.wontfix')
          }}</label>
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline" for="incident_statusdate">
            Datum<span style="color: red"> *</span> / Uhrzeit
          </label>
        </div>
        <div class="fx-form-content">
          <DateAndTimeInput v-model="statusdate" @changed="onChangeStatusdate" />
        </div>
      </div>

      <div class="fx-form-row">
        <label class="inline" for="incident_note">Hinweis am Status</label>
        <textarea
          id="incident_note"
          ref="noteTextArea"
          v-model="note"
          name="note"
          rows="10"
        />
      </div>

      <div class="fx-btn-group float-right">
        <a class="fx-btn-secondary" @click="$emit('close')">Abbrechen</a>
        <button class="fx-btn-primary " @click.prevent="createIncidentStatus">
          Speichern
        </button>
      </div>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash';
import { ref } from 'vue';
import DateAndTimeInput from 'components/date_and_time_input.vue';

export default {
  name: 'AddIncidentStatusModal',
  components: {
    DateAndTimeInput,
  },
  props: {
    incidentId: {
      type: String,
      required: true,
    },
    initStatus: {
      type: String,
      default: 'opened',
    },
  },
  emits: ['created', 'close'],
  setup(props, { emit }) {
    const note = ref(null);
    const resolution = ref('unresolved');
    const statusdate = ref(null);
    const status = ref(props.initStatus);

    const createIncidentStatus = () => {
      axios.post(`/incidents/${props.incidentId}/incident_statuses`, {
        statusdate: statusdate.value,
        status: status.value,
        note: note.value,
        resolution: resolution.value,
      }).then((response) => {
        emit('created', response.data.status);
        Flash.info('Status hinzugefügt');
      }).catch(() => {
        Flash.error('Fehler beim Anlegen des Status');
      });
    };

    return {
      note,
      resolution,
      statusdate,
      status,
      createIncidentStatus,
    };
  },
  watch: {
    status: {
      handler(newVal) {
        if (newVal === 'closed') {
          this.resolution = 'resolved';
        } else {
          this.resolution = 'unresolved';
        }
      },
    },
  },
  mounted() {
    if (this.status === 'closed') {
      this.resolution = 'resolved';
    } else {
      this.resolution = 'unresolved';
    }

    this.$refs.noteTextArea.focus();
  },
  methods: {
    onChangeStatusdate(value) {
      this.statusdate = value;
    },
  },
};
</script>
