<template>
  <section class="fx-page-content">
    <section class="fx-header">
      <div class="fx-grid">
        <div class="fx-col" style="padding: 0px 1rem;">
          <ul class="fx-breadcrumps">
            <li><a href="/organizations/settings">Administration</a></li>
            <li><a href="/organization_configs/reports">Berichte und Logo</a></li>
            <li>Berichtslayout</li>
          </ul>

          <div class="float-right">
            <a :href="backPath" class="fx-btn-secondary">abbrechen</a>
            <span style="margin-left: 0.4rem;">
              <button
                class="fx-btn-primary float-right"
                style="margin-bottom: 0.5rem;"
                :disabled="reportLayout.system"
                @click="updatViaAPI()"
              >Layout speichern</button>
            </span>
            <div class="clearfix" />
          </div>

          <div class="fx-header-title">
            <h1 class="fx-header-title">
              <span class="fx-header-title_number">Berichtslayout</span> <span
                class="fx-header-title_name"
              >{{ reportLayout.name }}</span>
            </h1>
          </div>
        </div>
      </div>
    </section>

    <div style="margin: 0 auto; max-width: 1200px; overflow-y: scroll; width: 1200px;">
      <div class="float-right mt-4">
        <button class="fx-btn-secondary" @click="openPreviewModal">
          Layout mit Auftrag testen
        </button>
      </div>
      <div class="clearfix" />

      <div style="margin-left: .2rem; margin-bottom: 5rem; margin-top: 1rem;">
        <div class="ml-4">
          <ServerErrors v-model="serverErrors" title="Layout konnte nicht gespeichert werden" margin-bottom="1rem" />

          <div class="fx-card fx-card-content">
            <div v-if="reportLayout.system" class="callout callout--info">
              <strong>Dies ist ein System-Layout von Foxtag.</strong><br>
              Sie können dieses Layout nicht verändern.
            </div>

            <div class="fx-form-row mt-4">
              <div class="fx-form-label">
                <label class="inline mandatory" for="customer_name">Layout-Name</label>
              </div>
              <div class="fx-form-content">
                <input
                  id="layout_name"
                  v-model="reportLayout.name"
                  maxlength="60"
                  name="layout_name"
                  :disabled="reportLayout.system"
                  type="text"
                  @change="pendingChanges = true"
                >
              </div>
            </div>

            <div v-if="features.canChangeLayoutEngine" class="fx-form-row mt-4">
              <div class="fx-form-label">
                <label class="inline mandatory" for="customer_name">Layout-Engine</label>
              </div>
              <div class="fx-form-content">
                <input
                  id="layout-engine-foxtag_report"
                  v-model="layoutEngine"
                  value="foxtag_report"
                  type="radio"
                  name="layout-engine"
                  @change="pendingChanges = true"
                ><label
                  for="layout-engine-foxtag_report"
                  class="inline"
                >Version 1</label>
                <input
                  id="layout-engine-exp_report"
                  v-model="layoutEngine"
                  value="exp_report"
                  type="radio"
                  name="layout-engine"
                  @change="pendingChanges = true"
                ><label
                  for="layout-engine-exp_report"
                  class="inline"
                >Version 2 (Neu)</label>
              </div>
            </div>

            <div class="fx-form-row">
              <div class="fx-form-label">
                <label class="inline mandatory" for="customer_name">Farbschema</label>
              </div>
              <div class="fx-form-content">
                <div style="display: grid; grid-template-columns: 100px 100px 100px 100px 100px;">
                  <div
                    v-for="scheme in color_schemes"
                    :key="scheme.id"
                    class="mb-4 color-chooser"
                    :class="classesForColor(scheme.id)"
                    @click="maybeChangeColorScheme(scheme.id)"
                  >
                    <div style="margin: 0; padding: 0; height: 20px; font-size: 0">
                      <div
                        style="display: inline-block; width: 10px; height: 20px;"
                        :style="`background: #${scheme.color_headlines}`"
                      />
                      <div
                        style="display: inline-block; width: 30px; height: 20px;"
                        :style="`background: #${scheme.color_lines}`"
                      />
                      <div
                        style="display: inline-block; width: 25px; height: 20px;"
                        :style="`background: #${scheme.color_bg_groups}`"
                      />
                    </div>
                    <div style="font-size: .7rem;">
                      {{ scheme.label }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="fx-form-row">
              <div class="fx-form-content">
                <input
                  id="shows_tags"
                  v-model="showsTags"
                  type="checkbox"
                  :disabled="reportLayout.system"
                  @change="pendingChanges = true"
                >
                <label class="inline" for="shows_tags">Foxtag-ID/QR-Codes werden abgedruckt</label>
                <br>
                <input
                  id="default_layout"
                  v-model="reportLayout.default"
                  type="checkbox"
                  class="mb-0"
                  :disabled="reportLayout.system"
                  @change="pendingChanges = true"
                >
                <label class="inline" for="default_layout">Als Standard verwenden</label>
                <div class="form-field-hint">
                  Standard-Layout wird für neue Auftragstypen automatisch verwendet
                </div>
              </div>
            </div>
          </div>

          <br>

          <h2 class="fx">
            Vorschau und Einstellungen
          </h2>
          <p style="max-width: 60rem;">
            Die Layout-Vorschau gibt Ihnen einen ersten Eindruck, wie der Bericht aufgebaut
            ist.
          </p>
        </div>

        <div v-for="(section, index) in localSections" :key="index">
          <SectionHeader
            v-if="section.key == 'header'"
            :index="index"
            :list-length="localSections.length"
            :colors="colors"
            :section="section"
            :organization="currentUser.organization"
            :company-logo-url="companyLogoUrl"
            :readonly="sectionsReadonly"
            :layout-engine="layoutEngine"
          />
          <SectionChecks
            v-if="section.key == 'checks'"
            :section="section"
            :index="index"
            :list-length="localSections.length"
            :colors="colors"
            :shows-tags="showsTags"
            :readonly="sectionsReadonly"
            :layout-engine="layoutEngine"
            @move-up="moveUp(index)"
            @move-down="moveDown(index)"
            @delete="deleteFromList(index)"
            @add="openAddSectionModal(index)"
          />
          <SectionArticles
            v-if="section.key == 'articles'"
            :section="section"
            :index="index"
            :list-length="localSections.length"
            :colors="colors"
            :readonly="sectionsReadonly"
            :layout-engine="layoutEngine"
            @move-up="moveUp(index)"
            @move-down="moveDown(index)"
            @delete="deleteFromList(index)"
            @add="openAddSectionModal(index)"
          />
          <SectionChecklists
            v-if="section.key == 'checklists'"
            :section="section"
            :index="index"
            :list-length="localSections.length"
            :colors="colors"
            :readonly="sectionsReadonly"
            :layout-engine="layoutEngine"
            @move-up="moveUp(index)"
            @move-down="moveDown(index)"
            @delete="deleteFromList(index)"
            @add="openAddSectionModal(index)"
          />
          <SectionPagebreak
            v-if="section.key == 'page_break'"
            :section="section"
            :index="index"
            :list-length="localSections.length"
            :colors="colors"
            :readonly="sectionsReadonly"
            :layout-engine="layoutEngine"
            @move-up="moveUp(index)"
            @move-down="moveDown(index)"
            @delete="deleteFromList(index)"
            @add="openAddSectionModal(index)"
          />
          <SectionSignatures
            v-if="section.key == 'signatures'"
            :section="section"
            :index="index"
            :list-length="localSections.length"
            :colors="colors"
            :organization="currentUser.organization"
            :readonly="sectionsReadonly"
            :layout-engine="layoutEngine"
            @move-up="moveUp(index)"
            @move-down="moveDown(index)"
            @delete="deleteFromList(index)"
            @add="openAddSectionModal(index)"
          />
          <SectionInfotext
            v-if="section.key == 'info_text'"
            :section="section"
            :index="index"
            :list-length="localSections.length"
            :colors="colors"
            :readonly="sectionsReadonly"
            :layout-engine="layoutEngine"
            @move-up="moveUp(index)"
            @move-down="moveDown(index)"
            @delete="deleteFromList(index)"
            @add="openAddSectionModal(index)"
          />
          <SectionPhotos
            v-if="section.key == 'photos'"
            :section="section"
            :index="index"
            :list-length="localSections.length"
            :colors="colors"
            :readonly="sectionsReadonly"
            :layout-engine="layoutEngine"
            @move-up="moveUp(index)"
            @move-down="moveDown(index)"
            @delete="deleteFromList(index)"
            @add="openAddSectionModal(index)"
          />
          <SectionFooter
            v-if="section.key == 'footer'"
            :section="section"
            :index="index"
            :list-length="localSections.length"
            :colors="colors"
            :organization="currentUser.organization"
            :readonly="sectionsReadonly"
            @add="openAddSectionModal(index)"
          />
          <SectionLifecycleEvents
            v-if="section.key == 'lifecycle_events'"
            :section="section"
            :index="index"
            :list-length="localSections.length"
            :colors="colors"
            :shows-tags="showsTags"
            :readonly="sectionsReadonly"
            :layout-engine="layoutEngine"
            @move-up="moveUp(index)"
            @move-down="moveDown(index)"
            @delete="deleteFromList(index)"
            @add="openAddSectionModal(index)"
          />
          <SectionIncidents
            v-if="section.key == 'incidents'"
            :section="section"
            :index="index"
            :list-length="localSections.length"
            :colors="colors"
            :shows-tags="showsTags"
            :readonly="sectionsReadonly"
            :layout-engine="layoutEngine"
            @move-up="moveUp(index)"
            @move-down="moveDown(index)"
            @delete="deleteFromList(index)"
            @add="openAddSectionModal(index)"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';
import _ from 'lodash';

import ServerErrors from 'components/partials/server_errors.vue';
import FeatureFlipper from 'mixins/feature_flipper';
import { useCurrentUserStore } from 'stores/current_user';

import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';
import { ref } from 'vue';

import SectionHeader from './section_header.vue';
import SectionFooter from './section_footer.vue';
import SectionChecklists from './section_checklists.vue';
import SectionArticles from './section_articles.vue';
import SectionChecks from './section_checks.vue';
import SectionPagebreak from './section_pagebreak.vue';
import SectionSignatures from './section_signatures.vue';
import SectionInfotext from './section_infotext.vue';
import SectionPhotos from './section_photos.vue';
import SectionLifecycleEvents from './section_lifecycle_events.vue';
import SectionIncidents from './section_incidents.vue';
import AddSectionModal from './add_section_modal.vue';
import PreviewModal from './preview_modal.vue';

export default {
  name: 'ReportsConfig',
  components: {
    ServerErrors,
    SectionHeader,
    SectionFooter,
    SectionChecklists,
    SectionArticles,
    SectionChecks,
    SectionPagebreak,
    SectionSignatures,
    SectionInfotext,
    SectionPhotos,
    SectionLifecycleEvents,
    SectionIncidents,
  },
  mixins: [FeatureFlipper],
  props: {
    companyLogoUrl: {
      type: String,
      required: true,
    },
    allSections: {
      type: Array,
      required: true,
    },
    reportLayout: {
      type: Object,
      required: true,
    },
    backPath: {
      type: String,
      default: '/organization_configs/reports',
    },
  },
  setup(props) {
    const localSections = ref(JSON.parse(JSON.stringify(props.reportLayout.sections)));
    const layoutEngine = ref(props.reportLayout.layout_engine);
    const currentSchemeId = ref(props.reportLayout.color_scheme);
    const showsTags = ref(props.reportLayout.shows_tags);

    const addSection = (params) => {
      const idxSection = _.findIndex(props.allSections, { key: params.key });

      localSections.value.splice(params.index, 0, JSON.parse(JSON.stringify(props.allSections[idxSection])));
    };

    const openAddSectionModal = (index) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: AddSectionModal,
            attrs: {
              index,
              sections: localSections.value,
              onClose() {
                modalInstance.close();
              },
              onAddSection(params) {
                addSection(params);
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openPreviewModal = () => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: PreviewModal,
            attrs: {
              previewLayoutId: props.reportLayout.id,
              reportLayout: {
                color_scheme: currentSchemeId.value,
                shows_tags: showsTags.value,
                sections: localSections.value,
                default: props.reportLayout.default,
                layout_engine: layoutEngine.value,
              },
              onClose() {
                modalInstance.close();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    return {
      currentUser: useCurrentUserStore().currentUser,
      openAddSectionModal,
      openPreviewModal,
      localSections,
      layoutEngine,
      currentSchemeId,
      showsTags,
    };
  },
  data() {
    return {
      previewJobId: null,
      pendingChanges: false,
      serverErrors: [],
      // localSections: [],
      color_schemes: [],
      // currentSchemeId: 'default',
      // layoutEngine: 'foxtag_report',
      // showsTags: true,
      colors: {
        color_text: '000000',
        color_lines: 'becad2',
        color_bg_groups: 'e4e9ec',
        color_headlines: '2c556e',
        color_col_headline_text: '000000',
      },
    };
  },
  computed: {
    sectionsReadonly() {
      return this.reportLayout.system;
    },
    previewTitle() {
      if (this.pendingChanges) {
        return 'Bitte erst Änderungen speichern';
      }
      return '';
    },
    selectedColors() {
      return this.color_schemes[this.findColorSchemeIndex(this.currentSchemeId)];
    },
  },
  watch: {
    localSections: {
      handler(newVal) {
        this.pendingChanges = true;
      },
      deep: true,
    },
  },
  mounted() {
    this.initColors();
    // this.currentSchemeId = this.reportLayout.color_scheme;
    this.name = this.reportLayout.name;
    // this.layoutEngine = this.reportLayout.layout_engine;
    this.showsTags = this.reportLayout.shows_tags;

    // Note: deep clone to be able to reset changes in this editor
    // this.localSections = JSON.parse(JSON.stringify(this.reportLayout.sections));

    this.changeColorScheme(this.currentSchemeId);
    this.$nextTick(() => {
      this.pendingChanges = false;
    });
  },
  methods: {
    // openAddSectionModal(index) {
    //   this.$modal.show('add-section-modal', {
    //     index,
    //   });
    // },
    // openPreviewModal() {
    //   this.$modal.show('preview-modal', {
    //     report_layout: {
    //       color_scheme: this.currentSchemeId,
    //       shows_tags: this.showsTags,
    //       sections: this.localSections,
    //       default: this.reportLayout.default,
    //       layout_engine: this.layoutEngine,
    //     },
    //   });
    // },
    // addSection(params) {
    //   const idxSection = _.findIndex(this.allSections, { key: params.key });

    //   this.localSections.splice(params.index, 0, JSON.parse(JSON.stringify(this.allSections[idxSection])));
    // },
    findColorSchemeIndex(schemeId) {
      return _.findIndex(this.color_schemes, { id: schemeId });
    },
    maybeChangeColorScheme(schemeId) {
      if (!this.reportLayout.system) {
        this.changeColorScheme(schemeId);
      }
    },
    changeColorScheme(schemeId) {
      this.currentSchemeId = schemeId;
      this.colors = this.color_schemes[this.findColorSchemeIndex(this.currentSchemeId)];
      this.pendingChanges = true;
    },
    moveUp(index) {
      if (index < 1) { return; }

      const tmp = this.localSections.slice();
      this.localSections[index - 1] = tmp[index];
      this.localSections[index] = tmp[index - 1];
      this.$forceUpdate();
      this.pendingChanges = true;
    },
    moveDown(index) {
      if (index >= this.localSections.length - 1) { return; }

      const tmp = this.localSections.slice();
      this.localSections[index + 1] = tmp[index];
      this.localSections[index] = tmp[index + 1];
      this.$forceUpdate();
      this.pendingChanges = true;
    },
    deleteFromList(index) {
      this.localSections.splice(index, 1);
      this.$forceUpdate();
    },
    updatViaAPI() {
      const that = this;
      that.serverErrors = [];
      axios.put(`/report_layouts/${this.reportLayout.id}.json`, {
        report_layout: {
          color_scheme: this.currentSchemeId,
          name: this.reportLayout.name,
          shows_tags: this.showsTags,
          sections: this.localSections,
          default: this.reportLayout.default,
          layout_engine: this.layoutEngine,
        },
      }).then((response) => {
        this.pendingChanges = false;
        new Flash('Layout gespeichert').show();
        window.location.href = '/organization_configs/reports';
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.report_layouts;
        } else {
          new Flash('Fehler beim Speichern', { isError: true }).show();
        }
      });
    },
    classesForColor(schemeId) {
      if (this.currentSchemeId == schemeId) {
        return 'color-chooser-selected';
      }
      return '';
    },
    initColors() {
      this.color_schemes = [
        {
          id: 'default',
          label: 'Standard',
          color_text: '000000',
          color_lines: 'becad2',
          color_bg_groups: 'e4e9ec',
          color_headlines: '2c556e',
          color_col_headline_text: '000000',
        },
        {
          id: 'blue_dark',
          label: 'blau',
          color_text: '000000',
          color_lines: '2c556e',
          color_bg_groups: 'e4e9ec',
          color_headlines: '2c556e',
          color_col_headline_text: 'ffffff',
        },
        {
          id: 'gray_blue',
          label: 'grau-blau',
          color_text: '000000',
          color_lines: 'd8d8d8',
          color_bg_groups: 'ededed',
          color_headlines: '1A5977',
          color_col_headline_text: '000000',
        },
        {
          id: 'gray_light',
          label: 'grau (hell)',
          color_text: '000000',
          color_lines: 'd8d8d8',
          color_bg_groups: 'ededed',
          color_headlines: '444444',
          color_col_headline_text: '000000',
        },
        {
          id: 'gray_dark',
          label: 'grau (dunkel)',
          color_text: '000000',
          color_lines: '545454',
          color_bg_groups: 'dfdfdf',
          color_headlines: '444444',
          color_col_headline_text: 'ffffff',
        },
        {
          id: 'black_white',
          label: 'schwarz',
          color_text: '000000',
          color_lines: '000000',
          color_bg_groups: 'cccccc',
          color_headlines: '000000',
          color_col_headline_text: 'ffffff',
        },
        {
          id: 'green_light',
          label: 'grün (hell)',
          color_text: '000000',
          color_lines: '6d9537',
          color_bg_groups: 'bccca7',
          color_headlines: '4d5355',
          color_col_headline_text: 'ffffff',
        },
        {
          id: 'green_dark',
          label: 'grün (dunkel)',
          color_text: '000000',
          color_lines: '4c6b23',
          color_bg_groups: 'b3c49d',
          color_headlines: '444444',
          color_col_headline_text: 'ffffff',
        },
        {
          id: 'red_dark',
          label: 'rot',
          color_text: '000000',
          color_lines: '8f2411',
          color_bg_groups: 'e3e3e3',
          color_headlines: '444444',
          color_col_headline_text: 'ffffff',
        },
        {
          id: 'pixie',
          label: 'pixie',
          color_text: '373737',
          color_lines: 'dd8c8c',
          color_bg_groups: 'fad2d2',
          color_headlines: 'ba1b33',
          color_col_headline_text: '222222',
        },
      ];
    },
  },
};
</script>
