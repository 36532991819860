<template>
  <div class="fx-flex-row">
    <div class="fx-flex-row-ico">
      <i class="fas fa-calendar-check" />
    </div>
    <div class="fx-flex-row-main">
      <div class="fx-flex-row-title">
        <a class="no-color mr-2" :href="jobPath(job.id)">
          <span class="fx-flex-row-title-number">{{ job.number }}</span>
          {{ job.job_type_name }} {{ formatDate(job.planned_at, 'DD.MM.YYYY') }} {{ plannedAtTime }}</a>
        <Labels :labels="job.labels" :link="job.link" />
      </div>

      <div class="fx-flex-row-subline">
        <span v-if="job.status" :class="'fx-status ' + job.status">
          {{ $t('job.status.' + job.status) }}
        </span>

        <span>
          <template v-if="job.pooling"><img
            src="/jeder.svg"
            height="24px"
            width="42px"
            style="vertical-align: middle;"
            title="Jeder Nutzer kann diesen Auftrag durchführen"
          ></template>
          <template v-else>
            <img
              v-for="user in job.assigned_users"
              :key="user.id"
              v-tippy="{ placement: 'top', duration: 100, arrow: true }"
              :src="user.avatar_path"
              width="24px"
              height="24px"
              style="vertical-align: middle;"
              :title="user.full_name"
            >
          </template>
        </span>

        <span class="fx-ico-text fx-ico-text-installation">
          <a :href="installationUrl" class="no-color">
            <strong>{{ truncate(job.installation_number, maxNumberLength) }}</strong> {{ truncate(job.installation_name,
                                                                                                  maxNameLength) }}
          </a>
        </span>

        <span class="fx-ico-text fx-ico-text-location">
          <a :href="locationUrl" class="no-color">
            <strong>{{ truncate(job.location_number, maxNumberLength) }}</strong> {{
              truncate(job.location_name, maxNameLength) }}
          </a>
        </span>

        <span
          v-if="job.location_address_one_line && job.location_address_one_line.length"
          class="fx-ico-text fx-ico-text-map"
        >
          <template v-if="showLocateMe"><a class="no-color" @click="emitLocateMe()">{{ job.location_address_one_line
          }}</a></template><template v-else>{{
            truncate(job.location_address_one_line, 100) }}</template>
        </span>
      </div>
      <div v-if="job.comment" class="fx-flex-row-comment">
        <i class="fas fa-info-circle" />
        <span style="margin-left: 0.2rem;">{{ job.comment }}</span>
      </div>
    </div>

    <div class="fx-flex-row-nav">
      <div style="width: 120px; color: #666;">
        <a v-if="job.incidents_count" class="no-color" :href="incidentsUrl"><span
          style="margin-right: 0.4rem; white-space: nowrap;"
        ><i class="fas fa-exclamation-triangle" /> {{
          job.incidents_count }}</span>
        </a>
        <a v-if="job.photos_count" class="no-color" :href="photosUrl">
          <span style="margin-right: 0.4rem; white-space: nowrap;">
            <i class="fas fa-camera" /> {{ job.photos_count }}
          </span>
        </a>
        <a v-if="job.notes_count" class="no-color" :href="notesUrl">
          <span style="margin-right: 0.4rem; white-space: nowrap;">
            <i class="fas fa-comment" /> {{ job.notes_count }}
          </span>
        </a>
        <a v-if="job.report_available" class="no-color" :href="reportDownloadUrl">
          <span style="white-space: nowrap; margin-right: 0.5rem;">
           <br><i class="fas fa-file" /> Bericht
          </span>
        </a>
      </div>
      <a class="fx-btn-skeleton" @click="$emit('quickview', job.id)"><i class="fas fa-eye" /></a>
      <!--<a class="fx-btn-skeleton" :href="jobPath(job.id)">Details</a>-->
      <drop-down v-if="allowEditing">
        <drop-down-item>
          <a :href="jobPath(job.id)"><i class="far fa-calendar-check fa-fw" /> Auftrag ansehen</a>
        </drop-down-item>
        <drop-down-item>
          <hr>
        </drop-down-item>
        <drop-down-item>
          <a :href="jobIcalPath(job.id)"><i class="far fa-calendar-plus fa-fw" /> Als Termin herunterladen (ical)</a>
        </drop-down-item>
        <drop-down-item>
          <hr>
        </drop-down-item>
        <job-status-options
          :job-status="job.status"
          @change-status="onChangeStatus"
          @confirm-deletion="$emit('openConfirmDeleteJobModal', job)"
        />
      </drop-down>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Labels from 'components/partials/labels.vue';

import FoxtagPaths from 'mixins/foxtag_paths';
import { DateFormatter, TruncateFormatter } from 'mixins/formatters';

import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';
import JobStatusOptions from 'components/partials/job_status_options.vue';

export default {
  name: 'JobListItem',
  components: {
    DropDown,
    DropDownItem,
    JobStatusOptions,
    Labels,
  },
  mixins: [FoxtagPaths, DateFormatter, TruncateFormatter],
  props: {
    job: {
      type: Object,
      required: true,
    },
    allowEditing: {
      type: Boolean,
      required: true,
    },
    showLocateMe: {
      type: Boolean,
      required: false,
    },
    maxNumberLength: {
      type: Number,
      default: 12,
    },
    maxNameLength: {
      type: Number,
      default: 30,
    },
  },
  emits: ['changeStatus', 'openConfirmDeleteJobModal', 'quickview'],
  computed: {
    jobUrl() {
      return `/jobs/${this.job.id}`;
    },
    installationUrl() {
      return `/installations/${this.job.installation_id}`;
    },
    locationUrl() {
      return `/locations/${this.job.location_id}`;
    },
    reportDownloadUrl() {
      return `/jobs/${this.job.id}/download_report`;
    },
    notesUrl() {
      return `/jobs/${this.job.id}#tab-notes`;
    },
    incidentsUrl() {
      return `/jobs/${this.job.id}#tab-incidents`;
    },
    photosUrl() {
      return `/jobs/${this.job.id}#tab-photos`;
    },
    plannedAtDate() {
      return moment(this.job.planned_at).format('DD.MM.YYYY');
    },
    plannedAtTime() {
      if (!this.job.all_day) {
        return `${moment(this.job.planned_at).format('HH:mm')} Uhr`;
      }
      return null;
    },
  },
  mounted() {
  },
  methods: {
    onChangeStatus(status) {
      this.$emit('changeStatus', this.job, status);
    },
    emitLocateMe() {
      this.$emit('locate-me', [this.job.location_address.lng, this.job.location_address.lat]);
    },
  },
};
</script>

<style></style>
