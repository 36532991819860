<template>
  <div>
    <template v-if="customer != null">
      <a v-if="link" :href="customerUrl" class="no-color">
        <div class="secondary">{{ customer.number }}</div>
        {{ customer.name }}
        <Address :address="customer.address" />
      </a>
      <template v-else>
        <div class="secondary">
          {{ customer.number }}
        </div>
        {{ customer.name }}
        <Address :address="customer.address" />
      </template>
    </template>
    <span v-if="customer == null" class="secondary">Kein Kunde zugeordnet</span>
  </div>
</template>

<script>
import Address from 'components/partials/address.vue';

export default {
  components: {
    Address,
  },
  props: {
    customer: {
      type: Object,
      default: null,
    },
    link: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    customerUrl() {
      return `/customers/${this.customer.id}`;
    },
  },
};
</script>
