<template>
   <div class="fx-modal-content-medium">
    <div v-if="reportLayout" style="min-height: 300px">
      <a class="fx-modal__close" @click="$emit('close')">&times;</a>
      <h2 class="fx">
        {{ reportLayout.name }}
      </h2>
      <p>Dieses Layout wird von {{ reportLayout.job_types.length }} Auftragstypen verwendet:</p>
      <table class="material">
        <tr><th>Wartungsanwendung</th><th>Auftragstyp</th></tr>
        <tr v-for="jobType in reportLayout.job_types" :key="jobType.id">
          <td><i class="fas fa-drafting-compass fa-fw" /> {{ jobType.installation_type_name }}</td>
          <td><a :href="jobTypePath(jobType)">{{ jobType.name }}</a></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportLayoutJobTypesModal',
  components: {
  },
  props: {
    reportLayout: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  methods: {
    jobTypePath(jobType) {
      return `/job_types/${jobType.id}/edit#report-settings`;
    },
  },
};
</script>
