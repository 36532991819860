<template>
  <div class="fx-modal-content-medium">
  <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <h2 class="fx">
      {{ title }}
    </h2>

    <hr style="margin-top:0;">
    <ServerErrors v-model="serverErrors" title="Ereignis konnte nicht gespeichert werden" margin-bottom="1rem" />

    <form @submit.prevent="submit">
      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory" for="group_number">Zeitpunkt</label>
        </div>
        <div class="fx-form-content">
          <div style="display: inline-block; width: 180px;">
            <VueDatePicker
              v-model="eventdate"
              :locale="$i18n.locale"
              :auto-apply="true"
              model-type="yyyy-MM-dd"
              :enable-time-picker="false"
              :format="'dd.MM.yyyy'"
              :disabled-dates="isFutureDate"
            />
          </div>
          <div style="display: inline-block; width: 70px; margin-left: 8px;">
            <time-input id="eventdate-time" v-model="eventdateTime" @input="event => eventdateTime = event" />
          </div>
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory" for="group_name">Ereignis</label>
        </div>
        <div class="fx-form-content">
          <select v-model="eventtype" :disabled="!isCreate">
            <option value="check">
              Prüfung durchgeführt
            </option>
            <!--<option value="installation">Installation</option>-->
            <!-- triggered option only to show trigger events, not allowed when creating events via form -->
            <option v-if="!isCreate" value="triggered">
              Ausgelöst
            </option>
          </select>
        </div>
      </div>

      <div v-if="eventtype === 'check'" class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory" for="group_name">Ergebnis</label>
        </div>
        <div class="fx-form-content">
          <select v-model="result">
            <option value="success">
              OK
            </option>
            <option value="failed">
              Mangel
            </option>
          </select>
        </div>
      </div>

      <hr>
      <button
        id="submit-edit-group"
        type="submit"
        class="fx-btn-primary float-right"
        style="margin-bottom: 0.5rem; margin-left: 0.5rem;"
      >
        Speichern
      </button>
      <button class="fx-btn-secondary float-right" @click.prevent="$emit('close')">
        Abbrechen
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';
import moment from 'moment';

import ServerErrors from 'components/partials/server_errors.vue';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import TimeInput from 'components/time_input.vue';
import { ref } from 'vue';

export default {
  name: 'EditEventModal',
  components: {
    ServerErrors,
    VueDatePicker,
    TimeInput,
  },
  props: {
    installationId: {
      type: String,
      required: true,
    },
    thingId: {
      type: String,
      required: true,
    },
    event: {
      type: Object,
      default: null,
    },
  },
  emits: ['created', 'updated', 'close'],
  setup(props, { emit }) {
    const eventdateTime = ref('08:00');

    if (props.event) {
      eventdateTime.value = moment(props.event.eventdate).format('HH:mm');
    }

    return {
      eventdateTime,
    };
  },
  data() {
    return {
      eventId: null,
      eventtype: 'check',
      eventdate: new Date().toISOString().split('T')[0],
      // eventdateTime: '08:00',
      result: 'success',
      serverErrors: [],
    };
  },
  computed: {
    title() {
      if (this.isCreate) {
        return 'Ereignis hinzufügen';
      }
      return 'Ereignis bearbeiten';
    },
    isCreate() {
      return !this.eventId;
    },
  },
  mounted() {
    if (this.event) {
      this.eventId = this.event.id;
      this.eventdate = moment(this.event.eventdate).format('YYYY-MM-DD');
      this.eventdateTime = moment(this.event.eventdate).format('HH:mm');

      this.eventtype = this.event.eventtype;
      this.result = this.event.result;
    }
  },
  methods: {
    // --- used by date pick
    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },
    submit() {
      if (this.isCreate) {
        this.createViaAPI();
      } else {
        this.updateViaAPI();
      }
    },
    eventDateTimeString() {
      return `${this.eventdate}T${this.eventdateTime}`;
    },
    createViaAPI() {
      const that = this;
      that.serverErrors = [];
      axios.post(`/things/${this.thingId}/events.json`, {
        event: {
          eventdate: this.eventDateTimeString(),
          eventtype: this.eventtype,
          result: this.result,
        },
      }).then((response) => {
        Flash.info('Ereignis hinzugefügt');
        this.$emit('created', { ...response.data.event });
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.events;
        } else {
          Flash.error('Ereignis konnte nicht gespeichert werden');
        }
      });
    },
    updateViaAPI() {
      const that = this;
      that.serverErrors = [];
      axios.put(`/things/${this.thingId}/events/${this.eventId}.json`, {
        event: {
          eventdate: this.eventDateTimeString(),
          eventtype: this.eventtype,
          result: this.result,
        },
      }).then((response) => {
        Flash.info('Ereignis gespeichert');
        this.$emit('updated', { ...response.data.event });
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.events;
        } else {
          Flash.error('Die Änderung konnte nicht gespeichert werden');
        }
      });
    },
  },
};
</script>
