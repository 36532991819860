<template>
  <div>
    <section class="list-content">
      <div class="fx-grid">
        <div class="fx-col" style="padding-top: 0;">
          <div style="text-align: right; margin-bottom: .5rem;">
            <div class="fx-btn-group">
              <a id="add-unassigned-incident" class="fx-btn-secondary" @click.prevent="assignAll()">Alle ohne Zielauftrag einplanen</a>
              <a id="add-assigned-incident" class="fx-btn-secondary" @click.prevent="confirmMoveAll()">Alle mit anderem Zielauftrag in diesen
                Auftrag</a>
              <a id="unassign-all-incident" class="fx-btn-secondary" @click.prevent="unAssignAll()">Alle ausplanen</a>
            </div>
          </div>

          <div class="fx-card" style="clear: both;">
            <div id="incidents-list-header" class="fx-list-row">
              <div class="fx-50px-col">
                <strong>#</strong>
              </div>
              <div class="fx-220px-col">
                <strong>Störung</strong>
              </div>
              <div class="fx-220px-col">
                <strong>Status</strong>
              </div>
              <div class="fx-200px-col">
                <strong>Komponente</strong>
              </div>
              <div class="fx-220px-col">
                <strong>Anderer Zielauftrag</strong>
              </div>
              <div class="fx-single-control-col">
                <strong>Eingeplant?</strong>
              </div>
            </div>

            <IncidentForPlanning
              v-for="incident in localIncidents"
              :key="incident.id"
              :incident="incident"
              :job-id="jobId"
              @changed="rowChanged"
            />
            <div v-if="showNoResults" style="padding: 1rem; text-align: center;">
              Keine Störungen gefunden
            </div>
          </div>

          <Pagination v-model="pagination" i18n-key="activerecord.models.incident" @navigate="loadPage" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import axios from 'axios';
import Flash from 'flash/index';

import { ref, computed } from 'vue';
import confirmViaModal from 'helpers/confirm_modal';
import Pagination from 'components/pagination.vue';

import IncidentForPlanning from './incident_for_planning.vue';

export default {
  name: 'IncidentList',
  components: {
    Pagination,
    IncidentForPlanning,
  },
  props: {
    jobId: {
      type: String,
      required: true,
    },
    installationId: {
      type: String,
      required: true,
    },
  },
  emits: ['changed'],
  setup(props, { emit }) {
    const loaded = ref(false);
    const localIncidents = ref([]);
    const filter = ref({
      timerange: null,
      severity: null,
      assignedJob: null,
      status: 'reported_opened',
      customerId: null,
      installationId: null,
    });
    const pagination = ref({
      page: 1,
      pageCount: 1,
      totalCount: 0,
      limit: 25,
    });

    const load = () => {
      loaded.value = false;
      axios.get('/incidents/filtered', {
        params: {
          severity: filter.value.severity,
          status: filter.value.status,
          assigned_job: filter.value.assignedJob,
          installation_id: props.installationId,
          label_id: filter.value.labelId,
          timerange: filter.value.timerange,
          with_thing_details: true,
          page: pagination.value.page,
          limit: pagination.value.limit,
        },
      }).then((response) => {
        loaded.value = true;
        localIncidents.value = response.data.incidents;
        pagination.value.page = response.data.meta.current_page;
        pagination.value.pageCount = response.data.meta.total_pages;
        pagination.value.totalCount = response.data.meta.total_count;
      }).catch((err) => {
        if (err.message !== 'Request aborted') {
          Flash.error(err.message);
        }
      });
    };

    const loadPage = (changedPagination) => {
      pagination.value = { ...changedPagination };
      load();
    };

    const search = () => {
      localIncidents.value = [];
      pagination.value.page = 1;
      load();
    };

    const resetSearch = () => {
      filter.value = {
        query: null,
      };
    };

    const moveAll = () => {
      axios.post(`/jobs/${props.jobId}/move_assigned_incidents`)
        .then(() => {
          emit('changed');
          search();
        }).catch(() => {
          Flash.error('Störungen konnten nicht eingeplant werden.');
        });
    };

    const confirmMoveAll = () => {
      confirmViaModal({
        title: 'Alle Störungen verschieben?',
        message: 'Alle offenen Störungen der Anlage, die bereits in einen anderen Auftrag eingeplant sind, werden in diesen Auftrag verschoben.',
        action: 'Verschieben',
      }).then(() => {
        moveAll();
      });
    };

    const showNoResults = computed(() => (loaded.value && (localIncidents.value.length === 0)));

    return {
      localIncidents,
      filter,
      pagination,
      load,
      loadPage,
      search,
      resetSearch,
      confirmMoveAll,
      showNoResults,
    };
  },
  methods: {
    assignAll() {
      const that = this;
      axios.post(`/jobs/${this.jobId}/assign_open_unassigned_incidents`)
        .then(() => {
          this.$emit('changed');
          that.search();
        }).catch(() => {
          Flash.error('Störungen konnten nicht eingeplant werden.');
        });
    },
    unAssignAll() {
      const that = this;
      axios.post(`/jobs/${this.jobId}/unassign_open_incidents`)
        .then(() => {
          this.$emit('changed');
          that.search();
        }).catch(() => {
          Flash.error('Störungen konnten nicht ausgeplant werden.');
        });
    },
    rowChanged() {
      this.$emit('changed');
    },
  },
};
</script>
