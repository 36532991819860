<template>
  <tr>
    <td>
      <DBBasedSelect
        v-model="localModelValue.article.id"
        icon-id="article"
        :default-label="$t('comp.select.no-selection')"
        i18n-base-key="articles"
        load-options-url="/select/articles"
        @changed="onArticleSelected"
      />
      <!-- <SelectArticle v-model="jobArticle.article.id" /> -->
    </td>
    <td class="input-no-margin">
      <FloatingNumberInput
        v-model="localModelValue.quantity"
      />
    </td>
    <td>
      <input v-model="localModelValue.comment" type="text" style="margin:0; box-shadow: unset;">
    </td>
    <td><a @click.prevent="deleteItem">löschen</a></td>
  </tr>
</template>

<script>

import FloatingNumberInput from 'components/floating_number_input.vue';
import DBBasedSelect from 'components/select/db_based_select.vue';

export default {
  name: 'JobArticlesEditListItem',
  components: {
    DBBasedSelect,
    FloatingNumberInput,
  },
  props: {
    jobArticle: {
      type: Object,
      required: true,
    },
  },
  emits: ['deleteItem', 'update:modelValue'],
  data() {
    return {
      localModelValue: this.jobArticle,
    };
  },
  mounted() {
    this.localModelValue = this.jobArticle;
  },
  methods: {
    onArticleSelected(articleId) {
      this.localModelValue.article.id = articleId;
    },
    deleteItem() {
      this.$emit('deleteItem', this.jobArticle);
    },
  },
};
</script>
