<template>
  <div class="fx-modal-content-medium">
    <h2 class="fx">
      Termin-Ankündigung bearbeiten
    </h2>
    <p>Termin-Ankündigungen werden automatisch einige Tage vor dem Termin an den Kontakt verschickt.
      <help-article article-id="learn_more_job_announcements">
        Mehr erfahren
      </help-article>
    </p>
    <div>
      <ServerErrors v-model="serverErrors" title="Änderung konnte nicht gespeichert werden" margin-bottom="0" />

      <label class="inline">Kontakt</label>
      <DBBasedSelect
        :key="contactSelectKey"
        v-model="contactId"
        :readonly="!canEdit"
        icon-id="contact"
        i18n-base-key="contacts"
        :default-label="$t('comp.select.no-selection')"
        load-options-url="/contacts/for_select?with_email=true"
        @changed="onContactSelected"
      />
      <div v-if="canEdit" class="form-field-hint">
        Wählen Sie aus Ihren Kontakten, oder fügen Sie einen  <a @click="openAddContactModal()">neuen Kontakt hinzu.</a>
      </div>

      <div v-if="!canEdit" class="callout callout--info mt-4">
        Die Termin-Ankündigung kann nicht mehr verändert werden, wenn der Auftrag läuft oder erledigt ist.
      </div>
    </div>

    <hr class="fx">
    <div style="text-align: right;">
      <div class="fx-btn-group">
        <button
          class="fx-btn-primary"
          style="margin-left: 0.5rem;"
          @click.prevent="updateJob()"
        >
          Speichern
        </button>

        <button class="fx-btn-secondary" @click.prevent="cancel()">
          Abbrechen
        </button>
      </div>
      <div class="clearfix" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';
import { ref, onMounted } from 'vue';
import { VueFinalModal, useModal, useModalSlot } from 'vue-final-modal';
import ServerErrors from 'components/partials/server_errors.vue';
import DBBasedSelect from 'components/select/db_based_select.vue';
import AddCreateContactModal from 'apps/contacts/add_create_modal.vue';

export default {
  name: 'EditJobAnnouncementModal',
  components: {
    DBBasedSelect,
    ServerErrors,
  },
  props: {
    job: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'updated'],
  setup(props, { emit }) {
    const emailInput = ref(null);
    const localJob = ref(JSON.parse(JSON.stringify(props.job)));
    const announcementEmail = ref(null);
    const announcementName = ref(null);
    const contactId = ref(null);
    const serverErrors = ref([]);
    const contactSelectKey = ref(0);
    const canEdit = (props.job.status === 'unplanned' || props.job.status === 'planned');

    const cancel = () => {
      emit('close');
    };

    const updateJob = () => {
      serverErrors.value = [];
      axios.put(`/jobs/${localJob.value.id}/update_announcement_email`, {
        contact_id: contactId.value,
      }).then((response) => {
        emit('updated', response.data.job);
        emit('close');
      }).catch((error) => {
        if (error.response.status === 400) {
          serverErrors.value = error.response.data.jobs;
        } else {
          Flash.error('Auftrag konnte nicht gespeichert werden');
        }
      });
    };

    const openAddContactModal = () => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: AddCreateContactModal,
            attrs: {
              relationType: 'contact',
              onlyCreate: true,

              onClose() {
                modalInstance.close();
              },
              onAdded(newContact) {
                contactId.value = newContact.id;
                contactSelectKey.value += 1; // this forces re-render of select with a the new contact selected
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    onMounted(() => {
      // emailInput.value.focus();
      if (localJob.value.job_announcements && localJob.value.job_announcements.length) {
        announcementEmail.value = localJob.value.job_announcements[0].email;
        announcementName.value = localJob.value.job_announcements[0].name;
        contactId.value = localJob.value.job_announcements[0].contact_id;
      }
    });

    const onContactSelected = (selectedContactId) => {
      contactId.value = selectedContactId;
    };

    return {
      canEdit,
      openAddContactModal,
      onContactSelected,
      emailInput,
      announcementEmail,
      announcementName,
      contactId,
      contactSelectKey,
      cancel,
      localJob,
      serverErrors,
      updateJob,
    };
  },
};
</script>
