<template>
  <div class="fx-list-row">
    <div class="fx-single-control-col">
      <template v-if="hasIncidentsPro && incidentUrl">
        <a class="fx-btn-skeleton" :href="incidentUrl">ansehen</a>
      </template>
    </div>
    <div class="fx-50px-col">
      <strong>#{{ incident.number }}</strong>
    </div>
    <div class="fx-220px-col">
      <span
        style="font-weight: bold;"
        :title="incident.incident_type_name"
      >{{ truncate(incident.incident_type_name, 200) }}</span>
      <div v-if="incident.severity !== 'normal'">
        <span :class="'severity-' + incident.severity">
          {{ $t('activerecord.attributes.incident.severities.' + incident.severity) }}
        </span>
      </div>
    </div>
    <div class="fx-100px-col">
      <span :class="'fx-status incident-' + incident.status">
        {{ $t('activerecord.attributes.incident_status.statuses.' + incident.status) }}
      </span>
    </div>
    <div class="fx-100px-col">
      <b>{{ statusDate }}</b>
      {{ statusDateTime }}
    </div>

    <div class="fx-300px-col">
      {{ incident.note }}
    </div>

    <div class="fx-10px-col">
      <template v-if="showLinks">
        <a
          v-if="incident.assigned_job_id"
          v-tippy="{ placement: 'top', duration: 100, arrow: true }"
          :href="assignedJobUrl"
          title="ist in Zielauftrag eingeplant"
        >
          <i style="font-size: 17px;" class="far fa-calendar-check" />
        </a>
      </template>
      <template v-else>
        <i v-if="incident.assigned_job_id" style="font-size: 17px;" class="far fa-calendar-check" />
      </template>
    </div>

    <div class="clearfix" />
  </div>
</template>

<script>
import moment from 'moment';
import { TruncateFormatter } from 'mixins/formatters';
// import IncidentModel from "../incident_model"

export default {
  name: 'ThingIncident',
  mixins: [TruncateFormatter],
  props: {
    incident: {
      type: Object,
      required: true,
    },
    showLinks: {
      type: Boolean,
      default: true,
    },
    hasIncidentsPro: {
      type: Boolean,
      default: false,
    },
    incidentDetailsUrl: {
      type: String,
      default: '/incidents/',
    },
  },
  computed: {
    thingUrl() {
      return `/things/${this.incident.thing_id}`;
    },
    assignedJobUrl() {
      return `/jobs/${this.incident.assigned_job_id}`;
    },
    incidentUrl() {
      return `${this.incidentDetailsUrl}${this.incident.id}`;
    },
    installationUrl() {
      return `/installations/${this.incident.installation.id}`;
    },
    customerUrl() {
      return `/customers/${this.incident.customer.id}`;
    },
    statusDate() {
      return moment(this.incident.statusdate).format('DD.MM.YYYY');
    },
    statusDateTime() {
      return `${moment(this.incident.statusdate).format('HH:mm')} Uhr`;
    },
    address() {
      return this.incident.installation.location_address;
    },
    locationName() {
      const name = this.incident.installation.location_name;
      return name ? name.replace(/c\/o/g, '<br>c/o') : null;
    },
  },
};
</script>

<style>
</style>
