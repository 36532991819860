<!-- eslint-disable no-console -->
<template>
  <div>
    <section class="fx-page-content">
      <div style="display: flex; flex-flow: column; max-height: calc(100vh - 60px); overflow: hidden;">
        <div style="flex: 0 1 auto; background-color: rgb(222, 229, 233);">
          <div style="display: inline-block; padding: .3rem;">
            <DBBasedFilter
              v-model="filter.installationTypeId"
              :default-label="$t('comp.filter.installation_types.default-label')"
              i18n-base-key="installation_types"
              load-options-url="/installation_types/for_select"
              icon-id="installation_type"
              @changed="onFilterInstallationType"
            />

            <DBBasedFilter
              v-model="filter.customerId"
              i18n-base-key="customers"
              :default-label="$t('comp.filter.customers.default-label')"
              :allow-multi-select="false"
              load-options-url="/customers/for_select"
              icon-id="customer"
              @changed="onFilterCustomer"
            />

            <FilterStaticOptions
              v-model="filter.checkStatus"
              i18n-base-key="check_status"
              icon-id="check_status"
              :init-options="statusOptions"
              @changed="onFilterStatus"
            />

            <FilterStaticOptions
              v-model="filter.replacementStatus"
              i18n-base-key="replacement_status"
              icon-id="replacement_status"
              :init-options="replacementOptions"
              @changed="onFilterReplacementStatus"
            />

            <DBBasedFilter
              v-model="filter.technician"
              :default-label="$t('comp.filter.users.default-label')"
              i18n-base-key="users"
              load-options-url="/users/for_select"
              icon-id="user"
              @changed="onFilterTechnician"
            />

            <span v-if="loading" style="margin-left: 20px;font-size: 13px;"><i class="fas fa-spinner fa-spin" /> Karte wird aktualisiert</span>
            <span v-else>
              <a style="font-size: .8rem; padding-left: 1rem;" @click.prevent="resetFilter()">zurücksetzen</a>
              <template v-if="mapLoaded">
                <span
                  v-if="geoData.features.length < 1"
                  style="margin-left: 20px; font-size: 13px; color: #F44336;"
                >Keine Ergebnisse für diese Filtereinstellung</span>

                <button
                  v-if="geoData.features.length > 0"
                  v-tippy="{ placement: 'top', duration: 100, arrow: true }"
                  class="fx-btn-secondary"
                  title="Auf Ergebnisse zoomen"
                  style="margin-left: 20px; font-size: .7rem; border: 1px solid #aaa;"
                  @click="mapFitBounds()"
                ><i class="fas fa-brackets" style="margin-right: 5px;" /> {{ geoData.features.length }} Ergebnisse</button>

              </template>
            </span>
          </div>
        </div>

        <!-- <div id="mapbox" :style="mapStyle" />-->

        <div :style="showSidebarStyle">
          <div style="padding: 15px; background: #f0f0f0; max-height: 100vh; overflow-y: scroll;">
            <div v-if="localInstallations.length">
              <div v-for="(installation, index) in localInstallations" :key="installation.id">
                <div v-if="index == 0" class="fx-card" style="margin-bottom: 15px;">
                  <table class="fx-card" style="font-size: 0.8rem;">
                    <colgroup>
                      <col style="width: 100px;">
                      <col>
                    </colgroup>
                    <tr>
                      <th style="max-width: 25px;">
                        Objekt
                      </th>
                      <td id="job-details-location">
                        <Location :location="installation.location" :link="true" />
                      </td>
                    </tr>
                  </table>
                </div>

                <div class="fx-card" style="margin-bottom: 15px;">
                  <table class="fx-card" style="font-size: 0.8rem;">
                    <colgroup>
                      <col style="width: 100px;">
                      <col>
                    </colgroup>
                    <tr>
                      <td colspan="2">
                        <h3 class="fx" style="margin-bottom: 4px;">
                          <Installation :installation="installation" :link="true" />
                        </h3>
                        <Labels :labels="installation.labels" />
                      </td>
                    </tr>
                    <tr>
                      <!-- <th>&nbsp;</th> -->
                      <td colspan="2">
                        <!-- Fälligkeiten<br> -->
                        <NextCheckInfo
                          v-if="installation.next_check_at"
                          :next-date="installation.next_check_at"
                          :next-status="installation.next_check_status"
                        />
                        <NextCheckInfo
                          v-if="installation.next_replacement_at"
                          :next-date="installation.next_replacement_at"
                          :next-status="installation.next_replacement_status"
                          :is-replacement="true"
                        />
                        <!-- Komponenten -->
                        <div><i class="far fa-cube" /> {{ installation.things_count }} Komponenten</div>
                        <!-- Störungen<br> -->
                        <template v-if="currentUser.pro">
                          <div>
                            <template v-if="installation.open_incidents_count > 0">
                              <i class="fas fa-exclamation-triangle color-warn" /> {{ installation.open_incidents_count }} offene
                              Störungen gesamt
                            </template>
                            <template v-else>
                              <span class="secondary" title=""><i class="fas fa-exclamation-triangle" /> 0 offene Störungen
                                gesamt</span>
                            </template>
                          </div>
                          <div>
                            <template v-if="installation.open_unassigned_incidents_count > 0">
                              <i class="fas fa-exclamation-triangle color-warn" /> {{ installation.open_unassigned_incidents_count
                              }}
                              offene Störungen ohne Zielauftrag <help-article article-id="incident_target_job" :no-style="true">
                                <i
                                  class="fas fa-question-circle"
                                />
                              </help-article>
                            </template>
                            <template v-else>
                              <span class="secondary" title=""><i class="fas fa-exclamation-triangle" /> 0 offene Störungen ohne
                                Zielauftrag <help-article article-id="incident_target_job" :no-style="true"><i
                                  class="fas fa-question-circle"
                                /></help-article>
                              </span>
                            </template>
                          </div>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <th>Techniker</th>
                      <td id="job-details-assigned_user">
                        {{ installation.assigned_user_name }}
                        <span v-if="!installation.assigned_user_name" class="secondary">Kein Techniker hinterlegt</span>
                      </td>
                    </tr>
                    <tr>
                    <th>
                      Nächster Auftrag
                    </th>
                    <td>
                    <table style="margin-bottom: 0;">
                      <tr v-if="!installation.next_job">
                        <td><span class="secondary">Kein Auftrag</span></td>
                      </tr>
                      <tr v-if="installation.next_job">
                        <td valign="top" style="padding: 0 .5rem 1rem 0;">
                          <strong>{{ formattedPlannedAt(installation.next_job) }}</strong><br>
                          <span :class="'fx-status ' + installation.next_job.status">{{ $t('job.status.' +
                            installation.next_job.status)
                          }}</span>
                        </td>
                        <td valign="top" style="padding: 0 .5rem 1rem 0; width: 100%;">
                          <a id="next-job-link" :href="jobUrl(installation.next_job)">
                            {{ installation.next_job.job_type_name }} {{ installation.next_job.number }}</a><br>

                          <div v-if="installation.next_job.pooling" class="secondary">
                            <i class="fas fa-users" /> Jeder
                          </div>
                          <div
                            v-for="name in installation.next_job.assigned_user_names"
                            v-else
                            :key="name"
                            class="secondary"
                          >
                            <i class="fas fa-user" /> {{ name }}
                          </div>
                        </td>
                        <td valign="top" style="padding: 0;">
                          <a id="next-job-quickview" class="fx-btn-skeleton" @click="openJobQuickView(installation.next_job.id)"><i
                            class="fas fa-eye"
                          /></a>
                        </td>
                      </tr>
                    </table>
                    </td>
                  </tr>
                  </table>
                  <div style="padding: 10px;">
                    <div class="fx-btn-group">
                      <button class="fx-btn-secondary" style="width: 100%;" @click="openNewJobModal(installation.id)">
                        Auftrag planen
                      </button>
                      <a class="fx-btn-secondary" href="">Anlage ansehen</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="mapbox" :style="mapStyle" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

// import { defineStore } from 'pinia';

import FilterStaticOptions from 'components/filter/filter_static_options.vue';
import NextCheckInfo from 'components/partials/next_check_info.vue';
import DBBasedFilter from 'components/filter/db_based_filter.vue';
import { jobQuickView } from 'helpers/quickviews';
import openNewJobModal from 'helpers/open_new_job_modal';
import { useCurrentUserStore } from 'stores/current_user';
import { ref } from 'vue';

import Location from 'components/partials/location.vue';
import Installation from 'components/partials/installation.vue';
import Labels from 'components/partials/labels.vue';

import mapboxgl from 'mapbox-gl/dist/mapbox-gl';
// import MapboxLanguage from '@mapbox/mapbox-gl-language';
import 'mapbox-gl/dist/mapbox-gl.css';

export default {
  name: 'MainMap',
  components: {
    FilterStaticOptions,
    DBBasedFilter,
    Location,
    Installation,
    Labels,
    NextCheckInfo,
  },
  props: {
    statusOptions: {
      type: Array,
      default: () => [
        { id: 'near_and_overdue', color: null },
        { id: 'overdue', color: '#F44336' },
        { id: 'near', color: '#FF8F00' },
        { id: 'far', color: 'green' },
        { id: 'unknown', color: '#aaa' },
      ],
    },
    replacementOptions: {
      type: Array,
      default: () => [
        { id: 'near_and_overdue', color: null },
        { id: 'overdue', color: '#F44336' },
        { id: 'near', color: '#FF8F00' },
        { id: 'far', color: 'green' },
        { id: 'unknown', color: '#aaa' },
      ],
    },
  },
  setup() {
    const userStore = useCurrentUserStore();
    const filter = ref({ ...userStore.filter('map') });

    const localInstallations = ref([]);

    const loadLocation = (locationId) => {
      localInstallations.value = axios.get(`/locations/${locationId}/installations_for_job.json`)
        .then((response) => {
          localInstallations.value = response.data.installations;
        }).catch(() => {
        });
    };

    const openJobQuickView = (jobId) => {
      jobQuickView(jobId);
    };

    return {
      filter,
      openJobQuickView,
      openNewJobModal,
      localInstallations,
      loadLocation,
      userStore,
      currentUser: userStore.currentUser,
    };
  },
  data() {
    return {
      selectedInstallation: null,
      geoData: {
        type: 'FeatureCollection',
        features: [],
      },
      geoDataBounds: null,
      map: null,
      mapLoaded: false,
      mapEnabled: true,
      locations: [],
      // localLocation: null,
      all_locations: [],
      loading: false,
      // filter: {
      //   installationTypeId: null,
      //   checkStatus: null,
      //   technician: null,
      //   customerId: null,
      // },
      showSidebar: false,
    };
  },
  computed: {
    mapStyle() {
      return 'width: 100%; height: calc(100vh - 105px); background-color: #ddd;';
    },
    showSidebarStyle() {
      if (this.showSidebar) {
        return 'display: grid; grid-template-columns: 450px 1fr; grid-template-rows: 1fr; flex: 1 1 auto;';
      }

      return 'display: grid; grid-template-columns: 0 1fr; grid-template-rows: 1fr; flex: 1 1 auto;';
    },
  },
  watch: {
    filter: {
      handler() {
        this.loadMapData();
      },
      deep: true,
    },
  },
  mounted() {
    this.initMapBox();
  },
  methods: {
    friendlyTime(time) {
      moment.locale('de');
      return moment(time).fromNow();
    },
    formattedTimeWithMinute(time) {
      return moment(time).format('DD.MM.YYYY HH:mm');
    },
    formattedPlannedAt(job) {
      return moment(job.planned_at).format('DD.MM.YYYY');
    },
    jobUrl(job) {
      return `/jobs/${job.id}`;
    },
    onFilterStatus(statusId) {
      this.filter.checkStatus = statusId;
    },
    onFilterReplacementStatus(replacementStatusId) {
      this.filter.replacementStatus = replacementStatusId;
    },
    onFilterInstallationType(installationTypeId) {
      this.filter.installationTypeId = installationTypeId;
    },
    onFilterTechnician(technicianId) {
      this.filter.technician = technicianId;
    },
    onFilterCustomer(customerId) {
      this.filter.customerId = customerId;
    },
    loadMapData() {
      this.loading = true;
      const that = this;
      axios.get('/locations/filtered_map.json', {
        params: {
          installation_type_id: this.filter.installationTypeId,
          technician: this.filter.technician,
          status: this.filter.checkStatus,
          replacement: this.filter.replacementStatus,
          customer_id: this.filter.customerId,
        },
      }).then((response) => {
        that.geoData = response.data;
        that.refreshMapFromSource();

        that.mapLoaded = true;
        that.loading = false;

        this.userStore.updateFilter('map', this.filter);
      }).catch(() => {
        that.loading = false;
      });
    },
    refreshMapFromSource() {
      this.map.getSource('locations-source').setData(this.geoData);
    },
    populateMap() {
      if (!this.mapEnabled) { return; }

      const that = this;
      this.map.addSource('locations-source', {
        type: 'geojson',
        data: this.geoData,
      });

      this.map.addLayer({
        id: 'search-results',
        type: 'symbol',
        source: 'locations-source',
        layout: {
          'icon-image': 'marker-{icon}',
          'icon-allow-overlap': true,
          'text-allow-overlap': true,
          'icon-anchor': 'bottom',
          visibility: 'visible',
        },
      });

      this.map.on('click', 'search-results', (e) => {
        // --- Sidebar logic, soon to come
        // this.showSidebar = true;
        // this.loadLocation(e.features[0].properties.id);

        that.map.flyTo({
          center: e.features[0].geometry.coordinates,
        });
        const popup = new mapboxgl.Popup({ anchor: 'left', offset: [10, -16], maxWidth: '300px' })
          .setText('loading ...').setLngLat(e.features[0].geometry.coordinates).addTo(that.map);
        axios.get(`/locations/${e.features[0].properties.id}/map_popup_content.html`)
          .then((response) => {
            popup.setHTML(response.data);
          }).catch();
      });

      // Change the cursor to a pointer when the it enters a feature in the 'symbols' layer.
      this.map.on('mouseenter', 'search-results', () => {
        that.map.getCanvas().style.cursor = 'pointer';
      });

      // Change it back to a pointer when it leaves.
      this.map.on('mouseleave', 'search-results', () => {
        that.map.getCanvas().style.cursor = '';
      });

      // store new center and zoom
      this.map.on('moveend', () => {
        window.localStorage.setItem(
          `mainMapCenter-${this.currentUser.id}`,
          `{"lng": ${this.map.getCenter().lng}, "lat": ${this.map.getCenter().lat}, "zoom": ${this.map.getZoom()}}`,
        );
      });
    },
    // --- move map to a specific point
    locateMe(data) {
      this.map.flyTo({
        center: data,
        zoom: 13,
      });
    },
    mapFitBounds() {
      this.geoDataBounds = this.getBoundingBox(this.geoData);
      const boundingBox = this.geoDataBounds;
      this.map.fitBounds([[boundingBox.xMin, boundingBox.yMin], [boundingBox.xMax, boundingBox.yMax]], { padding: 100, maxZoom: 14 });
    },
    getBoundingBox(data) {
      this.plannedJobsCount = 0;
      this.dueInstallationsCount = 0;
      this.overdueInstallationsCount = 0;

      const bounds = {}; let coords; let latitude; let
        longitude;

      for (let i = 0; i < data.features.length; i += 1) {
        if (data.features[i].properties.icon === 'job') {
          this.plannedJobsCount += 1;
        }
        if (data.features[i].properties.icon === 'far' || data.features[i].properties.icon === 'near') {
          this.dueInstallationsCount += 1;
        }
        if (data.features[i].properties.icon === 'overdue') {
          this.overdueInstallationsCount += 1;
        }

        coords = data.features[i].geometry.coordinates;

        for (let j = 0; j < coords.length; j += 1) {
          longitude = coords[0];
          latitude = coords[1];

          bounds.xMin = bounds.xMin < longitude ? bounds.xMin : longitude;
          bounds.xMax = bounds.xMax > longitude ? bounds.xMax : longitude;
          bounds.yMin = bounds.yMin < latitude ? bounds.yMin : latitude;
          bounds.yMax = bounds.yMax > latitude ? bounds.yMax : latitude;
        }
      }

      return bounds;
    },
    initMapBox() {
      //  const MapboxLanguage = require('@mapbox/mapbox-gl-language');

      if (!this.mapEnabled) { return; }

      mapboxgl.accessToken = 'pk.eyJ1IjoiZm94dGFnIiwiYSI6ImNrMXVneDl2aDB5MjkzY3Q2aDRtNWtwNXUifQ.0aazkwQuRWiwzN8P3zjx3A';
      this.map = new mapboxgl.Map({
        container: 'mapbox',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: this.getInitialCenterOfMap(),
        attributionControl: false,
        logo: false,
        zoom: this.getInitialZoom(),
      });
      // this.map.scrollZoom.disable();
      this.map.addControl(new mapboxgl.NavigationControl(), 'top-left');

      // const language = new MapboxLanguage();
      // this.map.addControl(language);

      const that = this;
      this.map.on('load', () => {
        that.map.loadImage('/mapmarkers/marker-re-green-shadow.png', (error, image) => {
          if (error) throw error;
          that.map.addImage('marker-repl-far', image, { pixelRatio: 2 });
        });
        that.map.loadImage('/mapmarkers/marker-dot-green-shadow.png', (error, image) => {
          if (error) throw error;
          that.map.addImage('marker-check-far', image, { pixelRatio: 2 });
        });
        that.map.loadImage('/mapmarkers/marker-re-orange-shadow.png', (error, image) => {
          if (error) throw error;
          that.map.addImage('marker-repl-near', image, { pixelRatio: 2 });
        });
        that.map.loadImage('/mapmarkers/marker-dot-orange-shadow.png', (error, image) => {
          if (error) throw error;
          that.map.addImage('marker-check-near', image, { pixelRatio: 2 });
        });
        that.map.loadImage('/mapmarkers/marker-re-red-shadow.png', (error, image) => {
          if (error) throw error;
          that.map.addImage('marker-repl-overdue', image, { pixelRatio: 2 });
        });
        that.map.loadImage('/mapmarkers/marker-dot-red-shadow.png', (error, image) => {
          if (error) throw error;
          that.map.addImage('marker-check-overdue', image, { pixelRatio: 2 });
        });
        that.map.loadImage('/mapmarkers/marker-cal-blue-shadow.png', (error, image) => {
          if (error) throw error;
          that.map.addImage('marker-job', image, { pixelRatio: 2 });
        });
        that.map.loadImage('/mapmarkers/marker-re-gray-shadow.png', (error, image) => {
          if (error) throw error;
          that.map.addImage('marker-repl-unknown', image, { pixelRatio: 2 });
        });
        that.map.loadImage('/mapmarkers/marker-dot-gray-shadow.png', (error, image) => {
          if (error) throw error;
          that.map.addImage('marker-check-unknown', image, { pixelRatio: 2 });
        });

        /* What?! Wait till all images has been loaded ... */
        setTimeout(() => {
          that.loadMapData();
          that.populateMap();
        }, 500);
      });
    },

    // Get center from localstorage or default
    getInitialCenterOfMap() {
      try {
        this.savedCenter = JSON.parse(localStorage.getItem(`mainMapCenter-${this.currentUser.id}`));
      } catch (exception) {
        // eslint-disable-next-line no-console
        console.log('Exception: ', exception);
      }
      if (this.savedCenter) {
        return this.savedCenter;
      }
      return [10.018042, 53.586379]; // Hamburg Coords
    },

    // Get zoom from localstorage or default
    getInitialZoom() {
      try {
        this.savedZoom = JSON.parse(localStorage.getItem(`mainMapCenter-${this.currentUser.id}`));
      } catch (exception) {
        // eslint-disable-next-line no-console
        console.log('Exception: ', exception);
      }
      if (this.savedZoom) {
        return this.savedZoom.zoom;
      }
      return 12;
    },

    resetFilter() {
      this.filter.installationTypeId = null;
      this.filter.checkStatus = null;
      this.filter.replacementStatus = null;
      this.filter.technician = null;
      this.filter.customerId = null;
      this.loadMapData();
    },

    loadInstallation(installationId) {
      this.selectedInstallationId = installationId;

      const that = this;
      axios.get(`/installations/${installationId}/for_job`)
        .then((response) => {
          that.selectedInstallation = response.data;
          // that.job = that.buildNewJob(that.installation);
          // that.announcementEmail = that.installation.announcement_email_suggestion;
          // that.assignedTechnicians = [that.installation.assigned_user_id];
        }).catch(() => {
          // Flash.error('Anlage konnte nicht geladen werden');
        });
    },
  },
};
</script>

<style scoped></style>
