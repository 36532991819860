<template>
  <div class="fx-modal-content-medium">
    <template v-if="!resultingLocation">
      <a class="fx-modal__close" @click="$emit('close')">&times;</a>
      <h2 class="fx">
        {{ title }}
      </h2>
      <hr style="margin-top:0;">

      <ServerErrors v-model="serverErrors" title="Objekt konnte nicht gespeichert werden" margin-bottom="1rem" />

      <form @submit.prevent="submit">
        <div class="fx-form-row">
          <div class="fx-form-content">
            <span>Ein Objekt ist der Standort von einer oder mehreren Anlagen</span>
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline" for="customer">Kunde</label>
          </div>
          <div class="fx-form-content">
            <DBBasedSelect
              v-model="localLocation.customer_id"
              icon-id="customer"
              i18n-base-key="customers"
              :default-label="$t('comp.select.no-selection')"
              load-options-url="/customers/for_select"
              :readonly="customerReadonly"
              @changed="onCustomerSelected"
            />
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline" for="location_number">Objektnummer</label>
          </div>
          <div class="fx-form-content">
            <input
              id="location_number"
              v-model="localLocation.number"
              name="location_number"
              placeholder="optional"
              type="text"
            >
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline" :class="nameMandatoryClass" for="installation_name">Name</label>
          </div>
          <div class="fx-form-content">
            <input
              id="location_name"
              v-model="localLocation.name"
              name="location_name"
              :placeholder="namePlaceholder"
              type="text"
            >
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-content">
            <input
              id="shares_customer_address_true"
              v-model="localLocation.shares_customer_address"
              type="radio"
              :value="true"
            >
            <label for="shares_customer_address_true">Objektadresse ist Kundenadresse</label>
            <br>
            <input
              id="shares_customer_address_false"
              v-model="localLocation.shares_customer_address"
              type="radio"
              :value="false"
            >
            <label for="shares_customer_address_false">Objekt hat eigene Adresse</label>
          </div>
        </div>

        <template v-if="!localLocation.shares_customer_address">
          <AddressForm v-model="localLocation.active_address" />
        </template>

        <hr>
        <button
          id="submit-edit-location"
          type="submit"
          class="fx-btn-primary float-right"
          style="margin-bottom: 0.5rem; margin-left: 0.5rem;"
        >
          Speichern
        </button>
        <button class="fx-btn-secondary float-right" @click.prevent="$emit('close')">
          Abbrechen
        </button>
        <div class="clearfix" />
      </form>
    </template>
    <SuccessState
      v-if="resultingLocation"
      icon="yo"
      :title="'Objekt hinzugefügt'"
      hint="Möchten Sie eine erste Anlage hinzufügen?"
    >
      <div class="fx-btn-group">
        <button class="fx-btn-secondary" @click.prevent="$emit('close')">
          Fenster schließen
        </button>
        <button id="btn-add-installation-modal" class="fx-btn-primary" @click.prevent="closeAndAddInstallation()">
          Anlage
          hinzufügen
        </button>
      </div>
    </SuccessState>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';

import FoxtagPaths from 'mixins/foxtag_paths';

import SuccessState from 'components/success_state.vue';
import AddressForm from 'components/address_form.vue';
import ServerErrors from 'components/partials/server_errors.vue';

import DBBasedSelect from 'components/select/db_based_select.vue';

export default {
  name: 'EditLocationModal',
  components: {
    DBBasedSelect,
    SuccessState,
    AddressForm,
    ServerErrors,
  },
  mixins: [FoxtagPaths],
  props: {
    location: {
      type: Object,
      default: null,
    },
    customerId: {
      type: String,
      default: null,
    },
  },
  emits: ['close', 'created', 'updated', 'add-installation'],
  data() {
    return {
      customerReadonly: false,
      presetCustomerId: null,
      serverErrors: [],
      resultingLocation: null,
      localLocation: {
        customer_id: null,
        number: null,
        name: null,
        shares_customer_address: true,
        active_address: {
          line1: null,
          line2: null,
          zip: null,
          city: null,
          country: 'DE',
        },
      },
    };
  },
  computed: {
    nameMandatoryClass() {
      return this.customerId == null ? 'mandatory' : '';
    },
    namePlaceholder() {
      return this.customerId == null ? '' : 'optional';
    },
    title() {
      if (this.createNewLocation) {
        return 'Objekt hinzufügen';
      }
      return 'Objekt bearbeiten';
    },
    createNewLocation() {
      return (this.localLocation.id == null);
    },
  },
  mounted() {
    if (this.location != null) {
      // --- make a deep copy of the location to avoid changing the original
      this.localLocation = JSON.parse(JSON.stringify(this.location));
    } else {
      this.localLocation.customer_id = this.customerId;
      this.customerReadonly = (this.customerId != null); // --- if we have a customer id, we are not allowed to change it
    }
  },
  methods: {
    onCustomerSelected(customerId) {
      this.localLocation.customer_id = customerId;
    },
    submit() {
      if (this.createNewLocation) {
        this.createViaAPI();
      } else {
        this.updateViaAPI();
      }
    },
    closeAndAddInstallation() {
      this.$emit('close');
      this.$emit('add-installation', { ...this.resultingLocation });
    },
    createViaAPI() {
      const that = this;
      that.serverErrors = [];
      axios.post('/locations.json', {
        location: {
          customer_id: this.localLocation.customer_id,
          number: this.localLocation.number,
          name: this.localLocation.name,
          shares_customer_address: this.localLocation.shares_customer_address,
          address_attributes: {
            line1: this.localLocation.active_address.line1,
            line2: this.localLocation.active_address.line2,
            zip: this.localLocation.active_address.zip,
            city: this.localLocation.active_address.city,
            country: this.localLocation.active_address.country,
          },
        },
      }).then((response) => {
        this.resultingLocation = response.data.location;
        this.$emit('created', this.resultingLocation);
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.locations;
        } else {
          Flash.error('Objekt konnte nicht gespeichert werden');
        }
      });
    },
    updateViaAPI() {
      const that = this;
      that.serverErrors = [];
      axios.put(`/locations/${this.localLocation.id}.json`, {
        location: {
          customer_id: this.localLocation.customer_id,
          number: this.localLocation.number,
          name: this.localLocation.name,
          shares_customer_address: this.localLocation.shares_customer_address,
          address_attributes: {
            line1: this.localLocation.active_address.line1,
            line2: this.localLocation.active_address.line2,
            zip: this.localLocation.active_address.zip,
            city: this.localLocation.active_address.city,
            country: this.localLocation.active_address.country,
          },
        },
      }).then((response) => {
        this.$emit('updated', response.data.location);
        this.$emit('close');
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.locations;
        } else {
          Flash.error('Objekt konnte nicht gespeichert werden');
        }
      });
    },
  },
};
</script>
