<template>
  <div>
    <input
      id="user_role_admin"
      v-model="roleAdmin"
      type="checkbox"
      name="user_role_admin"
      :disabled="roleAdminDisabled"
      @change="rolesChanged"
    >
    <label for="user_role_admin" class="inline">Administration</label>
    <br>

    <input
      id="user_role_manage"
      v-model="roleManage"
      type="checkbox"
      name="user_role_manage"
      @change="rolesChanged"
    >
    <label for="user_role_manage" class="inline">Planen und Daten pflegen</label>
    <br>

    <input
      id="user_role_execute"
      v-model="roleExecute"
      type="checkbox"
      name="user_role_execute"
      @change="rolesChanged"
    >
    <label for="user_role_execute" class="inline">Aufträge mit der App durchführen</label>
    <br>

    <input
      id="user_role_web"
      v-model="roleWeb"
      type="checkbox"
      name="user_role_web"
      :disabled="roleWebDisabled"
      @change="rolesChanged"
    >
    <label for="user_role_web" class="inline">Web-Zugang</label>
    <br>

    <div style="padding-top: 0.6rem; padding-bottom: 1rem; font-size: 0.825rem; font-weight: normal;">
      <help-article article-id="overview_user_roles" hide-modal-before-open="invite-modal">
        Was bedeuten die
        Berechtigungen?
      </help-article>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RolesCheckboxes',
  props: {
    initRoles: {
      type: Array,
      required: true,
    },
    roleAdminDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      roleAdmin: false,
      roleManage: false,
      roleExecute: false,
      roleWeb: false,
    };
  },
  computed: {
    roleWebDisabled() {
      // --- role web can not be changed if admin or manage is set (only if execute is set)
      return (this.roleAdmin || this.roleManage || !this.roleExecute);
    },
  },
  mounted() {
    this.roleAdmin = this.initRoles.includes('admin');
    this.roleManage = this.initRoles.includes('manage');
    this.roleExecute = this.initRoles.includes('execute');
    this.roleWeb = this.initRoles.includes('web');
  },
  methods: {
    ensureWebRole() {
      if (this.roleAdmin || this.roleManage) {
        this.roleWeb = true;
      }
    },
    rolesChanged() {
      this.ensureWebRole();

      const roles = [];
      if (this.roleAdmin) { roles.push('admin'); }
      if (this.roleManage) { roles.push('manage'); }
      if (this.roleExecute) { roles.push('execute'); }
      if (this.roleWeb) { roles.push('web'); }

      this.$emit('changed', roles);
    },
  },
};
</script>
