<template>
  <span>
    <div v-if="hasError" style="color: #ec5840; font-size: .9rem;"><i class="fas fa-exclamation-triangle" /> {{ errorMsg() }}</div>
  </span>
</template>

<script>
import { join } from 'lodash';

export default {
  name: 'FieldError',
  components: {
  },
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
    field: {
      type: String,
      required: true,
    },
  },
  computed: {
    hasError() {
      return this.modelValue.details && this.modelValue.details[this.field] != null;
    },
  },
  methods: {
    errorMsg() {
      if (!this.hasError) {
        return '';
      }
      const arr = this.modelValue.details[this.field];
      return join(arr, ', ');
    },
  },
};
</script>
