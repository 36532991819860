<template>
  <section class="fx-page-content">
    <template v-if="initialDataLoaded && !resultingJob">
      <div v-if="installation" class="fx-grid">
        <div class="fx-col">
          <h2 class="fx">
            Auftrag bearbeiten
          </h2>
          <!-- MAIN FORM -->
          <!-- DATE / TYPE -->
          <ServerErrors v-model="serverErrors" title="Auftrag konnte nicht gespeichert werden" margin-bottom="0" />

          <div class="fx-card">
            <div class="fx-card-content">
              <div v-if="installation.has_job_number" class="fx-form-row">
                <div class="fx-form-label">
                  <label for="job_number">Auftragsnummer</label>
                </div>
                <div class="fx-form-content">
                  <input
                    v-model="localJob.number"
                    type="text"
                    placeholder="Auftragsnummer"
                    name="job[number]"
                    class="fx-focus"
                  >
                </div>
              </div>

              <div class="fx-form-row">
                <div class="fx-form-label">
                  <label for="job_job_type">Auftragstyp</label>
                </div>
                <div class="fx-form-content">
                  <select id="job-job-type-id" v-model="localJob.job_type_id" disabled="true">
                    <option v-for="jobType in installation.active_job_types" :key="jobType.id" :value="jobType.id">
                      {{ jobType.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="fx-form-row" style="margin-bottom: 0;">
                <div class="fx-form-label">
                  <label for="job_{:class=>&quot;inline mandatory&quot;}">Datum<span style="color: red"> *</span> / Uhrzeit
                  </label>
                </div>
                <div class="fx-form-content">
                  <StartEnddateForm
                    :init-start-date="localJob.planned_at"
                    :init-end-date="localJob.enddate"
                    :init-all-day="localJob.all_day"
                    @changed="jobDurationChanged"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- TECHNICIANS -->
          <div class="fx-card">
            <div class="fx-card-content">
              <h2 class="fx">
                Zugeordnete Mitarbeiter
              </h2>
              <p>Wenn Sie niemanden zuordnen, können alle Mitarbeiter den Auftrag ausführen.</p>

              <div id="job-assigned-users">
                <div class="fx-form-row">
                  <div class="fx-form-label">
                    <label>Mitarbeiter</label>
                  </div>
                  <div class="fx-form-content">
                    <div v-for="(assignedTechnician, index) in assignedTechnicians" :key="index" style="margin-bottom: .5rem;">
                      <div style="display: inline-block; width: 100%; max-width: 340px; margin-right: .8rem;">
                        <select v-model="assignedTechnicians[index]" class="fx-focus">
                          <option value="" />
                          <option
                            v-for="technician in installation.assignable_technicians"
                            :key="technician.id"
                            :value="technician.id"
                          >
                            {{ technician.label }}
                          </option>
                        </select>
                      </div>

                      <a v-if="assignedTechnicians.length > 1" class="small" @click.prevent="removeAssignedTechnicianRow(index)">löschen</a>
                    </div>

                    <div>
                      <a style="font-size: .875rem;" @click.prevent="addAssignedTechnicianRow()">
                        <i class="fas fa-plus" />
                        Mitarbeiter hinzufügen
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- INSTRUCTIONS -->
          <div class="fx-card">
            <div class="fx-card-content">
              <h2 class="fx">
                Hinweise
              </h2>

              <div class="fx-form-row">
                <div class="fx-form-label">
                  <label for="job_instructions">Hinweise</label>
                </div>
                <div class="fx-form-content">
                  <textarea
                    id="job_instructions"
                    v-model="localJob.instructions"
                    style="margin-bottom:0; min-height: 100px;"
                    rows="3"
                    placeholder="Hinweise für die Techniker vor Ort ..."
                    class="fx-focus"
                  />
                </div>
              </div>

              <div class="fx-form-row">
                <div class="fx-form-label">
                  <label for="job_instructions">Allgemeine Hinweise <help-article article-id="job_instructions" :no-style="true"><i class="fas fa-question-circle" /></help-article></label>
                </div>
                <div class="fx-form-content" style="padding-top: 0.2rem;">
                  {{ installation.note }}
                  <span v-if="!installation.note" class="secondary">Keine Hinweise an der Anlage hinterlegt</span>
                </div>
              </div>

              <div v-if="currentUser.pro" class="fx-form-row">
                <div class="fx-form-label">
                  <label class="inline" for="group_link">Link</label>
                </div>
                <div class="fx-form-help">
                  <HelpHover content="links" />
                </div>
                <div class="fx-form-content">
                  <input
                    id="group_link"
                    v-model="localJob.link"
                    class="has-form-field-hint"
                    name="group_link"
                    type="text"
                  >
                  <div class="form-field-hint">
                    <ExternalLinkButton :href="localJob.link" /> Link auf eine externe Webseite, Datei oder einen Speicherort.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- announcement -->
          <div v-if="announcementsEnabled" class="fx-card">
            <div class="fx-card-content">
              <h2 class="fx">
                Ankündigung per E-Mail
              </h2>
              <div class="fx-form-row">
                <div class="fx-form-label">
                  <label>E-Mail-Adresse</label>
                </div>
                <div class="fx-form-content">
                  <input
                    id="announcement_email"
                    v-model="localJob.announcement_email"
                    name="announcement_email"
                    style="display: inline-block; width: 100%; max-width: 320px; margin-right: .8rem;"
                    class="fx-focus"
                    type="text"
                  >
                  <a class="small" @click.prevent="localJob.announcement_email = null">löschen</a>
                </div>
              </div>
            </div>
          </div>

          <div style="text-align:right;">
            <span style="margin-right: .5rem;"><a @click="back()">abbrechen</a></span>
            <div class="fx-btn-group">
              <template v-if="jobIsUnplanned">
                <TransactionButton
                  v-model="saving"
                  class="fx-btn-secondary"
                  title="In Planung speichern"
                  :no-spinner="true"
                  @click="submit(false)"
                />
                <TransactionButton
                  v-model="saving"
                  class="fx-btn-primary"
                  title="Planung beenden"
                  :no-spinner="true"
                  @click="submit(true)"
                />
              </template>
              <template v-else>
                <TransactionButton
                  v-model="saving"
                  class="fx-btn-primary"
                  title="Speichern"
                  :no-spinner="true"
                  @click="submit(false)"
                />
              </template>
            </div>
          </div>
        </div> <!-- END OF FORM -->

        <!-- CONTEXT SIDEBAR -->
        <div class="fx-col" style="max-width: 400px;">
          <InstallationSidebar :installation="installation" :feature-incidents-pro="featureIncidentsPro" />
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import Flash from 'flash/index';

import { useCurrentUserStore } from 'stores/current_user';

import StartEnddateForm from 'components/start_enddate_form.vue';
import ServerErrors from 'components/partials/server_errors.vue';
import TransactionButton from 'components/transaction_button.vue';
import FeatureFlipper from 'mixins/feature_flipper';
import ExternalLinkButton from 'components/external_link_button.vue';
import HelpHover from 'components/help_hover.vue';
import InstallationSidebar from '../new/installation_sidebar.vue';

export default {
  name: 'EditJob',
  components: {
    InstallationSidebar,
    StartEnddateForm,
    ServerErrors,
    TransactionButton,
    ExternalLinkButton,
    HelpHover,
  },
  mixins: [FeatureFlipper],
  props: {
    jobId: {
      type: String,
      required: true,
    },
    installationId: {
      type: String,
      required: true,
    },
    syncWindowFutureDays: {
      type: Number,
      default: 90,
    },
    announcementsEnabled: {
      type: Boolean,
      required: true,
    },
    featureIncidentsPro: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    return {
      currentUser: useCurrentUserStore().currentUser,
    };
  },
  data() {
    return {
      initialDataLoaded: false,
      selectedInstallationId: null,
      installation: null,
      localJob: {},
      showEnddate: false,
      assignedTechnicians: [null],
      announcementEmail: null,
      serverErrors: [],
      resultingJob: null,
      nextJobInstallationId: null,
      saving: false,
    };
  },
  computed: {
    jobIsUnplanned() {
      if (this.localJob == null) {
        return false;
      }
      return this.localJob.status === 'unplanned';
    },
  },
  mounted() {
    this.loadJob();
    this.loadInstallation();
  },
  methods: {
    jobDurationChanged(duration) {
      this.localJob.planned_at = duration.startDate;
      this.localJob.enddate = duration.endDate;
      this.localJob.all_day = duration.allDay;
    },
    back() {
      window.history.back();
    },
    addAssignedTechnicianRow() {
      this.assignedTechnicians.push('');
    },
    removeAssignedTechnicianRow(index) {
      this.assignedTechnicians.splice(index, 1);
    },
    hasCheckStep(jobTypeId) {
      const selectedType = this.installation.active_job_types
        .find((element) => element.id === jobTypeId);

      return (selectedType && selectedType.has_checks_step);
    },
    technicianLabel(user) {
      if (user.full_name == null) {
        return user.email;
      }
      return user.full_name;
    },
    submit(addStatusPlanned) {
      this.saving = true;
      const that = this;
      that.serverErrors = [];

      const statusPlannedParam = addStatusPlanned ? true : null;

      axios.put(`/jobs/${this.localJob.id}`, {
        job: {
          all_day: this.localJob.all_day,
          planned_at: this.localJob.planned_at,
          enddate: this.localJob.enddate,
          number: this.localJob.number,
          instructions: this.localJob.instructions,
          link: this.localJob.link,
          assigned_user_ids: _.uniq(this.assignedTechnicians),
        },
        add_status_planned: statusPlannedParam,
        announcement_email: this.localJob.announcement_email,
      }).then((response) => {
        Flash.info('Auftrag gespeichert');
        window.location.href = `/jobs/${response.data.job.id}`;
      }).catch((error) => {
        this.saving = false;
        if (error.response.status === 400) {
          window.scrollTo(0, 0);
          that.serverErrors = error.response.data.jobs;
          Flash.info('Bitte prüfen Sie Ihre Eingaben');
        } else {
          Flash.error('Auftrag konnte nicht gespeichert werden');
        }
      });
    },
    loadInstallation() {
      const that = this;
      axios.get(`/installations/${this.installationId}/for_job`)
        .then((response) => {
          that.installation = response.data;
        }).catch(() => {
          Flash.error('Anlage konnte nicht geladen werden');
        });
    },
    loadJob() {
      const that = this;
      axios.get(`/jobs/${this.jobId}/edit`)
        .then((response) => {
          that.localJob = response.data.job;
          if (!that.localJob.pooling) {
            that.assignedTechnicians = that.localJob.assigned_user_ids;
          }
          that.initialDataLoaded = true;
        }).catch(() => {
          Flash.error('Auftrag konnte nicht geladen werden');
        });
    },
  },
};
</script>
