<template>
  <div>
    <div class="fx-form-row">
      <div class="fx-form-content" style="padding-top: .3rem;">
        <input
          id="thing_inherit_check_interval_true"
          v-model="thing.inherit_check_interval"
          type="radio"
          :value="true"
        >
        <label for="thing_inherit_check_interval_true">Prüfintervall vom Typ übernehmen</label>
        <br>
        <input
          id="thing_inherit_check_interval_false"
          v-model="thing.inherit_check_interval"
          type="radio"
          :value="false"
        >
        <label for="thing_inherit_check_interval_false">Eigenes Prüfintervall</label>
      </div>
    </div>
    <div class="fx-form-row">
      <div class="fx-form-label">
        <label class="inline">Prüfintervall</label>
      </div>
      <div class="fx-form-content" style="font-size: .9rem; padding-top: .4rem">
        <div v-if="thing.inherit_check_interval">
          <span class="secondary" style="margin-right: .2rem;"><i class="fas fa-circle" /></span>
          <span v-if="checkIntervalInfo"> {{ checkIntervalInfo }}
            <span
              v-if="okSeriesFactor > 1"
              style="margin-left: .5rem; background-color: #bbb;"
              class="fx-label"
            >
              <i class="fas fa-forward" />
              {{ okSeriesFactor }}-fach ab {{ okSeriesThreshold }} x OK</span>
          </span>
          <span v-else class="secondary">
            <span>Kein Prüfintervall am Typ hinterlegt</span>
          </span>
        </div>
        <div v-if="thing.ok_series_completed_at">
          <strong style="color: green;"><i class="fas fa-forward" /> Automatische Verlängerung aktiv</strong>
        </div>
        <div v-if="!thing.inherit_check_interval">
          <select v-model="thing.check_interval_quantity" style="width: 80px;">
            <option v-for="index in 30" :key="index" :value="index">
              {{ index }}
            </option>
          </select>&shy;
          <select v-model="thing.check_interval_frequency" style="width: 150px;">
            <option value="days">
              Tag(e)
            </option>
            <option value="weeks">
              Woche(n)
            </option>
            <option value="months">
              Monat(e)
            </option>
            <option value="years">
              Jahr(e)
            </option>
          </select>

          <div style="margin-top: 1rem;" class="callout callout--info">
            Verwenden Sie eigene Intervalle nur im Ausnahmefall. Wir empfehlen Intervalle stattdessen
            zentral an der Wartungsanwendung zu hinterlegen.
          </div>
        </div>
      </div>
    </div>
    <div class="fx-form-row">
      <div class="fx-form-label">
        <label class="inline">Austauschintervall</label>
      </div>
      <div class="fx-form-content" style="font-size: .9rem; padding-top: .4rem;">
        <span class="secondary" style="margin-right: .2rem;"><i class="fas fa-sync-alt" /></span>
        <span v-if="replacementIntervalInfo">{{ replacementIntervalInfo }}</span>
        <span v-else class="secondary">
          <span>Kein Austauschintervall am Typ hinterlegt</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import axios from 'axios';

export default {
  name: 'ThingIntervalForm',
  props: {
    thing: {
      type: Object,
      required: true,
    },
    thingTypeId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      checkIntervalInfo: null,
      replacementIntervalInfo: null,
      okSeriesFactor: 0,
      okSeriesThreshold: 0,
    };
  },
  computed: {

  },
  watch: {
    thingTypeId: {
      handler(newVal) {
        this.loadThingTypeIntervals();
      },
    },
  },
  mounted() {
    this.loadThingTypeIntervals();
  },
  methods: {
    loadThingTypeIntervals() {
      if (this.thingTypeId == null) { return; }

      const that = this;
      axios.get(`/thing_types/${this.thingTypeId}/intervals`).then((response) => {
        that.checkIntervalInfo = response.data.check;
        that.replacementIntervalInfo = response.data.replacement;
        that.okSeriesFactor = response.data.ok_series_factor;
        that.okSeriesThreshold = response.data.ok_series_threshold;
      }).catch(() => {
        Flash.error('Intervall-Information konnte nicht geladen werden');
      });
    },
  },
};
</script>
