<template>
  <section class="fx-page-content">
    <div class="fx-grid">
      <div class="fx-col">
        <div class="float-right" style="margin: 1rem 0 0.4rem 0;">
          <a id="link-dashboard-settings" href="/dashboard/edit"><i class="fas fa-sliders-v-square" /> Startseite anpassen</a>
        </div>
        <div class="clearfix" />
        <Notifications />

        <ProTrialCard v-if="protrial" :trial-remaining-days="trialRemainingDays" />
        <TrialCard
          v-if="trial"
          :trial-remaining-days="trialRemainingDays"
          :license-type="licenseType"
          :organization-id="organizationId"
        />
        <FirstSteps v-if="trial && showFirstSteps" />

        <TodayCard v-if="todayEnabled" :overdue-planned-jobs-count="overduePlannedJobsCount" :allow-editing="allowEditing" />

        <MyJobsCard v-if="myJobsEnabled" :allow-editing="allowEditing" />

        <UpcomingCard
          v-if="upcomingEnabled"
          :map-size="upcomingMapSize"
          :weeks="upcomingWeeks"
          :has-incidents-pro="hasIncidentsPro"
        />

        <StatsCard v-if="statsEnabled" />
      </div>
    </div>
  </section>
</template>

<script>
import ProTrialCard from './pro_trial_card.vue';
import TrialCard from './trial_card.vue';
import TodayCard from './today_card.vue';
import MyJobsCard from './my_jobs_card.vue';
import UpcomingCard from './upcoming_card.vue';
import StatsCard from './stats_card.vue';
import Notifications from './notifications.vue';
import FirstSteps from './first_steps.vue';

export default {
  components: {
    StatsCard,
    ProTrialCard,
    TrialCard,
    TodayCard,
    MyJobsCard,
    UpcomingCard,
    Notifications,
    FirstSteps,
  },
  props: {
    todayEnabled: {
      type: Boolean,
      default: false,
    },
    myJobsEnabled: {
      type: Boolean,
      default: false,
    },
    overduePlannedJobsCount: {
      type: Number,
      default: 0,
    },
    allowEditing: {
      type: Boolean,
      default: false,
    },
    statsEnabled: {
      type: Boolean,
      default: false,
    },
    upcomingEnabled: {
      type: Boolean,
      default: false,
    },
    upcomingMapSize: {
      type: String,
      default: 'small',
    },
    upcomingWeeks: {
      type: Number,
      default: 2,
    },
    hasIncidentsPro: {
      type: Boolean,
      default: true,
    },
    trial: {
      type: Boolean,
      default: false,
    },
    protrial: {
      type: Boolean,
      default: false,
    },
    trialRemainingDays: {
      type: Number,
      default: 0,
    },
    licenseType: {
      type: String,
      required: true,
    },
    organizationId: {
      type: String,
      required: true,
    },
    showFirstSteps: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
