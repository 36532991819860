<template>
  <div id="dashboard-card-my-jobs" class="fx-card">
    <div class="float-right" style="margin: .8rem;">
      <div class="fx-btn-group">
        <button id="my-jobs-app-login" class="fx-btn-primary" @click="openOTPModal()">
          App-Login per Scan
        </button>
        <a
          id="my-jobs-link-feeds"
          v-tippy="{ placement: 'top', duration: 100, arrow: true }"
          :href="`/users/${currentUser.id}/feeds`"
          class="fx-btn-secondary"
          title="Kalender-Abonnement"
        ><i class="far fa-calendar-alt fw" /></a>
        <button
          id="my-jobs-reload"
          v-tippy="{ placement: 'top', duration: 100, arrow: true }"
          class="fx-btn-secondary"
          title="Liste neu laden"
          @click="reset()"
        >
          <i class="fas fa-sync-alt" />
        </button>
      </div>
    </div>
    <div class="fx-card-content">
      <h2 class="fx">
        Meine Aufträge
      </h2>
    </div>
    <button id="my-jobs-tab-today" :class="$tabClasses('my_jobs_today')" @click="$setActiveTab('my_jobs_today', false)">
      <i class="far fa-calendar-check" /> Heute <span class="fx-tab-count">{{ todaysJobsCount }}</span>
    </button>
    <button id="my-jobs-tab-upcoming" :class="$tabClasses('my_jobs_upcoming')" @click="$setActiveTab('my_jobs_upcoming', false)">
      <i class="far fa-calendar-check" /> Demnächst <span class="fx-tab-count">{{ upcomingJobsCount }}</span>
    </button>
    <button id="my-jobs-tab-done" :class="$tabClasses('my_jobs_done')" @click="$setActiveTab('my_jobs_done', false)">
      <i class="far fa-calendar-check" /> Erledigt <span class="fx-tab-count">{{ doneJobsCount }}</span>
    </button>
    <hr style="margin:0;">

    <div v-if="$isTabInitialized('my_jobs_today')" v-show="$isTabActive('my_jobs_today')">
      <JobList
        :key="jobListKey"
        query-path="/dashboard/my_jobs_today.json"
        :limit="20"
        :allow-editing="allowEditing"
      >
        <template #empty-state>
          <div class="fx-list-empty-state">
            <img :src="emptyStateRelax" style="width: 144px; height: 144px;">
            <br>Keine Aufträge für heute
          </div>
        </template>
      </JobList>
    </div>
    <div v-if="$isTabInitialized('my_jobs_upcoming')" v-show="$isTabActive('my_jobs_upcoming')">
      <JobList
        :key="jobListKey"
        query-path="/dashboard/my_jobs_upcoming.json"
        :limit="20"
        :allow-editing="allowEditing"
      >
        <template #empty-state>
          <div class="fx-list-empty-state">
            <strong>Demnächst keine Aufträge</strong><br>
            Es werden zukünftige Aufträge bis zum {{ syncWindowEnddate }} angezeigt
          </div>
        </template>
      </JobList>
    </div>
    <div v-if="$isTabInitialized('my_jobs_done')" v-show="$isTabActive('my_jobs_done')">
      <JobList
        :key="jobListKey"
        query-path="/dashboard/my_jobs_done.json"
        :limit="20"
        :allow-editing="allowEditing"
      >
        <template #empty-state>
          <div class="fx-list-empty-state">
            <strong>Keine erledigten Aufträge</strong><br>Es werden zurückliegende
            Aufträge bis zum {{ syncWindowStartdate }} angezeigt
          </div>
        </template>
      </JobList>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import emptyStateRelax from 'images/empty-state-relax.png';
import { useCurrentUserStore } from 'stores/current_user';
import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';

import OTPLoginModal from 'components/otp_login_modal.vue';
import JobList from './job_list.vue';

export default {
  name: 'MyJobsCard',
  components: {
    JobList,
    OTPLoginModal,
  },
  props: {
    allowEditing: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const openOTPModal = () => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: OTPLoginModal,
            attrs: {
              onClose() {
                modalInstance.close();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    return {
      currentUser: useCurrentUserStore().currentUser,
      openOTPModal,
    };
  },
  data() {
    return {
      emptyStateRelax,
      activeTab: null,
      initializedTabs: new Set(),
      jobListKey: 0,
      todaysJobsCount: 0,
      upcomingJobsCount: 0,
      doneJobsCount: 0,
      syncWindowStartdate: null,
      syncWindowEnddate: null,
    };
  },
  computed: {
  },
  mounted() {
    this.loadCounts();
    this.$setActiveTab('my_jobs_today', false);
  },
  methods: {
    reset() {
      this.loadCounts();
      this.jobListKey += 1;
    },
    loadCounts() {
      const that = this;
      axios.get('/dashboard/my_jobs_counts').then((response) => {
        that.todaysJobsCount = response.data.today;
        that.upcomingJobsCount = response.data.upcoming;
        that.doneJobsCount = response.data.done;
        that.syncWindowStartdate = response.data.sync_window_startdate;
        that.syncWindowEnddate = response.data.sync_window_enddate;
      }).catch(() => {
      });
    },
  },
};
</script>
