<template>
  <div class="fx-card">
    <div class="fx-card-content">
      <h2 class="fx">
        Aktive Geräte
      </h2>
      <p>
        Smartphones und Tablets, mit denen dieser Nutzer angemeldet ist.
      </p>
    </div>

    <table class="material">
      <tr class="material__title">
        <th style="width: 5%" />
        <th style="width: 55%">
          Gerät
        </th>
        <th style="width: 20%">
          angemeldet seit
        </th>
        <th style="width: 20%" />
      </tr>

      <tr v-for="device in localDevices" :key="device.id">
        <td>
          <i v-if="device.family === 'android'" class="fab fa-android fa-2x" style="color: #A4C639;" />
          <i v-if="device.family === 'ios'" class="fab fa-apple fa-2x" style="color: #666;" />
        </td>
        <td>
          <strong>{{ device.user_agent }}</strong>
          <span style="color: #888;">{{ device.serial }}</span>
          <br>App Version:
          <i v-if="device.app_platform === 1" class="fab fa-android" style="color: #A4C639;" />
          <i v-if="device.app_platform === 2" class="fab fa-apple" style="color: #A4C639;" />
          <i v-if="device.app_platform === 3" class="fas fa-virus" style="color: #2088B2;" />
          {{ device.app_version }}
        </td>
        <td>{{ formatDate(device.created_at, 'DD.MM.YYYY HH:mm') }} Uhr</td>
        <td style="text-align: right;">
          <button v-if="device.api_key_id" class="fx-btn-skeleton" @click="confirmDiscardApiKey(device.api_key_id)">
            abmelden
          </button>
        </td>
      </tr>
      <tr v-if="localDevices.length === 0">
        <td colspan="4" style="text-align: center;">
          <em>Keine Geräte angemeldet.</em>
        </td>
      </tr>
    </table>

    <div class="fx-card-content" style="padding-top: 1rem">
      <div class="callout callout--info">
        Nutzer kann {{ maxParallelApps }} Geräte parallel verwenden.
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';
import confirmViaModal from 'helpers/confirm_modal';
import { ref } from 'vue';
import { DateFormatter } from 'mixins/formatters';

export default {
  name: 'UserDevices',
  mixins: [DateFormatter],
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const localDevices = ref([]);
    const maxParallelApps = ref(0);

    const loadDevices = () => {
      axios.get(`/users/${props.userId}/devices.json`)
        .then((response) => {
          localDevices.value = response.data.devices;
          maxParallelApps.value = response.data.max_parallel_apps;
        });
    };

    const discardApiKey = (apiKeyId) => {
      axios.delete(`/api_keys/${apiKeyId}.json`, {
      }).then(() => {
        Flash.info('Gerät wurde abgemeldet.');
        loadDevices();
      });
    };

    const confirmDiscardApiKey = (apiKeyId) => {
      confirmViaModal({
        title: 'Dieses Gerät abmelden?',
        // eslint-disable-next-line max-len
        message: 'Achtung möglicher Datenverlust: Der Nutzer wird auf dem Gerät sofort abmeldet und Änderungen, die noch nicht übertragen wurden, gehen verloren.',
        action: 'Abmelden',
      }).then(() => {
        discardApiKey(apiKeyId);
      });
    };

    return {
      localDevices,
      loadDevices,
      confirmDiscardApiKey,
      maxParallelApps,
    };
  },
  mounted() {
    this.loadDevices();
  },
};

</script>
