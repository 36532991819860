<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <h2 class="fx">
      Inbetriebnahme-Datum setzen
    </h2>
    <hr>
    <ServerErrors v-model="serverErrors" title="Inbetriebnahme-Datum konnte nicht gesetzt werden" margin-bottom="1rem" />
    <form @submit.prevent="submit">
      <p>{{ message }}</p>
      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory" for="thing_number">Inbetriebnahme-Datum</label>
        </div>
        <div class="fx-form-content">
          <VueDatePicker
            v-model="date"
            :locale="$i18n.locale"
            :auto-apply="true"
            model-type="yyyy-MM-dd"
            :enable-time-picker="false"
            :format="'dd.MM.yyyy'"
            :disabled-dates="isFutureDate"
          />
        </div>
      </div>

      <div v-if="scope !== 'thing'" class="callout callout--clock">
        Nach dem Speichern kann es einige Minuten dauern, bis die Änderungen an den Komponenten sichtbar werden.
        <div style="margin-top: 1rem;">
          Aktualisieren Sie die Liste mit dem Button
          <span style="background-color: #bbb; border-radius: 5px; padding: .4rem;"><i class="fas fa-sync-alt" /></span>
          , damit die Änderungen sichtbar werden.
        </div>
      </div>
      <hr>
      <button type="submit" class="fx-btn-primary float-right" style="margin-bottom: 0.5rem; margin-left: 0.5rem;">
        Speichern
      </button>
      <button class="fx-btn-secondary float-right" @click.prevent="$emit('close')">
        Abbrechen
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import ServerErrors from 'components/partials/server_errors.vue';

export default {
  name: 'SetDeploymentDateModal',
  components: {
    VueDatePicker,
    ServerErrors,
  },
  props: {
    message: {
      type: String,
      default: null,
    },
    scope: {
      type: String,
      default: null,
    },
    thing: {
      type: Object,
      default: null,
    },
    group: {
      type: Object,
      default: null,
    },
    installationId: {
      type: String,
      default: null,
    },
  },
  emits: ['close', 'updated'],
  data() {
    return {
      targetId: null,
      serverErrors: [],
      date: new Date().toISOString().split('T')[0],
      jobId: null,
    };
  },
  mounted() {
    if (this.scope === 'thing') {
      this.targetId = this.thing.id;
    } else if (this.scope === 'group') {
      this.targetId = this.group.id;
    } else {
      this.targetId = this.installationId;
    }
  },
  methods: {
    // --- used by date pick
    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },
    submit() {
      this.updateViaAPI();
    },
    updateViaAPI() {
      if (this.scope === 'thing') {
        this.updateThingViaAPI();
      } else if (this.scope === 'group') {
        this.updateGroupViaAPI();
      } else {
        this.updateInstallationViaAPI();
      }
    },
    updateThingViaAPI() {
      const that = this;
      that.serverErrors = [];
      axios.put(`/things/${this.targetId}/installation_date.json`, {
        installation_date: this.date,
      }).then((response) => {
        Flash.info('Änderung gespeichert');
        this.$emit('updated', { ...response.data.thing });
      }).catch((error) => {
        if (error.response && error.response.status === 400) {
          that.serverErrors = error.response.data.things;
        } else {
          Flash.error('Die Änderung konnte nicht gespeichert werden');
        }
      });
    },
    updateGroupViaAPI() {
      const that = this;
      that.serverErrors = [];
      axios.put(`/groups/${this.targetId}/thing_installation_date.json`, {
        installation_date: this.date,
      }).then((response) => {
        Flash.info('Änderung gespeichert');
        this.$emit('updated', { ...response.data.group });
      }).catch((error) => {
        if (error.response && error.response.status === 400) {
          that.serverErrors = error.response.data.groups;
        } else {
          Flash.error('Die Änderung konnte nicht gespeichert werden');
        }
      });
    },
    updateInstallationViaAPI() {
      const that = this;
      that.serverErrors = [];
      axios.put(`/installations/${this.targetId}/thing_installation_date.json`, {
        installation_date: this.date,
      }).then((response) => {
        Flash.info('Änderung gespeichert');
        this.$emit('updated', { ...response.data.thing });
      }).catch((error) => {
        if (error.response && error.response.status === 400) {
          that.serverErrors = error.response.data.installations;
        } else {
          Flash.error('Die Änderung konnte nicht gespeichert werden');
        }
      });
    },
  },
};
</script>
