<template>
  <button
    class="fx-btn-skeleton"
    :title="$t('delete')"
    :disabled="!enabled"
    @click="click();"
  >
    <i class="fas fa-trash-alt" />
  </button>
</template>

<script>

export default {
  name: 'ButtonDeleteIcon',
  props: {
    enabled: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const click = () => {
      if (props.enabled) {
        emit('click');
      }
    };

    return {
      click,
    };
  },
};
</script>
