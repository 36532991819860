<template>
  <div class="fx-card">
    <div class="fx-card-content">
      <h2 class="fx">
        Sicherheitsrichtlinie (2FA)
      </h2>
      <p>
        Legen Sie fest, für welche Nutzerrollen Zwei-Faktor-Authentifizierung erforderlich ist. Wir empfehlen 2FA mindestens für die Rolle 'Administration'.
        <help-article article-id="info_2fa_policy">
          {{ $t('action.learn_more') }}
        </help-article>
      </p>
      <p>
        <span style="margin-right: 1rem;">
            <input id="admin" v-model="admin" type="checkbox" name="admin">
            <label class="inline" for="admin">
              Administration
            </label>
            <input id="manage" v-model="manage" type="checkbox" name="manage">
            <label class="inline" for="manage">
              Planen und Daten pflegen
            </label>
            <input id="execute" v-model="execute" type="checkbox" name="execute">
            <label class="inline" for="execute">
              Aufträge durchführen
            </label>
            <input id="web" v-model="web" type="checkbox" name="web">
            <label class="inline" for="web">
              Web-Zugang
            </label>
        </span>
      </p>

      <div>
        <button class="fx-btn-primary" @click="confirmUpdate()">
          Sicherheitsrichtlinie aktualisieren
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';
import confirmViaModal from 'helpers/confirm_modal';
import { ref } from 'vue';

export default {
  name: 'SecurityPolicy',
  props: {
    organizationId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const admin = ref(false);
    const manage = ref(false);
    const execute = ref(false);
    const web = ref(false);

    const updateSecurityPolicy = () => {
      axios.put(`/organizations/${props.organizationId}/security_policies.json`, {
        admin: admin.value,
        manage: manage.value,
        execute: execute.value,
        web: web.value,
      }).then(() => {
        Flash.info('Sicherheitsrichtlinie aktualisiert');
      });
    };

    const confirmUpdate = () => {
      confirmViaModal({
        message: 'Sind Sie sicher, dass Sie die Sicherheitsrichtlinie aktualisieren möchten?',
        title: 'Sicherheitsrichtlinie aktualisieren?',
        action: 'Jetzt aktualisieren',
      }).then(() => {
        updateSecurityPolicy();
      });
    };

    return {
      admin,
      manage,
      execute,
      web,
      confirmUpdate,
    };
  },
  mounted() {
    this.loadPolicy();
  },
  methods: {
    initCheckboxes(securityPolicy) {
      this.admin = securityPolicy.roles_2fa.includes('admin');
      this.manage = securityPolicy.roles_2fa.includes('manage');
      this.execute = securityPolicy.roles_2fa.includes('execute');
      this.web = securityPolicy.roles_2fa.includes('web');
    },
    loadPolicy() {
      axios.get(`/organizations/${this.organizationId}/security_policies.json`)
        .then((response) => {
          this.initCheckboxes(response.data.security_policy);
        });
    },
  },
};

</script>
