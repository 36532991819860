<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <h2 class="fx">
      {{ title }}
    </h2>
    <hr>
    <ServerErrors v-model="serverErrors" title="Typ konnte nicht gespeichert werden" margin-bottom="1rem" />
    <form>
      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline" for="incident_type_position">Position</label>
        </div>
        <div class="fx-form-content">
          <input
            id="incident_type_position"
            v-model="localIncidentType.position"
            type="text"
            class="has-form-field-hint"
            placeholder="optional"
          >
          <div class="form-field-hint">
            Optional. Tragen Sie eine Zahl ein, um die Sortierung bei der Auswahl in der App festzulegen
          </div>
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory" for="incident_type_name">Name</label>
        </div>
        <div class="fx-form-content">
          <input id="incident_type_name" v-model="localIncidentType.name" type="text">
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory" for="incident_type_default_severity">Schweregrad</label>
        </div>
        <div class="fx-form-content">
          <select id="incident_type_default_severity" v-model="localIncidentType.default_severity" class="has-form-field-hint">
            <option value="low">
              niedrig
            </option>
            <option value="normal">
              normal
            </option>
            <option value="high">
              schwerwiegend
            </option>
            <option value="notice">
              Hinweis
            </option>
          </select>
          <div class="form-field-hint">
            Standardwert, den Sie beim Erfassen einer Störung verändern können.
          </div>
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-content">
          <!-- portal -->
          <input id="incident_type_portal" v-model="localIncidentType.portal" type="checkbox">
          <label class="inline" for="incident_type_portal">im Auftraggeberportal verfügbar</label>
          <HelpHover content="available_for_portal" />
          <br>

          <!-- active -->
          <input id="incident_type_active" v-model="localIncidentType.active" type="checkbox">
          <label class="inline" for="incident_type_active">aktiv</label>
          <HelpHover content="active_passive" />
          <br>
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline" for="incident_type_default_description">Beschreibung</label>
        </div>
        <div class="fx-form-content">
          <div v-if="!features.incidentsPro" class="secondary">
            Mit <a href="https://support.foxtagapp.de/t/leistungsumfang-foxtag-pro/203" target="_blank">Foxtag <sup>pro</sup></a> können Sie an dieser Stelle einen Text hinterlegen, der die Beschreibung bei neuen Störungen automatisch ausfüllt.
          </div>

          <template v-if="features.incidentsPro">
            <textarea
              id="incident_type_default_description"
              v-model="localIncidentType.default_description"
              rows="8"
              class="has-form-field-hint"
              name="incident_type[default_description]"
            />
            <div class="form-field-hint">
              Standardtext, den Sie beim Erfassen einer Störung verändern können.
            </div>
          </template>
        </div>
      </div>
    </form>
    <hr>
    <input
      type="submit"
      value="Störungstyp speichern"
      class="fx-btn-primary float-right"
      style="margin-bottom: 0.5rem;"
      @click.prevent="submit"
    >
    <div class="clearfix" />
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';
import { useCurrentUserStore } from 'stores/current_user';
import ServerErrors from 'components/partials/server_errors.vue';
import HelpHover from 'components/help_hover.vue';
import FeatureFlipper from 'mixins/feature_flipper';

export default {
  name: 'EditIncidentTypeModal',
  components: {
    ServerErrors,
    HelpHover,
  },
  mixins: [FeatureFlipper],
  props: {
    installationTypeId: {
      type: String,
      required: true,
    },
    incidentType: {
      type: Object,
      default: null,
    },
  },
  emits: ['created', 'updated', 'close'],
  setup() {
    return { currentUser: useCurrentUserStore().currentUser };
  },
  data() {
    return {
      localIncidentType: {
        id: null,
        position: null,
        name: '',
        default_severity: 'normal',
        default_description: null,
        active: true,
        portal: true,
      },
      serverErrors: [],
    };
  },
  computed: {
    title() {
      if (this.localIncidentType.id) {
        return 'Störungstyp bearbeiten';
      }
      return 'Störungstyp hinzufügen';
    },
  },
  mounted() {
    if (this.incidentType) {
      this.localIncidentType.id = this.incidentType.id;
      this.localIncidentType.position = this.incidentType.position;
      this.localIncidentType.name = this.incidentType.name;
      this.localIncidentType.active = this.incidentType.active;
      this.localIncidentType.portal = this.incidentType.portal;
      this.localIncidentType.default_severity = this.incidentType.default_severity;
      this.localIncidentType.default_description = this.incidentType.default_description;
    }
  },
  methods: {
    submit() {
      if (this.localIncidentType.id) {
        this.updateViaAPI();
      } else {
        this.createViaAPI();
      }
    },
    createViaAPI() {
      const that = this;
      that.serverErrors = [];
      axios.post('/incident_types.json', {
        incident_type: {
          installation_type_id: this.installationTypeId,
          position: this.localIncidentType.position,
          name: this.localIncidentType.name,
          active: this.localIncidentType.active,
          portal: this.localIncidentType.portal,
          default_severity: this.localIncidentType.default_severity,
          default_description: this.localIncidentType.default_description,
        },
      }).then((response) => {
        Flash.info('Störungstyp hinzugefügt');
        that.$emit('created', { ...response.data.incident_type });
        that.$emit('close');
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.incident_types;
        } else {
          Flash.error('Störungstyp konnte nicht gespeichert werden');
        }
      });
    },
    updateViaAPI() {
      const that = this;
      that.serverErrors = [];
      axios.put(`/incident_types/${this.localIncidentType.id}.json`, {
        incident_type: {
          installation_type_id: this.installationTypeId,
          position: this.localIncidentType.position,
          name: this.localIncidentType.name,
          active: this.localIncidentType.active,
          portal: this.localIncidentType.portal,
          default_severity: this.localIncidentType.default_severity,
          default_description: this.localIncidentType.default_description,
        },
      }).then((response) => {
        Flash.info('Störungstyp gespeichert');
        this.$emit('updated', { ...response.data.incident_type });
        that.$emit('close');
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.incident_types;
        } else {
          Flash.error('Störungstyp konnte nicht gespeichert werden');
        }
      });
    },
  },
};
</script>
