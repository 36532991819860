<template>
  <div class="fx-list-row">
    <div class="fx-single-control-col">
      <template v-if="!isAssignedToDifferentJob">
        <div class="yesnoswitch" style="float: none;">
          <input
            :id="incident.id"
            class="yesnoswitch-checkbox"
            type="checkbox"
            :checked="isAssignedToJob"
            @change="toggleAssigned()"
          >
          <label class="yesnoswitch-label" :for="incident.id">
            <span class="yesnoswitch-inner" />
            <span class="yesnoswitch-switch" />
          </label>
        </div>
      </template>
    </div>
    <div class="fx-50px-col">
      <strong>#{{ incident.number }}</strong>
    </div>
    <div class="fx-220px-col">
      <span style="font-weight: bold;" :title="incident.incident_type_name">{{ truncate(incident.incident_type_name, 200)
      }}</span>
      <div v-if="incident.severity != 'normal'" class="v--hover">
        <span :class="'severity-' + incident.severity">
          {{ $t('activerecord.attributes.incident.severities.' + incident.severity) }}
        </span>
      </div>
    </div>
    <div class="fx-100px-col">
      <div class="v--hover">
        <span :class="'fx-status incident-' + incident.status">
          {{ $t('activerecord.attributes.incident_status.statuses.' + incident.status) }}
        </span>
      </div>
    </div>
    <div class="fx-100px-col">
      <b>{{ statusDate }}</b>
      {{ statusDateTime }}
    </div>

    <div class="fx-200px-col">
      <span v-if="incident.thing_id">{{ thingLabel }}</span>
      <span v-else class="secondary">Keine Angabe</span>
    </div>

    <div class="fx-220px-col">
      <template v-if="isAssignedToDifferentJob">
        <a v-if="incident.assigned_job_id" :href="assignedJobUrl">
          <i style="font-size: 17px;" class="far fa-calendar-check" /> Zielauftrag {{ assignedJobDate }}
        </a>

        <template v-if="isAssignedToDifferentJob">
          <a class="fx-btn-secondary small" @click="toggleAssigned()">in diesen Auftrag</a>
        </template>
      </template>
    </div>

    <div class="clearfix" />
  </div>
</template>

<script>
import moment from 'moment';
import Flash from 'flash/index';
import { TruncateFormatter } from 'mixins/formatters';
import Api from '../../incidents/api';

export default {
  name: 'IncidentForPlanning',
  mixins: [TruncateFormatter],
  props: {
    incident: {
      type: Object,
      required: true,
    },
    jobId: {
      type: String,
      required: true,
    },
  },
  emits: ['changed'],
  computed: {
    isAssignedToDifferentJob() {
      return ((this.incident.assigned_job_id !== this.jobId) && (this.incident.assigned_job_id != null));
    },
    isAssignedToJob() {
      return (this.incident.assigned_job_id === this.jobId);
    },
    assignedJobUrl() {
      return `/jobs/${this.incident.assigned_job_id}`;
    },
    statusDate() {
      return moment(this.incident.statusdate).format('DD.MM.YYYY');
    },
    statusDateTime() {
      return `${moment(this.incident.statusdate).format('HH:mm')} Uhr`;
    },
    address() {
      return this.incident.installation.location_address;
    },
    locationName() {
      const name = this.incident.installation.location_name;
      return name ? name.replace(/c\/o/g, '<br>c/o') : null;
    },
    thingLabel() {
      let name = ` ${this.incident.thing_type_name}`;
      if (this.incident.thing_sub_number > 0) {
        name = `.${this.incident.thing_sub_number}${name}`;
      }
      name = this.incident.thing_number + name;
      if (this.incident.thing_group_number != null && this.incident.thing_group_number >= 0) {
        name = `${this.incident.thing_group_number}.${name}`;
      }
      return name;
    },
    assignedJobDate() {
      if (this.incident.assigned_job_date != null) {
        return this.incident.assigned_job_date;
      }
      return '';
    },
  },
  methods: {
    toggleAssigned() {
      if (this.incident.assigned_job_id !== this.jobId) {
        this.assignJob();
      } else {
        this.unAssignJob();
      }
    },
    assignJob() {
      const that = this;
      Api.assignIncidentToJob({ incidentId: this.incident.id, assignedJobId: this.jobId })
        .then(() => {
          that.incident.assigned_job_id = this.jobId;
          this.$emit('changed');
        })
        .catch(() => {
          Flash.error('Störung konnte nicht eingeplant werden');
        });
    },
    unAssignJob() {
      const that = this;
      Api.assignIncidentToJob({ incidentId: this.incident.id, assignedJobId: null })
        .then(() => {
          that.incident.assigned_job_id = null;
          this.$emit('changed');
        })
        .catch(() => {
          Flash.error('Störung konnte nicht ausgeplant werden');
        });
    },
  },
};
</script>

<style></style>
