<template>
  <div class="fx-card-notop">
    <table class="material top-align">
      <colgroup>
        <col>
        <col>
        <col style="width: 4rem;">
      </colgroup>
      <tbody>
        <tr class="middle-align">
          <th style="width: 8rem;">
            Status
          </th>
          <td colspan="2">
            <span v-if="isStatus('reported')" class="fx-status incident-reported">{{ statusText('reported') }}</span>
            <span v-if="isStatus('opened')" class="fx-status incident-opened">{{ statusText('opened') }}</span>
            <span v-if="isStatus('closed')" class="fx-status incident-closed">{{ statusText('closed') }}</span>
          </td>
        </tr>
        <tr>
          <th>Schweregrad</th>
          <td colspan="2">
            <span :class="'severity-' + incident.severity">
              {{ $t('activerecord.attributes.incident.severities.' + incident.severity) }}
            </span>
          </td>
        </tr>
        <tr v-if="incidentStatus">
          <th>Lösung</th>
          <td colspan="2">
            <span :class="'resolution-' + incidentStatus.resolution">
              {{ $t('activerecord.attributes.incident_status.resolutions.' + incidentStatus.resolution) }}
            </span>
          </td>
        </tr>
        <tr>
          <th>Gemeldet von</th>
          <td colspan="2">
            <div v-if="incident.reported_by">
              {{ incident.reported_by }}
            </div>
            <div v-else class="secondary">
              keine Angabe
            </div>
          </td>
        </tr>
        <tr>
          <th>Zielauftrag</th>
          <template v-if="!loading && assignedJob">
            <td>
              <Labels :labels="assignedJob.labels" />
              <div>
                <strong><a
                  :href="assignedJobUrl"
                  class="no-color"
                >{{ assignedJob.label }}</a></strong>
                <span v-if="assignedJob.days_away > 0" style="white-space: nowrap;" class="secondary">(in
                  {{ assignedJob.days_away }} Tagen)</span>
                <span v-if="assignedJob.days_away == 0" class="secondary">heute</span>
                <br>
                Status <span class="fx-status" :class="assignedJob.status">{{ jobStatusText(assignedJob.status) }}</span>
              </div>
            </td>
          </template>
          <template v-if="!loading && !assignedJob">
            <td colspan="2">
              <span class="secondary">kein Zielauftrag</span>
            </td>
          </template>
          <template v-if="loading">
            <td colspan="2">
              <span class="secondary">Daten werden geladen...</span>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import Labels from 'components/partials/labels.vue';
import IncidentModel from 'apps/incidents/incident_model';

export default {
  name: 'PortalIncidentDetails',
  components: {
    Labels,
  },
  props: {
    incident: {
      type: Object,
      required: true,
    },
    jobUrl: {
      type: String,
      default: '/portal/jobs',
    },
  },
  data() {
    return {
      assignedJob: null,
      loading: false,
    };
  },
  computed: {
    incidentStatus() {
      return IncidentModel.getCurrentStatus(this.incident);
    },
    assignedJobUrl() {
      return `${this.jobUrl}/${this.assignedJob.id}`;
    },
  },
  mounted() {
    this.assignedJob = this.incident.assigned_job;
  },
  methods: {
    isStatus(status) {
      return this.incidentStatus != null && this.incidentStatus.status === status;
    },
    statusText(status) {
      return this.$t(`activerecord.attributes.incident_status.statuses.${status}`);
    },
    jobStatusText(status) {
      return this.$t(`job.status.${status}`);
    },
  },
};
</script>
