<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <h2 class="fx">
      {{ title }}
    </h2>
    <hr>
    <ServerErrors v-model="serverErrors" title="Artikel konnte nicht gespeichert werden" margin-bottom="1rem" />
    <form>
      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline">Sortierung</label>
        </div>
        <div class="fx-form-content">
          <input v-model="localArticle.position" type="text">
          <div class="form-field-hint">
            Mit diesem Feld können Sie die Sortierung von Artikeln auf dem Bericht festlegen.
          </div>
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline">Gruppe</label>
        </div>
        <div class="fx-form-content">
          <SelectArticleGroups
            v-model="localArticle.group"
            :article-groups="articleGroups"
            no-selection-label=""
            @input="updateArticleGroup"
          />
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory">Art.-Nr.</label>
        </div>
        <div class="fx-form-content">
          <input v-model="localArticle.number" type="text">
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory">Bezeichnung</label>
        </div>
        <div class="fx-form-content">
          <input v-model="localArticle.name" type="text">
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory">Einheit</label>
        </div>
        <div class="fx-form-content">
          <input v-model="localArticle.unit" type="text">
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-content">
          <input id="article_active" v-model="localArticle.active" type="checkbox">
          <label class="inline" for="article_active">aktiv</label>
          <HelpHover content="active_passive" />
        </div>
      </div>
    </form>
    <hr>
    <input
      type="submit"
      value="Artikel speichern"
      class="fx-btn-primary float-right"
      style="margin-bottom: 0.5rem;"
      @click.prevent="submit"
    >
    <div class="clearfix" />
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';

import ServerErrors from 'components/partials/server_errors.vue';
import HelpHover from 'components/help_hover.vue';

import SelectArticleGroups from 'components/select/select_article_group.vue';

export default {
  name: 'EditThingTypeModal',
  components: {
    ServerErrors,
    SelectArticleGroups,
    HelpHover,
  },
  props: {
    articleGroups: {
      type: Array,
      required: true,
    },
    article: {
      type: Object,
      default: null,
    },
  },
  emits: ['close', 'created', 'updated'],
  data() {
    return {
      localArticle: {
        number: '',
        name: '',
        group: null,
        position: null,
        unit: 'Stk.',
        active: true,
      },
      serverErrors: [],
    };
  },
  computed: {
    title() {
      if (this.localArticle.id) {
        return 'Artikel bearbeiten';
      }
      return 'Artikel hinzufügen';
    },
  },
  mounted() {
    if (this.article) {
      this.localArticle = { ...this.article };
    }
  },
  methods: {
    opened() {
    },
    submit() {
      if (this.localArticle.id) {
        this.updateViaAPI();
      } else {
        this.createViaAPI();
      }
    },
    updateArticleGroup(newGroup) {
      this.localArticle.group = newGroup;
    },
    createViaAPI() {
      const that = this;
      that.serverErrors = [];
      axios.post('/articles.json', {
        article: {
          position: this.localArticle.position,
          group: this.localArticle.group,
          name: this.localArticle.name,
          number: this.localArticle.number,
          unit: this.localArticle.unit,
          active: this.localArticle.active,
        },
      }).then(() => {
        Flash.info('Artikel hinzugefügt');
        that.$emit('close');
        that.$emit('created');
      }).catch((error) => {
        if (error.response && error.response.status === 400) {
          that.serverErrors = error.response.data.articles;
        } else {
          Flash.error('Artikel konnte nicht gespeichert werden');
        }
      });
    },
    updateViaAPI() {
      const that = this;
      that.serverErrors = [];
      axios.put(`/articles/${this.localArticle.id}.json`, {
        article: {
          position: this.localArticle.position,
          group: this.localArticle.group,
          name: this.localArticle.name,
          number: this.localArticle.number,
          unit: this.localArticle.unit,
          active: this.localArticle.active,
        },
      }).then((response) => {
        Flash.info('Artikel gespeichert');
        that.$emit('close');
        this.$emit('updated', { ...response.data.article });
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.articles;
        } else {
          Flash.error('Artikel konnte nicht gespeichert werden');
        }
      });
    },
  },
};
</script>

<style></style>
