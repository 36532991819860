<template>
  <div>
    <div class="fx-grid" style="margin-top: 0;">
      <div class="fx-col">
        <div style="display:flex; justify-content: space-between; margin-top: 0">
          <div style="flex: 1;">
            <h1 class="fx" style="flex: 1;">
              Auftragstypen
            </h1>
            <p>
              Die Auftragstypen legen den genauen <strong>Ablauf in der App</strong> und das <strong>verwendete Berichtslayout</strong> fest.
              <help-article article-id="learn_more_jobtypes">
                Mehr erfahren
              </help-article>
            </p>
          </div>
        </div>

        <div class="fx-card">
          <div class="fx-list-row" style="background-color: #f2f2f2; padding: 1rem; ">
            <div class="fx-single-control-col" style="margin:0;">
              <a class="fx-btn-ico-primary" :href="`/job_types/new?installation_type=${installationTypeId}`">
                <i class="fas fa-plus" /> Auftragstyp hinzufügen
              </a>
            </div>

            <div class="clearfix" />
          </div>

          <div class="fx-list-row">
            <div class="fx-400px-col">
              <strong>Auftragstyp</strong>
            </div>
            <div class="fx-300px-col">
              <strong>Berichtslayout</strong>
            </div>
            <div class="fx-180px-col">
              <strong>Anzahl Aufträge</strong>
            </div>
            <div class="clearfix" />
          </div>

          <div v-for="jobType in localJobTypes" :key="jobType.id" class="fx-list-row">
            <div class="fx-single-control-col">
              <div class="fx-btn-group">
                <a class="fx-btn-skeleton" :href="`/job_types/${jobType.id}/edit`">{{ $t('edit') }}</a>
                <ButtonDeleteIcon :disabled="jobType.jobs_count > 0" @click="confirmDeleteThingType(jobType)" />
              </div>
            </div>

            <div class="fx-400px-col">
              <div v-if="jobType.number" class="secondary">
                {{ jobType.number }}
              </div>

              <span v-if="jobType.active">
                <a class="no-color" :href="`/job_types/${jobType.id}/edit`">{{ jobType.name }}</a>
              </span>
              <span v-else class="secondary">
                {{ jobType.name }} (passiv)
              </span>
              <span v-if="jobType.default" class="fx-label" style="margin-left: 0.2rem; background: rgb(170, 170, 170);">Standard</span>
            </div>

            <div class="fx-300px-col">
              <a :href="`/report_layouts/${jobType.report_layout_id}`" class="no-color">{{ jobType.report_layout_name }}</a>
            </div>

            <div class="fx-180px-col" :class="{ secondary: jobType.jobs_count === 0 }">
              <i class="far fa-cube" />
              <span style="margin-left: 0.3rem;">{{ jobType.jobs_count }}</span>
            </div>

            <div class="clearfix" />
          </div>

          <div v-if="showNoResults" style="padding: 1rem; text-align: center;">
            Keine Auftragstypen gefunden
          </div>
        </div>
        <Pagination
          v-model="pagination"
          i18n-key="activerecord.models.job_type"
          :init-from-url="false"
          :update-url="false"
          @navigate="loadPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';
import EntityList from 'helpers/entity_list';
import { ref } from 'vue';
import confirmViaModal from 'helpers/confirm_modal';

import Pagination from 'components/pagination.vue';
import ButtonDeleteIcon from 'components/button_delete_icon.vue';

export default {
  name: 'JobTypesList',
  components: {
    ButtonDeleteIcon,
    Pagination,
  },
  props: {
    installationTypeId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const localJobTypes = new EntityList();
    const pagination = ref({
      page: 1,
      pageCount: 1,
      totalCount: 0,
      limit: 25,
    });

    const load = () => {
      axios.get(`/installation_types/${props.installationTypeId}/job_types.json`, {
        params: {
          page: pagination.value.page,
          limit: pagination.value.limit,
        },
      }).then((response) => {
        localJobTypes.value = response.data.job_types;
        pagination.value.page = response.data.meta.current_page;
        pagination.value.pageCount = response.data.meta.total_pages;
        pagination.value.totalCount = response.data.meta.total_count;
      }).catch((err) => {
        if (err.message !== 'Request aborted') {
          Flash.error(err.message);
        }
      });
    };

    const deleteViaApi = (thingType) => {
      axios.delete(`/job_types/${thingType.id}.json`).then(() => {
        localJobTypes.remove(thingType);
        Flash.info('Auftragstyp gelöscht');
      });
    };

    const loadPage = (changedPagination) => {
      pagination.value = { ...changedPagination };
      load();
    };

    // --- modals

    const confirmDeleteThingType = (jobType) => {
      confirmViaModal({
        title: `Auftragstyp ${jobType.name} löschen?`,
      }).then(() => {
        deleteViaApi(jobType);
      });
    };

    return {
      loadPage,
      pagination,
      confirmDeleteThingType,
      localJobTypes: localJobTypes.reactiveList,
      thingType: {},
    };
  },
  computed: {
    showNoResults() {
      return (this.localJobTypes.length === 0);
    },
  },
};
</script>
