<template>
  <div v-if="initialDataLoaded">
    <section class="fx-page-content">
      <div v-if="jobDirty" style="background-color: #8dcf54; margin-top: -3px; text-align: right;">
        <div class="fx-grid">
          <div style="flex: 1; color: #2C556E; font-size: .9rem; font-weight: 400; margin: .4rem 1rem 0 0; display: inline-block;">
            Auftragsdaten wurden geändert
            <button class="fx-btn-primary" style="margin: .5rem 0  .5rem .5rem;" @click="recreateReport()">
              Bericht neu erzeugen
            </button>
          </div>
        </div>
      </div>
      <section class="fx-header">
        <div class="fx-grid">
          <div class="fx-col" style="padding: 0 1rem; margin-bottom:0;">
            <ul class="fx-breadcrumps">
              <li><a href="/start">Start</a></li>
              <li><a href="/jobs">Aufträge</a></li>
              <li v-if="job.number">
                {{ job.number }}
              </li>
            </ul>

            <div class="job-installation-infobox" style="font-size: 0.9rem;">
              <a :href="installationPath(job.installation.id)" class="no-color">
                <div class="secondary">{{ installation.number }}</div>
                {{ installation.full_name }}
                <br>
                {{ installation.location.active_name }}
              </a>
            </div>

            <div class="fx-header-title">
              <h1 class="fx-header-title">
                <span class="fx-header-title_number">{{ job.number }}</span>
                <span class="fx-header-title_name">{{ typeAndNumber }}</span>
              </h1>
            </div>

            <div class="statuses">
              <div class="statuses-label">
                Status:
              </div>
              <ul class="statuses-list">
                <li class="statuses-list-item">
                  <span :class="classesForStatus('unplanned')">in Planung</span>
                </li>
                <li class="statuses-list-item">
                  <span :class="classesForStatus('planned')">geplant</span>
                </li>
                <li class="statuses-list-item">
                  <span :class="classesForStatus('active')">Auftrag läuft</span>
                </li>
                <li class="statuses-list-item">
                  <span :class="classesForStatus('closed')">erledigt</span>
                </li>
                <li v-if="job.status !== 'free_of_charge'" class="statuses-list-item">
                  <span :class="classesForStatus('invoiced')">abgerechnet</span>
                </li>
                <li v-if="job.status === 'free_of_charge'" class="statuses-list-item">
                  <span :class="classesForStatus('free_of_charge')">nicht berechnet</span>
                </li>
              </ul>
            </div>

            <button :class="$tabClasses('tab-overview')" @click="$setActiveTab('tab-overview')">
              Übersicht
            </button>

            <button :class="$tabClasses('tab-lifecycle')" @click="$setActiveTab('tab-lifecycle')">
              In-/Außerbetriebnahmen
              <span class="fx-tab-count">{{ lifecycleEventsCount }}</span>
            </button>

            <button :class="$tabClasses('tab-checks')" @click="$setActiveTab('tab-checks')">
              Prüfungen
              <span class="fx-tab-count">{{ checksCount }}</span>
            </button>

            <button :class="$tabClasses('tab-articles')" @click="$setActiveTab('tab-articles')">
              Artikelpositionen
              <span class="fx-tab-count">{{ jobArticlesCount }}</span>
            </button>

            <button :class="$tabClasses('tab-incidents')" @click="$setActiveTab('tab-incidents')">
              <i class="fas fa-exclamation-triangle" /> Störungen
              <span class="fx-tab-count">{{ incidentsCount }}</span>
            </button>

            <button :class="$tabClasses('tab-photos')" @click="$setActiveTab('tab-photos')">
              <i class="fas fa-camera" /> Fotos
              <span class="fx-tab-count">{{ attachmentsCount }}</span>
            </button>

            <button :class="$tabClasses('tab-notes')" @click="$setActiveTab('tab-notes')">
              <i class="fas fa-comment" /> Notizen
              <span class="fx-tab-count">{{ notesCount }}</span>
            </button>
          </div>
        </div>
      </section>
      <section class="list-content">
        <div v-if="$isTabInitialized('tab-overview')" v-show="$isTabActive('tab-overview')" class="tab-panel">
          <TabOverview
            v-model="job"
            :job-result="jobResult"
            :installation="installation"
            :announcements-enabled="announcementsEnabled"
            :can-edit="canEdit"
            :customer="job.customer"
            :location="job.location"
            @recreate-report="recreateReport()"
          />
        </div>

        <div v-if="$isTabInitialized('tab-checks')" v-show="$isTabActive('tab-checks')" class="tab-panel">
          <TabChecks
            v-model="job"
            :thing-label="thingLabel"
            :group-label="groupLabel"
            :group-separator="groupSeparator"
          />
        </div>

        <div v-if="$isTabInitialized('tab-lifecycle')" v-show="$isTabActive('tab-lifecycle')" class="tab-panel">
          <TabLifecycle
            v-model="job"
            :thing-label="thingLabel"
            :group-label="groupLabel"
            :group-separator="groupSeparator"
          />
        </div>

        <div v-if="$isTabInitialized('tab-articles')" v-show="$isTabActive('tab-articles')" class="tab-panel">
          <div class="fx-grid">
            <div class="fx-col">
              <JobArticlesList
                :job-articles="job.job_articles"
                :recreate-report="true"
                :can-edit="canEditArticles"
                :job-id="job.id"
                :title="jobArticlesLabel"
                :has-import="featureJobArticlesPro"
                @changed="changed"
              />
            </div>
          </div>
        </div>

        <div v-if="$isTabInitialized('tab-photos')" v-show="$isTabActive('tab-photos')" class="tab-panel">
          <TabPhotos v-model="job" @changed="changed" />
        </div>

        <div v-if="$isTabInitialized('tab-incidents')" v-show="$isTabActive('tab-incidents')" class="tab-panel">
          <TabIncidents v-model="job" :thing-label="thingLabel" />
        </div>

        <div v-if="$isTabInitialized('tab-notes')" v-show="$isTabActive('tab-notes')" class="tab-panel">
          <div class="fx-grid">
            <div class="fx-col">
              <InstallationNotesIndex
                :installation-id="job.installation_id"
                :job-id="job.id"
                @changed="refreshCounts"
              />
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import _ from 'lodash';
import axios from 'axios';
import { ref } from 'vue';
import { useCurrentUserStore } from 'stores/current_user';
import waitForAsyncModal from 'helpers/wait_for_async_modal';

import { DateFormatter } from 'mixins/formatters';
import FoxtagPaths from 'mixins/foxtag_paths';
import JobArticlesList from 'apps/jobs/job_articles/list.vue';
import InstallationNotesIndex from 'apps/installations/notes/index.vue';

import TabOverview from './tab_overview.vue';
import TabPhotos from './tab_photos.vue';
import TabChecks from './tab_checks.vue';
import TabLifecycle from './tab_lifecycle.vue';
import TabIncidents from './tab_incidents.vue';

export default {
  name: 'JobShow',
  components: {
    JobArticlesList,
    InstallationNotesIndex,
    TabOverview,
    TabPhotos,
    TabIncidents,
    TabChecks,
    TabLifecycle,
  },
  mixins: [FoxtagPaths, DateFormatter],
  props: {
    jobId: {
      type: String,
      required: true,
    },
    featureIncidentsPro: {
      type: Boolean,
      required: false,
    },
    featureJobArticlesPro: {
      type: Boolean,
      default: true,
    },
    jobArticlesLabel: {
      type: String,
      required: true,
    },
    announcementsEnabled: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    thingLabel: {
      type: String,
      default: 'Komponente',
    },
    groupLabel: {
      type: String,
      default: 'Gruppe',
    },
    groupSeparator: {
      type: String,
      default: '/',
    },
  },
  setup(props) {
    const jobDirty = ref(false);

    const jobPath = (id) => `/jobs/${id}`;

    const reportRecreated = () => {
      // TODO: this should reset dirty status and allow resending emails (unless autopublish) w/o reload
      jobDirty.value = false;
      window.location.href = jobPath(props.jobId);
    };

    const waitForReportCreated = (sidekiqJobId) => {
      waitForAsyncModal({
        title: 'Bericht wird neu erzeugt ...',
        message: 'Das Erzeugen von Berichten dauert in der Regel wenige Sekunden.',
        sidekiqJobId,
      }).then(() => {
        reportRecreated();
      });
    };

    return {
      jobDirty,
      waitForReportCreated,
      currentUser: useCurrentUserStore().currentUser,
    };
  },
  data() {
    return {
      job: null,
      jobResult: null,
      installation: null,
      activeTab: null,
      initializedTabs: new Set(),
      notesCount: 0,
      attachmentsCount: 0,
      dataAndChecksCount: 0,
      incidentsCount: 0,
      jobArticlesCount: 0,
      lifecycleEventsCount: 0,
      checksCount: 0,
      initialDataLoaded: false,
    };
  },
  computed: {
    typeAndNumber() {
      return `${this.job.job_type_label} ${this.formatDate(this.job.planned_at, 'DD.MM.YYYY')}`;
    },
    jobThingsCount() {
      if (this.installation == null) {
        return 0;
      }

      if (this.localJobThingsScope === 'all_things') {
        return this.installation.things_count;
      }
      if (this.localJobThingsScope === 'due_things') {
        return this.installation.due_things_count;
      }
      if (this.localJobThingsScope === 'custom_things') {
        return this.localCustomJobThingsCount;
      }
      return 0;
    },
    jobIsUnplanned() {
      if (this.job == null) {
        return false;
      }
      return this.job.status === 'unplanned';
    },
    jobTypeHasChecks() {
      return this.hasCheckStep(this.job.job_type_id);
    },
    installationHasOpenIncidents() {
      return this.installation.open_incidents_count > 0;
    },
    hasNextStep() {
      return (this.job.job_things_scope === 'custom_things' || this.job.job_incidents_scope === 'custom_incidents');
    },
    planned_at_date() {
      return this.formatDate(this.job.planned_at, 'DD.MM.YYYY');
    },
    canEditArticles() {
      return this.canEdit && _.includes(['unplanned', 'planned', 'active', 'closed'], this.job.status);
    },
  },
  mounted() {
    this.loadData();
    this.$setActiveTabFromURL('tab-overview');
    this.refreshCounts();
  },
  methods: {
    classesForStatus(queryStatus) {
      if (this.job.status === queryStatus) {
        return `fx-status ${queryStatus}`;
      }
      return null;
    },
    loadCounts() {
      const that = this;
      axios.get(`/jobs/${this.job.id}/counts`)
        .then((response) => {
          that.localAssignedIncidentsCount = response.data.assigned_incidents_count;
          that.localCustomJobThingsCount = response.data.job_things_count;
        }).catch(() => {
          Flash.error('Anlagedaten konnten nicht geladen werden');
        });
    },
    loadData() {
      const that = this;
      axios.get(`/jobs/${this.jobId}`)
        .then((response) => {
          that.jobDirty = response.data.job.dirty;
          that.jobResult = response.data.job_result;
          that.installation = response.data.installation;
          that.job = response.data.job;
          that.initialDataLoaded = true;
        });
    },
    refreshCounts() {
      const that = this;
      axios.get(`/jobs/${this.jobId}/counts`).then((response) => {
        that.notesCount = response.data.notes_count;
        that.attachmentsCount = response.data.attachments_count;
        that.jobArticlesCount = response.data.job_articles_count;
        that.incidentsCount = response.data.incidents_count;
        that.dataAndChecksCount = response.data.data_and_checks_count;
        that.checksCount = response.data.checks_count;
        that.lifecycleEventsCount = response.data.lifecycle_events_count;
      }).catch();
    },
    recreateReport() {
      axios.post(`/jobs/${this.job.id}/create_new_report`).then((response) => {
        this.waitForReportCreated(response.data.sidekiq_job_id);
      }).catch(() => {
        Flash.error('Bericht konnte nicht neu erzeugt werden');
      });
    },
    changed() {
      this.refreshCounts();
      if (this.job.status === 'closed') {
        this.jobDirty = true;
      }
    },
  },
};
</script>
