<template>
  <td colspan="2" class="v--hover" :class="{'cursor-pointer': linkable}">
    <a v-if="linkable" :href="href" style="text-decoration: none; color: unset;">
      <div class="float-right v--hover-target">
        <button class="fx-context-circle">
          <i :class="icon" class="fw" />
        </button>
      </div>
      <slot />
    </a>
    <span v-else>
      <slot />
    </span>
   </td>
 </template>

<script>
export default {
  name: 'LinkableTd',
  props: {
    linkable: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: 'far fa-eye',
    },
    href: {
      type: String,
      required: true,
    },
  },
};
</script>

<!-- <td colspan="2" class="v--hover">
  <a :href="`/locations/${location.id}`" style="text-decoration: none; color: unset;">
  <div class="float-right v--hover-target">
    <button class="fx-context-circle">
    <i class="far fa-eye fw" />
    </button>
  </div>
  <Location :location="location" :link="false" />
</a>
</td> -->
