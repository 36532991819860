<template>
  <span>
    <i class="fas fa-envelope" /> {{ delivery.email }}

    <span v-if="isInitiated" class="secondary">
      Versand gestartet {{ timeAgo(delivery.initiated_at) }}
    </span>

    <span v-if="isHandedOver" class="secondary">
      verschickt am {{ formatDate(delivery.handed_to_postmark_at, 'DD.MM.YYYY HH:mm') }} Uhr
      <i class="fas fa-check" style="color: green;" />
    </span>

    <span v-if="isDelivered" class="secondary">
      verschickt am {{ formatDate(delivery.handed_to_postmark_at, 'DD.MM.YYYY HH:mm') }} Uhr
      <i class="fas fa-check-double" style="color: green;" />
    </span>

    <span v-if="isFailed" class="secondary">
      <span style="color: red">
        fehlgeschlagen am {{ formatDate(delivery.failed_at, 'DD.MM.YYYY HH:mm') }} Uhr
      </span>
      <div class="secondary">{{ delivery.error_message }}</div>
    </span>
  </span>
</template>

<script>
import moment from 'moment';
import { DateFormatter } from 'mixins/formatters';

export default {
  name: 'EmailDeliveryInfo',
  mixins: [DateFormatter],
  props: {
    delivery: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isInitiated() {
      return this.delivery.status === 'initiated';
    },
    isDelivered() {
      return this.delivery.status === 'delivered';
    },
    isFailed() {
      return this.delivery.status === 'failed';
    },
    isHandedOver() {
      return this.delivery.status === 'handed_over';
    },
  },
  methods: {
    timeAgo(timestamp) {
      moment.locale('de');
      return moment(timestamp).fromNow();
    },
  },
};
</script>
