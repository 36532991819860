<template>
  <div class="fx-modal-content-large">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <div style="float: right; margin-right: 1.5rem;">
      <a class="fx-btn-secondary" title="Liste neu laden" @click="refresh()"><i :class="spinnerClasses" /></a>
    </div>

    <h2 class="fx">
      Versandhistorie
    </h2>
    <hr>
    <table class="material">
      <tr v-for="delivery in emailDeliveries" :key="delivery.id" style="font-size: 1rem;">
        <td style="width: 150px; padding-left: 0;">
          {{ formatDate(delivery.initiated_at, 'DD.MM.YYYY HH:mm') }} Uhr
        </td>
        <td style="width: 220px; padding-left: 0;">
          {{ $t(`activerecord.attributes.email_delivery.email_type.${delivery.email_type}`) }}
        </td>
        <td style="padding-left: 0;">
          <EmailDeliveryInfo :delivery="delivery" />
        </td>
        <td style="padding-left: 0;width: 140px; text-align: right;">
          <a v-if="canResend(delivery)" @click="confirmResendModal(delivery.id)">erneut versenden</a>
        </td>
      </tr>
    </table>

    <div v-if="!emailDeliveries.length" class="secondary text-center">
      Keine Einträge
    </div>
    <div class="secondary small text-center">
      Die Liste zeigt Daten der letzten 6 Monate
    </div>
    <div class="clearfix" />
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';
import confirmViaModal from 'helpers/confirm_modal';
import { DateFormatter } from 'mixins/formatters';
import { ref } from 'vue';

import EmailDeliveryInfo from 'components/partials/email_delivery_info.vue';

export default {
  name: 'ResendNotificationModal',
  components: {
    EmailDeliveryInfo,
  },
  mixins: [DateFormatter],
  props: {
    jobId: {
      type: String,
      required: true,
    },
    defaultSpinnerClasses: {
      type: String,
      default: 'fas fa-sync-alt fa-fw',
    },
  },
  emits: ['close'],
  setup(props) {
    const emailDeliveries = ref([]);
    const spinnerClasses = ref(props.defaultSpinnerClasses);

    const loadDeliveries = () => {
      axios.get(`/jobs/${props.jobId}/email_deliveries.json`).then((response) => {
        emailDeliveries.value = response.data.email_deliveries;
        spinnerClasses.value = props.defaultSpinnerClasses;
      }).catch(() => {
        Flash.error('Versandhistorie konnte nicht geladen werden');
        spinnerClasses.value = props.defaultSpinnerClasses;
      });
    };

    const resendDelivery = (deliveryId) => {
      axios.post(
        `/jobs/${props.jobId}/resend_delivery.json`,
        {
          delivery_id: deliveryId,
        },
      ).then(() => {
        Flash.info('Versand erneut gestartet');
        loadDeliveries();
      }).catch(() => {
        Flash.error('Versand konnte nicht gestartet werden');
      });
    };

    const confirmResendModal = (deliveryId) => {
      confirmViaModal({
        title: 'Erneuten Versand bestätigen',
        message: 'Bitte versenden Sie Benachrichtigungen nur erneut, wenn Sie sicher sind, dass die E-Mail-Adresse korrekt ist und E-Mails empfangen kann.',
        action: 'Erneut versenden',
      }).then(() => {
        resendDelivery(deliveryId);
      });
    };

    return {
      emailDeliveries,
      spinnerClasses,
      loadDeliveries,
      confirmResendModal,
    };
  },
  mounted() {
    this.loadDeliveries();
  },
  methods: {
    // clear the list for a little moment, so that the users sees feedback when clicking refresh
    refresh() {
      const that = this;
      // this.emailDeliveries = [];
      this.spinnerClasses = 'fas fa-sync-alt fa-spin fa-fw';
      setTimeout(() => { that.loadDeliveries(); }, 1000);
    },
    canResend(delivery) {
      return ((delivery != null) && (delivery.email_type === 'job_published'));
    },
  },
};
</script>
