<template>
  <section class="fx-page-content">
    <div style="background-color: #DEE5E9;">
      <div style="display: inline-block; padding: .3rem;">
        <FilterStaticOptions
          v-model="filter.timerange"
          i18n-base-key="date-range"
          icon-id="timerange"
          :init-options="timeRangeOptions"
          @changed="onFilterTimeRange"
        />

        <DBBasedFilter
          v-model="filter.installationTypeId"
          :default-label="$t('comp.filter.installation_types.default-label')"
          i18n-base-key="installation_types"
          load-options-url="/installation_types/for_select"
          icon-id="installation_type"
          @changed="onFilterInstallationType"
        />

        <DBBasedFilter
          v-model="filter.customerId"
          i18n-base-key="customers"
          :default-label="$t('comp.filter.customers.default-label')"
          :allow-multi-select="false"
          load-options-url="/customers/for_select"
          icon-id="customer"
          @changed="onFilterCustomer"
        />

        <DBBasedFilter
          v-model="filter.locationId"
          :default-label="$t('comp.filter.locations.default-label')"
          i18n-base-key="locations"
          load-options-url="/select/locations"
          icon-id="location"
          @changed="onFilterLocation"
        />

        <FilterStaticOptions
          v-model="filter.status"
          i18n-base-key="incident_status"
          icon-id="incident_status"
          :init-options="statusOptions"
          @changed="onFilterStatus"
        />

        <FilterStaticOptions
          v-model="filter.severity"
          i18n-base-key="incident_severity"
          icon-id="incident_severity"
          :init-options="severityOptions"
          @changed="onFilterSeverity"
        />

        <FilterStaticOptions
          v-model="filter.assignedJob"
          i18n-base-key="assigned_job"
          icon-id="job"
          :init-options="assignedJobOptions"
          @changed="onFilterAssignedJob"
        />

        <DBBasedFilter
          v-model="filter.labelId"
          i18n-base-key="labels"
          :default-label="$t('comp.filter.labels.default-label')"
          :allow-multi-select="true"
          load-options-url="/labels/for_select?label_type=incident"
          icon-id="label"
          @changed="onFilterLabel"
        />
      </div>
    </div>

    <div class="fx-grid">
      <div class="fx-col">
        <div style="display:flex; justify-content: space-between; margin-top: 2rem;">
          <h1 class="fx" style="flex: 1; ">
            Störungen
          </h1>

          <div class="button-group">
            <div class="fx-btn-group">
              <a class="fx-btn-ico-primary" :disabled="!allowNewPolicy" @click="newIncident()">
                <i class="fas fa-plus" /> Störung erfassen
              </a>

              <a
                v-if="inboxEnabled"
                href="/inbox_items"
                class="fx-btn-secondary"
                :disabled="!allowNewPolicy"
              >
                Posteingang <span
                  v-if="inboxItemsCount"
                  class="fx-tab-count"
                  style="background-color:orange;color:white;"
                >{{ inboxItemsCount }}</span>
              </a>

              <a class="fx-btn-secondary" @click="openExportModalIncidents"><i class="far fa-file-export fa-fw" />
                Exportieren</a>
              <button
                v-tippy="{ placement: 'top', duration: 100, arrow: true }"
                class="fx-btn-secondary"
                title="Liste neu laden"
                @click="search()"
              >
                <i class="fas fa-sync-alt" />
              </button>
            </div>
          </div>
        </div>

        <div class="fx-card">
          <div class="fx-list-row" style="background-color: #f2f2f2; padding: .8rem 1rem; color: #888; font-size: 1rem;">
            <template v-if="filterInfo">
              {{ filterInfo }}
              <a
                v-tippy="{ content: 'Filter zurücksetzen', placement: 'top', duration: 100, delay: [300, null], arrow: true }"
                class="m-4 no-color"
                @click="resetSearch()"
              ><i
                class="fas fa-times-circle"
              /></a>
            </template>
            <template v-else>
              Alle Störungen
            </template>
          </div>
          <ListItem
            v-for="incident in localIncidents"
            :key="incident.id"
            :incident="incident"
            :link-to-details-page="true"
            :show-quickview="true"
            @quickview="openIncidentQuickView"
          />
          <EmptyState v-if="localIncidents.length === 0 && loaded" :title="noResultsTitle" :hint="noResultsHint" :no-border="true">
            <button v-if="filterApplied" class="fx-btn-secondary" @click="resetSearch()">
              <i
                class="fas fa-times-circle"
              />&nbsp;&nbsp;Filter
              zurücksetzen
            </button>
          </EmptyState>
        </div>

        <Pagination v-model="pagination" i18n-key="activerecord.models.incident" @navigate="loadPage" />
      </div>
    </div>
  </section>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import Flash from 'flash/index';
import FilterInfo from 'mixins/filter_info';
import openExportModal from 'helpers/open_export_modal';
import openNewIncidentModal from 'helpers/open_new_incident_modal';

import { ref, computed } from 'vue';
import { incidentQuickView } from 'helpers/quickviews';
import { useCurrentUserStore } from 'stores/current_user';

import DBBasedFilter from 'components/filter/db_based_filter.vue';
import FilterStaticOptions from 'components/filter/filter_static_options.vue';
import Pagination from 'components/pagination.vue';

import EmptyState from 'components/empty_state.vue';
import ListItem from './list_item.vue';

export default {
  name: 'IncidentList',
  components: {
    ListItem,
    Pagination,
    DBBasedFilter,
    EmptyState,
    FilterStaticOptions,
  },
  mixins: [FilterInfo],
  props: {
    allowNewPolicy: {
      type: Boolean,
    },
    customerId: {
      type: String,
      default: null,
    },
    locationId: {
      type: String,
      default: null,
    },
    inboxItemsCount: {
      type: Number,
      default: 0,
    },
    inboxEnabled: {
      type: Boolean,
      default: false,
    },
    noResultsTitle: {
      type: String,
      default: 'Keine Störungen gefunden',
    },
    noResultsHint: {
      type: String,
      default: 'Mit den aktuellen Filtereinstellungen wurden keine Störungen gefunden',
    },
    statusOptions: {
      type: Array,
      default: () => [
        { id: 'reported_opened', color: null },
        { id: 'reported', color: '#FF8F00' },
        { id: 'opened', color: '#F44336' },
        { id: 'closed', color: 'green' },
      ],
    },
    timeRangeOptions: {
      type: Array,
      default: () => [
        { id: 'today' },
        { id: '-1w' },
        { id: '-1m' },
        { id: '-12m' },
      ],
    },
    severityOptions: {
      type: Array,
      default: () => [
        { id: 'low' },
        { id: 'normal' },
        { id: 'high' },
        { id: 'notice' },
      ],
    },
    assignedJobOptions: {
      type: Array,
      default: () => [
        { id: '1' },
        { id: '0' },
      ],
    },
  },
  setup() {
    const localIncidents = ref([]);

    const userStore = useCurrentUserStore();
    const pagination = ref({ ...userStore.pagination('incidents') });
    const filter = ref({ ...userStore.filter('incidents') });
    const defaultFilter = {
      assignedJob: null,
      customerId: null,
      installationId: null,
      locationId: null,
      severity: null,
      status: null,
      timerange: null,
      labelId: null,
      installationTypeId: null,
    };

    const loaded = ref(false);

    const load = () => {
      loaded.value = false;
      axios.get('/incidents/filtered', {
        params: {
          severity: filter.value.severity,
          status: filter.value.status,
          assigned_job: filter.value.assignedJob,
          installation_type_id: filter.value.installationTypeId,
          installation_id: filter.value.installationId,
          location_id: filter.value.locationId,
          customer_id: filter.value.customerId,
          label_id: filter.value.labelId,
          timerange: filter.value.timerange,
          with_thing_details: null,
          page: pagination.value.page,
          limit: pagination.value.limit,
        },
      }).then((response) => {
        localIncidents.value = response.data.incidents;
        pagination.value.page = response.data.meta.current_page;
        pagination.value.pageCount = response.data.meta.total_pages;
        pagination.value.totalCount = response.data.meta.total_count;
        pagination.value.limit = response.data.meta.limit;

        userStore.updatePaginationFromMeta('incidents', response.data.meta);
        userStore.updateFilter('incidents', filter.value);

        loaded.value = true;
      }).catch((err) => {
        if (err.message !== 'Request aborted') {
          Flash.error(err.message);
        }
      });
    };

    const loadPage = (changedPagination) => {
      pagination.value = { ...changedPagination };
      load();
    };

    const patchIncident = (incident) => {
      const index = _.findIndex(localIncidents.value, { id: incident.id });
      localIncidents.value[index].labels = incident.labels;
    };

    const search = () => {
      localIncidents.value = [];
      pagination.value.page = 1;
      load();
    };

    const onFilterTimeRange = (timerange) => {
      filter.value.timerange = timerange;
      search();
    };

    const onFilterInstallationType = (installationTypeId) => {
      filter.value.installationTypeId = installationTypeId;
      search();
    };

    const onFilterLabel = (labelId) => {
      filter.value.labelId = labelId;
      search();
    };

    const onFilterCustomer = (customerId) => {
      filter.value.customerId = customerId;
      search();
    };

    const onFilterLocation = (locationId) => {
      filter.value.locationId = locationId;
      search();
    };

    const onFilterStatus = (status) => {
      filter.value.status = status;
      search();
    };

    const onFilterSeverity = (severity) => {
      filter.value.severity = severity;
      search();
    };

    const onFilterAssignedJob = (assignedJob) => {
      filter.value.assignedJob = assignedJob;
      search();
    };

    const resetSearch = () => {
      filter.value = { ...defaultFilter };
      search();
    };

    // -- Computed

    const filterApplied = computed(() => {
      let filtered = false;

      Object.keys(defaultFilter).forEach((key) => {
        if (filter.value[key] !== defaultFilter[key]) {
          filtered = true;
        }
      });

      return filtered;
    });

    // -- Modals

    const openExportModalIncidents = () => openExportModal({
      title: 'Störungen-Export',
      exporttype: 'incidents',
      config: filter,
    });

    const openIncidentQuickView = (incidentId) => {
      incidentQuickView(incidentId).then((changedIncident) => {
        patchIncident(changedIncident);
      });
    };

    return {
      openNewIncidentModal,
      openExportModalIncidents,
      openIncidentQuickView,
      localIncidents,
      onFilterAssignedJob,
      onFilterCustomer,
      onFilterInstallationType,
      onFilterLabel,
      onFilterLocation,
      onFilterSeverity,
      onFilterStatus,
      onFilterTimeRange,
      filter,
      filterApplied,
      search,
      resetSearch,
      loadPage,
      load,
      loaded,
      pagination,
    };
  },
  mounted() {
    this.filter = useCurrentUserStore().incidentsListFilter;

    if (this.customerId) {
      this.filter.customerId = this.customerId;
      this.filter.locationId = null;
    }

    if (this.locationId) {
      this.filter.locationId = this.locationId;
      this.filter.customerId = null;
    }
  },
  methods: {
    newIncident() {
      this.openNewIncidentModal().then(() => {
        this.search();
      });
    },
  },
};
</script>
