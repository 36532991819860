<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <h2 class="fx">
      Kontakt bearbeiten
    </h2>
    <hr>
    <p>
      Dieser Kontakt ist mit <strong>{{ localContact.customers_count }} Kunden</strong> und
      <strong>{{ localContact.installation_report_recipients_count + localContact.installation_contacts_count }}
        Anlagen</strong> verknüpft.
    </p>
    <ServerErrors v-model="serverErrors" title="Kontakt konnte nicht gespeichert werden" margin-bottom="1rem" />
    <form>
      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline" for="contact_name">Name</label>
        </div>
        <div class="fx-form-content">
          <input
            id="contact_name"
            v-model="localContact.name"
            name="contact_name"
            type="text"
          >
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline" for="contact_company">Firma</label>
        </div>
        <div class="fx-form-content">
          <input
            id="contact_company"
            v-model="localContact.company"
            name="contact_company"
            type="text"
          >
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline">E-Mail</label>
        </div>
        <div class="fx-form-content">
          <input
            id="contact_email"
            v-model="localContact.email"
            name="contact_email"
            type="text"
          >
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline">Telefon</label>
        </div>
        <div class="fx-form-content">
          <input
            id="contact_phone"
            v-model="localContact.phone"
            name="contact_phone"
            type="text"
          >
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline" for="contact_mobile">Mobil</label>
        </div>
        <div class="fx-form-content">
          <input
            id="contact_mobile"
            v-model="localContact.mobile"
            name="contact_mobile"
            type="text"
          >
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline" for="contact_fax">Fax</label>
        </div>
        <div class="fx-form-content">
          <input
            id="contact_fax"
            v-model="localContact.fax"
            name="contact_fax"
            type="text"
          >
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline" for="contact_note">Notiz</label>
        </div>
        <div class="fx-form-content">
          <textarea
            id="contact_mobile"
            v-model="localContact.note"
            rows="3"
            name="contact_note"
          />
        </div>
      </div>
    </form>
    <hr>
    <input
      type="submit"
      value="Kontakt speichern"
      class="fx-btn-primary float-right"
      style="margin-bottom: 0.5rem;"
      @click.prevent="submit"
    >
    <div class="clearfix" />
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';

import ServerErrors from 'components/partials/server_errors.vue';

export default {
  name: 'EditContactModal',
  components: {
    ServerErrors,
  },
  props: {
    contact: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  emits: ['close', 'updated'],
  data() {
    return {
      localContact: {
      },
      serverErrors: [],
    };
  },
  computed: {
  },
  mounted() {
    this.localContact = { ...this.contact };
  },
  methods: {
    submit() {
      if (this.localContact.id) {
        this.updateViaAPI();
      }
    },
    updateViaAPI() {
      const that = this;
      that.serverErrors = [];
      axios.put(`/contacts/${this.localContact.id}.json`, {
        contact: {
          name: this.localContact.name,
          company: this.localContact.company,
          phone: this.localContact.phone,
          mobile: this.localContact.mobile,
          fax: this.localContact.fax,
          email: this.localContact.email,
          note: this.localContact.note,
        },
      }).then((response) => {
        Flash.info('Kontakt gespeichert');
        this.$emit('close');
        this.$emit('updated', { ...response.data.contact });
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.contacts;
        } else {
          Flash.error('Kontakt konnte nicht gespeichert werden');
        }
      });
    },
  },
};
</script>
