const FilterInfo = {
  computed: {
    filterInfo() {
      return this.filterInfoText();
    },
  },
  methods: {
    filterInfoText() {
      const words = [];

      if (this.filter.timerange && this.filter.timerange !== 'y') {
        words.push('Zeitraum');
      }
      if (this.filter.letter) {
        if (this.filter.letter === '123') {
          words.push('beginnt mit Zahl');
        } else {
          words.push(`beginnt mit '${this.filter.letter}'`);
        }
      }
      if (this.filter.installationTypeId) {
        words.push('Wartungsanwendung');
      }
      if (this.filter.dueTimerange && this.filter.dueTimerange !== 'none') {
        words.push('Prüffälligkeit');
      }
      if (this.filter.replacementTimerange && this.filter.replacementTimerange !== 'none') {
        words.push('Austauschfälligkeit');
      }
      if (this.filter.customerId) {
        words.push('Kunde');
      }
      if (this.filter.technicianId) {
        words.push('Techniker');
      }
      if (this.filter.locationId) {
        words.push('Objekt');
      }
      if (this.filter.query) {
        words.push(`enthält '${this.filter.query}'`);
      }
      if (this.filter.status) {
        words.push('Status');
      }
      if (this.filter.severity) {
        words.push('Schweregrad');
      }
      if (this.filter.assignedJob) {
        words.push('Zielauftrag');
      }
      if (this.filter.zip) {
        words.push('PLZ');
      }
      if (this.filter.labelId) {
        words.push('Label');
      }
      if (this.filter.passive) {
        words.push('Passiv');
      }

      if (words.length === 0) {
        return null;
      }
      if (words.length < 3) {
        return `Gefiltert nach: ${words.join(' und ')}`;
      }
      return `Gefiltert nach: ${words.join(', ')}`;
    },
  },
};
export default FilterInfo;
