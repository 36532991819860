<template>
  <div class="fx-card">
    <div class="fx-card-content">
      <h2 class="fx float-left">
        {{ localJob.job_type_label }}
        <div v-if="localJob.external">
          <span class="fx-label" style="color: rgb(255, 255, 255); background-color: rgb(206, 45, 45);">externer Auftrag</span>
        </div>
      </h2>
    </div>

    <table class="material top-align">
      <colgroup>
        <col style="width: 120px;">
        <col>
        <col style="width: 175px;">
      </colgroup>

      <!-- show and edit status -->
      <tr class="middle-align" style="border-top: 1px solid #eee;">
        <th>Status</th>
        <td colspan="2">
          <span style="line-height: 36px;"><span class="fx-status" :class="localJob.status">{{ $t('job.status.' + localJob.status) }}</span></span>

          <div v-if="policyJobEdit" class="float-right">
            <div class="fx-btn-group">
              <TransactionButton
                v-if="localJob.status === 'closed'"
                v-model="processing"
                class="fx-btn-skeleton"
                title="abgerechnet"
                @click="changeStatusViaAPI('invoiced')"
              />
              <TransactionButton
                v-if="localJob.status === 'unplanned'"
                v-model="processing"
                class="fx-btn-skeleton"
                title="Planung beenden"
                @click="changeStatusViaAPI('planned')"
              />

              <drop-down :text="changeStatusLabel">
                <job-status-options
                  :job-status="localJob.status"
                  :show-delete="true"
                  :with-external-option="true"
                  :is-external="localJob.external"
                  @change-status="changeStatusViaAPI"
                  @confirm-deletion="confirmDeleteJobModal"
                  @confirm-close-as-external="openConfirmCloseAsExternalModal"
                />
              </drop-down>
            </div>
          </div>

          <div v-if="localJob.status_comment" style="margin: .6rem 0 0 0; padding: .3rem; color: rgb(0, 161, 223); background-color: rgba(0, 161, 223, 0.1);">
            <i class="fas fa-info-circle" />
            <span style="margin-left: 0.2rem;">{{ localJob.status_comment }}</span>
          </div>
        </td>
      </tr>

      <!-- Date -->
      <tr>
        <th>Datum</th>
        <ClickableTd colspan="2" :clickable="canEdit" @clicked="openEditJobDateModal(localJob)">
          <JobDuration :job="localJob" />
        </ClickableTd>
      </tr>

      <!-- Job Number -->
      <tr>
        <th>Auftrags-Nr.</th>
        <ClickableTd colspan="2" :clickable="canEdit" @clicked="openEditJobNumberModal(localJob)">
          {{ localJob.number }}
        </ClickableTd>
      </tr>

      <!-- Assigned technicians -->
      <tr>
        <th>Mitarbeiter</th>
        <ClickableTd colspan="2" :clickable="canEdit" @clicked="openEditJobAssignedUsersModal(localJob)">
          <span>
            <template v-if="localJob.pooling"><img
              id="pooling"
              src="/jeder.svg"
              height="24px"
              width="42px"
              style="vertical-align: middle;"
              title="Jeder Nutzer kann diesen Auftrag durchführen"
            ></template>
            <template v-else>
              <div v-for="user in localJob.assigned_users" :key="user.id">
                <img
                  :src="user.avatar_path"
                  width="20px"
                  height="20px"
                  style="vertical-align: middle;"
                > <span style="vertical-align: middle;">{{ user.full_name }}</span></div>
            </template>
          </span>
        </ClickableTd>
      </tr>

      <!-- Planned stuff -->
      <tr>
        <th>Geplant</th>
        <ClickableTd colspan="2" :clickable="canEdit" @clicked="goToPlanning()">
          <div>Prüfungen: {{ jobThingsScopeLabel }}</div>
          <div v-if="localJob.assigned_incidents_count">
            Störungen: {{ localJob.assigned_incidents_count }} Störungen
          </div>
        </ClickableTd>
      </tr>

      <!-- Instructions -->
      <tr>
        <th>Hinweise</th>
        <ClickableTd colspan="2" :clickable="canEdit" @clicked="openEditJobInstructionsModal(localJob)">
          <pre style="white-space: pre-wrap;">{{ localJob.instructions }}</pre>
        </ClickableTd>
      </tr>

      <tr v-if="announcementsEnabled">
        <th>Ankündigung</th>
        <ClickableTd colspan="2" :clickable="canEdit" @clicked="openEditJobAnnoucementModal(localJob)">
          <div v-for="announcement in localJob.job_announcements" :key="announcement.id">
            <div v-if="announcement.name">{{ announcement.name }}</div>
            <div>{{ announcement.email }}</div>
            <div v-if="announcement.sent_at" class="secondary">
              verschickt am {{ formatDate(announcement.sent_at, 'DD.MM.YYYY') }}
              <i class="fas fa-check" style="color: green;" />
            </div>
            <div v-else class="secondary">
              noch nicht versendet
            </div>
          </div>
        </ClickableTd>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';
import { ref } from 'vue';
import { DateFormatter } from 'mixins/formatters';
import confirmViaModal from 'helpers/confirm_modal';
import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';

import DropDown from 'components/dropdown_menu/menu.vue';
// import DropDownItem from 'components/dropdown_menu/menu_item.vue';
import JobStatusOptions from 'components/partials/job_status_options.vue';
import JobDuration from 'components/partials/job_duration.vue';

import ConfirmExternalJobModal from 'apps/jobs/index/confirm_external_job_modal.vue';
import TransactionButton from 'components/transaction_button.vue';
import EditJobDateModal from 'apps/jobs/edit/edit_job_date_modal.vue';
import EditJobNumberModal from 'apps/jobs/edit/edit_job_number_modal.vue';
import EditJobAssignedUsersModal from 'apps/jobs/edit/edit_job_assigned_users_modal.vue';
import EditJobInstructionsModal from 'apps/jobs/edit/edit_job_instructions_modal.vue';
import EditJobAnnouncementModal from 'apps/jobs/edit/edit_job_announcement_modal.vue';

import ClickableTd from 'components/partials/clickable_td.vue';

export default {
  name: 'JobOverview',
  components: {
    DropDown,
    // DropDownItem,
    JobStatusOptions,
    TransactionButton,
    JobDuration,
    ClickableTd,
  },
  mixins: [DateFormatter],
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    installation: {
      type: Object,
      required: true,
    },
    announcementsEnabled: {
      type: Boolean,
      required: true,
    },
    policyJobEdit: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const processing = ref(false);
    const localJob = ref(JSON.parse(JSON.stringify(props.modelValue)));

    const deleteViaAPI = () => {
      if (processing.value) {
        return;
      }

      processing.value = true;
      axios.delete(`/jobs/${localJob.value.id}`).then(() => {
        Flash.info('Auftrag wird gelöscht ...');
        window.location.href = '/jobs';
      }).catch(() => {
        Flash.error('Auftrag konnte nicht gelöscht werden');
      });
    };

    const closeExternalViaAPI = () => {
      if (processing.value) {
        return;
      }

      processing.value = true;
      axios.post(`/jobs/${localJob.value.id}/close_as_external.json`)
        .then(() => {
          Flash.info('Auftragstatus wird aktualisiert ...');
          window.location.href = `/jobs/${localJob.value.id}`;
        }).catch(() => {
          Flash.error('Auftragstatus konnte nicht aktualisiert werden');
        });
    };

    const openEditJobDateModal = (job) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: EditJobDateModal,
            attrs: {
              job,
              onClose() {
                modalInstance.close();
              },
              onUpdated(updatedJob) {
                localJob.value.planned_at = updatedJob.planned_at;
                localJob.value.enddate = updatedJob.enddate;
                localJob.value.all_day = updatedJob.all_day;
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openEditJobNumberModal = (job) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: EditJobNumberModal,
            attrs: {
              job,
              onClose() {
                modalInstance.close();
              },
              onUpdated(updatedJob) {
                localJob.value.number = updatedJob.number;
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openEditJobInstructionsModal = (job) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: EditJobInstructionsModal,
            attrs: {
              job,
              installation: props.installation,
              onClose() {
                modalInstance.close();
              },
              onUpdated(updatedJob) {
                localJob.value.instructions = updatedJob.instructions;
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openEditJobAssignedUsersModal = (job) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: EditJobAssignedUsersModal,
            attrs: {
              job,
              installation: props.installation,
              onClose() {
                modalInstance.close();
              },
              onUpdated(updatedJob) {
                localJob.value.pooling = updatedJob.pooling;
                localJob.value.assigned_users = updatedJob.assigned_users;
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openEditJobAnnoucementModal = (job) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: EditJobAnnouncementModal,
            attrs: {
              job,
              installation: props.installation,
              onClose() {
                modalInstance.close();
              },
              onUpdated(updatedJob) {
                localJob.value.job_announcements = updatedJob.job_announcements;
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const confirmDeleteJobModal = () => {
      confirmViaModal({
        title: 'Auftrag löschen?',
        message: 'Diese Aktion löscht den Auftrag und alle im Auftrag erfassten Daten und Berichte!',
        requireInputConfirmation: true,
      }).then(() => {
        deleteViaAPI();
      });
    };

    const openConfirmCloseAsExternalModal = () => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: ConfirmExternalJobModal,
            attrs: {
              onClose() {
                modalInstance.close();
              },
              onConfirmed() {
                closeExternalViaAPI();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    return {
      localJob,
      processing,
      confirmDeleteJobModal,
      openConfirmCloseAsExternalModal,
      openEditJobDateModal,
      openEditJobNumberModal,
      openEditJobAssignedUsersModal,
      openEditJobInstructionsModal,
      openEditJobAnnoucementModal,
    };
  },
  computed: {
    canEdit() {
      return this.policyJobEdit && !this.localJob.external && ['unplanned', 'planned', 'active', 'closed'].includes(this.localJob.status);
    },
    editJobPath() {
      return `/jobs/${this.localJob.id}/edit`;
    },
    editPlanningPath() {
      return `/jobs/${this.localJob.id}/planning`;
    },
    jobThingsScopeLabel() {
      if (this.localJob.job_things_scope === 'all_things') {
        return `Ganze Anlage (${this.installation.things_count} Komponenten)`;
      }
      if (this.localJob.job_things_scope === 'due_things') {
        return `Fällige (${this.installation.due_things_count} Komponenten)`;
      }
      if (this.localJob.job_things_scope === 'custom_things') {
        return `${this.localJob.job_things_count} von ${this.installation.things_count} Komponenten`;
      }
      return '';
    },
    changeStatusLabel() {
      if (['unplanned', 'closed'].includes(this.localJob.status)) {
        return 'mehr';
      }
      return 'Status ändern';
    },
  },
  methods: {
    goToPlanning() {
      window.location.href = this.editPlanningPath;
    },
    changeStatusViaAPI(status) {
      if (this.processing) {
        return;
      }

      this.processing = true;
      axios.post(`/jobs/${this.localJob.id}/statuses`, { status })
        .then(() => {
          Flash.info('Auftragstatus wird aktualisiert ...');
          window.location.href = `/jobs/${this.localJob.id}`;
        }).catch(() => {
          Flash.error('Auftragstatus konnte nicht aktualisiert werden');
        });
    },
  },
};
</script>
