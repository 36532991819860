<template>
  <a class="no-color" :href="linkPath">
    <div :class="blockClass">
      <div :class="lineClass">{{ firstLine }}</div>
      <div :class="lineClass"><strong>{{ plannedAtFormatted }}</strong></div>
    </div>
  </a>
</template>

<script>
import moment from 'moment';
import FoxtagPaths from 'mixins/foxtag_paths';

export default {
  name: 'JobLabel',
  mixins: [FoxtagPaths],
  props: {
    jobId: {
      type: String,
      default: null,
    },
    jobPlannedAt: {
      type: String,
      default: null,
    },
    jobTypeName: {
      type: String,
      default: null,
    },
    withIcon: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    blockClass() {
      if (this.jobPlannedAt) {
        return (this.withIcon) ? 'fx-job-block' : '';
      }
      return (this.withIcon) ? 'fx-job-block secondary' : 'secondary';
    },
    lineClass() {
      return (this.withIcon) ? 'fx-job-line' : '';
    },
    plannedAtFormatted() {
      if (this.jobPlannedAt) {
        return moment(this.jobPlannedAt).format('DD.MM.YYYY');
      }
      return '';
    },
    firstLine() {
      if (this.jobPlannedAt) {
        return this.jobTypeName;
      }
      return '';
    },
    linkPath() {
      if (this.jobId) {
        return this.jobPath(this.jobId);
      }
      return this.jobForInstallationPath(this.installationId);
    },
  },
};
</script>
