<template>
  <div>
    <div v-for="(notification, index) in notifications" :key="index" :class="classes(notification)">
      <div v-if="notification.can_mark_read" class="float-right">
        <a style="text-decoration: none; color: #256FC5;" @click.prevent="markAsRead(notification.id)">
          <i class="fas fa-times" /> schließen
        </a>
      </div>
      <div class="float-left icon">
        <i :class="notification.icon" />
      </div>
      <div class="message">
        <h2 v-if="notification.title && notification.title.length">
          {{ notification.title }}
        </h2>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="notification.message" />
        <div v-if="notification.link_url && notification.link_url.length" class="mt-4">
          <a :href="notification.link_url">
            {{ notification.link_text }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import Flash from 'flash/index';

export default {
  name: 'DashboardNotifications',
  components: {
  },
  data() {
    return {
      notifications: [],
    };
  },
  computed: {
  },
  mounted() {
    this.loadNotifications();
  },
  methods: {
    classes(notification) {
      return `dashboard-notification ${notification.color}`;
    },
    loadNotifications() {
      axios.get('/notifications/dashboard')
        .then((response) => {
          this.notifications = response.data.notifications;
        })
        .catch((error) => {
          Flash.error(error);
        });
    },
    markAsRead(notificationId) {
      axios.post(`/notifications/${notificationId}/mark_as_read`)
        .then(() => {
          this.notifications = _.reject(this.notifications, { id: notificationId });
        })
        .catch((error) => {
          Flash.error(error);
        });
    },
  },
};
</script>
