<template>
  <div v-if="!hidden" class="fx-card" style="border: 1px solid #7099C9">
    <div class="fx-card-content">
      <div class="float-right pt-2">
        <a style="text-decoration: none; color: #256FC5;" @click.prevent="hideFirstSteps()">
          <i class="fas fa-times" /> schließen
        </a>
      </div>

      <h2 class="fx">
        Erste Schritte
      </h2>
      <FirstStepsStep title="Wählen Sie eine Wartungsanwendung aus." :completed="completed(0)" :featured="featured(0)">
        Mit einer Wartungsanwendung legen Sie fest, wie Service-Einsätze dokumentiert werden und welche
        Informationen im Bericht abgedruckt werden. In unserem <strong>Anwendungsverzeichnis finden Sie
          Vorlagen</strong> für viele Gewerke und Anlagentypen, die Sie an Ihre Bedürfnisse anpassen können.
        <br><br>
        <a href="/installation_types"><i class="fas fa-arrow-right" />
          Die Wartungsanwendungen finden Sie im Admin-Bereich
        </a>
      </FirstStepsStep>

      <FirstStepsStep
        title="Legen Sie einen Kunden und eine Anlage an."
        :completed="completed(1)"
        :featured="featured(1)"
      >
        Eine technische Anlage besteht aus mehreren Komponenten, bspw. einzelne Rauchmelder. Jede Komponente einer
        Anlage
        trägt bei der Wartung ein eindeutiges Prüfergebnis.
        <br><br>
        <a href="/customers"><i class="fas fa-arrow-right" />
          Kunden fügen Sie auf der <strong>Kunden-Seite</strong> hinzu
        </a><br>
        <a href="/locations"><i class="fas fa-arrow-right" />
          Techn. Anlagen fügen Sie auf der <strong>Anlagen-Seite</strong> hinzu
        </a>
      </FirstStepsStep>

      <FirstStepsStep title="Laden Sie die Smartphone-App herunter." :completed="completed(2)" :featured="featured(2)">
        Mit unserer App für Smartphones und Tablets dokumentieren Sie Ihre Wartungs- und Service-Einsätze. <br>Suchen
        Sie
        mit Ihrem Smartphone/Tablet im <strong>Apple AppStore oder Google PlayStore nach "Foxtag"</strong> und
        installieren Sie die App.
        Loggen Sie sich dann mit Ihrer E-Mail-Adresse und Passwort ein.
        <br><br>
        <img src="/App_Store_Badge.svg" style="height: 45px;">
        <img src="/Play_Store_Badge.svg" style="height: 45px;">
      </FirstStepsStep>

      <FirstStepsStep
        title="Legen Sie einen Auftrag an und probieren Sie den Ablauf in der App aus."
        :completed="completed(3)"
        :featured="featured(3)"
      >
        Legen Sie für eine Ihrer Anlagen einen Auftrag an und führen Sie den Auftrag in der App durch.
        <br><br>
        <a href="/jobs">
          <i class="fas fa-arrow-right" />
          Aufträge legen Sie auf der <strong>Aufträge-Seite</strong> an
        </a>
        oder in der Liste der Anlagen direkt an der entsprechenden Anlage.
      </FirstStepsStep>

      <FirstStepsStep title="Laden Sie Ihre Kollegen zum Testen ein." :completed="completed(4)" :featured="featured(4)">
        In der Testphase können Sie mit bis zu 5 Kollegen den vollen Funktionsumfang von Foxtag nutzen. Probieren Sie
        das Zuweisen von Aufträgen, oder das gemeinsame Abarbeiten eines Auftrags aus.
        <br><br>
        <a href="/organizations/users"><i class="fas fa-arrow-right" />
          Weitere Nutzer laden Sie unter <strong>Mitarbeiter im Admin-Bereich</strong> ein.
        </a>
      </FirstStepsStep>

      <div class="fx-card-content mt-4" style="margin-top: 30px; background-color: #f6f6f6; border-radius: 6px;">
        Nutzen Sie auch unsere <strong>Nutzerhilfe im Hauptmenü (<i class="fas fa-question-circle" />)</strong>
        oder wenden Sie sich bei Fragen an den Kundenservice unter <strong>040 / 257 660 444</strong>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import FirstStepsStep from './first_steps_step.vue';

export default {
  name: 'FirstSteps',
  components: {
    FirstStepsStep,
  },
  data() {
    return {
      hidden: false,
      completedSteps: [true, true, false, false, false],
      featuredSteps: [false, false, false, false, false],
    };
  },
  computed: {
  },
  mounted() {
    this.loadStatus();
  },
  methods: {
    completed(i) {
      return this.completedSteps[i];
    },
    featured(i) {
      return this.featuredSteps[i];
    },
    loadStatus() {
      const that = this;
      axios.get('/users/start/first_steps.json')
        .then((response) => {
          that.completedSteps = response.data.checked;
          that.featuredSteps = response.data.featured;
        });
    },
    hideFirstSteps() {
      const that = this;
      axios.put('/users/start/hide_first_steps.json')
        .then(() => {
          that.hidden = true;
        });
    },
  },
};
</script>
