<template>
  <section class="fx-page-content">
    <div class="fx-sidebar-layout-infobar">
      <h2 class="fx">
        <i class="fas fa-cog" /> Administration
      </h2>
    </div>
    <section class="fx-header">
      <div class="fx-grid">
        <div class="fx-col" style="padding: 0 1rem;">
          <ul class="fx-breadcrumps">
            <li><a href="/organizations/settings">Administration</a></li>
            <li><a href="/installation_types">Wartungsanwendungen</a></li>
            <li><a :href="backLink()">{{ truncate(installationTypeName, 50) }}</a></li>
            <li v-if="localJobType.id">
              {{ truncate(localJobType.name, 50) }}
            </li>
          </ul>

          <div v-if="showSubmit" class="float-right">
            <a class="fx-btn-secondary" :href="backLink()">abbrechen</a>
            <span style="margin-left: .4rem;"><button
              class="fx-btn-primary float-right"
              style="margin-bottom: 0.5rem;"
              @click="submit"
            >Auftragstyp speichern</button></span>
            <div class="clearfix" />
          </div>

          <div class="fx-header-title">
            <h1 class="fx-header-title">
              <span class="fx-header-title_number">Auftragstyp</span>
              <span class="fx-header-title_name">{{ truncate(localJobType.name,50) }}</span>
            </h1>
          </div>

          <button :class="$tabClasses('name')" @click="$setActiveTab('name');">
            Name
          </button>
          <button :class="$tabClasses('steps')" @click="$setActiveTab('steps');">
            Ablauf in der App
          </button>
          <button :class="$tabClasses('report-settings')" @click="$setActiveTab('report-settings');">
            Einstellungen für den
            Bericht
          </button>
          <button
            :class="$tabClasses('article-presets')"
            @click="$setActiveTab('article-presets');"
          >
            Artikel-Vorauswahl
          </button>
        </div>
      </div>
    </section>

    <section class="list-content">
      <div class="fx-grid" style="margin-top: 0rem;">
        <div class="fx-col">
          <div v-if="$isTabInitialized('name')" v-show="$isTabActive('name')" class="tab-panel">
            <ServerErrors
              v-model="serverErrors"
              title="Auftragstyp konnte nicht gespeichert werden"
              margin-bottom="1rem"
            />
            <div class="fx-card">
              <div class="fx-card-content">
                <div class="fx-form-row">
                  <div class="fx-form-label">
                    <label class="inline mandatory">Name</label>
                  </div>
                  <div class="fx-form-content">
                    <input v-model="localJobType.name" type="text">
                  </div>
                </div>

                <div class="fx-form-row">
                  <div class="fx-form-label">
                    <label class="inline">Nummer</label>
                  </div>
                  <div class="fx-form-help">
                    <HelpHover content="number_for_api_usage" />
                  </div>
                  <div class="fx-form-content">
                    <input v-model="localJobType.number" type="text" placeholder="optional">
                  </div>
                </div>

                <div class="fx-form-row">
                  <div class="fx-form-content">
                    <input id="cb-active" v-model="localJobType.active" type="checkbox">
                    <label for="cb-active" class="inline">Aktiv (steht für neue Aufträge zur Verfügung)</label>
                  </div>
                </div>

                <div class="fx-form-row">
                  <div class="fx-form-content">
                    <input id="cb-default" v-model="localJobType.default" type="checkbox">
                    <label for="cb-default" class="inline">Standard (steht als erster Typ in der Auswahlliste)</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- TAB -->
          <div v-if="$isTabInitialized('steps')" v-show="$isTabActive('steps')" class="tab-panel">
            <ServerErrors
              v-model="serverErrors"
              title="Auftragstyp konnte nicht gespeichert werden"
              margin-bottom="1rem"
            />
            <div class="fx-card">
              <div class="fx-card-content">
                <div class="job-steps-panel">
                  <div class="float-right">
                    <div class="onoffswitch">
                      <input
                        id="job_type_has_installation_step"
                        v-model="localJobType.has_installation_step"
                        class="onoffswitch-checkbox"
                        type="checkbox"
                        value="1"
                        name="job_type[has_installation_step]"
                      >
                      <label class="onoffswitch-label" for="job_type_has_installation_step"><span
                                                                                              class="onoffswitch-inner"
                                                                                            />
                        <span class="onoffswitch-switch" />
                      </label>
                    </div>
                  </div>
                  <div style="margin-bottom: 0.4rem;">
                    <span class="job-steps-counter">1</span>
                    <strong style="color: #2C556E;">Inbetriebnahmen dokumentieren</strong>
                  </div>
                  <p style="color: #666;">
                    Wenn bei diesem Auftragstyp neue Komponenten vor Ort in Betrieb genommen werden sollen.
                  </p>
                  <p style="font-size: 0.8rem; margin: 0; color: #666;">
                    Der Techniker kann unabhängig von dieser Einstellung über <strong>Anlage bearbeiten</strong> neue
                    Komponenten erfassen.
                  </p>
                </div>
                <div class="job-steps-panel">
                  <div>
                    <span class="job-steps-counter">2</span>
                    <strong style="color: #2C556E;">Vorarbeiten (Allgemeine Checkliste)</strong>
                    <div style="padding-top: 1rem;">
                      <div class="fx-form-row">
                        <div class="fx-form-label">
                          <label
                            class="inline "
                            for="job_type_pre_checklist_id"
                          >Checkliste</label>
                        </div>
                        <div class="fx-form-content">
                          <select
                            id="job_type_pre_checklist_id"
                            v-model="localJobType.pre_checklist_id"
                            class="has-form-field-hint"
                            name="job_type[pre_checklist_id]"
                          >
                            <option value="" />
                            <option v-for="option in assignableChecklists" :key="option[0]" :value="option[0]">
                              {{ option[1] }}
                            </option>
                          </select>
                          <div class="form-field-hint">
                            Diese Checklisten können Sie im Tab Checklisten der
                            Wartungsanwendung einpflegen
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="job-steps-panel">
                  <div class="float-right">
                    <div class="onoffswitch">
                      <input
                        id="job_type_has_checks_step"
                        v-model="localJobType.has_checks_step"
                        class="onoffswitch-checkbox"
                        type="checkbox"
                        name="job_type[has_checks_step]"
                      >
                      <label class="onoffswitch-label" for="job_type_has_checks_step"><span
                                                                                        class="onoffswitch-inner"
                                                                                      />
                        <span class="onoffswitch-switch" />
                      </label>
                    </div>
                  </div>
                  <div style="margin-bottom: 0.4rem;">
                    <span class="job-steps-counter">3</span>
                    <strong style="color: #2C556E;">Prüfungen dokumentieren</strong>
                  </div>
                  <p style="margin: 0">
                    Wenn der Techniker vor Ort Prüfungen von Komponenten dokumentieren soll.
                  </p>
                </div>

                <div class="job-steps-panel">
                  <div>
                    <span class="job-steps-counter">4</span>
                    <strong style="color: #2C556E;">Nacharbeiten (Allgemeine Checkliste)</strong>
                    <div style="padding-top: 1rem;">
                      <div class="fx-form-row">
                        <div class="fx-form-label">
                          <label
                            class="inline "
                            for="job_type_pre_checklist_id"
                          >Checkliste</label>
                        </div>
                        <div class="fx-form-content">
                          <select
                            id="job_type_pre_checklist_id"
                            v-model="localJobType.post_checklist_id"
                            class="has-form-field-hint "
                            name="job_type[pre_checklist_id]"
                          >
                            <option value="" />
                            <option v-for="option in assignableChecklists" :key="option[0]" :value="option[0]">
                              {{ option[1] }}
                            </option>
                          </select>
                          <div class="form-field-hint">
                            Diese Checklisten können Sie im Tab Checklisten der
                            Wartungsanwendung einpflegen
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="job-steps-panel">
                  <div class="float-right">
                    <div class="onoffswitch">
                      <input
                        id="job_type_has_articles_step"
                        v-model="localJobType.has_articles_step"
                        class="onoffswitch-checkbox"
                        type="checkbox"
                        name="job_type[has_articles_step]"
                      >
                      <label class="onoffswitch-label" for="job_type_has_articles_step"><span
                                                                                          class="onoffswitch-inner"
                                                                                        />
                        <span class="onoffswitch-switch" />
                      </label>
                    </div>
                  </div>
                  <div style="margin-bottom: 0.4rem;">
                    <span class="job-steps-counter">5</span>
                    <strong style="color: #2C556E;">{{ articlePositionsLabel }}</strong>
                  </div>
                  <p style="margin: 0">
                    Wenn der Techniker Artikelpositionen im Auftrag erfassen können soll.
                  </p>
                </div>

                <div class="job-steps-panel">
                  <div class="float-right">
                    <div class="onoffswitch">
                      <input
                        id="job_type_has_technician_signature"
                        v-model="localJobType.has_technician_signature"
                        class="onoffswitch-checkbox"
                        type="checkbox"
                        name="job_type[has_technician_signature]"
                      >
                      <label class="onoffswitch-label" for="job_type_has_technician_signature"><span
                                                                                                 class="onoffswitch-inner"
                                                                                               />
                        <span class="onoffswitch-switch" />
                      </label>
                    </div>
                  </div>
                  <div style="margin-bottom: 0.4rem;">
                    <span class="job-steps-counter">6</span>
                    <strong style="color: #2C556E;">Unterschrift vom Techniker</strong>
                  </div>
                  <p style="margin: 0">
                    Wenn der Techniker unterschreiben können soll.
                  </p>
                </div>

                <div class="job-steps-panel">
                  <div class="float-right">
                    <div class="onoffswitch">
                      <input
                        id="job_type_has_customer_signature"
                        v-model="localJobType.has_customer_signature"
                        class="onoffswitch-checkbox"
                        type="checkbox"
                        name="job_type[has_customer_signature]"
                      >
                      <label class="onoffswitch-label" for="job_type_has_customer_signature"><span
                                                                                               class="onoffswitch-inner"
                                                                                             />
                        <span class="onoffswitch-switch" />
                      </label>
                    </div>
                  </div>
                  <div style="margin-bottom: 0.4rem;">
                    <span class="job-steps-counter">7</span>
                    <strong style="color: #2C556E;">Kundenunterschrift</strong>
                  </div>
                  <p style="margin: 0">
                    Wenn eine digitale Kundenunterschrift erfasst werden soll.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- end tab job steps -->

          <div v-if="$isTabInitialized('report-settings')" v-show="$isTabActive('report-settings')" class="tab-panel">
            <ServerErrors
              v-model="serverErrors"
              title="Auftragstyp konnte nicht gespeichert werden"
              margin-bottom="1rem"
            />

            <div class="fx-card fx-card-content">
              <div class="fx-form-row">
                <div class="fx-form-label">
                  <label class="inline " for="job_type_report_layout">Berichtslayout</label>
                </div>
                <div class="fx-form-content">
                  <select v-model="localJobType.report_layout_id" @change="updateLayoutWarnings()">
                    <option v-for="layout in reportLayouts" :key="layout.id" :value="layout.id">
                      {{ layout.name }}
                    </option>
                  </select>
                  <div class="form-field-hint">
                    <a :href="`/report_layouts/${localJobType.report_layout_id}`">Zum
                      ausgewählten Berichtslayout</a>
                  </div>

                  <div v-if="jobTypeWarnings.length" class="callout callout--warning">
                    <div class="mb-4">
                      Sie können dieses Layout verwenden, aber einige Daten aus dem <a
                        @click="$setActiveTab('steps');"
                      >Ablauf in der App</a> werden nicht abgedruckt:
                    </div>
                    <ul v-for="(warning, index) in jobTypeWarnings" :key="index">
                      <li>{{ warning }}</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="fx-form-row">
                <div class="fx-form-label">
                  <label class="inline " for="job_type_report_title">Titel auf dem
                    Bericht</label>
                </div>
                <div class="fx-form-content">
                  <input
                    id="job_type_report_title"
                    v-model="localJobType.report_title"
                    type="text"
                    class="has-form-field-hint"
                    name="job_type[report_title]"
                  >
                </div>
              </div>

              <div class="fx-form-row">
                <div class="fx-form-label">
                  <label class="inline " for="job_type_report_sub_title">Untertitel auf dem
                    Bericht</label>
                </div>
                <div class="fx-form-content">
                  <input
                    id="job_type_report_sub_title"
                    v-model="localJobType.report_sub_title"
                    type="text"
                    class="has-form-field-hint"
                    name="job_type[report_sub_title]"
                  >
                </div>
              </div>

              <div v-if="featureIndividualReports" class="fx-form-row">
                <div class="fx-form-label">
                  <label class="inline" for="">Erzeugte Dokumente</label>
                </div>
                <div class="fx-form-content">
                  <template v-if="currentUser.new_storage_rules">
                    <input
                      id="report-scope-default"
                      v-model="localJobType.report_scope"
                      type="radio"
                      name="report-scope"
                      value="default_report"
                    ><label
                      class="inline"
                      for="report-scope-default"
                    >Sammelbericht</label><br>
                    <input
                      id="report-scope-things"
                      v-model="localJobType.report_scope"
                      type="radio"
                      name="report-scope"
                      value="with_individual_things_reports"
                    ><label
                      class="inline"
                      for="report-scope-things"
                    >Sammelbericht und Einzelnachweise pro Komponente <sup><span
                      class="license-type-label license-type-pro"
                    >pro</span></sup></label><br>

                    <input
                      id="report-scope-groups"
                      v-model="localJobType.report_scope"
                      type="radio"
                      name="report-scope"
                      value="with_individual_groups_reports"
                    ><label
                      class="inline"
                      for="report-scope-groups"
                    >Sammelbericht und Einzelnachweise pro Gruppe <sup><span
                      class="license-type-label license-type-pro"
                    >pro</span></sup></label><br>
                  </template>
                  <template v-else>
                    <div class="callout callout--info">
                      Sie müssen der aktuellen Leistungsbeschreibung zustimmen, bevor Sie diese Funktion nutzen können<br>
                      <a href="/organizations/confirm_new_storage_rules">Informationen und Zustimmung</a>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>

          <div v-if="$isTabInitialized('article-presets')" v-show="$isTabActive('article-presets')" class="tab-panel">
            <ProFeatureCallout v-if="!featureArticlePresetsPro">
              Wenn Sie auf <strong>Foxtag Pro</strong> wechseln, können Sie an dieser Stelle Artikelpositionen
              hinterlegen,
              die neuen Aufträgen automatisch hinzugefügt werden.
            </ProFeatureCallout>

            <template v-if="featureArticlePresetsPro">
              <EmptyState v-if="!localJobArticlePresets.length" title="Keine Artikelpositionen hinterlegt">
                Artikelpositionen aus der Artikel-Vorauswahl werden einem neuen Auftrag automatisch hinzugefügt.
                <help-article article-id="learn_more_article_presets">
                  Mehr erfahren
                </help-article>
                <br><br>
                <template v-if="!localJobType.id">
                  <div class="callout callout--info" style="width: 450px; display: inline-block;">
                    Bitte speichern Sie zuerst den Auftragstyp, danach können Sie an dieser Stelle die Artikel-Vorauswahl
                    einstellen
                  </div>
                </template>

                <template v-if="localJobType.id">
                  <FileUploadButton
                    v-if="!uploading"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    text="Artikelpositionen aus Datei laden"
                    :show-filename="false"
                    @change="uploadJobArticlesFile"
                  />
                  <button v-if="uploading" class="fx-btn-primary" disabled="disabled">
                    <i
                      class="fas fa-circle-notch fa-spin"
                    /> Artikelpositionen aus Datei laden
                  </button>
                  <div style="font-size: .9rem; margin-top: 1rem;">
                    Verwenden Sie unsere Datei-Vorlage:<br><a
                      href="/import_templates/Foxtag_Vorlage_Artikelvorauswahl.xlsx"
                    ><i class="fas fa-file-excel" />
                      Vorlage Artikel-Vorauswahl (.xlsx)</a>
                  </div>
                </template>
              </EmptyState>

              <template v-if="localJobArticlePresets.length">
                <div class="float-right">
                  <div class="fx-btn-group">
                    <FileUploadButton
                      v-if="!uploading"
                      accept=""
                      text="Artikelpositionen aus Datei laden"
                      :show-filename="false"
                      @change="uploadJobArticlesFile"
                    />
                    <button v-if="uploading" class="fx-btn-primary" disabled="disabled">
                      <i
                        class="fas fa-circle-notch fa-spin"
                      /> Artikelpositionen aus Datei laden
                    </button>
                    <a class="fx-btn-secondary" @click="confirmDeleteArticleTypePresets()">Artikelpositionen löschen</a>
                  </div>
                </div>
                <div class="clearfix" />

                <div class="fx-card">
                  <table v-if="localJobArticlePresets.length" class="material">
                    <tr>
                      <th style="width: 150px;">
                        Artikel-Nr.
                      </th>
                      <th>Gruppe</th>
                      <th>Name</th>
                      <th style="width: 100px;">
                        Anzahl
                      </th>
                      <th>Kommentar</th>
                    </tr>
                    <tr v-for="jobArticlePreset in localJobArticlePresets" :key="jobArticlePreset.number">
                      <template v-if="jobArticlePreset.article">
                        <td>{{ jobArticlePreset.article.number }}</td>
                        <td>{{ jobArticlePreset.article.group }}</td>
                        <td>{{ jobArticlePreset.article.name }}</td>
                        <td>{{ jobArticlePreset.quantity }} {{ jobArticlePreset.article.unit }}</td>
                        <td>{{ jobArticlePreset.comment }}</td>
                      </template>

                      <template v-if="jobArticlePreset.article == null">
                        <td style="color: red;">
                          <i class="fas fa-exclamation-triangle" /> {{ jobArticlePreset.article_number }}
                        </td>
                        <td colspan="4" style="color: red;">
                          Artikel mit Nummer <strong>{{ jobArticlePreset.article_number }}</strong> wurde nicht gefunden,
                          oder ist zur Zeit nicht aktiv.
                        </td>
                      </template>
                    </tr>
                  </table>
                </div>
                <div class="callout callout--info">
                  Verwenden Sie unsere Datei-Vorlage:<br><a
                    href="/import_templates/Foxtag_Vorlage_Artikelvorauswahl.xlsx"
                  ><i class="fas fa-file-excel" />
                    Vorlage Artikel-Vorauswahl (.xlsx)</a>
                </div>
              </template>
            </template>
          </div>

          <!-- end tab report settings -->
          <div v-if="showSubmit" class="float-right">
            <a class="fx-btn-secondary" :href="backLink()">abbrechen</a>
            <span style="margin-left: .4rem;"><button
              class="fx-btn-primary float-right"
              style="margin-bottom: 0.5rem;"
              @click="submit"
            >Auftragstyp speichern</button></span>
            <div class="clearfix" />
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';

import Tabs from 'mixins/tabs';
import FeatureFlipper from 'mixins/feature_flipper';
import { TruncateFormatter } from 'mixins/formatters';
import { useCurrentUserStore } from 'stores/current_user';
import { ref } from 'vue';

import ServerErrors from 'components/partials/server_errors.vue';
import HelpHover from 'components/help_hover.vue';
import ProFeatureCallout from 'components/pro_feature_callout.vue';
import FileUploadButton from 'components/file_upload_button.vue';
import EmptyState from 'components/empty_state.vue';
import confirmViaModal from 'helpers/confirm_modal';

export default {
  name: 'EditJobType',
  components: {
    ServerErrors,
    HelpHover,
    ProFeatureCallout,
    FileUploadButton,
    EmptyState,
  },
  mixins: [Tabs, FeatureFlipper, TruncateFormatter],
  props: {
    jobType: {
      type: Object,
      required: true,
    },
    installationTypeId: {
      type: String,
      required: true,
    },
    installationTypeName: {
      type: String,
      required: true,
    },
    articlePositionsLabel: {
      type: String,
      required: true,
    },
    assignableChecklists: {
      type: Array,
      required: true,
    },
    reportLayouts: {
      type: Array,
      required: true,
    },
    featureIncidentsPro: {
      type: Boolean,
      default: false,
    },
    featureArticlePresetsPro: {
      type: Boolean,
      default: false,
    },
    featureIndividualReports: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const localJobArticlePresets = ref([]);

    const deleteJobArticlePresets = () => {
      axios.delete(`/job_types/${props.jobType.id}/delete_article_presets`).then(() => {
        localJobArticlePresets.value = [];
        Flash.info('Artikel-Vorauswahl gelöscht');
      }).catch(() => {
        Flash.error('Artikel-Vorauswahl konnte nicht geladen werden.');
      });
    };

    const confirmDeleteArticleTypePresets = () => {
      confirmViaModal({
        title: 'Alle Artikelpositionen löschen?',
      }).then(() => {
        deleteJobArticlePresets();
      });
    };

    return {
      currentUser: useCurrentUserStore().currentUser,
      confirmDeleteArticleTypePresets,
      localJobArticlePresets,
    };
  },
  data() {
    return {
      localJobType: {},
      serverErrors: [],
      uploading: false,
      showSubmit: false,
      jobTypeWarnings: [],
    };
  },
  watch: {
    activeTab: {
      handler(newValue) {
        if (newValue === 'article-presets') {
          this.showSubmit = false;
        } else {
          this.showSubmit = true;
        }
      },
    },
  },
  mounted() {
    this.localJobType = this.jobType;
    this.updateLayoutWarnings();
    this.$setActiveTabFromURL('name');
    if (this.localJobType.id) {
      this.loadJobArticlePresets();
    }
  },
  methods: {
    backLink() {
      return `/installation_types/${this.installationTypeId}#tab-job-types`;
    },
    submit() {
      if (this.localJobType.id) {
        this.updateViaAPI();
      } else {
        this.createViaAPI();
      }
    },
    jobTypeParams() {
      return {
        installation_type_id: this.installationTypeId,
        name: this.localJobType.name,
        number: this.localJobType.number,
        active: this.localJobType.active,
        default: this.localJobType.default,
        has_installation_step: this.localJobType.has_installation_step,
        has_checks_step: this.localJobType.has_checks_step,
        has_articles_step: this.localJobType.has_articles_step,
        has_technician_signature: this.localJobType.has_technician_signature,
        has_customer_signature: this.localJobType.has_customer_signature,
        pre_checklist_id: this.localJobType.pre_checklist_id,
        post_checklist_id: this.localJobType.post_checklist_id,
        report_title: this.localJobType.report_title,
        report_sub_title: this.localJobType.report_sub_title,
        report_scope: this.localJobType.report_scope,
        report_layout_id: this.localJobType.report_layout_id,
      };
    },
    createViaAPI() {
      const that = this;
      that.serverErrors = [];
      axios.post('/job_types.json', {
        job_type: this.jobTypeParams(),
      }).then(() => {
        Flash.info('Auftragstyp hinzugefügt');
        window.location.href = this.backLink();
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.job_types;
        } else {
          Flash.error('Auftragstyp konnte nicht gespeichert werden');
        }
      });
    },
    updateViaAPI() {
      const that = this;
      that.serverErrors = [];
      axios.put(`/job_types/${this.localJobType.id}.json`, {
        job_type: this.jobTypeParams(),
      }).then(() => {
        Flash.info('Auftragstyp gespeichert');
        window.location.href = this.backLink();
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.job_types;
        } else {
          Flash.error('Auftragstyp konnte nicht gespeichert werden');
        }
      });
    },
    loadJobArticlePresets() {
      axios.get(`/job_types/${this.localJobType.id}/article_presets`).then((response) => {
        this.localJobArticlePresets = response.data;
      }).catch(() => {
        Flash.error('Artikel-Vorauswahl konnte nicht geladen werden.');
      });
    },
    uploadJobArticlesFile(event) {
      this.uploading = true;
      const file = event.target.files[0];

      const that = this;
      const formData = new FormData();
      formData.append('presets_file', file);
      axios.post(`/job_types/${this.localJobType.id}/update_article_presets`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        that.loadJobArticlePresets();
        Flash.info('Artikel-Vorauswahl aktualisiert');
        that.uploading = false;
      }).catch((error) => {
        if (error.response && error.response.status === 400) {
          that.serverErrors = error.response.data.attachments;
        } else {
          Flash.error('Fehler beim Speichern der Artikel-Vorauswahl');
        }
        that.uploading = false;
      });
    },
    updateLayoutWarnings() {
      if (this.localJobType.id == null || this.localJobType.report_layout_id == null) {
        return;
      }
      axios.get(`/report_layouts/${this.localJobType.report_layout_id}/warnings.json?job_type_id=${this.localJobType.id}`).then((response) => {
        this.jobTypeWarnings = response.data.warnings;
      });
    },
  },
};
</script>
