// NOTE relies on mixin FeatureFlipper to be present (also on store['currentUser'])
//
const PortalRoles = {
  methods: {
    portalRoles(sharedInstallation) {
      return {
        // keep decimal bit representation in sync with feature_flip.rb
        jobs: sharedInstallation.roles.includes('jobs'),
        dueDates: sharedInstallation.roles.includes('due_dates'),
        incidents: sharedInstallation.roles.includes('incidents') && this.features.incidentsPro,
        inventory: sharedInstallation.roles.includes('inventory'),
      };
    },
  },
};
export default PortalRoles;
