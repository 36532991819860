<template>
  <div>
    <NavBreadcrumps path="administration/contacts" />

    <div style="display:flex; justify-content: space-between; margin-top: 0">
      <div style="flex: 1; max-width: 650px;">
        <h1 class="fx" style="">
          Kontakte
        </h1>
        <p>
          Gesamtliste aller Kontakte in Ihrem Firmen-Account.
          <help-article article-id="learn_more_contacts">
            Mehr erfahren
          </help-article>
        </p>
      </div>

      <div style="align-self: flex-end;">
        <div class="button-group">
          <div class="fx-btn-group">
            <button class="fx-btn-ico-primary" @click.prevent="openAddContactModal()">
              <i class="fas fa-plus" /> Kontakt hinzufügen
            </button>

            <drop-down :icon="currentListTypeSymbol" classes="fx-btn-secondary">
              <drop-down-item>
                <a @click.prevent="setListType('list')"><i class="fas fa-list fa-fw" />
                  Liste</a>
              </drop-down-item>
              <drop-down-item>
                <a @click.prevent="setListType('grid')"><i class="fas fa-th fa-fw" />
                  Kacheln</a>
              </drop-down-item>
            </drop-down>

            <drop-down text="Import/Export" classes="fx-btn-secondary">
              <drop-down-item>
                <a href="/imports/contacts/new"><i class="far fa-file-import fa-fw" /> Kontakte
                  importieren</a>
              </drop-down-item>
              <drop-down-item>
                <a @click.prevent="openExportContactsModal"><i class="far fa-file-export fa-fw" />
                  Kontakte
                  exportieren</a>
              </drop-down-item>
            </drop-down>

            <drop-down classes="fx-btn-secondary">
              <drop-down-item>
                <a @click.prevent="openNullifyNamesModal()"><i class="far fa-sparkles fa-fw" /> Namen von
                  Kontakten entfernen</a>
              </drop-down-item>
            </drop-down>
          </div>
        </div>
      </div>
    </div>

    <EmptyState
      v-if="showEmptyState"
      icon="fas fa-address-card"
      title="Keine Kontakte"
      hint="Legen Sie einen Kontakte an."
    >
      <button class="fx-btn-primary" @click.prevent="openNewContactModal()">
        Ersten Kontakte anlegen
      </button>
    </EmptyState>

    <template v-if="!showEmptyState">
      <div v-if="listType == 'list'" class="fx-card">
        <!-- filter bar -->
        <div class="fx-list-row" style="background-color: #f2f2f2; padding: 1rem; ">
          <template v-if="selection.length">
            <span style="font-size: 1rem; margin-right: 1rem;">{{ selection.length }} Kontakte ausgewählt</span>
            <div class="fx-btn-group">
              <button class="fx-btn-ico-primary" :disabled="selection.length < 2" @click="openMergeContactsModal">
                <i
                  class="far fa-code-merge"
                /> Zusammenführen
              </button>
              <button class="fx-btn-ico-primary" @click="confirmDeleteSelection">
                <i class="far fa-trash-alt" />
                Löschen
              </button>
            </div>
          </template>
          <template v-else>
            <input
              v-model="filter.query"
              type="text"
              style="display: inline-block; width: 350px; margin: 0; padding-right: 32px;"
              placeholder="Filtern nach Name, Firma oder E-Mail"
            ><button
              v-if="filter.query"
              class="reset"
              @click="filter.query = null"
            />
          </template>
        </div>

        <table class="data-grid contacts">
          <tr v-if="localContacts.length">
            <th class="icon">
              <input
                v-model="selectionAllSelected"
                type="checkbox"
                :indeterminate.prop="selectionIndeterminate"
                @change="toggleSelectAll()"
              >
            </th>
            <th>Name</th>
            <th>Firma</th>
            <th>E-Mail</th>
            <th>Telefon</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
          </tr>

          <ListItem
            v-for="contact in localContacts"
            :key="contact.id"
            :contact="contact"
            :current-list-selection-type="currentSelectionType"
            @open-confirm-delete="confirmDeleteContact"
            @open-edit-contact="openEditContactModal"
            @selected="addSelection"
            @unselected="removeSelection"
            @open-references="openContactReferencesModal"
          />
        </table>
      </div>

      <template v-if="listType == 'grid'">
        <div
          class="fx-list-row"
          style="background-color: #f2f2f2; padding: 1rem; border: 1px solid #eee; border-radius: 6px; margin-top: 10px;"
        >
          <input
            v-model="filter.query"
            type="text"
            style="display: inline-block; width: 350px; margin: 0; padding-right: 32px;"
            placeholder="Filtern nach Name, Firma oder E-Mail"
          ><button
            v-if="filter.query"
            class="reset"
            @click="filter.query = null"
          />
        </div>

        <div class="grid-4-col">
          <ContactCard
            v-for="contact in localContacts"
            :key="contact.id"
            :contact="contact"
            @open-confirm-delete="confirmDeleteContact"
            @open-edit-contact="openEditContactModal"
            @open-references="openContactReferencesModal"
          />
        </div>
      </template>

      <div v-if="showNoResults" style="padding: 1rem; text-align: center;">
        Keine Kontakte gefunden
      </div>

      <Pagination v-model="pagination" i18n-key="activerecord.models.contact" @navigate="loadPage" />
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import Flash from 'flash/index';

import { useCurrentUserStore } from 'stores/current_user';
import openExportModal from 'helpers/open_export_modal';
import confirmViaModal from 'helpers/confirm_modal';
import { ref } from 'vue';
import { VueFinalModal, useModal, useModalSlot } from 'vue-final-modal';

import FilterInfo from 'mixins/filter_info';

import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';
import EmptyState from 'components/empty_state.vue';

import NavBreadcrumps from 'components/nav_breadcrumps.vue';
import Pagination from 'components/pagination.vue';

import ContactCard from 'apps/contacts/card.vue';
import ContactReferencesModal from 'apps/contacts/references_modal.vue';
import EditContactModal from 'apps/contacts/edit_modal.vue';
import AddCreateContactModal from 'apps/contacts/add_create_modal.vue';

import MergeContactsModal from './merge_modal.vue';
import ListItem from './list_item.vue';
import NullifyNamesModal from './nullify_names_modal.vue';

export default {
  name: 'ContactsList',
  components: {
    NavBreadcrumps,
    ListItem,
    DropDown,
    DropDownItem,
    EmptyState,
    ContactCard,
    Pagination,
  },
  mixins: [FilterInfo],
  props: {
    startShowEmptyState: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const listType = ref('list'); // list, grid
    const loaded = ref(false);
    const localContacts = ref([]);
    const selection = ref([]);
    const selectionAllSelected = ref(false);
    const currentSelectionType = ref('none'); // none, indeterminate, all
    const showEmptyState = ref(false);

    const pagination = ref({
      page: 1,
      pageCount: 1,
      totalCount: 0,
      limit: 25,
    });

    const filter = ref({
      query: null,
    });

    const load = () => {
      loaded.value = false;
      selectionAllSelected.value = false;
      currentSelectionType.value = 'none';
      selection.value = [];

      axios.get('/contacts.json', {
        params: {
          query: filter.value.query,
          page: pagination.value.page,
          limit: pagination.value.limit,
        },
      }).then((response) => {
        localContacts.value = response.data.contacts;
        pagination.value.page = response.data.meta.current_page;
        pagination.value.pageCount = response.data.meta.total_pages;
        pagination.value.totalCount = response.data.meta.total_count;
        loaded.value = true;
      });
    };

    const search = () => {
      pagination.value.page = 1;
      load();
    };

    const contactAddedOrUpdated = () => {
      showEmptyState.value = false;
      search();
    };

    const nullifyNames = (name) => {
      axios.post('/contacts/nullify_names.json', {
        name,
      }).then((response) => {
        Flash.info(`${response.data.count} Namen aktualisiert`);
        search();
      }).catch(() => {
        Flash.error('Fehler beim Aktualisieren der Namen');
      });
    };

    const deleteContactViaApi = (contact) => {
      axios.delete(`/contacts/${contact.id}.json`).then(() => {
        search();
        Flash.info('Kontakt gelöscht');
      });
    };

    const discardSelectionViaApi = () => {
      axios.post('/contacts/discard_selection.json', {
        contact_ids: selection.value,
      }).then(() => {
        search();
        Flash.info('Kontakte gelöscht');
      });
    };

    const openExportContactsModal = () => openExportModal({
      title: 'Kontakte-Export',
      exporttype: 'contacts',
    });

    const confirmDeleteContact = (contact) => {
      confirmViaModal({
        message: 'Der Kontakt wird von allen Anlagen und Kunden entfernt und gelöscht.',
        title: 'Kontakt löschen?',
      }).then(() => {
        deleteContactViaApi(contact);
      });
    };

    const confirmDeleteSelection = () => {
      confirmViaModal({
        title: `${selection.value.length} Kontakte löschen?`,
        message: 'Diese Aktion löscht alle ausgewählten Kontakte.',
        requireInputConfirmation: selection.value.length > 2,
      }).then(() => {
        discardSelectionViaApi();
      });
    };

    const openContactReferencesModal = (contact) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: ContactReferencesModal,
            attrs: {
              contact,

              onClose() {
                modalInstance.close();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openAddContactModal = () => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: AddCreateContactModal,
            attrs: {
              relationType: 'contact',
              onlyCreate: true,

              onClose() {
                modalInstance.close();
              },
              onAdded() {
                contactAddedOrUpdated();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openNullifyNamesModal = () => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: NullifyNamesModal,
            attrs: {
              onClose() {
                modalInstance.close();
              },
              onNullify(name) {
                nullifyNames(name);
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openEditContactModal = (contact) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: EditContactModal,
            attrs: {
              contact,
              onClose() {
                modalInstance.close();
              },
              onUpdated(xContact) {
                contactAddedOrUpdated(xContact);
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openMergeContactsModal = () => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: MergeContactsModal,
            attrs: {
              mergeContactIds: selection.value,
              onClose() {
                modalInstance.close();
              },
              onMerged() {
                contactAddedOrUpdated();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const loadPage = (changedPagination) => {
      pagination.value = { ...changedPagination };
      load();
    };

    return {
      listType,
      showEmptyState,
      loaded,
      filter,
      pagination,
      selection,
      localContacts,
      selectionAllSelected,
      currentSelectionType,
      openExportContactsModal,
      openContactReferencesModal,
      openAddContactModal,
      openEditContactModal,
      openNullifyNamesModal,
      openMergeContactsModal,
      confirmDeleteContact,
      confirmDeleteSelection,
      currentUser: useCurrentUserStore().currentUser,
      load,
      loadPage,
      search,
    };
  },
  computed: {
    showNoResults() {
      return (this.loaded && (this.localContacts.length === 0));
    },
    currentListTypeSymbol() {
      return this.listType === 'grid' ? 'fas fa-th' : 'fas fa-list';
    },
    selectionIndeterminate() {
      return (this.selection.length > 0 && this.selection.length < this.pagination.limit);
    },
  },
  watch: {
    filter: {
      handler() {
        this.debouncedSearch();
      },
      deep: true,
    },
  },
  mounted() {
    this.showEmptyState = this.startShowEmptyState;

    // --- experimental: set page from URL
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has('page')) {
      this.page = parseInt(urlParams.get('page'), 10);
    }
    this.load();

    const viewFromStorage = localStorage.getItem(`contactsIndexView-${this.currentUser.id}`);
    if (viewFromStorage != null) {
      this.listType = viewFromStorage;
    }
  },
  methods: {
    toggleSelectAll() {
      if (this.selectionAllSelected) {
        this.currentSelectionType = 'all';
      } else {
        this.currentSelectionType = 'none';
      }
    },
    setListType(listType) {
      this.listType = listType;
      window.localStorage.setItem(`contactsIndexView-${this.currentUser.id}`, this.listType);
    },
    resetSearch() {
      this.filter = {
        query: null,
      };
    },
    debouncedSearch: _.debounce(function () {
      this.search();
    }, 250),
    addSelection(id) {
      this.selection.push(id);
    },
    removeSelection(id) {
      this.selection = _.without(this.selection, id);
      this.selectionAllSelected = false;
    },
  },
};
</script>
