<template>
  <div class="fx-modal-content-medium">
    <h2 class="fx">
      Mitarbeiter-Zuordnung bearbeiten
    </h2>
    <p>Wenn Sie niemanden zuordnen, können alle Mitarbeiter den Auftrag ausführen.</p>
    <div>
      <ServerErrors v-model="serverErrors" title="Änderung konnte nicht gespeichert werden" margin-bottom="0" />

      <label>Mitarbeiter</label>

      <div id="job-assigned-users">
        <div v-for="(assignedTechnician, index) in assignedUsers" :key="index" style="margin-bottom: .5rem;">
          <div style="display: inline-block; width: 100%; max-width: 340px; margin-right: .8rem;">
            <select v-model="assignedUsers[index]" style="margin-bottom: .1rem">
              <option value="" />
              <option
                v-for="technician in selectableUsers"
                :key="technician.id"
                :value="technician.id"
              >
                {{ technician.label }}
              </option>
            </select>
          </div>

          <a v-if="assignedUsers.length > 0" class="small" @click.prevent="removeAssignedTechnicianRow(index)">löschen</a>
        </div>

        <div>
          <a id="add-assigned-user-field" style="font-size: .875rem;" @click.prevent="addAssignedTechnicianRow()">
            <i class="fas fa-plus" />
            Mitarbeiter hinzufügen
          </a>
        </div>
      </div>
    </div>
    <hr class="fx">
    <div style="text-align:right;">
      <div class="fx-btn-group">
        <button
          class="fx-btn-primary float-right"
          style="margin-left: 0.5rem;"
          @click.prevent="updateJob()"
        >
          Speichern
        </button>

        <button class="fx-btn-secondary float-right" @click.prevent="cancel()">
          Abbrechen
        </button>
      </div>
      <div class="clearfix" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';
import { ref, onBeforeMount } from 'vue';
import { uniq, findIndex } from 'lodash';

import ServerErrors from 'components/partials/server_errors.vue';

export default {
  name: 'EditJobAssignedUsersModal',
  components: {
    ServerErrors,
  },
  props: {
    job: {
      type: Object,
      required: true,
    },
    installation: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'updated'],
  setup(props, { emit }) {
    const localJob = ref(JSON.parse(JSON.stringify(props.job)));
    const serverErrors = ref([]);
    const assignedUsers = ref([]);
    const selectableUsers = ref(props.installation.assignable_technicians);

    const cancel = () => {
      emit('close');
    };

    const updateJob = () => {
      serverErrors.value = [];
      axios.put(`/jobs/${localJob.value.id}`, {
        job: {
          assigned_user_ids: uniq(assignedUsers.value),
        },
      }).then((response) => {
        emit('updated', response.data.job);
        emit('close');
      }).catch((error) => {
        if (error.response.status === 400) {
          serverErrors.value = error.response.data.jobs;
        } else {
          Flash.error('Auftrag konnte nicht gespeichert werden');
        }
      });
    };

    // deleted users, or users with changed roles are not in "installation.assignable_technicians" any more
    // if these kind of users are currently assigned, we add them to selectableUsers to show them properly
    const addMissingTechnicianToOptions = () => {
      localJob.value.assigned_users.forEach((user) => {
        if (findIndex(selectableUsers.value, { id: user.id }) < 0) {
          selectableUsers.value.push({ id: user.id, label: user.full_name, option_label: user.full_name });
        }
      });
    };

    const addAssignedTechnicianRow = () => {
      assignedUsers.value.push('');
    };

    const removeAssignedTechnicianRow = (index) => {
      assignedUsers.value.splice(index, 1);
    };

    onBeforeMount(() => {
      if (!localJob.value.pooling) {
        assignedUsers.value = localJob.value.assigned_users.map((user) => user.id);
      }

      addMissingTechnicianToOptions();
    });

    return {
      selectableUsers,
      assignedUsers,
      cancel,
      localJob,
      serverErrors,
      updateJob,
      addAssignedTechnicianRow,
      removeAssignedTechnicianRow,
    };
  },
};
</script>
