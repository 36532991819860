<template>
  <div>
    <div id="label-grid" class="fx-grid">
      <div class="fx-col" style="margin-bottom: 0; padding-bottom: 0">
        <LinkAssign
          v-if="currentUser.pro"
          :href="modelValue.link"
          linkable-type="job"
          :linkable-id="modelValue.id"
          />
        <LabelsAssign
          v-if="currentUser.pro"
          :preset-labels="modelValue.labels"
          label-type="job"
          :lablable-id="modelValue.id"
          :can-edit="currentUser.policies.canEditProLabels"
          :link="modelValue.link"
        />
      </div>
    </div>

    <div class="fx-grid">
      <div class="fx-col-notop">
        <JobOverview
          id="job-overview-card"
          :model-value="modelValue"
          :installation="installation"
          :announcements-enabled="announcementsEnabled"
          :policy-job-edit="canEdit"
          @update:model-value="newValue => value = newValue"
        />

        <div id="associations-card" class="fx-card" style="max-width: 750px;">
          <table class="material top-align">
            <colgroup>
              <col style="width: 120px;">
              <col>
              <col style="width: 175px;">
            </colgroup>
            <tbody>
              <tr v-if="customer">
                <th>Kunde</th>
                <LinkableTd colspan="2" :linkable="true" :href="`/customers/${customer.id}`">
                  <Labels :labels="customer.labels" :link="customer.link" />
                  <Customer :customer="customer" :link="false" />
                </LinkableTd>
              </tr>
              <tr>
                <th>Objekt</th>
                <LinkableTd colspan="2" :linkable="true" :href="`/locations/${location.id}`">
                  <Location :location="location" :link="false" />
                </LinkableTd>
              </tr>
              <tr>
                <th>Anlage</th>
                <LinkableTd colspan="2" :linkable="true" :href="`/installations/${modelValue.installation.id}`">
                  <Labels :labels="modelValue.installation.labels" />
                  <Installation :installation="modelValue.installation" :link="false" />
                </LinkableTd>
              </tr>
            </tbody>
          </table>
        </div>

        <div id="status-historie-card" class="fx-card" style="max-width: 750px;">
          <div class="fx-card-content">
            <h2 class="fx">
              Verlauf
            </h2>
          </div>

          <div v-for="job_status in modelValue.job_statuses" :key="job_status.id" class="fx-list-row">
            <div class="fx-180px-col">
              <strong>{{ formatDate(job_status.statusdate, 'DD.MM.YYYY') }}</strong>
              {{ formatDate(job_status.statusdate, 'HH:mm') + "Uhr" }}
            </div>
            <div class="fx-180px-col">
              {{ job_status.user.full_name }}
            </div>
            <div class="fx-100px-col">
              <span class="fx-status" :class="job_status.status">{{ $t(`job.status.${job_status.status}`) }}</span>
            </div>
            <div
              v-if="job_status.comment"
              style="margin: 0 .6rem .6rem .6rem; padding: .3rem; color: rgb(0, 161, 223);
                background-color: rgba(0, 161, 223, 0.1);"
            >
              <i class="fas fa-info-circle" />
              <span style="margin-left: 0.2rem;">{{ job_status.comment }}</span>
            </div>
          </div>
        </div>
        <div id="ical-download" class="small">
          <i class="far fa-calendar-plus fx-inline-ico" /> <a :href="jobIcalPath(modelValue.id)">Als Termin herunterladen
            (ical)</a>
        </div>
      </div>
      <div class="fx-col-notop">
        <div id="publish-card" class="fx-card fx-card-content" :style="publishedCardHighlight">
          <div class="float-right" style="padding: .3rem 0 0 .2rem;">
            <drop-down classes="fx-context-circle" :disabled="false">
              <drop-down-item>
                <a @click.prevent="openResendModal(modelValue.id)"><i class="far fa-envelope fw" />
                  Versandhistorie</a>
              </drop-down-item>
            </drop-down>
          </div>

          <div class="float-right" style="padding-top: .3rem;">
            <YesNoSwitch v-model="isPublished" :disabled="!publishEnabled" @click="togglePublished()" />
          </div>

          <h2 class="fx" style="margin-bottom: .5rem;">
            Veröffentlicht
          </h2>
          <div style="font-size: .825rem">
            <div v-if="localPublishedAt">
              Am {{ formatDate(localPublishedAt, "DD.MM.YYYY HH:mm") + " Uhr" }}
              veröffentlicht.
            </div>
            <div v-if="!localPublishedAt" style="margin-bottom: 1rem;">
              Berichtsempfänger erhalten eine E-Mail mit
              Download-Link für den Bericht. <help-article article-id="publish_jobs">
                mehr erfahren
              </help-article>
            </div>

            <template v-if="jobResult.report_recipients_count > 0">
              <a id="report-recipients-link" :href="installationPath(modelValue.installation_id, 'tab-sharing')">{{ jobResult.report_recipients_count }}
                E-Mail-Empfänger hinterlegt</a>
              <div v-if="jobResult.autopublish_jobs" class="secondary">
                Wird automatisch
                veröffentlicht
              </div>
            </template>
            <template v-if="jobResult.report_recipients_count === 0">
              <a :href="installationPath(modelValue.installation_id, 'tab-sharing')">Keine E-Mail-Empfänger hinterlegt</a>
            </template>

            <template v-if="jobResult.portal_users_count > 0">
              <br>
              <a :href="installationPath(modelValue.installation_id, 'tab-sharing')">{{ jobResult.portal_users_count }}
                Freigaben für Portal-Nutzer</a>
            </template>
          </div>

          <div class="clearfix" />
        </div>

        <div v-if="showReportCard" id="report-card" class="fx-card">
          <div class="fx-card-content">
            <div class="float-right" style="padding: .3rem 0 0 .2rem;">
              <drop-down classes="fx-context-circle" :disabled="false">
                <template v-if="features.auxJobAttachments && currentUser.pro">
                  <drop-down-item v-if="addJobAttachmentMenuEnabled">
                    <a @click.prevent="openUploadModal(modelValue.id)"><i class="far fa-file fa-fw" /> Eigene Datei hinzufügen</a>
                  </drop-down-item>
                  <drop-down-item v-else>
                    <span class="disabled"><i class="far fa-file fa-fw" /> Eigene Datei hinzufügen</span>
                  </drop-down-item>
                  <template v-if="features.detectortesters">
                    <drop-down-item>
                      <hr>
                    </drop-down-item>
                    <drop-down-item>
                      <a @click.prevent="openDetectorTestersModal(modelValue.id)"><i class="far fa-cloud-download fa-fw" />
                        Bericht aus Testifire®-Cloud laden
                      </a>
                    </drop-down-item>
                  </template>
                  <drop-down-item>
                    <hr>
                  </drop-down-item>
                </template>
                <template v-if="modelValue.status == 'closed'">
                  <drop-down-item>
                    <a @click.prevent="confirmRecreateReport()"><i class="far fa-sync-alt fw" />
                      Bericht neu erzeugen</a>
                  </drop-down-item>
                </template>
                <template v-else>
                  <drop-down-item>
                    <span class="disabled"><i class="far fa-sync-alt fw" /> Bericht neu
                      erzeugen</span>
                  </drop-down-item>
                </template>
              </drop-down>
            </div>

            <h2 class="fx" style="margin-bottom: 0;">
              Berichte
            </h2>
            <AttachmentsList
              :key="jobAttachmentsComponentKey"
              :attachments="jobResult.attachments"
              :job="modelValue"
              :can-delete="true"
              @can-create-changed="updateCanCreate"
            />
          </div>

          <table class="material top-align">
            <tbody>
              <tr>
                <th style="width: 190px;">
                  Geprüfte Komponenten
                </th>
                <td id="job-report-total-check-count">
                  {{ jobResult.reportable_checks_label }}
                  <br>
                  <i class="fas fa-shield-check" /> {{ jobResult.confirmed_checks_count }} bestätigte Prüfungen
                  <help-article article-id="check_confirmation" :no-style="true">
                    <i class="fas fa-question-circle" />
                  </help-article>
                </td>
              </tr>
              <tr>
                <th>Mängel</th>
                <td id="job-report-failed-check-count">
                  {{ jobResult.failed_checks_count }} Prüfungen mit negativem
                  Prüfergebnis
                </td>
              </tr>
              <tr>
                <th>Unterschriften</th>
                <td id="job-report-signatures" style="display: flex; flex-wrap: wrap">
                  <template v-if="!jobResult.signatures.length">
                    <span class="secondary">Keine Unterschrift</span>
                  </template>
                  <template v-else>
                    <div
                      v-for="signature in jobResult.signatures"
                      :key="signature.id"
                      style="flex: 1; min-width: 150px;"
                      class="job-report-signature"
                    >
                      <span v-if="signature.archived" class="secondary">Archiviert</span>
                      <img :src="signature.thumb_url">
                      <div>{{ signatureLabel(signature.signee) }}<br>{{ signature.signee_name }}</div>
                    </div>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import axios from 'axios';
import _ from 'lodash';
import confirmViaModal from 'helpers/confirm_modal';
import { useCurrentUserStore } from 'stores/current_user';
import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';
import { ref } from 'vue';

import Flash from 'flash/index';
import FoxtagPaths from 'mixins/foxtag_paths';
import FeatureFlipper from 'mixins/feature_flipper';
import { DateFormatter } from 'mixins/formatters';

import JobOverview from 'apps/jobs/overview/index.vue';

import Location from 'components/partials/location.vue';
import Customer from 'components/partials/customer.vue';
import Installation from 'components/partials/installation.vue';
import Labels from 'components/partials/labels.vue';
import YesNoSwitch from 'components/partials/yes_no_switch.vue';
import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';
import LabelsAssign from 'apps/labels/assign/index.vue';
import LinkAssign from 'components/link_assign.vue';
import AttachmentsList from 'components/job_attachments/attachments_list.vue';
import UploadModal from 'components/upload_modal.vue';
import DetectorTestersModal from 'components/detector_testers_modal.vue';
import LinkableTd from 'components/partials/linkable_td.vue';

import ResendNotificationModal from './resend_notification_modal.vue';

export default {
  name: 'TabOverview',
  components: {
    JobOverview,
    Location,
    Customer,
    Installation,
    Labels,
    YesNoSwitch,
    DropDownItem,
    DropDown,
    LabelsAssign,
    LinkAssign,
    AttachmentsList,
    LinkableTd,
  },
  mixins: [FoxtagPaths, FeatureFlipper, DateFormatter],
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    jobResult: {
      type: Object,
      required: true,
    },
    installation: {
      type: Object,
      required: true,
    },
    featureIncidentsPro: {
      type: Boolean,
      default: false,
    },
    announcementsEnabled: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    location: {
      type: Object,
      required: true,
    },
    customer: {
      type: Object,
      default: null,
    },
  },
  emits: ['recreate-report'],
  setup(props, { emit }) {
    const jobAttachmentsComponentKey = ref(0);

    const reloadJobAttachments = () => {
      // changing a vue components key forces a re-render
      jobAttachmentsComponentKey.value += 1;
    };

    const openResendModal = (jobId) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: ResendNotificationModal,
            attrs: {
              jobId,
              onClose() {
                modalInstance.close();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openDetectorTestersModal = (jobId) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: DetectorTestersModal,
            attrs: {
              jobId,
              onClose() {
                modalInstance.close();
              },
              onDownloaded() {
                reloadJobAttachments();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openUploadModal = (jobId) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: UploadModal,
            attrs: {
              jobId,
              uploadUrl: `/jobs/${jobId}/job_attachments`,
              onClose() {
                modalInstance.close();
              },
              onUploaded() {
                reloadJobAttachments();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const confirmRecreateReport = () => {
      confirmViaModal({
        title: 'Bericht neu erzeugen',
        message: 'Sie löschen damit den bestehenden Bericht und erzeugen ein neues Dokument mit den aktuellen Daten. '
          + 'Es werden keine Benachrichtigungen versendet.',
        action: 'Bericht neu erzeugen',
      }).then(() => {
        emit('recreate-report');
      });
    };

    return {
      jobAttachmentsComponentKey,
      openResendModal,
      openDetectorTestersModal,
      openUploadModal,
      confirmRecreateReport,
      currentUser: useCurrentUserStore().currentUser,
    };
  },
  data() {
    return {
      localPublishedAt: false,
      addJobAttachmentMenuEnabled: false,
    };
  },
  computed: {
    publishedCardHighlight() {
      if (this.localPublishedAt) {
        return 'border-top: 2px solid #A0D368';
      }
      return '';
    },
    publishEnabled() {
      return this.publishableStatus
        && (this.jobResult.report_recipients_count + this.jobResult.portal_users_count > 0)
        && this.canEdit;
    },
    publishableStatus() {
      return _.includes(['closed', 'invoiced', 'free_of_charge'], this.modelValue.status);
    },
    showReportCard() {
      return _.includes(['active', 'closed', 'invoiced', 'free_of_charge'], this.modelValue.status);
    },
    isPublished() {
      return this.localPublishedAt != null;
    },
  },
  mounted() {
    this.localPublishedAt = this.jobResult.published_at;
  },
  methods: {
    signatureLabel(signee) {
      if (signee === 'service_technician') {
        return 'Techniker:';
      }
      return 'Kunde:';
    },
    togglePublished() {
      if (this.localPublishedAt) {
        this.unpublish();
      } else {
        this.publish();
      }
    },
    publish() {
      const that = this;
      axios.post(`/jobs/${this.modelValue.id}/publish`).then(() => {
        Flash.info('E-Mails an Berichtsempfänger werden nun versendet');
        that.localPublishedAt = new Date().toISOString();
      }).catch(() => {
        Flash.error('Bericht konnte nicht versendet werden');
      });
    },
    unpublish() {
      const that = this;
      axios.put(`/jobs/${this.modelValue.id}/unpublish`).then(() => {
        Flash.info('Auftrag ist nun nicht mehr veröffentlicht');
        that.localPublishedAt = null;
      }).catch(() => {
        Flash.error('Bericht konnte nicht versendet werden');
      });
    },
    updateCanCreate(canCreate) {
      this.addJobAttachmentMenuEnabled = canCreate;
    },
  },
};
</script>
