<template>
  <div style="margin-top: .3rem;">
    <div v-for="(singleFilter, index) in localFilters" :key="singleFilter.id" style="padding: .3rem 0">
      <div style="width: 40%; display: inline-block;">
        <DbBasedAutocomplete
          id="autocomplete-type-name"
          v-model="singleFilter.type"
          placeholder="Komponenten-Typ"
          :url="`/installation_types/${installationTypeId}/selectable_types?category=${category}`"
          @input="event => singleFilter.type = event"
        />
      </div>
      <div style="width: 40%; display: inline-block; margin-left: 8px;">
        <DbBasedAutocomplete
          id="autocomplete-manufacturer"
          v-model="singleFilter.manufacturer"
          placeholder="Hersteller (optional)"
          :url="`/installation_types/${installationTypeId}/selectable_manufacturers?category=${category}`"
          @input="event => singleFilter.manufacturer = event"
        />
      </div>
      <div style="width: 17%; display: inline-block; padding-left: .8rem; font-size: .825rem">
        <a @click="deleteRow(index)">löschen</a>
      </div>
    </div>
    <div style="padding: .3rem 0">
      <a @click="addRow()"><i class="fas fa-plus" /> Filter hinzufügen</a>
    </div>
  </div>
</template>

<script>
import DbBasedAutocomplete from 'components/select/db_based_autocomplete.vue';

export default {
  name: 'ThingTypeFilter',
  components: {
    DbBasedAutocomplete,
  },

  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    installationTypeId: {
      type: String,
      required: true,
    },
    category: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      localFilters: [],
    };
  },
  watch: {
    localFilters: {
      handler(val) {
        this.$emit('update:modelValue', val);
      },
      deep: true,
    },
  },
  mounted() {
    this.localFilters = this.modelValue.map((element) => (
      {
        type: element.type,
        manufacturer: element.manufacturer,
        id: window.crypto.randomUUID(), // we're adding artificial id to each row to prevent vue list glitches
      }
    ));
  },

  methods: {
    addRow() {
      this.localFilters.push(
        { manufacturer: null, type: null, id: window.crypto.randomUUID() },
      );
    },
    deleteRow(index) {
      this.localFilters.splice(index, 1);
    },
  },
};
</script>
