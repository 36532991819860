<template>
  <div>
    <div class="restricted-width">
      <NavBreadcrumps path="administration/reports" />
      <h1 class="fx">
        Berichte und Logo
      </h1>
      <p>
        Einstellungen für das Erzeugen Ihrer Berichts-Dokumente.
      </p>
    </div>
    <div class="fx-card fx-card-content">
      <h2 class="fx">
        Firmenlogo
      </h2>
      <p>
        Wir unterstützen die Formate <strong>.jpg</strong> und <strong>.png</strong>. Weiße Ränder werden automatisch
        entfernt.
      </p>

      <template v-if="localLogoUrl && localLogoUrl.length">
        <div v-if="logoWarningTooSmall" class="callout callout--warning mb-4">
          Ihr Logo ist so klein, dass es möglichweise nicht optimal auf dem Bericht abgedruckt wird.<br>Wir empfehlen
          eine
          Größe von mindestens 600px (längste Seite).
        </div>
        <a :href="logoUrlOriginal">
          <!-- max-width, coz the cached original (large) is used as fallback, until promotion is finished -->
          <img :src="logoUrl" style="max-width: 400px;">
        </a>

        <div class="mt-4">
          <button class="fx-btn-primary" @click="editLogo = true">
            Anderes Logo hochladen
          </button>
          <a class="ml-4" @click="confirmDeleteLogo()">Logo löschen</a>
        </div>
      </template>
      <template v-if="showLogoUpload">
        <dashboard
          :uppy="uppy"
          :props="{
            inline: true,
            height: 300,
          }"
        />
      </template>
    </div>

    <div class="fx-card">
      <div class="fx-card-content">
        <div class="float-right">
          <div class="fx-btn-group">
            <button class="fx-btn-primary" :disabled="!defaultLayout" @click="confirmAddLayout()">
              Neues Layout
            </button>
            <drop-down classes="fx-btn-secondary">
              <drop-down-item>
                <a href="/report_layouts/merge"><i class="fas fa-sparkles fa-fw" /> Doppelte Layouts
                  zusammenführen</a>
              </drop-down-item>
            </drop-down>
          </div>
        </div>
        <h2 class="fx">
          Berichtslayouts
        </h2>
      </div>

      <div v-for="layout in localReportLayouts" :key="layout.id" class="fx-flex-row">
        <div class="fx-flex-row-main" style="font-size: .8rem">
          <div
            style="margin: 0 .5rem 0 0; padding: 0; height: 20px; display: inline-block; vertical-align: bottom; font-size: 0;"
          >
            <span
              style="display: inline-block; width: 10px; height: 20px;"
              :style="`background: #${layout.colors.color_headlines}`"
            />
            <span
              style="display: inline-block; width: 20px; height: 20px;"
              :style="`background: #${layout.colors.color_lines}`"
            />
            <span
              style="display: inline-block; width: 15px; height: 20px;"
              :style="`background: #${layout.colors.color_bg_groups}`"
            />
          </div>

          <span :style="extraStyles(layout)"><a
            :href="layoutPath(layout.id)"
            class="no-color"
          >{{ layout.name }}</a></span> <span
            v-if="layout.default"
            v-tippy="infoTippy"
            class="fx-label"
          >Standard</span>
        </div>
        <div class="fx-flex-row-nav">
          <div style="width: 220px;" class="secondary">
            <span v-if="layout.job_types.length > 0">von <strong><a
              class="no-color"
              @click="openJobTypesModal(layout)"
            >{{ layout.job_types.length }}
              Auftragstypen</a></strong> verwendet
            </span><span v-else>wird nicht verwendet</span>
          </div>

          <a :href="layoutPath(layout.id)" class="fx-btn-skeleton">bearbeiten</a>
          <drop-down>
            <drop-down-item>
              <a @click="duplicateLayout(layout)"><i class="far fa-clone fa-fw" /> duplizieren</a>
            </drop-down-item>
            <drop-down-item>
              <a v-if="!layout.default" @click="setDefaultLayout(layout)"><i class="far fa-star fa-fw" /> als Standard
                verwenden</a>
              <span v-else class="secondary"><i class="far fa-star fa-fw" /> als Standard verwenden</span>
            </drop-down-item>
            <drop-down-item>
              <hr>
            </drop-down-item>
            <drop-down-item>
              <a v-if="!layout.system && !layout.default" @click="confirmDeleteLayout(layout)"><i
                class="far fa-trash-alt fa-fw"
              /> löschen</a>
              <span v-else class="secondary"><i class="far fa-trash-alt fa-fw" /> löschen</span>
            </drop-down-item>
          </drop-down>
        </div>
      </div>

      <div v-if="features.canChangeLayoutEngine" class="fx-card-content">
        <p>
          Alle Layouts auf die aktuelle Layout-Engine (v2) umstellen.
          <help-article article-id="learn_more_migrate_layout_engine">
            Mehr erfahren
          </help-article>
        </p>
        <button class="fx-btn-secondary" @click="confirmMigrateLayouts()">
          Alle Layouts umstellen
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import Flash from 'flash/index';
import _ from 'lodash';

import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';
import NavBreadcrumps from 'components/nav_breadcrumps.vue';
import FeatureFlipper from 'mixins/feature_flipper';
import { ref } from 'vue';
import { useCurrentUserStore } from 'stores/current_user';
import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';
import confirmViaModal from 'helpers/confirm_modal';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { Dashboard } from '@uppy/vue';

import JobTypesModal from 'apps/report_layouts/index/job_types_modal.vue';

import { useUppy, onUppyUploaded } from 'helpers/uppy';

export default {
  name: 'ReportsConfigList',
  components: {
    DropDown,
    DropDownItem,
    Dashboard,
    NavBreadcrumps,
  },
  mixins: [FeatureFlipper],
  props: {
    configId: {
      type: String,
      required: true,
    },
    logoUrl: {
      type: String,
      required: true,
    },
    logoUrlOriginal: {
      type: String,
      required: true,
    },
    logoWarningTooSmall: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const localLogoUrl = ref(props.logoUrl);
    const localReportLayouts = ref([]);
    const editLogo = ref(false);
    const defaultLayout = ref(null);

    const uppy = useUppy();

    const uploadCallback = (uploadedFileData) => {
      axios.put(
        `/organization_configs/${props.configId}.json`,
        {
          organization_config: {
            company_logo: uploadedFileData,
          },
        },
      ).then(() => {
        window.location.reload();
      }).catch(() => {
        Flash.info('Fehler beim Hochladen');
      });
    };

    onUppyUploaded(uppy, uploadCallback);

    const deleteLogo = () => {
      axios.delete(`/organization_configs/${props.configId}/delete_logo.json`).then(() => {
        localLogoUrl.value = null;
        Flash.info('Logo gelöscht');
      }).catch(() => {
        Flash.error('Fehler beim Löschen des Logos');
      });
    };

    const findDefaultLayout = () => {
      const index = _.findIndex(localReportLayouts.value, { default: true });

      defaultLayout.value = localReportLayouts.value[index];
    };

    const loadLayouts = () => {
      axios.get('/report_layouts.json').then((response) => {
        localReportLayouts.value = response.data.layouts;
        findDefaultLayout();
      }).catch(() => {
        Flash.error('Fehler beim Laden der Liste');
      });
    };

    const setDefaultLayout = (layout) => {
      axios.put(`/report_layouts/${layout.id}/default.json`).then(() => {
        Flash.info('Layout als Standard gesetzt');
        loadLayouts();
      }).catch(() => {
        Flash.error('Fehler beim Aktualisieren des Layouts');
      });
    };

    const migrateLayouts = () => {
      axios.post('/report_layouts/migrate.json').then(() => {
        Flash.info('Alle Layouts umgestellt');
      }).catch(() => {
        Flash.error('Fehler beim Umschalten der Layouts');
      });
    };

    const duplicateLayout = (layout) => {
      axios.post(`/report_layouts/${layout.id}/duplicate.json`).then(() => {
        Flash.info('Neues Layout erstellt');
        loadLayouts();
      }).catch(() => {
        Flash.error('Fehler beim Erstellen des Layouts');
      });
    };

    const deleteLayout = (layout) => {
      axios.delete(`/report_layouts/${layout.id}.json`).then(() => {
        loadLayouts(); // reload to update counts changed by delete
        Flash.info('Layout gelöscht');
      }).catch(() => {
        Flash.error('Fehler beim Löschen des Layouts');
      });
    };

    // --------------

    const confirmDeleteLogo = () => {
      confirmViaModal({
        title: 'Logo löschen?',
        message: 'Berichte werden dann zukünftig ohne Firmenlogo erzeugt.',
      }).then(() => {
        deleteLogo();
      });
    };

    const confirmMigrateLayouts = () => {
      confirmViaModal({
        title: 'Alle Layouts umstellen?',
        message: 'Alle Layouts werden auf die neuen Layout-Engine (v2) umstellt. Dadurch verändert sich auch das Aussehen der Berichte.',
        action: 'Jetzt umstellen',
      }).then(() => {
        migrateLayouts();
      });
    };

    const confirmAddLayout = () => {
      confirmViaModal({
        title: 'Neues Layout erzeugen?',
        message: 'Diese Aktion dupliziert das aktuelle Standard-Layout.',
        action: 'Neues Layout erzeugen',
      }).then(() => {
        duplicateLayout(defaultLayout.value);
      });
    };

    const confirmDeleteLayout = (layout) => {
      confirmViaModal({
        title: 'Layout löschen?',
        message: 'Alle Autragstypen, die diese Layout verwenden, werden Standard-Layout zugeordnet.',
        action: 'Löschen',
        requireInputConfirmation: (layout.job_types.length > 0),
      }).then(() => {
        deleteLayout(layout);
      });
    };

    const openJobTypesModal = (layout) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: JobTypesModal,
            attrs: {
              reportLayout: layout,
              onClose() {
                modalInstance.close();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    return {
      currentUser: useCurrentUserStore().currentUser,
      localLogoUrl,
      localReportLayouts,
      editLogo,
      defaultLayout,
      uppy,
      loadLayouts,
      duplicateLayout,
      setDefaultLayout,
      confirmDeleteLogo,
      confirmMigrateLayouts,
      confirmAddLayout,
      confirmDeleteLayout,
      openJobTypesModal,
    };
  },
  computed: {
    showLogoUpload() {
      return (!this.localLogoUrl || !this.localLogoUrl.length || this.editLogo);
    },
    infoTippy() {
      return {
        content: 'Standardlayout<br>Wird automatisch für neue Auftragstypen verwendet.<br>Bestimmt die Farben von Ad-Hoc Berichten wie dem Jahresfortschrittsbericht.', placement: 'top', duration: 100, delay: [500, 0], arrow: true,
      };
    },
  },
  mounted() {
    this.localLogoUrl = this.logoUrl;
    this.loadLayouts();
  },
  methods: {
    extraStyles(layout) {
      if (layout.system) {
        return 'font-weight: 500; color: #2c556e;';
      }
      return null;
    },
    layoutPath(id) {
      return `/report_layouts/${id}`;
    },
    beforeUnmount() {
      this.uppy.close();
    },
  },
};
</script>
