<template>
  <div>
    <empty-state v-if="showEmptyState" title="Diese Anlage enthält keine Komponenten" />
    <div v-else>
      <div style="float: right">
        <a :href="exportUrl" class="fx-btn-secondary">Inventar exportieren</a>
      </div>

      <div style="margin-bottom: 2rem;">
        <input
          v-model="filter.query"
          type="text"
          style="width: 200px; display:inline-block; margin: 0;
        margin-right: .5rem;"
          placeholder="Gruppe suchen"
        >
        <a id="reset-incidents" style="font-size: .8rem;" @click.prevent="resetSearch()">zurücksetzen</a>
      </div>

      <SingleGroup
        v-for="group in localGroups"
        :key="group.id + group.things_count"
        :group="group"
        :installation-id="installationId"
        :installation-type-id="installationType.id"
        :group-label="installationType.groups_label"
        :thing-label="installationType.things_label"
        :has-central-unit="installationType.has_central_unit"
        :start-collapsed="false"
        :editable="false"
        :show-due-dates="showDueDates"
        :show-portal-add-incident-buttons="showAddIncidentButtons"
        :custom-dataset="[]"
      />
      <div v-if="showNoResults" style="padding: 1rem; text-align: center;">
        Keine Gruppen gefunden
      </div>
      <Pagination v-model="pagination" i18n-key="activerecord.models.group" @navigate="loadPage" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import Flash from 'flash/index';
import SingleGroup from 'apps/installations/groups/single_group.vue';
import { ref, watch } from 'vue';
import Pagination from 'components/pagination.vue';
import EmptyState from 'components/empty_state.vue';

export default {
  name: 'GroupsList',
  components: {
    SingleGroup,
    Pagination,
    EmptyState,
  },
  props: {
    installationId: {
      type: String,
      default: null,
    },
    showDueDates: {
      type: Boolean,
      default: false,
    },
    showAddIncidentButtons: {
      type: Boolean,
      default: false,
    },
    installationType: {
      type: Object,
      required: true,
    },
    startEmptyState: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const localGroups = ref([]);
    const filter = ref({
      query: null,
    });
    const pagination = ref({
      page: 1,
      pageCount: 1,
      totalCount: 0,
      limit: 25,
    });

    const load = () => {
      axios.get(`/installations/${props.installationId}/groups.json`, {
        params: {
          query: filter.value.query,
          page: pagination.value.page,
          limit: pagination.value.limit,
        },
      }).then((response) => {
        localGroups.value = response.data.groups;
        pagination.value.page = response.data.meta.current_page;
        pagination.value.pageCount = response.data.meta.total_pages;
        pagination.value.totalCount = response.data.meta.total_count;
      }).catch((err) => {
        if (err.message !== 'Request aborted') {
          Flash.error(err.message);
        }
      });
    };

    const loadPage = (changedPagination) => {
      pagination.value = { ...changedPagination };
      load();
    };

    const search = () => {
      localGroups.value = [];
      pagination.value.page = 1;
      load();
    };

    const resetSearch = () => {
      filter.value = {
        query: null,
      };
    };
    const debouncedSearch = _.debounce(() => {
      search();
    }, 250);

    watch(filter, () => {
      debouncedSearch();
    }, { deep: true });

    return {
      localGroups,
      filter,
      pagination,
      resetSearch,
      loadPage,
    };
  },

  data() {
    return {
      showEmptyState: false,
    };
  },
  computed: {
    showNoResults() {
      return (this.localGroups.length === 0);
    },
    exportUrl() {
      return `/installations/${this.installationId}/export`;
    },
  },
  mounted() {
    this.showEmptyState = this.startEmptyState;
  },
};
</script>
