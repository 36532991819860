<template>
  <form @submit.prevent="enqueue">
    <div class="fx-form-row">
      <div class="fx-form-label">
        <label class="inline mandatory">Jahr</label>
      </div>
      <div class="fx-form-content">
        <select v-model="year" style="width: 80px; display: inline-block;">
          <option v-for="(singleYear, index) in years()" :key="index" :value="singleYear">
            {{ singleYear }}
          </option>
        </select>
      </div>
    </div>

    <input type="submit" class="fx-btn-primary" value="Bericht erzeugen">
  </form>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';

export default {
  name: 'EnqueueInstallationYearProgress',
  props: {
    installationId: {
      type: String,
      required: true,
    },
  },
  emits: ['enqueued'],
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  computed: {
  },
  methods: {
    years() {
      let year = new Date().getFullYear();
      const years = [];
      while (year > 2015) {
        years.push(year);
        year -= 1;
      }
      return years;
    },
    enqueue() {
      axios.post('/analysis/year_progress_installation_report', {
        installation_id: this.installationId,
        year: this.year,
      }).then((response) => {
        this.$emit('enqueued', response.data.export_id);
      }).catch(() => {
        Flash.error('Bericht konnte nicht erzeugt werden');
      });
    },
  },
};
</script>
