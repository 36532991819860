<template>
  <div v-if="loaded">
    <section class="fx-page-content">
      <section class="fx-header">
        <div class="fx-grid">
          <div class="fx-col">
            <ul class="fx-breadcrumps">
              <li><a href="/start">Start</a></li>
              <li><a href="/incidents">Störungen</a></li>
              <li v-if="localIncident">
                {{ localIncident.number }}
              </li>
            </ul>

            <div v-if="localIncident" class="fx-grid" style="justify-content: space-between; gap: .3rem">
              <h1 class="fx-header-title" style="align-self: flex-start;">
                <span class="fx-header-title_number">
                  Störung
                  <template v-if="localIncident.number">
                    #{{ localIncident.number }}
                  </template>
                </span>
                <span class="fx-header-title_name" :title="localIncident.incident_type.name">
                  {{ truncate(localIncident.incident_type.name) }}
                </span>
              </h1>

              <div style="align-self: flex-end;">
                <div class="fx-btn-group">
                  <a href="#" class="fx-btn-primary" @click.prevent="openEditIncidentModal()">Störung bearbeiten</a>
                  <drop-down classes="fx-btn-secondary">
                    <drop-down-item>
                      <a @click.prevent="confirmDeleteIncident()">
                        <i class="far fa-trash-alt fa-fw" /> Störung löschen
                      </a>
                    </drop-down-item>
                  </drop-down>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section v-if="localIncident" class="list-content pt-4">
        <div class="fx-grid">
          <!-- incident description and history -->
          <div class="fx-col-notop">
            <div class="fx-card-notop fx-card-content">
              <LinkAssign
                :href="localIncident.link"
                linkable-type="incident"
                :linkable-id="localIncident.id"
                />
              <LabelsAssign
                :preset-labels="localIncident.labels"
                label-type="incident"
                :lablable-id="localIncident.id"
                :can-edit="currentUser.policies.canEditProLabels"
                :link="localIncident.link"
              />
              <div class="bold mt-2">
                {{ localIncident.incident_type.name }}
              </div>

              <p v-if="!localIncident.description" class="secondary">
                Keine Beschreibung
              </p>
              <p v-if="localIncident.description" style="white-space: pre-wrap;">
                {{ localIncident.description }}
              </p>
            </div>

            <div class="fx-card-notop">
              <div class="fx-card-content">
                <PhotoGrid
                  :photos="localIncident.photos"
                  :photoable-id="localIncident.id"
                  :installation-id="localIncident.installation.id"
                  photoable-type="Incident"
                  title="Fotos"
                />
              </div>
            </div>

            <!-- incident status history -->
            <div class="fx-card-notop">
              <div class="fx-card-content">
                <h2 class="fx" style="margin-bottom: 2rem;">
                  Verlauf
                  <a href="#" class="fx-btn-secondary float-right" @click.prevent="openAddIncidentStatusModal">
                    <i class="fas fa-plus" /> Status hinzufügen
                  </a>
                </h2>
                <IncidentStatus
                  v-for="incStatus in sortedIncidentStatuses"
                  :key="incStatus.id"
                  :incident-status="incStatus"
                  :enable-move="(localIncident.description == null || localIncident.description == '')"
                  @move-status-text-to-description="moveStatusTextToDescription"
                  @edit-incident-status="openEditIncidentStatusModal"
                  @delete-incident-status="confirmDeleteIncidentStatus"
                />
              </div>
            </div>
          </div>

          <!-- sidebar with details and context -->
          <div class="fx-col-notop" style="max-width: 480px;">
            <IncidentDetails :incident="localIncident" @open-edit-incident-modal="openEditIncidentModal" />
            <Context :incident="localIncident" @open-edit-incident-modal="openEditIncidentModal" />
          </div>
        </div>
      </section>

      <section v-if="!localIncident" class="list-content pt-4">
        <div class="fx-grid">
          <div class="fx-col">
            <h3 style="margin-top: 1.5rem;">
              Diese Störung wurde gelöscht
            </h3>
            <p>
              <a id="incidents-list-link" href="/incidents" class="fx-btn-skeleton">Zur Liste aller Störungen</a>
              <a id="location-link" :href="'/locations/' + locationId" class="fx-btn-skeleton">Zum Objekt</a>
              <a id="installation-link" :href="'/installations/' + installationId" class="fx-btn-skeleton">Zur Anlage</a>
            </p>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';

import { TruncateFormatter } from 'mixins/formatters';
import FeatureFlipper from 'mixins/feature_flipper';

import Flash from 'flash/index';
import { useCurrentUserStore } from 'stores/current_user';
import { ref } from 'vue';
import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';
import confirmViaModal from 'helpers/confirm_modal';

import LabelsAssign from 'apps/labels/assign/index.vue';
import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';
import PhotoGrid from 'apps/photos/photo_grid.vue';
import LinkAssign from 'components/link_assign.vue';

import Api from '../api';
import IncidentDetails from './incident_details.vue';
import Context from './incident_context.vue';
import IncidentStatus from './incident_status.vue';
import AddIncidentStatusModal from './modals/add_incident_status_modal.vue';
import EditIncidentStatusModal from './modals/edit_incident_status_modal.vue';
import EditIncidentModal from './modals/edit_incident_modal.vue';
import IncidentModel from '../incident_model';

export default {
  name: 'IncidentShow',
  components: {
    IncidentDetails,
    Context,
    IncidentStatus,
    LabelsAssign,
    DropDown,
    DropDownItem,
    PhotoGrid,
    LinkAssign,
  },
  mixins: [FeatureFlipper, TruncateFormatter],
  props: {
    incidentId: {
      type: String,
      required: true,
    },
    installationId: {
      type: String,
      required: true,
    },
    locationId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const localIncident = ref(null);

    const nextStatus = () => {
      const incidentStatus = IncidentModel.getCurrentStatus(localIncident.value);
      if (incidentStatus.status === 'opened') {
        return 'closed';
      }
      return 'opened';
    };

    const findIncidentStatusIndexInList = (incidentStatusId) => _.findIndex(localIncident.value.incident_statuses, { id: incidentStatusId });

    const deleteIncidentStatusFromList = (response) => {
      const index = findIncidentStatusIndexInList(response.data.status.id);
      localIncident.value.incident_statuses.splice(index, 1);
    };

    const addIncidentStatusToList = (incidentStatus) => {
      localIncident.value.incident_statuses.push(incidentStatus);
    };

    const updateIncidentStatusInList = (incidentStatus) => {
      const index = findIncidentStatusIndexInList(incidentStatus.id);
      localIncident.value.incident_statuses.splice(index, 1, incidentStatus);
    };

    const deleteIncident = () => {
      Api.deleteIncident(localIncident.value.id).then(() => {
        localIncident.value = null;
        Flash.info('Störung gelöscht');
      }).catch(() => {
        Flash.error('Die Störung konnte nicht gelöscht werden');
      });
    };

    const deleteIncidentStatus = (incidentStatus) => {
      if (localIncident.value.incident_statuses.length < 2) {
        Flash.error('Der letzte Status kann nicht gelöscht werden');
        return;
      }

      Api.deleteIncidentStatus(localIncident.value.id, incidentStatus.id)
        .then(deleteIncidentStatusFromList);
    };

    const confirmDeleteIncident = () => {
      confirmViaModal({
        title: 'Störung löschen?',
        message: 'Löscht die Störung und den gesamten Verlauf.',
      }).then(() => {
        deleteIncident();
      });
    };

    const confirmDeleteIncidentStatus = (incidentStatus) => {
      confirmViaModal({
        title: 'Status löschen?',
        message: 'Diese Aktion kann nicht rückgängig gemacht werden.',
      }).then(() => {
        deleteIncidentStatus(incidentStatus);
      });
    };

    const openAddIncidentStatusModal = () => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: AddIncidentStatusModal,
            attrs: {
              incidentId: localIncident.value.id,
              initStatus: nextStatus(),
              onClose() {
                modalInstance.close();
              },
              onCreated(createdStatus) {
                modalInstance.close();
                addIncidentStatusToList(createdStatus);
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openEditIncidentStatusModal = (incidentStatus) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: EditIncidentStatusModal,
            attrs: {
              incidentId: localIncident.value.id,
              incidentStatus,
              onClose() {
                modalInstance.close();
              },
              onUpdated(updatedStatus) {
                modalInstance.close();
                updateIncidentStatusInList(updatedStatus);
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openEditIncidentModal = () => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: EditIncidentModal,
            attrs: {
              incident: localIncident.value,
              onClose() {
                modalInstance.close();
              },
              onUpdated(updatedIncident) {
                modalInstance.close();
                localIncident.value = updatedIncident;
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    return {
      localIncident,
      confirmDeleteIncident,
      confirmDeleteIncidentStatus,
      openAddIncidentStatusModal,
      openEditIncidentStatusModal,
      openEditIncidentModal,
      currentUser: useCurrentUserStore().currentUser,
    };
  },
  data() {
    return {
      loaded: false,
      showAddPhotos: false,
    };
  },
  computed: {
    sortedIncidentStatuses() {
      return _.orderBy(this.localIncident.incident_statuses, ['statusdate', 'created_at']).reverse();
    },
    incidentStatus() {
      return IncidentModel.getCurrentStatus(this.localIncident);
    },
  },
  mounted() {
    this.loadIncident();
  },
  methods: {
    moveStatusTextToDescription(incidentStatus) {
      axios.put(`/incidents/${this.localIncident.id}/move_description`, {
        incident_status_id: incidentStatus.id,
      }).then((response) => {
        this.localIncident = response.data.incident;

        Flash.info('Hinweis am Status zur Beschreibung gespeichert');
      }).catch(() => {
        Flash.error('Die Änderung konnte nicht gespeichert werden');
      });
    },
    updateLocalIncident(incident) {
      this.localIncident = incident;
    },
    loadIncident() {
      const that = this;
      axios.get(`/incidents/${this.incidentId}`)
        .then((response) => {
          that.localIncident = response.data.incident;
          that.loaded = true;
        }).catch(() => {
          Flash.alert('Incident konnte nicht geladen werden');
        });
    },
  },
};
</script>
