<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <h2 class="fx">
      Layout mit Auftrag testen
    </h2>

    <hr style="margin-top:0;">
    Sie können Änderungen an diesem Layout mit den Daten eines realen Auftrags testen, bevor Sie die Änderungen speichern.<br>
    Der "echte" Bericht des Auftrags wird dabei nicht überschrieben oder verändert.

    <div v-if="previewDownloadUrl == null">
      <br>
      <DBBasedSelect
        v-model="previewJobId"
        icon-id="job"
        :default-label="$t('comp.select.no-selection')"
        i18n-base-key="jobs"
        :load-options-url="`/select/jobs?only_open_jobs=false`"
        @changed="(value) => {previewJobId = value}"
      />
      <br>
      <TransactionButton
        v-model="reportInProgress"
        class="fx-btn-primary"
        title="Testbericht erstellen"
        :disabled="previewJobId == null"
        @click="createPreviewViaAPI()"
      />
    </div>
    <div v-else>
      <br>
      <i class="fas fa-file fx-inline-ico" /> <a :href="previewDownloadUrl" target="_blank">Testbericht herunterladen</a>
    </div>

    <div class="clearfix" />
  </div>
</template>

<script>
/* eslint-disable no-undef */
import axios from 'axios';
import Flash from 'flash/index';

import DBBasedSelect from 'components/select/db_based_select.vue';
import TransactionButton from 'components/transaction_button.vue';

export default {
  name: 'PreviewModal',
  components: {
    DBBasedSelect,
    TransactionButton,
  },
  props: {
    reportLayout: {
      type: Object,
      required: true,
    },
    previewLayoutId: {
      type: String,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      previewJobId: null,
      serverErrors: [],
      previewDownloadUrl: null,
      reportInProgress: false,
    };
  },
  methods: {
    createPreviewViaAPI() {
      const that = this;
      that.serverErrors = [];
      that.reportInProgress = true;
      axios.post(`/report_layouts/${this.previewLayoutId}/preview.json`, {
        report_layout: {
          color_scheme: this.reportLayout.color_scheme,
          shows_tags: this.reportLayout.shows_tags,
          sections: this.reportLayout.sections,
          layout_engine: this.reportLayout.layout_engine,
        },
        job_id: this.previewJobId,
      }).then((response) => {
        that.reportInProgress = false;
        that.previewDownloadUrl = response.data.download_url;
      }).catch((error) => {
        that.reportInProgress = false;
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.report_layouts;
        } else {
          new Flash('Fehler beim Speichern', { isError: true }).show();
        }
      });
    },
  },
};
</script>
