<template>
  <tr>
    <td>{{ index + 1 }}</td>
    <td>{{ jobArticle.article.number }}</td>
    <td>{{ jobArticle.article.group }}</td>
    <td>{{ jobArticle.article.name }}</td>
    <td style="text-align: right; padding-right: 5px;">
      {{ formattedQuantity }}
    </td>
    <td style="padding-left: 0;">
      {{ jobArticle.article.unit }}
    </td>
    <td>{{ jobArticle.comment }}</td>
  </tr>
</template>

<script>
import numeral from 'numeral';

export default {
  name: 'JobArticlesListItem',
  props: {
    jobArticle: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    formattedQuantity() {
      return numeral(Number(this.jobArticle.quantity)).format('0,0.[0000]');
    },
  },
};
</script>

<style>
</style>
