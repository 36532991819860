<template>
  <div class="repco_config_section" style="border: 0; padding: 0; min-width: 300px;">
    <div class="divider" style="font-size: .8rem">
      <a v-if="canAddSectionAbove" @click="$emit('add')"><i class="fas fa-plus" /> Element hinzufügen</a>
    </div>
    <div style="padding: 2rem 0 1rem 1rem; font-size: .8rem;">
      <a v-if="canDeleteSection" style="float: right; padding-top: .5rem;" @click="$emit('delete')"><i class="fas fa-trash" /> löschen</a>
      <h3 class="fx">
        {{ index + 1 }}. {{ name }}
      </h3>
      <a v-if="canToggleSettings" @click="$emit('settings')"><i class="fas fa-sliders-h" /> Einstellungen</a>&nbsp;&nbsp;
      <a v-if="canMoveUp" @click="$emit('moveUp')"><i class="fas fa-arrow-up" /> hoch</a>&nbsp;&nbsp;
      <a v-if="canMoveDown" @click="$emit('moveDown')"><i class="fas fa-arrow-down" /> runter</a>
    </div>
  </div>
</template>

<script>
import { useCurrentUserStore } from 'stores/current_user';

export default {
  name: 'SectionControls',
  props: {
    name: {
      type: String,
      default: 'SectionName',
    },
    hasSettings: {
      type: Boolean,
      default: true,
    },
    index: {
      type: Number,
      required: true,
    },
    listLength: {
      type: Number,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['add', 'delete', 'settings', 'moveUp', 'moveDown'],
  setup() {
    return {
      currentUser: useCurrentUserStore().currentUser,
    };
  },
  data() {
    return {};
  },
  computed: {
    canToggleSettings() {
      return !this.readonly && this.hasSettings;
    },
    canAddSectionAbove() {
      return !this.readonly && this.currentUser.pro && this.notHeader;
    },
    canDeleteSection() {
      return !this.readonly && this.currentUser.pro && this.notFooter && this.notHeader;
    },
    canMoveUp() {
      return !this.readonly && this.currentUser.pro && this.index > 1 && this.notFooter;
    },
    canMoveDown() {
      return !this.readonly && this.currentUser.pro && this.index < this.listLength - 2 && this.notHeader;
    },
    notFooter() {
      return this.index < this.listLength - 1;
    },
    notHeader() {
      return this.index > 0;
    },
  },
  mounted() {
    this.localSection = this.section;
  },
};
</script>
