<template>
  <div id="create-shared-installation-modal" class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>
    <h2 class="fx">
      Freigabe hinzufügen
    </h2>

    <button :class="tabClasses(0)" @click="setActiveTab(0)">
      Freigabe für bestehenden Nutzer
    </button>
    <button :class="tabClasses(1)" @click="setActiveTab(1)">
      Neuen Nutzer einladen
    </button>

    <hr style="margin-top:0;">
    <ServerErrors v-model="serverErrors" title="Daten konnten nicht gespeichert werden" margin-bottom="1rem" />

    <template v-if="isActiveTab(0)">
      <form @submit.prevent="submit_share">
        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline mandatory" for="user">Portal-Nutzer</label>
          </div>
          <div class="fx-form-content">
            <DBBasedSelect
              v-model="user_id"
              :default-label="$t('comp.select.no-selection')"
              i18n-base-key="portal-users"
              load-options-url="/select/portal_users"
              icon-id="user"
              @changed="onPortalUserSelected"
            />
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline mandatory" for="group_name">Freigabe</label>
          </div>
          <div class="fx-form-content">
            <select v-model="alsoShare" name="also_share">
              <option value="installation">
                Nur diese Anlage
              </option>
              <option value="location">
                Diese Anlage und alle Anlagen des selben Objekts
              </option>
              <option value="customer">
                Diese Anlage und alle Anlagen des selben Kunden
              </option>
            </select>
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline mandatory" for="group_name">Berechtigungen</label>
          </div>
          <div class="fx-form-content">
            <input id="role_jobs" v-model="roleJobs" type="checkbox">
            <label for="role_jobs">
              <i class="far fa-calendar-check role_icon" />
              {{ $t('activerecord.attributes.shared_installation.roles_values.jobs') }}
            </label>
            <br>
            <input id="role_inventory" v-model="roleInventory" type="checkbox">
            <label for="role_inventory"><i class="far fa-cube role_icon" />
              {{ $t('activerecord.attributes.shared_installation.roles_values.inventory') }}
            </label>
            <br>
            <input id="role_due_dates" v-model="roleDueDates" type="checkbox">
            <label for="role_due_dates"><i class="fas fa-circle role_icon" />
              {{ $t('activerecord.attributes.shared_installation.roles_values.due_dates') }}
            </label>
            <br>
            <div v-if="features.incidentsPro">
              <input id="role_incidents" v-model="roleIncidents" type="checkbox">
              <label for="role_incidents"><i class="fas fa-exclamation-triangle role_icon" />
                {{ $t('activerecord.attributes.shared_installation.roles_values.incidents') }}
              </label>
            </div>
            <div v-else>
              <input type="checkbox" disabled="true">
              <label for="role_incidents" style="color: #bbb;">
                <i class="fas fa-exclamation-triangle" />
                {{ $t('activerecord.attributes.shared_installation.roles_values.incidents') }} (pro)
              </label>
            </div>
          </div>
        </div>

        <hr>
        <button
          id="submit-share"
          type="submit"
          class="fx-btn-primary float-right"
          style="margin-bottom: 0.5rem; margin-left: 0.5rem;"
        >
          Freigabe hinzufügen
        </button>
        <button class="fx-btn-secondary float-right" @click.prevent="$emit('close')">
          Abbrechen
        </button>
        <div style="font-size: .825rem; padding-top: .5rem;">
          <i class="fas fa-info-circle" /> Der Nutzer wird per E-Mail benachrichtigt
        </div>
        <div class="clearfix" />
      </form>
    </template>

    <template v-if="isActiveTab(1)">
      <form @submit.prevent="submit_invite_and_share">
        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline mandatory" for="email">E-Mail</label>
          </div>
          <div class="fx-form-content">
            <input
              id="email"
              v-model="email"
              vv-validate="{ required: true,
                            regex: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ }"
              name="email"
              type="text"
            >
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline mandatory" for="organization_name">Firma</label>
          </div>
          <div class="fx-form-content">
            <input
              id="organization_name"
              v-model="organization_name"
              name="organization_name"
              type="text"
            >
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline mandatory" for="name">Name</label>
          </div>
          <div class="fx-form-content">
            <input
              id="name"
              v-model="name"
              name="name"
              type="text"
            >
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline mandatory" for="group_name">Freigabe</label>
          </div>
          <div class="fx-form-content">
            <select v-model="alsoShare" name="also_share">
              <option value="installation">
                Nur diese Anlage
              </option>
              <option value="location">
                Diese Anlage und alle Anlagen des selben Objekts
              </option>
              <option value="customer">
                Diese Anlage und alle Anlagen des selben Kunden
              </option>
            </select>
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline mandatory" for="group_name">Berechtigungen</label>
          </div>
          <div class="fx-form-content">
            <input id="role_jobs" v-model="roleJobs" type="checkbox">
            <label for="role_jobs">
              <i class="far fa-calendar-check role_icon" />
              {{ $t('activerecord.attributes.shared_installation.roles_values.jobs') }}
            </label>
            <br>
            <input id="role_inventory" v-model="roleInventory" type="checkbox">
            <label for="role_inventory">
              <i class="far fa-cube role_icon" />
              {{ $t('activerecord.attributes.shared_installation.roles_values.inventory') }}
            </label>
            <br>
            <input id="role_due_dates" v-model="roleDueDates" type="checkbox">
            <label for="role_due_dates"><i class="fas fa-circle role_icon" />
              {{ $t('activerecord.attributes.shared_installation.roles_values.due_dates') }}
            </label>
            <br>
            <div v-if="features.incidentsPro">
              <input id="role_incidents" v-model="roleIncidents" type="checkbox">
              <label for="role_incidents"><i class="fas fa-exclamation-triangle role_icon" />
                {{ $t('activerecord.attributes.shared_installation.roles_values.incidents') }}
              </label>
            </div>
            <div v-else>
              <input type="checkbox" disabled="true">
              <label for="role_incidents" style="color: #bbb;">
                <i class="fas fa-exclamation-triangle" /> {{ $t('activerecord.attributes.shared_installation.roles_values.incidents') }} (pro)
              </label>
            </div>
          </div>
        </div>
        <!--</div>-->

        <hr>
        <button
          id="submit-invite-and-share"
          type="submit"
          class="fx-btn-primary float-right"
          style="margin-bottom: 0.5rem; margin-left: 0.5rem;"
        >
          Einladen und Freigabe hinzufügen
        </button>
        <button class="fx-btn-secondary float-right" @click.prevent="$emit('close')">
          Abbrechen
        </button>
        <div style="font-size: .825rem; padding-top: .5rem;">
          <i class="fas fa-info-circle" /> Der Nutzer wird per E-Mail eingeladen
        </div>
        <div class="clearfix" />
      </form>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash';
import FeatureFlipper from 'mixins/feature_flipper';
import { useCurrentUserStore } from 'stores/current_user';

import DBBasedSelect from 'components/select/db_based_select.vue';
import ServerErrors from 'components/partials/server_errors.vue';

export default {
  name: 'EditSharingModal',
  components: {
    DBBasedSelect,
    ServerErrors,
  },
  mixins: [FeatureFlipper],
  props: {
    installationId: {
      type: String,
      required: true,
    },
    customerName: {
      type: String,
      required: true,
    },
  },
  emits: ['close', 'created'],
  setup() {
    return {
      currentUser: useCurrentUserStore().currentUser,
    };
  },
  data() {
    return {
      user_id: null,
      email: null,
      name: null,
      organization_name: null,
      serverErrors: [],
      activeTab: 0,
      roleJobs: true,
      roleInventory: false,
      roleDueDates: false,
      roleIncidents: false,
      alsoShare: 'installation',
    };
  },
  mounted() {
    this.organization_name = this.customerName;
  },
  methods: {
    onPortalUserSelected(userId) {
      this.user_id = userId;
    },
    isActiveTab(tab) {
      return (this.activeTab === tab);
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    tabClasses(tab) {
      if (tab === this.activeTab) {
        return 'fx-tab fx-tab__active';
      }
      return 'fx-tab';
    },
    submit_share() {
      this.shareViaAPI();
    },
    submit_invite_and_share() {
      this.inviteAndShareViaAPI();
    },
    inviteAndShareViaAPI() {
      const that = this;
      that.serverErrors = [];
      axios.post('/shared_installations.json', {
        shared_installation: {
          installation_id: this.installationId,
          email: this.email,
          name: this.name,
          organization_name: this.organization_name,
          role_jobs: this.roleJobs,
          role_inventory: this.roleInventory,
          role_due_dates: this.roleDueDates,
          role_incidents: this.roleIncidents,
          also_share: this.alsoShare,
        },
      }).then((response) => {
        Flash.info('Freigabe hinzugefügt');
        this.$emit('close');
        this.$emit('created', { ...response.data.shared_installation });
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.shared_installations;
        } else {
          that.serverErrors = ['Freigabe konnte nicht erstellt werden'];
        }
      });
    },
    shareViaAPI() {
      const that = this;
      that.serverErrors = [];
      axios.post('/shared_installations.json', {
        shared_installation: {
          installation_id: this.installationId,
          user_id: this.user_id,
          role_jobs: this.roleJobs,
          role_inventory: this.roleInventory,
          role_due_dates: this.roleDueDates,
          role_incidents: this.roleIncidents,
          also_share: this.alsoShare,
        },
      }).then((response) => {
        Flash.info('Freigabe hinzugefügt');
        this.$emit('created', { ...response.data.shared_installation });
      }).catch((error) => {
        if (error && error.response.status === 400) {
          that.serverErrors = error.response.data.shared_installations;
        } else {
          that.serverErrors = ['Freigabe konnte nicht erstellt werden'];
          Flash.error('Freigabe konnte nicht erstellt werden');
        }
      });
    },
  },
};
</script>

<style scoped>
i.role_icon {
  color: #888;
  padding-right: .2rem;
}
</style>
