<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>
    <h2 class="fx">
      {{ title }}
    </h2>
    <div class="fx-form-row">
      <div class="fx-form-label">
        <label for="file">Datei-Typ</label>
      </div>
      <div class="fx-form-content">
        <select v-model="attachmentType">
          <option value="auxiliary">
            Dokument
          </option>
          <option value="measurement_data">
            Messdaten
          </option>
          <option value="invoice">
            Rechnung
          </option>
        </select>
        <br>
        <span class="small">
          <i class="fas fa-exclamation-triangle" /> Dateien werden zusammen mit dem Bericht
          veröffentlicht
        </span>
      </div>
    </div>

    <dashboard
      :uppy="uppy"
      :props="{
        inline: true,
        hideUploadButton: false,
        height: 380,
        width: '100%',
      }"
    />
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';

import '@uppy/core/dist/style.min.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@uppy/dashboard/dist/style.min.css'; // using dashboard but using styles from dashboard

import { Dashboard } from '@uppy/vue';
import { useUppy, onUppyUploaded } from 'helpers/uppy';
import { ref } from 'vue';

export default {
  name: 'UploadModal',
  components: {
    Dashboard,
  },
  props: {
    uploadUrl: {
      type: String,
      required: true,
    },
  },
  emits: ['uploaded', 'close'],
  setup(props, { emit }) {
    const attachmentType = ref('auxiliary');

    const uppy = useUppy({
      allowedFileTypes: ['.pdf', '.xlsx', '.docx'],
      maxFileSizeMB: 5,
      maxNumberOfFiles: 1,
    });

    const uploadCallback = (uploadedFileData) => {
      axios.post(props.uploadUrl, {
        file: uploadedFileData,
        attachment_type: attachmentType.value,
      }).then(() => {
        emit('close');
        emit('uploaded');
      }).catch(() => {
        Flash.error('Fehler beim Hochladen');
      });
    };

    onUppyUploaded(uppy, uploadCallback);

    return { uppy, attachmentType };
  },
  data() {
    return {
      title: 'Eigene Dateien hochladen',
    };
  },
};
</script>
