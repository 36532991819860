<template>
  <div v-if="localInstallationType">
    <ServerErrors v-model="serverErrors" title="Wartungsanwendung konnte nicht gespeichert werden" margin-bottom="1rem" />

    <div id="general-data-card" class="fx-card fx-card-content">
      <!-- installation type name -->
      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory" for="installation_type_name">Name</label>
        </div>
        <div class="fx-form-content">
          <input
            id="installation-type-name"
            v-model="localInstallationType.name"
            type="text"
            autofocus="autofocus"
            placeholder=""
            class="has-form-field-hint"
          >
        </div>
      </div>

      <!-- installation type number -->
      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline " for="installation_type_number">Nummer</label>
        </div>
        <div class="fx-form-help">
          <HelpHover content="number_for_api_usage" />
        </div>
        <div class="fx-form-content">
          <input
            id="installation-type-number"
            v-model="localInstallationType.number"
            type="text"
            placeholder="optional"
            class="has-form-field-hint"
          >
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-content">
          <!-- central unit -->
          <input id="installation-type-has-central-unit" v-model="localInstallationType.has_central_unit" type="checkbox">
          <label class="inline" for="installation_type_has_central_unit">Anlagen haben eine Zentrale</label>
          <HelpHover content="has_central_unit" />
          <br>

          <!-- job number -->
          <input id="installation-type-has-job-number" v-model="localInstallationType.has_job_number" type="checkbox">
          <label class="inline" for="installation_type_has_job_number">Aufträge verwenden Auftragsnummern</label>
          <HelpHover content="has_job_number" />
          <br>

          <!-- photos -->
          <input id="installation-type-has-photos" v-model="localInstallationType.has_photos" type="checkbox">
          <label class="inline" for="installation_type_has_photos">Verwendet Fotos</label>
          <HelpHover content="has_photos" />
          <br>

          <!-- stock -->
          <input
            id="installation-type-has-stock"
            v-model="localInstallationType.has_stock"
            type="checkbox"
            :disabled="!features.stockPro"
          >
          <label class="inline" for="installation_type_has_stock">
            Lager-Funktion
            <sup><span class="license-type-label license-type-pro">pro</span></sup>
          </label>
          <HelpHover content="has_stock" />
        </div>
      </div>
    </div>

    <div id="group-data-card" class="fx-card fx-card-content">
      <h2 class="fx">
        Gruppen
      </h2>
      <p class="color-secondary">
        Komponenten einer Anlage können in Gruppen organisiert werden.
      </p>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline " for="installation_type_group_separator">Gruppen-Trenner</label>
        </div>
        <div class="fx-form-content">
          <select id="installation-type-group-separator" v-model="localInstallationType.group_separator" class="has-form-field-hint">
            <option value=".">
              .
            </option>
            <option value="/">
              /
            </option>
            <option value="-">
              -
            </option>
          </select>
          <div class="form-field-hint">
            Wird zwischen Gruppennummer und Nummer verwendet. Bspw 1.2 oder 1/2
          </div>
        </div>
      </div>

      <!-- group signatures -->
      <div class="fx-form-row">
        <div class="fx-form-content">
          <input id="installation-type-has-group-signatures" v-model="localInstallationType.has_group_signatures" type="checkbox">
          <label class="inline" for="installation_type_has_group_signatures">Kunden-Unterschriften werden pro Gruppe erfasst</label>
          <HelpHover content="group_signatures" />
        </div>
      </div>
    </div>

    <div id="label-data-card" class="fx-card fx-card-content">
      <h2 class="fx">
        Alternative Bezeichnungen
      </h2>
      <p class="color-secondary">
        Wir empfehlen die Bezeichnungen Komponente und Gruppe nur im Ausnahmefall zu verändern.
      </p>

      <!-- label for Foxtag ID -->
      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline " for="installation_type_tags_label">Bezeichnung QR-Code</label>
        </div>
        <div class="fx-form-help">
          <HelpHover content="tags_label" />
        </div>
        <div class="fx-form-content">
          <input
            id="installation-type-tags-label"
            v-model="localInstallationType.tags_label"
            type="text"
            placeholder="optional"
            class="has-form-field-hint"
          >
          <div class="form-field-hint">
            Bezeichnung im Singular (Einzahl), bspw. <strong>QR-Code</strong>.
          </div>
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline " for="installation_type_things_label">Bezeichnung Komponente</label>
        </div>
        <div class="fx-form-help">
          <HelpHover content="things_label" />
        </div>
        <div class="fx-form-content">
          <input
            id="installation-type-things-label"
            v-model="localInstallationType.things_label"
            type="text"
            placeholder="optional"
            class="has-form-field-hint"
          >
          <div class="form-field-hint">
            Bezeichnung im Singular (Einzahl), bspw. <strong>Gerät</strong>.
          </div>
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline " for="installation_type_groups_label">Bezeichnung Gruppe</label>
        </div>
        <div class="fx-form-content">
          <input
            id="installation-type-groups-label"
            v-model="localInstallationType.groups_label"
            type="text"
            placeholder="optional"
            class="has-form-field-hint"
          >
          <div class="form-field-hint">
            Bezeichnung im Singular (Einzahl), bspw. <strong>Gruppe</strong>.
          </div>
        </div>
      </div>
    </div>

    <div style="margin-bottom: 3rem;">
      <button id="save-changes-btn" class="fx-btn-primary float-right" style="margin-bottom: 0.5rem;" @click="updateViaAPI()">
        Einstellungen speichern
      </button>
      <div class="clearfix" />
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import axios from 'axios';
import { useCurrentUserStore } from 'stores/current_user';
import HelpHover from 'components/help_hover.vue';
import ServerErrors from 'components/partials/server_errors.vue';
import Flash from 'flash/index';
import FeatureFlipper from 'mixins/feature_flipper';

export default {
  name: 'InstallationTypeData',
  components: {
    ServerErrors,
    HelpHover,
  },
  mixins: [FeatureFlipper],
  props: {
    installationType: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const currentUser = useCurrentUserStore().currentUser;
    return {
      currentUser,
    };
  },
  data() {
    return {
      initialDataLoaded: false,
      serverErrors: [],
      localInstallationType: null,
    };
  },
  computed: {
  },
  mounted() {
    this.localInstallationType = this.installationType;
  },
  methods: {
    updateViaAPI() {
      const that = this;
      that.serverErrors = [];

      axios.put(`/installation_types/${this.localInstallationType.id}.json`, {
        installation_type: {
          name: this.localInstallationType.name,
          number: this.localInstallationType.number,
          has_central_unit: this.localInstallationType.has_central_unit,
          has_job_number: this.localInstallationType.has_job_number,
          has_photos: this.localInstallationType.has_photos,
          has_stock: this.localInstallationType.has_stock,
          group_separator: this.localInstallationType.group_separator,
          has_group_signatures: this.localInstallationType.has_group_signatures,
          tags_label: this.localInstallationType.tags_label,
          things_label: this.localInstallationType.things_label,
          groups_label: this.localInstallationType.groups_label,
        },
      }).then((response) => {
        that.localInstallationType = response.data.installation_type;
        window.scrollTo(0, 0);
        Flash.info('Änderungen gespeichert');
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.installation_types;
          window.scrollTo(0, 0);
          Flash.info('Bitte überprüfen Sie Ihre Eingaben');
        } else {
          Flash.error('Die Änderung konnte nicht gespeichert werden');
        }
      });
    },
  },
};
</script>
