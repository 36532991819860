<template>
  <div>
    <div class="repco_preview" style="border-bottom: 1px solid #bbb;">
      <template v-if="showForm">
        <div style="background-color: #eee; min-height: 16rem; border-radius: 8px; padding: 1rem;">
          <h3 class="fx">
            {{ name }}
          </h3>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline mandatory" for="customer_name">Fußzeile</label>
            </div>
            <div class="fx-form-content" style="width: 35rem;">
              <textarea
                v-model="localSection.text"
                rows="3"
                maxlength="250"
                type="text"
              />
              Die Fußzeile unterstützt diese Platzhalter:<br>
              <strong>%JOB.NUMBER%</strong> für die Auftragsnummer<br>
              <strong>%JOB.DATE%</strong> für das Auftragsdatum<br>
              <strong>%ORG.NAME%</strong> für den Firmenname<br>
              <help-article article-id="custom_report_footer">
                Hilfe zur Fußzeile und Platzhaltern
              </help-article>
            </div>
          </div>

          <button class="fx-btn-primary" @click="showForm=false">
            Zurück zur Vorschau
          </button>
        </div>
      </template>
      <template v-if="!showForm">
        <div class="float-left" style="margin-top: 4rem; max-width: 46rem;  font-size: .83rem;">
          {{ footerText }}
        </div>
        <div class="float-right" style="margin-top: 4rem; font-size: .83rem;">
          X / X
        </div>
      </template>
    </div>

    <Controls
      :index="index"
      :list-length="listLength"
      :name="name"
      :readonly="readonly"
      @settings="showForm = !showForm"
      @add="$emit('add')"
      @delete="$emit('delete')"
      @move-up="$emit('moveUp')"
      @move-down="$emit('moveDown')"
    />
  </div>
</template>

<script>
import Controls from './section_controls.vue';

export default {
  name: 'SectionFooter',
  components: {
    Controls,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      default: 'Fußzeile',
    },
    index: {
      type: Number,
      required: true,
    },
    listLength: {
      type: Number,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showForm: false,
      localSection: {},
    };
  },
  computed: {
    footerText() {
      return this.localSection.text;
    },
  },
  mounted() {
    this.localSection = this.section;
  },
};
</script>
