<template>
  <div>
    <table class="fx-thing-details">
      <tr v-if="thing.deployment && thing.deployment.position">
        <th class="fx-thing-details">
          Standort
        </th><td class="fx-thing-details t-thing-position">
          {{ thing.deployment.position }}
        </td>
      </tr>
      <tr v-if="showIds && thing.active_tag_serial">
        <th class="fx-thing-details">
          {{ tagLabel }}
        </th><td class="fx-thing-details t-thing-tag">
          {{ humanTagSerial(thing) }}
        </td>
      </tr>
      <tr v-if="showIds && thing.serial_number">
        <th class="fx-thing-details">
          S/N
        </th><td class="fx-thing-details t-thing-serial">
          {{ thing.serial_number }}
        </td>
      </tr>
      <tr v-if="thing.year_of_construction">
        <th class="fx-thing-details">
          Baujahr
        </th><td class="fx-thing-details t-thing-yoc">
          {{ thing.year_of_construction }}
        </td>
      </tr>
      <tr v-if="thing.approval_number">
        <th class="fx-thing-details">
          Zul.Nr.
        </th><td class="fx-thing-details t-thing-apn">
          {{ thing.approval_number }}
        </td>
      </tr>
      <template v-for="(dataRow, index) in thing.custom_data">
        <tr v-if="dataRow.value" :key="index">
          <th class="fx-thing-details">
            {{ dataRow.label }}
          </th><td class="fx-thing-details t-thing-details">
            {{ dataRow.value }}
          </td>
        </tr>
      </template>
    </table>
  </div>
</template>
<script>
import ThingPresenter from 'mixins/thing_presenter';

export default {
  name: 'ThingDetails',
  mixins: [ThingPresenter],
  props: {
    thing: {
      type: Object,
      required: true,
    },
    tagLabel: {
      type: String,
      default: 'Foxtag-ID',
    },
    showIds: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style>
</style>
