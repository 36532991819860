<template>
<section class="list-content">
  <div class="fx-grid">
    <div class="fx-col">
      <div id="checklists-index">
        <h1 class="fx">
          Checklisten für Prüfung von Komponenten
        </h1>
        <p>
          Mit Checklisten können Sie bei der Wartung einzelner Komponenten Daten erfassen oder Checks dokumentieren.
          <HelpArticle article-id="learn_more_checklists">
            mehr erfahren
          </HelpArticle>
        </p>
        <div class="fx-card">
          <table class="material">
            <colgroup>
              <col style="width: 70px;">
              <col style="width: 260px;">
              <col>
              <col style="width: 180px;">
            </colgroup>

            <tr class="material__controls">
              <th colspan="4" style="text-align: right;">
                <a :href="`/installation_types/${installationTypeId}/checklists/new?checklist_type=thing_type`" class="fx-btn-ico-primary">
                  <i class="fas fa-plus" />
                  Checkliste hinzufügen
                </a>
              </th>
            </tr>

            <tr>
              <th>
                <i class="fas fa-sort-numeric-down secondary" title="Sortierung" />
              </th>
              <th>Name</th>
              <th>{{ $t('words.applies_to') }}</th>
              <th class="controls_secondary">
                &nbsp;
              </th>
            </tr>

            <tr v-for="checklist in localChecklistsThingType" :key="checklist.id">
              <td>
                <div class="secondary">
                  {{ checklist.position }}
                </div>
              </td>
              <td>
                  <i class="fas fa-list" style="color: #888" />
                  <span style="margin-left: 0.3rem;">
                    <a :href="editLink(checklist)" class="no-color">{{ checklist.name }}</a>
                  </span>
              </td>
              <td>{{ checklist.filter_info }}</td>
              <td style="text-align: right;">
                <div class="fx-btn-group">
                    <a class="fx-btn-skeleton" :href="editLink(checklist)">{{ $t('edit') }}</a>
                    <ButtonDeleteIcon @click="confirmDeleteChecklist(checklist)" />
                  </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div style="margin-top: 2rem;">
        &nbsp;
      </div>
      <div>
      <h1 class="fx">
        Allgemeine Checklisten im Auftrag
      </h1>
        <p>
          Diese Checklisten können Sie direkt im Ablauf Ihrer Auftragstypen verwenden um allgemeine Daten zu erfassen,
          beispielsweise die Vor- oder Nacharbeiten in einem Wartungstermin.
        </p>
        <div class="fx-card">
          <table class="material">
            <colgroup>
              <col>
              <col style="width: 180px;">
            </colgroup>

            <tr class="material__controls">
              <th colspan="2" style="text-align: right;">
                <a :href="`/installation_types/${installationTypeId}/checklists/new?checklist_type=job_type`" class="fx-btn-ico-primary">
                  <i class="fas fa-plus" />
                  Checkliste hinzufügen
                </a>
              </th>
            </tr>

            <tr>
              <th>Name</th>
              <th class="controls_secondary">
                &nbsp;
              </th>
            </tr>

            <tr v-for="checklist in localChecklistsJobType" :key="checklist.id">
              <td>
                  <i class="fas fa-list" style="color: #888" />
                  <span style="margin-left: 0.3rem;">
                    <a :href="editLink(checklist)" class="no-color">{{ checklist.name }}</a>
                  </span>
              </td>
              <td style="text-align: right;">
                <div class="fx-btn-group">
                    <a class="fx-btn-skeleton" :href="editLink(checklist)">{{ $t('edit') }}</a>
                    <ButtonDeleteIcon @click="confirmDeleteChecklist(checklist)" />
                  </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';
import EntityList from 'helpers/entity_list';
import confirmViaModal from 'helpers/confirm_modal';

import ButtonDeleteIcon from 'components/button_delete_icon.vue';

export default {
  name: 'JobTypesList',
  components: {
    ButtonDeleteIcon,
  },
  props: {
    installationTypeId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const localChecklistsThingType = new EntityList();
    const localChecklistsJobType = new EntityList();

    const loadChecklistsThingType = () => {
      axios.get(`/installation_types/${props.installationTypeId}/checklists.json`, {
        params: {
          checklist_type: 'thing_type',
          page: 1,
          limit: 1000,
        },
      }).then((response) => {
        localChecklistsThingType.value = response.data.checklists;
      }).catch((err) => {
        if (err.message !== 'Request aborted') {
          Flash.error(err.message);
        }
      });
    };

    const loadChecklistsJobType = () => {
      axios.get(`/installation_types/${props.installationTypeId}/checklists.json`, {
        params: {
          checklist_type: 'job_type',
          page: 1,
          limit: 1000,
        },
      }).then((response) => {
        localChecklistsJobType.value = response.data.checklists;
      }).catch((err) => {
        if (err.message !== 'Request aborted') {
          Flash.error(err.message);
        }
      });
    };

    const deleteViaApi = (checklist) => {
      axios.delete(`/installation_types/${props.installationTypeId}/checklists/${checklist.id}.json`).then(() => {
        loadChecklistsThingType();
        loadChecklistsJobType();
        Flash.info('Checkliste gelöscht');
      });
    };

    // --- modals

    const confirmDeleteChecklist = (checklist) => {
      confirmViaModal({
        title: `Checkliste ${checklist.name} löschen?`,
        message: 'Diese Aktion kann nicht rückgängig gemacht werden.',
      }).then(() => {
        deleteViaApi(checklist);
      });
    };

    const editLink = (checklist) => `/installation_types/${props.installationTypeId}/checklists/${checklist.id}/edit`;

    return {
      confirmDeleteChecklist,
      loadChecklistsThingType,
      loadChecklistsJobType,
      editLink,
      localChecklistsThingType: localChecklistsThingType.reactiveList,
      localChecklistsJobType: localChecklistsJobType.reactiveList,
      thingType: {},
    };
  },
  computed: {
    showNoResults() {
      return (this.localChecklistsThingType.length === 0);
    },
  },
  mounted() {
    this.loadChecklistsThingType();
    this.loadChecklistsJobType();
  },
};
</script>
