<!-- eslint-disable vue/no-v-html -->
<template>
  <section class="fx-page-content">
    <div class="fx-grid">
      <div class="fx-col">
        <h2>Benachrichtigungen</h2>

        <div class="fx-btn-group">
          <span class="btn_segmented" tabindex="0">
            <button class="btn_segmented" :class="filterButtonAllState" @click="loadAll()"><i
              class="far fa-mail-bulk"
            />
              Alle {{ allCount }}</button><button
              class="btn_segmented"
              :class="filterButtonUnreadState"
              @click="loadUnread()"
            ><i class="far fa-envelope" /> Ungelesen {{ unreadCount }}</button><button
              class="btn_segmented"
              :class="filterButtonReadState"
              @click="loadRead()"
            ><i
              class="far fa-envelope-open"
            />
              Gelesen {{ readCount }}</button>
          </span>

          <button class="fx-btn-secondary" @click="markAllAsRead()">
            Alle als gelesen markieren
          </button>
        </div>

        <div class="fx-card">
          <div v-for="notification in notifications" :key="notification.id" class=" fx-flex-row">
            <div class="fx-flex-row-ico" @click="toggleMarkAsRead(notification)">
              <i
                v-if="!notification.read_at"
                class="fas fa-circle"
                style="color: rgb(9, 105, 218); font-size: 10px; padding-top: 10px;"
              />
            </div>
            <div class="fx-flex-row-ico" style="max-width: 40px; padding-right: 10px;">
              <div
                class="notification-icon-wrapper"
                :class="iconColor(notification)"
                style="cursor: pointer;"
                @click="toggleMarkAsRead(notification)"
              >
                <i :class="notification.icon" class="fa-fw" />
              </div>
            </div>
            <div class="fx-flex-row-main">
              <div class="fx-flex-row-title">
                <div
                  v-if="notification.title && notification.title.length"
                  style="cursor: pointer; font-weight: bold;"
                  @click="toggleMarkAsRead(notification)"
                >
                  {{
                    notification.title
                  }}
                </div>
              </div>
              <div cclass="fx-flex-row-subline" style="font-size: 1rem; margin-top: 10px;">
                <span class="notification-message" v-html="notification.message" />
                <HelpArticle v-if="notification.help_article" :article-id="notification.help_article">
                  mehr erfahren
                </HelpArticle>
                <div v-if="notification.link_url && notification.link_url.length" class="mt-4">
                  <a :href="notification.link_url">
                    {{ notification.link_text }}
                  </a>
                </div>
              </div>
            </div>
            <div class="fx-flex-row-nav">
              <div class="secondary pl-4">
                {{ formatDate(notification.created_at, "DD.MM.YYYY") }}<br>
                {{ formatDate(notification.created_at, "HH:mm") }}
              </div>
            </div>
          </div>
          <div
            v-if="!notifications.length"
            class="fx-list-row"
            style="padding: .8rem 1rem; color: #888; font-size: 1rem;"
            >
            {{ emptyListText }}
          </div>
        </div>

        <Pagination v-model="pagination" i18n-key="activerecord.models.notification" @navigate="loadPage" />
      </div>
    </div>
  </section>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';

import Flash from 'flash/index';
import { DateFormatter } from 'mixins/formatters';
import { ref, computed } from 'vue';
import Pagination from 'components/pagination.vue';

export default {
  name: 'NotificationsIndex',
  components: {
    Pagination,
  },
  mixins: [DateFormatter],
  setup() {
    const readCount = ref(0);
    const unreadCount = ref(0);
    const showNoResults = ref(false);
    const filter = ref('unread');
    const notifications = ref([]);
    const pagination = ref({
      page: 1,
      pageCount: 1,
      totalCount: 0,
      limit: 25,
    });

    const load = () => {
      axios.get('/notifications.json', {
        params: {
          filter: filter.value,
          page: pagination.value.page,
          limit: pagination.value.limit,
        },
      }).then((response) => {
        readCount.value = response.data.meta.read;
        unreadCount.value = response.data.meta.unread;
        notifications.value = response.data.notifications;

        pagination.value.page = response.data.meta.current_page;
        pagination.value.pageCount = response.data.meta.total_pages;
        pagination.value.totalCount = response.data.meta.total_count;
      }).catch((err) => {
        if (err.message !== 'Request aborted') {
          Flash.error(err.message);
        }
      });
    };

    const loadPage = (changedPagination) => {
      pagination.value = { ...changedPagination };
      load();
    };

    const reload = () => {
      pagination.value.page = 1;
      load();
    };

    const loadAll = () => {
      filter.value = null;
      reload();
    };

    const loadUnread = () => {
      filter.value = 'unread';
      reload();
    };

    const loadRead = () => {
      filter.value = 'read';
      reload();
    };

    const iconColor = (notification) => {
      if (notification.read_at == null) {
        return notification.color;
      }
      return '';
    };

    const findNotificationIndexInList = (id) => _.findIndex(notifications.value, { id });

    const toggleMarkAsRead = (notification) => {
      if (notification.can_mark_read === false) {
        Flash.info('Kann nicht als gelesen/ungelesen markiert werden');
        return;
      }

      axios.post(`/notifications/${notification.id}/toggle_mark_as_read`)
        .then((response) => {
          readCount.value = response.data.meta.read;
          unreadCount.value = response.data.meta.unread;

          const resultingNotification = response.data.notification;

          const index = findNotificationIndexInList(notification.id);

          // --- replace notification in list (changes appearance)
          notifications.value.splice(index, 1, resultingNotification);

          if (filter.value != null) {
            // --- remove notification from list (list is read or unread)
            setTimeout(() => {
              notifications.value.splice(index, 1);
              if (notifications.value.length === 0) {
                reload(); // go to first page
              }
            }, 200);
          }

          if (resultingNotification.read_at == null) {
            Flash.info('Als ungelesen markiert');
          } else {
            Flash.info('Als gelesen markiert');
          }
        }).catch((error) => {
          Flash.error(error);
        });
    };

    const markAllAsRead = () => {
      axios.post('/notifications/mark_all_as_read')
        .then(() => {
          Flash.info('Alle als gelesen markiert');
          reload();
        }).catch((error) => {
          Flash.error(error);
        });
    };

    const allCount = computed(() => readCount.value + unreadCount.value);

    const filterButtonAllState = computed(() => (filter.value == null ? 'on' : 'off'));

    const filterButtonReadState = computed(() => (filter.value === 'read' ? 'on' : 'off'));

    const filterButtonUnreadState = computed(() => (filter.value === 'unread' ? 'on' : 'off'));

    const emptyListText = computed(() => {
      if (filter.value === 'read') {
        return 'Keine gelesenen Benachrichtigungen';
      }
      if (filter.value === 'unread') {
        return 'Keine ungelesenen Benachrichtigungen';
      }
      return 'Keine Benachrichtigungen';
    });

    return {
      readCount,
      unreadCount,
      showNoResults,
      filter,
      notifications,
      pagination,
      load,
      reload,
      loadAll,
      loadUnread,
      loadRead,
      iconColor,
      toggleMarkAsRead,
      markAllAsRead,
      loadPage,
      allCount,
      filterButtonAllState,
      filterButtonReadState,
      filterButtonUnreadState,
      emptyListText,
    };
  },
};
</script>
