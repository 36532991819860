<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>
    <h2 class="fx">
      Anwendung wurde hinzugefügt
    </h2>
    <hr>
    <div style="margin: 0 0.985rem;">
      <p>
        Sie können nun Anlagen für die Wartungsanwendung
        <strong>{{ localInstallationType.name }}</strong>
        anlegen.
      </p>
      <div>
        <h4 style="margin-bottom: 0;">
          Demo-Anlage erzeugen
        </h4>
        <p style="margin-top: .4rem;">
          Für viele Anwendungen können Sie auf Knopfdruck eine Demo-Anlage erzeugen,
          um sich mit der Anwendung vertraut zu machen. Drücken Sie dafür den
          Knopf <strong>Demo-Anlage erzeugen</strong> in der Liste Ihrer Anwendungen.
        </p>
      </div>
      <h4 style="margin-bottom: 0;">
        Wartungsanwendung anpassen
      </h4>
      <p style="margin-top: .4rem;">
        Um die Anwendung an Ihre Wartungsabläufe anzupassen klicken sie auf <strong>bearbeiten</strong>
        in der Liste Ihrer Anwendungen.
      </p>
    </div>
    <hr>
    <div class="text-center" style="margin: 0 0.985rem; ">
      <a class="fx-btn-primary" href="#" @click="$emit('close')">Alles klar!</a>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    installationTypeId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      localInstallationType: {
        id: 'A',
        name: 'asdfasdf',
        demo_data_available: true,
      },
    };
  },
  mounted() {
    axios.get(`/installation_types/${this.installationTypeId}.json`).then((response) => {
      this.localInstallationType = response.data;
    });
  },
};
</script>
