<template>
  <div class="fx-flex-row">
    <div class="fx-flex-row-ico">
      <i class="fas fa-sitemap" />
    </div>
    <div class="fx-flex-row-main">
      <div class="fx-flex-row-title">
        <a class="no-color mr-2" :href="installationPath(installation.id)">
          <span v-if="installation.number" class="fx-flex-row-title-number">{{ installation.number }}</span>
          {{ installation.full_name }}
        </a>
        <Labels :labels="installation.labels" :link="installation.link" />
      </div>

      <div class="fx-flex-row-subline">
        <template v-if="!installation.passive">
          <NextCheckInfo :next-date="installation.next_check_at" :next-status="installation.next_check_status" />
          <NextCheckInfo
            v-if="installation.next_replacement_at"
            :next-date="installation.next_replacement_at"
            :next-status="installation.next_replacement_status"
            :is-replacement="true"
          />
        </template>
        <template v-if="installation.passive">
          <span class="secondary"><strong>Anlage ist passiv</strong></span>
        </template>

        <span v-if="installation.next_job_id" class="fx-ico-text fx-ico-text-job">
          <a
            fhref="`/jobs/${installation_next_job_id`}"
            class="no-color"
            @click.prevent="$emit('quickView', installation.next_job_id);"
          >
            <strong>{{ truncate(installation.next_job_number, 12) }}</strong>
            {{ truncate(installation.next_job_type_name, 100) }} {{ formatDate(installation.next_job_planned_at,
                                                                               'DD.MM.YYYY') }}
          </a>
        </span>
        <span v-else class="fx-ico-text fx-ico-text-job secondary">kein Auftrag geplant</span>
      </div>
    </div>
    <div class="fx-flex-row-nav">
      <div style="width: 120px; color: #666;">
        <a class="no-color" :href="installationPath(installation.id) + '#tab-things'">
          <span><i class="far fa-cube" /> {{ installation.things_count }}</span>
        </a>

        <a
          v-if="features.incidentsPro"
          class="no-color ml-2"
          :href="installationPath(installation.id) + '#tab-incidents'"
        >
          <template v-if="installation.open_incidents_count > 0">
            <i class="fas fa-exclamation-triangle color-warn" /> {{ installation.open_incidents_count }}
          </template>
          <template v-else>
            <span title=""><i class="fas fa-exclamation-triangle" /> {{ installation.open_incidents_count }}</span>
          </template>
        </a>
      </div>

      <a
        v-if="!installation.passive"
        class="fx-btn-skeleton"
        style="white-space: nowrap;"
        @click="newJob()"
      >Auftrag
        planen</a>
      <button
        v-if="installation.passive"
        class="fx-btn-skeleton"
        disabled="true"
        style="white-space: nowrap;"
      >
        Auftrag
        planen
      </button>
      <drop-down>
        <drop-down-item>
          <a :href="installationPath(installation.id)"><i class="fas fa-sitemap fa-fw" /> Anlage ansehen</a>
        </drop-down-item>
      </drop-down>
    </div>
  </div>
</template>
<script>
import FoxtagPaths from 'mixins/foxtag_paths';
import FeatureFlipper from 'mixins/feature_flipper';
import { DateFormatter, TruncateFormatter } from 'mixins/formatters';
import { useCurrentUserStore } from 'stores/current_user';

import NextCheckInfo from 'components/partials/next_check_info.vue';
import Labels from 'components/partials/labels.vue';
import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';

import openNewJobModal from 'helpers/open_new_job_modal';

export default {
  name: 'InstallationRow',
  components: {
    NextCheckInfo,
    Labels,
    DropDown,
    DropDownItem,
  },
  mixins: [FoxtagPaths, FeatureFlipper, DateFormatter, TruncateFormatter],
  props: {
    installation: {
      type: Object,
      required: true,
    },
  },
  emits: ['changed'],
  setup() {
    return {
      currentUser: useCurrentUserStore().currentUser,
      openNewJobModal,
    };
  },
  data() {
    return {
    };
  },
  computed: {
    thingsClass() {
      if (this.installation.things_count === 0) {
        return 'secondary';
      }
      return '';
    },
  },
  mounted() {
  },
  methods: {
    newJob() {
      this.openNewJobModal(this.installation.id).then(() => {
        this.$emit('changed');
      });
    },
  },
};
</script>
