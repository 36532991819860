<template>
  <div>
    <section class="fx-page-content">
      <div class="fx-grid">
        <div class="fx-col">
          <h1 class="fx">
            Auswertungen
          </h1>

          <div id="analysis-grid" class="fx-flex-grid mt-4">
            <div id="annual-analysis" class="fx-flex-grid-item-500">
              <div style="background-color: white; border: 1px solid #ddd; border-radius: 6px; padding: 1rem; height: 240px; position: relative;">
                  <h2 class="fx">
                    <i class="fas fa-chart-line fa-fw" style="margin-right: .5rem;" />Jahresfortschritt Wartungsanwendung
                  </h2>
                  <p>
                    Dieser Bericht eignet sich für Wartungsanwendungen, deren Komponenten mindestens einmal pro Kalenderjahr gewartet werden müssen.
                  </p>
                  <div style="position:absolute; bottom: 15px; margin-top: 1rem;">
                    <div v-if="currentUser.pro" class="fx-btn-group">
                      <a class="fx-btn-secondary" @click="openYearProgressExportsModal">Bericht erzeugen</a>
                    </div>
                    <ProFeatureHint v-else />
                  </div>
                </div>
            </div>

            <div id="material-and-services" class="fx-flex-grid-item-500">
              <div style="background-color: white; border: 1px solid #ddd; padding: 1rem; height: 240px; position: relative;">
                <h2 class="fx">
                  <Icon id="articles" class="fa-fw" style="margin-right: .8rem;" />Material und Leistungen
                </h2>
                <p>
                  Summe aller Artikelpositionen für alle erledigten Aufträge pro Monat.
                </p>
                <div style="position:absolute; bottom: 15px; margin-top: 1rem;">
                  <div v-if="currentUser.pro">
                    <a class="fx-btn-secondary" @click="openExportModalJobArticles">Summen exportieren</a>
                  </div>
                  <ProFeatureHint v-else />
                </div>
              </div>
            </div>

            <div id="due-replacements" class="fx-flex-grid-item-500">
              <div style="background-color: white; border: 1px solid #ddd; padding: 1rem; height: 240px; position: relative;">
                <h2 class="fx">
                  <i class="fas fa-sync-alt fa-fw" style="margin-right: .5rem;" />Austauschfälligkeiten
                </h2>
                <p>
                  Liste aller Komponenten, die bis zu einem bestimmten Datum ausgetauscht werden müssen.
                </p>
                <div style="position:absolute; bottom: 15px; margin-top: 1rem;">
                  <div v-if="currentUser.pro">
                    <a class="fx-btn-secondary" @click="openExportModalDueReplacements">Austauschfälligkeiten exportieren</a>
                  </div>
                  <ProFeatureHint v-else />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import openExportModal from 'helpers/open_export_modal';
import Icon from 'components/icon.vue';
import ProFeatureHint from 'components/pro_feature_hint.vue';

import { useCurrentUserStore } from 'stores/current_user';

export default {
  name: 'AnalysisIndex',
  components: {
    Icon,
    ProFeatureHint,
  },
  props: {
  },
  setup() {
    const openYearProgressExportsModal = () => openExportModal({
      exportableId: null,
      title: 'Jahresfortschritt pro Wartungsanwendung',
      intro: 'Jahresfortschritt pro Komponenten-Typ für alle Anlagen der Wartungsanwendung',
      exporttype: 'installation_type_year_progress',
    });

    const openExportModalJobArticles = () => openExportModal({
      title: 'Artikel-Positionen exportieren',
      exporttype: 'job_article_sums',
      intro: 'Exportiert die Summe der Artikel-Positionen für alle abgeschlossenen Aufträge.',
    });

    const openExportModalDueReplacements = () => openExportModal({
      title: 'Austauschfälligkeiten exportieren',
      exporttype: 'due_replacements',
    });

    return {
      openYearProgressExportsModal,
      openExportModalJobArticles,
      openExportModalDueReplacements,
      currentUser: useCurrentUserStore().currentUser,
    };
  },
};
</script>
