<template>
  <section class="fx-page-content">
  <div v-if="initialDataLoaded">
    <div v-if="localThing.deleted_at" style="background: red; color: white; font-size: .8rem; padding-left: 1rem;">
      Dieser
      Datensatz wurde am {{ formatDate(localThing.deleted_at, 'DD.MM.YYYY HH:mm') }} gelöscht und wird in Kürze aus dem
      System
      entfernt.
    </div>
    <section class="fx-header">
      <div class="fx-grid">
        <div class="fx-col" style="padding: 0 1rem;">
          <div v-if="isDeployed(localThing)" class="float-right">
            <div class="fx-due-dates-box">
              <h3>Fälligkeiten</h3>
              <NextCheckInfo :next-date="localThing.next_check_at" :next-status="localThing.next_check_status" />
              <NextCheckInfo
                :next-date="localThing.next_replacement_at"
                :next-status="localThing.next_replacement_status"
                :is-replacement="true"
              />
            </div>
          </div>

          <ul v-if="localInstallation" class="fx-breadcrumps">
            <li><a href="/start">Start</a></li>
            <li><a href="/locations">Objekte</a></li>
            <li><a :href="`/locations/${localInstallation.location.id}`">{{ locationNameBreadcrumps }}</a></li>
            <li><a :href="`/installations/${localInstallation.id}`">{{ installationNameBreadcrumps }}</a></li>
            <li>{{ thingTypeCategory(localThing) }}</li>
          </ul>

          <div v-if="localThing" class="fx-header-title">
            <h1 class="fx-header-title">
              <span class="fx-header-title_number">{{ localThing.thing_type.name }}</span>
              <span class="fx-header-title_name">{{ localThing.serial_number || humanTagSerial(localThing) }}</span>
            </h1>
          </div>

          <button :class="$tabClasses('overview')" @click="$setActiveTab('overview')">
            Übersicht
          </button>
          <button :class="$tabClasses('events')" @click="$setActiveTab('events')">
            Prüfhistorie
            <span class="fx-tab-count">{{ eventsCount }}</span>
          </button>
          <button
            v-if="features.incidentsPro"
            :class="$tabClasses('incidents')"
            @click="$setActiveTab('incidents')"
          >
            Störungen
            <span class="fx-tab-count">{{ incidentsCount }}</span>
          </button>
          <button
            v-if="currentUser.pro"
            :class="$tabClasses('photos')"
            @click="$setActiveTab('photos')"
          >
            Fotos
            <!--<span class="fx-tab-count">{{ incidentsCount }}</span>-->
          </button>
        </div>
      </div>
    </section>
    <section class="list-content">
      <div
        v-if="$isTabInitialized('overview')"
        v-show="$isTabActive('overview')"
        class="tab-panel"
        style="margin-top: 1rem;"
      >
        <div v-if="Object.keys(commandServerErrors).length" class="fx-grid" style="margin-top: 0rem;">
          <div class="fx-col" style="padding-top: 0;">
            <ServerErrorsCommand v-model="commandServerErrors" title="Änderungen konnten nicht gespeichert werden" />
          </div>
        </div>

        <div class="fx-grid" style="padding-right: 1rem; margin-top: 0rem; justify-content: flex-end;">
          <button v-if="isDeployed(localThing)" class="fx-btn-secondary" @click="openRemoveThingModal(localThing)">
            <i
              class="far fa-sign-out fa-fw"
            /> Außer Betrieb nehmen
          </button>
          <button
            v-if="isDeployed(localThing) && hasStock"
            class="fx-btn-secondary"
            style="margin-left: 5px;"
            @click="openReplaceThingModal(localThing)"
          >
            <i class="far fa-sync fa-fw" /> Tauschen
          </button>

          <drop-down classes="fx-btn-secondary" style="margin-left: 5px;">
            <drop-down-item v-if="isDeployed(localThing)">
              <a @click="openSetDeploymentDateThingModal(localThing)"><i class="far fa-calendar-alt fa-fw" /> Inbetriebnahme-Datum
                setzen</a>
            </drop-down-item>

            <drop-down-item v-if="hasStock && localThing.status === 'stocked'">
              <a @click.prevent="confirmStatusOutOfOrder()"><i class="far fa-sign-out fa-fw" /> Status
                'Außer Betrieb' setzen</a>
            </drop-down-item>
            <drop-down-item v-if="hasStock && localThing.status === 'out_of_order'">
              <a @click.prevent="confirmStatusStocked()"><i class="far fa-warehouse-alt fa-fw" /> Status
                'Im Lager' setzen</a>
            </drop-down-item>

            <drop-down-item v-if="!isDeployed(localThing)">
              <a @click.prevent="openRedeployThingModal(localThing)"><i
                class="far fa-undo fa-fw"
              /> Außerbetriebnahme rückgängig machen</a>
            </drop-down-item>
            <drop-down-item>
              <hr>
            </drop-down-item>
            <drop-down-item>
              <a @click.prevent="confirmDeleteThing(localThing)">
                <i class="far fa-trash-alt fa-fw" /> Komponente löschen
              </a>
            </drop-down-item>
          </drop-down>
        </div>

        <div class="fx-grid-group" style="max-width: 1300px;">
          <div class="fx-card fx-card-content" style="max-width: 50%;">
            <h2 class="fx">
              Stammdaten
            </h2>
            <form @submit.prevent="updateData()">
              <div class="fx-form-row">
                <div class="fx-form-label">
                  <label class="inline">Labels</label>
                </div>
                <div class="fx-form-content" style="padding-top: .3rem;">
                  <AssignFeatures v-model="localThing.assigned_features" :can-edit="true" />
                </div>
              </div>

              <div class="fx-form-row">
                <div class="fx-form-label">
                  <label class="inline mandatory" for="thing_type">Typ</label>
                </div>
                <div class="fx-form-content">
                  <DBBasedSelect
                    v-model="localThing.thing_type.id"
                    icon-id="thing_type"
                    i18n-base-key="thing_types"
                    :default-label="$t('comp.select.no-selection')"
                    :load-options-url="`/thing_types/for_select/?installation_type_id=${localThing.thing_type.installation_type_id}&category=${localThing.thing_type.category}`"
                    @changed="(selectedThingTypeId) => {localThing.thing_type.id = selectedThingTypeId;}"
                  />
                  <div class="form-field-hint">
                    Typ-Kategorie: {{ thingTypeCategory(localThing) }}
                  </div>
                </div>
              </div>

              <div class="fx-form-row">
                <div class="fx-form-label">
                  <label class="inline" for="thing_serial_number">Seriennummer</label>
                </div>
                <div class="fx-form-content">
                  <input
                    id="thing_serial_number"
                    v-model="localThing.serial_number"
                    name="serial_number"
                    type="text"
                  >
                  <FieldError v-model="commandServerErrors" field="serial_number" />
                </div>
              </div>

              <div class="fx-form-row">
                <div class="fx-form-label">
                  <label class="inline" for="thing_year_of_construction">Baujahr</label>
                </div>
                <div class="fx-form-content">
                  <input
                    id="thing_year_of_construction"
                    v-model="localThing.year_of_construction"
                    name="yearOfConstruction"
                    type="text"
                  >
                  <FieldError v-model="commandServerErrors" field="year_of_construction" />
                </div>
              </div>

              <div class="fx-form-row">
                <div class="fx-form-label">
                  <label class="inline" for="approval_number">Zulassungsnummer</label>
                </div>
                <div class="fx-form-content">
                  <input
                    id="approval_number"
                    v-model="localThing.approval_number"
                    name="approval_number"
                    type="text"
                  >
                  <FieldError v-model="commandServerErrors" field="approval_number" />
                </div>
              </div>

              <div v-for="(customDataRow, index) in localThing.custom_data" :key="index" class="fx-form-row">
                <div class="fx-form-label">
                  <label class="inline" :for="`custom-data-field-${index}`">{{ customDataRow.label }}</label>
                </div>
                <div class="fx-form-content">
                  <input
                    :id="`custom-data-field-${index}`"
                    v-model="customDataRow.value"
                    :name="`custom-data-field-${index}`"
                    type="text"
                  >
                </div>
              </div>

              <div class="fx-form-row">
                <div class="fx-form-content text-right">
                  <button class="fx-btn-primary">
                    Speichern
                  </button>
                </div>
              </div>
            </form>
            <hr>

            <div class="fx-form-row">
              <div class="fx-form-label">
                <label class="inline" for="tag_serial">{{ tagLabel }}</label>
              </div>
              <div class="fx-form-content" style="padding-top: 6px; font-size: .9rem;">
                <div v-if="localThing.active_tag_serial" class="v--hover">
                  <div class="float-right v--hover-target">
                    <button class="fx-context-circle" @click.stop="openEditFoxtagIdModal(localThing)">
                      <i class="fas fa-pencil fw" />
                    </button>
                  </div>
                  <VueQrcode v-if="hasFoxtagId(localThing)" :value="localThing.active_tag_serial" :options="{ margin: 0, width: 100 }" />
                  <span style="vertical-align: top; margin-left: 10px; cursor: pointer;" @click.stop="openEditFoxtagIdModal(localThing)">
                    {{ humanTagSerial(localThing) }}
                  </span>
                </div>
                <div v-else>
                  <a @click="openEditFoxtagIdModal(localThing)">{{ tagLabel }} hinzufügen</a>
                </div>
              </div>
            </div>
          </div>

          <div class="fx-card" style="max-width: 50%;">
            <div class="fx-card-content">
              <template v-if="localInstallation && localInstallation.location && isDeployed(localThing)">
                <h2 class="fx">
                  Einsatzort
                </h2>
                <form @submit.prevent="updateDeployment()">
                  <div class="fx-form-row">
                    <div class="fx-form-label">
                      <label class="inline" for="thing_group">Objekt</label>
                    </div>
                    <div class="fx-form-content" style="font-size: .9rem;padding-top: .4rem;">
                      <Location :location="localInstallation.location" :link="true" />
                    </div>
                  </div>

                  <div class="fx-form-row">
                    <div class="fx-form-label">
                      <label class="inline" for="thing_group">Anlage</label>
                    </div>
                    <div class="fx-form-content" style="font-size: .9rem;padding-top: .4rem;">
                      <Labels :labels="localInstallation.labels" />
                      <Installation :installation="localInstallation" :link="true" />
                    </div>
                  </div>

                  <div class="fx-form-row">
                    <div class="fx-form-label">
                      <label class="inline">Inbetriebnahme am</label>
                    </div>
                    <div class="fx-form-content" style="font-size: .9rem; padding-top: .4rem;">
                      <span v-if="localThing.deployment.deployed_at">
                        {{ formatDate(localThing.deployment.deployed_at, 'DD.MM.YYYY') }}
                        <span v-if="localThing.deployment.deployed_job_id">
                          (<a :href="`/jobs/${localThing.deployment.deployed_job_id}`">{{
                            localThing.deployment.deployed_job_label }}</a>)
                        </span>
                      </span>
                      <span v-else class="secondary">Unbekannt</span>
                    </div>
                  </div>

                  <div v-if="localThing.deployment.predecessor_thing_id" class="fx-form-row">
                    <div class="fx-form-label">
                      <label class="inline" for="thing_predecessor">Im Austausch für</label>
                    </div>
                    <div class="fx-form-content" style="font-size: .9rem; padding-top: .4rem;">
                      <i class="far fa-cube fx-inline-ico" />
                      <a :href="`/things/${localThing.deployment.predecessor_thing_id}`" class="no-color">{{
                        localThing.deployment.predecessor_label }}</a>
                    </div>
                  </div>

                  <div v-if="parentThing" class="fx-form-row">
                    <div class="fx-form-label">
                      <label class="inline" for="thing_parent">Eltern-Komponente</label>
                    </div>
                    <div class="fx-form-content" style="font-size: .9rem; padding-top: .4rem;">
                      <i class="far fa-cube fx-inline-ico" />
                      <a :href="`/things/${parentThing.id}`" class="no-color">{{ parentThing.deployment.number }} {{
                        parentThing.thing_type.name }}</a>
                      <span class="secondary" style="margin-left: .2rem;">{{ parentThing.deployment.position }}</span>
                    </div>
                  </div>

                  <hr>

                  <div class="fx-form-row">
                    <div class="fx-form-label">
                      <label class="inline mandatory" for="thing_group">Gruppe</label>
                    </div>
                    <div class="fx-form-content">
                      <DBBasedSelect
                        v-model="localThing.deployment.group.id"
                        i18n-base-key="groups"
                        :default-label="$t('comp.select.no-selection')"
                        :load-options-url="`/groups/for_select/?installation_id=${localThing.deployment.installation_id}`"
                        :readonly="isSubThing(localThing)"
                        @changed="(selectedGroupId) => {localThing.deployment.group.id = selectedGroupId;}"
                      />
                    </div>
                  </div>

                  <div class="fx-form-row">
                    <div class="fx-form-label">
                      <label class="inline mandatory" for="thing_number">Nummer</label>
                    </div>

                    <div v-if="isSubThing(localThing)" class="fx-form-content">
                      <div style="display: inline-block; width: 49%; vertical-align: top;">
                        <input
                          id="thing-number"
                          v-model="localThing.deployment.number"
                          name="thing-number"
                          type="text"
                          disabled="1"
                          readonly="true"
                        >
                        <FieldError v-model="commandServerErrors" field="deployments.number" />
                      </div>
                      <div style="display: inline-block; width: 49%; vertical-align: top; margin-left: 4px;">
                        <input
                          id="thing-sub-number"
                          v-model="localThing.deployment.sub_number"
                          name="thing-sub-number"
                          type="text"
                        >
                        <FieldError v-model="commandServerErrors" field="deployments.sub_number" />
                      </div>
                      <div class="clearfix" />
                    </div>

                    <div v-else class="fx-form-content">
                      <input
                        id="thing_number"
                        v-model="localThing.deployment.number"
                        name="localThing.number"
                        type="text"
                      >
                      <FieldError v-model="commandServerErrors" field="deployments.number" />
                    </div>
                  </div>

                  <div class="fx-form-row">
                    <div class="fx-form-label">
                      <label class="inline" for="position">Standort</label>
                    </div>
                    <div class="fx-form-content">
                      <input
                        id="position"
                        v-model="localThing.deployment.position"
                        name="position"
                        type="text"
                      >
                      <FieldError v-model="commandServerErrors" field="deployments.position" />
                    </div>
                  </div>

                  <div class="fx-form-row">
                    <div class="fx-form-content text-right">
                      <button class="fx-btn-primary">
                        Speichern
                      </button>
                    </div>
                  </div>
                </form>
              </template>
              <template v-if="!isDeployed(localThing)">
                <h2 class="fx">
                  Einsatzort
                </h2>
                <LastRemoval :thing-id="localThing.id" :status="localThing.status" />
              </template>
            </div>
          </div>

          <div v-if="localInstallation && isDeployed(localThing)" class="fx-card">
            <div class="fx-card-content">
              <h2 class="fx">
                Intervalle
              </h2>
              <form @submit.prevent="updateIntervalData()">
                <ThingIntervalForm :thing="localThing" :thing-type-id="localThing.thing_type.id" />
                <div class="fx-form-row fx-form-content">
                  <button class="fx-btn-primary">
                    Speichern
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div v-if="currentUser.support || currentUser.foxtag" class="secondary text-center">
          <br>Nur Kundenservice oder
          Foxtag
        </div>
        <div v-if="currentUser.support || currentUser.foxtag" class="fx-grid-group">
          <!-- <ThingEventsList v-model="localThing" /> -->
          <div class="fx-card fx-card-content">
            <strong>Deployment data</strong><br>
            <PrettyJSON :json="localThing.deployment" />
          </div>
        </div>
      </div>

      <div v-if="$isTabInitialized('events')" v-show="$isTabActive('events')" class="tab-panel">
        <div class="fx-grid" style="margin-top: 0rem;">
          <div class="fx-col">
            <EventsList
              :thing-id="localThing.id"
              :installation-id="localThing.deployment.installation_id"
              :show-test-trigger-events="showTestTriggerEvents"
              @count-changed="eventsCountChanged"
            />
          </div>
        </div>
      </div>

      <div v-if="$isTabInitialized('incidents')" v-show="$isTabActive('incidents')" class="tab-panel">
        <div class="fx-grid" style="margin-top: 0rem;">
          <div class="fx-col">
            <IncidentsList :thing-id="localThing.id" :installation-id="localThing.deployment.installation_id" @count-changed="incidentsCountChanged" />
          </div>
        </div>
      </div>

      <div v-if="$isTabInitialized('photos')" v-show="$isTabActive('photos')" class="tab-panel">
        <div class="fx-grid" style="margin-top: 0rem;">
          <div class="fx-col">
            <TabPhotos :thing-id="localThing.id" :installation-id="localThing.deployment.installation_id" />
          </div>
        </div>
      </div>
    </section>
  </div>
</section>
</template>
<script>
import axios from 'axios';
import Flash from 'flash/index';

import ThingPresenter from 'mixins/thing_presenter';
import FeatureFlipper from 'mixins/feature_flipper';
import { DateFormatter } from 'mixins/formatters';
import { useCurrentUserStore } from 'stores/current_user';
import { ref } from 'vue';
import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';
import confirmViaModal from 'helpers/confirm_modal';

import VueQrcode from '@chenfengyuan/vue-qrcode';

import Location from 'components/partials/location.vue';
import Installation from 'components/partials/installation.vue';
import Labels from 'components/partials/labels.vue';
import ThingIntervalForm from 'components/partials/thing_interval_form.vue';
import NextCheckInfo from 'components/partials/next_check_info.vue';
import ServerErrorsCommand from 'components/partials/server_errors_command.vue';
import FieldError from 'components/partials/field_error.vue';
import PrettyJSON from 'components/pretty_json.vue';
import DBBasedSelect from 'components/select/db_based_select.vue';

import SetDeploymentDateModal from 'apps/installations/groups/modals/set_deployment_date_modal.vue';
import RemoveModal from 'apps/things/remove_modal.vue';
import ReplaceModal from 'apps/things/replace_modal.vue';
import RedeployModal from 'apps/things/redeploy_modal.vue';
import EditFoxtagIdModal from 'apps/things/show/modals/edit_foxtag_id_modal.vue';

import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';
import IncidentsList from './incidents_list.vue';
import EventsList from './events_list.vue';
import AssignFeatures from './assign_features.vue';
import LastRemoval from './last_removal.vue';

import TabPhotos from './tab_photos.vue';

// import ThingEventsList from './thing_events_list.vue';

export default {
  name: 'ShowThing',
  components: {
    DBBasedSelect,
    EventsList,
    Location,
    Installation,
    Labels,
    ThingIntervalForm,
    NextCheckInfo,
    IncidentsList,
    ServerErrorsCommand,
    AssignFeatures,
    // ThingEventsList,
    FieldError,
    PrettyJSON,
    DropDown,
    DropDownItem,
    LastRemoval,
    TabPhotos,
    VueQrcode,
  },
  mixins: [ThingPresenter, FeatureFlipper, DateFormatter],
  props: {
    thingId: {
      type: String,
      required: true,
    },
    // installationId: {
    //   type: String,
    //   default: null,
    //   required: false,
    // },
    parentId: {
      type: String,
      default: null,
      required: false,
    },
    anyTriggerApiKeys: {
      type: Boolean,
      default: false,
    },
    initEventsCount: {
      type: Number,
      default: 0,
    },
    initIncidentsCount: {
      type: Number,
      default: 0,
    },
    locationNameBreadcrumps: {
      type: String,
      default: null,
    },
    installationNameBreadcrumps: {
      type: String,
      default: null,
    },
    hasStock: {
      type: Boolean,
      default: false,
    },
    tagLabel: {
      type: String,
      default: 'Foxtag-ID',
    },
    customDataset: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const localThing = ref(null);
    const parentThing = ref(null);
    const localInstallation = ref(null);
    const initialDataLoaded = ref(false);
    const commandServerErrors = ref({});

    // const isSubThing = (thing) => (thing.deployment && thing.deployment.sub_number !== 0);

    // --- if the thing has no custom fields, but the installation type has custom fields, add them as empty fields
    const prepareCustomData = () => {
      if (props.customDataset.length && !localThing.value.custom_data.length) {
        for (let i = 0; i < props.customDataset.length; i += 1) {
          localThing.value.custom_data.push({ label: props.customDataset[i].label, value: '' });
        }
      }
    };

    const loadParent = () => {
      if (!props.parentId) { return; }
      axios.get(`/installations/${localThing.value.deployment.installation_id}/deployments/${props.parentId}.json`)
        .then((response) => {
          parentThing.value = response.data.installation_deployment;
        }).catch(() => {
          Flash.error('Parent konnte nicht geladen werden');
        });
    };

    const loadInstallation = () => {
      if (!localThing.value.deployment.installation_id) { return; }
      axios.get(`/installations/${localThing.value.deployment.installation_id}/for_job`)
        .then((response) => {
          localInstallation.value = response.data;
        }).catch(() => {
          Flash.error('Anlage konnte nicht geladen werden');
        });
    };

    const loadThing = () => {
      axios.get(`/things/${props.thingId}.json`).then((response) => {
        localThing.value = response.data.thing;
        prepareCustomData();
        loadParent();
        loadInstallation();
        initialDataLoaded.value = true;
      }).catch(() => {
        Flash.error('Komponente konnte nicht geladen werden');
      });
    };

    const deleteViaAPI = (thing) => {
      axios.delete(`/things/${thing.id}`).then(() => {
        Flash.info('Komponente gelöscht');
        window.history.back();
      }).catch(() => {
        Flash.error('Komponente konnte nicht gelöscht werden');
      });
    };

    const updateViaAPI = (changes, successMessage) => {
      commandServerErrors.value = {};

      axios.put(`/things/${localThing.value.id}.json`, changes).then((response) => {
        localThing.value = response.data.thing;
        prepareCustomData();
        Flash.info(successMessage);
      }).catch((error) => {
        if (error.response && error.response.status === 400) {
          commandServerErrors.value = error.response.data;
          window.scrollTo(0, 0);
          Flash.info('Bitte überprüfen Sie Ihre Eingaben');
        } else {
          Flash.error('Die Änderung konnte nicht gespeichert werden');
        }
      });
    };

    const updateStatusViaAPI = (status) => {
      commandServerErrors.value = {};

      axios.put(`/things/${localThing.value.id}/update_status.json`, { status }).then((response) => {
        localThing.value = response.data.thing;
        prepareCustomData();
        Flash.info('Status aktualisiert');
      }).catch((error) => {
        if (error.response && error.response.status === 400) {
          commandServerErrors.value = error.response.data;
          window.scrollTo(0, 0);
          Flash.info('Status konnte nicht geändert werden');
        } else {
          Flash.error('Die Änderung konnte nicht gespeichert werden');
        }
      });
    };

    // ------

    const openEditFoxtagIdModal = (thing) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: EditFoxtagIdModal,
            attrs: {
              thing,
              tagLabel: props.tagLabel,
              onClose() {
                modalInstance.close();
              },
              onUpdated() {
                modalInstance.close();
                loadThing();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openRemoveThingModal = (thing) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: RemoveModal,
            attrs: {
              thing,
              hasStock: props.hasStock,
              onClose() {
                modalInstance.close();
              },
              onRemoved() {
                modalInstance.close();
                loadThing();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openReplaceThingModal = (thing) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: ReplaceModal,
            attrs: {
              thing,
              hasStock: props.hasStock,
              onClose() {
                modalInstance.close();
              },
              onReplaced() {
                modalInstance.close();
                loadThing();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openRedeployThingModal = (thing) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: RedeployModal,
            attrs: {
              thingId: thing.id,
              onClose() {
                modalInstance.close();
              },
              onRedeployed() {
                modalInstance.close();
                loadThing();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openSetDeploymentDateThingModal = (thing) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: SetDeploymentDateModal,
            attrs: {
              scope: 'thing',
              thing,
              message: 'Setzen Sie ein neues Inbetriebnahme-Datum für die ausgewählte Komponente.',
              onClose() {
                modalInstance.close();
              },
              onUpdated() {
                modalInstance.close();
                loadThing();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const confirmDeleteThing = (thing) => {
      confirmViaModal({
        title: 'Komponente löschen?',
        message: 'Löscht die Komponente, vorhandene Sub-Komponenten und alle mit der Komponente verbundenen Daten aus dem System.',
      }).then(() => {
        deleteViaAPI(thing);
      });
    };

    const confirmStatusOutOfOrder = () => {
      confirmViaModal({
        title: 'Status korrigieren?',
        message: 'Diese Aktion korrigiert den Status der Komponente auf \'Außer Betrieb\'',
        action: 'Status korrigieren',

      }).then(() => {
        updateStatusViaAPI('out_of_order');
      });
    };

    const confirmStatusStocked = () => {
      confirmViaModal({
        title: 'Status korrigieren?',
        message: 'Diese Aktion korrigiert den Status der Komponente auf \'Im Lager\'',
        action: 'Status korrigieren',

      }).then(() => {
        updateStatusViaAPI('stocked');
      });
    };

    return {
      currentUser: useCurrentUserStore().currentUser,
      localThing,
      localInstallation,
      parentThing,
      confirmDeleteThing,
      confirmStatusOutOfOrder,
      confirmStatusStocked,
      loadThing,
      loadInstallation,
      updateViaAPI,
      initialDataLoaded,
      commandServerErrors,
      openSetDeploymentDateThingModal,
      openRemoveThingModal,
      openReplaceThingModal,
      openRedeployThingModal,
      openEditFoxtagIdModal,
    };
  },
  data() {
    return {
      activeTab: null,
      initializedTabs: new Set(),
      eventsCount: 0,
      incidentsCount: 0,
      checkIntervalInfo: null,
      replacementIntervalInfo: null,
    };
  },
  computed: {
    showTestTriggerEvents() {
      return (this.anyTriggerApiKeys && this.isDeployed(this.localThing));
    },
  },
  mounted() {
    this.loadThing();
    this.eventsCount = this.initEventsCount;
    this.incidentsCount = this.initIncidentsCount;
    this.$setActiveTabFromURL('overview');
  },
  methods: {
    eventsCountChanged(newCount) {
      this.eventsCount = newCount;
      this.loadThing(); // to refresh due dates
    },

    incidentsCountChanged(newCount) {
      this.incidentsCount = newCount;
    },

    updateData() {
      let features = [];
      if (this.localThing.assigned_features == null || this.localThing.assigned_features.length === 0) {
        features = [' ']; // --- send empty element to trigger update
      } else {
        this.localThing.assigned_features.forEach((feature) => {
          features.push(feature.id);
        });
      }

      this.updateViaAPI({
        thing: {
          thing_type_id: this.localThing.thing_type.id,
          year_of_construction: this.localThing.year_of_construction,
          serial_number: this.localThing.serial_number,
          approval_number: this.localThing.approval_number,
          custom_data: this.localThing.custom_data,
          assigned_feature_ids: features,
        },
      }, 'Stammdaten gespeichert');
    },
    updateIntervalData() {
      this.updateViaAPI({
        thing: {
          check_interval_frequency: this.localThing.check_interval_frequency,
          check_interval_quantity: this.localThing.check_interval_quantity,
          inherit_check_interval: this.localThing.inherit_check_interval,
        },
      }, 'Intervalle gespeichert');
    },
    updateDeployment() {
      this.updateViaAPI({
        thing: {
          group_id: this.localThing.deployment.group.id,
          number: this.localThing.deployment.number,
          sub_number: this.localThing.deployment.sub_number,
          position: this.localThing.deployment.position,
        },
      }, 'Einsatzort gespeichert');
    },

  },
};
</script>
