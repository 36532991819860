<template>
  <div>
    <div>
      <div class="restricted-width">
        <NavBreadcrumps path="administration/installation-types" />
      </div>

      <div style="max-width: 800px;">
        <h1 class="fx">
          Wartungsanwendungen
        </h1>
        <p>
          Mit einer Wartungsanwendung legen Sie fest, wie die Wartung eines bestimmten Anlagentyps dokumentiert wird
          und welche Informationen im Bericht abgedruckt werden.
          <help-article article-id="learn_more_installation_types">
            mehr erfahren
          </help-article>
        </p>
      </div>

      <div class="fx-card">
        <div class="fx-list-row" style="background-color: #f2f2f2; padding: 1rem; ">
          <div class="fx-single-control-col" style="margin:0;">
            <div class="fx-btn-group">
              <a id="select-installation-type" href="/directory" class="fx-btn-ico-green"><i class="fas fa-drafting-compass" /> Aus Verzeichnis
                auswählen</a>
              <button id="create-installation-type" class="fx-btn-ico-primary" @click.prevent="openNewInstallationTypeModal()">
                <i class="fas fa-plus" /> Eigene Anwendung anlegen
              </button>
            </div>
          </div>

          <div class="clearfix" />
        </div>

        <div class="fx-list-row">
          <div class="fx-400px-col">
            <strong>Name</strong>
          </div>
          <div class="fx-200px-col">
            <strong>Anzahl Anlagen</strong>
          </div>
          <div class="fx-100px-col">
            <strong>Anzahl Typen</strong>
          </div>
          <div class="clearfix" />
        </div>

        <div v-if="!loaded" style="text-align: center;">
          <i class="fx-spinner" />
        </div>
        <div id="installation-type-rows">
          <div v-for="installationType in localInstallationTypes" :key="installationType.id" class="fx-list-row">
            <div class="fx-single-control-col">
              <div class="fx-btn-group">
                <a class="fx-btn-skeleton" :href="installationTypePath(installationType.id)">bearbeiten</a>
                <drop-down>
                  <drop-down-item>
                    <a @click.prevent="duplicateViaApi(installationType)"><i class="far fa-clone fa-fw" />
                      Duplizieren</a>
                  </drop-down-item>
                  <template v-if="installationType.demo_data_available">
                    <drop-down-item>
                      <a @click.prevent="createDemoInstallation(installationType)"><i
                        class="far fa-sitemap fa-fw"
                      /> Demo-Anlage erzeugen</a>
                    </drop-down-item>
                  </template>
                  <template v-else>
                    <drop-down-item>
                      <span class="disabled"><i class="far fa-sitemap fa-fw" /> Demo-Anlage
                        erzeugen</span>
                    </drop-down-item>
                  </template>
                  <drop-down-item>
                    <hr>
                  </drop-down-item>
                  <template v-if="installationType.installations_count === 0">
                    <drop-down-item>
                      <a @click.prevent="confirmDeleteInstallationType(installationType)"><i
                        class="far fa-trash-alt fa-fw"
                      /> Löschen</a>
                    </drop-down-item>
                  </template>
                  <template v-else>
                    <drop-down-item>
                      <span class="disabled"><i class="far fa-trash-alt fa-fw" />
                        Löschen</span>
                    </drop-down-item>
                  </template>
                </drop-down>
              </div>
            </div>

            <div class="fx-400px-col">
              <a class="no-color" :href="installationTypePath(installationType.id)">
                <div v-if="installationType.number" class="secondary">{{ installationType.number }}</div>
                {{ installationType.name }}
              </a>
            </div>
            <div class="fx-200px-col" :class="counterClass(installationType.installations_count)">
              <i class="far fa-sitemap" /> {{ installationType.installations_count }}
              <div v-if="installationType.demo_data_available && installationType.installations_count === 0">
                <button class="fx-btn-skeleton" @click.prevent="createDemoInstallation(installationType)">
                  Demo-Anlage
                  erzeugen
                </button>
              </div>
            </div>
            <div class="fx-100px-col" :class="counterClass(installationType.thing_types_count)">
              {{ installationType.thing_types_count }}
            </div>
            <div class="clearfix" />
          </div>
        </div>

        <div v-if="showNoResults" id="empty-state" style="padding: 1rem; text-align: center;">
          Keine Wartungsanwendungen gefunden
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import Flash from 'flash/index';
import FoxtagPaths from 'mixins/foxtag_paths';
import confirmViaModal from 'helpers/confirm_modal';
import waitForAsyncModal from 'helpers/wait_for_async_modal';
import NavBreadcrumps from 'components/nav_breadcrumps.vue';

import { ref } from 'vue';
import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';

import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';

import NewInstallationTypeModal from './new_installation_type_modal.vue';
import AddedFromDirectoryModal from './added_from_directory_modal.vue';

export default {
  name: 'InstallationTypesList',
  components: {
    DropDown,
    DropDownItem,
    NavBreadcrumps,
  },
  mixins: [FoxtagPaths],
  props: {
    createdInstallationTypeId: {
      type: String,
      default: null,
    },
  },
  setup() {
    const localInstallationTypes = ref([]);

    const deleteLocalInstallationType = (installationTypeId) => {
      const index = _.findIndex(localInstallationTypes.value, { id: installationTypeId });
      localInstallationTypes.value.splice(index, 1);
    };

    const deleteViaApi = (installationType) => {
      axios.delete(`/installation_types/${installationType.id}.json`).then(() => {
        deleteLocalInstallationType(installationType.id);
        Flash.info('Wartungsanwendung gelöscht');
      });
    };

    const confirmDeleteInstallationType = (installationType) => {
      confirmViaModal({
        title: `'${installationType.name}' löschen?`,
        message: 'Diese Aktion kann nicht rückgängig gemacht werden.',
      }).then(() => {
        deleteViaApi(installationType);
      });
    };

    const openNewInstallationTypeModal = () => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: NewInstallationTypeModal,
            attrs: {
              onClose() {
                modalInstance.close();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const waitForDemoInstallation = (sidekiqJobId, installationType) => {
      waitForAsyncModal({
        title: 'Demo-Anlage wird erzeugt ...',
        message: 'Sie werden gleich zur Anlage weitergeleitet.',
        sidekiqJobId,
      }).then(() => {
        window.location.href = `/installation_types/${installationType.id}/latest_installation`;
      });
    };

    const createDemoInstallation = (installationType) => {
      axios.post(`/installation_types/${installationType.id}/create_demo_installation.json`).then((response) => {
        waitForDemoInstallation(response.data.sidekiq_job_id, installationType);
      }).catch(() => {
        Flash.error('Fehler beim Erzeugen der Demo-Anlage');
      });
    };

    const openAddedFromDirectoryModal = (installationTypeId) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: AddedFromDirectoryModal,
            attrs: {
              installationTypeId,
              onClose() {
                modalInstance.close();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    return {
      localInstallationTypes,
      confirmDeleteInstallationType,
      openNewInstallationTypeModal,
      openAddedFromDirectoryModal,
      createDemoInstallation,
    };
  },
  data() {
    return {
      loaded: false,
      showEmptyState: false,
    };
  },
  computed: {
    showNoResults() {
      return (this.loaded && (this.localInstallationTypes.length === 0));
    },
  },
  mounted() {
    if (this.createdInstallationTypeId) {
      this.openAddedFromDirectoryModal(this.createdInstallationTypeId);
    }
    this.loadInstallationTypes();
  },
  methods: {
    counterClass(count) {
      if (count === 0) {
        return 'secondary';
      }
      return '';
    },
    loadInstallationTypes() {
      this.loaded = false;

      axios.get('/installation_types.json?').then((response) => {
        this.localInstallationTypes = response.data.installation_types;
        this.loaded = true;
      });
    },
    duplicateViaApi(installationType) {
      axios.post(`/installation_types/${installationType.id}/duplicate.json`).then((response) => {
        window.location.href = `/installation_types/${response.data.installation_type_id}`;
      }).catch(() => {
        Flash.error('Fehler beim Duplizieren');
      });
    },
  },
};
</script>
