<template>
  <div>
    <div class="fx-grid" style="margin-top: 0;">
      <div class="fx-col">

        <div style="display:flex; justify-content: space-between; margin-top: 0">
          <div style="flex: 1; max-width: 650px;">
            <h1 class="fx">
              Komponenten-Typen
            </h1>
            <p>
              Der Typ einer Komponente bestimmt über das Prüfintervall und die Checks und Messdaten,
              die dokumentiert werden können.
              <help-article article-id="learn-more-thing-types">
                Mehr erfahren
              </help-article>
            </p>
          </div>
          <div style="align-self: flex-end;">
            <div class="fx-btn-group">
              <button id="create-thing-type" class="fx-btn-ico-primary" @click.prevent="openEditThingTypeModal()">
                <i class="fas fa-plus" /> Typ hinzufügen
              </button>
              <drop-down id="import-export-btn" text="Import/Export" classes="fx-btn-secondary">
                <drop-down-item>
                  <a :href="importHref()"><i class="far fa-file-import fa-fw" /> Typen importieren</a>
                </drop-down-item>
                <drop-down-item>
                  <a @click.prevent="openExportModalThingType"><i class="far fa-file-export fa-fw" />
                    Typen exportieren</a>
                </drop-down-item>
              </drop-down>
            </div>
          </div>
        </div>

        <div id="thing-types-card" class="fx-card">
          <div class="fx-list-row" style="background-color: #f2f2f2; padding: 1rem; ">
            <div class="fx-500px-col" style="margin:0;">
              <div class="fx-btn-group">
                <select id="category-filter" v-model="filter.category" style="display: inline-block; width: 220px; margin: 0;">
                  <option value="">
                    Alle Kategorien
                  </option>
                  <option value="thing">
                    Komponenten
                  </option>
                  <option value="sub_thing">
                    Sub-Komponenten
                  </option>
                  <option value="central_unit">
                    Zentralen
                  </option>
                </select>
                <input
                  id="query-filter"
                  v-model="filter.query"
                  type="text"
                  style="display: inline-block; width: 250px; margin: 0;"
                  placeholder="Name, Hersteller, Modell"
                >
              </div>
            </div>
            <div class="clearfix" />
          </div>

          <div class="fx-list-row">
            <div class="fx-400px-col">
              <strong>Typ</strong>
            </div>
            <div class="fx-180px-col">
              <strong>Intervalle</strong>
            </div>
            <div class="fx-300px-col">
              <strong>Checklisten</strong>
            </div>
            <div class="fx-50px-col">
              <strong>Anzahl</strong>
            </div>
            <div class="clearfix" />
          </div>
          <div id="thing-type-rows">
            <ListItem
              v-for="thingType in localThingTypes"
              :key="thingType.id"
              :thing-type="thingType"
              @open-edit-thing-type="openEditThingTypeModal"
              @open-confirm-delete="confirmDeleteThingType"
              @open-merge-thing-type="openMergeThingTypeModal"
            />
            <div v-if="showNoResults" style="padding: 1rem; text-align: center;">
              Keine Typen gefunden
            </div>
          </div>
        </div>
        <Pagination
          v-model="pagination"
          i18n-key="activerecord.models.thing_type"
          :init-from-url="false"
          :update-url="false"
          @navigate="loadPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import Flash from 'flash/index';
import EntityList from 'helpers/entity_list';
import { ref, watch } from 'vue';
import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';
import confirmViaModal from 'helpers/confirm_modal';

import Pagination from 'components/pagination.vue';
import waitForAsyncModal from 'helpers/wait_for_async_modal';

import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';
import openExportModal from 'helpers/open_export_modal';

import EditThingTypeModal from './edit_thing_type_modal.vue';
import MergeThingTypeModal from './merge_thing_type_modal.vue';

import ListItem from './list_item.vue';

export default {
  name: 'ThingTypesList',
  components: {
    DropDown,
    DropDownItem,
    ListItem,
    Pagination,
  },
  props: {
    installationTypeId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const localThingTypes = new EntityList();
    const pagination = ref({
      page: 1,
      pageCount: 1,
      totalCount: 0,
      limit: 25,
    });
    const filter = ref({
      query: null,
      category: '',
    });

    const load = () => {
      axios.get(`/thing_types.json?installation_type=${props.installationTypeId}`, {
        params: {
          query: filter.value.query,
          category: filter.value.category,
          page: pagination.value.page,
          limit: pagination.value.limit,
        },
      }).then((response) => {
        localThingTypes.value = response.data.thing_types;
        pagination.value.page = response.data.meta.current_page;
        pagination.value.pageCount = response.data.meta.total_pages;
        pagination.value.totalCount = response.data.meta.total_count;
      }).catch((err) => {
        if (err.message !== 'Request aborted') {
          Flash.error(err.message);
        }
      });
    };

    const deleteViaApi = (thingType) => {
      axios.delete(`/thing_types/${thingType.id}.json`).then(() => {
        localThingTypes.remove(thingType);
        Flash.info('Typ gelöscht');
      });
    };

    const loadPage = (changedPagination) => {
      pagination.value = { ...changedPagination };
      load();
    };

    const search = () => {
      localThingTypes.value = [];
      pagination.value.page = 1;
      load();
    };

    const resetSearch = () => {
      filter.value = {
        query: null,
        category: '',
      };
    };

    const debouncedSearch = _.debounce(() => {
      search();
    }, 250);

    // --- modals

    const confirmDeleteThingType = (thingType) => {
      confirmViaModal({
        title: 'Typ löschen?',
      }).then(() => {
        deleteViaApi(thingType);
      });
    };

    const openEditThingTypeModal = (thingType) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: EditThingTypeModal,
            attrs: {
              thingType,
              installationTypeId: props.installationTypeId,
              onClose() {
                modalInstance.close();
              },
              onCreated(newThingType) {
                localThingTypes.append(newThingType);
              },
              onUpdated(updatedThingType) {
                localThingTypes.update(updatedThingType);
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openMergeThingTypeModal = (thingType) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: MergeThingTypeModal,
            attrs: {
              thingType,
              installationTypeId: props.installationTypeId,
              onClose() {
                modalInstance.close();
              },
              onStarted(sidekiqJobId) {
                waitForAsyncModal({
                  title: 'Typen werden zusammengelegt ...',
                  message: 'Diese Aktion kann einige Minuten dauern.',
                  sidekiqJobId,
                }).then(() => {
                  search();
                });
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openExportModalThingType = () => openExportModal({
      title: 'Komponenten-Typ-Export',
      exporttype: 'thing_types',
      exportableId: props.installationTypeId,
    });

    const importHref = () => `/imports/thing_types/new?installation_type=${props.installationTypeId}`;

    watch(filter, () => {
      debouncedSearch();
    }, { deep: true });

    return {
      loadPage,
      pagination,
      filter,
      resetSearch,
      debouncedSearch,
      openEditThingTypeModal,
      openMergeThingTypeModal,
      openExportModalThingType,
      confirmDeleteThingType,
      importHref,
      search,
      localThingTypes: localThingTypes.reactiveList,
    };
  },
  computed: {
    showNoResults() {
      return (this.localThingTypes.length === 0);
    },
  },
};
</script>
