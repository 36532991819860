import numeral from 'numeral';

numeral.register('locale', 'de', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  // eslint-disable-next-line no-unused-vars
  ordinal(number) {
    return '.';
  },
  currency: {
    symbol: '€',
  },
});

numeral.locale('de');
