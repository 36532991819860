<template>
  <div class="fx-flex-row">
    <div class="fx-flex-row-ico">
      <i class="fas fa-briefcase" />
    </div>
    <div class="fx-flex-row-main">
      <div class="fx-flex-row-title">
        <a class="no-color mr-2" :href="customerUrl">
          <span class="fx-flex-row-title-number">{{ customer.number }}</span>
          {{ customer.name }}
        </a>
        <Labels :labels="customer.labels" :link="customer.link" />
      </div>

      <div class="fx-flex-row-subline">
        <span v-if="customer.address_one_line && customer.address_one_line.length" class="fx-ico-text fx-ico-text-map">
          {{ truncate(customer.address_one_line, 200) }}
        </span>
      </div>
    </div>

    <div class="fx-flex-row-nav">
      <div style="width: 150px; color: #666;">
        <span :class="locationCountClass">
          <a :href="`${customerUrl}#locations`" class="no-color">
            <i class="fas fa-building" /> {{ customer.locations_count }}
            &nbsp;
            <i class="fas fa-sitemap" /> {{ customer.installations_count }}
          </a>
        </span>

        <a
          v-if="hasNote"
          v-tippy="noteTooltip"
          :href="`${customerUrl}`"
          class="no-color ml-2"
        ><i
          class="fas fa-comment"
        /> Notiz</a>
      </div>

      <drop-down :disabled="!canCustomerDelete">
        <drop-down-item><a :href="customerUrl"><i class="far fa-briefcase fw" /> Kunden ansehen</a></drop-down-item>
        <template v-if="customer.locations_count === 0">
          <drop-down-item>
            <a @click.prevent="$emit('open-confirm-delete', customer)"><i class="far fa-trash-alt fw" />
              Kunden löschen</a>
          </drop-down-item>
        </template>
        <template v-else>
          <drop-down-item>
            <span class="disabled"><i class="far fa-trash-alt fw" /> Kunden
              löschen</span>
          </drop-down-item>
        </template>
      </drop-down>
    </div>
  </div>
</template>

<script>
import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';
import Labels from 'components/partials/labels.vue';

import { TruncateFormatter } from 'mixins/formatters';

export default {
  name: 'CustomerListItem',
  components: {
    DropDown,
    DropDownItem,
    Labels,
  },
  mixins: [TruncateFormatter],
  props: {
    customer: {
      type: Object,
      required: true,
    },
    canCustomerDelete: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    hasNote() {
      return (this.customer.note != null && this.customer.note.length > 0);
    },
    noteTooltip() {
      return {
        content: this.nl2br(this.customer.note.substring(0, 500)),
        placement: 'top',
        duration: 100,
        delay: [200, 0],
        arrow: true,
      };
    },
    customerUrl() {
      return `/customers/${this.customer.id}`;
    },
    locationCountClass() {
      if (this.customer.locations_count === 0) {
        return 'secondary';
      }
      return '';
    },
  },
  methods: {
    nl2br(text) {
      return (`${text}`).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>' + '$2');
    },
  },
};
</script>

<style>
</style>
