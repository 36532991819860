<template>
  <div class="fx-grid-narrow">
    <div class="fx-col">
      <div class="no-distraction__bubble">
        <h1 class="no-distraction__title">
          Foxtag kostenlos testen!
        </h1>
        <div class="no-distraction__intro">
          Die Registrierung dauert nur wenige Momente, danach können Sie Foxtag in vollem Umfang testen.
          <div style="text-align: center; font-weight: bold; padding-top: 1rem;">
            <i class="fas fa-check" style="color: green;" />
            30 Tage testen
            <i class="fas fa-check" style="color: green; margin-left: 1rem;" />
            Test endet automatisch
            <i class="fas fa-check" style="color: green; margin-left: 1rem;" />
            Keine Kostenfalle
          </div>
        </div>
        <div class="form-container__no-distraction">
          <hr class="no-distraction__divider">

            <div v-if="invitationCodeRequired" class="fx-form-row">
              <div class="fx-form-label">
                <label class="inline mandatory">Einladungscode</label>
              </div>
              <div class="fx-form-content">
                <input id="reg_password" v-model="regPassword" type="text" name="reg_password">
              </div>
            </div>

            <div class="fx-form-row">
              <div class="fx-form-label">
                <label class="inline mandatory">Leistungsumfang</label>
              </div>
              <div class="fx-form-content" style="padding-top: .3rem;">
                <div class="float-left">
                  <input v-model="localLicenseType" type="radio" name="license-type" value="basic">
                </div>
                <div style="margin-left: 30px;">
                  Foxtag
                  <span class="license-type-label license-type-basic" style="font-size: 1rem;">basic</span>
                  <div style="font-size: .8rem; margin-bottom: .8rem;">
                    Ihr Einstieg ins intelligente Wartungs- und Service-Management.
                  </div>
                </div>
                  <div class="float-left">
                    <input v-model="localLicenseType" type="radio" name="license-type" value="pro">
                  </div>
                  <div style="margin-left: 30px;">
                    Foxtag
                    <span class="license-type-label license-type-pro" style="font-size: 1rem;">pro</span>
                    <div style="font-size: .8rem; margin-bottom: .8rem;">
                      Die Komplettlösung für Wartungs-, Service- und <b>Störungsmanagement</b>. Enthält alle Funktionen aus Basic.
                    </div>
                  </div>
                </div>
              </div>

              <hr>

              <div v-if="Object.keys(serverErrors).length" class="fx-form-row">
                <div class="fx-form-content">
                  <div class="no-distraction__error" style="margin: 0">
                    <strong>Bitte überprüfen Sie Ihre Angaben:</strong>
                    <div v-for="key in Object.keys(serverErrors)" :key="key">
                      {{ $t(`activerecord.attributes.account.${key}`) }} {{ serverErrors[key].join(", ") }}
                    </div>
                  </div>
                </div>
              </div>

            <div class="fx-form-row">
              <div class="fx-form-label">
                <label class="inline mandatory">Firma</label>
              </div>
              <div class="fx-form-content">
                <input id="organization-name" v-model="organizationName" type="text" name="name" placeholder="Firma GmbH">
                <div v-if="serverErrors['organization_name']" class="form-field-error">
                  <strong>{{ serverErrors['organization_name'].join(", ") }}</strong>
                </div>
              </div>
            </div>
            <div class="fx-form-row">
              <div class="fx-form-label">
                <label class="inline mandatory">Name</label>
              </div>
              <div class="fx-form-content">
                <input id="name" v-model="name" type="text" name="name" placeholder="Vor-/Nachname">
                <div v-if="serverErrors['name']" class="form-field-error">
                  <strong>{{ serverErrors['name'].join(", ") }}</strong>
                </div>
              </div>
            </div>
            <div class="fx-form-row">
              <div class="fx-form-label">
                <label class="inline mandatory">E-Mail</label>
              </div>
              <div class="fx-form-content">
                <input id="account-email" v-model="email" type="email" name="email" placeholder="name@email.com">
                <div v-if="serverErrors['email']" class="form-field-error">
                  <strong>{{ serverErrors['email'].join(", ") }}</strong>
                </div>
              </div>
            </div>

            <PasswordInput @changed="updatePasswords" />

            <div class="fx-form-row">
              <div class="fx-form-label">
                <label class="inline mandatory" for="tos">AGB</label>
              </div>
              <div class="fx-form-content" style="font-size: .9rem;">
                <input id="tos" v-model="tosAccepted" type="checkbox" style="float: left; margin-top: 4px;">
                <div style="margin-left: 20px;">
                  Ich akzeptiere die
                  <a :href="currentAGBLink" target="_blank">AGB</a>, <a :href="currentTosLink" target="_blank">Leistungsbeschreibung</a> und
                  <a :href="currentPrivacyLink" target="_blank">Datenschutzerklärung</a> der Foxtag GmbH.
                </div>
              </div>
            </div>

            <TransactionButton
              id="btn-submit"
              v-model="saving"
              class="fx-btn-primary"
              title="Kostenlos testen"
              :disabled="submitDisabled"
              @click="createNewAccount()"
            />
           <!-- <button  class="fx-btn-primary" @click="createNewAccount">Kostenlos testen</button>-->
        </div>
      </div>
      <div class="no-distraction__footer">
        Sie sind bereits registriert?
        <p>
          <a href="/accounts/confirmation/new">Bestätigungs-E-Mail erneut anfordern</a>
          <span style="margin: 0 0.6rem; color: #aaa;">&#9679;</span>
          <a href="/accounts/sign_in">Hier geht es zum Login</a>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { ref, computed } from 'vue';

import PasswordInput from 'apps/application/password/password.vue';
import TransactionButton from 'components/transaction_button.vue';

export default {
  name: 'RegistrationIndex',
  components: {
    PasswordInput,
    TransactionButton,
  },
  props: {
    invitationCodeRequired: {
      type: Boolean,
      default: false,
    },
    licenseType: {
      type: String,
      default: 'pro',
    },
    agbVersion: {
      type: String,
      required: true,
    },
    privacyVersion: {
      type: String,
      required: true,
    },
    tosVersion: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const localLicenseType = ref(props.licenseType);
    const serverErrors = ref({});
    const organizationName = ref('');
    const name = ref('');
    const email = ref('');
    const localPassword = ref('');
    const localPasswordConfirmation = ref('');
    const localPasswordValid = ref(false);
    const tosAccepted = ref(false);
    const regPassword = ref('');
    const saving = ref(false);

    const updatePasswords = (password, passwordConfirmation, passwordValid) => {
      localPassword.value = password;
      localPasswordConfirmation.value = passwordConfirmation;
      localPasswordValid.value = passwordValid;
    };

    async function createHash(string) {
      const encoder = new TextEncoder();
      const byteData = encoder.encode(string);
      const hashBuffer = await crypto.subtle.digest('SHA-256', byteData);
      const hashByteArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashByteArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
      return hashHex;
    }

    const createNewAccount = () => {
      serverErrors.value = {};
      saving.value = true;

      axios.post('/accounts.json', {
        reg_password: regPassword.value,
        account: {
          organization_name: organizationName.value,
          name: name.value,
          email: email.value,
          password: localPassword.value,
          password_confirmation: localPasswordConfirmation.value,
          license_type: localLicenseType.value,
        },
      }).then(() => {
        // in Promise due async behavior of crypto
        createHash(email.value).then((gtmTransactionId) => {
          window.location.href = `/accounts/please_confirm?gtm_transaction_id=${gtmTransactionId}`;
        });
      }).catch((error) => {
        saving.value = false;
        serverErrors.value = error.response.data.errors;
      });
    };

    const submitDisabled = computed(() => !localPasswordValid.value || !tosAccepted.value || !organizationName.value || !name.value || !email.value);

    const currentAGBLink = computed(() => `/tos/Foxtag-AGB_${props.agbVersion}.pdf`);
    const currentTosLink = computed(() => `/tos/Foxtag-Leistungsbeschreibung_${props.tosVersion}.pdf`);
    const currentPrivacyLink = computed(() => `/tos/Foxtag-Datenschutzerklärung_${props.privacyVersion}.pdf`);

    return {
      localLicenseType,
      createNewAccount,
      serverErrors,
      name,
      email,
      organizationName,
      updatePasswords,
      localPasswordValid,
      submitDisabled,
      tosAccepted,
      regPassword,
      saving,
      currentAGBLink,
      currentTosLink,
      currentPrivacyLink,
    };
  },
  mounted() {
  },
};
</script>
