<template>
  <div class="callout callout--pro">
    <slot>Diese Funktion benötigt den Leistungsumfang <strong>Foxtag Pro</strong></slot>
    <br>
    <br>
    <help-article article-id="pro_overview">
      Mehr über die Funktionen von Foxtag Pro erfahren
    </help-article>
  </div>
</template>

<script>
export default {
  name: 'ProFeatureCallout',
};
</script>
