<template>
  <div>
    <form @submit.prevent="enqueue">
      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline">Filter</label>
        </div>
        <div class="fx-form-text-content" style="font-style: italic;">
          Es werden alle Aufträge exportiert, die den aktuellen Filtereinstellungen entsprechen.
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory">Format</label>
        </div>
        <div class="fx-form-content">
          <input
              id="file_type_xlsx"
              v-model="fileType"
              name="file_type"
              type="radio"
              value="xlsx"
            >
            <label for="file_type_xlsx">Excel</label>

            <input
              id="file_type_csv"
              v-model="fileType"
              name="file_type"
              type="radio"
              value="csv"
            >
            <label for="file_type_csv">CSV</label>
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-content">
          <input
            type="submit"
            style="margin-bottom: 7px;"
            class="fx-btn-primary"
            value="Export starten"
          >
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';

export default {
  name: 'EnqueueFilteredJobs',
  props: {
    config: {
      type: Object,
      required: true,
    },
    exporttype: {
      type: String,
      required: true,
    },
  },
  emits: ['enqueued'],
  data() {
    return {
      fileType: 'xlsx',
    };
  },
  computed: {
  },
  methods: {
    enqueue() {
      axios.post('/exports/enqueue', {
        exportable_id: this.exportableId,
        exporttype: this.exporttype,
        filter: this.config,
        file_type: this.fileType,
      }).then((response) => {
        this.$emit('enqueued', response.data.export_id);
      }).catch(() => {
        Flash.error('Export konnte nicht erzeugt werden');
      });
    },
  },
};
</script>
