<template>
  <section class="fx-page-content">
    <div style="background-color: #DEE5E9;">
      <div style="display: inline-block; padding: .3rem;">
        <DBBasedFilter
          v-model="filter.installationTypeId"
          :default-label="$t('comp.filter.installation_types.default-label')"
          i18n-base-key="installation_types"
          load-options-url="/installation_types/for_select"
          icon-id="installation_type"
          @changed="onFilterInstallationType"
        />

        <FilterDueDatesTimerange
          v-model="filter.dueTimerange"
          i18n-base-key="due-date-range"
          icon="fa-circle"
          @changed="onFilterDueRange"
        />

        <FilterDueDatesTimerange
          v-model="filter.replacementTimerange"
          icon="fa-sync-alt"
          i18n-base-key="replacement-date-range"
          @changed="onFilterReplacementRange"
        />

        <DBBasedFilter
          v-model="filter.technicianId"
          :default-label="$t('comp.filter.users.default-label')"
          i18n-base-key="users"
          load-options-url="/users/for_select"
          icon-id="user"
          @changed="onFilterTechnician"
        />

        <FilterFulltext
          v-model="filter.query"
          icon="filter"
          :placeholder="$t('comp.filter.site-address.title')"
          width="250px"
          @changed="onFilterQuery"
        />

        <FilterFulltext
          v-model="filter.zip"
          icon="mapmarker"
          :placeholder="$t('comp.filter.zip.title')"
          width="95px"
          @changed="onFilterZip"
        />

        <DBBasedFilter
          v-model="filter.labelId"
          i18n-base-key="labels"
          :default-label="$t('comp.filter.labels.default-label')"
          :allow-multi-select="true"
          load-options-url="/labels/for_select?label_type=installation"
          icon-id="label"
          @changed="onFilterLabel"
        />
      </div>
    </div>

    <div class="fx-grid" style="margin-top: 1rem;">
      <div class="fx-col">
        <LocationsList
          v-model="filter"
          :title="$t('nav.main.installations')"
          :can-edit="canEdit"
          :filter-info="filterInfo"
          :has-due-replacement-export="hasDueReplacementExport"
          :filter-applied="filterApplied"
          pagination-filter-storage-key="locations"
          @reset-search="resetSearch"
          @filter-passive="setFilterPassive"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { useCurrentUserStore } from 'stores/current_user';
import { ref, computed } from 'vue';

import FilterInfo from 'mixins/filter_info';

import DBBasedFilter from 'components/filter/db_based_filter.vue';
import FilterDueDatesTimerange from 'components/filter/filter_due_dates_timerange.vue';
import FilterFulltext from 'components/filter/filter_fulltext.vue';

import LocationsList from './locations_list.vue';

export default {
  components: {

    LocationsList,
    DBBasedFilter,
    FilterDueDatesTimerange,
    FilterFulltext,
  },
  mixins: [FilterInfo],
  props: {
    canEdit: {
      type: Boolean,
      default: true,
    },
    hasIncidentsPro: {
      type: Boolean,
      default: true,
    },
    hasDueReplacementExport: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const userStore = useCurrentUserStore();
    const filter = ref({ ...userStore.filter('locations') });
    const defaultFilter = {
      labelId: null,
      installationTypeId: null,
      query: null,
      zip: null,
      dueTimerange: 'none',
      replacementTimerange: 'none',
      technicianId: null,
      passive: null,
    };

    const resetSearch = () => {
      // --- Note: watching this filter may not work as expected, if you
      // --- change the filter object directly, instead of changing its properties.
      filter.value = { ...defaultFilter };
    };

    const setFilterPassive = () => {
      resetSearch();
      filter.value.passive = true;
    };

    const onFilterInstallationType = (installationTypeId) => {
      filter.value.installationTypeId = installationTypeId;
    };

    const onFilterTechnician = (technicianId) => {
      filter.value.technicianId = technicianId;
    };

    const onFilterLabel = (labelId) => {
      filter.value.labelId = labelId;
    };

    const onFilterQuery = (query) => {
      filter.value.query = query;
    };

    const onFilterZip = (zip) => {
      filter.value.zip = zip;
    };

    const onFilterReplacementRange = (replacementTimerange) => {
      filter.value.replacementTimerange = replacementTimerange;
    };

    const onFilterDueRange = (dueTimerange) => {
      filter.value.dueTimerange = dueTimerange;
    };

    const filterApplied = computed(() => {
      let filtered = false;

      Object.keys(defaultFilter).forEach((key) => {
        if (filter.value[key] !== defaultFilter[key]) {
          filtered = true;
        }
      });

      return filtered;
    });

    return {
      filter,
      filterApplied,
      onFilterDueRange,
      onFilterInstallationType,
      onFilterLabel,
      onFilterQuery,
      onFilterReplacementRange,
      onFilterTechnician,
      onFilterZip,
      resetSearch,
      setFilterPassive,
      currentUser: useCurrentUserStore().currentUser,
    };
  },
  mounted() {
  },
};
</script>
