<template>
  <div class="fx-modal-content-medium">
    <h2 class="fx">
      Auftragsnummer bearbeiten
    </h2>
    <div style="min-height: 120px;">
      <ServerErrors v-model="serverErrors" title="Änderung konnte nicht gespeichert werden" margin-bottom="0" />
        <label for="job_number">Auftragsnummer</label>
        <input
          id="job-number"
          ref="numberInput"
          v-model="localJob.number"
          type="text"
          placeholder="Auftragsnummer"
          name="job[number]"
          class="fx-focus"
        >
    </div>
    <hr class="fx">
    <div style="float:right;">
      <div class="fx-btn-group">
        <button
          class="fx-btn-primary float-right"
          style="margin-left: 0.5rem;"
          @click.prevent="updateJob()"
        >
          Speichern
        </button>

        <button class="fx-btn-secondary float-right" @click.prevent="cancel()">
          Abbrechen
        </button>
      </div>
      <div class="clearfix" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';
import { ref, onMounted } from 'vue';

import ServerErrors from 'components/partials/server_errors.vue';

export default {
  name: 'EditJobNumberModal',
  components: {
    ServerErrors,
  },
  props: {
    job: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'updated'],
  setup(props, { emit }) {
    const numberInput = ref(null);
    const localJob = ref(JSON.parse(JSON.stringify(props.job)));
    const serverErrors = ref([]);

    const cancel = () => {
      emit('close');
    };

    const updateJob = () => {
      serverErrors.value = [];
      axios.put(`/jobs/${localJob.value.id}`, {
        job: {
          number: localJob.value.number,
        },
      }).then((response) => {
        emit('updated', response.data.job);
        emit('close');
      }).catch((error) => {
        if (error.response.status === 400) {
          serverErrors.value = error.response.data.jobs;
        } else {
          Flash.error('Auftrag konnte nicht gespeichert werden');
        }
      });
    };

    onMounted(() => {
      numberInput.value.focus();
    });

    return {
      numberInput,
      cancel,
      localJob,
      serverErrors,
      updateJob,
    };
  },
};
</script>
