<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <h2 class="fx">
      Kunden hinzufügen
    </h2>
    <hr>
    <ServerErrors v-model="serverErrors" title="Kunde konnte nicht gespeichert werden" margin-bottom="1rem" />
    <CustomerForm
      v-model="localCustomer"
      :can-edit="true"
      :with-coordinates="false"
      :with-add-location="true"
      @submit="submit"
    />
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';

import ServerErrors from 'components/partials/server_errors.vue';
import CustomerForm from '../form.vue';

export default {
  name: 'NewCustomerModal',
  components: {
    CustomerForm,
    ServerErrors,
  },
  emits: ['close', 'created'],
  data() {
    return {
      localCustomer: {
        name: '',
        number: '',
        add_location: true,
        address: {
          line1: '',
          line2: '',
          zip: '',
          city: '',
          country: 'DE',
          custom_coordinates: false,
          lat: null,
          lng: null,
        },
      },
      incidentTypeId: null,
      incidentId: null,
      severity: null,
      reportedBy: null,
      incidentTypeOptions: [],
      serverErrors: [],
    };
  },
  methods: {
    submit() {
      const that = this;
      this.savingCustomer = true;
      const customCoordinates = this.localCustomer.address.custom_coordinates;
      axios.post('/customers.json', {
        add_location: this.localCustomer.add_location,
        customer: {
          number: this.localCustomer.number,
          name: this.localCustomer.name,
          link: this.localCustomer.link,
          address_attributes: {
            line1: this.localCustomer.address.line1,
            line2: this.localCustomer.address.line2,
            zip: this.localCustomer.address.zip,
            city: this.localCustomer.address.city,
            country: this.localCustomer.address.country,
            custom_coordinates: customCoordinates,
            lat: (customCoordinates ? this.localCustomer.address.lat : null),
            lng: (customCoordinates ? this.localCustomer.address.lng : null),
          },
        },
      }).then((response) => {
        this.$emit('created', response.data.customer);
        this.$emit('close');
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.customers;
        } else {
          Flash.error('Fehler beim Speichern');
        }
        that.savingCustomer = false;
      });
    },
  },
};
</script>

<style></style>
