<template>
  <div>
    <div class="no-distraction__intro">
      Bitte wählen Sie ein Passwort.
    </div>

    <ServerErrors v-model="serverErrors" title="Bitte überprüfen Sie ihre Eingaben" margin-bottom="1rem" />

    <div v-if="showUnauthorizedInfo" class="callout callout--warning mb-4">
      <strong>Diese Einladung ist ungültig oder wurde bereits angenommen.</strong> Bitte wenden Sie sich an den Absender der
      Einladung.
    </div>

    <div class="form-container__no-distraction">
      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory">E-Mail</label>
        </div>
        <div class="fx-form-content">
          <input id="email" v-model="account.email" disabled="true" type="text">
        </div>
      </div>

      <div v-if="showEditOrganizationName" class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory" for="organization-name">Ihre Firma</label>
        </div>
        <div class="fx-form-content">
          <input
            id="organization-name"
            v-model="account.organization_name"
            name="organization_name"
            type="text"
          >
          <div class="form-field-hint">
            korrigieren Sie bei Bedarf den Firmennamen
          </div>
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory" for="display-name">Ihr Name</label>
        </div>
        <div class="fx-form-content">
          <input
            id="display-name"
            v-model="account.name"
            name="name"
            type="text"
          >
          <div class="form-field-hint">
            korrigieren Sie bei Bedarf Ihren Namen
          </div>
        </div>
      </div>
      <div id="password-input">
        <PasswordInput @changed="updatePasswords" />
      </div>

      <div class="fx-form-row">
        <div class="fx-form-content">
          <button
            id="submit-login"
            class="fx-btn-primary"
            :disabled="!passwordValid"
            @click="createNewAccount()"
          >
            Einladung annehmen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { ref } from 'vue';
import { useCurrentUserStore } from 'stores/current_user';
import ServerErrors from 'components/partials/server_errors.vue';

import PasswordInput from 'apps/application/password/password.vue';

export default {
  name: 'CreateNewAccount',
  components: {
    PasswordInput,
    ServerErrors,
  },

  props: {
    invite: {
      type: Object,
      required: true,
    },
    successPage: {
      type: String,
      required: true,
    },
    showEditOrganizationName: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const account = ref({});
    const serverErrors = ref([]);
    const passwordValid = ref(false);
    const showUnauthorizedInfo = ref(false);

    const createNewAccount = () => {
      axios.put(`/invites/${props.invite.token}/create_new_account.json`, {
        organization_name: account.value.organization_name,
        name: account.value.name,
        account: {
          password: account.value.password,
          password_confirmation: account.value.passwordConfirmation,
        },
      }).then(() => {
        window.location.href = props.successPage;
      }).catch((error) => {
        if (error.response.status === 400) {
          serverErrors.value = error.response.data.accounts;
        } else {
          showUnauthorizedInfo.value = true;
        }
      });
    };

    return {
      currentUser: useCurrentUserStore().currentUser,
      account,
      createNewAccount,
      serverErrors,
      passwordValid,
      showUnauthorizedInfo,
    };
  },
  mounted() {
    this.account.name = this.invite.recipient_name;
    this.account.email = this.invite.recipient_email;
    this.account.organization_name = this.invite.account_organization_name;
  },
  methods: {
    updatePasswords(password, passwordConfirmation, passwordValid) {
      this.account.password = password;
      this.account.passwordConfirmation = passwordConfirmation;
      this.passwordValid = passwordValid;
    },
  },
};
</script>
