<template>
  <tr>
    <td :class="thingNumberClasses">
      {{ thingNumber(thing, groupSeparator) }}
    </td>
    <td>
      <div class="fx_thing_type_with_icon">
        {{ thing.thing_type.name }}
        <div class="secondary">
          {{ thingMake(thing) }}
        </div>
      </div>
    </td>
    <td>
      <ThingDetails :thing="thing" :tag-label="tagLabel" />
    </td>
    <td>
      <span
        v-for="(feature, index) in thing.assigned_features"
        :key="index"
      ><span
        class="fx-label"
        style="background-color: #2C556E; color: #fff;"
      >{{ feature.symbol }}</span>&shy;
      </span>
    </td>
    <td v-if="showDueDates">
      <NextCheckInfo v-if="hasCheckInterval(thing)" :next-date="thing.next_check_at" :next-status="thing.next_check_status" />
      <NextCheckInfo
        v-if="hasReplacementInterval(thing)"
        :is-replacement="true"
        :next-date="thing.next_replacement_at"
        :next-status="thing.next_replacement_status"
      />
    </td>
    <td>
      <template v-if="jobThingsScope === 'custom_things'">
        <div class="yesnoswitch" style="float: none;">
          <input
            :id="thing.id"
            v-model="uiPlanned"
            class="yesnoswitch-checkbox"
            type="checkbox"
            @change="togglePlanThing()"
          >
          <label class="yesnoswitch-label" :for="thing.id">
            <span class="yesnoswitch-inner" />
            <span class="yesnoswitch-switch" />
          </label>
        </div>
      </template>

      <template v-else>
        <div v-if="isPlanned" style="font-weight: bold; color: #256FC5; padding-top: 4px; margin-right: 1rem;">
          JA
        </div>
        <div v-if="!isPlanned" style="font-weight: bold; color: #aaa; padding-top: 4px; margin-right: 1rem;">
          NEIN
        </div>
      </template>
    </td>
  </tr>
</template>

<script>
/* eslint-disable no-undef */
import axios from 'axios';
import NextCheckInfo from 'components/partials/next_check_info.vue';
import ThingDetails from 'components/partials/thing_details.vue';

import ThingPresenter from 'mixins/thing_presenter';

export default {
  name: 'SingleThingPlanning',
  components: {
    NextCheckInfo,
    ThingDetails,
  },
  mixins: [ThingPresenter],
  props: {
    jobId: {
      type: String,
      required: true,
    },
    installationId: {
      type: String,
      required: true,
    },
    thing: {
      type: Object,
      required: true,
    },
    groupSeparator: {
      type: String,
      default: '/',
    },
    tagLabel: {
      type: String,
      default: 'Foxtag-ID',
    },
    showDueDates: {
      type: Boolean,
      default: true,
    },
    showPortalAddIncidentButtons: {
      type: Boolean,
      default: false,
    },
    jobThingsScope: {
      type: String,
      default: 'all_things',
    },
    planned: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      uiPlanned: true,
      confirmedPlanned: true,
    };
  },
  computed: {
    isPlanned() {
      if (this.jobThingsScope === 'due_things') {
        return this.uiPlanned;
      }
      return true; // all_things
    },
    thingUrl() {
      return `/things/${this.thing.id}`;
    },
    thingNumberClasses() {
      if (this.isSubThing(this.thing)) {
        return 'text-right';
      }
      return '';
    },
  },
  mounted() {
    this.uiPlanned = this.planned;
    this.confirmedPlanned = this.planned;
  },
  methods: {
    togglePlanThing() {
      const that = this;

      axios.put(`/jobs/${this.jobId}/toggle_plan_thing`, {
        thing_id: this.thing.id,
      }).then((response) => {
        that.confirmedPlanned = !that.confirmedPlanned;
        this.$emit('changedPlannedCount', response.data);
      }).catch(() => {
        Flash.error('Fehler beim Speichern');
        that.uiPlanned = false;
      });
    },
  },
};
</script>
