<template>
  <div>
    <div class="repco_preview">
      <template v-if="showForm">
        <div style="background-color: #eee; min-height: 3rem; border-radius: 8px; padding: 1rem;">
          <h3 class="fx">
            {{ name }}
          </h3>

          <div v-if="expReport" class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline" for="customer_name">Überschrift</label>
            </div>
            <div class="fx-form-content">
              <input
                id="customer_name"
                v-model="localSection.title"
                name="customer_name"
                type="text"
              >
              <div class="form-field-hint">
                Optionale Überschrift über dem Text
              </div>
            </div>
          </div>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline" for="customer_name">Text</label>
            </div>
            <div class="fx-form-content" style="width: 35rem;">
              <textarea
                v-model="localSection.text"
                rows="5"
                maxlength="2000"
                type="text"
              />
            </div>
          </div>

          <button class="fx-btn-primary" @click="showForm=false">
            Zurück zur Vorschau
          </button>
        </div>
      </template>
      <template v-if="!showForm">
        <div :class="previewEngineClass">
          <div v-if="expReport && localSection.title" class="repco-h2" :style="headlineStyle">
            {{ localSection.title }}
          </div>

          <div class="infotext">
            {{ localSection.text }}
          </div>
        </div>
      </template>
      <div>&nbsp;</div>
    </div>

    <Controls
      :index="index"
      :list-length="listLength"
      :name="name"
      :readonly="readonly"
      @settings="showForm = !showForm"
      @add="$emit('add')"
      @delete="$emit('delete')"
      @move-up="$emit('moveUp')"
      @move-down="$emit('moveDown')"
    />
  </div>
</template>
<script>
import Controls from './section_controls.vue';

export default {
  name: 'SectionInfotext',
  components: {
    Controls,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      default: 'Textblock',
    },
    index: {
      type: Number,
      required: true,
    },
    listLength: {
      type: Number,
      required: true,
    },
    colors: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    layoutEngine: {
      type: String,
      default: 'foxtag_report',
    },
  },
  data() {
    return {
      showForm: false,
      localSection: {},
    };
  },
  computed: {
    previewEngineClass() {
      return `preview-${this.layoutEngine}`;
    },
    expReport() {
      return (this.layoutEngine == 'exp_report');
    },
    thStyle() {
      return `color: #${this.colors.color_col_headline_text}; background-color: #${this.colors.color_lines}; border-color: #${this.colors.color_lines}`;
    },
    tdStyle() {
      return `color: #${this.colors.color_text}; border-color: #${this.colors.color_lines}`;
    },
    headlineStyle() {
      return `color: #${this.colors.color_headlines};`;
    },
  },
  mounted() {
    this.localSection = this.section;
  },
};
</script>
