<template>
  <div>
    <slot name="topbar" />
    <section class="all-content" style="display: flex; flex-flow: column; height: 100%; padding-top: 59px;">
      <div class="fx-sidebar-layout-wrapper">
        <div class="fx-sidebar-layout-infobar">
          <h2 class="fx">
            <i :class="iconClass()" /> {{ title }}
          </h2>
        </div>

        <slot name="sidebar" />

        <div class="fx-sidebar-layout-content">
          <slot name="content" />
        </div>
      </div>
    </section>

    <ModalsContainer />
    <BackToTop />
  </div>
</template>

<script>
import foxtagIcons from 'initializers/icons';
import { ModalsContainer } from 'vue-final-modal';
import { useCurrentUserStore } from 'stores/current_user';
import Flash from 'flash/index';
import BackToTop from 'components/back_to_top.vue';

export default {
  components: {
    ModalsContainer,
    BackToTop,
  },
  props: {
    title: {
      type: String,
      default: 'Administration',
    },
    iconId: {
      type: String,
      default: 'settings',
    },
    currentUser: {
      type: Object,
      required: true,
    },
    // rails flash messages
    flash: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const store = useCurrentUserStore();
    store.change(props.currentUser);
  },
  created() {
    this.$root.$i18n.locale = document.documentElement.lang;
  },
  mounted() {
    setTimeout(() => {
      this.showRailsFlashMessages();
    }, 300);
  },
  methods: {
    iconClass() {
      return `${foxtagIcons[this.iconId]}`;
    },
    showRailsFlashMessages() {
      if (this.flash == null) { return; }

      for (let i = 0; i < this.flash.length; i += 1) {
        if (this.flash[i][0] === 'alert') {
          Flash.error(this.flash[i][1]);
        } else {
          Flash.info(this.flash[i][1]);
        }
      }
    },
  },
};
</script>
