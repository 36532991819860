<template>
  <div>
    <div style="display: flex; justify-content: space-between; margin-top: 0rem;">
      <h1 class="fx" style="flex: 1 1 0%;">
&nbsp;
      </h1>
      <div class="fx-btn-group">
        <button
          v-if="showLinks"
          class="fx-btn-ico-primary"
          :disabled="!canEdit"
          @click="newIncident()"
        >
          <i class="fas fa-plus" /> Störung erfassen
        </button>

        <button
          v-tippy="{ placement: 'top', duration: 100, arrow: true }"
          class="fx-btn-secondary"
          title="Liste neu laden"
          @click="search()"
        >
          <i class="fas fa-sync-alt" />
        </button>
      </div>
    </div>
    <div class="fx-card">
      <div style="background-color: #f2f2f2; padding: .5rem;">
        <FilterStaticOptions
          v-model="filter.status"
          i18n-base-key="incident_status"
          icon-id="incident_status"
          :init-options="statusOptions"
          @changed="onFilterStatus"
        />

        <FilterStaticOptions
          v-model="filter.severity"
          i18n-base-key="incident_severity"
          icon-id="incident_severity"
          :init-options="severityOptions"
          @changed="onFilterSeverity"
        />

        <FilterStaticOptions
          v-model="filter.assignedJob"
          i18n-base-key="assigned_job"
          icon-id="job"
          :init-options="assignedJobOptions"
          @changed="onFilterAssignedJob"
        />

        <DBBasedFilter
          v-model="filter.labelId"
          i18n-base-key="labels"
          :default-label="$t('comp.filter.labels.default-label')"
          :allow-multi-select="false"
          :load-options-url="labelsSelectUrl"
          icon-id="label"
          @changed="onFilterLabel"
        />

        <a id="reset-incidents" style="font-size: .8rem;" @click.prevent="resetSearch()"> zurücksetzen</a>
      </div>

      <ListItem
        v-for="incident in localIncidents"
        :key="incident.id"
        :incident="incident"
        :show-context-links="showLinks"
        :show-quickview="showQuickview"
        :link-to-details-page="true"
        :incident-details-base-url="incidentDetailsUrl"
        :job-details-base-url="jobDetailsUrl"
        :show-thing="true"
        :show-customer-location-installation="false"
        @quickview="incidentQuickView"
      />
      <EmptyState v-if="localIncidents.length === 0 && loaded" :title="noResultsTitle" :hint="noResultsHint" :no-border="true">
        <button v-if="filterApplied" class="fx-btn-secondary" @click="resetSearch()">
          <i
            class="fas fa-times-circle"
          />&nbsp;&nbsp;Filter
          zurücksetzen
        </button>
      </EmptyState>
    </div>
    <Pagination v-model="pagination" i18n-key="activerecord.models.incident" @navigate="loadPage" />
  </div>
</template>

<script>

import axios from 'axios';
import Flash from 'flash/index';
import { ref, computed } from 'vue';
import { incidentQuickView } from 'helpers/quickviews';
import openNewIncidentModal from 'helpers/open_new_incident_modal';

import EmptyState from 'components/empty_state.vue';
import DBBasedFilter from 'components/filter/db_based_filter.vue';
import FilterStaticOptions from 'components/filter/filter_static_options.vue';
import ListItem from 'apps/incidents/index/list_item.vue';
import Pagination from 'components/pagination.vue';

export default {
  name: 'IncidentList',
  components: {
    ListItem,
    DBBasedFilter,
    FilterStaticOptions,
    Pagination,
    EmptyState,
  },
  props: {
    installationId: {
      type: String,
      required: true,
    },
    showLinks: {
      type: Boolean,
      default: true,
    },
    showQuickview: {
      type: Boolean,
      default: false,
    },
    incidentsUrl: {
      type: String,
      default: '/incidents/filtered',
    },
    incidentDetailsUrl: {
      type: String,
      default: '/incidents',
    },
    jobDetailsUrl: {
      type: String,
      default: '/jobs',
    },
    labelsSelectUrl: {
      type: String,
      default: '/labels/for_select?label_type=incident',
    },
    noResultsTitle: {
      type: String,
      default: 'Keine Störungen gefunden',
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    statusOptions: {
      type: Array,
      default: () => [
        { id: 'reported_opened', color: null },
        { id: 'reported', color: '#FF8F00' },
        { id: 'opened', color: '#F44336' },
        { id: 'closed', color: 'green' },
      ],
    },
    severityOptions: {
      type: Array,
      default: () => [
        { id: 'low' },
        { id: 'normal' },
        { id: 'high' },
        { id: 'notice' },
      ],
    },
    assignedJobOptions: {
      type: Array,
      default: () => [
        { id: '1' },
        { id: '0' },
      ],
    },
  },
  emits: ['changed'],
  setup(props, { emit }) {
    const localIncidents = ref([]);
    const loaded = ref(false);
    const pagination = ref({
      page: 1,
      pageCount: 1,
      totalCount: 0,
      limit: 25,
    });

    const defaultFilter = {
      timerange: null,
      severity: null,
      assignedJob: null,
      status: 'reported_opened',
      customerId: null,
      installationId: null,
      labelId: null,
    };

    const filter = ref({ ...defaultFilter });

    const load = () => {
      loaded.value = false;
      axios.get(props.incidentsUrl, {
        params: {
          severity: filter.value.severity,
          status: filter.value.status,
          assigned_job: filter.value.assignedJob,
          installation_id: props.installationId,
          // location_id: null,
          // customer_id: null,
          label_id: filter.value.labelId,
          timerange: filter.value.timerange,
          with_thing_details: true,
          page: pagination.value.page,
          limit: pagination.value.limit,
        },
      }).then((response) => {
        loaded.value = true;
        localIncidents.value = response.data.incidents;
        pagination.value.page = response.data.meta.current_page;
        pagination.value.pageCount = response.data.meta.total_pages;
        pagination.value.totalCount = response.data.meta.total_count;
      }).catch((err) => {
        if (err.message !== 'Request aborted') {
          Flash.error(err.message);
        }
      });
    };

    const loadPage = (changedPagination) => {
      pagination.value = { ...changedPagination };
      load();
    };

    const search = () => {
      localIncidents.value = [];
      pagination.value.page = 1;
      load();
    };

    const onFilterTimeRange = (timerange) => {
      filter.value.timerange = timerange;
      search();
    };

    const onFilterAssignedJob = (assignedJob) => {
      filter.value.assignedJob = assignedJob;
      search();
    };

    const onFilterSeverity = (severity) => {
      filter.value.severity = severity;
      search();
    };

    const onFilterStatus = (status) => {
      filter.value.status = status;
      search();
    };

    const onFilterLabel = (labelId) => {
      filter.value.labelId = labelId;
      search();
    };

    const resetSearch = () => {
      filter.value = { ...defaultFilter };
      search();
    };

    const newIncident = () => {
      openNewIncidentModal(props.installationId).then(() => {
        search();
        emit('changed');
      });
    };

    const filterApplied = computed(() => {
      let filtered = false;

      Object.keys(defaultFilter).forEach((key) => {
        if (filter.value[key] !== defaultFilter[key]) {
          filtered = true;
        }
      });

      return filtered;
    });

    return {
      openNewIncidentModal,
      incidentQuickView,
      localIncidents,
      search,
      pagination,
      filter,
      loadPage,
      loaded,
      onFilterTimeRange,
      onFilterAssignedJob,
      onFilterSeverity,
      onFilterStatus,
      onFilterLabel,
      resetSearch,
      newIncident,
      filterApplied,
    };
  },
  mounted() {
    // this.resetSearch();
  },
  methods: {
    // ??? seems like not required
    patchIncident(incident) {
      const index = _.findIndex(this.localIncidents, { id: incident.id });
      this.localIncidents[index].labels = incident.labels;
    },
  },
};
</script>
