<template>
  <div>
    <div class="fx-grid">
      <div class="fx-col">
        <EmptyState
          v-if="showEmptyState"
          title="Keine In- oder Außerbetriebnahmen"
          hint="Es wurden keine Inbetriebnahmen, Außerbetriebnahmen und kein Austausch dokumentiert"
        />

        <div v-if="localLifecycleEvents.length" class="fx-card">
          <div class="fx-list-row">
            <div class="fx-80px-col">
              <strong>Zeitpunkt</strong>
            </div>
            <div class="fx-150px-col">
              <strong>Aktion</strong>
            </div>
            <div class="fx-150px-col">
              <strong>{{ groupLabel }}</strong>
            </div>
            <div class="fx-100px-col">
              <strong>Nummer</strong>
            </div>
            <div class="fx-220px-col">
              <strong>Typ</strong>
            </div>
            <div class="fx-300px-col">
              <strong>Details</strong>
            </div>
          </div>

          <div v-for="lcEvent in localLifecycleEvents" :key="lcEvent.id" class="fx-list-row">
            <div class="fx-80px-col">
              <strong>
                {{ formatDate(lcEvent.eventdate, 'DD.MM.YYYY') }}</strong>
              {{ formatDate(lcEvent.eventdate, 'HH:mm') + "Uhr" }}
            </div>
            <div class="fx-150px-col">
              <strong>{{ lcEvent.event_label }}</strong>
            </div>
            <div class="fx-150px-col">
              {{ groupNumberAndName(lcEvent.thing) }}
            </div>
            <div class="fx-100px-col">
              <a :href="thingPath(lcEvent.thing.id)">{{ thingNumber(lcEvent.thing, groupSeparator) }}</a>
            </div>
            <div class="fx-550px-col" style="margin-left: 0; margin-top: 0;">
              <div class="fx-list-row" style="border: 0;">
                <div class="fx-220px-col">
                  <div v-if="lcEvent.event_label == 'Austausch'" class="secondary" style="color: #2C556E; font-style: italic;">
                    <i class="fas fa-sign-in" /> Inbetriebnahme
                  </div>
                  {{ lcEvent.thing.thing_type.name }}
                  <div class="secondary">
                    {{ thingMake(lcEvent.thing) }}
                  </div>
                </div>
                <div class="fx-300px-col" style="margin-bottom: 0;">
                  <ThingDetails :thing="lcEvent.thing" />

                  <div v-if="lcEvent.removed_reason" style="margin-top: 1rem; font-weight: bold;">
                    {{ lcEvent.removed_reason }}
                  </div>
                </div>
              </div>

              <div v-if="lcEvent.predecessor_thing" class="fx-list-row" style="padding: 0;">
                <div class="fx-220px-col">
                  <div class="secondary" style="color: #2C556E; font-style: italic;">
                    <i class="fas fa-sign-out fa-flip-horizontal" /> Außerbetriebnahme
                  </div>
                  {{ lcEvent.predecessor_thing.thing_type.name }}
                  <div class="secondary">
                    {{ thingMake(lcEvent.predecessor_thing) }}
                  </div>
                </div>

                <div class="fx-300px-col" style="margin-bottom: 0;">
                  <ThingDetails :thing="lcEvent.predecessor_thing" />

                  <div v-if="lcEvent.predecessor_removed_reason" style="margin-top: 1rem; font-weight: bold;">
                    {{ lcEvent.predecessor_removed_reason }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import axios from 'axios';

import Flash from 'flash/index';
import FoxtagPaths from 'mixins/foxtag_paths';
import ThingPresenter from 'mixins/thing_presenter';
import { DateFormatter } from 'mixins/formatters';

import EmptyState from 'components/empty_state.vue';
import ThingDetails from 'components/partials/thing_details.vue';

export default {
  name: 'TabLifecycle',
  components: {
    EmptyState,
    ThingDetails,
  },
  mixins: [FoxtagPaths, ThingPresenter, DateFormatter],
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    thingLabel: {
      type: String,
      default: 'Komponente',
    },
    groupLabel: {
      type: String,
      default: 'Gruppe',
    },
    groupSeparator: {
      type: String,
      default: '/',
    },
  },
  data() {
    return {
      localLifecycleEvents: [],
      lifecycleEventsLoaded: false,
    };
  },
  computed: {
    showEmptyState() {
      return (this.lifecycleEventsLoaded && !this.localLifecycleEvents.length);
    },
  },
  mounted() {
    this.loadLifecycleEvents();
  },
  methods: {
    loadLifecycleEvents() {
      axios.get(`/jobs/${this.modelValue.id}/lifecycle_events.json`).then((response) => {
        this.localLifecycleEvents = response.data.lifecyle_events;
        this.lifecycleEventsLoaded = true;
      }).catch(() => {
        Flash.error('Fehler beim Laden der Daten');
      });
    },
  },
};
</script>
