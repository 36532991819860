<template>
  <div>
    <ul class="fx-breadcrumps">
      <li><a href="/organizations/settings">Administration</a></li>
      <li><a href="/contacts">Kontakte</a></li>
      <li>{{ localContact.name }}</li>
    </ul>

    <div style="flex: 1; max-width: 650px;">
      <h1 class="fx" style="">
        {{ localContact.name }}
      </h1>

      <div class="fx-card">
        <ContactCard
          :contact="localContact"
          :with-stats="false"
          :link-details="false"
          @open-edit-contact="openEditContactModal"
          @open-confirm-delete="confirmDeleteContact"
        />
      </div>

      <div class="fx-card fx-card-content">
        <h2 class="fx">
          Verknüpfte Kunden <span class="things-count" style="font-size: 1rem;">{{ customers.length }}</span>
        </h2>

        <div v-for="customer in customers" :key="customer.id">
          <i class="fas fa-briefcase fa-fw fx-inline-ico" /> <a :href="`/customers/${customer.id}`" class="no-color">
            <strong>{{ customer.number }}</strong> {{ customer.name }}</a>
        </div>
      </div>

      <div class="fx-card fx-card-content">
        <h2 class="fx">
          Ansprechpartner für Anlagen <span class="things-count" style="font-size: 1rem;">{{
            installations_as_contact.length }}</span>
        </h2>

        <div v-for="installation in installations_as_contact" :key="installation.id">
          <i class="fas fa-sitemap fa-fw fx-inline-ico" /> <a
            :href="`/installations/${installation.id}`"
            class="no-color"
          ><strong>{{ installation.number }}</strong> {{ installation.name }}</a>
        </div>
      </div>

      <div class="fx-card fx-card-content">
        <h2 class="fx">
          Berichtsempfänger für Anlagen <span class="things-count" style="font-size: 1rem;">{{
            installations_as_report_recipient.length }}</span>
        </h2>

        <div v-for="installation in installations_as_report_recipient" :key="installation.id">
          <i class="far fa-file-export fa-fw fx-inline-ico" /> <a
            :href="`/installations/${installation.id}#tab-sharing`"
            class="no-color"
          ><strong>{{ installation.number
          }}</strong> {{ installation.name }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash';
import confirmViaModal from 'helpers/confirm_modal';
import { ref } from 'vue';
import { VueFinalModal, useModal, useModalSlot } from 'vue-final-modal';

import EditContactModal from '../edit_modal.vue';
import ContactCard from '../card.vue';

export default {
  name: 'ContactShow',
  components: {
    ContactCard,
  },
  props: {
    contactId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const localContact = ref({});

    const deleteViaApi = (contact) => {
      axios.delete(`/contacts/${contact.id}.json`).then(() => {
        window.location.href = '/contacts';
        Flash.info('Kontakt gelöscht');
      });
    };

    const confirmDeleteContact = (contact) => {
      confirmViaModal({
        message: 'Der Kontakt wird von allen Anlagen und Kunden entfernt und gelöscht.',
        title: 'Kontakt löschen?',
      }).then(() => {
        deleteViaApi(contact);
      });
    };

    const openEditContactModal = (contact) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: EditContactModal,
            attrs: {
              contact,
              onClose() {
                modalInstance.close();
              },
              onUpdated(xContact) {
                localContact.value = xContact;
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    return {
      localContact,
      confirmDeleteContact,
      openEditContactModal,
    };
  },
  data() {
    return {
      customers: [],
      installations_as_contact: [],
      installations_as_report_recipient: [],
    };
  },
  mounted() {
    this.loadContact();
  },
  methods: {
    updateContact(contact) {
      this.localContact = contact;
    },
    loadContact() {
      axios.get(`/contacts/${this.contactId}.json`).then((response) => {
        this.localContact = response.data.contact;
      }).catch(() => {
        Flash.error('Fehler beim Laden des Kontakts');
      });

      axios.get(`/contacts/${this.contactId}/references.json`).then((response) => {
        this.customers = response.data.customers;
        this.installations_as_contact = response.data.installations_as_contact;
        this.installations_as_report_recipient = response.data.installations_as_report_recipient;
      }).catch(() => {
      });
    },
  },
};
</script>
