<template>
  <div>
    <section class="fx-page-content">
      <div style="background-color: #DEE5E9;">
        <div style="display: inline-block; padding: .3rem;">
          <SelectMegaTimerange id="calendar-filter" v-model="filter.timerange" @changed="onFilterTimerange" />

          <DBBasedFilter
            id="installation-type-filter"
            v-model="filter.installationTypeId"
            :default-label="$t('comp.filter.installation_types.default-label')"
            i18n-base-key="installation_types"
            load-options-url="/installation_types/for_select"
            icon-id="installation_type"
            @changed="onFilterInstallationType"
          />

          <DBBasedFilter
            id="technician-filter"
            v-model="filter.technicianId"
            :default-label="$t('comp.filter.users.default-label')"
            i18n-base-key="users"
            load-options-url="/users/for_select"
            icon-id="user"
            @changed="onFilterTechnician"
          />

          <DBBasedFilter
            id="location-filter"
            v-model="filter.locationId"
            :default-label="$t('comp.filter.locations.default-label')"
            i18n-base-key="locations"
            load-options-url="/select/locations"
            icon-id="location"
            @changed="onFilterLocation"
          />

          <FilterStaticOptions
            id="status-filter"
            v-model="filter.status"
            i18n-base-key="job_status"
            icon-id="job_status"
            :init-options="statusOptions"
            @changed="onFilterStatus"
          />

          <DBBasedFilter
            id="label-filter"
            v-model="filter.labelId"
            i18n-base-key="labels"
            :default-label="$t('comp.filter.labels.default-label')"
            :allow-multi-select="true"
            load-options-url="/labels/for_select?label_type=job"
            icon-id="label"
            @changed="onFilterLabel"
          />
        </div>
      </div>

      <div class="fx-grid">
        <div class="fx-col">
          <table style="width: 100%">
            <tr>
              <td style="vertical-align: bottom;">
                <h1 class="fx" style="margin-bottom:0;">
                  {{ $t('jobs.index.title') }}
                </h1>
              </td>
              <td style="text-align: right;">
                <div class="fx-btn-group">
                  <template v-if="allowNewPolicy">
                    <a id="btn-new-job" class="fx-btn-ico-primary" @click="newJob()"><i class="fas fa-plus" /> {{
                      $t('jobs.index.schedule_job') }}</a>
                  </template>
                  <template v-if="!allowNewPolicy">
                    <button class="fx-btn-primary" disabled="disabled">
                      <i class="fas fa-plus" /> {{
                        $t('jobs.index.schedule_job') }}
                    </button>
                  </template>
                  <span style="font-weight: normal;">
                    <DropDown id="btn-export-dropdown" :text="importExportDropDownLabel" classes="fx-btn-secondary" :disabled="!allowNewPolicy">
                      <DropDownItem v-if="hasJobsImport">
                        <a :href="jobsImportPath()"><i class="far fa-file-import fa-fw" /> Aufträge importieren</a>
                      </DropDownItem>
                      <DropDownItem><a @click="openExportModalJobs"><i class="far fa-file-export fa-fw" /> Aufträge
                        exportieren</a></DropDownItem>
                    </DropDown>
                  </span>
                  <button
                    id="btn-reload-list"
                    v-tippy="{ placement: 'top', duration: 100, arrow: true }"
                    class="fx-btn-secondary"
                    title="Liste neu laden"
                    @click="search()"
                  >
                    <i class="fas fa-sync-alt" />
                  </button>
                </div>
              </td>
            </tr>
          </table>

          <div class="fx-card">
            <div
              class="fx-list-row"
              style="background-color: #f2f2f2; padding: .8rem 1rem; color: #888; font-size: 1rem;"
            >
              <template v-if="filterInfo">
                {{ filterInfo }}
                <a
                  id="reset-filter"
                  v-tippy="{ content: 'Filter zurücksetzen', placement: 'top', duration: 100, delay: [300, null], arrow: true }"
                  class="m-4 no-color"
                  @click="resetSearch()"
                ><i
                  class="fas fa-times-circle"
                /></a>
              </template>
              <template v-else>
                Laufendes Jahr
              </template>
            </div>
            <JobListItem
              v-for=" job in localJobs "
              :key="job.id"
              :job="job"
              :allow-editing="allowNewPolicy"
              @open-confirm-delete-job-modal="openConfirmDeleteJobModal"
              @change-status="changeStatusViaAPI"
              @quickview="openQuickView"
            />
            <EmptyState v-if="localJobs.length === 0 && loaded" :title="noResultsTitle" :hint="noResultsHint" :no-border="true">
              <button v-if="filterApplied" class="fx-btn-secondary" @click="resetSearch()">
                <i
                  class="fas fa-times-circle"
                />&nbsp;&nbsp;Filter
                zurücksetzen
              </button>
            </EmptyState>
          </div>
          <Pagination v-model="pagination" i18n-key="activerecord.models.job" @navigate="loadPage" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import _ from 'lodash';
import axios from 'axios';
import Flash from 'flash/index';
import openExportModal from 'helpers/open_export_modal';
import confirmViaModal from 'helpers/confirm_modal';
import { jobQuickView } from 'helpers/quickviews';
import { useCurrentUserStore } from 'stores/current_user';
import openNewJobModal from 'helpers/open_new_job_modal';

import FoxtagPaths from 'mixins/foxtag_paths';
import FilterInfo from 'mixins/filter_info';

import SelectMegaTimerange from 'components/select/select_mega_timerange.vue';
import DBBasedFilter from 'components/filter/db_based_filter.vue';
import FilterStaticOptions from 'components/filter/filter_static_options.vue';

import { ref, computed } from 'vue';

import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';

import Pagination from 'components/pagination.vue';

import EmptyState from 'components/empty_state.vue';
import JobListItem from './job_list_item.vue';

export default {
  name: 'JobsIndex',
  components: {
    JobListItem,
    EmptyState,
    Pagination,
    SelectMegaTimerange,
    DropDown,
    DropDownItem,
    FilterStaticOptions,
    DBBasedFilter,
  },
  mixins: [FoxtagPaths, FilterInfo],
  props: {
    allowNewPolicy: {
      type: Boolean,
    },
    customerId: {
      type: String,
      default: null,
    },
    locationId: {
      type: String,
      default: null,
    },
    defaultTimerange: {
      type: String,
      default: 'y',
    },
    hasJobsImport: {
      type: Boolean,
      default: false,
    },
    noResultsTitle: {
      type: String,
      default: 'Keine Aufträge gefunden',
    },
    noResultsHint: {
      type: String,
      default: 'Mit den aktuellen Filtereinstellungen wurden keine Aufträge gefunden',
    },
    statusOptions: {
      type: Array,
      default: () => [
        { id: 'not_cancelled', color: null },
        { id: 'open_status', color: null },
        { id: 'unplanned', color: 'rgb(98, 132, 203)' },
        { id: 'planned', color: 'rgb(59, 175, 218)' },
        { id: 'active', color: 'rgb(245, 187, 67)' },
        { id: 'closed', color: 'rgb(160, 211, 104)' },
        { id: 'invoiced', color: 'rgb(97, 162, 26)' },
        { id: 'free_of_charge', color: 'rgb(97, 162, 26)' },
        { id: 'cancelled', color: 'rgb(170, 170, 170)' },
      ],
    },
  },
  setup() {
    const userStore = useCurrentUserStore();
    const pagination = ref({ ...userStore.pagination('jobs') });
    const filter = ref({ ...userStore.filter('jobs') });
    const defaultFilter = {
      timerange: 'y',
      labelId: null,
      locationId: null,
      status: null,
      technicianId: null,
      installationTypeId: null,
    };

    const localJobs = ref([]);

    const loaded = ref(false);

    const load = () => {
      loaded.value = false;
      axios.get('/jobs/filtered', {
        params: {
          status: filter.value.status,
          location: filter.value.locationId,
          timerange: filter.value.timerange,
          technician: filter.value.technicianId,
          installation_type: filter.value.installationTypeId,
          label: filter.value.labelId,
          page: pagination.value.page,
          limit: pagination.value.limit,
        },
      }).then((response) => {
        localJobs.value = response.data.jobs;
        pagination.value.page = response.data.meta.current_page;
        pagination.value.pageCount = response.data.meta.total_pages;
        pagination.value.totalCount = response.data.meta.total_count;

        userStore.updatePaginationFromMeta('jobs', response.data.meta);
        userStore.updateFilter('jobs', filter.value);

        loaded.value = true;
      }).catch((err) => {
        if (err.message !== 'Request aborted') {
          Flash.error(err.message);
        }
      });
    };

    const loadPage = (changedPagination) => {
      pagination.value = { ...changedPagination };
      load();
    };

    const patchJobLabels = (job) => {
      const index = _.findIndex(localJobs.value, { id: job.id });
      localJobs.value[index].labels = job.labels;
    };

    const updatedJobEvent = (job) => {
      const index = _.findIndex(localJobs.value, { id: job.id });
      localJobs.value.splice(index, 1, job);
    };

    const deletedJobEvent = (job) => {
      const index = _.findIndex(localJobs.value, { id: job.id });
      localJobs.value.splice(index, 1);
    };

    const changeStatusViaAPI = (job, status) => {
      axios.post(`/jobs/${job.id}/statuses`, {
        status,
      }).then((response) => {
        Flash.info('Auftragstatus aktualisiert');
        updatedJobEvent(response.data.job);
      }).catch(() => {
        Flash.error('Auftragstatus konnte nicht aktualisiert werden');
      });
    };

    const search = () => {
      localJobs.value = [];
      pagination.value.page = 1;
      load();
    };

    const onFilterTimerange = (timerange) => {
      filter.value.timerange = timerange;
      search();
    };

    const onFilterTechnician = (technician) => {
      filter.value.technicianId = technician;
      search();
    };

    const onFilterStatus = (status) => {
      filter.value.status = status;
      search();
    };

    const onFilterInstallationType = (installationType) => {
      filter.value.installationTypeId = installationType;
      search();
    };

    const onFilterLabel = (labelId) => {
      filter.value.labelId = labelId;
      search();
    };

    const onFilterLocation = (locationId) => {
      filter.value.locationId = locationId;
      search();
    };

    const resetSearch = () => {
      filter.value = { ...defaultFilter };
      search();
    };

    const deleteViaApi = (job) => {
      axios.delete(`/jobs/${job.id}`).then(() => {
        Flash.info('Auftrag gelöscht');
        deletedJobEvent(job);
      }).catch(() => {
        Flash.error('Auftrag konnte nicht gelöscht werden');
      });
    };

    // -- Computed

    const filterApplied = computed(() => {
      let filtered = false;

      Object.keys(defaultFilter).forEach((key) => {
        if (filter.value[key] !== defaultFilter[key]) {
          filtered = true;
        }
      });

      return filtered;
    });

    // --- MODALS

    const openQuickView = (jobId) => {
      jobQuickView(jobId).then((changedJob) => {
        patchJobLabels(changedJob);
      });
    };

    const openExportModalJobs = () => openExportModal({
      title: 'Auftrag-Export',
      exporttype: 'jobs',
      config: filter,
    });

    const openConfirmDeleteJobModal = (job) => {
      confirmViaModal({
        message: 'Diese Aktion löscht den Auftrag und alle im Auftrag erfassten Daten und Berichte!',
        title: `Auftrag ${job.number} löschen?`,
      }).then(() => {
        deleteViaApi(job);
      });
    };

    return {
      openQuickView,
      openExportModalJobs,
      openConfirmDeleteJobModal,
      changeStatusViaAPI,
      localJobs,
      onFilterInstallationType,
      onFilterLabel,
      onFilterLocation,
      onFilterStatus,
      onFilterTechnician,
      onFilterTimerange,
      filter,
      pagination,
      loadPage,
      load,
      search,
      resetSearch,
      loaded,
      filterApplied,
    };
  },
  computed: {
    importExportDropDownLabel() {
      if (this.hasJobsImport) {
        return 'Import/Export';
      }
      return 'Export';
    },
  },
  mounted() {
    if (!this.filter.timerange) {
      this.filter.timerange = this.defaultTimerange;
    }

    if (this.locationId) {
      this.filter.locationId = this.locationId;
      this.filter.customerId = null;
    }
  },
  methods: {
    newJob() {
      openNewJobModal().then(() => {
        this.search();
      });
    },
  },
};
</script>
