<template>
  <div>
    <drop-down-item v-if="hasStatus(['unplanned'])">
      <a href="#" @click.prevent="$emit('changeStatus', 'planned')"><i
        class="fas fa-square fa-fw"
        style="color: #3BAFDA"
      /> Planung beenden</a>
    </drop-down-item>
    <drop-down-item v-if="hasStatus(['planned'])">
      <a href="#" @click.prevent="$emit('changeStatus', 'unplanned')"><i
        class="fas fa-square fa-fw"
        style="color: #6284CB"
      /> in Planung zurücksetzen</a>
      <a href="#" @click.prevent="$emit('changeStatus', 'active')"><i
        class="fas fa-square fa-fw"
        style="color: #F5BB43"
      /> Auf Auftrag läuft setzen</a>
    </drop-down-item>
    <drop-down-item v-if="!isExternal && hasStatus(['closed', 'invoiced', 'free_of_charge', 'cancelled'])">
      <a href="#" @click.prevent="$emit('changeStatus', 'active')"><i
        class="fas fa-square fa-fw"
        style="color: #F5BB43"
      /> Auftrag erneut öffnen</a>
    </drop-down-item>
    <drop-down-item v-if="hasStatus(['closed'])">
      <a href="#" @click.prevent="$emit('changeStatus', 'invoiced')"><i
        class="fas fa-square fa-fw"
        style="color: #61A21A"
      /> Auftrag ist abgerechnet</a>
    </drop-down-item>
    <drop-down-item v-if="hasStatus(['closed'])">
      <a href="#" @click.prevent="$emit('changeStatus', 'free_of_charge')"><i
        class="fas fa-square fa-fw"
        style="color: #61A21A"
      /> Auftrag wird nicht berechnet</a>
    </drop-down-item>
    <drop-down-item v-if="hasStatus(['active'])">
      <a href="#" @click.prevent="$emit('changeStatus', 'closed')"><i
        class="fas fa-square fa-fw"
        style="color: #A0D368"
      /> Auf erledigt setzen</a>
    </drop-down-item>
    <drop-down-item v-if="hasStatus(['invoiced', 'free_of_charge'])">
      <a href="#" @click.prevent="$emit('changeStatus', 'closed')"><i
        class="fas fa-square fa-fw"
        style="color: #A0D368"
      /> Auf erledigt zurücksetzen</a>
    </drop-down-item>
    <drop-down-item v-if="hasStatus(['planned', 'unplanned', 'active'])">
      <a href="#" @click.prevent="$emit('changeStatus', 'cancelled')"><i
        class="fas fa-square fa-fw"
        style="color: #AAAAAA"
      /> Auftrag entfällt</a>
    </drop-down-item>

    <drop-down-item v-if="withExternalOption && hasStatus(['planned', 'unplanned'])">
      <a href="#" @click.prevent="$emit('confirmCloseAsExternal')"><i class="fas fa-fw" /> Als extern beenden</a>
    </drop-down-item>
    <drop-down-item v-if="showDelete">
      <hr>
    </drop-down-item>
    <drop-down-item v-if="showDelete">
      <a href="#" @click.prevent="$emit('confirmDeletion'); $emit('close');"><i class="far fa-trash-alt fa-fw" /> Auftrag
        löschen</a>
    </drop-down-item>
  </div>
</template>

<script>
import DropDownItem from 'components/dropdown_menu/menu_item.vue';

export default {
  name: 'JobStatusOptions',
  components: {
    DropDownItem,
  },
  props: {
    isExternal: {
      type: Boolean,
      default: false,
    },
    withExternalOption: {
      type: Boolean,
      default: false,
    },
    jobStatus: {
      type: String,
      required: true,
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['changeStatus', 'confirmCloseAsExternal', 'confirmDeletion', 'close'],
  methods: {
    hasStatus(statusList) {
      return statusList.includes(this.jobStatus);
    },
  },
};
</script>
