<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <h2 class="fx">
      Namen von Kontakten entfernen
    </h2>
    <p style="font-size: .9rem;">
      Ursprünglich war der Name eines Kontakts ein Pflichtfeld, ist aber inzwischen optional.
    </p>
    <p style="font-size: .9rem;">
      Mit dieser Funktion können Sie Platzhalter wie "Ansprechpartner" oder einzelne Zeichen als Namen von Kontakten
      entfernen.
    </p>

    <div class="fx-form-row">
      <div class="fx-form-label">
        <label class="inline mandatory">Name</label>
      </div>
      <div class="fx-form-content">
        <input v-model="name" type="text">
      </div>
    </div>
    <div class="float-right">
      <input
        type="submit"
        value="Namen entfernen"
        class="fx-btn-primary"
        style="margin-bottom: 0.5rem;"
        @click.prevent="submit()"
      >
    </div>
    <div class="clearfix" />
  </div>
</template>

<script>
export default {
  name: 'NullifyNamesModal',
  emits: ['close', 'nullify'],
  data() {
    return {
      name: null,
    };
  },
  computed: {
  },
  methods: {
    submit() {
      this.$emit('nullify', this.name);
      this.$emit('close');
    },
  },
};
</script>
