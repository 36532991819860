<template>
  <div class="fx-list-row">
    <div class="fx-single-control-col">
      <div class="fx-btn-group">
        <a
          class="fx-btn-skeleton"
          @click="$emit('open-edit-event-modal', checkEvent)"
        >bearbeiten</a>
        <ButtonDeleteIcon @click="$emit('open-confirm-delete-event-modal', checkEvent)" />
      </div>
    </div>
    <div class="fx-10px-col">
      <a v-tippy="{ placement: 'right', duration: 100, arrow: true }" title="Details zeigen" @click.prevent.stop="showDetails= !showDetails;">
        <i class="fa" :class="detailsClass" />
      </a>
    </div>
    <div class="fx-180px-col">
      <strong>{{ eventDate }}</strong> {{ eventTime }}
    </div>
    <div class="fx-220px-col">
      <JobLabel
        v-if="checkEvent.job"
        :with-icon="false"
        :job-id="checkEvent.job.id"
        :job-planned-at="checkEvent.job.planned_at"
        :job-type-name="checkEvent.job.job_type_name"
      />
      <span v-else class="secondary">außerhalb eines Auftrags</span>
    </div>

    <div class="fx-150px-col">
      <strong>{{ eventTypeLabel() }}</strong>
    </div>
    <div class="fx-10px-col">
      <span v-if="checkEvent.data.length" class="secondary">
        <a
          v-tippy="{ placement: 'top', duration: 100, arrow: true }"
          class="no-color"
          title="Checkliste ansehen"
          @click="showDetails= !showDetails;"
        ><i class="fas fa-list" /></a>
      </span>
    </div>
    <div class="fx-150px-col">
      <span :class="resultClass"><span v-if="isConfirmed"><i class="fas fa-shield-check" :title="confirmationText" /></span> {{ result }}</span>
    </div>

    <div class="fx-150px-col">
      {{ checkEvent.user.full_name }}
    </div>
    <div v-if="showDetails" style="padding: 1rem 0 2rem 100px; background: #f6f6f6">
      <table class="material">
        <tr>
          <td class="title" style="width: unset; border-top: 1px solid #eee; ">
            Geprüft von:
          </td>
          <td>{{ checkEvent.user.full_name }}</td>
        </tr>
      </table>
      <DataTable :data="checkEvent.data" />
      <IncidentsForEventTable :thing-id="thingId" :event-id="checkEvent.id" />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import JobLabel from 'components/partials/job_label.vue';
import ButtonDeleteIcon from 'components/button_delete_icon.vue';
import DataTable from 'components/partials/data_table.vue';
import IncidentsForEventTable from 'components/partials/incidents_for_event_table.vue';

export default {
  name: 'ThingEvent',
  components: {
    JobLabel,
    DataTable,
    IncidentsForEventTable,
    ButtonDeleteIcon,
  },
  props: {
    checkEvent: {
      type: Object,
      required: true,
    },
    thingId: {
      type: String,
      required: true,
    },
    positiveResultLabel: {
      type: String,
      default: 'OK',
    },
    negativeResultLabel:
    {
      type: String,
      default: 'Mangel',
    },
  },
  emits: ['open-confirm-delete-event-modal', 'open-edit-event-modal'],
  data() {
    return {
      showDetails: false,
    };
  },
  computed: {
    eventDate() {
      return moment(this.checkEvent.eventdate).format('DD.MM.YYYY');
    },
    eventTime() {
      return `${moment(this.checkEvent.eventdate).format('HH:mm')} Uhr`;
    },
    result() {
      if (this.checkEvent.eventtype !== 'check') { return ''; }

      if (this.checkEvent.result === 'success') {
        return this.positiveResultLabel;
      }
      return this.negativeResultLabel;
    },
    resultClass() {
      return `result-label-${this.checkEvent.result}`;
    },
    detailsClass() {
      if (this.showDetails) {
        return 'fa-chevron-down';
      }
      return 'fa-chevron-right';
    },
    isConfirmed() {
      return (this.checkEvent.confirmation != null && this.checkEvent.confirmation != 'no_confirm');
    },
    confirmationText() {
      return this.$t(`activerecord.attributes.event.confirmations.${this.checkEvent.confirmation}`);
    },
  },
  methods: {
    eventTypeLabel() {
      switch (this.checkEvent.eventtype) {
        case 'check':
          return 'Prüfung durchgeführt';
        case 'installation':
          return 'Inbetriebnahme';
        case 'triggered':
          return 'Ausgelöst';
        case 'removal':
          return 'Außer Betrieb genommen';
        default:
          return 'Unbekannt';
      }
    },
  },
};
</script>

<style></style>
