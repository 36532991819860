<template>
  <div>
    <div class="fx-grid" style="display: block;">
      <PhotoGrid
        :photoable-id="thingId"
        photoable-type="Thing"
        :col-count="4"
        :installation-id="installationId"
        empty-state-hint="Von dieser Komponente wurden keine Fotos gemacht, oder sie haben eine Filtereinstellung, die keine Fotos findet."
      />
    </div>
  </div>
</template>

<script>
import PhotoGrid from 'apps/photos/photo_grid.vue';

export default {
  name: 'TabPhotos',
  components: {
    PhotoGrid,
  },
  props: {
    thingId: {
      type: String,
      required: true,
    },
    installationId: {
      type: String,
      default: null,
    },
  },
};
</script>
