<template>
  <aside class="fx-sidebar-layout-nav">
    <ul class="fx-sidebar">
      <SidebarNavItem
        v-for="nav in navs"
        :id="idFromIcon(nav.icon)"
        :key="nav.icon"
        :icon="nav.icon"
        :href="userHref(nav.href)"
        :label-key="nav.labelKey"
        :active-key="activeNav"
        :disabled="isDisabled(nav)"
      />
    </ul>
    <br>
    <ul class="fx-sidebar">
      <SidebarNavItem
        v-for="nav in adminNavs"
        :id="idFromIcon(nav.icon)"
        :key="nav.icon"
        :disabled="isDisabled(nav)"
        :icon="nav.icon"
        :href="userHref(nav.href)"
        :label-key="nav.labelKey"
        :active-key="activeNav"
      />
    </ul>

    <ul v-if="(currentUser.roles.indexOf('admin') > -1) && keyaccountId && keyaccountId.length" class="fx-sidebar">
      <li id="side-bar-keyaccount" class="fx-sidebar">
        <a :href="`/keyaccounts/${keyaccountId}`">
          <i class="far fa-university fa-fw " /> {{ $t('nav.admin.key-account') }}
        </a>
      </li>
    </ul>

    <div style="font-size: 0.85rem; margin-left: 1.4rem; margin-top: 1rem;">
      <a href="/accounts/my">
        <i class="fas fa-long-arrow-right" /> {{ $t('nav.main.personal-settings') }}
      </a>
    </div>
    <div>&nbsp;</div>
    <div class="secondary" style="font-size: 0.85rem; margin-left: 1.4rem; margin-top: 1rem;">
      Foxtag Revision
    </div>
  </aside>
</template>

<script>
import { foxtagSitemap } from 'initializers/sitemap';
import { useCurrentUserStore } from 'stores/current_user';
import SidebarNavItem from './sidebar_nav_item.vue';

export default {
  components: {
    SidebarNavItem,
  },
  props: {
    activeNav: {
      type: String,
      default: null,
    },
    organizationId: {
      type: String,
      required: true,
    },
    keyaccountId: {
      type: String,
      default: null,
    },
  },
  setup() {
    const navs = [];
    navs.push(foxtagSitemap['administration/installation-types']);
    navs.push(foxtagSitemap['administration/articles']);
    navs.push(foxtagSitemap['administration/contacts']);
    navs.push(foxtagSitemap['administration/labels']);
    navs.push(foxtagSitemap['administration/jobs']);
    navs.push(foxtagSitemap['administration/reports']);
    navs.push(foxtagSitemap['administration/installation-templates']);
    navs.push(foxtagSitemap['administration/import']);

    const adminNavs = [];
    adminNavs.push(foxtagSitemap['administration/organization']);
    adminNavs.push(foxtagSitemap['administration/emails']);
    adminNavs.push(foxtagSitemap['administration/calendar']);
    adminNavs.push(foxtagSitemap['administration/app']);
    adminNavs.push(foxtagSitemap['administration/users']);
    adminNavs.push(foxtagSitemap['administration/licenses']);
    adminNavs.push(foxtagSitemap['administration/integrations']);

    return {
      navs,
      adminNavs,
      currentUser: useCurrentUserStore().currentUser,
    };
  },
  methods: {
    anyRole(roles) {
      if (roles == null) { return false; }
      return roles.some((role) => this.currentUser.roles.includes(role));
    },
    isDisabled(nav) {
      return !this.anyRole(nav.roles);
    },
    // replace path placeholders with actual values
    userHref(href) {
      return href.replace('ORGANIZATION_ID', this.currentUser.organization_id);
    },
    idFromIcon(icon) { return `side-bar-${icon.split('/').pop().replace('_', '-')}`; },
  },
};
</script>
