import Uppy from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import German from '@uppy/locales/lib/de_DE';

export const useUppy = (userSettings = {}) => {
  const loc = German;
  const defaults = { maxFileSizeMB: 5, allowedFileTypes: ['.png', '.jpg', '.jpeg'], maxNumberOfFiles: 1 };
  const combinedSettings = { ...defaults, ...userSettings };

  loc.strings.dropPasteFiles = 'Datei hier ablegen oder %{browseFiles}';

  const uppy = new Uppy({
    locale: loc,
    restrictions: {
      autoProceed: true,
      maxFileSize: 1024 * 1000 * combinedSettings.maxFileSizeMB,
      minFileSize: null,
      maxTotalFileSize: null,
      maxNumberOfFiles: combinedSettings.maxNumberOfFiles,
      minNumberOfFiles: 1,
      allowedFileTypes: combinedSettings.allowedFileTypes,
      requiredMetaFields: [],
    },
  });
  uppy.use(AwsS3, {
    companionUrl: '/', // will call `GET /s3/params` on our app
  });

  return uppy;
};

export const onUppyUploaded = (uppy, callback) => {
  uppy.on('upload-success', (file, response) => {
    const uploadedFileData = {
      id: file.meta.key.match(/^cache\/(.+)/)[1], // object key without prefix
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      },
    };

    callback(uploadedFileData);
  });
};
