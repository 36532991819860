import moment from 'moment';

require('moment/locale/de');

moment.updateLocale('de', {
  months: 'Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember'.split('_'),
  weekdays: 'Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag'.split('_'),
  weekdaysShort: 'Son_Mon_Din_Mit_Don_Fre_Sam'.split('_'),
  weekdaysMin: 'So_Mo_Di_Mi_Do_Fr_Sa'.split('_'),
});
// moment.locale('de');
// console.log(moment().format('dddd, DD.MM.YYYY'));
