<template>
  <div class="fx-card">
    <div class="fx-card-content fx-card-header" style="padding: 0.6rem 1rem;">
      <div class="fx-grid" style="padding-left: .1rem; align-items: center;">
        <div class="fx-col_no_pad" style="flex: 5; max-width: 600px;">
          <h2 class="fx" style="margin:0; font-size: 1.2rem; line-height: 1.3;">
            <span v-if="location.number" style="margin:0 0.5rem 0 0; font-weight: 400;">{{ location.number }}</span><span>{{ location.active_name }}</span>
          </h2>
        </div>
        <div class="fx-col_no_pad" style="flex: 2;  font-size: 0.825rem;">
          <span v-if="hasAddress"><i class="fas fa-map-marker-alt" style="margin-right: .3rem;" />{{ truncate(location.active_address_one_line, 150) }}</span>
        </div>
        <div class="fx-col_no_pad" style="flex: 0; font-size: 0.825rem; text-align: right; white-space: nowrap;">
          {{ truncate(location.organization_name, 150) }}
        </div>
      </div>
    </div>

    <SharedInstallation
      v-for="sharedInstallation in localSharedInstallations"
      :key="sharedInstallation.id"
      :shared-installation="sharedInstallation"
      :show-next-job-col="showNextJobCol"
      :show-next-check-col="showNextCheckCol"
      :show-inventory-col="showInventoryCol"
      :show-incidents-col="showIncidentsCol"
    />
  </div>
</template>

<script>
import axios from 'axios';
import { TruncateFormatter } from 'mixins/formatters';
import SharedInstallation from './shared_installation_row.vue';

export default {
  name: 'LocationListItem',
  components: {
    SharedInstallation,
  },
  mixins: [TruncateFormatter],
  props: {
    location: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localSharedInstallations: [],
      showIncidentsCol: false,
      showNextJobCol: false,
      showNextCheckCol: false,
      showInventoryCol: false,
    };
  },
  computed: {
    hasAddress() {
      return (this.location.active_address_one_line != null && this.location.active_address_one_line.length > 0);
    },
  },
  mounted() {
    this.loadInstallations();
  },
  methods: {
    loadInstallations() {
      axios.get('/portal/shared_installations.json', {
        params: {
          location_id: this.location.id,
        },
      }).then((response) => {
        this.localSharedInstallations = response.data;
        this.showColHeaders();
      }).catch();
    },
    showColHeaders() {
      for (let i = 0; i < this.localSharedInstallations.length; i += 1) {
        if (this.localSharedInstallations[i].roles.includes('incidents')) {
          this.showIncidentsCol = true;
        }
        if (this.localSharedInstallations[i].roles.includes('jobs')) {
          this.showNextJobCol = true;
        }
        if (this.localSharedInstallations[i].roles.includes('due_dates')) {
          this.showNextCheckCol = true;
        }
        if (this.localSharedInstallations[i].roles.includes('inventory')) {
          this.showInventoryCol = true;
        }
      }
    },
  },
};
</script>
