<template>
  <span v-if="withLink">
    <a
      v-tippy="{ content: title, placement: 'top', duration: 100, delay: [800,0], arrow: true }"
      class="no-color"
      :href="linkPath"
    >
      <div :class="blockClass">
        <div :class="lineClass">{{ firstLine }}</div>
        <div :class="lineClass"><strong>{{ plannedAtFormatted }}</strong></div>
      </div>
    </a>
  </span>
  <span v-else>
    <div :class="blockClass">
      <div :class="lineClass">{{ firstLine }}</div>
      <div :class="lineClass"><strong>{{ plannedAtFormatted }}</strong></div>
    </div>
  </span>
</template>

<script>
import moment from 'moment';
import FoxtagPaths from 'mixins/foxtag_paths';

export default {
  name: 'NextJob',
  mixins: [FoxtagPaths],
  props: {
    jobId: {
      type: String,
      default: null,
    },
    installationId: {
      type: String,
      required: true,
    },
    jobPlannedAt: {
      type: String,
      default: null,
    },
    jobTypeName: {
      type: String,
      default: null,
    },
    withIcon: {
      type: Boolean,
      default: true,
    },
    withLink: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    blockClass() {
      if (this.jobPlannedAt) {
        return (this.withIcon) ? 'fx-job-block' : '';
      }
      return (this.withIcon) ? 'fx-job-block secondary' : 'secondary';
    },
    lineClass() {
      return (this.withIcon) ? 'fx-job-line' : '';
    },
    plannedAtFormatted() {
      if (this.jobPlannedAt) {
        return moment(this.jobPlannedAt).format('DD.MM.YYYY');
      }
      return '';
    },
    firstLine() {
      if (this.jobPlannedAt) {
        return this.jobTypeName;
      }
      return 'kein Auftrag geplant';
    },
    linkPath() {
      if (this.jobId) {
        return this.jobPath(this.jobId);
      }
      return this.jobForInstallationPath(this.installationId);
    },
    title() {
      if (this.jobPlannedAt) {
        return `Nächster Auftrag für den ${this.plannedAtFormatted} geplant`;
      }
      return 'Klicken, um einen Auftrag zu planen';
    },
  },
};
</script>
