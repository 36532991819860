<template>
  <div>
    <div class="fx-grid">
      <div class="fx-col">
        <EmptyState
          v-if="showEmptyState"
          icon="fas fa-exclamation-triangle"
          title="Keine Störungen"
          hint="In diesem Auftrag wurden keine Störungen erfasst"
        />

        <div v-if="!showEmptyState" class="fx-card">
          <div v-for="incidentStatus in localIncidentStatuses" :key="incidentStatus.id" class="fx-flex-row">
            <div class="fx-flex-row-ico">
              <i class="fas fa-exclamation-triangle" />
            </div>
            <div class="fx-flex-row-main">
              <div class="fx-flex-row-title">
                <a v-if="features.incidentsPro" class="no-color mr-2" :href="incidentPath(incidentStatus.incident.id)">
                  <span class="fx-flex-row-title-number">#{{ incidentStatus.incident.number }}</span>
                  {{ truncate(incidentStatus.incident.incident_type.name, 120) }}
                </a>
                <span v-else>
                  <span class="fx-flex-row-title-number">#{{ incidentStatus.incident.number }}</span>
                  {{ truncate(incidentStatus.incident.incident_type.name, 120) }}
                </span>

                <Labels :labels="incidentStatus.incident.labels" />
              </div>

              <div class="fx-flex-row-subline">
                <span>
                  In diesem Auftrag
                  <span v-if="incidentStatus.status" :class="'fx-status incident-' + incidentStatus.status">
                    {{ $t('activerecord.attributes.incident_status.statuses.' + incidentStatus.status) }}
                  </span>

                  <span v-if="incidentStatus.statusdate" style="margin-left: .2rem;"><strong>{{
                    formatDate(incidentStatus.statusdate, 'DD.MM.YYYY') }}</strong> {{
                    formatDate(incidentStatus.statusdate, 'HH:mm') }} Uhr</span>
                </span>

                <span v-if="incidentStatus.incident.severity != 'normal'" style="margin-right: .8rem;">
                  <span :class="'severity-' + incidentStatus.incident.severity">
                    {{ $t('activerecord.attributes.incident.severities.' + incidentStatus.incident.severity) }}
                  </span>
                </span>

                <span v-if="incidentStatus.incident.thing" class="fx-ico-text fx-ico-text-thing">
                  <a class="no-color" :href="thingPath(incidentStatus.incident.thing.id)">{{
                    incidentStatus.incident.thing.label }}</a>
                </span>

                <span v-if="incidentStatus.note" class="fx-ico-text fx-ico-text-note">
                  <a class="no-color" @click="openIncidentQuickView(incidentStatus)">
                    {{ truncate(incidentStatus.note, 30) }}
                  </a>
                </span>
              </div>
            </div>

            <div class="fx-flex-row-nav">
              <a class="fx-btn-skeleton" @click="openIncidentQuickView(incidentStatus)"><i class="fas fa-eye" /></a>
              <a
                v-if="features.incidentsPro"
                :href="incidentPath(incidentStatus.incident.id)"
                class="fx-btn-skeleton"
              >Details</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import axios from 'axios';

import Flash from 'flash/index';

import FoxtagPaths from 'mixins/foxtag_paths';
import FeatureFlipper from 'mixins/feature_flipper';
import { DateFormatter, TruncateFormatter } from 'mixins/formatters';
import { useCurrentUserStore } from 'stores/current_user';
import { incidentQuickView } from 'helpers/quickviews';
import { ref } from 'vue';
import EmptyState from 'components/empty_state.vue';

import Labels from 'components/partials/labels.vue';

export default {
  name: 'TabIncidents',
  components: {
    EmptyState,
    Labels,
  },
  mixins: [FoxtagPaths, FeatureFlipper, DateFormatter, TruncateFormatter],
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    thingLabel: {
      type: String,
      default: 'Komponente',
    },
  },
  emits: ['patch-incident'],
  setup() {
    const localIncidentStatuses = ref([]);

    const patchIncident = (incident) => {
      const index = _.findIndex(localIncidentStatuses.value, { incident: { id: incident.id } });
      localIncidentStatuses.value[index].incident.labels = incident.labels;
    };

    const openIncidentQuickView = (incidentStatus) => {
      const incidentId = incidentStatus.incident.id;

      incidentQuickView(incidentId, incidentStatus.id).then((changedIncident) => {
        patchIncident(changedIncident);
      });
    };

    return {
      localIncidentStatuses,
      openIncidentQuickView,
      currentUser: useCurrentUserStore().currentUser,
    };
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    showEmptyState() {
      return (this.loaded && !this.localIncidentStatuses.length);
    },
  },
  mounted() {
    this.loadIncidents();
  },
  methods: {
    patchIncident(incident) {
      console.log(incident);
      const index = _.findIndex(this.localIncidentStatuses, { incident: { id: incident.id } });
      this.localIncidentStatuses[index].incident.labels = incident.labels;
    },
    // openQuickView(incidentStatus) {
    //   this.$modal.show('quick-view-modal', {
    //     incidentId: incidentStatus.incident.id,
    //     incidentStatusId: incidentStatus.id,
    //   });
    // },
    loadIncidents() {
      axios.get(`/jobs/${this.modelValue.id}/incidents.json`).then((response) => {
        this.localIncidentStatuses = response.data.incident_statuses;
        this.loaded = true;
      }).catch(() => {
        Flash.error('Fehler beim Laden der Störungen');
      });
    },
    notSameIncident(index) {
      if (index === 0) {
        return true;
      }

      return (this.localIncidentStatuses[index].incident.number
        !== this.localIncidentStatuses[index - 1].incident.number);
    },
  },
};
</script>
