<template>
  <div>
    <div class="fx-grid" style="margin-top: 0;">
      <div class="fx-col">
        <div style="display:flex; justify-content: space-between; margin-top: 0">
          <div style="flex: 1;">
            <h1 class="fx" style="flex: 1;">
              Störungstypen
            </h1>
            <p>
              Die Störungstypen stehen Ihren Technikern in der App beim Erfassen von Mängeln und Störungen zur Verfügung.
            </p>
          </div>
        </div>

        <div class="fx-card">
          <div class="fx-list-row" style="background-color: #f2f2f2; padding: 1rem; ">
            <div class="fx-single-control-col" style="margin:0;">
              <button class="fx-btn-ico-primary" @click.prevent="openEditIncidentTypeModal()">
                <i class="fas fa-plus" /> Störungstyp hinzufügen
              </button>
            </div>

            <div class="clearfix" />
          </div>

          <div class="fx-list-row">
            <div class="fx-50px-col secondary">
              <i class="fas fa-sort-numeric-down secondary" title="Sortierung" />
            </div>
            <div class="fx-450px-col">
              <strong>Name</strong>
            </div>
            <div class="fx-140px-col">
              <strong>Schweregrad</strong>
            </div>
            <div class="fx-140px-col">
              <strong>Auftraggeberportal</strong>
            </div>
            <div class="fx-140px-col">
              <strong>Anzahl Störungen</strong>
            </div>
          </div>

          <ListItem
            v-for="incidentType in localIncidentTypes"
            :key="incidentType.id"
            :incident-type="incidentType"
            @open-edit-incident-type="openEditIncidentTypeModal"
            @open-confirm-delete="confirmDeleteIncidentType"
          />
          <div v-if="showNoResults" style="padding: 1rem; text-align: center;">
            Keine Typen gefunden
          </div>
        </div>

        <Pagination v-model="pagination" i18n-key="activerecord.models.incident_type" @navigate="loadPage" />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import Flash from 'flash/index';
import EntityList from 'helpers/entity_list';
import { ref, watch } from 'vue';
import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';
import confirmViaModal from 'helpers/confirm_modal';

import Pagination from 'components/pagination.vue';

import EditModal from './edit_modal.vue';
import ListItem from './list_item.vue';

export default {
  name: 'IncidentTypesList',
  components: {
    ListItem,
    Pagination,
  },
  props: {
    installationTypeId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const localIncidentTypes = new EntityList();
    const pagination = ref({
      page: 1,
      pageCount: 1,
      totalCount: 0,
      limit: 25,
    });
    const filter = ref({
      query: null,
      category: '',
    });

    const load = () => {
      axios.get(`/incident_types.json?installation_type=${props.installationTypeId}`, {
        params: {
          page: pagination.value.page,
          limit: pagination.value.limit,
        },
      }).then((response) => {
        localIncidentTypes.value = response.data.incident_types;
        pagination.value.page = response.data.meta.current_page;
        pagination.value.pageCount = response.data.meta.total_pages;
        pagination.value.totalCount = response.data.meta.total_count;
      }).catch((err) => {
        if (err.message !== 'Request aborted') {
          Flash.error(err.message);
        }
      });
    };

    const deleteViaApi = (incidentType) => {
      axios.delete(`/incident_types/${incidentType.id}.json`).then(() => {
        localIncidentTypes.remove(incidentType);
        Flash.info('Störungstyp gelöscht');
      });
    };

    const loadPage = (changedPagination) => {
      pagination.value = { ...changedPagination };
      load();
    };

    const search = () => {
      localIncidentTypes.value = [];
      pagination.value.page = 1;
      load();
    };

    const resetSearch = () => {
      filter.value = {
        query: null,
        category: '',
      };
    };

    const debouncedSearch = _.debounce(() => {
      search();
    }, 250);

    // --- modals

    const confirmDeleteIncidentType = (incidentType) => {
      confirmViaModal({
        title: 'Störungstyp löschen?',
      }).then(() => {
        deleteViaApi(incidentType);
      });
    };

    const openEditIncidentTypeModal = (incidentType) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: EditModal,
            attrs: {
              incidentType,
              installationTypeId: props.installationTypeId,
              onClose() {
                modalInstance.close();
              },
              onCreated(newIncidentType) {
                localIncidentTypes.append(newIncidentType);
              },
              onUpdated(updatedIncidentType) {
                localIncidentTypes.update(updatedIncidentType);
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    watch(filter, () => {
      debouncedSearch();
    }, { deep: true });

    return {
      loadPage,
      pagination,
      filter,
      resetSearch,
      debouncedSearch,
      openEditIncidentTypeModal,
      confirmDeleteIncidentType,
      search,
      localIncidentTypes: localIncidentTypes.reactiveList,
    };
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    showNoResults() {
      return (this.localIncidentTypes.length === 0);
    },
  },
  mounted() {
    this.showEmptyState = this.startShowEmptyState;
  },
  methods: {
    reload() {
      this.localIncidentTypes = [];
      this.page = 0;
    },
    deleteViaApi(incidentType) {
      axios.delete(`/incident_types/${incidentType.id}.json`).then(() => {
        this.deleteLocalIncidentType(incidentType);
        Flash.info('Störungstyp gelöscht');
      });
    },
    deleteLocalIncidentType(incidentType) {
      const index = this.findIndexInList(incidentType.id);
      this.localIncidentTypes.splice(index, 1);
    },
    updateLocalIncidentType(incidentType) {
      const index = this.findIndexInList(incidentType.id);
      this.localIncidentTypes.splice(index, 1, incidentType);
    },
    findIndexInList(id) {
      return _.findIndex(this.localIncidentTypes, { id });
    },
  },
};
</script>
