<template>
  <div v-if="initialDataLoaded">
  <NavBreadcrumps path="administration/users/current" :current-label="localUser.name" />
  <div class="restricted-width">
  <div class="fx-card">
    <div style="background-color: #5C8097; padding: 1rem;">
      <img
        height="40px"
        :src="localUser.icon_path"
        style="vertical-align: middle;"
        width="40px"
      >
      <div
        style="display: inline-block; vertical-align: middle; color: white; margin-left: 0.6rem; font-size: 1.2rem; font-weight: 200;"
      >
        {{ localUser.name }}
      </div>
    </div>
    <hr style="margin: 0;">
    <div class="fx-card-content" style="padding-top: 1rem;">
      <ServerErrors v-model="serverErrors" title="Nutzer konnte nicht gespeichert werden" margin-bottom="1rem" />
      <div class="fx-form-row">
        <div class="fx-form-content">
          <span v-if="localUser.locked" class="fx-status alert">gesperrt</span>
          <span :class="statusClasses">{{ $t(`activerecord.attributes.user.statuses.${localUser.status}`) }}</span>
        </div>
      </div>
      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory">E-Mail</label>
        </div>
        <div class="fx-form-content">
          <input disabled="" type="text" :value="localUser.email">
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory">Name</label>
        </div>
        <div class="fx-form-content">
          <input disabled="" type="text" :value="localUser.name">
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory">Zeitzone</label>
        </div>
        <div class="fx-form-content">
          <input disabled="" type="text" :value="localUser.time_zone">
        </div>
      </div>

      <div class="callout callout--info">
        <p>
          E-Mail und Name kann der jeweilige Nutzer unter <strong>Persönliche Einstellungen</strong>
          aktualisieren.
        </p>
      </div>
      <hr style="margin-top: 1rem;">
      <form @submit.prevent="updateViaApi()">
        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline " for="user_number">Nummer</label>
          </div>
          <div class="fx-form-content">
            <input
              id="user_number"
              v-model="localUser.number"
              type="text"
              placeholder="optional"
              class="has-form-field-hint"
              name="user[number]"
            >
            <div class="form-field-hint">
              Mitarbeiter- oder Personalnummer
            </div>
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline" for="user_roles">Berechtigungen</label>
          </div>
          <div class="fx-form-content">
            <RolesCheckboxes :init-roles="localUser.roles" :role-admin-disabled="localUser.me" @changed="rolesChanged" />
          </div>
        </div>

        <div class="fx-form-row" style="margin-bottom: 0">
          <div class="fx-form-content">
            <div style="margin-bottom: 1rem;">
              <input
                id=""
                type="submit"
                name="commit"
                value="Speichern"
                class="fx-btn-primary"
              >
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <UserDevices :user-id="userId" />
</div>
</div>
</template>

<script>
import axios from 'axios';

import Flash from 'flash/index';
import ServerErrors from 'components/partials/server_errors.vue';
import RolesCheckboxes from 'components/partials/roles_checkboxes.vue';
import NavBreadcrumps from 'components/nav_breadcrumps.vue';
import UserDevices from 'apps/users/devices.vue';

export default {
  name: 'UsersShow',
  components: {
    ServerErrors,
    RolesCheckboxes,
    NavBreadcrumps,
    UserDevices,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      initialDataLoaded: false,
      localUser: null,
      serverErrors: [],
    };
  },
  computed: {
    statusClasses() {
      return `fx-status ${this.statusColor}`;
    },
    statusColor() {
      if (this.localUser.status === 'confirmed') { return 'success'; }
      if (this.localUser.status === 'invitation_expired') { return 'alert'; }
      return 'warning';
    },
  },
  mounted() {
    this.loadUser();
  },
  methods: {
    openInviteModal() {
      this.$modal.show('invite-modal');
    },
    rolesChanged(newRoles) {
      this.localUser.roles = newRoles;
    },
    updateViaApi() {
      const that = this;
      axios.put(`/users/${this.localUser.id}.json`, {
        user: {
          number: this.localUser.number,
          roles: this.localUser.roles,
        },
      }).then((response) => {
        that.localUser = response.data.user;
        new Flash('Nutzer gespeichert').show();
        that.serverErrors = [];
      }).catch((error) => {
        that.serverErrors = error.response.data.users;
      });
    },
    loadUser() {
      const that = this;
      axios.get(`/users/${this.userId}.json`)
        .then((response) => {
          that.localUser = response.data.user;
          that.initialDataLoaded = true;
        }).catch(() => {
          Flash.error('User konnte nicht geladen werden');
        });
    },
  },
};
</script>
