<template>
  <div class="fx-list-row">
    <div class="fx-single-control-col">
      <div class="fx-btn-group">
        <a class="fx-btn-skeleton" @click.prevent="$emit('open-edit-incident-type', incidentType)">{{ $t('edit') }}</a>
        <ButtonDeleteIcon :disabled="incidentType.incidents_count > 0" @click="$emit('open-confirm-delete', incidentType)" />
      </div>
    </div>

    <div class="fx-50px-col secondary">
      {{ incidentType.position }}
    </div>

    <div class="fx-450px-col">
      <template v-if="incidentType.active">
        <span :title="incidentType.name">{{ truncate(incidentType.name, 400) }}</span>
        <div class="secondary">
          {{ truncate(incidentType.default_description, 400) }}
        </div>
      </template>
      <template v-else>
        <span :title="incidentType.name" class="secondary">{{ truncate(incidentType.name, 400) }} (passiv)</span>
      </template>
    </div>
    <div class="fx-140px-col">
      <span :class="'severity-' + incidentType.default_severity">
        {{ $t('activerecord.attributes.incident.severities.' + incidentType.default_severity) }}
      </span>
    </div>

    <div class="fx-140px-col">
      <div v-if="incidentType.portal">
        verfügbar
      </div>
    </div>

    <div class="fx-100px-col" :class="incidentsCountClass">
      <i class="fas fa-exclamation-triangle" />
      <span style="margin-left: 0.1rem;">{{ incidentType.incidents_count }}</span>
    </div>

    <div class="clearfix" />
  </div>
</template>

<script>
import { TruncateFormatter } from 'mixins/formatters';

import ButtonDeleteIcon from 'components/button_delete_icon.vue';

export default {
  name: 'IncidentTypeItem',
  components: {
    ButtonDeleteIcon,
  },
  mixins: [TruncateFormatter],
  props: {
    incidentType: {
      type: Object,
      required: true,
    },
  },
  emits: ['open-edit-incident-type', 'open-confirm-delete'],
  computed: {
    incidentsCountClass() {
      if (this.incidentType.incidents_count === 0) {
        return 'secondary';
      }
      return '';
    },
    hasManufacturerOrModell() {
      return (this.incidentType.manufacturer != null || this.incidentType.model != null);
    },
  },
};
</script>
