<template>
  <form @submit.prevent="enqueue">
    <div class="fx-form-row">
      <div class="fx-form-row">
        <div class="fx-form-label">
          <label for="sdf">Austausch fällig bis</label>
        </div>
        <div class="fx-form-content">
          <VueDatePicker
            v-model="dueThru"
            :locale="$i18n.locale"
            :auto-apply="true"
            model-type="yyyy-MM-dd"
            :format="'dd.MM.yyyy'"
            :enable-time-picker="false"
          />
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline">Wartungsanwendung</label>
        </div>
        <div class="fx-form-content">
          <DBBasedSelect
            v-model="localInstallationTypeId"
            icon-id="installation_type"
            :default-label="$t('comp.select.no-selection')"
            i18n-base-key="installation_types"
            load-options-url="/installation_types/for_select"
            @changed="onInstallationTypeSelected"
          />
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline">Stammtechniker</label>
        </div>
        <div class="fx-form-content">
          <DBBasedSelect
            v-model="localTechnicianId"
            icon-id="user"
            :default-label="$t('comp.select.no-selection')"
            i18n-base-key="users"
            load-options-url="/users/for_select"
            @changed="onTechnicianSelected"
          />
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline">Objekt / Adresse</label>
        </div>
        <div class="fx-form-content">
          <input v-model="localQuery" :placeholder="$t('comp.filter.site-address.title')" type="text">
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline">PLZ</label>
        </div>
        <div class="fx-form-content">
          <input v-model="localZip" :placeholder="$t('comp.filter.zip.title')" type="text">
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline">Label</label>
        </div>
        <div class="fx-form-content">
          <DBBasedSelect
            v-model="localLabelIds"
            icon-id="label"
            :default-label="$t('comp.select.no-selection')"
            i18n-base-key="labels"
            load-options-url="/labels/for_select?label_type=installation"
            @changed="onLabelsSelected"
          />
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label>Format</label>
        </div>
        <div class="fx-form-content">
          <input
            id="file_type_xlsx"
            v-model="file_type"
            type="radio"
            value="xlsx"
            name="file_type"
          >
          <label class="inline" for="file_type_xlsx">Excel</label>
          <input
            id="file_type_csv"
            v-model="file_type"
            type="radio"
            value="csv"
            name="file_type"
          >
          <label class="inline" for="file_type_csv">CSV</label>
        </div>
      </div>
      <div class="fx-form-row">
        <div class="fx-form-content">
          <input type="submit" class="fx-btn-primary" value="Export starten">
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';

import DBBasedSelect from 'components/select/db_based_select.vue';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: 'EnqueueDueReplacements',
  components: {
    VueDatePicker,
    DBBasedSelect,
  },
  props: {
    exporttype: {
      type: String,
      required: true,
    },
  },
  emits: ['enqueued'],
  data() {
    return {
      dueThru: new Date().toISOString().substr(0, 10),
      file_type: 'xlsx',
      localInstallationTypeId: null,
      localLabelIds: null,
      localTechnicianId: null,
      localQuery: null,
      localZip: null,
    };
  },
  methods: {
    onInstallationTypeSelected(newInstallationTypeId) {
      this.localInstallationTypeId = newInstallationTypeId;
    },
    onTechnicianSelected(newTechnicianId) {
      this.localTechnicianId = newTechnicianId;
    },
    onLabelsSelected(newLabelIds) {
      this.localLabelIds = newLabelIds;
    },
    enqueue() {
      axios.post('/exports/enqueue', {
        exportable_id: this.exportableId,
        exporttype: this.exporttype,
        filter: {
          query: this.localQuery,
          installationTypeId: this.localInstallationTypeId,
          zip: this.localZip,
          passive: null,
          labelId: this.localLabelIds,
          technicianId: this.localTechnicianId,
        },
        due_thru: this.dueThru,
        file_type: this.file_type,
      }).then((response) => {
        this.$emit('enqueued', response.data.export_id);
      }).catch(() => {
        Flash.error('Export konnte nicht erzeugt werden');
      });
    },
  },
};
</script>
