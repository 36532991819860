<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <h2 class="fx">
      {{ title }}
    </h2>
    <hr>
    <ServerErrors v-model="serverErrors" title="Typ konnte nicht gespeichert werden" margin-bottom="1rem" />
    <form>
      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory">Kategorie</label>
        </div>
        <div class="fx-form-help">
          <HelpHover content="thingtype_categories" />
        </div>
        <div class="fx-form-content">
          <select id="select-category" v-model="localThingType.category">
            <option value="thing">
              Komponente
            </option>
            <option value="sub_thing">
              Sub-Komponente
            </option>
            <option value="central_unit">
              Zentrale
            </option>
          </select>
        </div>
      </div>
      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory">Name</label>
        </div>
        <div class="fx-form-content">
          <DbBasedAutocomplete
              id="autocomplete-type-name"
              v-model="localThingType.name"
              :url="`/installation_types/${installationTypeId}/selectable_types`"
              @input="event => localThingType.name = event"
            />
        </div>
      </div>
      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline">Hersteller</label>
        </div>
        <div class="fx-form-content">
          <DbBasedAutocomplete
              id="autocomplete-manufacturer"
              v-model="localThingType.manufacturer"
              :url="`/installation_types/${installationTypeId}/selectable_manufacturers`"
              @input="event => localThingType.manufacturer = event"
            />
        </div>
      </div>
      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline">Modell</label>
        </div>
        <div class="fx-form-content">
          <input id="input-model" v-model="localThingType.model" type="text">
        </div>
      </div>

      <div v-if="currentUser.pro" class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline" for="group_link">Link</label>
        </div>
        <div class="fx-form-help">
          <HelpHover content="links" />
        </div>
        <div class="fx-form-content">
          <input
            id="group_link"
            v-model="localThingType.link"
            class="has-form-field-hint"
            name="group_link"
            type="text"
          >

          <div class="form-field-hint">
            <ExternalLinkButton :href="localThingType.link" /> Link auf eine externe Webseite, Datei oder einen Speicherort.
          </div>
        </div>
      </div>
    </form>
    <hr>
    <input
      id="modal-submit"
      type="submit"
      value="Typ speichern"
      class="fx-btn-primary float-right"
      style="margin-bottom: 0.5rem;"
      @click.prevent="submit"
    >
    <div class="clearfix" />
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';
import { useCurrentUserStore } from 'stores/current_user';
import { ref } from 'vue';

import ServerErrors from 'components/partials/server_errors.vue';
import HelpHover from 'components/help_hover.vue';
import DbBasedAutocomplete from 'components/select/db_based_autocomplete.vue';
import ExternalLinkButton from 'components/external_link_button.vue';

export default {
  name: 'EditThingTypeModal',
  components: {
    ServerErrors,
    DbBasedAutocomplete,
    ExternalLinkButton,
    HelpHover,
  },
  props: {
    installationTypeId: {
      type: String,
      required: true,
    },
    thingType: {
      type: Object,
      default: () => ({
        id: null,
        category: 'thing',
        name: '',
        manufacturer: '',
        model: '',
        link: '',
      }),
    },
  },
  emits: ['created', 'updated', 'close'],
  setup(props) {
    const localThingType = ref({ ...props.thingType });

    return {
      currentUser: useCurrentUserStore().currentUser,
      localThingType,
    };
  },
  data() {
    return {
      serverErrors: [],
      isCreate: true,
    };
  },
  computed: {
    title() {
      if (this.localThingType.id) {
        return 'Typ bearbeiten';
      }
      return 'Typ hinzufügen';
    },
  },
  mounted() {
    // this.localThingType = { ...this.thingType };
    this.isCreate = !this.thingType;
  },
  methods: {
    submit() {
      if (this.localThingType.id) {
        this.updateViaAPI();
      } else {
        this.createViaAPI();
      }
    },
    createViaAPI() {
      const that = this;
      that.serverErrors = [];
      axios.post('/thing_types.json', {
        thing_type: {
          installation_type_id: this.installationTypeId,
          category: this.localThingType.category,
          name: this.localThingType.name,
          manufacturer: this.localThingType.manufacturer,
          model: this.localThingType.model,
          link: this.localThingType.link,
        },
      }).then((response) => {
        Flash.info('Typ hinzugefügt');
        that.$emit('created', { ...response.data.thing_type });
        that.$emit('close');
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.thing_types;
        } else {
          Flash.error('Typ konnte nicht gespeichert werden');
        }
      });
    },
    updateViaAPI() {
      const that = this;
      that.serverErrors = [];
      axios.put(`/thing_types/${this.localThingType.id}.json`, {
        thing_type: {
          installation_type_id: this.installationTypeId,
          category: this.localThingType.category,
          name: this.localThingType.name,
          manufacturer: this.localThingType.manufacturer,
          model: this.localThingType.model,
          link: this.localThingType.link,
        },
      }).then((response) => {
        Flash.info('Typ gespeichert');
        this.$emit('updated', { ...response.data.thing_type });
        that.$emit('close');
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.thing_types;
        } else {
          Flash.error('Typ konnte nicht gespeichert werden');
        }
      });
    },
  },
};
</script>

<style></style>
