<template>
  <div>
    <NavBreadcrumps path="administration/users" />

    <h1 class="fx">
      {{ $t('organizations.users.index.title') }}
    </h1>
    <p>
      {{ $t('organizations.users.index.intro') }}
      <help-article article-id="learn_more_users">
        {{ $t('action.learn_more') }}
      </help-article>
    </p>

    <div class="fx-card">
      <table id="organizations-users-index" class="material">
        <colgroup>
          <col>
          <col>
          <col>
          <col style="width: 180px;">
          <col style="width: 180px;">
        </colgroup>

        <tr class="material__controls">
          <th colspan="5" style="text-align: right; font-weight: normal; font-size: 1rem;">
            <button class="fx-btn-ico-primary" @click="openInviteModal()">
              <i class="far fa-plus" /> Mitarbeiter einladen
            </button>
          </th>
        </tr>

        <tr class="material__title">
          <th>Mitarbeiter</th>
          <th>Nummer</th>
          <th>Berechtigungen</th>
          <th>Status</th>
          <th class="controls_secondary">
            &nbsp;
          </th>
        </tr>

        <tr v-for="user in localUsers" :key="user.id">
          <td>
            <img
              :src="user.icon_path"
              width="30px"
              height="30px"
              style="float: left;"
            >

            <div style="margin-left: 40px;">
              <span>{{ user.name }}</span>
              <span v-if="user.me" class="fx-status me mini" title="Das bist du">
                {{ $t('me') }}
              </span>
              <span v-if="user.otp_enabled" class="fx-status mfa mini" title="Account durch 2FA geschützt">
                2FA
              </span>
              <div class="secondary" style="line-height: 1;">
                {{ user.email }}
              </div>
            </div>
          </td>
          <td>{{ user.number }}</td>
          <td>
            <div v-for="role in user.roles" :key="role" class="fx-tag fx-tag--small fx-tag--secondary" style="white-space: nowrap;">
              {{ $t(`activerecord.attributes.user.roles_values.${role}`) }}
            </div>
          </td>
          <td>
            <div v-if="user.locked">
              <span class="fx-status alert">gesperrt</span>
            </div>
            <div v-else>
              <span class="fx-status" :class="user.status">{{ $t(`activerecord.attributes.user.statuses.${user.status}`) }}</span>
            </div>
            <div v-if="showResendInvite(user)" class="mt-2">
              <button class="fx-btn-skeleton" @click="resendInvite(user)">
                erneut einladen
              </button>
            </div>
          </td>
          <td style="text-align: right;">
            <div class="fx-btn-group">
              <a class="fx-btn-skeleton" :href="`/users/${user.id}`">bearbeiten</a>
              <drop-down>
                <drop-down-item>
                  <a v-if="!currentUserRecord(user)" @click="confirmDeleteUser(user)">
                    <i class="far fa-trash-alt fa-fw" /> Löschen
                  </a>
                  <span v-if="currentUserRecord(user)" class="secondary">
                    <i class="far fa-trash-alt fa-fw" /> Löschen
                  </span>
                </drop-down-item>
              </drop-down>
          </div>
          </td>
        </tr>
      </table>
    </div>

    <SecurityPolicy :organization-id="organizationId" />
    <SupportAccess :organization-id="organizationId" />
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';

import { ref } from 'vue';
import { useCurrentUserStore } from 'stores/current_user';
import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';

import NavBreadcrumps from 'components/nav_breadcrumps.vue';
import confirmViaModal from 'helpers/confirm_modal';

import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';

import SecurityPolicy from '../security_policy.vue';
import InviteModal from '../invite_modal.vue';
import SupportAccess from '../support_access.vue';

export default {
  name: 'UsersIndex',
  components: {
    NavBreadcrumps,
    SecurityPolicy,
    SupportAccess,
    DropDown,
    DropDownItem,
  },
  props: {
    organizationId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const localUsers = ref([]);

    const loadUsers = () => {
      axios.get(`/organizations/${props.organizationId}/users.json`)
        .then((response) => {
          localUsers.value = response.data.users;
        })
        .catch((error) => {
          Flash.error(error);
        });
    };

    const deleteUser = (user) => {
      axios.delete(`/organizations/${props.organizationId}/users/${user.id}.json`)
        .then(() => {
          Flash.info('Nutzer gelöscht');
          loadUsers();
        })
        .catch((error) => {
          Flash.error(error);
        });
    };

    const confirmDeleteUser = (user) => {
      confirmViaModal({
        title: `Nutzer '${user.name}' löschen?`,
        message: 'Diese Aktion kann nicht rückgängig gemacht werden.',
        requireInputConfirmation: true,
      }).then(() => {
        deleteUser(user);
      });
    };

    const resendInvite = (user) => {
      axios.post(`/organizations/${props.organizationId}/users/${user.id}/resend_invite.json`)
        .then(() => {
          Flash.info('Einladung erneut versendet');
        })
        .catch((error) => {
          Flash.error(error);
        });
    };

    const showResendInvite = (user) => (user.status === 'invited') || (user.status === 'invitation_expired');

    const openInviteModal = () => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: InviteModal,
            attrs: {
              onClose() {
                modalInstance.close();
              },
              onInvited() {
                loadUsers();
                modalInstance.close();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const currentUserRecord = (user) => (useCurrentUserStore().currentUser.id === user.id);

    return {
      currentUser: useCurrentUserStore().currentUser,
      confirmDeleteUser,
      localUsers,
      loadUsers,
      openInviteModal,
      resendInvite,
      showResendInvite,
      currentUserRecord,
    };
  },
  computed: {
  },
  mounted() {
    this.loadUsers();
  },
};
</script>
