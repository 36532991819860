<template>
  <span>
    <div class="fx-filter">
      <OnClickOutside @trigger="close">
        <button type="button" class="fx-filter-btn" @click="toggleOpen">
          <div class="buttonLabel" :class="classForLabel">
            <i class="fx-select-icon" :class="icon(iconId)" />&nbsp;
            <template v-if="selection.length === 0">
              {{ $t(`comp.filter.${i18nBaseKey}.default-label`) }}
            </template>
            <template v-else>
              {{ $t(`comp.filter.${i18nBaseKey}.options.${selection[0]}`) }}
            </template>
            <span class="caret" />
          </div>
        </button>

        <div class="fx-filter-dropdown" :class="{ 'show': isOpen }" style="padding: 5px 0;">
          <div
            style="border-bottom: 1px solid #ddd; padding: 5px 15px; font-size: 12px; font-weight:
            bold;"
          >{{ $t(`comp.filter.${i18nBaseKey}.title`) }}</div>

          <div style="max-height: 400px; overflow-y:scroll">
            <div class="fx-filter-item">
              <a @click.exact="resetAndEmit"><i>{{ $t(`comp.filter.${i18nBaseKey}.default-label`) }}</i></a>
            </div>
            <div v-for="item in options" :key="item.id" class="fx-filter-item">
              <template v-if="isMultiSelect">
                <div class="fx-filter-item-multiselect">
                  <input
                    :id="item.id"
                    type="checkbox"
                    :checked="inSelection(item)"
                    @click="toggleMultiSelect(item)"
                  >
                  <label :for="item.id">{{ $t(`comp.filter.${i18nBaseKey}.options.${item.id}`) }}</label>
                </div>
              </template>
              <template v-else>
                <a :class="classForItem(item)" @click.exact="selectAndEmit(item)"><i
                  v-if="item.color"
                  class="fas fa-square fa-fw"
                  :style="`margin-right: 4px; color: ${item.color}`"
                />{{
                  $t(`comp.filter.${i18nBaseKey}.options.${item.id}`) }}
                </a>
              </template>
            </div>
          </div>
        </div>
      </OnClickOutside>
    </div>
  </span>
</template>

<script>
import _ from 'lodash';
import foxtagIcons from 'initializers/icons';
import { OnClickOutside } from '@vueuse/components';
import { useFilterDropdown } from 'apps/application/filter_dropdown_logic';

export default {
  name: 'FilterStaticOptions',
  components: {
    OnClickOutside,
  },
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    iconId: {
      type: String,
      default: null,
    },
    allowMultiSelect: {
      type: Boolean,
      default: false,
    },
    i18nBaseKey: {
      type: String,
      required: true,
    },
    initOptions: {
      type: Array,
      required: true,
    },
  },
  emits: ['changed'],
  setup(props, { emit }) {
    const {
      isOpen, isMultiSelect, options, selection, label,
      classForItem, classForLabel, select, toggleMultiSelect, resetSelection, inSelection, inOptions, toggleOpen, close,
    } = useFilterDropdown(props.defaultLabel, emit);
    return {
      isOpen,
      isMultiSelect,
      options,
      selection,
      label,
      classForLabel,
      classForItem,
      select,
      toggleMultiSelect,
      resetSelection,
      inSelection,
      inOptions,
      toggleOpen,
      close,
    };
  },
  data() {
    return {
      isAnd: false,
    };
  },
  watch: {
    modelValue: {
      handler(newVal) {
        this.initFromModelValue(newVal);
      },
      deep: true,
    },
  },
  created() {
    this.options = this.initOptions;
    this.initFromModelValue(this.modelValue);
  },
  methods: {
    initFromModelValue(newVal) {
      if (newVal == null || newVal === '') {
        this.selection = [];
      } else {
        this.selection = newVal.split('|');
      }
    },
    resetAndEmit() {
      this.resetSelection();
      this.emitSelection();
    },
    selectAndEmit(item) {
      this.select(item);
      this.emitSelection();
    },
    icon(id) {
      return foxtagIcons[id];
    },
    emitSelection() {
      this.$emit('changed', _.join(this.selection, '|'));
    },
  },
};
</script>
