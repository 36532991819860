<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <img
      src="/Detectortesters-logo.png"
      alt="detectortesters"
      style="width: 100%; max-width: 250px; margin-bottom: 20px;"
    >
    <h2 class="fx">
      Bericht aus Testifire®-Cloud laden
    </h2>
    <p>
      Lädt den Bericht für diesen Auftrag aus der Testifire®-Cloud und speichert ihn als zusätzliche Datei an diesem
      Auftrag.
    </p>
    <button v-if="!loading" class="fx-btn-ico-primary" @click="loadReport()">
      <i class="fas fa-cloud-download" />
      Bericht
      laden
    </button>
    <template v-if="loading">
      <i class="fas fa-spinner fa-spin" /> Download läuft ...
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';
import { useCurrentUserStore } from 'stores/current_user';

export default {
  name: 'DetectorTestersModal',
  props: {
    jobId: {
      type: String,
      required: true,
    },
  },
  emits: ['downloaded', 'close'],
  setup() {
    return {
      currentUser: useCurrentUserStore().currentUser,
    };
  },
  data() {
    return {
      title: 'Dateien hochladen',
      loading: false,
    };
  },
  methods: {
    fakeDownload() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        Flash.info('Testifire®-Bericht wurde geladen.');
        this.$emit('downloaded');
      }, 4000);
    },
    loadReport() {
      axios.post(`/jobs/${this.jobId}/job_attachments/load_detector_testers_report`)
        .then(() => {
          this.fakeDownload();
        })
        .catch(() => {
          Flash.error('Testifire®-Bericht konnte nicht geladen werden.');
          this.$emit('downloaded');
        });
    },
  },
};
</script>
