<template>
  <div v-if="localInstallationType">
    <section class="fx-page-content">
      <div class="fx-sidebar-layout-infobar">
        <h2 class="fx">
          <i class="fas fa-cog" /> Administration
        </h2>
      </div>
      <section class="fx-header">
        <div class="fx-grid">
          <div class="fx-col" style="padding: 0 1rem;">
            <NavBreadcrumps path="administration/installation-types/current" :current-label="localInstallationType.name" />

            <div class="fx-header-title">
              <h1 class="fx-header-title">
                <span class="fx-header-title_number">{{ localInstallationType.number }}</span>
                <span class="fx-header-title_name">{{ localInstallationType.name }}</span>
              </h1>
            </div>

            <button id="tab-data" :class="$tabClasses('tab-data')" @click="$setActiveTab('tab-data');">
              <Icon id="installation_type" /> Basisdaten
            </button>
            <button id="tab-custom-dataset" :class="$tabClasses('tab-custom-dataset')" @click="$setActiveTab('tab-custom-dataset');">
              <Icon id="custom_data" /> Stammdaten
            </button>
            <button id="tab-thing-types" :class="$tabClasses('tab-thing-types')" @click="$setActiveTab('tab-thing-types');">
              <Icon id="thing_type" /> Typen
            </button>
            <button
              id="tab-intervals"
              :class="$tabClasses('tab-intervals')"
              @click="$setActiveTab('tab-intervals');"
            >
            <Icon id="interval" /> Intervalle
            </button>
            <button id="tab-checklists" :class="$tabClasses('tab-checklists')" @click="$setActiveTab('tab-checklists');">
              <Icon id="checklist" /> Checklisten
            </button>
            <button id="tab-incident-types" :class="$tabClasses('tab-incident-types')" @click="$setActiveTab('tab-incident-types');">
              <Icon id="incident_type" /> Störungstypen
            </button>
            <button id="tab-job-types" :class="$tabClasses('tab-job-types')" @click="$setActiveTab('tab-job-types');">
              <Icon id="job" /> Auftragstypen
            </button>
          </div>
        </div>
      </section>

      <section class="list-content">
        <div class="fx-grid" style="margin-top: 0rem;">
          <div class="fx-col">
            <div
              v-if="$isTabInitialized('tab-data')"
              v-show="$isTabActive('tab-data')"
              class="tab-panel"
            >
              <TabData :installation-type="localInstallationType" />
            </div>

            <div v-if="$isTabInitialized('tab-custom-dataset')" v-show="$isTabActive('tab-custom-dataset')" class="tab-panel">
              <CustomDatasetIndex :installation-type-id="localInstallationType.id" />
            </div>

            <div
              v-if="$isTabInitialized('tab-thing-types')"
              v-show="$isTabActive('tab-thing-types')"
              class="tab-panel"
            >
              <ThingTypesIndex :installation-type-id="localInstallationType.id" />
            </div>

            <div
              v-if="$isTabInitialized('tab-intervals')"
              v-show="$isTabActive('tab-intervals')"
              class="tab-panel"
            >
            <IntervalsIndex :installation-type-id="localInstallationType.id" />
            </div>

            <div v-if="$isTabInitialized('tab-checklists')" v-show="$isTabActive('tab-checklists')" class="tab-panel">
              <ChecklistsIndex :installation-type-id="localInstallationType.id" />
            </div>

            <div v-if="$isTabInitialized('tab-incident-types')" v-show="$isTabActive('tab-incident-types')" class="tab-panel">
              <IncidentTypesIndex :installation-type-id="localInstallationType.id" />
            </div>

            <div v-if="$isTabInitialized('tab-job-types')" v-show="$isTabActive('tab-job-types')" class="tab-panel">
              <JobTypesIndex :installation-type-id="localInstallationType.id" />
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>
<script>
import axios from 'axios';
import Flash from 'flash/index';
import { useCurrentUserStore } from 'stores/current_user';
import { ref } from 'vue';
import { TruncateFormatter, DateFormatter } from 'mixins/formatters';
import NavBreadcrumps from 'components/nav_breadcrumps.vue';
import TabData from 'apps/installation_types/show/tab_data.vue';
import ThingTypesIndex from 'apps/installation_types/thing_types/index.vue';
import IncidentTypesIndex from 'apps/installation_types/incident_types/index.vue';
import JobTypesIndex from 'apps/installation_types/job_types/index/index.vue';
import CustomDatasetIndex from 'apps/installation_types/custom_dataset/index.vue';
import ChecklistsIndex from 'apps/installation_types/checklists/index/index.vue';
import IntervalsIndex from 'apps/installation_types/intervals/index/index.vue';

export default {
  name: 'InstallationTypeShow',
  components: {
    NavBreadcrumps,
    TabData,
    ThingTypesIndex,
    IncidentTypesIndex,
    JobTypesIndex,
    CustomDatasetIndex,
    ChecklistsIndex,
    IntervalsIndex,
  },
  mixins: [TruncateFormatter, DateFormatter],
  props: {
    installationTypeId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const activeTab = ref(null);
    const initializedTabs = ref(new Set());
    const localInstallationType = ref(null);

    const loadInstallationType = () => {
      axios.get(`/installation_types/${props.installationTypeId}.json`)
        .then((response) => {
          localInstallationType.value = response.data.installation_type;
        }).catch(() => {
          Flash.error('Wartungsanwendung konnte nicht geladen werden');
        });
    };

    return {
      loadInstallationType,
      currentUser: useCurrentUserStore().currentUser,
      activeTab,
      initializedTabs,
      localInstallationType,
    };
  },
  mounted() {
    this.$setActiveTabFromURL('tab-data');
    this.loadInstallationType();
  },
};
</script>
