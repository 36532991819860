<template>
  <div>
    <div class="fx-control-bar">
      <button id="return" class="fx-btn-secondary" style="padding: .5rem .5rem .4rem .5rem; margin-right: .8rem;" @click.prevent="$emit('back','active')">
        <i class="fas fa-arrow-left" />
      </button>
      <h3 class="fx">
        Außerbetriebnahme-Historie
      </h3>
      <div class="fx-control-bar-buttons">
        <drop-down classes="fx-btn-secondary">
          <drop-down-item>
            <a @click.prevent="$emit('back','active')"><i class="far fa-cube fa-fw" /> Aktive Komponenten ansehen</a>
          </drop-down-item>
          <drop-down-item v-if="hasStock">
            <a @click.prevent="$emit('back','stocked')"><i class="far fa-warehouse-alt fa-fw" /> Lager ansehen</a>
          </drop-down-item>
        </drop-down>

        <button
          v-tippy="{ placement: 'top', duration: 100, arrow: true }"
          class="fx-btn-secondary"
          title="Liste neu laden"
          @click.prevent="search()"
        >
          <i class="fas fa-sync-alt" />
        </button>
      </div>
    </div>
    <div class="fx-card group-header" style="margin-top: 1rem;">
      <div style="background-color: rgb(242, 242, 242); padding: 1rem;">
        <input
          id="filter-string"
          v-model="filter.query"
          type="text"
          style="margin: 0; width: 340px; display: inline-block;"
          placeholder="Nach Foxtag-ID oder Seriennummer suchen"
        >
        <a id="reset-search" style="font-size: .8rem; line-height: 2.4rem;" @click.prevent="resetSearch()">&nbsp;zurücksetzen</a>
      </div>

      <table id="out-of-order-things-table" class="material" style="table-layout:fixed;width:100%;">
        <tr v-if="!loading && localDeployments.length > 0" class="material__title">
          <th style="width: 12%">
            Datum
          </th>
          <th style="width: 18%">
            Grund
          </th>
          <th style="width: 15%">
            Nr. / Standort
          </th>
          <th style="width: 15%">
            Typ
          </th>
          <th>Details</th>
          <th class="no-print" style="width: 15%; text-align: right;">
&nbsp;
          </th>
        </tr>

        <tr v-for="deployment in localDeployments" :key="deployment.id">
          <td>
            <strong>{{ formatDate(deployment.removed_at, 'DD.MM.YYYY') }}</strong>
            <div class="secondary">
              {{ $t(`activerecord.attributes.thing.deployments.removed_types.${deployment.removed_type}`) }}
            </div>
          </td>
          <td>{{ deployment.removed_reason }}</td>
          <td>
            <div>{{ deployment.removed_full_number }}</div>
            <div>{{ deployment.position }}</div>
          </td>
          <td>
            <div class="fx_thing_type_with_icon">
              {{ deployment.thing.thing_type.name }}
              <div class="secondary">
                {{ thingMake(deployment.thing) }}
              </div>
            </div>
          </td>
          <td>
            <div>
              <span
                v-for="(feature, index) in deployment.thing.assigned_features"
                :key="index"
              ><span
                class="fx-label mb-2"
                style="background-color: #2C556E; color: #fff;"
              >{{ feature.symbol }}</span>&shy;
              </span>
            </div>
            <ThingDetails :thing="deployment.thing" :tag-label="tagLabel" />
          </td>
          <td style="text-align: right;">
            <div class="fx-btn-group">
              <a class="fx-btn-skeleton" :href="thingUrl(deployment.thing)">details</a>
              <drop-down>
                <drop-down-item>
                  <a @click.prevent="confirmDeleteDeployment(deployment)">
                    <i class="far fa-trash-alt fa-fw" /> Komponente löschen
                  </a>
                </drop-down-item>
              </drop-down>
            </div>
          </td>
        </tr>
        <tr>
          <td v-if="pagination.totalCount === 0" colspan="6" style="text-align: center;">
            Keine Komponenten gefunden
          </td>
        </tr>
      </table>
    </div>
    <Pagination
      v-model="pagination"
      i18n-key="activerecord.models.thing"
      :init-from-url="false"
      :update-url="false"
      @navigate="loadPage"
    />
  </div>
</template>

<script>
/* eslint-disable no-undef */
import _ from 'lodash';
import axios from 'axios';
import Flash from 'flash/index';

import { DateFormatter } from 'mixins/formatters';
import { ref, watch } from 'vue';
import EntityList from 'helpers/entity_list';
import confirmViaModal from 'helpers/confirm_modal';
import Pagination from 'components/pagination.vue';

import ThingDetails from 'components/partials/thing_details.vue';
import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';

export default {
  name: 'OutOfOrderThing',
  components: {
    Pagination,
    ThingDetails,
    DropDownItem,
    DropDown,
  },
  mixins: [DateFormatter],
  props: {
    installationId: {
      type: String,
      required: true,
    },
    tagLabel: {
      type: String,
      default: 'Foxtag-ID',
    },
    hasStock: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['back'],
  setup(props) {
    const localDeployments = new EntityList();
    const filter = ref({
      query: null,
    });
    const pagination = ref({
      page: 1,
      pageCount: 1,
      totalCount: 0,
      limit: 25,
    });

    const load = () => {
      axios.get(`/installations/${props.installationId}/out_of_order`, {
        params: {
          query: filter.value.query,
          page: pagination.value.page,
          limit: pagination.value.limit,
        },
      }).then((response) => {
        localDeployments.value = response.data.deployments;
        pagination.value.page = response.data.meta.current_page;
        pagination.value.pageCount = response.data.meta.total_pages;
        pagination.value.totalCount = response.data.meta.total_count;
      }).catch((err) => {
        if (err.message !== 'Request aborted') {
          Flash.error(err.message);
        }
      });
    };

    const loadPage = (changedPagination) => {
      pagination.value = { ...changedPagination };
      load();
    };

    const search = () => {
      localDeployments.value = [];
      pagination.value.page = 1;
      load();
    };

    const resetSearch = () => {
      filter.value = {
        query: null,
      };
    };

    const debouncedSearch = _.debounce(() => {
      search();
    }, 250);

    watch(filter, () => {
      debouncedSearch();
    }, { deep: true });

    const deleteViaAPI = (deployment) => {
      axios.delete(`/things/${deployment.thing.id}`).then(() => {
        // TODO: we're removing a single deployment, but we've deleted the thing -> maybe it has multiple deployments in this list?
        localDeployments.remove(deployment);
        Flash.info('Komponente gelöscht');
      }).catch(() => {
        Flash.error('Komponente konnte nicht gelöscht werden');
      });
    };

    const confirmDeleteDeployment = (deployment) => {
      confirmViaModal({
        title: 'Komponente löschen?',
        message: 'Löscht die Komponente und alle verbundenen Daten aus dem System.',
      }).then(() => {
        deleteViaAPI(deployment);
      });
    };

    return {
      localDeployments: localDeployments.reactiveList,
      confirmDeleteDeployment,
      pagination,
      filter,
      loadPage,
      search,
      resetSearch,
    };
  },
  watch: {
    query: {
      handler() {
        this.debouncedSearch();
      },
    },
  },
  methods: {
    thingMake(thing) {
      return [thing.thing_type.manufacturer, thing.thing_type.model].join(' ');
    },
    thingUrl(thing) {
      return `/things/${thing.id}`;
    },
  },
};
</script>
