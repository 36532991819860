<template>
  <div class="fx-modal-content-small">
    <div class="text-center">
      <i class="fas fa-circle-notch fa-spin fa-2x secondary" />
      <h3 class="fx">
        {{ title }}
      </h3>
      <p v-if="message" class="secondary">
        {{ message }}
      </p>
      <button v-if="showCloseButton" class="fx-btn-secondary float-right" @click.prevent="cancelled()">
        Nicht weiter warten
      </button>
    </div>
    <div class="clearfix" />
  </div>
</template>

<script>

import axios from 'axios';

export default {
  name: 'WaitForAsyncModal',
  props: {
    title: {
      type: String,
      default: 'Aktion wird ausgeführt',
    },
    message: {
      type: String,
      default: 'Diese Aktion kann einige Minuten dauern.',
    },
    action: {
      type: String,
      default: 'Ok',
    },
    fixTime: {
      type: Number,
      default: 0,
    },
    pollingSidekiqMillis: {
      type: Number,
      default: 3000,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    maxPollingTries: {
      type: Number,
      default: 50,
    },
    sidekiqJobId: {
      type: String,
      default: null,
    },
  },
  emits: ['close', 'finished'],
  data() {
    return {
      pollingCount: 0,
    };
  },
  computed: {
    disabledSubmit() {
      return (this.requireInputConfirmation && this.confirmationInput !== 'löschen');
    },
    additionalClasses() {
      if (this.redButton) {
        return 'fx-btn-red';
      }
      return '';
    },
  },
  mounted() {
    if (this.fixTime && this.fixTime > 0) {
      this.finishAfterFixedTime();
    } else if (this.sidekiqJobId) {
      this.pollSidekiqStatus();
    }
  },
  unmounted() {
    if (this.pollingTimeout) {
      clearTimeout(this.pollingTimeout);
    }
  },
  methods: {
    finishAfterFixedTime() {
      this.pollingTimeout = setTimeout(() => {
        this.finished();
      }, this.fixTime);
    },
    pollSidekiqStatus() {
      this.pollingCount += 1;
      if (this.pollingCount > this.maxPollingTries) {
        this.cancelled();
        return;
      }

      this.pollingTimeout = setTimeout(() => {
        const that = this;
        axios.get('/worker_status', {
          params: {
            job_id: this.sidekiqJobId,
          },
        }).then((response) => {
          if (response.status === 200) {
            that.finished();
          } else {
            that.pollSidekiqStatus();
          }
        }).catch(() => {
          that.cancelled();
        });
      }, this.pollingSidekiqMillis);
    },
    cancelled() {
      this.$emit('close');
    },
    finished() {
      this.$emit('finished');
    },
  },
};
</script>
