<template>
  <div class="fx-modal-content-medium">
    <h2 class="fx">
      Auftragsdatum bearbeiten
    </h2>
    <div style="min-height: 120px;">
      <ServerErrors v-model="serverErrors" title="Änderung konnte nicht gespeichert werden" margin-bottom="0" />
      <label for="job_{:class=>&quot;inline mandatory&quot;}">Datum<span style="color: red"> *</span> / Uhrzeit</label>
      <div class="mt-2">
        <StartEnddateForm
          :init-start-date="localJob.planned_at"
          :init-end-date="localJob.enddate"
          :init-all-day="localJob.all_day"
          @changed="jobDurationChanged"
        />
      </div>
    </div>
    <hr class="fx">
    <div style="float:right;">
      <div class="fx-btn-group">
        <button
          class="fx-btn-primary float-right"
          style="margin-left: 0.5rem;"
          @click.prevent="updateJob()"
        >
          Speichern
        </button>

        <button class="fx-btn-secondary float-right" @click.prevent="cancel()">
          Abbrechen
        </button>
      </div>
      <div class="clearfix" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';
import { ref } from 'vue';
import StartEnddateForm from 'components/start_enddate_form.vue';
import ServerErrors from 'components/partials/server_errors.vue';

export default {
  name: 'EditJobDateModal',
  components: {
    StartEnddateForm,
    ServerErrors,
  },
  props: {
    job: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'updated'],
  setup(props, { emit }) {
    const localJob = ref(JSON.parse(JSON.stringify(props.job)));
    const serverErrors = ref([]);

    const cancel = () => {
      emit('close');
    };

    const jobDurationChanged = (duration) => {
      localJob.value.planned_at = duration.startDate;
      localJob.value.enddate = duration.endDate;
      localJob.value.all_day = duration.allDay;
    };

    const updateJob = () => {
      axios.put(`/jobs/${localJob.value.id}`, {
        job: {
          all_day: localJob.value.all_day,
          planned_at: localJob.value.planned_at,
          enddate: localJob.value.enddate,
        },
      }).then((response) => {
        emit('updated', response.data.job);
        emit('close');
      }).catch((error) => {
        if (error.response.status === 400) {
          serverErrors.value = error.response.data.jobs;
        } else {
          Flash.error('Auftrag konnte nicht gespeichert werden');
        }
      });
    };

    return {
      cancel,
      localJob,
      serverErrors,
      jobDurationChanged,
      updateJob,
    };
  },
};
</script>
