<template>
  <div>
    <!-- <ExportsModal modal-name="export-year-progress" /> -->
    <div style="display: flex; justify-content: space-between; margin-top: 0rem;">
      <h1 class="fx" style="flex: 1 1 0%;">
&nbsp;
      </h1>
      <div class="fx-btn-group">
        <button class="fx-btn-ico-primary" :disabled="!canEdit" @click="newJob()">
          <i class="fas fa-plus" /> Auftrag planen
        </button>

        <button
          v-tippy="{ placement: 'top', duration: 100, arrow: true }"
          class="fx-btn-secondary"
          title="Liste neu laden"
          @click="reset()"
        >
          <i class="fas fa-sync-alt" />
        </button>
      </div>
    </div>

    <div class="fx-card">
      <JobList
        :key="jobListKey"
        :query-path="installationJobsPath(installationId)"
        :limit="25"
        :allow-editing="canEdit"
        @changed="$emit('changed')"
      >
        <template #empty-state>
          <div class="fx-list-empty-state">
            Keine Aufträge
          </div>
        </template>
      </JobList>
    </div>

    <div class="callout callout--info" style="margin-top: 1rem;">
      <strong>Historische Prüfergebnisse als externer Auftrag einpflegen</strong>
      <p style="margin-bottom: .2rem;">
        Mit der Funktion "als extern beenden" können Sie Aufträge und Prüfungen einpflegen, die Sie vor der Nutzung
        von Foxtag durchgeführt haben.
      </p>
      <help-article article-id="learn_more_external_jobs">
        mehr erfahren
      </help-article>
    </div>
  </div>
</template>

<script>
import FoxtagPaths from 'mixins/foxtag_paths';
import JobList from 'apps/dashboard/job_list.vue';

import openNewJobModal from 'helpers/open_new_job_modal';

export default {
  name: 'JobsTab',
  components: {
    JobList,
  },
  mixins: [FoxtagPaths],
  props: {
    installationId: {
      type: String,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['changed'],
  setup() {
    return {
      openNewJobModal,
    };
  },
  data() {
    return {
      jobListKey: 0,
    };
  },
  methods: {
    goToNewJob() {
      window.location.href = this.jobForInstallationPath(this.installationId);
    },
    reset() {
      this.jobListKey += 1;
    },
    newJob() {
      openNewJobModal(this.installationId).then(() => {
        this.reset();
        this.$emit('changed');
      });
    },
  },
};
</script>
