<template>
  <li class="fx-sidebar" :class="classes">
    <span v-if="disabled" class="disabled" style="font-size: .9rem;"><i
      :class="iconClass"
      style="margin-right: .4rem;"
    />{{
      $t(labelKey) }}</span>
    <a v-else :href="href">
      <i :class="iconClass" /> {{ $t(labelKey) }}
    </a>
  </li>
</template>
<script>
import foxtagIcons from 'initializers/icons';

export default {
  props: {
    activeKey: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: null,
    },
    labelKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    iconClass() {
      return `${foxtagIcons[this.icon]} fa-fw`;
    },
    classes() {
      if (this.activeKey === this.labelKey.split('.').pop()) {
        return 'fx-sidebar--selected';
      }
      return '';
    },
  },
};
</script>
