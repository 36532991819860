<template>
  <div>
    <div class="restricted-width">
      <NavBreadcrumps path="administration/labels" />
      <h1 class="fx">
        Labels
      </h1>
    </div>
    <div class="fx-card">
      <div class="fx-list-row" style="background-color: #f2f2f2;">
        <select v-model="labelType" style="width: 250px; margin: 0.5rem;" @change="search()">
          <option value="installation">
            Labels für Anlagen
          </option>
          <option value="thing">
            Labels für Komponenten
          </option>
          <option value="customer">
            Labels für Kunden
          </option>
          <option value="job">
            Labels für Aufträge
          </option>
          <option value="incident">
            Labels für Störungen
          </option>
        </select>

        <div v-if="canEditLabelsOfCurrentType" class="fx-single-control-col">
          <button class="fx-btn-primary" @click="openEditLabelModal()">
            <i class="fas fa-plus" /> Label hinzufügen
          </button>
        </div>
        <br clear="both">
      </div>

      <div v-for="label in localLabels" :key="label.id" class="fx-list-row">
        <div v-if="canEditLabelsOfCurrentType" class="fx-single-control-col">
          <div class="fx-btn-group">
            <button
              class="fx-btn-skeleton"
              @click="openEditLabelModal(label)"
            >
              bearbeiten
            </button>
            <ButtonDeleteIcon @click="confirmDeleteLabel(label)" />
          </div>
        </div>

        <div class="fx-550px-col p-0">
          <span class="fx-label-large" :style="styleForLabel(label)">{{ label.name }}</span>
          <span style="margin-left: 3rem;">
            {{ label.description }}
          </span>
        </div>

        <div class="fx-100px-col p-0" style="white-space: nowrap;">
          <span v-if="label.show_in_app" title="wird in der App angezeigt" style="margin-right: .3rem;"><i
            class="fas fa-mobile-alt"
          /> App</span>
          <span v-if="label.show_in_portal" title="wird im Portal angezeigt"><i class="far fa-globe" /> Portal</span>
        </div>
      </div>

      <slot v-if="showNoResults" name="empty-state">
        <div class="fx-list-empty-state">
          keine Labels
        </div>
      </slot>

      <div v-if="!canEditLabelsOfCurrentType" class="p-4">
        <ProFeatureCallout>
          Wenn Sie auf <strong>Foxtag Pro</strong> wechseln, können Sie an dieser Stelle eigene Labels anlegen.
        </ProFeatureCallout>
      </div>
    </div>
    <Pagination v-model="pagination" i18n-key="activerecord.models.label" @navigate="loadPage" />
  </div>
</template>

<script>
import axios from 'axios';

import { ref } from 'vue';
import Flash from 'flash';
import confirmViaModal from 'helpers/confirm_modal';
import NavBreadcrumps from 'components/nav_breadcrumps.vue';
import EntityList from 'helpers/entity_list';
import ButtonDeleteIcon from 'components/button_delete_icon.vue';
import Pagination from 'components/pagination.vue';

import FeatureFlipper from 'mixins/feature_flipper';
import ProFeatureCallout from 'components/pro_feature_callout.vue';
import { useCurrentUserStore } from 'stores/current_user';
import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';
import EditLabelModal from 'apps/labels/index/edit_label_modal.vue';

export default {
  name: 'LabelsIndex',
  components: {
    ProFeatureCallout,
    ButtonDeleteIcon,
    NavBreadcrumps,
    Pagination,
  },
  mixins: [FeatureFlipper],
  props: {
    errorMessage: {
      type: String,
      default: 'Die Labels konnten nicht geladen werden.',
    },
  },
  setup() {
    const currentUser = useCurrentUserStore().currentUser;
    const localLabels = new EntityList();
    const labelType = ref('installation');

    const pagination = ref({
      page: 1,
      pageCount: 1,
      totalCount: 0,
      limit: 25,
    });

    const load = () => {
      axios.get('/labels', {
        params: {
          label_type: labelType.value,
          page: pagination.value.page,
          limit: pagination.value.limit,
        },
      }).then((response) => {
        localLabels.value = response.data.labels;
        pagination.value.page = response.data.meta.current_page;
        pagination.value.pageCount = response.data.meta.total_pages;
        pagination.value.totalCount = response.data.meta.total_count;
      }).catch((err) => {
        if (err.message !== 'Request aborted') {
          Flash.error(err.message);
        }
      });
    };

    const loadPage = (changedPagination) => {
      pagination.value = { ...changedPagination };
      load();
    };

    const search = () => {
      localLabels.value = [];
      pagination.value.page = 1;
      load();
    };

    const deleteViaAPI = (label) => {
      axios.delete(`/labels/${label.id}`).then(() => {
        Flash.info('Label gelöscht');
        localLabels.remove(label);
        pagination.value.totalCount -= 1;
      }).catch(() => {
        Flash.error('Label konnte nicht gelöscht werden');
      });
    };

    const openEditLabelModal = (label) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: EditLabelModal,
            attrs: {
              labelType: labelType.value,
              labelPreset: label,
              onClose() {
                modalInstance.close();
              },
              onCreated(newLabel) {
                modalInstance.close();
                localLabels.append(newLabel);
                pagination.value.totalCount += 1;
              },
              onUpdated(updatedLabel) {
                modalInstance.close();
                localLabels.update(updatedLabel);
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const confirmDeleteLabel = (label) => {
      confirmViaModal({
        title: `Label ${label.name} löschen?`,
        action: 'Löschen',
        message: 'Diese Aktion entfernt das Label von allen Stellen, an denen es benutzt wird und löscht es.',
      }).then(() => {
        deleteViaAPI(label);
      });
    };

    return {
      currentUser,
      localLabels: localLabels.reactiveList,
      labelType,
      pagination,
      confirmDeleteLabel,
      openEditLabelModal,
      loadPage,
      search,
    };
  },
  computed: {
    showNoResults() {
      return this.localLabels.length === 0;
    },
    canEditLabelsOfCurrentType() {
      return (this.labelType === 'installation'
        || this.labelType === 'thing'
        || this.labelType === 'customer'
        || this.currentUser.pro);
    },
  },
  mounted() {
    const hash = window.location.hash.substr(1);
    if (hash) {
      this.labelType = hash;
    }
    this.search();
  },
  methods: {
    styleForLabel(label) {
      return `color: ${label.text_color}; background-color: ${label.bg_color};`;
    },
  },
};
</script>
