<template>
  <div>
    <div class="repco_preview">
      <template v-if="showForm">
        <div style="background-color: #eee; min-height: 16rem; border-radius: 8px; padding: 1rem;">
          <h3 class="fx">
            {{ name }}
          </h3>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline mandatory" for="customer_name">Überschrift</label>
            </div>
            <div class="fx-form-content">
              <input v-model="localSection.title" maxlength="80" type="text">
            </div>
          </div>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label for="job_type_report_success_label" class="inline ">Positives
                Prüfergebnis</label>
            </div>
            <div class="fx-form-content">
              <input
                id="job_type_report_success_label"
                v-model="localSection.success_label"
                type="text"
                placeholder="OK"
                style="max-width: 200px; display: inline-block;"
                name="job_type[report_success_label]"
                class="has-form-field-hint"
                maxlength="10"
              >&nbsp;&nbsp;&nbsp;
              <input
                id="success-color-black"
                v-model="localSection.success_color"
                value="000000"
                type="radio"
                name="success-color"
              ><label for="success-color-black" class="inline">schwarz</label>
              <input
                id="success-color-green"
                v-model="localSection.success_color"
                value="008000"
                type="radio"
                name="success-color"
              ><label
                for="success-color-green"
                class="inline"
                style="color: green;"
              >grün</label>
              <div class="form-field-hint">
                Wird als Ergebnis abgedruckt, wenn Sie eine <strong>Prüfung mit OK</strong>
                dokumentieren. Max. 10 Zeichen.
              </div>
            </div>
          </div>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label for="job_type_report_failed_label" class="inline ">Negatives
                Prüfergebnis</label>
            </div>
            <div class="fx-form-content">
              <input
                id="job_type_report_failed_label"
                v-model="localSection.failed_label"
                type="text"
                placeholder="Mangel"
                style="max-width: 200px; display: inline-block;"
                name="job_type[report_failed_label]"
                class="has-form-field-hint"
                maxlength="10"
              >&nbsp;&nbsp;&nbsp;
              <input
                id="failed-color-black"
                v-model="localSection.failed_color"
                value="000000"
                type="radio"
                name="failed-color"
              ><label for="failed-color-black" class="inline">schwarz</label>
              <input
                id="failed-color-red"
                v-model="localSection.failed_color"
                value="ba1b33"
                type="radio"
                name="failed-color"
              ><label for="failed-color-red" class="inline" style="color: red;">rot</label>
              <div class="form-field-hint">
                Wird als Ergebnis abgedruckt, wenn Sie eine <strong>Prüfung mit
                  MANGEL</strong>
                dokumentieren. Max. 10 Zeichen.
              </div>

              <input id="only-failed-checks" v-model="localSection.only_failed_checks" type="checkbox">
              <label class="inline" for="only-failed-checks">Nur negative Prüfergebnisse abdrucken (nur Mängel)</label>
              <br>
            </div>
          </div>

          <template v-if="expReport && currentUser.pro">
            <hr>
            <div class="fx-form-row">
              <div class="fx-form-label">
                <label class="inline">Fotos (Zustand)</label>
              </div>
              <div class="fx-form-content">
                <select v-model="localSection.thing_photos_col_count">
                  <option :value="0">
                    Keine Fotos abdrucken
                  </option>
                  <option :value="2">
                    Max. 2 Fotos der Komponente
                  </option>
                  <option :value="3">
                    Max. 3 Fotos der Komponente (Standard)
                  </option>
                </select>
                <div class="form-field-hint">
                  Druckt Fotos der <i class="fas fa-cube" />
                  Komponente, die im Auftrag gemacht wurden.
                </div>
              </div>
            </div>
          </template>

          <hr>
          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline mandatory" for="customer_name">Überschrift Mängelliste</label>
            </div>
            <div class="fx-form-content">
              <input v-model="localSection.incidents_list_title" maxlength="80" type="text">
            </div>
          </div>

          <div class="fx-form-row">
            <div class="fx-form-content">
              <input id="with_incident_description" v-model="localSection.with_incident_description" type="checkbox">
              <label class="inline" for="with_incident_description">Störungsbeschreibung der Mängel abdrucken</label>
              <br>
              <input id="with_incident_status_note" v-model="localSection.with_incident_status_note" type="checkbox">
              <label class="inline" for="with_incident_status_note">Hinweis am Status der Mängel abdrucken</label>
            </div>
          </div>

          <template v-if="expReport && currentUser.pro">
            <div class="fx-form-row">
              <div class="fx-form-label">
                <label class="inline">Fotos (Störung)</label>
              </div>
              <div class="fx-form-content">
                <select v-model="localSection.incident_photos_col_count">
                  <option :value="0">
                    Keine Fotos abdrucken
                  </option>
                  <option :value="2">
                    Max. 2 Fotos der Störung
                  </option>
                  <option :value="3">
                    Max. 3 Fotos der Störung (Standard)
                  </option>
                </select>
                <div class="form-field-hint">
                  Druckt eine Zeile Fotos der <i class="fas fa-exclamation-triangle" />
                  Störung, die
                  im Auftrag gemacht wurden.
                </div>
              </div>
            </div>
          </template>

          <button class="fx-btn-primary" @click="showForm = false">
            Zurück zur Vorschau
          </button>
        </div>
        <div v-if="!currentUser.pro && expReport" class="callout callout--pro mt-4">
          Mit Foxtag Pro können Sie an dieser Stelle auch <strong>Fotos von Störungen und vom Zustand einzelner
            Komponenten</strong>
          abdrucken. <help-article article-id="pro_overview">
            Mehr über die Funktionen von Foxtag Pro
            erfahren
          </help-article>
        </div>
      </template>

      <!-- PREVIEW foxtag_report -->
      <template v-if="!showForm && !expReport">
        <div :class="previewEngineClass">
          <div class="repco-h2" :style="headlineStyle">
            {{ localSection.title }}
          </div>
          <table class="preview">
            <tr>
              <th style="width: 12%" :style="thStyle">
                Nr.
              </th>
              <th style="width: 26%" :style="thStyle">
                Typ
              </th>
              <th style="width: 50%" :style="thStyle">
                Details
              </th>
              <th style="width: 12%" :style="thStyle">
                Ergebnis
              </th>
            </tr>
            <tr>
              <td colspan="4" class="repco-group" :style="groupStyle">
                1 Gruppenname
              </td>
            </tr>

            <tr v-if="!localSection.only_failed_checks">
              <td class="repco" :style="tdStyle">
                1/1
              </td>
              <td class="repco" :style="tdStyle">
                Typ-Name<br>Hersteller Modell
              </td>
              <td class="repco" :style="tdStyle">
                Gebäude A, Raum 123<br>
                Baujahr: 2012<br>
                <span v-if="showsTags">Foxtag-ID: a3c14d<br></span>
                <span class="secondary italic mt-4 text-sm">weitere Stammdaten ...</span>
              </td>
              <td class="repco" :style="tdStyle">
                <strong :style="`color: #${localSection.success_color};`">{{
                  localSection.success_label }}</strong>
              </td>
            </tr>

            <tr>
              <td class="repco" rowspan="2" :style="tdStyle">
                1/2
              </td>
              <td class="repco" :style="tdStyle">
                Typ-Name<br>Hersteller Modell
              </td>
              <td class="repco" :style="tdStyle">
                Gebäude B, Raum 3<br>
                Baujahr: 2012<br>
                <span v-if="showsTags">Foxtag-ID: b4c14d<br></span>
                <span class="secondary italic mt-4 text-sm">weitere Stammdaten ...</span>
              </td>
              <td class="repco" :style="tdStyle">
                <strong :style="`color: #${localSection.failed_color};`">{{
                  localSection.failed_label }}</strong>
              </td>
            </tr>
            <tr>
              <td class="repco" colspan="3" :style="tdStyle">
                <table class="no-lines">
                  <tr>
                    <td colspan="4" class="repco_cl_title">
                      Checkliste-Überschrift
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 35%;">
                      Messwert:
                    </td>
                    <td style="width: 15%;">
                      12,2 Ah
                    </td>
                    <td style="width: 35%;">
                      Serviceheft ausgefüllt:
                    </td>
                    <td style="width: 15%;">
                      Ja
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 35%;">
                      Anfahrt:
                    </td>
                    <td style="width: 15%;">
                      12 h
                    </td>
                    <td style="width: 35%;">
                      Kommentar:
                    </td>
                    <td style="width: 15%;">
                      Kommentar
                    </td>
                  </tr>
                </table>

                <table class="no-lines" style="margin-top: 2rem;">
                  <tr>
                    <td colspan="4" class="repco_cl_title">
                      {{ localSection.incidents_list_title }}
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 10%; vertical-align: top;">
                      #1000
                    </td>
                    <td style="width: 55%; vertical-align: top;">
                      <strong>Störungstyp-Name</strong>
                      <div v-if="localSection.with_incident_description">
                        Störungsbeschreibung. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                        eirmod tempor invidunt ut labore et dolore magna aliquyam erat.<br>
                        <i v-if="localSection.with_incident_status_note">Hinweis: Optionaler Hinweis am Status in diesem
                          Auftrag</i>
                      </div>
                      <div v-else>
                        <span v-if="localSection.with_incident_status_note">Optionaler Hinweis am Status in
                          diesem Auftrag</span>
                      </div>
                    </td>
                    <td style="width: 20%; vertical-align: top;">
                      schwerwiegend
                    </td>
                    <td style="width: 15%; vertical-align: top;">
                      offen
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
      </template>

      <!-- PREVIEW exp_report -->
      <template v-if="!showForm && expReport">
        <div :class="previewEngineClass">
          <div class="repco-h2" :style="headlineStyle">
            {{ localSection.title }}
          </div>
          <div class="mb-4">
            <strong>1 Gruppenname</strong>
          </div>
          <table class="preview">
            <tr>
              <th style="width: 12%" :style="thStyle">
                Nr.
              </th>
              <th style="width: 26%" :style="thStyle">
                Typ
              </th>
              <th style="width: 50%" :style="thStyle">
                Details
              </th>
              <th style="width: 12%" :style="thStyle">
                Ergebnis
              </th>
            </tr>

            <tr v-if="!localSection.only_failed_checks">
              <td class="repco" :style="tdStyle">
                1/1
              </td>
              <td class="repco" :style="tdStyle">
                Typ-Name<br>Hersteller Modell
              </td>
              <td class="repco" :style="tdStyle">
                Gebäude A, Raum 123<br>
                Baujahr: 2012<br>
                <span v-if="showsTags">Foxtag-ID: a3c14d<br></span>
                <span class="secondary italic mt-4 text-sm">weitere Stammdaten ...</span>
              </td>
              <td class="repco" :style="tdStyle">
                <strong :style="`color: #${localSection.success_color};`">{{
                  localSection.success_label }}</strong>
              </td>
            </tr>

            <tr>
              <td class="repco" rowspan="2" :style="tdStyle">
                1/2
              </td>
              <td class="repco" :style="tdStyle">
                Typ-Name<br>Hersteller Modell
              </td>
              <td class="repco" :style="tdStyle">
                Gebäude B, Raum 3<br>
                Baujahr: 2012<br>
                <span v-if="showsTags">Foxtag-ID: b4c14d<br></span>
                <span class="secondary italic mt-4 text-sm">weitere Stammdaten ...</span>
              </td>
              <td class="repco" :style="tdStyle">
                <strong :style="`color: #${localSection.failed_color};`">{{
                  localSection.failed_label }}</strong>
              </td>
            </tr>

            <tr>
              <td class="repco" colspan="3" style="border: 0;">
                <template v-if="localSection.thing_photos_col_count > 0">
                  <table class="photos mb-4">
                    <tr>
                      <td>
                        <img src="/demo_thing.jpg" width="100%">
                        <strong>03.01.2023</strong> Bildunterschrift
                      </td>
                      <td>
                        <img src="/demo_thing.jpg" width="100%">
                        <strong>02.01.2023</strong> Bildunterschrift
                      </td>
                      <td v-if="localSection.thing_photos_col_count > 2">
                        <img src="/demo_thing.jpg" width="100%">
                        <strong>01.01.2023</strong> Bildunterschrift
                      </td>
                    </tr>
                  </table>
                </template>

                <div class="nested-headline" :style="nestedHeadlineStyle">
                  Checkliste-Überschrift
                </div>

                <table class="no-lines">
                  <tr>
                    <td style="width: 35%;">
                      Messwert:
                    </td>
                    <td style="width: 15%;">
                      12,2 Ah
                    </td>
                    <td style="width: 35%;">
                      Serviceheft ausgefüllt:
                    </td>
                    <td style="width: 15%;">
                      Ja
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 35%;">
                      Anfahrt:
                    </td>
                    <td style="width: 15%;">
                      12 h
                    </td>
                    <td style="width: 35%;">
                      Kommentar:
                    </td>
                    <td style="width: 15%;">
                      Kommentar
                    </td>
                  </tr>
                </table>

                <div class="nested-headline" style="margin-top: 2rem;" :style="nestedHeadlineStyle">
                  {{
                    localSection.incidents_list_title }}
                </div>

                <table class="no-lines">
                  <tr>
                    <td style="width: 10%; vertical-align: top;">
                      #1000
                    </td>
                    <td style="width: 55%; vertical-align: top;">
                      <strong>Störungstyp-Name</strong>
                      <div v-if="localSection.with_incident_description">
                        Störungsbeschreibung. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                        eirmod tempor invidunt ut labore et dolore magna aliquyam erat.<br>
                        <i v-if="localSection.with_incident_status_note">Hinweis: Optionaler Hinweis am Status in
                          diesem
                          Auftrag</i>
                      </div>
                      <div v-else>
                        <span v-if="localSection.with_incident_status_note">Optionaler Hinweis am Status in
                          diesem Auftrag</span>
                      </div>
                    </td>
                    <td style="width: 20%; vertical-align: top;">
                      schwerwiegend
                    </td>
                    <td style="width: 15%; vertical-align: top;">
                      offen
                    </td>
                  </tr>
                  <tr v-if="localSection.incident_photos_col_count > 0">
                    <td>&nbsp;</td>
                    <td colspan="3">
                      <table class="photos mb-4">
                        <tr>
                          <td>
                            <img src="/demo_incident.jpg" width="100%">
                            <strong>03.01.2023</strong> Bildunterschrift
                          </td>
                          <td>
                            <img src="/demo_incident.jpg" width="100%">
                            <strong>02.01.2023</strong> Bildunterschrift
                          </td>
                          <td v-if="localSection.incident_photos_col_count > 2">
                            <img
                              src="/demo_incident.jpg"
                              width="100%"
                            >
                            <strong>01.01.2023</strong> Bildunterschrift
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
      </template>
    </div>

    <Controls
      :index="index"
      :list-length="listLength"
      :name="name"
      :readonly="readonly"
      @settings="showForm = !showForm"
      @add="$emit('add')"
      @delete="$emit('delete')"
      @move-up="$emit('moveUp')"
      @move-down="$emit('moveDown')"
    />
  </div>
</template>

<script>
import { useCurrentUserStore } from 'stores/current_user';
import Controls from './section_controls.vue';

export default {
  name: 'SectionChecks',
  components: {
    Controls,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    colors: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      default: 'Prüfergebnisse',
    },
    index: {
      type: Number,
      required: true,
    },
    listLength: {
      type: Number,
      required: true,
    },
    showsTags: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    layoutEngine: {
      type: String,
      default: 'foxtag_report',
    },
  },
  emits: ['add', 'delete', 'moveUp', 'moveDown'],
  setup() {
    return {
      currentUser: useCurrentUserStore().currentUser,
    };
  },
  data() {
    return {
      showForm: false,
      localSection: {},
      test: 'OK',
    };
  },
  computed: {
    expReport() {
      return (this.layoutEngine === 'exp_report');
    },
    previewEngineClass() {
      return `preview-${this.layoutEngine}`;
    },
    thStyle() {
      if (this.layoutEngine === 'exp_report') {
        return `background-color: transparent; border-color: #${this.colors.color_lines}; border: 0; border-bottom: 2px solid #${this.colors.color_lines}`;
      }

      return `color: #${this.colors.color_col_headline_text}; background-color: #${this.colors.color_lines}; border: 2px solid #${this.colors.color_lines}`;
    },
    tdStyle() {
      if (this.layoutEngine === 'exp_report') {
        return `background-color: transparent; border-color: #${this.colors.color_lines}; border: 0; border-top: 2px solid #${this.colors.color_lines}`;
      }

      return `color: #${this.colors.color_text}; border: 2px solid #${this.colors.color_lines}`;
    },
    headlineStyle() {
      return `color: #${this.colors.color_headlines};`;
    },
    groupStyle() {
      return `color: #${this.colors.color_text}; background-color: #${this.colors.color_bg_groups}; border-color: #${this.colors.color_lines}`;
    },
    nestedHeadlineStyle() {
      return `color: #${this.colors.color_text}; border: 0; border-bottom: 2px solid #${this.colors.color_lines}`;
    },
  },
  mounted() {
    this.localSection = this.section;

    if (this.localSection.thing_photos_col_count === undefined) {
      this.localSection.thing_photos_col_count = 0;
    }
    if (this.localSection.incident_photos_col_count === undefined) {
      this.localSection.incident_photos_col_count = 0;
    }
  },
};
</script>

<style>
.divider {
  display: flex;
  align-items: center;
  text-align: center;
}

/* To show the lines on right
  and left sides of the text */
.divider::after,
.divider::before {
  content: "";
  border-top: 1px dashed #888;
  flex: 1;
}

/* Space on left and right sides of text */
.divider:not(:empty)::before {
  margin-right: 0.6rem;
}

.divider:not(:empty)::after {
  margin-left: 0.6rem;
}
</style>
