<template>
  <div>
    <template v-if="selectedJob">
      <div v-if="selectedJob" class="fx-card" style="max-width: 600px;">
        <div class="fx-card-content">
          <h2 class="fx">
            {{ selectedJob.job_type_name }} {{ plannedAtFormatted(selectedJob) }}
          </h2>
          <Labels :labels="selectedJob.labels" />
        </div>
        <table class="material">
          <tr>
            <th style="width: 200px;">
              Auftragsnummer
            </th>
            <td>{{ selectedJob.number }}</td>
          </tr>
          <tr>
            <th>Auftragstyp</th>
            <td>{{ selectedJob.job_type_name }}</td>
          </tr>
          <tr>
            <th>Durchgeführt von</th>
            <td>
              <div v-if="selectedJob.pooling" class="secondary">
                Ohne feste Zuordnung
              </div>
              <div v-for="(name, index) in selectedJob.assigned_user_names" v-else :key="index">
                {{ name }}
              </div>
            </td>
          </tr>
          <tr>
            <th>Status</th>
            <td><span :class="'fx-status '+selectedJob.status">{{ $t('job.status.' + selectedJob.status) }}</span></td>
          </tr>
          <tr>
            <th>Bericht</th>
            <td>
              <template v-if="selectedJob.published">
                <a v-for="attachment in selectedJob.attachments" :key="attachment" :href="attachment.download_url">
                  <div class="fx-file-pane">
                    <div>
                      <img :src="attachment.preview_url" class="fx-file-pane"><br>
                      <span class="fx-file-pane-label">{{ attachment.label }}</span>
                    </div>
                  </div>
                </a>
              </template>

              <div v-if="selectedJob.attachments.length == 0" class="secondary">
                Aktuell sind keine Berichte veröffentlicht
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div style="max-width: 600px; text-align: center;">
        <a @click="selectedJob=null;selectedJobId=null;">Alle Aufträge der Anlage anzeigen</a>
      </div>
    </template>

    <div v-if="!selectedJob">
    <div v-if="localJobs.length" class="fx-card">
      <div v-for="job in localJobs" :key="job.id" class="fx-flex-row">
        <div class="fx-flex-row-ico">
          <i class="fas fa-calendar-check" />
        </div>
        <div class="fx-flex-row-main">
          <div class="fx-flex-row-title">
            <a class="no-color" @click="showJob(job)">
              <span class="fx-flex-row-title-number">{{ job.number }}</span> {{ job.job_type_name }} {{
                plannedAtFormatted(job) }}</a>
            <Labels :labels="job.labels" />
          </div>
          <div class="fx-flex-row-subline">
            <span :class="'fx-status '+job.status">{{ $t('job.status.' + job.status) }}</span>

            <span v-if="job.pooling">
              <i class="fas fa-users" /> Ohne feste Zuordnung
            </span>
            <span v-else>
              <div v-for="(name, index) in job.assigned_user_names" :key="index">
                <i class="fas fa-user" /> {{ name }}
              </div>
            </span>
          </div>
          <div v-if="false" class="fx-flex-row-comment">
            <i class="fas fa-info-circle" />
            <span style="margin-left: 0.2rem;">this is a comment</span>
          </div>
        </div>
        <div class="fx-flex-row-nav" style="">
          <div style="width: 100px; color: #666;">
            <span v-if="job.published" style="margin-bottom: 5px;">
              <a :href="downloadReportPath(job)" class="no-color"><span style="white-space: nowrap;">
                <i class="fas fa-file" /> Bericht</span></a>
            </span>
          </div>
          <a class="fx-btn-skeleton" @click="showJob(job)">ansehen</a>
        </div>
      </div>
    </div>

    <EmptyState
      v-if="loaded && !localJobs.length"
      title="Keine Aufträge an dieser Anlage"
      hint="An dieser Stelle sehen Sie zukünftig alle geplanten und erledigten Aufträge
    und haben Zugriff auf die Berichte"
    />

    <Pagination v-model="pagination" i18n-key="activerecord.models.job" @navigate="loadPage" />
  </div>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';

import Flash from 'flash/index';
import { ref, onMounted } from 'vue';

import EmptyState from 'components/empty_state.vue';
import Labels from 'components/partials/labels.vue';
import Pagination from 'components/pagination.vue';

export default {
  name: 'PortalJobsList',
  components: {
    EmptyState,
    Labels,
    Pagination,
  },
  props: {
    sharedInstallationId: {
      type: String,
      required: true,
    },
    jobId: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const localJobs = ref([]);
    const selectedJobId = ref();
    const selectedJob = ref();
    const loaded = ref(false);
    const pagination = ref({
      page: 1,
      pageCount: 1,
      totalCount: 0,
      limit: 25,
    });

    const setSelectedJobById = (id) => {
      selectedJob.value = localJobs.value.find((job) => job.id === id);
    };

    const load = () => {
      axios.get(`/portal/shared_installations/${props.sharedInstallationId}/jobs.json`, {
        params: {
          page: pagination.value.page,
          limit: pagination.value.limit,
        },
      }).then((response) => {
        localJobs.value = response.data.jobs;
        pagination.value.page = response.data.meta.current_page;
        pagination.value.pageCount = response.data.meta.total_pages;
        pagination.value.totalCount = response.data.meta.total_count;

        loaded.value = true;

        if (selectedJobId.value) {
          setSelectedJobById(selectedJobId.value);
        }
      }).catch((err) => {
        if (err.message !== 'Request aborted') {
          Flash.error(err.message);
        }
      });
    };

    const loadPage = (changedPagination) => {
      pagination.value = { ...changedPagination };
      load();
    };

    const showJob = (job) => {
      selectedJob.value = job;
    };

    const downloadReportPath = (job) => `/jobs/${job.id}/download_report`;

    const plannedAtFormatted = (job) => {
      if (job.planned_at) {
        return moment(job.planned_at).format('DD.MM.YYYY');
      }
      return '';
    };

    onMounted(() => {
      selectedJobId.value = props.jobId;
    });

    return {
      showJob,
      localJobs,
      selectedJob,
      selectedJobId,
      pagination,
      load,
      loadPage,
      loaded,
      downloadReportPath,
      plannedAtFormatted,
    };
  },
};
</script>
