<template>
  <span>
    <div class="fx-filter">
      <OnClickOutside @trigger="close">
        <button type="button" class="fx-filter-btn" @click="toggleAndFocus">
          <div class="buttonLabel" :class="classForLabel">
            <i class="fx-select-icon" :class="icon(iconId)" />
            {{ label }}
            <span class="caret" />
          </div>
        </button>

        <div v-if="isOpen" class="fx-filter-dropdown show" style="padding: 5px 0;">
          <div
            style="border-bottom: 1px solid #ddd; padding: 5px 15px; font-size: 12px; font-weight:
            bold;"
          >{{ $t(`comp.filter.${i18nBaseKey}.title`) }}</div>

          <div style="margin:0; padding: 7px 15px 7px 15px; border-bottom: 1px solid #ddd;">
            <input
              ref="searchInput"
              v-model="query"
              type="text"
              :placeholder="$t(`comp.filter.${i18nBaseKey}.search-placeholder`)"
              style="font-size: .8rem; padding: 5px; height: unset; margin: 0;"
              @keyup="onSearchChanged"
            >
          </div>

          <div style="max-height: 400px; overflow-y:scroll">
            <div class="fx-filter-item">
              <a @click.exact="resetAndEmit"><i>{{ defaultLabel }}</i></a>
            </div>
            <div v-for="item in options" :key="item.id" class="fx-filter-item">
              <template v-if="isMultiSelect">
                <div class="fx-filter-item-multiselect">
                  <input
                    :id="item.id"
                    type="checkbox"
                    :checked="inSelection(item)"
                    @click="toggleMultiSelectAndEmit(item)"
                  >
                  <label :for="item.id">{{ item.label }}</label>
                </div>
              </template>
              <template v-else>
                <a :class="classForItem(item)" @click.exact="selectAndEmit(item)"><i
                  v-if="item.color"
                  class="fas fa-square fa-fw"
                  :style="`margin-right: 4px; color: ${item.color}`"
                />{{
                  item.label
                }}</a>
              </template>
            </div>
          </div>

          <div v-if="allowMultiSelect" class="fx-filter-bottom-controls">
            <template v-if="!isMultiSelect">
              <div>&nbsp;</div>
              <div class="text-right">
                <a class="no-color" @click="isMultiSelect = true">{{ $t('comp.filter.multiselect.label') }} <i
                  style="font-size: 14px; vertical-align: text-bottom;"
                  class="fas fa-toggle-off"
                /></a>
              </div>
            </template>
            <template v-else>
              <div>{{ $t('comp.filter.multiselect.label_logic') }}:
                <a v-if="multiselectLogic == 'and'" @click="changeFilterLogic('or')">{{ $t('comp.filter.multiselect.and')
                }}</a>
                <a v-if="multiselectLogic == 'or'" @click="changeFilterLogic('and')">{{ $t('comp.filter.multiselect.or')
                }}</a>
              </div>
              <div class="text-right">
                <a class="no-color" @click="isMultiSelect = false; selection = [];">{{
                  $t('comp.filter.multiselect.label') }} <i
                  style="font-size: 14px; vertical-align: text-bottom; color: #256FC5;"
                  class="fas fa-toggle-on"
                /></a>
              </div>
            </template>
          </div>
        </div>
      </OnClickOutside>
    </div>
  </span>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import Flash from 'flash/index';
import foxtagIcons from 'initializers/icons';

import { OnClickOutside } from '@vueuse/components';
import { useFilterDropdown } from 'apps/application/filter_dropdown_logic';

export default {
  name: 'DBBasedFilter',
  components: {
    OnClickOutside,
  },
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    defaultLabel: {
      type: String,
      required: true,
    },
    loadOptionsUrl: {
      type: String,
      required: true,
    },
    iconId: {
      type: String,
      default: null,
    },
    allowMultiSelect: {
      type: Boolean,
      default: false,
    },
    i18nBaseKey: {
      type: String,
      required: true,
    },
  },
  emits: ['changed'],
  setup(props, { emit }) {
    const {
      isOpen, isMultiSelect, options, selection, label,
      classForItem, classForLabel, select, toggleMultiSelect, resetSelection, inSelection, inOptions, toggleOpen, close, selectionString, parseSelectionString,
    } = useFilterDropdown(props.defaultLabel, emit);
    return {
      isOpen,
      isMultiSelect,
      options,
      selection,
      label,
      classForLabel,
      classForItem,
      select,
      toggleMultiSelect,
      resetSelection,
      inSelection,
      inOptions,
      toggleOpen,
      close,
      selectionString,
      parseSelectionString,
    };
  },
  data() {
    return {
      query: null,
      multiselectLogic: 'and',
    };
  },
  watch: {
    modelValue: {
      handler(newVal) {
        if (newVal == null || newVal === '') {
          this.selection = [];
        } else {
          const parseResult = this.parseSelectionString(newVal);
          this.multiselectLogic = parseResult.logic;
          this.selection = parseResult.selection;

          if (this.selection.length > 1) {
            this.isMultiSelect = true;
          }
        }
      },
      deep: true,
    },
  },
  created() {
    this.loadDefaultOptions();
  },
  mounted() {
    if (this.modelValue != null && this.modelValue !== '') {
      const parseResult = this.parseSelectionString(this.modelValue);
      this.multiselectLogic = parseResult.logic;
      this.selection = parseResult.selection;

      if (this.selection.length > 1) {
        this.isMultiSelect = true;
      }
    }
  },
  methods: {
    toggleAndFocus() {
      this.toggleOpen();

      if (this.isOpen && !this.readonly) {
        this.$nextTick(() => {
          this.$refs.searchInput.focus();
        });
      }
    },
    resetAndEmit() {
      this.resetSelection();
      this.emitSelection();
    },
    selectAndEmit(item) {
      this.select(item);
      this.emitSelection();
    },
    toggleMultiSelectAndEmit(item) {
      this.toggleMultiSelect(item);
      this.emitSelection();
    },
    icon(id) {
      return foxtagIcons[id];
    },
    changeFilterLogic(logic) {
      this.multiselectLogic = logic;
      this.emitSelection();
    },
    emitSelection() {
      this.$emit('changed', this.selectionString(this.multiselectLogic));
    },
    onSearchChanged() {
      this.debouncedSearch(this.query);
    },
    // eslint-disable-next-line func-names
    debouncedSearch: _.debounce(function (query) {
      this.searchOptions(query);
    }, 250),
    searchOptions(query) {
      axios.get(this.loadOptionsUrl, {
        params: {
          query,
        },
      }).then((response) => {
        this.options = response.data.options;
      }).catch((err) => Flash.error(err));
    },
    loadAndAddSelectedOption(id) {
      // --- do not add if already in options
      if (this.inOptions(id)) {
        return;
      }

      axios.get(this.loadOptionsUrl, {
        params: { id },
      }).then((response) => {
        if (response.data.options.length > 0) {
          this.options.unshift(response.data.options[0]);
        }
      }).catch((err) => Flash.error(err));
    },
    loadDefaultOptions() {
      axios.get(this.loadOptionsUrl).then((response) => {
        this.options = response.data.options;

        // --- the list of default options may not include
        // --- the selected option, so we need to make sure
        // --- its in the list
        if (this.selection.length === 1) {
          this.loadAndAddSelectedOption(this.selection[0]);
        }
      }).catch((err) => Flash.error(err));
    },
  },
};
</script>
