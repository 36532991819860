<template>
  <div id="zoomable-container" :style="containerStyle" @click="toogleZoom">
    <img id="zoomable-img" :src="modelValue" :style="imageInitialStyle">
  </div>
</template>

<script>
export default {
  name: 'ZoomableImage',
  props: {
    containerStyle: {
      type: String,
      default: 'background: #f8f8f8; max-height: 90vh; height: 90vh; overflow: hidden; text-align:center; grid-row: 1/3;',
    },
    imageInitialStyle: {
      type: String,
      default: 'cursor: zoom-in; object-fit: scale-down; max-height: 90vh; position: relative;',
    },
    modelValue: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      zoomLevel: 0,
      maxZoomLevel: 2,
    };
  },
  computed: {
  },
  watch: {
    modelValue: {
      handler() {
        this.resetZoom();
      },
      deep: true,
    },
  },
  mounted() {

  },
  methods: {
    toogleZoom(e) {
      if (this.zoomLevel < this.maxZoomLevel) {
        this.zoomIn(e);
      } else {
        this.resetZoom();
      }
    },
    resetZoom() {
      this.zoomLevel = 0;
      document.getElementById('zoomable-container').removeEventListener('mousemove', this.moveZoom, false);

      const photo = document.getElementById('zoomable-img');
      photo.style.maxWidth = '100%';
      photo.style.maxHeight = '90vh';
      photo.style.objectFit = 'scale-down';
      photo.style.height = 'unset';
      photo.style.width = 'unset';
      photo.style.left = 0;
      photo.style.top = 0;
      photo.style.cursor = 'zoom-in';
    },
    zoomIn(e) {
      this.zoomLevel += 1;
      if (this.zoomLevel === 1) {
        document.getElementById('zoomable-container').addEventListener('mousemove', this.moveZoom, false);
      }

      const photo = document.getElementById('zoomable-img');
      const newWidth = `${photo.offsetWidth * 2}px`;
      const newHeight = `${photo.offsetHeight * 2}px`;

      photo.style.maxWidth = 'unset';
      photo.style.maxHeight = 'unset';
      photo.style.objectFit = 'unset';
      photo.style.width = newWidth;
      photo.style.height = newHeight;

      if (this.zoomLevel < this.maxZoomLevel) {
        photo.style.cursor = 'zoom-in';
      } else {
        photo.style.cursor = 'zoom-out';
      }

      this.moveZoom(e);
    },
    moveZoom(e) {
      const container = document.getElementById('zoomable-container');
      const img = document.getElementById('zoomable-img');
      const style = img.style;

      const x = e.clientX - container.getBoundingClientRect().left;
      const y = e.clientY - container.getBoundingClientRect().top;

      const imgWidth = img.width;
      const imgHeight = img.height;
      const xperc = (x / imgWidth);
      const yperc = (y / imgHeight);

      if (imgWidth > container.offsetWidth) {
        style.left = `${-((x - (xperc * container.offsetWidth)) * (img.width / container.offsetWidth))}px`;
      }

      if (imgHeight > container.offsetHeight) {
        style.top = `${-((y - (yperc * container.offsetHeight)) * (img.height / container.offsetHeight))}px`;
      }
    },
  },
};
</script>
