<template>
  <div>
    <NavBreadcrumps path="administration/articles" />

    <div style="display:flex; justify-content: space-between; margin-top: 0">
      <div style="flex: 1; max-width: 650px;">
        <h1 class="fx">
          Artikel
        </h1>
        <p>
          Die Liste der Artikel steht Ihnen in der Smartphone-App zur Verfügung, um Material und Leistungen zu
          dokumentieren. Sie können im Ablauf der einzelnen Auftragstypen festlegen, ob Artikel verwendet werden sollen.
          <help-article article-id="learn_more_articles">
            Mehr erfahren
          </help-article>
        </p>
      </div>
      <div style="align-self: flex-end;">
        <div class="fx-btn-group">
          <button class="fx-btn-primary" @click.prevent="openEditArticleModal()">
            <i class="fas fa-plus" /> Artikel hinzufügen
          </button>
          <drop-down text="Import/Export" classes="fx-btn-secondary">
            <drop-down-item>
              <a href="/imports/articles/new"><i class="far fa-file-import fw" /> Artikel
                importieren</a>
            </drop-down-item>
            <drop-down-item>
              <a @click.prevent="openExportModalArticles"><i class="far fa-file-export fw" /> Artikel
                exportieren</a>
            </drop-down-item>
          </drop-down>

          <drop-down classes="fx-btn-secondary">
            <drop-down-item>
              <a @click.prevent="confirmDeleteDemoArticles()"><i class="far fa-trash-alt" />
                Demo-Artikel löschen</a>
            </drop-down-item>
            <drop-down-item>
              <a @click.prevent="confirmDeleteUnusedArticles()"><i class="far fa-trash-alt" /> Nicht
                verwendete Artikel löschen</a>
            </drop-down-item>
          </drop-down>
        </div>
      </div>
    </div>

    <div class="fx-card">
      <div class="fx-list-row" style="background-color: #f2f2f2; padding: 1rem; ">
        <div class="fx-btn-group">
          <select v-model="filter.group" style="display: inline-block; width: 200px; margin: 0;">
            <option value="">
              Alle Gruppen
            </option>
            <option v-for="group in articleGroups" :key="group" :value="group">
              {{ group }}
            </option>
          </select>
          <input
            v-model="filter.query"
            type="text"
            style="display: inline-block; width: 200px; margin: 0;"
            placeholder="Art.-Nr., Bezeichnung"
          >

          <select v-model="filter.active" style="display: inline-block; width: 180px; margin: 0;">
            <option value="active">
              Nur Aktive
            </option>
            <option value="passive">
              Nur Passive
            </option>
            <option value="">
              Aktive und Passive
            </option>
          </select>
          <a style="font-size: 0.8rem; margin-top: 10px;" @click.prevent="resetSearch">zurücksetzen</a>
        </div>
        <div class="clearfix" />
      </div>

      <div class="fx-list-row">
        <div class="fx-50px-col">
          <i class="fas fa-sort-numeric-down secondary" title="Sortierung" />
        </div>
        <div class="fx-150px-col">
          <strong>Art.-Nr.</strong>
        </div>
        <div class="fx-220px-col">
          <strong>Gruppe</strong>
        </div>
        <div class="fx-220px-col">
          <strong>Bezeichnung</strong>
        </div>
        <div class="fx-50px-col">
          <strong>Einheit</strong>
        </div>
        <div class="clearfix" />
      </div>

      <ListItem
        v-for="article in localArticles"
        :key="article.id"
        :article="article"
        @open-edit-article="openEditArticleModal"
        @open-confirm-delete="confirmDeleteArticle"
      />

      <div
        v-if="!localArticles.length"
        class="fx-list-row"
        style="padding: .8rem 1rem; color: #888; font-size: 1rem; text-align: center;"
      >
        Keine Artikel gefunden
      </div>
    </div>
    <Pagination v-model="pagination" i18n-key="activerecord.models.article" @navigate="loadPage" />
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import Flash from 'flash/index';
import openExportModal from 'helpers/open_export_modal';
import confirmViaModal from 'helpers/confirm_modal';
import { ref, watch, onMounted } from 'vue';
import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';

import EntityList from 'helpers/entity_list';
import Pagination from 'components/pagination.vue';
import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';

import NavBreadcrumps from 'components/nav_breadcrumps.vue';
import EditArticleModal from './edit_article_modal.vue';
import ListItem from './list_item.vue';

export default {
  components: {
    ListItem,
    NavBreadcrumps,
    DropDown,
    DropDownItem,
    Pagination,
  },
  setup() {
    const localArticles = new EntityList();
    const articleGroups = ref([]);
    const filter = ref({
      query: null,
      group: '',
      active: 'active',
    });
    const pagination = ref({
      page: 1,
      pageCount: 1,
      totalCount: 0,
      limit: 25,
    });

    const load = () => {
      axios.get('/articles.json', {
        params: {
          query: filter.value.query,
          group: filter.value.group,
          active: filter.value.active,
          page: pagination.value.page,
          limit: pagination.value.limit,
        },
      }).then((response) => {
        localArticles.value = response.data.articles;
        pagination.value.page = response.data.meta.current_page;
        pagination.value.pageCount = response.data.meta.total_pages;
        pagination.value.totalCount = response.data.meta.total_count;
      }).catch((err) => {
        if (err.message !== 'Request aborted') {
          Flash.error(err.message);
        }
      });
    };

    const loadPage = (changedPagination) => {
      pagination.value = { ...changedPagination };
      load();
    };

    const search = () => {
      localArticles.value = [];
      pagination.value.page = 1;
      load();
    };

    const resetSearch = () => {
      filter.value = {
        query: null,
        group: '',
        active: 'active',
      };
    };

    const debouncedSearch = _.debounce(() => {
      search();
    }, 250);

    watch(filter, () => {
      debouncedSearch();
    }, { deep: true });

    const loadArticleGroups = () => {
      axios.get('/articles/article_groups.json').then((response) => {
        articleGroups.value = response.data;
      }).catch(() => {
        Flash.error('Artikelgruppen konnten nicht geladen werden.');
      });
    };

    const deleteViaApi = (article) => {
      axios.delete(`/articles/${article.id}.json`).then(() => {
        localArticles.remove(article);
        loadArticleGroups();
        Flash.info('Artikel gelöscht');
      }).catch(() => {
        Flash.error('Artikel konnte nicht gelöscht werden.');
      });
    };

    const deleteUnusedViaApi = () => {
      axios.delete('/articles/destroy_all_unassigned.json').then(() => {
        search();
        loadArticleGroups();
        Flash.info('Alle Artikel ohne Artikelpositionen gelöscht');
      }).catch(() => {
        Flash.error('Artikel konnten nicht gelöscht werden.');
      });
    };

    const deleteAllDemoViaApi = () => {
      axios.delete('/articles/destroy_all_demo.json').then(() => {
        search();
        loadArticleGroups();
        Flash.info('Alle Demo-Artikel gelöscht');
      }).catch(() => {
        Flash.error('Demo-Artikel konnten nicht gelöscht werden.');
      });
    };

    // --- modals

    const openExportModalArticles = () => openExportModal({
      title: 'Artikel-Export',
      exporttype: 'articles',
    });

    const confirmDeleteArticle = (article) => {
      confirmViaModal({
        title: `Artikel ${article.number} löschen?`,
      }).then(() => {
        deleteViaApi(article);
      });
    };

    const confirmDeleteUnusedArticles = () => {
      confirmViaModal({
        title: 'Nicht verwendete Artikel löschen?',
        message: 'Diese Aktion löscht alle Artikel, die bisher nicht in Artikelpositionen verwendet wurden.',
        requireInputConfirmation: true,
      }).then(() => {
        deleteUnusedViaApi();
      });
    };

    const confirmDeleteDemoArticles = () => {
      confirmViaModal({
        title: 'Demo-Artikel löschen?',
        message: 'Diese Aktion löscht alle Demo-Artikel und deren Artikelpositionen in Aufträgen.',
        requireInputConfirmation: true,
      }).then(() => {
        deleteAllDemoViaApi();
      });
    };

    const openEditArticleModal = (article) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: EditArticleModal,
            attrs: {
              article,
              articleGroups: articleGroups.value,

              onClose() {
                modalInstance.close();
              },
              onCreated() {
                loadArticleGroups(); // new group might have been created
                search();
              },
              onUpdated(xArticle) {
                localArticles.update(xArticle);
                loadArticleGroups();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    onMounted(() => {
      loadArticleGroups();
    });

    return {
      openExportModalArticles,
      confirmDeleteDemoArticles,
      confirmDeleteArticle,
      openEditArticleModal,
      confirmDeleteUnusedArticles,
      localArticles: localArticles.reactiveList,
      articleGroups,
      filter,
      pagination,
      resetSearch,
      loadPage,
    };
  },
};
</script>
