<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>l

    <h2 class="fx">
      Freigabe bearbeiten
    </h2>

    <hr>
    <ServerErrors v-model="serverErrors" title="Kunde konnte nicht gespeichert werden" margin-bottom="1rem" />

    <form @submit.prevent="updateViaAPI">
      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory" for="user">Portal-Nutzer</label>
        </div>
        <div v-if="sharedInstallation" class="fx-form-content">
          <input type="text" disabled="true" :value="sharedInstallation.user.full_name">
          <div class="form-field-hint">
            Namen und E-Mail-Adresse kann der Nutzer in seinen persönlichen Einstellungen selbst verändern.
          </div>
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory" for="group_name">Berechtigungen</label>
        </div>
        <div class="fx-form-content">
          <input id="role_jobs" v-model="roleJobs" type="checkbox">
          <label for="role_jobs"><i class="far fa-calendar-check role_icon" />
            {{ $t('activerecord.attributes.shared_installation.roles_values.jobs') }}
          </label>
          <br>
          <input id="role_inventory" v-model="roleInventory" type="checkbox">
          <label for="role_inventory"><i class="far fa-cube role_icon" />
            {{ $t('activerecord.attributes.shared_installation.roles_values.inventory') }}
          </label>
          <br>
          <input id="role_due_dates" v-model="roleDueDates" type="checkbox">
          <label for="role_due_dates"><i class="fas fa-circle role_icon" />
            {{ $t('activerecord.attributes.shared_installation.roles_values.due_dates') }}
          </label>
          <br>
          <div v-if="features.incidentsPro">
            <input id="role_incidents" v-model="roleIncidents" type="checkbox">
            <label for="role_incidents"><i class="fas fa-exclamation-triangle role_icon" />
              {{ $t('activerecord.attributes.shared_installation.roles_values.incidents') }}
            </label>
          </div>
          <div v-else>
            <input type="checkbox" disabled="true">
            <label for="role_incidents" style="color: #bbb;">
              <i class="fas fa-exclamation-triangle" />
              {{ $t('activerecord.attributes.shared_installation.roles_values.incidents') }} (pro)
            </label>
          </div>
        </div>
      </div>

      <hr>
      <button
        id="submit-edit-group"
        type="submit"
        class="fx-btn-primary float-right"
        style="margin-bottom: 0.5rem; margin-left: 0.5rem;"
      >
        Freigabe aktualisieren
      </button>
      <button class="fx-btn-secondary float-right" @click.prevent="$emit('close')">
        Abbrechen
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash';
import { useCurrentUserStore } from 'stores/current_user';
import FeatureFlipper from 'mixins/feature_flipper';

import ServerErrors from 'components/partials/server_errors.vue';

export default {
  name: 'EditSharingModal',
  components: {
    ServerErrors,
  },
  mixins: [FeatureFlipper],
  props: {
    installationId: {
      type: String,
      required: true,
    },
    sharedInstallation: {
      type: Object,
      required: true,
    },
  },
  emits: ['updated', 'close'],
  setup() {
    return { currentUser: useCurrentUserStore().currentUser };
  },
  data() {
    return {
      serverErrors: [],
      activeTab: 0,
      roleJobs: true,
      roleInventory: false,
      roleDueDates: false,
      roleIncidents: false,
    };
  },
  mounted() {
    this.roleJobs = this.sharedInstallation.roles.includes('jobs');
    this.roleInventory = this.sharedInstallation.roles.includes('inventory');
    this.roleIncidents = this.sharedInstallation.roles.includes('incidents');
    this.roleDueDates = this.sharedInstallation.roles.includes('due_dates');
  },
  methods: {
    updateViaAPI() {
      const that = this;
      that.serverErrors = [];
      axios.put(`/shared_installations/${this.sharedInstallation.id}.json`, {
        shared_installation: {
          role_jobs: this.roleJobs,
          role_inventory: this.roleInventory,
          role_due_dates: this.roleDueDates,
          role_incidents: this.roleIncidents,
        },
      }).then((response) => {
        Flash.info('Freigabe aktualisiert');
        this.$emit('updated', { ...response.data.shared_installation });
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.shared_installations;
        } else {
          Flash.error('Freigabe konnte nicht aktualisiert werden');
        }
      });
    },
  },
};
</script>

<style scoped>
i.role_icon {
  color: #888;
  padding-right: .2rem;
}
</style>
