<template>
  <div>
    <div class="text-right">
      <div class="fx-btn-group">
      <a
        v-if="features.incidentsPro && installationId != null"
        class="fx-btn-primary"
        @click="openNewIncidentModal(installationId, thingId).then(reload)"
      >
        <i class="fas fa-plus" /> Störung
        erfassen</a>
      <button
        v-tippy="{ placement: 'top', duration: 100, arrow: true }"
        class="fx-btn-secondary"
        title="Liste neu laden"
        @click="reload()"
      >
        <i class="fas fa-sync-alt" />
      </button>
    </div>
    </div>
    <div class="fx-card">
      <div class="fx-list-row">
        <div class="fx-50px-col">
          <strong>#</strong>
        </div>
        <div class="fx-220px-col">
          <strong>Störung</strong>
        </div>
        <div class="fx-220px-col">
          <strong>Status</strong>
        </div>
        <div class="fx-300px-col">
          <strong>Beschreibung</strong>
        </div>
        <div class="clearfix" />
      </div>

      <ThingIncident
        v-for="incident in localIncidents"
        :key="incident.id"
        :incident="incident"
        :thing-id="thingId"
        :has-incidents-pro="features.incidentsPro"
      />
      <div class="clearfix" />
      <div v-if="!localIncidents.length" class="small secondary" style="padding: 1rem; text-align: center;">
        Keine
        Störungen
      </div>
    </div>
    <Pagination
      v-model="pagination"
      i18n-key="activerecord.models.incident"
      :init-from-url="false"
      :update-url="false"
      @navigate="loadPage"
    />
  </div>
</template>
<script>
import axios from 'axios';
import { ref } from 'vue';
import openNewIncidentModal from 'helpers/open_new_incident_modal';
import { useCurrentUserStore } from 'stores/current_user';
import FeatureFlipper from 'mixins/feature_flipper';
import Pagination from 'components/pagination.vue';

import ThingIncident from './thing_incident.vue';

export default {
  name: 'IncidentsList',
  components: {
    Pagination,
    ThingIncident,
  },
  mixins: [FeatureFlipper],
  props: {
    thingId: {
      type: String,
      required: true,
    },
    installationId: {
      type: String,
      default: null,
    },
  },
  emits: ['count-changed'],
  setup(props, { emit }) {
    const localIncidents = ref([]);
    const loaded = ref(false);
    const showNoResults = ref(false);
    const pagination = ref({
      page: 1,
      pageCount: 1,
      totalCount: 0,
      limit: 25,
    });

    const load = () => {
      localIncidents.value = [];
      loaded.value = false;

      return axios.get('/incidents/filtered', {
        params: {
          thing_id: props.thingId,
          page: pagination.value.page,
          limit: pagination.value.limit,
        },
      }).then((response) => {
        localIncidents.value = response.data.incidents;
        pagination.value.page = response.data.meta.current_page;
        pagination.value.pageCount = response.data.meta.total_pages;
        pagination.value.totalCount = response.data.meta.total_count;
        emit('count-changed', pagination.value.totalCount);
      }).catch();
    };

    const loadPage = (changedPagination) => {
      pagination.value = { ...changedPagination };
      load();
    };

    const reload = () => {
      localIncidents.value = [];
      pagination.value.page = 1;
      load();
    };

    return {
      currentUser: useCurrentUserStore().currentUser,
      pagination,
      openNewIncidentModal,
      localIncidents,
      loaded,
      showNoResults,
      loadPage,
      reload,
    };
  },
};
</script>
