<template>
  <div class="fx-card">
    <div class="fx-card-content">
      <div>
        <span v-if="accessEnabled" class="fx-status success">aktiv</span>
      </div>
      <h2 class="fx">
        Kundenservice-Zugang
      </h2>
      <p>
        Kundenservice-Mitarbeiter von Foxtag können in Ihrem Auftrag in Ihrem Foxtag-Firmenaccount mitarbeiten.
      </p>

      <div v-if="accessEnabled">
        <button class="fx-btn-secondary" @click="confirmDisableSupport()">
          Kundenservice-Zugang deaktivieren
        </button>

        <table class="material">
          <colgroup>
            <col style="width: 250px;">
            <col>
            <col style="width: 100px;">
          </colgroup>

          <tr class="material__title">
            <th>Name</th>
          </tr>
          <tr v-for="agent in localSupportUsers" :key="agent.id">
            <td>
              {{ agent.name }}
              <span class="fx-status primary" style="border-radius: 4px; font-size: 0.6rem;">FOXTAG</span>
            </td>
          </tr>
        </table>
      </div>

      <div v-if="!accessEnabled">
        <input id="agent-code" type="text" placeholder="Code" style="display: inline-block; width: 80px; margin-right: 5px;">
        <button class="fx-btn-secondary" @click="inviteSupport()">
          Kundenservice einladen
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';
import { ref, computed } from 'vue';
import confirmViaModal from 'helpers/confirm_modal';

export default {
  name: 'SupportAccess',
  props: {
    organizationId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const agentCode = ref('');
    const localSupportUsers = ref([]);

    const loadSupportAgents = () => {
      axios.get(`/organizations/${props.organizationId}/support_agents.json`).then((response) => {
        localSupportUsers.value = response.data.users;
      });
    };

    const inviteSupport = () => {
      axios.post(`/organizations/${props.organizationId}/invite_support`, {
        agent_code: agentCode.value,
      }).then(() => {
        loadSupportAgents();
        Flash.info('Kundenservice wurde eingeladen');
      }).catch(() => {
        Flash.error('Kundenservice konnte nicht eingeladen werden');
      });
    };

    const disableSupport = () => {
      axios.delete(`/organizations/${props.organizationId}/delete_support`).then(() => {
        loadSupportAgents();
        Flash.info('Kundenservice-Zugang wurde deaktiviert');
      }).catch(() => {
        Flash.error('Kundenservice-Zugang konnte nicht deaktiviert werden');
      });
    };

    const confirmDisableSupport = () => {
      confirmViaModal({
        title: 'Kundenservice-Zugang deaktivieren?',
        message: 'Kundenservice-Mitarbeiter verlieren den Zugriff auf Ihren Account.',
        action: 'Deaktivieren',
      }).then(() => {
        disableSupport();
      });
    };

    const accessEnabled = computed(() => localSupportUsers.value.length > 0);

    return {
      confirmDisableSupport,
      loadSupportAgents,
      accessEnabled,
      localSupportUsers,
      inviteSupport,
      disableSupport,
    };
  },
  mounted() {
    this.loadSupportAgents();
  },
};

</script>
