<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <h2 class="fx">
      {{ title }}
    </h2>

    <hr style="margin-top:0;">
    <ServerErrors v-model="serverErrors" title="Gruppe konnte nicht gespeichert werden" margin-bottom="1rem" />

    <form @submit.prevent="submit">
      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory" for="group_number">Nummer</label>
        </div>
        <div class="fx-form-content">
          <input
            id="group_number"
            v-model="number"
            name="group_number"
            type="text"
            :disabled="isDefaultGroup"
          >
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline" for="group_name">Name</label>
        </div>
        <div class="fx-form-content">
          <input
            id="group_name"
            v-model="name"
            name="group_name"
            type="text"
            :disabled="isDefaultGroup"
          >
        </div>
      </div>

      <div v-if="currentUser.pro" class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline" for="group_link">Link</label>
        </div>
        <div class="fx-form-help">
          <HelpHover content="links" />
        </div>
        <div class="fx-form-content">
          <input
            id="group_link"
            v-model="link"
            class="has-form-field-hint"
            name="group_link"
            type="text"
          >
          <div class="form-field-hint">
            <ExternalLinkButton :href="link" /> Link auf eine externe Webseite, Datei oder einen Speicherort.
          </div>
        </div>
      </div>

      <hr>
      <button
        id="submit-edit-group"
        type="submit"
        class="fx-btn-primary float-right"
        style="margin-bottom: 0.5rem; margin-left: 0.5rem;"
      >
        Speichern
      </button>
      <button class="fx-btn-secondary float-right" @click.prevent="$emit('close')">
        Abbrechen
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import axios from 'axios';
import { useCurrentUserStore } from 'stores/current_user';

import Flash from 'flash/index';
import ServerErrors from 'components/partials/server_errors.vue';
import FeatureFlipper from 'mixins/feature_flipper';
import ExternalLinkButton from 'components/external_link_button.vue';
import HelpHover from 'components/help_hover.vue';

export default {
  name: 'EditGroupModal',
  components: {
    ServerErrors,
    ExternalLinkButton,
    HelpHover,
  },
  mixins: [FeatureFlipper],
  props: {
    group: {
      type: Object,
      default: () => ({}),
    },
    groupLabel: {
      type: String,
      default: 'Gruppe',
    },
    installationId: {
      type: String,
      required: true,
    },
    installationTypeId: {
      type: String,
      required: true,
    },
  },
  emits: ['close', 'created', 'updated'],
  setup() {
    return { currentUser: useCurrentUserStore().currentUser };
  },
  data() {
    return {
      groupId: null,
      number: 0,
      name: null,
      link: null,
      serverErrors: [],
    };
  },
  computed: {
    title() {
      if (this.isCreate()) {
        return `${this.groupLabel} hinzufügen`;
      }
      return `${this.groupLabel} bearbeiten`;
    },
    isDefaultGroup() {
      return this.number < 0;
    },
  },
  mounted() {
    this.groupId = this.group.id;
    this.number = this.group.number;
    this.name = this.group.name;
    this.link = this.group.link;

    if (this.number == null) {
      this.loadNextFreeNumber();
    }
  },
  methods: {
    isCreate() {
      return !this.groupId;
    },
    loadNextFreeNumber() {
      const that = this;
      axios.get(`/installations/${this.installationId}/next_group_number`)
        .then((response) => {
          that.number = response.data;
        }).catch(() => {
          that.number = 1;
        });
    },
    submit() {
      if (this.isCreate()) {
        this.createViaAPI();
      } else {
        this.updateViaAPI();
      }
    },
    createViaAPI() {
      const that = this;
      that.serverErrors = [];
      axios.post('/groups.json', {
        group: {
          installation_id: this.installationId,
          number: this.number,
          name: this.name,
          link: this.link,
        },
      }).then((response) => {
        Flash.info('Gruppe hinzugefügt');
        this.$emit('created', { ...response.data.group });
      }).catch((error) => {
        if (error.response && error.response.status === 400) {
          that.serverErrors = error.response.data.groups;
        } else {
          Flash.error('Gruppe konnte nicht gespeichert werden');
        }
      });
    },
    updateViaAPI() {
      const that = this;
      that.serverErrors = [];
      axios.put(`/groups/${this.groupId}`, {
        group: {
          installation_id: this.installationId,
          number: this.number,
          name: this.name,
          link: this.link,
        },
      }).then((response) => {
        Flash.info('Gruppe gespeichert');
        this.$emit('updated', { ...response.data.group });
      }).catch((error) => {
        if (error.response && error.response.status === 400) {
          that.serverErrors = error.response.data.groups;
        } else {
          Flash.error('Die Änderung konnte nicht gespeichert werden');
        }
      });
    },
  },
};
</script>
