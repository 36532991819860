<template>
  <div class="fx-card">
    <div class="fx-card-content fx-card-header" style="padding: 0.6rem 1rem;">
      <div class="fx-grid" style="padding-left: .1rem; align-items: center;">
        <div class="fx-col_no_pad" style="flex: 5; max-width: 600px;">
          <h2 class="fx" style="margin:0; font-size: 1.2rem; line-height: 1.3;">
            <a class="no-color" :href="locationPath(location.id)">
              <span v-if="location.number" style="margin:0 0.5rem 0 0; font-weight: 400;">{{ location.number
              }}</span><span>{{ location.active_name }}</span>
            </a>
          </h2>
        </div>
        <div class="fx-col_no_pad" style="flex: 2;  font-size: 0.825rem;">
          <span v-if="hasAddress"><i class="fas fa-map-marker-alt" style="margin-right: .3rem;" />{{
            truncate(location.active_address_one_line, 150) }}</span>
        </div>

        <div class="fx-col_no_pad" style="flex: 0; font-size: 0.825rem; text-align: right;">
          <!--<button class="fx-btn-no-decoration" style="margin-right: 1rem;" v-if="allowEdit" @click.prevent="$emit('addInstallation', location)"><i class="fas fa-plus"></i> Anlage hinzufügen</button>-->

          <drop-down
            v-if="allowEdit"
            ttext="Objekt"
            classes="fx-context-circle"
            test="fx-btn-no-decoration"
          >
            <drop-down-item>
              <a v-if="allowEdit" @click.prevent="$emit('addInstallation', location)"><i class="fas fa-plus fw" />
                Anlage hinzufügen</a>
              <span v-if="!allowEdit" class="secondary"><i class="far fa-plus fw" /> Anlage hinzufügen</span>
            </drop-down-item>
            <drop-down-item>
              <hr>
            </drop-down-item>
            <drop-down-item>
              <a :href="locationPath(location.id)"><i class="fas fa-building" /> Objekt ansehen</a>
            </drop-down-item>
            <drop-down-item>
              <a @click="$emit('editLocation', location)"><i class="far fa-pencil-alt" /> Objekt bearbeiten</a>
            </drop-down-item>
            <drop-down-item>
              <hr>
            </drop-down-item>
            <drop-down-item :action-disabled="hasInstallations">
              <a v-if="!hasInstallations" @click="$emit('deleteLocation', location)"><i class="far fa-trash-alt fw" />
                Objekt löschen</a>
              <span v-if="hasInstallations" class="secondary" title="Löschen Sie zuerst die Anlagen des Objekts"><i
                class="far fa-trash-alt fw"
              /> Objekt löschen</span>
            </drop-down-item>
          </drop-down>
        </div>
      </div>
    </div>

    <InstallationRow
      v-for="installation in localInstallations"
      :key="installation.id"
      :installation="installation"
      @quick-view="openQuickView"
      @changed="reload()"
    />
    <div v-if="hasMoreRecords" class="fx-list-row" style="text-align: center; padding: .5rem;">
      <button class="fx-btn-skeleton" @click="loadMore()">
        mehr anzeigen
      </button>
    </div>

    <div v-if="hasInstallationsLoaded && !localInstallations.length" class="fx-list-row">
      <div class="fx-max-width-col" style="padding-left: .5rem;">
        <a class="fx-btn-skeleton" @click.prevent="$emit('addInstallation', location)"><i class="fas fa-plus" /> Erste
          Anlage hinzufügen</a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import FoxtagPaths from 'mixins/foxtag_paths';
import { TruncateFormatter } from 'mixins/formatters';

import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';

import InstallationRow from './installation_row.vue';

export default {
  name: 'LocationListItem',
  components: {
    InstallationRow,
    DropDown,
    DropDownItem,
  },
  mixins: [FoxtagPaths, TruncateFormatter],
  props: {
    location: {
      type: Object,
      required: true,
    },
    filter: {
      type: Object,
      required: true,
    },
    allowEdit: {
      type: Boolean,
      default: true,
    },
    rowLimit: {
      type: Number,
      default: 15,
    },
  },
  data() {
    return {
      localInstallations: [],
      hasInstallationsLoaded: false,
      page: 1,
      hasMoreRecords: false,
    };
  },
  computed: {
    hasInstallations() {
      return (this.hasInstallationsLoaded && this.localInstallations.length > 0);
    },
    hasAddress() {
      return (this.location.active_address_one_line != null && this.location.active_address_one_line.length > 0);
    },
  },
  mounted() {
    this.loadInstallations();
  },
  methods: {
    openQuickView(jobId) {
      this.$emit('quickView', jobId);
    },
    loadMore() {
      this.page += 1;
      this.loadInstallations();
    },
    reload() {
      this.localInstallations = [];
      this.page = 1;
      this.loadInstallations();
    },
    loadInstallations() {
      const that = this;
      axios.get('/installations/filtered.json', {
        params: {
          location_id: this.location.id,
          installation_type_id: this.filter.installationTypeId,
          dtr: this.filter.dueTimerange,
          rtr: this.filter.replacementTimerange,
          label_id: this.filter.labelId,
          passive: this.filter.passive,
          technician_id: this.filter.technicianId,
          limit: this.rowLimit,
          page: this.page,
        },
      }).then((response) => {
        that.hasMoreRecords = (response.data.meta.total_pages > response.data.meta.current_page);
        that.localInstallations = that.localInstallations.concat(response.data.installations.slice(0, this.rowLimit));
        that.hasInstallationsLoaded = true;
      }).catch(() => {
      });
    },
  },
};
</script>
