<template>
  <div class="fx-modal-content-medium">
    <h2 class="fx">
      Als "externer Auftrag" beenden
    </h2>
    <p class="small" style="margin-bottom: .2rem;">
      Nutzen Sie diese Funktion für Aufträge, die Sie vor der Nutzung von Foxtag durchgeführt haben.
      Diese Funktion setzt den Auftrag auf <strong>erledigt</strong> und
      erzeugt Prüfergebnisse für alle eingeplanten Komponenten. Es wird kein Bericht erzeugt.
    </p>
    <help-article article-id="learn_more_external_jobs">
      Mehr über externe Aufträge erfahren
    </help-article>
    <div class="callout callout--clock" style="margin-top: .5rem;">
      Es kann einige Minuten dauern bis die Prüfergebnisse für alle Komponenten erzeugt sind.
    </div>

    <div style="margin-top: 1rem;">
      <div class="fx-btn-group">
        <button
          class="fx-btn-primary float-right"
          style="margin-left: 0.5rem;"
          @click.prevent="confirmed()"
        >
          Als extern beenden
        </button>

        <button class="fx-btn-secondary float-right" @click.prevent="cancelled()">
          Abbrechen
        </button>
      </div>
      <div class="clearfix" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'ConfirmExternalJobModal',
  props: {
    modalName: {
      type: String,
      required: true,
    },
  },
  emits: ['close', 'confirmed'],
  methods: {
    cancelled() {
      this.$emit('close');
    },
    confirmed() {
      this.$emit('confirmed');
      this.$emit('close');
    },
  },
};
</script>
