<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <h2 class="fx">
      Außerbetriebnahme rückgängig machen
    </h2>
    <p>
      Diese Funktion macht die letzte Außerbetriebnahme rückgängig, so dass die Komponente <strong>unter ihrer ursprünglichen Nummer</strong>
      wieder in die Anlage aufgenommen wird.
    </p>
    <div class="callout callout--info">
      Durch diese Aktion können Nummern in der Anlage doppelt belegt werden.
      Kontrollieren Sie die aktiven Komponenten der Anlage und vergeben Sie ggf. eindeutige Nummern.
    </div>
    <hr>

    <div>
      <ServerErrorsCommand
        v-model="commandServerErrors"
        title="Änderungen konnten nicht gespeichert werden"
      />

      <button
        type="submit"
        class="fx-btn-primary float-right"
        style="margin-bottom: 0.5rem; margin-left: 0.5rem;"
        @click.prevent="redeployViaAPI()"
      >
        Speichern
      </button>
      <button class="fx-btn-secondary float-right" @click.prevent="$emit('close')">
        Abbrechen
      </button>
      <div class="clearfix" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';

import ServerErrorsCommand from 'components/partials/server_errors_command.vue';

export default {
  name: 'RedeployThingModal',
  components: {
    ServerErrorsCommand,
  },
  props: {
    thingId: {
      type: Number,
      required: true,
    },
  },
  emits: ['redeployed', 'close'],
  data() {
    return {
      commandServerErrors: {},
    };
  },
  methods: {
    redeployViaAPI() {
      const that = this;
      that.commandServerErrors = {};

      axios.put(`/things/${this.thingId}/redeploy.json`).then((response) => {
        this.$emit('redeployed', response.data);
        Flash.info('Komponente wurde in Betrieb genommen');
      }).catch((error) => {
        that.commandServerErrors = error.response.data;
      });
    },
  },
};
</script>
