<template>
  <div>
    <empty-state
      v-if="showEmptyState"
      title="Diese Anlage enthält keine Komponenten"
      hint="Legen Sie Komponenten und Gruppen an, oder importieren Sie Daten aus einer Excel-Datei."
    >
      <div class="fx-btn-group">
        <a class="fx-btn-primary" @click.prevent="openNewThingModal()">{{ addThingLabel }}</a>
        <a id="add-group" class="fx-btn-primary" @click.prevent="openNewGroupModal()">{{ addGroupLabel }}</a>
        <a :href="importUrl" class="fx-btn-secondary">Daten importieren</a>
      </div>
    </empty-state>
    <div v-else>
      <div v-if="showActiveList" class="fx-control-bar mb-4">
        <input
          v-model="filter.query"
          type="text"
          style="width: 200px; display:inline-block; margin: 0; margin-right: .5rem;"
          placeholder="Gruppe suchen"
        >
        <a
          id="reset-incidents"
          style="font-size: .8rem; line-height: 2.4rem;"
          @click.prevent="resetSearch()"
        >zurücksetzen</a>

        <div class="fx-control-bar-buttons">
          <button class="fx-btn-primary" @click.prevent="openNewThingModal()">
            <i class="fas fa-plus" /> {{ addThingLabel }}
          </button>
          <button class="fx-btn-primary" @click.prevent="openNewGroupModal()">
            <i class="fas fa-plus" /> {{ addGroupLabel }}
          </button>

          <drop-down text="Import/Export" classes="fx-btn-secondary">
            <drop-down-item>
              <a :href="importUrl"><i class="far fa-file-import fa-fw" /> Komponenten
                importieren</a>
            </drop-down-item>
            <drop-down-item>
              <a @click.prevent="openExportThingsModal"><i class="far fa-file-export fa-fw" /> Komponenten
                exportieren</a>
            </drop-down-item>
          </drop-down>

          <drop-down classes="fx-btn-secondary">
            <drop-down-item>
              <a @click.prevent="listType='outoforder'"><i class="far fa-recycle fa-fw" />
                Außerbetriebnahme-Historie</a>
            </drop-down-item>
            <drop-down-item v-if="hasStock">
              <a @click.prevent="listType='stocked'"><i class="far fa-warehouse-alt fa-fw" /> Lager ansehen</a>
            </drop-down-item>
            <drop-down-item>
              <hr>
            </drop-down-item>

            <drop-down-item>
              <a @click="openSetDeploymentDateModal()"><i class="far fa-calendar-alt fa-fw" /> Inbetriebnahme-Datum
                setzen</a>
            </drop-down-item>
            <drop-down-item>
              <a @click="openSaveAsTemplateModal()"><i class="far fa-clone fa-fw" /> Als Vorlage speichern</a>
            </drop-down-item>

            <drop-down-item>
              <a :hhhref="`/installations/${installationId}/recalculate`" @click.prevent="recalculateDueDates()"><i
                class="far fa-sync fa-fw"
              /> Fälligkeiten neu berechnen</a>
            </drop-down-item>
            <drop-down-item>
              <hr>
            </drop-down-item>
            <drop-down-item>
              <a @click.prevent="confirmDeleteAllThings()">
                <i class="far fa-trash-alt fa-fw" /> Alle Komponenten löschen
              </a>
            </drop-down-item>
          </drop-down>

          <button
            v-tippy="{ placement: 'top', duration: 100, arrow: true }"
            class="fx-btn-secondary"
            title="Liste neu laden"
            @click.prevent="search()"
          >
            <i class="fas fa-sync-alt" />
          </button>
        </div>
      </div>

      <OutOfOrder
        v-if="showOutOfOrderList"
        :installation-id="installationId"
        :installation-type-id="installationTypeId"
        :group-label="groupLabel"
        :tag-label="tagLabel"
        :group-separator="groupSeparator"
        :thing-label="thingLabel"
        :has-central-unit="hasCentralUnit"
        :start-collapsed="startCollapsed"
        :custom-dataset="customDataset"
        :has-stock="hasStock"
        @back="showList"
      />

      <StockedList
        v-if="showStockedList"
        :installation-id="installationId"
        :installation-type-id="installationTypeId"
        installation-type-name="Wartungsanwendung"
        :group-label="groupLabel"
        :tag-label="tagLabel"
        :group-separator="groupSeparator"
        :thing-label="thingLabel"
        :has-central-unit="hasCentralUnit"
        :start-collapsed="startCollapsed"
        :custom-dataset="customDataset"
        @back="showList"
      />

      <template v-if="showActiveList">
        <SingleGroup
          v-for="group in localGroups"
          :key="group.id + group.things_count"
          :group="group"
          :installation-id="installationId"
          :installation-type-id="installationTypeId"
          :group-label="groupLabel"
          :tag-label="tagLabel"
          :group-separator="groupSeparator"
          :thing-label="thingLabel"
          :has-central-unit="hasCentralUnit"
          :has-stock="hasStock"
          :start-collapsed="startCollapsed"
          :custom-dataset="customDataset"
          @open-edit-group-modal="openEditGroupModal"
          @open-confirm-delete-group-modal="confirmDeleteGroup"
          @sibling-needs-refresh="search"
          @changed="$emit('changed')"
        />

        <EmptyState v-if="localGroups.length === 0">
          Keine Gruppen gefunden
        </EmptyState>

        <Pagination v-model="pagination" i18n-key="activerecord.models.group" @navigate="loadPage" />
      </template>
    </div>
  </div>
</template>

<script>

import _ from 'lodash';
import axios from 'axios';
import Flash from 'flash/index';
import { ref, watch } from 'vue';
import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';
import { useCurrentUserStore } from 'stores/current_user';
import FeatureFlipper from 'mixins/feature_flipper';

import openExportModal from 'helpers/open_export_modal';
import confirmViaModal from 'helpers/confirm_modal';
import waitForAsyncModal from 'helpers/wait_for_async_modal';

import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';
import EmptyState from 'components/empty_state.vue';
import EntityList from 'helpers/entity_list';
import Pagination from 'components/pagination.vue';

import SetDeploymentDateModal from './modals/set_deployment_date_modal.vue';
import EditThingModal from './modals/edit_thing_modal.vue';
import EditGroupModal from './modals/edit_group_modal.vue';
import SaveAsTemplateModal from './modals/save_as_template_modal.vue';
import SingleGroup from './single_group.vue';
import OutOfOrder from '../../things/out_of_order.vue';
import StockedList from '../../things/stocked_list.vue';

export default {
  name: 'GroupsList',
  components: {
    SingleGroup,
    DropDown,
    DropDownItem,
    EmptyState,
    OutOfOrder,
    StockedList,
    Pagination,
  },
  mixins: [FeatureFlipper],
  props: {
    installationId: {
      type: String,
      default: null,
    },
    groupLabel: {
      type: String,
      default: 'Gruppe',
    },
    groupSeparator: {
      type: String,
      default: '/',
    },
    thingLabel: {
      type: String,
      default: 'Komponente',
    },
    tagLabel: {
      type: String,
      default: 'Foxtag-ID',
    },
    thingsLabelPlural: {
      type: String,
      default: 'Komponenten',
    },
    installationTypeId: {
      type: String,
      default: null,
    },
    defaultGroupId: {
      type: String,
      default: null,
    },
    hasCentralUnit: {
      type: Boolean,
      default: false,
    },
    hasStock: {
      type: Boolean,
      default: false,
    },
    startCollapsed: {
      type: Boolean,
      default: false,
    },
    startEmptyState: {
      type: Boolean,
      default: false,
    },
    customDataset: {
      type: Array,
      required: true,
    },
  },
  emits: ['changed'],
  setup(props, { emit }) {
    const localGroups = new EntityList();
    const pagination = ref({
      page: 1,
      pageCount: 1,
      totalCount: 0,
      limit: 25,
    });
    const filter = ref({
      groupQuery: null,
    });

    const load = () => {
      axios.get(`/installations/${props.installationId}/groups`, {
        params: {
          query: filter.value.query,
          page: pagination.value.page,
          limit: pagination.value.limit,
        },
      }).then((response) => {
        localGroups.value = response.data.groups;
        pagination.value.page = response.data.meta.current_page;
        pagination.value.pageCount = response.data.meta.total_pages;
        pagination.value.totalCount = response.data.meta.total_count;
      }).catch((err) => {
        if (err.message !== 'Request aborted') {
          Flash.error(err.message);
        }
      });
    };

    const loadPage = (changedPagination) => {
      pagination.value = { ...changedPagination };
      load();
    };

    const search = () => {
      localGroups.value = [];
      pagination.value.page = 1;
      load();
    };

    const resetSearch = () => {
      filter.value = {
        query: null,
      };
    };

    const debouncedSearch = _.debounce(() => {
      search();
    }, 250);

    watch(filter, () => {
      debouncedSearch();
    }, { deep: true });

    /// ----

    const showEmptyState = ref(props.startEmptyState);

    const reloadGroup = (groupId) => {
      axios.get(`/groups/${groupId}`).then((response) => {
        localGroups.update(response.data.group);
      }).catch(() => {
        Flash.error('Gruppe konnte nicht aktualisiert werden');
      });
    };

    const createdThingEvent = () => {
      if (!showEmptyState.value) {
        reloadGroup(props.defaultGroupId);
      } else {
        showEmptyState.value = false;
      }
      emit('changed'); // triggers count updates in parents
    };

    const createdGroupEvent = (group) => {
      if (!showEmptyState.value) {
        // add to list only if list is already there
        // otherwise just remove empty state and the list will be
        // initially loaded
        localGroups.append(group);
      } else {
        showEmptyState.value = false;
      }
    };

    const openNewThingModal = () => {
      const thing = {
        id: null,
        deployment: {
          number: null,
          group: null,
        },
        thing_type: { id: null },
        inherit_check_interval: true,
        assigned_features: [],
      };

      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: EditThingModal,
            attrs: {
              thing,
              installationTypeId: props.installationTypeId,
              installationId: props.installationId,
              isCentralUnit: props.hasCentralUnit,
              hasStock: props.hasStock,
              customDataset: props.customDataset,
              initGroupId: props.defaultGroupId,
              onClose() {
                modalInstance.close();
              },
              onCreated(createdThing) {
                modalInstance.close();
                createdThingEvent(createdThing);
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openEditGroupModal = (group) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: EditGroupModal,
            attrs: {
              group,
              installationTypeId: props.installationTypeId,
              installationId: props.installationId,
              onClose() {
                modalInstance.close();
              },
              onCreated(createdGroup) {
                modalInstance.close();
                createdGroupEvent(createdGroup);
              },
              onUpdated(updatedGroup) {
                modalInstance.close();
                localGroups.update(updatedGroup);
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openNewGroupModal = () => {
      openEditGroupModal({
        id: null,
        number: null,
        name: null,
      });
    };

    const openExportThingsModal = () => openExportModal({
      exportableId: props.installationId,
      title: 'Komponenten-Export',
      exporttype: 'things',
    });

    const openSaveAsTemplateModal = () => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: SaveAsTemplateModal,
            attrs: {
              installationId: props.installationId,
              onClose() {
                modalInstance.close();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openSetDeploymentDateModal = () => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: SetDeploymentDateModal,
            attrs: {
              scope: 'installation',
              installationId: props.installationId,
              message: 'Setzen Sie ein neues Inbetriebnahme-Datum für alle Komponenten der Anlage.',
              onClose() {
                modalInstance.close();
              },
              onUpdated() {
                window.location.reload();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const deleteAllViaAPI = () => {
      axios.post(`/installations/${props.installationId}/destroy_content`).then((response) => {
        waitForAsyncModal({
          title: 'Alle Komponenten werden gelöscht ...',
          message: 'Diese Aktion kann einige Minuten dauern',
          sidekiqJobId: response.data.sidekiq_job_id,
        }).then(() => {
          window.location.reload();
        });
      }).catch(() => {
        Flash.error('Komponenten konnten nicht gelöscht werden');
      });
    };

    const deleteGroupViaAPI = (group) => {
      axios.delete(`/groups/${group.id}`).then(() => {
        Flash.info('Gruppe gelöscht');
        localGroups.remove(group);
        emit('changed'); // triggers count updates in parents
      }).catch(() => {
        Flash.error('Gruppe konnte nicht gelöscht werden');
      });
    };

    const confirmDeleteAllThings = () => {
      confirmViaModal({
        title: 'Alle Komponenten löschen?',
        message: 'Diese Aktion löscht alle Gruppen und Komponenten mit ihrer kompletten Historie.',
        requireInputConfirmation: true,
      }).then(() => {
        deleteAllViaAPI();
      });
    };

    const confirmDeleteGroup = (group) => {
      confirmViaModal({
        title: 'Gruppe löschen?',
        message: 'Löscht die Gruppe und allen enthaltenen Komponenten.',
      }).then(() => {
        deleteGroupViaAPI(group);
      });
    };

    const recalculateDueDates = () => {
      axios.put(`/installations/${props.installationId}/recalculate`).then((response) => {
        waitForAsyncModal({
          title: 'Fälligkeiten werden neu berechnet ...',
          message: 'Diese Aktion kann einige Minuten dauern, abhängig von der Anzahl der Komponenten.',
          sidekiqJobId: response.data.sidekiq_job_id,
        }).then(() => {
          window.location.reload();
        });
      }).catch(() => {
        Flash.error('Neuberechnung konnte nicht gestartet werden');
      });
    };

    return {
      localGroups: localGroups.reactiveList,
      pagination,
      filter,
      openNewThingModal,
      openNewGroupModal,
      openEditGroupModal,
      openExportThingsModal,
      openSaveAsTemplateModal,
      openSetDeploymentDateModal,
      confirmDeleteAllThings,
      confirmDeleteGroup,
      recalculateDueDates,
      showEmptyState,
      currentUser: useCurrentUserStore().currentUser,
      search,
      resetSearch,
      loadPage,
      load,
    };
  },
  data() {
    return {
      listType: 'active',
    };
  },
  computed: {
    addThingLabel() {
      if (this.hasCentralUnit) {
        return 'Zentrale hinzufügen';
      }
      return `${this.thingLabel} hinzufügen`;
    },
    addGroupLabel() {
      return `${this.groupLabel} hinzufügen`;
    },
    importUrl() {
      return `/imports/things/new?installation=${this.installationId}`;
    },
    exportUrl() {
      return `/installations/${this.installationId}/export`;
    },
    showActiveList() {
      return this.listType === 'active';
    },
    showOutOfOrderList() {
      return this.listType === 'outoforder';
    },
    showStockedList() {
      return this.listType === 'stocked';
    },
  },
  mounted() {
    this.showEmptyState = this.startEmptyState;
  },
  methods: {
    showList(listType) {
      this.listType = listType;
    },
    init() {
      if (this.localGroups.length === 0) {
        this.search();
      }
    },
  },
};
</script>
