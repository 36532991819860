<template>
  <div>
    <div class="repco_preview">
      <template v-if="showForm">
        <div style="background-color: #eee; min-height: 18rem; border-radius: 8px; padding: 1rem;">
          <h3 class="fx">
            {{ name }}
          </h3>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline" for="customer_name">Abschließender Text</label>
            </div>
            <div class="fx-form-content" style="width: 35rem;">
              <textarea
                v-model="localSection.remark"
                rows="5"
                maxlength="2000"
                type="text"
              />
              Dieser Text steht unmittelbar vor den abgedruckten Unterschriften
            </div>
          </div>

          <button class="fx-btn-primary" @click="showForm=false">
            Zurück zur Vorschau
          </button>
        </div>
      </template>
      <template v-if="!showForm">
        <div style="margin-bottom: 4rem; white-space: pre-wrap;">
          {{ localSection.remark }}
        </div>

        <table style="border-collapse: collapse; width: 100%;">
          <tr>
            <td style="width: 40%">
              <img src="/demo_signature.png" width="180px">
              <div style="border-top: 1px solid black; max-width: 280px; line-height: 1.2; padding-top: .2rem;">
                {{ organization }}<br>01.01.2000, Technikername
              </div>
            </td>
            <td style="width: 60%">
              <img src="/demo_signature.png" width="180px">
              <div style="border-top: 1px solid black; max-width: 280px; line-height: 1.2; padding-top: .2rem;">
                Kundenunterschrift<br>01.01.2000, Karl Kunde
              </div>
            </td>
          </tr>
        </table>
      </template>
      <div>&nbsp;</div>
    </div>

    <Controls
      :index="index"
      :list-length="listLength"
      :name="name"
      :readonly="readonly"
      @settings="showForm = !showForm"
      @add="$emit('add')"
      @delete="$emit('delete')"
      @move-up="$emit('moveUp')"
      @move-down="$emit('moveDown')"
    />
  </div>
</template>

<script>
import Controls from './section_controls.vue';

export default {
  name: 'SectionSignatures',
  components: {
    Controls,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      default: 'Unterschriften',
    },
    index: {
      type: Number,
      required: true,
    },
    listLength: {
      type: Number,
      required: true,
    },
    colors: {
      type: Object,
      required: true,
    },
    organization: {
      type: String,
      default: 'Firmenname',
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showForm: false,
      localSection: {},
    };
  },
  computed: {
    thStyle() {
      return `color: #${this.colors.color_col_headline_text}; background-color: #${this.colors.color_lines}; border-color: #${this.colors.color_lines}`;
    },
    tdStyle() {
      return `color: #${this.colors.color_text}; border-color: #${this.colors.color_lines}`;
    },
    headlineStyle() {
      return `color: #${this.colors.color_headlines};`;
    },
  },
  mounted() {
    this.localSection = this.section;
  },
};
</script>
