<template>
  <div class="mt-4" :style="wrapperStyle">
    <div style=" display: inline-block;">
      <i :class="icon" :style="iconStyle" />
      <span :style="titleStyle" class="secondary">{{ title }}</span>
      <div v-if="featured" style="margin: 5px 0  10px 34px;">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FirstStepsStep',
  components: {
  },
  props: {
    completed: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Title',
    },
    featured: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    titleStyle() {
      if (this.featured) {
        return 'vertical-align: middle; font-weight: bold; color: #111;';
      }
      return 'vertical-align: middle;';
    },
    wrapperStyle() {
      if (!this.featured) {
        return '';
      }
      return 'padding: 1rem .5rem; border-radius: 6px; background-color: rgb(246, 246, 246);';
    },
    icon() {
      if (this.completed) {
        return 'fas fa-check-circle fa-fw';
      }
      return 'far fa-circle fa-fw';
    },
    iconStyle() {
      if (this.completed) {
        return 'color: #02af16; font-size: 24px; vertical-align: middle;';
      }
      return 'color: #828282; font-size: 24px; vertical-align: middle;';
    },
  },
};
</script>
