<template>
  <div>
    <div v-for="installation in localInstallations" :key="installation.id" class="fx-flex-row">
      <div class="fx-flex-row-ico">
        <i class="fas fa-sitemap" />
      </div>
      <div class="fx-flex-row-main">
        <div class="fx-flex-row-title">
          <a class="no-color mr-2" :href="installationPath(installation.id)">
            <span v-if="installation.number" class="fx-flex-row-title-number">{{ installation.number }}</span>
            {{ installation.full_name }}
          </a>
          <Labels :labels="installation.labels" />
        </div>

        <div class="fx-flex-row-subline">
          <template v-if="!installation.passive">
            <NextCheckInfo :next-date="installation.next_check_at" :next-status="installation.next_check_status" />
            <NextCheckInfo
              v-if="installation.next_replacement_at"
              :next-date="installation.next_replacement_at"
              :next-status="installation.next_replacement_status"
              :is-replacement="true"
            />
          </template>

          <template v-if="installation.passive">
            <span class="secondary"><strong>Anlage ist passiv</strong></span>
          </template>

          <span class="fx-ico-text fx-ico-text-location">
            <a :href="locationPath(installation.location.id)" class="no-color">
              <strong>{{ truncate(installation.location.number, maxNumberLength) }}</strong> {{
                truncate(installation.location.active_name, maxNameLength) }}
            </a>
          </span>

          <span
            v-if="installation.location.active_address_one_line && installation.location.active_address_one_line.length"
            class="fx-ico-text fx-ico-text-map"
          >

            <template v-if="showLocateMe"><a
              class="no-color"
              @click="emitLocateMe([installation.address.lng, installation.address.lat])"
            >{{ installation.location.active_address_one_line }}</a></template>

            <template v-else>{{
              truncate(installation.location.active_address_one_line,100) }}</template>
          </span>
          <span v-else class="fx-ico-text fx-ico-text-map">(Keine Koordinaten)</span>
        </div>
      </div>
      <div class="fx-flex-row-nav">
        <div style="width: 120px; color: #666;">
          <a class="no-color" :href="installationPath(installation.id) + '#tab-things'">
            <span><i class="far fa-cube" /> {{ installation.things_count }}</span>
          </a>

          <a v-if="features.incidentsPro" class="no-color ml-2" :href="installationPath(installation.id) + '#tab-incidents'">

            <template v-if="installation.open_incidents_count > 0">
              <i class="fas fa-exclamation-triangle color-warn" /> {{ installation.open_incidents_count }}
            </template>

            <template v-else>
              <span title=""><i class="fas fa-exclamation-triangle" /> {{ installation.open_incidents_count }}</span>
            </template>
          </a>
        </div>

        <a
          v-if="!installation.passive"
          class="fx-btn-skeleton"
          style="white-space: nowrap;"
          @click="openNewJobModal(installation.id)"
        >Auftrag
          planen</a><!--<a class="fx-btn-skeleton" :href="installationPath(installation.id)">ansehen</a>-->
        <drop-down>
          <drop-down-item>
            <a :href="installationPath(installation.id)"><i class="fas fa-sitemap fa-fw" /> Anlage ansehen</a>
          </drop-down-item>
        </drop-down>
      </div>
    </div>

    <div v-if="!loaded" style="padding: 1rem; text-align: center;">
      <i class="fx-spinner" />
    </div>
    <div v-if="loaded && moreJobsAvailable" style="padding: 1rem; text-align: center;">
      <button class="fx-btn-skeleton" @click="loadMore()">
        mehr Anlagen anzeigen
      </button>
    </div>
    <slot v-if="showNoResults" name="empty-state">
      keine Anlagen
    </slot>
    <div v-if="error" class="fx-list-empty-state">
      {{ errorMessage }} <a @click.prevent="loadJobs()">Nochmal versuchen</a>
    </div>
    <div v-if="cancelled" class="fx-list-empty-state">
      Anfrage abgebrochen
    </div>
  </div>
</template>

<script>
import FoxtagPaths from 'mixins/foxtag_paths';
import FeatureFlipper from 'mixins/feature_flipper';
import { TruncateFormatter } from 'mixins/formatters';
import { useCurrentUserStore } from 'stores/current_user';

import axios from 'axios';
import NextCheckInfo from 'components/partials/next_check_info.vue';
import Labels from 'components/partials/labels.vue';
import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';
import openNewJobModal from 'helpers/open_new_job_modal';

export default {
  name: 'InstallationList',
  components: {
    NextCheckInfo,
    Labels,
    DropDown,
    DropDownItem,
  },
  mixins: [FoxtagPaths, FeatureFlipper, TruncateFormatter],
  props: {
    overduePlannedJobsCount: {
      type: Number,
      default: 0,
    },
    errorMessage: {
      type: String,
      default: 'Die Anlagen konnten nicht geladen werden.',
    },
    queryPath: {
      type: String,
      required: true,
    },
    allowEditing: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 25,
    },
    showLocateMe: {
      type: Boolean,
      default: false,
    },
    hasIncidentsPro: {
      type: Boolean,
      default: true,
    },
    maxNumberLength: {
      type: Number,
      default: 12,
    },
    maxNameLength: {
      type: Number,
      default: 30,
    },
  },
  setup() {
    return {
      currentUser: useCurrentUserStore().currentUser,
      openNewJobModal,
    };
  },
  data() {
    return {
      page: 1,
      localInstallations: [],
      loaded: false,
      moreJobsAvailable: false,
      error: false,
      cancelled: false,
    };
  },
  computed: {
    showNoResults() {
      return (!this.error && !this.cancelled && this.loaded && (this.localInstallations.length === 0));
    },
  },
  mounted() {
    this.loadInstallations();
  },
  methods: {
    loadMore() {
      this.page += 1;
      this.loadInstallations();
    },
    loadInstallations() {
      this.loaded = false;
      this.error = false;
      this.cancelled = false;
      const that = this;
      axios.get(this.queryPath, {
        params: {
          page: this.page,
          limit: this.limit,
        },
      }).then((response) => {
        that.localInstallations = that.localInstallations.concat(response.data.installations);
        that.loaded = true;
        that.moreJobsAvailable = (response.data.length === that.limit);
      }).catch((err) => {
        that.loaded = true;
        if (err.message === 'Request aborted') {
          that.cancelled = true;
        } else {
          that.error = true;
        }
      });
    },
    emitLocateMe(data) {
      this.$emit('locate-me', data);
    },
  },
};
</script>
