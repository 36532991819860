<template>
  <section class="fx-page-content">
  <div v-if="initialDataLoaded">
    <section class="header">
      <div class="fx-grid">
        <div class="fx-col">
          <ul class="fx-breadcrumps">
            <li><a href="/portal/locations">Objekte</a></li>
            <li>{{ locationBreadcrumpLabel }}</li>
            <li>
              <a :href="'/portal/shared_installations/' + localSharedInstallation.id">{{
                localSharedInstallation.installation.installation_type_name }}</a>
            </li>
            <li>#{{ localIncident.number }}</li>
          </ul>

          <div class="header-title">
            <h1 class="fx">
              <span class="header-title_number">
                Störung #{{ localIncident.number }}
              </span>
              <span class="header-title_name">
                {{ localIncident.incident_type.name }}
              </span>
            </h1>
          </div>
        </div>
      </div>
    </section>

    <section v-if="localIncident" class="list-content pt-4">
      <div class="fx-grid">
        <!-- incident description and history -->
        <div class="fx-col-notop">
          <div class="fx-card-notop fx-card-content">
            <Labels :labels="localIncident.labels" />
            <div class="bold mt-2">
              {{ localIncident.incident_type.name }}
            </div>

            <p v-if="!localIncident.description" class="secondary">
              Keine Beschreibung
            </p>
            <p v-if="localIncident.description" style="white-space: pre-wrap;">
              {{ localIncident.description }}
            </p>
          </div>

          <!-- photos -->
          <div class="fx-card-notop">
            <div class="fx-card-content">
              <h2 class="fx">
                Fotos
              </h2>
              <div class="photo-grid-3-col">
                <div v-for="photo in localIncident.photos" :key="photo.id" style="width: 100%; overflow: hidden;">
                  <figure
                    style="position: relative; margin: 0; border: 1px solid #aaa; border-radius: 4px; overflow: hidden;"
                  >
                    <a :href="photo.url">
                      <img
                        :src="photo.thumb_url"
                        style="width: 100%; height: 100%; ; object-fit: cover; aspect-ratio: 4 / 3;"
                      >
                    </a>
                    <figcaption>
                      {{ formatDate(photo.taken_at, 'DD.MM.YYYY') }}<br>{{ truncate(photo.caption, 80) }}
                    </figcaption>
                  </figure>
                </div>
              </div>
            </div>
          </div>

          <!-- incident status history -->
          <div class="fx-card-notop">
            <div class="fx-card-content">
              <h2 class="fx" style="margin-bottom: 2rem;">
                Verlauf
              </h2>
              <IncidentStatus
                v-for="status in sortedIncidentStatuses"
                :key="status.id"
                :incident-status="status"
                :show-links="false"
                job-url="/portal/jobs/"
              />
            </div>
          </div>
        </div>

        <!-- sidebar with details and context -->
        <div class="fx-col-notop" style="max-width: 480px;">
          <PortalIncidentDetails :incident="localIncident" />
          <IncidentContext :incident="localIncident" :show-links="false" />
        </div>
      </div>
    </section>
  </div>
</section>
</template>

<script>
import _ from 'lodash';

import PortalIncidentDetails from 'apps/portal/incidents/show/incident_details.vue';
import IncidentContext from 'apps/incidents/show/incident_context.vue';
import IncidentStatus from 'apps/incidents/show/incident_status.vue';

import { useCurrentUserStore } from 'stores/current_user';
import Labels from 'components/partials/labels.vue';
import IncidentModel from 'apps/incidents/incident_model';
import FeatureFlipper from 'mixins/feature_flipper';
import { DateFormatter, TruncateFormatter } from 'mixins/formatters';
import axios from 'axios';

export default {
  name: 'PortalIncidentShow',
  components: {
    PortalIncidentDetails,
    IncidentContext,
    IncidentStatus,
    Labels,
  },
  mixins: [FeatureFlipper, DateFormatter, TruncateFormatter],
  props: {
    incidentId: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      currentUser: useCurrentUserStore().currentUser,
    };
  },
  data() {
    return {
      initialDataLoaded: false,
      localIncident: null,
      localSharedInstallation: null,
    };
  },
  computed: {
    locationBreadcrumpLabel() {
      if (this.localSharedInstallation.location_number) {
        return this.localSharedInstallation.location_number;
      }
      return this.localSharedInstallation.installation.location_active_name;
    },
    sortedIncidentStatuses() {
      return _.orderBy(this.localIncident.incident_statuses, 'statusdate').reverse();
    },
    incidentStatus() {
      return IncidentModel.getCurrentStatus(this.localIncident);
    },
  },
  mounted() {
    console.log('mounted', this.incidentId);
    this.loadIncident();
  },
  methods: {
    loadIncident() {
      console.log('loadIncident', this.incidentId);

      const that = this;
      axios.get(`/portal/incidents/${this.incidentId}.json`)
        .then((response) => {
          that.localIncident = response.data.incident;
          that.localSharedInstallation = response.data.shared_installation;
          that.initialDataLoaded = true;
        });
    },
  },
};

</script>
