<template>
  <section class="fx-page-content">
    <div class="fx-sidebar-layout-infobar">
      <h2 class="fx">
        <i class="fas fa-cog" /> Administration
      </h2>
    </div>
    <section class="fx-header">
      <div class="fx-grid">
        <div class="fx-col" style="padding: 0 1rem;">
          <ul class="fx-breadcrumps">
            <li><a href="/organizations/settings">Administration</a></li>
            <li><a href="/installation_types">Wartungsanwendungen</a></li>
            <li><a :href="backLink()">{{ truncate(installationTypeName, 50) }}</a></li>
            <li>Checkliste</li>
          </ul>

          <div class="float-right">
            <a class="fx-btn-secondary" :href="backLink()">abbrechen</a>
            <span style="margin-left: .4rem;"><button
              class="fx-btn-primary float-right"
              style="margin-bottom: 0.5rem;"
              @click="submit"
            >Checkliste speichern</button></span>
            <div class="clearfix" />
          </div>

          <div class="fx-header-title">
            <h1 class="fx-header-title">
              <span class="fx-header-title_number">Checkliste</span>
              <span class="fx-header-title_name">{{ truncate(localChecklist.name, 50) }}</span>
            </h1>
          </div>

          <button :class="$tabClasses('checklist')" @click="$setActiveTab('checklist')">
            <i class="far fa-list" /> Checkliste
          </button>
          <button
            v-if="localChecklist.checklist_type === 'job_type'"
            :class="$tabClasses('job-types')"
            @click="$setActiveTab('job-types')"
          >
            Verwendung in Auftragstypen <span class="fx-tab-count">{{ assignedJobtypes.length }}</span>
          </button>

          <button
            v-if="localChecklist.checklist_type === 'thing_type'"
            :class="$tabClasses('thing-types')"
            @click="$setActiveTab('thing-types')"
          >
            Zuordnung zu den Komponenten
          </button>
        </div>
      </div>
    </section>

    <section class="list-content">
      <div class="fx-grid" style="margin-top: 0rem;">
        <div class="fx-col">
          <div v-if="$isTabInitialized('checklist')" v-show="$isTabActive('checklist')" class="tab-panel">
            <ServerErrors
              v-model="serverErrors"
              title="Checkliste konnte nicht gespeichert werden"
              margin-bottom="1rem"
            />
            <div class="fx-card">
              <div class="fx-card-content">
                <div class="fx-form-row">
                  <div class="fx-form-label">
                    <label class="inline mandatory">Name</label>
                  </div>
                  <div class="fx-form-content">
                    <input v-model="localChecklist.name" type="text">
                    <div class="form-field-hint">
                      <strong>Der Name wird nicht im Bericht abgedruckt!</strong> Er hilft
                      Ihnen mehrere Checklisten zu unterscheiden.
                    </div>
                  </div>
                </div>

                <div v-if="isThingTypeChecklist" class="fx-form-row">
                  <div class="fx-form-label">
                    <label class="inline">Position</label>
                  </div>
                  <div class="fx-form-content">
                    <input v-model="localChecklist.position" type="text" placeholder="optional">
                    <div class="form-field-hint">
                      Optional. Zur Sortierung, falls mehrere Checklisten einem Typ zugeordnet
                      werden.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="fx-card">
              <Dataset
              v-model="localChecklist.dataset"
              :headline-field-type="headlineFieldType"
              @changed="(dataset) => localChecklist.dataset = dataset"
            />
            </div>
          </div>

          <!-- this tab is only shown if checklist is for job_types -->
          <div v-if="$isTabInitialized('job-types')" v-show="$isTabActive('job-types')" class="tab-panel">
            <div class="fx-card">
              <div class="fx-card-content">
                So verwenden Sie diese Checkliste in einem Auftragstyp:
                <br>
                Wählen Sie <strong>bearbeiten</strong> in der Liste der Auftragstypen und danach das Tab
                <strong>Ablauf</strong>. Sie können diese Checkliste nun in den Schritten 2 oder 4 auswählen und dem
                Auftragstyp zuordnen.
              </div>
            </div>
            <div v-if="assignedJobtypes.length" class="fx-card">
              <table class="material">
                <tbody>
                  <tr>
                    <td class="title">
                      Auftragstyp
                    </td>
                  </tr>
                  <tr v-for="jobType in assignedJobtypes" :key="jobType.id">
                    <td>{{ jobType.name }}</td>
                    <td><a class="fx-btn-skeleton text-right" :href="`/job_types/${jobType.id}/edit`">ansehen</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- this tab is only shown if checklist is for thing_types -->
          <div v-if="$isTabInitialized('thing-types')" v-show="$isTabActive('thing-types')" class="tab-panel">
            <ServerErrors
              v-model="serverErrors"
              title="Auftragstyp konnte nicht gespeichert werden"
              margin-bottom="1rem"
            />
            <div class="fx-card fx-card-content" style="margin-bottom: 1rem;">
              <!--<h2 class="fx">Zuordnung zu den Komponenten</h2>-->
              <div class="fx-form-row">
                <div class="fx-form-label">
                  <label class="inline mandatory">Kategorie</label>
                </div>
                <div class="fx-form-help" />
                <div class="fx-form-content">
                  <p>Für welche Kategorie von Komponenten soll dieses Intervall verwendet werden?</p>
                  <input
                    id="interval_category_0"
                    v-model="localChecklist.category"
                    name="interval_category"
                    type="radio"
                    :value="0"
                    @click="clearSelectedTypes"
                  >
                  <label for="interval_category_0">Komponenten</label>
                  <input
                    id="interval_category_1"
                    v-model="localChecklist.category"
                    name="interval_category"
                    type="radio"
                    :value="1"
                    @click="clearSelectedTypes"
                  >
                  <label for="interval_category_1">Sub-Komponenten</label>
                  <input
                    id="interval_category_2"
                    v-model="localChecklist.category"
                    name="interval_category"
                    type="radio"
                    :value="2"
                    @click="clearSelectedTypes"
                  >
                  <label for="interval_category_2">Zentralen</label>
                  <span style="font-size: .8rem; color: #0275d8;">
                    <HelpHover content="thingtype_categories" />
                  </span>
                </div>
              </div>
              <hr>
              <div class="fx-form-row">
                <div class="fx-form-label">
                  <label class="inline mandatory">Typen-Filter</label>
                </div>
                <div class="fx-form-content" style="width: 800px;">
                  <p>Hier können Sie dieses Intervall nur bestimmten Typen oder Herstellern zuordnen.</p>
                  <input
                    id="interval_all_types_true"
                    v-model="localChecklist.all_types"
                    name="interval_all_types"

                    type="radio"
                    :value="true"
                  >
                  <label for="interval_all_types_true">Alle Typen der Kategorie
                    "{{ categoryLabels[checklist.category] }}"</label><br>
                  <input
                    id="interval_all_types_false"
                    v-model="localChecklist.all_types"
                    name="interval_all_types"
                    type="radio"
                    :value="false"
                  >
                  <label for="interval_all_types_false">Nur {{ categoryLabels[localChecklist.category] }} bestimmter Typen oder
                    Hersteller</label>

                  <ThingTypeFilter
                    v-if="!localChecklist.all_types"
                    v-model="localChecklist.selected_types"
                    :installation-type-id="installationTypeId"
                    :category="localChecklist.category"
                  />
                </div>
              </div>
            </div>

            <div class="callout callout--clock" style="margin-bottom: .8rem;">
              Nach dem Speichern kann es mehrere Minuten dauern, bis die Fälligkeiten aller Komponenten neu berechnet
              sind.
            </div>
          </div>

          <div class="float-right">
            <a class="fx-btn-secondary" :href="backLink()">abbrechen</a>
            <span style="margin-left: .4rem;"><button
              class="fx-btn-primary float-right"
              style="margin-bottom: 0.5rem;"
              @click="submit"
            >Checkliste speichern</button></span>
            <div class="clearfix" />
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';

import Tabs from 'mixins/tabs';
import { TruncateFormatter } from 'mixins/formatters';

import ServerErrors from 'components/partials/server_errors.vue';

import HelpHover from 'components/help_hover.vue';

import Dataset from './dataset.vue';
import ThingTypeFilter from '../../intervals/edit/thing_type_filter.vue';

export default {
  name: 'EditChecklist',
  components: {
    ServerErrors,
    Dataset,
    ThingTypeFilter,

    HelpHover,
  },
  mixins: [Tabs, TruncateFormatter],
  props: {
    installationTypeId: {
      type: String,
      required: true,
    },
    installationTypeName: {
      type: String,
      required: true,
    },
    checklist: {
      type: Object,
      required: true,
    },
    assignedJobtypes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localChecklist: [],
      serverErrors: [],
      categoryLabels: ['Komponenten', 'Sub-Komponenten', 'Zentralen'],
    };
  },
  computed: {
    isJobTypeChecklist() {
      if (this.localChecklist.checklist_type === 'job_type') {
        return true;
      }
      return false;
    },
    isThingTypeChecklist() {
      if (this.localChecklist.checklist_type === 'thing_type') {
        return true;
      }
      return false;
    },
    // --- thing type checklists and job type checklists use different fields for headlines
    headlineFieldType() {
      if (this.isThingTypeChecklist) {
        return 'headline';
      }
      return 'group';
    },
  },
  mounted() {
    this.localChecklist = this.checklist;
    this.$setActiveTabFromURL('checklist');
  },
  methods: {
    clearSelectedTypes() {
      this.localChecklist.all_types = true;
      this.localChecklist.selected_types = [{ type: '', manufacturer: '' }];
    },
    backLink() {
      return `/installation_types/${this.installationTypeId}#tab-checklists`;
    },
    submit() {
      if (this.localChecklist.id) {
        this.updateViaAPI();
      } else {
        this.createViaAPI();
      }
    },
    checklistParams() {
      return {
        installation_type_id: this.installationTypeId,
        checklist_type: this.localChecklist.checklist_type,
        name: this.localChecklist.name,
        position: this.localChecklist.position,
        dataset: this.localChecklist.dataset,
        category: this.localChecklist.category,
        all_types: this.localChecklist.all_types,
        selected_types: this.localChecklist.selected_types,
      };
    },
    createViaAPI() {
      const that = this;
      that.serverErrors = [];
      axios.post(`/installation_types/${this.installationTypeId}/checklists.json`, {
        checklist: this.checklistParams(),
      }).then(() => {
        Flash.info('Checkliste hinzugefügt');
        window.location.href = this.backLink();
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.checklists;
        } else {
          Flash.error('Checkliste konnte nicht gespeichert werden');
        }
      });
    },
    updateViaAPI() {
      const that = this;
      that.serverErrors = [];
      axios.put(`/installation_types/${this.installationTypeId}/checklists/${this.localChecklist.id}.json`, {
        checklist: this.checklistParams(),
      }).then(() => {
        Flash.info('Checkliste gespeichert');
        // window.location.href = this.backLink();
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.checklists;
        } else {
          Flash.error('Checkliste konnte nicht gespeichert werden');
        }
      });
    },
  },
};
</script>
