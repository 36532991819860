<template>
  <span>
    <input
      id="file-upload"
      :accept="accept"
      name="file_upload"
      type="file"
      style="display: none;"
      @change="onChange"
    >

    <label for="file-upload" class="fx-btn-primary">
      <i class="fas fa-fw fa-cloud-upload" />&nbsp;
      <span>{{ text }}</span>
    </label>
    <div v-if="showFilename && value">{{ truncate(value, 50) }}</div>
  </span>
</template>

<script>
import { TruncateFormatter } from 'mixins/formatters';

export default {
  name: 'FileUploadButton',
  mixins: [TruncateFormatter],
  props: {
    accept: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: 'Datei auswählen',
    },
    directUpload: {
      type: Boolean,
      default: false,
    },
    showFilename: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      value: null,
    };
  },
  computed: {
  },
  methods: {
    onChange(event) {
      const input = event.target;

      if (input.value && !this.directUpload) {
        this.value = input.value.split('\\').pop();
      } else {
        this.value = null;
      }

      this.$emit('change', event);
    },
  },
};
</script>

<style>
</style>
