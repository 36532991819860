<template>
  <div class="fx-card-contact">
    <div class="float-right" style="padding: 0 0 0 .2rem;">
      <drop-down classes="fx-context-circle" :disabled="false">
        <drop-down-item v-if="addCustomerId && !removeCustomerId">
          <a @click.prevent="$emit('add-customer', contact)"><i
            class="fas fa-briefcase fa-fw"
          /> Mit Kunden verknüpfen</a>
        </drop-down-item>
        <drop-down-item v-if="removeInstallationId">
          <a @click.prevent="$emit('remove-installation', contact)"><i
            class="far fa-times fa-fw"
          /> Von Anlage entfernen</a>
        </drop-down-item>
        <drop-down-item v-if="removeCustomerId">
          <a @click.prevent="$emit('remove-customer', contact)"><i
            class="far fa-times fa-fw"
          /> Vom Kunden entfernen</a>
        </drop-down-item>
        <drop-down-item v-if="linkDetails">
          <a :href="`/contacts/${contact.id}`"><i class="fas fa-address-card fa-fw" />
            Details ansehen</a>
        </drop-down-item>
        <drop-down-item v-if="canEdit">
          <a @click.prevent="$emit('open-edit-contact', contact)"><i
            class="far fa-pencil-alt fa-fw"
          /> Bearbeiten</a>
        </drop-down-item>
        <drop-down-item v-if="canEdit">
          <hr>
        </drop-down-item>
        <drop-down-item v-if="canEdit">
          <a @click.prevent="$emit('open-confirm-delete', contact)"><i
            class="far fa-trash-alt fa-fw"
          /> Kontakt löschen</a>
        </drop-down-item>
      </drop-down>
    </div>
    <div>
      <div style="margin-top: 10px; font-weight: 500;">
        <a
          v-if="showLinkDetails"
          :href="`/contacts/${contact.id}`"
          class="no-color"
        >{{ contact.name }}</a>
        <span v-else>{{ contact.name }}</span>
      </div>
      <div class="secondary">
        {{ contact.company }}
      </div>
    </div>
    <div style="margin-top: 10px;">
      <div v-if="contact.email">
        <i class="fas fa-envelope fa-fw fx-inline-ico" /> <a
          :href="`mailto:${contact.email}`"
          class="no-color"
        >{{ contact.email }}</a>
      </div>
      <div v-if="contact.phone">
        <i class="fas fa-phone-alt fa-fw fx-inline-ico" /> <a
          :href="`tel:${contact.phone}`"
          class="no-color"
        >{{ contact.phone }}</a>
      </div>
      <div v-if="contact.mobile">
        <i class="fas fa-mobile-alt fa-fw fx-inline-ico" /> <a
          :href="`tel:${contact.mobile}`"
          class="no-color"
        >{{ contact.mobile }}</a>
      </div>
      <div v-if="contact.fax">
        <i class="fas fa-fax fa-fw fx-inline-ico" /> {{ contact.fax }} (Fax)
      </div>
    </div>
    <div style="margin: 10px 0; white-space: pre-line; font-style: italic;">
      {{ contact.note }}
    </div>
    <div v-if="withStats" style="text-align:center;">
      <a v-if="showLinkDetails" class="no-color" @click="$emit('open-references', contact)">
        <span v-tippy="customerCountTooltip" :class="customerCountClass" style="margin-right: 5px;"><i
          class="fas fa-briefcase"
        /> {{ contact.customers_count }}</span>
        <span
          v-tippy="installationContactCountTooltip"
          :class="installationsContactCountClass"
          style="margin: 0 5px;"
        ><i
           class="fas fa-sitemap"
         />
          {{ contact.installation_contacts_count }}</span>
        <span
          v-tippy="installationReportRecipientCountTooltip"
          :class="installationsReportRecipientCountClass"
          style="margin-left: 5px;"
        ><i class="far fa-file-export" />
          {{ contact.installation_report_recipients_count }}</span>
      </a>

      <span v-else>
        <span v-tippy="customerCountTooltip" :class="customerCountClass" style="margin-right: 5px;"><i
          class="fas fa-briefcase"
        /> {{ contact.customers_counts }}</span>
        <span
          v-tippy="installationContactCountTooltip"
          :class="installationsContactCountClass"
          style="margin: 0 5px;"
        ><i class="fas fa-sitemap" />
          {{ contact.installation_contacts_count }}</span>
        <span
          v-tippy="installationReportRecipientCountTooltip"
          :class="installationsReportRecipientCountClass"
          style="margin-left: 5px;"
        ><i class="far fa-file-export" />
          {{ contact.installation_report_recipients_count }}</span>
      </span>
    </div>
  </div>
</template>

<script>
import UserModel from 'apps/application/user_model';
import { useCurrentUserStore } from 'stores/current_user';

import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';

export default {
  name: 'ContactCard',
  components: {
    DropDown,
    DropDownItem,
  },
  props: {
    contact: {
      type: Object,
      required: true,
    },
    removeInstallationId: {
      type: String,
      default: null,
    },
    removeCustomerId: {
      type: String,
      default: null,
    },
    addCustomerId: {
      type: String,
      default: null,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    withStats: {
      type: Boolean,
      default: true,
    },
    linkDetails: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'open-edit-contact',
    'open-confirm-delete',
    'open-references',
    'add-customer',
    'remove-installation',
    'remove-customer',
  ],
  setup() {
    return {
      currentUser: useCurrentUserStore().currentUser,
    };
  },
  computed: {
    showLinkDetails() {
      return this.linkDetails && UserModel.isAdminOrManage(this.currentUser);
    },
    hasNote() {
      return (this.contact.note != null && this.contact.note.length > 0);
    },
    installationContactCountTooltip() {
      return {
        content: `Ansprechpartner für ${this.contact.installation_contacts_count} Anlagen`,
        placement: 'top',
        duration: 100,
        delay: [200, 0],
        arrow: true,
      };
    },
    installationReportRecipientCountTooltip() {
      return {
        content: `Berichtsempfänger für ${this.contact.installation_report_recipients_count} Anlagen`,
        placement: 'top',
        duration: 100,
        delay: [200, 0],
        arrow: true,
      };
    },
    installationsContactCountClass() {
      if (this.contact.installation_contacts_count === 0) {
        return 'secondary';
      }
      return '';
    },
    installationsReportRecipientCountClass() {
      if (this.contact.installation_report_recipients_count === 0) {
        return 'secondary';
      }
      return '';
    },
    customerCountTooltip() {
      return {
        content: `Kontakt für ${this.contact.customers_count} Kunden`,
        placement: 'top',
        duration: 100,
        delay: [200, 0],
        arrow: true,
      };
    },
    customerCountClass() {
      if (this.contact.customers_count === 0) {
        return 'secondary';
      }
      return '';
    },
  },
  methods: {
    nl2br(text) {
      // eslint-disable-next-line no-useless-concat
      return (`${text}`).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>' + '$2');
    },
  },
};
</script>

<style>
</style>
