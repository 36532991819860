<template>
  <div class="fx-flex-grid">
    <div class="fx-flex-grid-item">
      <div id="anual-report" class="fx-card-analysis">
        <h2 class="fx">
          <i class="fas fa-chart-line" style="margin-right: 1rem;" />Jahresbericht
        </h2>
        <p>
          Dieser Bericht eignet sich besonders für Anlagen, deren Komponenten einmal im Jahr geprüft werden müssen (BMA).
        </p>
        <div style="position:absolute; bottom: 15px; margin-top: 1rem;">
          <div v-if="currentUser.pro">
            <a class="fx-btn-secondary" @click="openExportYearProgressModal">Bericht erzeugen</a>
          </div>
          <ProFeatureHint v-else />
        </div>
      </div>
    </div>

    <div class="fx-flex-grid-item">
      <div id="incident-report" class="fx-card-analysis">
        <h2 class="fx">
          <Icon id="incidents" class="fa-fw" style="margin-right: .8rem;" />Störungen
        </h2>
        <p>
          Liste aller Störungen, die an dieser Anlage aufgetreten sind.
        </p>
        <div style="position:absolute; bottom: 15px; margin-top: 1rem;">
          <div v-if="currentUser.pro">
            <a class="fx-btn-secondary" @click="openExportModalIncidents">Liste exportieren</a>
          </div>
          <ProFeatureHint v-else />
        </div>
      </div>
    </div>

    <div class="fx-flex-grid-item">
      <div id="service-report" class="fx-card-analysis">
        <h2 class="fx">
          <Icon id="articles" class="fa-fw" style="margin-right: .8rem;" />Material und Leistungen
        </h2>
        <p>
          Liste aller Artikelpositionen über alle Aufträge hinweg, die für diese Anlage dokumentiert wurden.
        </p>
        <div style="position:absolute; bottom: 15px; margin-top: 1rem;">
          <div v-if="currentUser.pro">
            <a class="fx-btn-secondary" @click="openExportModalJobArticles">Liste exportieren</a>
          </div>
          <ProFeatureHint v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import openExportModal from 'helpers/open_export_modal';
import Icon from 'components/icon.vue';
import ProFeatureHint from 'components/pro_feature_hint.vue';
import { useCurrentUserStore } from 'stores/current_user';

export default {
  name: 'TabAnalysis',
  components: {
    Icon,
    ProFeatureHint,
  },
  props: {
    installationId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const openExportYearProgressModal = () => openExportModal({
      title: 'Jahresbericht',
      exporttype: 'installation_year_progress',
      intro: 'Dieser Bericht eignet sich besonders für Anlagen, deren Komponenten einmal im Jahr geprüft werden müssen (BMA)',
      exportableId: props.installationId,
    });

    const openExportModalIncidents = () => openExportModal({
      title: 'Störungen der Anlage exportieren',
      exporttype: 'incidents',
      intro: 'Exportiert alle Störungen, die an dieser Anlage dokumentiert wurden.',
      config: { installationId: props.installationId },
    });

    const openExportModalJobArticles = () => openExportModal({
      title: 'Artikel-Positionen exportieren',
      exporttype: 'job_article_sums',
      intro: 'Exportiert die Summe der Artikel-Positionen für alle abgeschlossenen Aufträge.',
      config: { installationId: props.installationId },
    });

    return {
      openExportYearProgressModal,
      openExportModalIncidents,
      openExportModalJobArticles,
      currentUser: useCurrentUserStore().currentUser,
    };
  },
};
</script>
