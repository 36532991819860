<template>
  <div class="fx-modal-content-small">
    <div v-if="!running">
      <h3 class="fx">
        Import aus CLSS starten?
      </h3>
      <p>
        Der Import fügt Komponenten aus dem CLSS hinzu. Bestehende Komponenten werden dabei nicht
        verändert.
      </p>
      <input
        id="with_trace"
        v-model="withTrace"
        type="checkbox"
        name="with_trace"
      > <label for="with_trace">Trace in
        Dateien
        an der Anlage sichern</label>
      <br><br>
      <button class="fx-btn-primary" @click="startImport()">
        Import starten
      </button>
    </div>

    <div v-if="running">
      <div class="text-center">
        <i class="fas fa-circle-notch fa-spin fa-2x secondary" />
        <h3 class="fx">
          Import läuft
        </h3>
        <p class="secondary">
          Bitte haben Sie einen Moment Geduld.
        </p>
        <button class="fx-btn-secondary float-right" @click.prevent="cancelled()">
          Nicht weiter warten
        </button>
      </div>
      <div class="clearfix" />
    </div>
  </div>
</template>

<script>

import axios from 'axios';

export default {
  name: 'ImportViaIntegrationModal',
  props: {
    integration: {
      type: Object,
      required: true,
    },
    pollingSidekiqMillis: {
      type: Number,
      default: 3000,
    },
    maxPollingTries: {
      type: Number,
      default: 50,
    },
    installationId: {
      type: String,
      required: true,
    },
  },
  emits: ['cancelled', 'finished'],
  data() {
    return {
      running: false,
      withTrace: false,
      pollingTimeout: null,
      pollingCount: 0,
      sidekiqJobId: null,
    };
  },
  unmounted() {
    if (this.pollingTimeout) {
      clearTimeout(this.pollingTimeout);
    }
  },
  methods: {
    pollSidekiqStatus() {
      this.pollingCount += 1;
      if (this.pollingCount > this.maxPollingTries) {
        this.cancelled();
        return;
      }

      this.pollingTimeout = setTimeout(() => {
        const that = this;
        axios.get('/worker_status', {
          params: {
            job_id: this.sidekiqJobId,
          },
        }).then((response) => {
          if (response.status === 200) {
            that.finished();
          } else {
            that.pollSidekiqStatus();
          }
        }).catch(() => {
          that.cancelled();
        });
      }, this.pollingSidekiqMillis);
    },
    cancelled() {
      this.$emit('cancelled', this.integration);
    },
    finished() {
      this.$emit('finished', this.integration);
    },
    startImport() {
      axios.post(
        `/installations/${this.installationId}/integrations/${this.integration.id}/import.json`,
        { with_trace: this.withTrace },
      ).then((response) => {
        this.sidekiqJobId = response.data.sidekiq_job_id;
        this.running = true;
        this.pollSidekiqStatus();
      });
    },
  },
};
</script>
