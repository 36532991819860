<template>
  <div class="fx-modal-content-medium">
    <template v-if="!resultingInstallation">
      <a class="fx-modal__close" @click="$emit('close')">&times;</a>
      <h2 class="fx">
        Anlage hinzufügen
      </h2>

      <hr style="margin-top:0;">
      <ServerErrors v-model="serverErrors" title="Anlage konnte nicht gespeichert werden" margin-bottom="1rem" />

      <form>
        <div v-if="showCustomerSelect" class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline mandatory" for="customer">Kunde</label>
          </div>
          <div class="fx-form-content">
            <DBBasedSelect
              v-model="localCustomerId"
              icon-id="customer"
              i18n-base-key="customers"
              :default-label="$t('comp.select.no-selection')"
              load-options-url="/customers/for_select"
              :readonly="customerReadonly"
              @changed="onCustomerSelected"
            />
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline mandatory" for="location_name">Objekt</label>
          </div>
          <div class="fx-form-content">
            <template v-if="localCustomerId || localLocationId">
              <DBBasedSelect
                v-model="localLocationId"
                icon-id="location"
                i18n-base-key="locations"
                :default-label="$t('comp.select.no-selection')"
                load-options-url="/select/locations"
                :load-options-params="{ customer_id: localCustomerId }"
                :readonly="locationReadonly"
                @changed="onLocationSelected"
              />
              <div v-if="!locationReadonly" class="form-field-hint">
                Wählen Sie ein Objekt des Kunden, oder <a
                  class="fx-btn-secondary"
                  style="padding: .5rem"
                  @click="$emit('add-location', localCustomerId)"
                >Neues Objekt anlegen</a>
              </div>
            </template>
            <template v-else>
              <div class="form-field-hint">
                Wählen Sie zuerst einen Kunden und dann ein Objekt des Kunden als Standort der
                Anlage.
              </div>
            </template>
          </div>
        </div>

        <hr>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline mandatory" for="installation_type">Wartungsanwendung</label>
          </div>
          <div class="fx-form-content">
            <DBBasedSelect
              v-model="localInstallationTypeId"
              icon-id="installation_type"
              :default-label="$t('comp.select.no-selection')"
              i18n-base-key="installation_types"
              load-options-url="/installation_types/for_select"
              @changed="onInstallationTypeSelected"
            />
            <div class="form-field-hint">
              Im Bereich Administration können Sie <a href="/installation_types">weitere
                Wartungsanwendungen anlegen</a>.
            </div>
          </div>
        </div>

        <div v-if="selectedInstallationTypeHasTemplates" class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline" for="installation_type">Vorlage</label>
          </div>
          <div class="fx-form-content">
            <select v-model="installationTemplateId">
              <option />
              <option v-for="template in availableTemplates" :key="template.id" :value="template.id">
                {{
                  template.name }}
              </option>
            </select>
            <div class="form-field-hint">
              Die Komponenten aus der Vorlage werden automatisch angelegt.
            </div>
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline" for="installation_number">Anlagennummer</label>
          </div>
          <div class="fx-form-content">
            <input
              id="installation_number"
              v-model="number"
              name="installation_number"
              placeholder="optional"
              type="text"
            >
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline" for="installation_name">Name</label>
          </div>
          <div class="fx-form-content">
            <input
              id="installation_name"
              v-model="name"
              name="installation_name"
              placeholder="optional"
              type="text"
            >
          </div>
        </div>

        <hr>
        <TransactionButton
          id="btn-save-installation"
          v-model="processing"
          class="fx-btn-primary float-right"
          title="Speichern"
          style="margin-bottom: 0.5rem; margin-left: 0.5rem;"
          @click="createViaAPI()"
        />
        <button class="fx-btn-secondary float-right" @click.prevent="$emit('close')">
          Abbrechen
        </button>
        <div class="clearfix" />
      </form>
    </template>
    <SuccessState v-if="resultingInstallation" title="Anlage hinzugefügt">
      <div class="fx-btn-group">
        <button class="fx-btn-secondary" @click.prevent="$emit('close')">
          Fenster schließen
        </button>
        <a class="fx-btn-primary" :href="installationPath(resultingInstallation.id)">Anlage ansehen</a>
      </div>
    </SuccessState>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import Flash from 'flash/index';
import FoxtagPaths from 'mixins/foxtag_paths';

import DBBasedSelect from 'components/select/db_based_select.vue';

import SuccessState from 'components/success_state.vue';
import TransactionButton from 'components/transaction_button.vue';
import ServerErrors from 'components/partials/server_errors.vue';

export default {
  name: 'AddInstallationModal',
  components: {
    SuccessState,
    TransactionButton,
    ServerErrors,
    DBBasedSelect,
  },
  mixins: [FoxtagPaths],
  props: {
    customerId: {
      type: String,
      default: null,
    },
    locationId: {
      type: String,
      default: null,
    },
    installationTypeId: {
      type: String,
      default: null,
    },
  },
  emits: ['close', 'created', 'add-location'],
  data() {
    return {
      localCustomerId: null,
      localLocationId: null,
      localInstallationTypeId: null,
      installationTemplateId: null,
      number: null,
      name: null,
      serverErrors: [],
      resultingInstallation: null,
      localInstallationTemplates: [],
      processing: false,
      customerReadonly: false,
      locationReadonly: false,
    };
  },
  computed: {
    selectedInstallationTypeHasTemplates() {
      return this.availableTemplates.length > 0;
    },
    availableTemplates() {
      return _.filter(this.localInstallationTemplates, { installation_type_id: this.localInstallationTypeId });
    },
    showCustomerSelect() {
      // customer is optional and we do not want to show the select element if a location is pre set w/o customer
      return (this.customerId || !this.locationId);
    },
  },
  mounted() {
    this.localLocationId = this.locationId;
    this.localCustomerId = this.customerId;
    this.localInstallationTypeId = this.installationTypeId;

    this.customerReadonly = (this.customerId != null); // given customer means, you cannot change it
    this.locationReadonly = (this.locationId != null); // given location means, you cannot change it

    this.loadInstallationTemplates();
  },
  methods: {
    onCustomerSelected(customerId) {
      this.localCustomerId = customerId;
      this.localLocationId = null; // location does not belong to customer anymore -> reset
    },
    onLocationSelected(locationId) {
      this.localLocationId = locationId;
    },
    onInstallationTypeSelected(installationTypeId) {
      this.localInstallationTypeId = installationTypeId;
    },
    loadInstallationTemplates() {
      const that = this;
      axios.get('/installation_templates.json').then((response) => {
        that.localInstallationTemplates = response.data.installation_templates;
      });
    },
    closeAfterCreated() {
      this.$emit('close');
    },
    createViaAPI() {
      const that = this;
      that.serverErrors = [];
      that.processing = true;
      axios.post('/installations.json', {
        installation: {
          location_id: this.localLocationId,
          installation_type_id: this.localInstallationTypeId,
          number: this.number,
          name: this.name,
        },
        installation_template_id: this.installationTemplateId,
      }).then((response) => {
        this.resultingInstallation = response.data.installation;
        this.$emit('created', this.resultingInstallation);
        that.processing = false;
      }).catch((error) => {
        if (error.response && error.response.status === 400) {
          that.serverErrors = error.response.data.installations;
        } else {
          Flash.error('Fehler beim Speichern der Anlage');
        }
        that.processing = false;
      });
    },
  },
};
</script>
