<template>
  <div v-if="invite" class="fx-grid-narrow">
    <div class="fx-col">
      <div class="no-distraction__bubble">
        <h1 class="no-distraction__title">
          {{ title }}
        </h1>

        <!-- Option 1: Somebody is logged in with another email: "please logout" -->
        <div v-if="otherAccountIsLoggedIn">
          <div id="logout-prompt" class="no-distraction__intro">
            Sie sind mit der E-Mail-Adresse <strong>{{ currentUser.email }}</strong> angemeldet, aber diese Einladung wurde an
            <strong>{{ invite.recipient_email }}</strong> gesendet.
            <br><br>
            Um einen zusätzlichen Account als {{ invite.recipient_email }} zu erstellen, loggen Sie sich zuerst aus und
            <strong>öffnen Sie den Einladungs-Link erneut</strong>.
            <div class="mt-4">
              <a class="fx-btn-primary" href="/accounts/sign_out">Logout</a>
            </div>
          </div>
        </div>

        <!-- Option 2: Not logged in, but invite is for a email that has already registered (existing account) -->
        <div v-if="inviteExistingAccountButNotLoggedId">
          <div id="login-with-prompt" class="no-distraction__intro">
            Diese Einladung wurde an <strong>{{ invite.recipient_email }}</strong> gesendet, um einen
            zusätzlichen Firmenzugang zu einem bestehenden Nutzeraccount hinzuzufügen.
            <br><br>
            Bitte loggen Sie sich als <strong>{{ invite.recipient_email }}</strong> ein, um die Einladung anzunehmen.
            <div class="mt-4">
              <a class="fx-btn-primary" href="/accounts/sign_in">Login</a>
            </div>
          </div>
        </div>

        <!-- Option 3: Not logged in, invite is for new user: "create account" -->
        <div v-if="inviteNewAccountButNotLoggedId">
          <CreateNewAccount
            id="create-acc-prompt"
            :invite="invite"
            :success-page="`/users/${invite.recipient_id}/welcome`"
            :show-edit-organization-name="isPortal"
          />
        </div>

        <!-- Option 4: Invite is for the account that is currently logged in (add new user to existing account) -->
        <div v-if="sameAccountIsLoggedIn">
          <div id="same-acc-prompt" class="no-distraction__intro">
          Diese Einladung fügt einen zusätzlichen Firmenzugang zu Ihrem bestehenden Nutzeraccount hinzu.
          <br><br>
          <div class="mt-4">
            <a class="fx-btn-primary" @click="addInviteToExistingAccount()">Firmenzugang hinzufügen</a>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import Flash from 'flash';
import { includes } from 'lodash';
import { ref } from 'vue';
import { useCurrentUserStore } from 'stores/current_user';

import CreateNewAccount from './create_new_account.vue';

export default {
  name: 'InviteShow',
  components: {
    CreateNewAccount,
  },

  props: {
    token: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const invite = ref(null);
    const isPortal = ref(false);

    const loadInvite = () => {
      axios.get(`/invites/${props.token}.json`)
        .then((response) => {
          invite.value = response.data.invite;

          isPortal.value = includes(invite.value.roles, 'portal');
        });
    };

    const addInviteToExistingAccount = () => {
      axios.put(`/invites/${props.token}/add_to_account.json`).then(() => {
        window.location.href = `/users/${invite.value.recipient_id}/welcome`;
      }).catch(() => {
        Flash.error('Fehler beim Hinzufügen des Firmenzugangs');
      });
    };

    return {
      currentUser: useCurrentUserStore().currentUser,
      invite,
      isPortal,
      loadInvite,
      addInviteToExistingAccount,
    };
  },
  computed: {
    sameAccountIsLoggedIn() {
      return this.currentUser && this.currentUser.email === this.invite.recipient_email;
    },
    otherAccountIsLoggedIn() {
      return this.currentUser && this.currentUser.email !== this.invite.recipient_email;
    },
    inviteExistingAccountButNotLoggedId() {
      return !this.currentUser && this.invite.email_already_signed_up;
    },
    inviteNewAccountButNotLoggedId() {
      return !this.currentUser && !this.invite.email_already_signed_up;
    },
    title() {
      if (this.isPortal) {
        return `Zugang zum Auftraggeberportal von ${this.invite.organization_name}`;
      }
      return `Firmenzugang zu ${this.invite.organization_name}`;
    },
  },
  mounted() {
    this.loadInvite();
  },
};
</script>
