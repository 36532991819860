<template>
  <tr class="t-thing-row">
    <td :class="thingNumberClasses">
      {{ thingNumber(thing, groupSeparator) }}
    </td>
    <td>
      <div class="fx_thing_type_with_icon">
        {{ thing.thing_type.name }}
        <div class="secondary">
          {{ thingMake(thing) }}
        </div>
      </div>
    </td>
    <td>
      <div>
        <ExternalLinkButton :href="thing.thing_type.link" />
        <span
          v-for="(feature, index) in thing.assigned_features"
          :key="index"
        ><span
          class="fx-label mb-2"
          style="background-color: #2C556E; color: #fff;"
        >{{ feature.symbol }}</span>&shy;
        </span>
      </div>
      <ThingDetails :thing="thing" :tag-label="tagLabel" />
    </td>
    <td v-if="showDueDates">
      <NextCheckInfo
        v-if="hasCheckInterval(thing)"
        :next-date="thing.next_check_at"
        :next-status="thing.next_check_status"
        :with-unknown-hint="true"
      />
      <NextCheckInfo
        v-if="hasReplacementInterval(thing)"
        :is-replacement="true"
        :next-date="thing.next_replacement_at"
        :next-status="thing.next_replacement_status"
        :with-unknown-hint="true"
      />
    </td>
    <td style="text-align: right;">
      <template v-if="editable">
        <div class="fx-btn-group">
          <a class="fx-btn-skeleton" @click.prevent="$emit('open-edit-thing-modal', thing)">bearbeiten</a>
          <drop-down>
            <drop-down-item><a :href="thingUrl"><i class="far fa-cube fa-fw" /> Details anzeigen</a></drop-down-item>
            <drop-down-item v-if="features.incidentsPro">
              <a @click="openNewIncidentModal(installationId, thing.id)"><i class="fas fa-exclamation-triangle fa-fw" /> Störung erfassen</a>
            </drop-down-item>
            <drop-down-item><hr></drop-down-item>
            <drop-down-item v-if="!isSubThing(thing)">
              <a @click.prevent="$emit('open-new-subthing-modal', thing)"><i class="far fa-plus fa-fw" /> Sub-Komponente hinzufügen</a>
            </drop-down-item>
            <drop-down-item>
              <a @click.prevent="$emit('open-add-installation-date-modal', thing)"><i class="far fa-calendar-alt fa-fw" /> Inbetriebnahme-Datum setzen</a>
            </drop-down-item>
            <drop-down-item><a @click.prevent="$emit('duplicate', thing)"><i class="far fa-clone fa-fw" /> Duplizieren</a></drop-down-item>

            <drop-down-item><hr></drop-down-item>
            <drop-down-item v-if="hasStock">
              <a @click.prevent="$emit('open-replace-modal', thing)"><i class="far fa-sync fa-fw" /> Tauschen</a>
            </drop-down-item>
            <drop-down-item>
              <a @click.prevent="$emit('open-remove-modal', thing)"><i class="far fa-sign-out fa-fw" /> Außer Betrieb nehmen</a>
            </drop-down-item>

            <drop-down-item><hr></drop-down-item>
            <drop-down-item>
              <a @click.prevent="$emit('open-confirm-delete-thing-modal', thing)">
                <i class="far fa-trash-alt fa-fw" /> Löschen
              </a>
            </drop-down-item>
          </drop-down>
        </div>
      </template>
      <template v-if="showPortalAddIncidentButtons">
        <a class="fx-btn-skeleton" :href="portalAddIncidentUrl">Störung melden</a>
      </template>
    </td>
  </tr>
</template>

<script>
import FeatureFlipper from 'mixins/feature_flipper';

import { useCurrentUserStore } from 'stores/current_user';
import openNewIncidentModal from 'helpers/open_new_incident_modal';

import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';
import NextCheckInfo from 'components/partials/next_check_info.vue';
import ThingDetails from 'components/partials/thing_details.vue';
import ExternalLinkButton from 'components/external_link_button.vue';

import ThingPresenter from 'mixins/thing_presenter';

export default {
  name: 'SingleThing',
  components: {
    DropDown,
    DropDownItem,
    NextCheckInfo,
    ThingDetails,
    ExternalLinkButton,
  },
  mixins: [ThingPresenter, FeatureFlipper],
  props: {
    installationId: {
      type: String,
      required: true,
    },
    thing: {
      type: Object,
      required: true,
    },
    groupSeparator: {
      type: String,
      default: '/',
    },
    tagLabel: {
      type: String,
      default: 'Foxtag-ID',
    },
    hasStock: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    showDueDates: {
      type: Boolean,
      default: true,
    },
    showPortalAddIncidentButtons: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'open-edit-thing-modal',
    'open-new-subthing-modal',
    'open-add-installation-date-modal',
    'duplicate',
    'open-replace-modal',
    'open-remove-modal',
    'open-confirm-delete-thing-modal',
  ],
  setup() {
    return {
      openNewIncidentModal,
      currentUser: useCurrentUserStore().currentUser,
    };
  },
  computed: {
    thingUrl() {
      return `/things/${this.thing.id}`;
    },
    addIncidentUrl() {
      return `/incidents/new?thing_id=${this.thing.id}`;
    },
    portalAddIncidentUrl() {
      return `/portal/incidents/new/?installation_id=${this.installationId}&thing_id=${this.thing.id}`;
    },
    thingNumberClasses() {
      if (this.isSubThing(this.thing)) {
        return 'text-right';
      }
      return '';
    },
  },
};
</script>
