import { ref } from 'vue';
import { findIndex } from 'lodash';

class EntityList {
  #list = null;

  constructor(initList = ref([])) {
    this.#list = initList;
  }

  get reactiveList() {
    return this.#list;
  }

  get value() {
    return this.#list.value;
  }

  set value(newValue) {
    this.#list.value = newValue;
  }

  indexOf(entity) {
    return findIndex(this.#list.value, { id: entity.id });
  }

  remove(entity) {
    const index = this.indexOf(entity);
    if (index !== -1) {
      this.#list.value.splice(index, 1);
    }
  }

  update(updatedEntity) {
    const index = this.indexOf(updatedEntity);
    if (index !== -1) {
      this.#list.value.splice(index, 1, updatedEntity);
    }
  }

  append(entity) {
    this.#list.value.push(entity);
  }

  reset() {
    this.#list.value = [];
  }
}

export default EntityList;
