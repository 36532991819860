<template>
  <span>
    {{ list.slice(0, limit).join(', ') }}
    <span v-if="showMore" class="secondary">und {{ moreCount }} weitere</span>
  </span>
</template>
<script>
export default {
  name: 'TruncatedTextList',
  props: {
    list: {
      type: Array,
      required: true,
    },
    limit: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    moreCount() {
      return this.list.length - this.limit;
    },
    showMore() {
      return this.list.length > this.limit;
    },
  },
};
</script>
