<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <h2 class="fx">
      Anlage als Vorlage speichern
    </h2>
    <hr>
    <p>
      Die Liste der Komponenten wird als Vorlage gespeichert.
      Beim Anlegen weiterer Anlagen wird Ihnen angeboten, die Komponenten aus dieser
      Vorlage automatisch zu Ihrer neuen Anlage hinzuzufügen.
      <help-article article-id="learn_more_installation_templates" :hide-modal-before-open="modalName">
        Mehr über Vorlagen erfahren
      </help-article>
    </p>
    <ServerErrors v-model="serverErrors" title="Vorlage konnte nicht gespeichert werden" margin-bottom="1rem" />
    <form @submit.prevent="submit">
      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory" for="thing_number">Name der Vorlage</label>
        </div>
        <div class="fx-form-content">
          <input v-model="templateName" type="text">
        </div>
      </div>

      <hr>
      <button type="submit" class="fx-btn-primary float-right" style="margin-bottom: 0.5rem; margin-left: 0.5rem;">
        Speichern
      </button>
      <button class="fx-btn-secondary float-right" @click.prevent="$emit('close')">
        Abbrechen
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import axios from 'axios';

import Flash from 'flash/index';
import ServerErrors from 'components/partials/server_errors.vue';

export default {
  name: 'SaveAsTemplateModal',
  components: {
    ServerErrors,
  },
  props: {
    installationId: {
      type: String,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      serverErrors: [],
      templateName: null,
    };
  },
  methods: {
    submit() {
      this.saveInstallationTemplateViaAPI();
    },
    saveInstallationTemplateViaAPI() {
      const that = this;
      that.serverErrors = [];
      axios.post(`/installations/${this.installationId}/save_as_template.json`, {
        installation_template_name: this.templateName,
      }).then(() => {
        Flash.info('Vorlage gespeichert');
        this.$emit('close');
      }).catch((error) => {
        if (error && error.response.status === 400) {
          that.serverErrors = error.response.data.installation_templates;
        } else {
          Flash.error('Vorlage konnte nicht gespeichert werden');
        }
      });
    },
  },
};
</script>
