<template>
  <div>
    <div class="fx-form-row">
      <div class="fx-form-label">
        <label class="inline" for="address_line1">Adresse</label>
      </div>
      <div class="fx-form-content">
        <input
          id="address_line1"
          v-model="modelValue.line1"
          name="address_line1"
          type="text"
        >
      </div>
    </div>
    <div class="fx-form-row">
      <div class="fx-form-content">
        <input
          id="address_line2"
          v-model="modelValue.line2"
          name="address_line2"
          type="text"
        >
      </div>
    </div>

    <div class="fx-form-row">
      <div class="fx-form-label">
        <label class="inline " for="address_zip">PLZ / Ort</label>
      </div>
      <div class="fx-form-content">
        <div class="div" style="width: 35%; float: left">
          <input
            id="address_zip"
            v-model="modelValue.zip"
            type="text"
            name="address_zip"
          >
        </div>
        <div class="div" style="width: 60%; float: right;">
          <input
            id="address_city"
            v-model="modelValue.city"
            class="has-form-field-hint"
            type="text"
            name="address_city"
          >
        </div>
        <div class="clearfix" />
      </div>
    </div>
    <div class="fx-form-row">
      <div class="fx-form-label">
        <label class="inline " for="address_country">Land</label>
      </div>
      <div class="fx-form-content">
        <select id="address_country" v-model="modelValue.country" name="address_country">
          <option value="" />
          <option value="DE">
            Deutschland
          </option>
          <option value="AT">
            Österreich
          </option>
          <option value="CH">
            Schweiz
          </option>
          <option value="LU">
            Luxemburg
          </option>
          <option value="BE">
            Belgien
          </option>
          <option value="IT">
            Italien
          </option>
          <option value="FR">
            Frankreich
          </option>
          <option disabled="disabled" value="---------------">
            ---------------
          </option>
          <option value="AF">
            Afghanistan
          </option>
          <option value="EG">
            Ägypten
          </option>
          <option value="AX">
            Åland-Inseln
          </option>
          <option value="AL">
            Albanien
          </option>
          <option value="DZ">
            Algerien
          </option>
          <option value="AS">
            Amerikanisch-Samoa
          </option>
          <option value="VI">
            Amerikanische Jungferninseln
          </option>
          <option value="AD">
            Andorra
          </option>
          <option value="AO">
            Angola
          </option>
          <option value="AI">
            Anguilla
          </option>
          <option value="AQ">
            Antarktis
          </option>
          <option value="AG">
            Antigua und Barbuda
          </option>
          <option value="GQ">
            Äquatorialguinea
          </option>
          <option value="AR">
            Argentinien
          </option>
          <option value="AM">
            Armenien
          </option>
          <option value="AW">
            Aruba
          </option>
          <option value="AZ">
            Aserbaidschan
          </option>
          <option value="ET">
            Äthiopien
          </option>
          <option value="AU">
            Australien
          </option>
          <option value="BS">
            Bahamas
          </option>
          <option value="BH">
            Bahrain
          </option>
          <option value="BD">
            Bangladesch
          </option>
          <option value="BB">
            Barbados
          </option>
          <option value="BE">
            Belgien
          </option>
          <option value="BZ">
            Belize
          </option>
          <option value="BJ">
            Benin
          </option>
          <option value="BM">
            Bermuda
          </option>
          <option value="BT">
            Bhutan
          </option>
          <option value="BO">
            Bolivien, Plurinationaler Staat
          </option>
          <option value="BQ">
            Bonaire, Sint Eustatius und Saba
          </option>
          <option value="BA">
            Bosnien und Herzegowina
          </option>
          <option value="BW">
            Botsuana
          </option>
          <option value="BV">
            Bouvet-Insel
          </option>
          <option value="BR">
            Brasilien
          </option>
          <option value="VG">
            Britische Jungferninseln
          </option>
          <option value="IO">
            Britisches Territorium im Indischen Ozean
          </option>
          <option value="BN">
            Brunei Darussalam
          </option>
          <option value="BG">
            Bulgarien
          </option>
          <option value="BF">
            Burkina Faso
          </option>
          <option value="BI">
            Burundi
          </option>
          <option value="CV">
            Cabo Verde
          </option>
          <option value="KY">
            Cayman-Inseln
          </option>
          <option value="CL">
            Chile
          </option>
          <option value="CN">
            China
          </option>
          <option value="CK">
            Cookinseln
          </option>
          <option value="CR">
            Costa Rica
          </option>
          <option value="CI">
            Côte d'Ivoire
          </option>
          <option value="CW">
            Curaçao
          </option>
          <option value="DK">
            Dänemark
          </option>
          <option value="CD">
            Demokratische Republik Kongo
          </option>
          <option value="DE">
            Deutschland
          </option>
          <option value="DM">
            Dominica
          </option>
          <option value="DO">
            Dominikanische Republik
          </option>
          <option value="DJ">
            Dschibuti
          </option>
          <option value="EC">
            Ecuador
          </option>
          <option value="SV">
            El Salvador
          </option>
          <option value="ER">
            Eritrea
          </option>
          <option value="EE">
            Estland
          </option>
          <option value="FK">
            Falklandinseln (Malwinen)
          </option>
          <option value="FO">
            Färöer-Inseln
          </option>
          <option value="FJ">
            Fidschi
          </option>
          <option value="FI">
            Finnland
          </option>
          <option value="FR">
            Frankreich
          </option>
          <option value="GF">
            Französisch-Guyana
          </option>
          <option value="PF">
            Französisch-Polynesien
          </option>
          <option value="TF">
            Französische Süd- und Antarktisgebiete
          </option>
          <option value="GA">
            Gabun
          </option>
          <option value="GM">
            Gambia
          </option>
          <option value="GE">
            Georgien
          </option>
          <option value="GH">
            Ghana
          </option>
          <option value="GI">
            Gibraltar
          </option>
          <option value="GD">
            Grenada
          </option>
          <option value="GR">
            Griechenland
          </option>
          <option value="GL">
            Grönland
          </option>
          <option value="GP">
            Guadeloupe
          </option>
          <option value="GU">
            Guam
          </option>
          <option value="GT">
            Guatemala
          </option>
          <option value="GG">
            Guernsey
          </option>
          <option value="GN">
            Guinea
          </option>
          <option value="GW">
            Guinea-Bissau
          </option>
          <option value="GY">
            Guyana
          </option>
          <option value="HT">
            Haiti
          </option>
          <option value="HM">
            Heard und McDonaldinseln
          </option>
          <option value="VA">
            Heiliger Stuhl (Staat Vatikanstadt)
          </option>
          <option value="HN">
            Honduras
          </option>
          <option value="HK">
            Hongkong
          </option>
          <option value="IN">
            Indien
          </option>
          <option value="ID">
            Indonesien
          </option>
          <option value="IM">
            Insel Man
          </option>
          <option value="IQ">
            Irak
          </option>
          <option value="IR">
            Iran, Islamische Republik
          </option>
          <option value="IE">
            Irland
          </option>
          <option value="IS">
            Island
          </option>
          <option value="IL">
            Israel
          </option>
          <option value="IT">
            Italien
          </option>
          <option value="JM">
            Jamaika
          </option>
          <option value="JP">
            Japan
          </option>
          <option value="YE">
            Jemen
          </option>
          <option value="JE">
            Jersey
          </option>
          <option value="JO">
            Jordanien
          </option>
          <option value="KH">
            Kambodscha
          </option>
          <option value="CM">
            Kamerun
          </option>
          <option value="CA">
            Kanada
          </option>
          <option value="KZ">
            Kasachstan
          </option>
          <option value="QA">
            Katar
          </option>
          <option value="KE">
            Kenia
          </option>
          <option value="KG">
            Kirgisistan
          </option>
          <option value="KI">
            Kiribati
          </option>
          <option value="CC">
            Kokos-(Keeling-)Inseln
          </option>
          <option value="CO">
            Kolumbien
          </option>
          <option value="KM">
            Komoren
          </option>
          <option value="CG">
            Kongo
          </option>
          <option value="KP">
            Korea, Demokratische Volksrepublik
          </option>
          <option value="KR">
            Korea, Republik
          </option>
          <option value="HR">
            Kroatien
          </option>
          <option value="CU">
            Kuba
          </option>
          <option value="KW">
            Kuwait
          </option>
          <option value="LA">
            Laos, Demokratische Volksrepublik
          </option>
          <option value="LS">
            Lesotho
          </option>
          <option value="LV">
            Lettland
          </option>
          <option value="LB">
            Libanon
          </option>
          <option value="LR">
            Liberia
          </option>
          <option value="LY">
            Libyen
          </option>
          <option value="LI">
            Liechtenstein
          </option>
          <option value="LT">
            Litauen
          </option>
          <option value="LU">
            Luxemburg
          </option>
          <option value="MO">
            Macao
          </option>
          <option value="MG">
            Madagaskar
          </option>
          <option value="MW">
            Malawi
          </option>
          <option value="MY">
            Malaysia
          </option>
          <option value="MV">
            Malediven
          </option>
          <option value="ML">
            Mali
          </option>
          <option value="MT">
            Malta
          </option>
          <option value="MA">
            Marokko
          </option>
          <option value="MH">
            Marshallinseln
          </option>
          <option value="MQ">
            Martinique
          </option>
          <option value="MR">
            Mauretanien
          </option>
          <option value="MU">
            Mauritius
          </option>
          <option value="YT">
            Mayotte
          </option>
          <option value="MK">
            Mazedonien, ehemalige jugoslawische Republik
          </option>
          <option value="MX">
            Mexiko
          </option>
          <option value="FM">
            Mikronesien, Föderierte Staaten von
          </option>
          <option value="MD">
            Moldau, Republik
          </option>
          <option value="MC">
            Monaco
          </option>
          <option value="MN">
            Mongolei
          </option>
          <option value="ME">
            Montenegro
          </option>
          <option value="MS">
            Montserrat
          </option>
          <option value="MZ">
            Mosambik
          </option>
          <option value="MM">
            Myanmar
          </option>
          <option value="NA">
            Namibia
          </option>
          <option value="NR">
            Nauru
          </option>
          <option value="NP">
            Nepal
          </option>
          <option value="NC">
            Neukaledonien
          </option>
          <option value="NZ">
            Neuseeland
          </option>
          <option value="NI">
            Nicaragua
          </option>
          <option value="NL">
            Niederlande
          </option>
          <option value="NE">
            Niger
          </option>
          <option value="NG">
            Nigeria
          </option>
          <option value="NU">
            Niue
          </option>
          <option value="MP">
            Nördliche Mariana-Inseln
          </option>
          <option value="NF">
            Norfolkinsel
          </option>
          <option value="NO">
            Norwegen
          </option>
          <option value="OM">
            Oman
          </option>
          <option value="AT">
            Österreich
          </option>
          <option value="PK">
            Pakistan
          </option>
          <option value="PS">
            Palästina, Staat
          </option>
          <option value="PW">
            Palau
          </option>
          <option value="PA">
            Panama
          </option>
          <option value="PG">
            Papua-Neuguinea
          </option>
          <option value="PY">
            Paraguay
          </option>
          <option value="PE">
            Peru
          </option>
          <option value="PH">
            Philippinen
          </option>
          <option value="PN">
            Pitcairn
          </option>
          <option value="PL">
            Polen
          </option>
          <option value="PT">
            Portugal
          </option>
          <option value="PR">
            Puerto Rico
          </option>
          <option value="RE">
            Réunion
          </option>
          <option value="RW">
            Ruanda
          </option>
          <option value="RO">
            Rumänien
          </option>
          <option value="RU">
            Russische Föderation
          </option>
          <option value="MF">
            Saint Martin (Französischer Teil)
          </option>
          <option value="BL">
            Saint-Barthélemy
          </option>
          <option value="SX">
            Saint-Martin (Niederländischer Teil)
          </option>
          <option value="SB">
            Salomoninseln
          </option>
          <option value="ZM">
            Sambia
          </option>
          <option value="WS">
            Samoa
          </option>
          <option value="SM">
            San Marino
          </option>
          <option value="ST">
            São Tomé und Príncipe
          </option>
          <option value="SA">
            Saudi-Arabien
          </option>
          <option value="SE">
            Schweden
          </option>
          <option value="CH">
            Schweiz
          </option>
          <option value="SN">
            Senegal
          </option>
          <option value="RS">
            Serbien
          </option>
          <option value="SC">
            Seychellen
          </option>
          <option value="SL">
            Sierra Leone
          </option>
          <option value="ZW">
            Simbabwe
          </option>
          <option value="SG">
            Singapur
          </option>
          <option value="SK">
            Slowakei
          </option>
          <option value="SI">
            Slowenien
          </option>
          <option value="SO">
            Somalia
          </option>
          <option value="GS">
            South Georgia und die Südlichen Sandwichinseln
          </option>
          <option value="ES">
            Spanien
          </option>
          <option value="LK">
            Sri Lanka
          </option>
          <option value="SH">
            St. Helena, Ascension und Tristan da Cunha
          </option>
          <option value="KN">
            St. Kitts und Nevis
          </option>
          <option value="LC">
            St. Lucia
          </option>
          <option value="PM">
            St. Pierre und Miquelon
          </option>
          <option value="VC">
            St. Vincent und die Grenadinen
          </option>
          <option value="ZA">
            Südafrika
          </option>
          <option value="SD">
            Sudan
          </option>
          <option value="SS">
            Südsudan
          </option>
          <option value="SR">
            Suriname
          </option>
          <option value="SJ">
            Svalbard und Jan Mayen
          </option>
          <option value="SZ">
            Swasiland
          </option>
          <option value="SY">
            Syrien, Arabische Republik
          </option>
          <option value="TJ">
            Tadschikistan
          </option>
          <option value="TW">
            Taiwan
          </option>
          <option value="TZ">
            Tansania, Vereinigte Republik
          </option>
          <option value="TH">
            Thailand
          </option>
          <option value="TL">
            Timor-Leste
          </option>
          <option value="TG">
            Togo
          </option>
          <option value="TK">
            Tokelau
          </option>
          <option value="TO">
            Tonga
          </option>
          <option value="TT">
            Trinidad und Tobago
          </option>
          <option value="TD">
            Tschad
          </option>
          <option value="CZ">
            Tschechische Republik
          </option>
          <option value="TN">
            Tunesien
          </option>
          <option value="TR">
            Türkei
          </option>
          <option value="TM">
            Turkmenistan
          </option>
          <option value="TC">
            Turks- und Caicosinseln
          </option>
          <option value="TV">
            Tuvalu
          </option>
          <option value="UG">
            Uganda
          </option>
          <option value="UA">
            Ukraine
          </option>
          <option value="HU">
            Ungarn
          </option>
          <option value="UM">
            United States Minor Outlying Islands
          </option>
          <option value="UY">
            Uruguay
          </option>
          <option value="UZ">
            Usbekistan
          </option>
          <option value="VU">
            Vanuatu
          </option>
          <option value="VE">
            Venezuela, Bolivarische Republik
          </option>
          <option value="AE">
            Vereinigte Arabische Emirate
          </option>
          <option value="US">
            Vereinigte Staaten
          </option>
          <option value="GB">
            Vereinigtes Königreich
          </option>
          <option value="VN">
            Vietnam
          </option>
          <option value="WF">
            Wallis und Futuna
          </option>
          <option value="CX">
            Weihnachtsinseln
          </option>
          <option value="BY">
            Weißrussland
          </option>
          <option value="EH">
            Westsahara
          </option>
          <option value="CF">
            Zentralafrikanische Republik
          </option>
          <option value="CY">
            Zypern
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AddressForm',
  props: {
    modelValue: {
      type: Object,
      default() {
        return {
          line1: null,
          line2: null,
          zip: null,
          city: null,
          country: null,
        };
      },
    },
  },
};
</script>
