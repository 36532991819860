<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <h2 class="fx">
      Störungsstatus bearbeiten
    </h2>
    <hr>

    <form>
      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory" for="incident_status">Status</label>
        </div>
        <div class="fx-form-content">
          <input
            id="incident_status_reported"
            v-model="localIncidentStatus.status"
            type="radio"
            value="reported"
          >
          <label for="incident_status_reported">{{ $t('activerecord.attributes.incident_status.statuses.reported')
          }}</label>
          <input
            id="incident_status_opened"
            v-model="localIncidentStatus.status"
            type="radio"
            value="opened"
          >
          <label for="incident_status_opened">{{ $t('activerecord.attributes.incident_status.statuses.opened') }}</label>
          <input
            id="incident_status_closed"
            v-model="localIncidentStatus.status"
            type="radio"
            value="closed"
          >
          <label for="incident_status_closed">{{ $t('activerecord.attributes.incident_status.statuses.closed') }}</label>
        </div>
      </div>

      <div v-if="localIncidentStatus.status == 'closed'" class="fx-form-row">
        <div class=" fx-form-label">
          <label class="inline mandatory" for="incident_status">Lösung</label>
        </div>

        <div class="fx-form-content">
          <input
            id="incident_resolution_resolved"
            v-model="localIncidentStatus.resolution"
            type="radio"
            value="resolved"
          >
          <label for="incident_resolution_resolved">{{
            $t('activerecord.attributes.incident_status.resolutions.resolved')
          }}</label>

          <input
            id="incident_resolution_wontfix"
            v-model="localIncidentStatus.resolution"
            type="radio"
            value="wontfix"
          >
          <label for="incident_resolution_wontfix">{{
            $t('activerecord.attributes.incident_status.resolutions.wontfix')
          }}</label>
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline" for="incident_statusdate">
            Datum<span style="color: red"> *</span> / Uhrzeit
          </label>
        </div>
        <div class="fx-form-content">
          <DateAndTimeInput v-model="localIncidentStatus.statusdate" @changed="onChangeStatusdate" />
        </div>
      </div>

      <div class="fx-form-row">
        <label class="inline" for="incident_note">Hinweis am Status</label>
        <textarea
          id="incident_note"
          ref="noteTextArea"
          v-model="localIncidentStatus.note"
          name="note"
          rows="10"
        />
      </div>

      <button class="fx-btn-primary float-right" style="margin-bottom: 0.5rem;" @click.prevent="updateIncidentStatus">
        Speichern
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash';
import DateAndTimeInput from 'components/date_and_time_input.vue';

export default {
  name: 'EditIncidentStatusModal',
  components: {
    DateAndTimeInput,
  },
  props: {
    incidentId: {
      type: String,
      required: true,
    },
    incidentStatus: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'updated'],
  data() {
    return {
      localIncidentStatus: JSON.parse(JSON.stringify(this.incidentStatus)),
    };
  },
  watch: {
    status: {
      handler(newVal) {
        if (newVal === 'closed') {
          this.resolution = 'resolved';
        } else {
          this.resolution = 'unresolved';
        }
      },
    },
  },
  mounted() {
    this.$refs.noteTextArea.focus();
  },
  methods: {
    onChangeStatusdate(value) {
      this.localIncidentStatus.statusdate = value;
    },
    updateIncidentStatus() {
      axios.patch(`/incidents/${this.incidentId}/incident_statuses/${this.localIncidentStatus.id}`, {
        statusdate: this.localIncidentStatus.statusdate,
        status: this.localIncidentStatus.status,
        note: this.localIncidentStatus.note,
        resolution: this.localIncidentStatus.resolution,
      }).then((response) => {
        this.$emit('updated', response.data.status);
        Flash.info('Status aktualisiert');
      }).catch(() => {
        Flash.error('Fehler beim Aktualisieren des Status');
      });
    },
  },
};
</script>
