<template>
  <div id="invite-modal" class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <h2 class="fx">
      Mitarbeiter einladen
    </h2>
    <hr>

    <div v-if="licenseInfoLoaded">
      <div v-if="!licenseInfo.can_invite_without_license_restrictions" style="border-bottom: solid 1px #ccc; margin-bottom: 1rem; padding-bottom: 1rem;">
        <div v-if="licensesAvailable">
          <strong>Sie haben {{ licenseInfo.free_licensed_users_count }} verfügbare Nutzerlizenzen.</strong>
          &nbsp;<a href="/licenses">Lizenzen ansehen</a>
        </div>
        <div v-if="!licensesAvailable">
          <strong style="color: red;">Sie haben keine verfügbare Nutzerlizenz mehr.</strong>
          &nbsp;<a href="/licenses">Lizenzen verwalten</a>
        </div>

        <div v-if="licenseInfo.role_manage_free" class="small secondary">
          Nutzer mit dem Recht "Planen und Daten pflegen" benötigen keine Lizenz.
        </div>

        <div v-if="licenseInfo.license_per_uniq_account" class="small secondary">
          Nutzer, die bereits in anderen Niederlassungen aktiv sind, benötigen keine Lizenz.
        </div>
      </div>
    </div>

    <ServerErrors v-model="serverErrors" title="Nutzer konnte nicht eingeladen werden" margin-bottom="1rem" />

    <form>
      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory" for="invite-email">E-Mail</label>
        </div>
        <div class="fx-form-content">
          <input id="invite-email" v-model="localUser.email" type="text" name="email">
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory" for="invite-name">Name</label>
        </div>
        <div class="fx-form-content">
          <input id="invite-name" v-model="localUser.name" type="text">
          <div class="form-field-hint">
            Bitte legen Sie für jede Person, die Foxtag nutzt, einen eigenen Nutzer mit dem Namen der Person an.
            <help-article article-id="no_license_sharing" :no-style="true">
              Warum?
            </help-article>
          </div>
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline" for="invite-number">Nummer</label>
        </div>
        <div class="fx-form-content">
          <input id="invite-number" v-model="localUser.number" type="text" placeholder="optional">
          <div class="form-field-hint">
            Personal- oder Mitarbeiternummer in Ihrem Unternehmen
          </div>
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline" for="user_roles">Berechtigungen</label>
        </div>
        <div class="fx-form-content">
          <RolesCheckboxes :init-roles="[]" @changed="rolesChanged" />
        </div>
      </div>
    </form>
    <hr>

    <div class="float-right">
      <button
        class="fx-btn-primary float-right"
        style="margin-bottom: 0.5rem;"
        :disabled="!localUser.email || !localUser.name || licenseInfo === null"
        @click.prevent="createViaAPI()"
        >
        Einladung versenden
      </button>
    </div>
    <i class="fas fa-info-circle" /> Ihr Mitarbeiter erhält eine Einladung per E-Mail

    <div class="clearfix" />
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';

import ServerErrors from 'components/partials/server_errors.vue';

import RolesCheckboxes from 'components/partials/roles_checkboxes.vue';

export default {
  name: 'InviteModal',
  components: {
    ServerErrors,
    RolesCheckboxes,
  },
  emits: ['close', 'invited'],
  data() {
    return {
      licenseInfo: {},
      serverErrors: [],
      localUser: {
        name: null,
        email: null,
        number: null,
        roles: [],
      },
    };
  },
  computed: {
    licensesAvailable() {
      return this.licenseInfo.free_licensed_users_count > 0;
    },
    licenseInfoLoaded() {
      return Object.keys(this.licenseInfo).length > 0;
    },
  },
  mounted() {
    this.loadInfo();
  },
  methods: {
    rolesChanged(newRoles) {
      this.localUser.roles = newRoles;
    },
    loadInfo() {
      axios.get('/licenses/info.json')
        .then((response) => {
          this.licenseInfo = response.data.license_info;
        })
        .catch(() => {
          Flash.error('Konnte Lizenzinformationen nicht laden');
        });
    },
    createViaAPI() {
      const that = this;
      that.serverErrors = [];
      axios.post('/invites.json', {
        invite: {
          recipient_name: this.localUser.name,
          recipient_email: this.localUser.email,
          recipient_attributes: {
            number: this.localUser.number,
            roles: this.localUser.roles,
          },
        },
      }).then(() => {
        that.$emit('invited');
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.invites;
        } else {
          Flash.error('Einladung konnte nicht gespeichert werden');
        }
      });
    },
  },
};
</script>
