<template>
  <div class="fx-card" style="border: 1px solid #7099C9">
    <div class="fx-card-content">
      <div class="fx-grid">
        <div class="fx-col" style="padding-top: 0; padding-left: 0;">
          <h2 class="fx">
            Kostenloser Testaccount ({{ trialRemainingDays }} Tage)
          </h2>
          <p>
            Mit diesem separaten Firmenaccount können Sie den kompletten Funktionsumfang von <strong>Foxtag</strong> <span
              style="font-size: 1rem;"
              class="license-type-label license-type-pro"
            >pro</span> testen.
          </p>
          <p>
            Am Ende der Testphase wird dieser Firmenaccount und alle enthaltenen Daten automatisch gelöscht, verwenden Sie
            den Test-Account daher bitte nicht für reale Einsätze.
          </p>

          <p>
            Wenn Sie sich für ein Upgrade auf <strong>Foxtag</strong> <span
              style="font-size: 1rem;"
              class="license-type-label license-type-pro"
            >pro</span> entscheiden, schalten wir die Funktionen für Ihren
            bestehenden Firmenaccount frei.<br>Kontaktieren Sie uns
            zu den Details der Umstellung und für alle Fragen zu Ihrer Testphase unter: <strong>040 / 257 660
              444</strong>.
          </p>

          <p>
            <span v-if="trialRemainingDays > 0">Ihre kostenlose Testphase läuft noch {{ trialRemainingDays }} Tage.</span>
            <span v-if="trialRemainingDays < 1"><strong>{{ $t('trial_over') }}</strong></span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProTrialCard',
  props: {
    trialRemainingDays: {
      type: Number,
      default: 0,
    },
  },
};
</script>
