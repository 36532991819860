<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <h2 class="fx">
      Störung bearbeiten
    </h2>
    <hr>
    <ServerErrors v-model="serverErrors" title="Störung konnte nicht gespeichert werden" margin-bottom="1rem" />
    <form @submit.prevent="submit">
      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory" for="incident_incident_type">Störungstyp</label>
        </div>
        <div class="fx-form-content">
          <select
            v-model="incidentTypeId"
            :disabled="!enableIncidentTypeSelect"
            name="incident-type"
            :current-value="selectedIncidentType"
          >
            <option v-for="opt in incidentTypeOptions" :key="opt.value" :value="opt.value">
              {{ opt.text }}
            </option>
          </select>
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory" for="severity">Schweregrad</label>
        </div>
        <div class="fx-form-content">
          <input
            id="incident_severity_low"
            v-model="severity"
            type="radio"
            value="low"
          >
          <label for="incident_severity_low">{{ $t('activerecord.attributes.incident.severities.low') }}</label>
          <input
            id="incident_severity_normal"
            v-model="severity"
            type="radio"
            value="normal"
          >
          <label for="incident_severity_normal">{{ $t('activerecord.attributes.incident.severities.normal') }}</label>
          <input
            id="incident_severity_high"
            v-model="severity"
            type="radio"
            value="high"
          >
          <label for="incident_severity_high">{{ $t('activerecord.attributes.incident.severities.high') }}</label>
          <input
            id="incident_severity_notice"
            v-model="severity"
            type="radio"
            value="notice"
          >
          <label for="incident_severity_notice">{{ $t('activerecord.attributes.incident.severities.notice') }}</label>
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline" for="incident_reported_by">Gemeldet von</label>
        </div>
        <div class="fx-form-content">
          <input
            id="incident_reported_by"
            v-model="reportedBy"
            name="reportedBy"
            type="text"
          >
          <div class="form-field-hint">
            Name des Kunden, der die Störung gemeldet hat. Wird auf dem Bericht abgedruckt.
          </div>
        </div>
      </div>

      <div class="fx-form-row">
        <label class="inline" for="incident_description">Beschreibung</label>
        <textarea
          id="incident_description"
          v-model="description"
          rows="10"
          maxlength="2500"
        />
      </div>

      <hr>
      <button type="submit" class="fx-btn-primary float-right" style="margin-bottom: 0.5rem;">
        Speichern
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import { useCurrentUserStore } from 'stores/current_user';
import Flash from 'flash/index';
import FeatureFlipper from 'mixins/feature_flipper';
import ServerErrors from 'components/partials/server_errors.vue';

export default {
  name: 'EditIncidentModal',
  components: {
    ServerErrors,
  },
  mixins: [FeatureFlipper],
  props: {
    incident: {
      type: Object,
      required: true,
    },
  },
  emits: ['updated', 'close'],
  setup() {
    return {
      currentUser: useCurrentUserStore().currentUser,
    };
  },
  data() {
    return {
      installationId: null,
      incidentTypeId: null,
      incidentId: null,
      severity: null,
      reportedBy: null,
      description: null,
      incidentTypeOptions: [],
      enableIncidentTypeSelect: false,
      serverErrors: [],
    };
  },
  computed: {
    selectedIncidentType() {
      const selectedOption = this.incidentTypeOptions.find((opt) => opt.value === this.incidentTypeId);
      return selectedOption ? selectedOption.text : '';
    },
  },
  mounted() {
    const incident = this.incident;
    this.incidentId = incident.id;
    this.installationId = incident.installation.id;
    this.resolution = incident.resolution;
    this.severity = incident.severity;
    this.incidentTypeId = incident.incident_type.id;
    this.reportedBy = incident.reported_by;
    this.description = incident.description;

    this.loadIncidentTypeOptions(this.installationId);
  },
  methods: {
    submit() {
      this.serverErrors = [];

      const that = this;
      const params = {
        incident_type_id: this.incidentTypeId,
        severity: this.severity,
        reported_by: this.reportedBy,
        description: this.description,
      };

      axios.patch(`/incidents/${this.incidentId}`, params).then((response) => {
        Flash.info('Störung wurde aktualisiert');
        that.$emit('updated', response.data.incident);
      }).catch((error) => {
        if (error.response && error.response.status === 400) {
          that.serverErrors = error.response.data.incidents;
        } else {
          Flash.error('Störung konnte nicht gespeichert werden');
        }
      });
    },
    loadIncidentTypeOptions(installationId) {
      if (!installationId) {
        this.incidentTypeOptions = [];
        this.enableIncidentTypeSelect = false;
        return;
      }
      axios.get(`/installations/${installationId}/incident_type_options`)
        .then((response) => {
          this.incidentTypeOptions = response.data;
          this.enableIncidentTypeSelect = true;
        });
    },
  },
};
</script>
