<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <h2 class="fx">
      Element zum Layout hinzufügen
    </h2>

    <div style="cursor: pointer;" class="fx-button-chooser" @click="closeEmit('page_break')">
      <strong>Seitenumbruch</strong><br>
      <span style="font-size: .825rem;">Seitenumbrüche werden bei Bedarf automatisch eingefügt. Benutzen Sie dieses
        Element, wenn Sie den Bericht bspw. in einen Hauptteil und einen Anhang trennen möchten.
      </span>
    </div>

    <!-- INFO TEXT -->
    <template v-if="!hasSection('info_text')">
      <div style="cursor: pointer;" class="fx-button-chooser" @click="closeEmit('info_text')">
        <strong>Textblock</strong><br>
        <span style="font-size: .825rem;">Fügt einen festgelegten Text in das Layout ein.</span>
      </div>
    </template>
    <template v-else>
      <div class="fx-button-chooser secondary">
        Textblock  <span style="float:right; font-size: .825rem">bereits im Layout verwendet</span>
      </div>
    </template>

    <!-- ALLGEMEINE CHECKLISTEN -->
    <template v-if="!hasSection('checklists')">
      <div style="cursor: pointer;" class="fx-button-chooser" @click="closeEmit('checklists')">
        <strong>Allgemeine Checklisten</strong><br>
        <span style="font-size: .825rem;">
          Sie können bis zu 2 allgemeine Checklisten hinterlegen, die von diesem Element abgedruckt werden. Wenn keine Checklisten in einem Auftrag ausgefüllt werden, wird dieses Element komplett ausgeblendet.
        </span>
      </div>
    </template>
    <template v-else>
      <div class="fx-button-chooser secondary">
        Allgemeine Checklisten  <span style="float:right; font-size: .825rem">bereits im Layout verwendet</span>
      </div>
    </template>

    <!-- ARTIKELPOSITIONEN -->
    <template v-if="!hasSection('articles')">
      <div style="cursor: pointer;" class="fx-button-chooser" @click="closeEmit('articles')">
        <strong>Artikelpositionen</strong><br>
        <span style="font-size: .825rem;">
          Druckt die im Auftrag erfassten Artikelpositionen. Wenn keine Artikelpositionen in einem Auftrag erfasst werden, wird dieses
          Element komplett ausgeblendet.
        </span>
      </div>
    </template>
    <template v-else>
      <div class="fx-button-chooser secondary">
        Artikelpositionen<span style="float:right; font-size: .825rem">bereits im Layout verwendet</span>
      </div>
    </template>

    <!-- ARTIKELPOSITIONEN -->
    <template v-if="!hasSection('lifecycle_events')">
      <div style="cursor: pointer;" class="fx-button-chooser" @click="closeEmit('lifecycle_events')">
        <strong>Inbetriebnahme/Außerbetriebnahme/Tausch</strong><br>
        <span style="font-size: .825rem;">
          Liste der Komponenten, die in diesem Auftrag Inbetrieb / Außerbetrieb genommen oder getauscht wurden.
        </span>
      </div>
    </template>
    <template v-else>
      <div class="fx-button-chooser secondary">
        Inbetriebnahme/Außerbetriebnahme/Tausch<span style="float:right; font-size: .825rem">bereits im Layout verwendet</span>
      </div>
    </template>

    <!-- PRÜFERGEBNISSE -->
    <template v-if="!hasSection('checks')">
      <div style="cursor: pointer;" class="fx-button-chooser" @click="closeEmit('checks')">
        <strong>Prüfergebnisse</strong><br>
        <span style="font-size: .825rem;">
          Druckt die Prüfergebnisse im Bericht ab. Wenn keine Prüfergebnisse in einem Auftrag erfasst werden, wird dieses
          Element komplett ausgeblendet.
        </span>
      </div>
    </template>
    <template v-else>
      <div class="fx-button-chooser secondary">
        Prüfergebnisse  <span style="float:right; font-size: .825rem">bereits im Layout verwendet</span>
      </div>
    </template>

    <!-- STÖRUNGEN -->
    <template v-if="!hasSection('incidents')">
      <div style="cursor: pointer;" class="fx-button-chooser" @click="closeEmit('incidents')">
        <strong>Störungen</strong> <sup><span class="license-type-label license-type-pro">pro</span></sup><br>
        <span style="font-size: .825rem;">
          Druckt die im Auftrag bearbeiteten Störungen, mitsamt der kompletten Historie der Störung
        </span>
      </div>
    </template>
    <template v-else>
      <div class="fx-button-chooser secondary">
        Störungen  <span style="float:right; font-size: .825rem">bereits im Layout verwendet</span>
      </div>
    </template>

    <!-- SIGNATURES -->
    <template v-if="!hasSection('signatures')">
      <div style="cursor: pointer;" class="fx-button-chooser" @click="closeEmit('signatures')">
        <strong>Unterschriften</strong><br>
        <span style="font-size: .825rem;">
          Druckt erfasste Unterschriften und einen optionalen abschließenden Text ab.
        </span>
      </div>
    </template>
    <template v-else>
      <div class="fx-button-chooser secondary">
        Unterschriften  <span style="float:right; font-size: .825rem">bereits im Layout verwendet</span>
      </div>
    </template>

    <!-- FOTOS -->
    <template v-if="!hasSection('photos')">
      <div style="cursor: pointer;" class="fx-button-chooser" @click="closeEmit('photos')">
        <strong>Fotos</strong><br>
        <span style="font-size: .825rem;">
          Druckt die im Auftrag erfassten Fotos (4 pro Seite)
        </span>
      </div>
    </template>
    <template v-else>
      <div class="fx-button-chooser secondary">
        Fotos  <span style="float:right; font-size: .825rem">bereits im Layout verwendet</span>
      </div>
    </template>

    <div class="clearfix" />
  </div>
</template>

<script>
/* eslint-disable no-undef */

export default {
  name: 'AddSectionModal',
  components: {
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    sections: {
      type: Array,
      required: true,
    },
  },
  emits: ['add-section', 'close'],
  methods: {
    hasSection(key) {
      return (_.findIndex(this.sections, { key }) > -1);
    },
    closeEmit(key) {
      this.$emit('add-section', { index: this.index, key });
      this.$emit('close');
    },
  },
};
</script>
