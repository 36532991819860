<template>
  <div>
    <div class="repco_preview">
      <template v-if="showForm">
        <div style="background-color: #eee; min-height: 16rem; border-radius: 8px; padding: 1rem;">
          <h3 class="fx">
            {{ name }}
          </h3>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline mandatory" for="customer_name">Überschrift</label>
            </div>
            <div class="fx-form-content">
              <input
                id="customer_name"
                v-model="localSection.title"
                name="customer_name"
                type="text"
              >
            </div>
          </div>

          <button class="fx-btn-primary" @click="showForm=false">
            Zurück zur Vorschau
          </button>
        </div>
      </template>

      <template v-if="!showForm">
        <div :class="previewEngineClass">
          <div class="repco-h2" :style="headlineStyle">
            {{ localSection.title }}
          </div>
          <table class="preview">
            <tr>
              <th style="width: 12%" :style="thStyle">
                Position
              </th>
              <th style="width: 64%" :style="thStyle">
                Beschreibung
              </th>
              <th style="width: 12%" :style="thStyle">
                Einheit
              </th>
              <th style="width: 12%" :style="thStyle">
                Anzahl
              </th>
            </tr>
            <tr>
              <td class="repco" :style="tdStyle">
                1
              </td>
              <td class="repco" :style="tdStyle">
                Dichtungsring, 9mm Kunststoff<br>Art.-Nr. XXXXX-XXXXX-XXXX<br>
                Optionaler Kommentar an der Artikelposition
              </td>
              <td class="repco" :style="tdStyle">
                Stk.
              </td>
              <td class="repco" :style="tdStyle">
                4
              </td>
            </tr>
          </table>
        </div>
      </template>
    </div>

    <Controls
      :index="index"
      :list-length="listLength"
      :name="name"
      :readonly="readonly"
      @settings="showForm = !showForm"
      @add="$emit('add')"
      @delete="$emit('delete')"
      @move-up="$emit('moveUp')"
      @move-down="$emit('moveDown')"
    />
  </div>
</template>

<script>
import Controls from './section_controls.vue';

export default {
  name: 'SectionArticles',
  components: {
    Controls,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    colors: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      default: 'Artikelpositionen',
    },
    index: {
      type: Number,
      required: true,
    },
    listLength: {
      type: Number,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    layoutEngine: {
      type: String,
      default: 'foxtag_report',
    },
  },
  data() {
    return {
      showForm: false,
      localSection: {},
    };
  },
  computed: {
    previewEngineClass() {
      return `preview-${this.layoutEngine}`;
    },
    thStyle() {
      if (this.layoutEngine == 'exp_report') {
        return `background-color: transparent; border-color: #${this.colors.color_lines}; border: 0; border-bottom: 2px solid #${this.colors.color_lines}`;
      }

      return `color: #${this.colors.color_col_headline_text}; background-color: #${this.colors.color_lines}; border: 2px solid #${this.colors.color_lines}`;
    },
    tdStyle() {
      if (this.layoutEngine == 'exp_report') {
        return `color: #${this.colors.color_text}; border: 0;`;
      }

      return `color: #${this.colors.color_text}; border-color: #${this.colors.color_lines}`;
    },
    headlineStyle() {
      return `color: #${this.colors.color_headlines};`;
    },
  },
  mounted() {
    this.localSection = this.section;
  },
};
</script>
