<template>
  <li class="dropdown-menu-list-item">
    <slot />
  </li>
</template>

<script>
export default {
  name: 'DropdownMenuItem',
};
</script>

<style>
</style>
