<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <h2 class="fx">
      {{ mergeContactIds.length }} Kontakte zusammenführen
    </h2>
    <p>
      Die Kontakte werden <strong>in einen neuen Kontakt zusammengeführt</strong>. Verknüpfungen zu Kunden und Anlagen
      bleiben erhalten.
    </p>
    <hr>
    <ServerErrors v-model="serverErrors" title="Kontakt konnte nicht gespeichert werden" margin-bottom="1rem" />

    <div class="fx-form-row">
      <div class="fx-form-label">
        <label class="inline mandatory" for="contact_name">Name</label>
      </div>
      <div class="fx-form-content">
        <input
          id="contact_name"
          v-model="localContact.name"
          name="contact_name"
          type="text"
        >
      </div>
    </div>

    <div class="fx-form-row">
      <div class="fx-form-label">
        <label class="inline" for="contact_company">Firma</label>
      </div>
      <div class="fx-form-content">
        <input
          id="contact_company"
          v-model="localContact.company"
          name="contact_company"
          type="text"
        >
      </div>
    </div>

    <div class="fx-form-row">
      <div class="fx-form-label">
        <label class="inline">E-Mail</label>
      </div>
      <div class="fx-form-content">
        <input
          id="contact_email"
          v-model="localContact.email"
          name="contact_email"
          type="text"
        >
      </div>
    </div>

    <div class="fx-form-row">
      <div class="fx-form-label">
        <label class="inline">Telefon</label>
      </div>
      <div class="fx-form-content">
        <input
          id="contact_phone"
          v-model="localContact.phone"
          name="contact_phone"
          type="text"
        >
      </div>
    </div>

    <div class="fx-form-row">
      <div class="fx-form-label">
        <label class="inline" for="contact_mobile">Mobil</label>
      </div>
      <div class="fx-form-content">
        <input
          id="contact_mobile"
          v-model="localContact.mobile"
          name="contact_mobile"
          type="text"
        >
      </div>
    </div>

    <div class="fx-form-row">
      <div class="fx-form-label">
        <label class="inline" for="contact_fax">Fax</label>
      </div>
      <div class="fx-form-content">
        <input
          id="contact_fax"
          v-model="localContact.fax"
          name="contact_fax"
          type="text"
        >
      </div>
    </div>

    <div class="fx-form-row">
      <div class="fx-form-label">
        <label class="inline" for="contact_note">Notiz</label>
      </div>
      <div class="fx-form-content">
        <textarea
          id="contact_mobile"
          v-model="localContact.note"
          rows="3"
          name="contact_note"
        />
      </div>
    </div>

    <hr>
    <TransactionButton
      v-model="busy"
      class="fx-btn-primary float-right"
      title="Kontakte zusammenführen"
      @click="mergeViaAPI()"
    />
    <div class="clearfix" />
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';

import ServerErrors from 'components/partials/server_errors.vue';
import TransactionButton from 'components/transaction_button.vue';

export default {
  name: 'MergeContactModal',
  components: {
    ServerErrors,
    TransactionButton,
  },
  props: {
    mergeContactIds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      busy: false,
      localContact: {
        name: null,
        phone: null,
        email: null,
        mobile: null,
        note: null,
        fax: null,
      },
      serverErrors: [],
    };
  },
  mounted() {
    this.loadMergeSuggestion();
  },
  methods: {
    loadMergeSuggestion() {
      this.serverErrors = [];
      const that = this;
      axios.post('/contacts/merge_suggestion', {
        contact_ids: this.mergeContactIds,
      }).then((response) => {
        that.localContact = response.data.contact;
      }).catch((error) => {
        Flash.error('Fehler', error);
      });
    },
    mergeViaAPI() {
      const that = this;
      that.busy = true;
      that.serverErrors = [];
      axios.post('/contacts.json', {
        merge_contact_ids: this.mergeContactIds,
        contact: {
          name: this.localContact.name,
          company: this.localContact.company,
          phone: this.localContact.phone,
          mobile: this.localContact.mobile,
          fax: this.localContact.fax,
          email: this.localContact.email,
          note: this.localContact.note,
        },
      }).then((response) => {
        this.$emit('close');
        that.busy = false;
        Flash.info('Kontakte wurden zusammmengeführt');
        that.$emit('merged', { ...response.data });
      }).catch((error) => {
        that.busy = false;
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.contacts;
        } else {
          Flash.error('Kontakt konnte nicht gespeichert werden');
        }
      });
    },
  },
};
</script>
