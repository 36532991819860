<template>
  <div id="dashboard-card-today" class="fx-card">
    <div class="float-right" style="margin: .8rem">
      <button
        v-tippy="{ placement: 'top', duration: 100, arrow: true }"
        class="fx-btn-secondary"
        title="Liste neu laden"
        @click="reset()"
      >
        <i class="fas fa-sync-alt" />
      </button>
    </div>
    <div class="fx-card-content">
      <h2 class="fx">
        <strong>Heute</strong> {{ todayDate() }}
      </h2>
    </div>
    <JobList
      :key="jobListKey"
      query-path="/dashboard/todays_jobs.json"
      :limit="20"
      :allow-editing="allowEditing"
    >
      <template #empty-state>
        <div class="fx-list-empty-state">
          Keine für heute geplanten, keine laufenden oder heute erledigte Aufträge
        </div>
      </template>
    </JobList>
    <div v-if="overduePlannedJobsCount > 0" class="fx-list-row">
      <div class="fx-max-width-col">
        Sie haben <a href="/jobs" class="activity-link">{{ overduePlannedJobsCount }} geplante überfällige Aufträge</a>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import JobList from './job_list.vue';

export default {
  name: 'TodayCard',
  components: {
    JobList,
  },
  props: {
    overduePlannedJobsCount: {
      type: Number,
      default: 0,
    },
    allowEditing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      jobListKey: 0,
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    todayDate() {
      return moment().format('dddd, DD.MM.YYYY');
    },
    reset() {
      this.jobListKey += 1;
    },
  },
};
</script>
