<template>
  <div>
    <div class="text-right">
      <div class="fx-btn-group">
        <button
          v-if="installationId"
          class="fx-btn-primary"
          :disabled="addEventDisabled"
          @click="openEditEventModal(null);"
        >
          <i class="fas fa-plus" /> Ereignis hinzufügen
        </button>
        <DropDown v-if="installationId && showTestTriggerEvents" classes="fx-btn-secondary">
          <DropDownItem><a @click="createTriggerEvent()">Auslöse-Ereignis testen</a></DropDownItem>
        </DropDown>
        <button
          v-tippy="{ placement: 'top', duration: 100, arrow: true }"
          class="fx-btn-secondary"
          title="Liste neu laden"
          @click="reload()"
        >
          <i class="fas fa-sync-alt" />
        </button>
      </div>
    </div>
    <div class="fx-card">
      <div class="fx-list-row">
        <div class="fx-10px-col">
&nbsp;
        </div>
        <div class="fx-180px-col">
          <strong>Zeitpunkt</strong>
        </div>
        <div class="fx-220px-col">
          <strong>Auftrag</strong>
        </div>
        <div class="fx-150px-col">
          <strong>Ereignis</strong>
        </div>
        <div class="fx-10px-col">
&nbsp;
        </div>
        <div class="fx-150px-col">
          <strong>Ergebnis</strong>
        </div>
        <div class="fx-180px-col">
          <strong>Nutzer</strong>
        </div>
      </div>

      <ThingEvent
        v-for="thingEvent in localEvents"
        :key="thingEvent.id"
        :check-event="thingEvent"
        :thing-id="thingId"
        @open-confirm-delete-event-modal="confirmDeleteEvent"
        @open-edit-event-modal="openEditEventModal"
      />
      <div class="clearfix" />
      <div
        v-if="!localEvents.length"
        class="small secondary"
        style="padding: 1rem; text-align: center;"
      >
        Keine Ereignisse
      </div>
    </div>
    <Pagination
      v-model="pagination"
      :init-from-url="false"
      :update-url="false"
      @navigate="loadPage"
    />
  </div>
</template>
<script>
import axios from 'axios';
import Flash from 'flash/index';

import { useCurrentUserStore } from 'stores/current_user';
import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';
import { ref } from 'vue';
import confirmViaModal from 'helpers/confirm_modal';

import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';
import Pagination from 'components/pagination.vue';
import ThingEvent from './thing_event.vue';
import EditEventModal from './modals/edit_event_modal.vue';

export default {
  name: 'EventsList',
  components: {
    ThingEvent,
    DropDown,
    DropDownItem,
    Pagination,
  },
  props: {
    thingId: {
      type: String,
      required: true,
    },
    installationId: {
      type: String,
      default: null,
    },
    showTestTriggerEvents: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['count-changed'],
  setup(props, { emit }) {
    const localEvents = ref([]);
    const loaded = ref(false);
    const pagination = ref({
      page: 1,
      pageCount: 1,
      totalCount: 0,
      limit: 25,
    });

    const load = () => {
      localEvents.value = [];
      loaded.value = false;

      axios.get(`/things/${props.thingId}/events.json`, {
        params: {
          page: pagination.value.page,
          limit: pagination.value.limit,
        },
      }).then((response) => {
        localEvents.value = response.data.events;
        pagination.value.page = response.data.meta.current_page;
        pagination.value.pageCount = response.data.meta.total_pages;
        pagination.value.totalCount = response.data.meta.total_count;
        emit('count-changed', pagination.value.totalCount);
      }).catch();
    };

    const loadPage = (changedPagination) => {
      pagination.value = { ...changedPagination };
      load();
    };

    const reload = () => {
      localEvents.value = [];
      pagination.value.page = 1;
      load();
    };

    const deleteViaAPI = (event) => {
      axios.delete(`/things/${props.thingId}/events/${event.id}.json`).then(() => {
        Flash.info('Ereignis gelöscht');
        load();
      }).catch(() => {
        Flash.error('Ereignis konnte nicht gelöscht werden');
      });
    };

    const openEditEventModal = (event) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: EditEventModal,
            attrs: {
              thingId: props.thingId,
              installationId: props.installationId,
              event,
              onClose() {
                modalInstance.close();
              },
              onCreated() {
                modalInstance.close();
                load();
              },
              onUpdated() {
                modalInstance.close();
                load();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const confirmDeleteEvent = (event) => {
      confirmViaModal({
        title: 'Ereignis löschen?',
        message: 'Sie sollten Ereignisse nur im Ausnahmefall löschen, um Daten zu korrigieren!',
      }).then(() => {
        deleteViaAPI(event);
      });
    };

    return {
      currentUser: useCurrentUserStore().currentUser,
      openEditEventModal,
      confirmDeleteEvent,
      localEvents,
      pagination,
      reload,
      load,
      loadPage,
    };
  },
  data() {
    return {
      showNoResults: false,
    };
  },
  computed: {
    addEventDisabled() {
      return !(this.currentUser.roles.includes('admin') || this.currentUser.roles.includes('manage'));
    },
  },
  methods: {
    openConfirmDeleteEventModal(event) {
      this.$modal.show('confirm-delete-event-modal', {
        object: event,
        title: 'Ereignis löschen?',
        action: 'Löschen',
        message: 'Sie sollten Ereignisse nur im Ausnahmefall löschen, um Daten zu korrigieren!',
        requireInputConfirmation: false,
      });
    },
    createTriggerEvent() {
      axios.post(`/things/${this.thingId}/test_trigger_event`).then(() => {
        Flash.info('Auslöseereignis erzeugt');
        this.load();
      }).catch((error) => {
        Flash.error(`Auslöseereignis konnte nicht erzeugt werden: ${error.response.data}`);
      });
    },
  },
};
</script>
