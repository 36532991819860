<template>
  <ul class="fx-breadcrumps">
    <li v-for="(crump, index) in breadcrumps()" :key="index">
      <Icon
      v-if="crump['icon']"
      :id="crump['icon']"
      class="fx-inline-ico"
      style="margin-right: 6px;"
      />
      <a :href="crump['href']">{{ $t(crump["labelKey"]) }}</a>
    </li>
    <li>
      <Icon
      v-if="currentCrump()['icon']"
      :id="currentCrump()['icon']"
      class="fx-inline-ico"
      style="margin-right: 6px;"
      />
      <span v-if="currentLabel">{{ currentLabel }}</span>
      <span v-else>{{ $t(currentCrump()["labelKey"]) }}</span>
    </li>
  </ul>
</template>
<script>
import Icon from 'components/icon.vue';
import { foxtagSitemap } from 'initializers/sitemap';
import { useCurrentUserStore } from 'stores/current_user';

export default {
  name: 'NavBreadcrumps',
  components: {
    Icon,
  },
  props: {
    path: {
      type: String,
      required: true,
    },
    // --- if currentLabel is set, it will be used as the last crump.
    // --- Use a placeholder 'current' in path e.g. "administration/users/current" to add the currentLabel
    // -- to avoid the last crump to be generated from the path
    currentLabel: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const currentUser = useCurrentUserStore().currentUser;

    const breadcrumps = () => {
      const crumps = [];

      let currentKey = null;
      props.path.split('/').slice(0, -1).forEach((key) => {
        if (currentKey != null) {
          currentKey = `${currentKey}/${key}`;
        } else {
          currentKey = key;
        }

        if (foxtagSitemap[currentKey]) {
          crumps.push({
            labelKey: foxtagSitemap[currentKey].labelKey,
            icon: foxtagSitemap[currentKey].icon,
            href: foxtagSitemap[currentKey].href.replace('ORGANIZATION_ID', currentUser.organization_id),
          });
        }
      });
      return crumps;
    };

    const currentCrump = () => foxtagSitemap[props.path] || { url: '', labelKey: 'unknown_key' };

    return {
      currentUser,
      currentCrump,
      breadcrumps,
    };
  },
};
</script>
