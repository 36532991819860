<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <h2 class="fx">
      {{ title }}
    </h2>

    <template v-if="showAddThingType">
      <ServerErrors v-model="serverErrors" margin-bottom="1rem;" />
      <div style="border-radius: 5px; padding: 1rem; margin-top: 1rem; background-color: #f6f6f6;">
        <h2 class="fx" style="font-size: 1.3rem; margin-bottom: 1rem;">
          Neuen Typ anlegen
        </h2>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline mandatory">Kategorie</label>
          </div>
          <div class="fx-form-content">
            {{ thingLabel }}
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline mandatory" for="new-thing-type-name">Typ-Name</label>
          </div>
          <div class="fx-form-content">
            <DbBasedAutocomplete
              id="autocomplete-type-name"
              v-model="newThingTypeName"
              :url="`/installation_types/${installationTypeId}/selectable_types?category=${thingTypeCategory}`"
              @input="updateThingTypeName"
            />
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline" for="autocomplete-manufacturer">Hersteller</label>
          </div>
          <div class="fx-form-content">
            <DbBasedAutocomplete
              id="autocomplete-manufacturer"
              v-model="newThingTypeManufacturer"
              :url="`/installation_types/${installationTypeId}/selectable_manufacturers?category=${thingTypeCategory}`"
              @input="updateThingTypeManufacturer"
            />
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline" for="new-thing-type-model">Modell</label>
          </div>
          <div class="fx-form-content">
            <input
              id="new-thing-type-model"
              v-model="newThingTypeModel"
              type="text"
            >
          </div>
        </div>

        <button
          class="fx-btn-primary float-right"
          style="margin-bottom: 0.5rem; margin-left: 0.5rem;"
          @click.prevent="addThingTypeViaApi()"
        >
          Typ speichern
        </button>
        <button class="fx-btn-secondary float-right" @click.prevent="serverErrors=[];showAddThingType = false">
          Abbrechen
        </button>
        <div id="abc" class="clearfix" />
      </div>
    </template>

    <template v-if="!showAddThingType">
      <button id="ov-tab" :class="$tabClasses('data')" @click="$setActiveTab('data', false)">
        Stammdaten
      </button>
      <button id="pou-tab" :class="$tabClasses('deployment-data')" @click="$setActiveTab('deployment-data', false)">
        Einsatzort
      </button>
      <button
        id="int-tab"
        :class="$tabClasses('intervals')"
        @click="$setActiveTab('intervals', false); loadThingTypeIntervals();"
      >
        Intervalle
      </button>

      <hr style="margin-top:0;">
      <ServerErrorsCommand v-model="commandServerErrors" margin-bottom="1rem" />

      <form @submit.prevent="submit">
        <div v-if="$isTabInitialized('data')" v-show="$isTabActive('data')" style="min-height: 400px;">
          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline">Labels</label>
            </div>
            <div class="fx-form-text-content">
              <AssignFeatures v-model="assignedFeatures" :can-edit="true" />
            </div>
          </div>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline mandatory" for="thing_type">Typ</label>
            </div>
            <div class="fx-form-content">
              <DBBasedSelect
                v-model="thingTypeId"
                icon-id="thing_type"
                i18n-base-key="thing_types"
                :default-label="$t('comp.select.no-selection')"
                :load-options-url="`/thing_types/for_select/?installation_type_id=${installationTypeId}&category=${thingTypeCategory}`"
                @changed="(selectedThingTypeId) => {thingTypeId = selectedThingTypeId;}"
              />
              <FieldError v-model="commandServerErrors" field="thing_type" />
              <div v-if="canAddThingTypes()" class="form-field-hint">
                Typ der {{ thingLabel }} aus der Liste wählen oder
                <a
                  id="add-new-type-btn"
                  class="fx-btn-secondary"
                  style="padding: .5rem .6rem;"
                  @click="showAddThingType=true"
                >Neuen Typ anlegen</a>
              </div>
            </div>
          </div>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline" for="thing_serial_number">Seriennummer</label>
            </div>
            <div class="fx-form-content">
              <input
                id="thing_serial_number"
                v-model="serialNumber"
                name="serial_number"
                type="text"
              >
              <FieldError v-model="commandServerErrors" field="serial_number" />
            </div>
          </div>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline" for="thing_year_of_construction">Baujahr</label>
            </div>
            <div class="fx-form-content">
              <input
                id="thing_year_of_construction"
                v-model="yearOfConstruction"
                name="yearOfConstruction"
                type="text"
              >
              <FieldError v-model="commandServerErrors" field="year_of_construction" />
            </div>
          </div>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline" for="thing_year_of_construction">Zulassungsnummer</label>
            </div>
            <div class="fx-form-content">
              <input
                id="thing_approval_number"
                v-model="approvalNumber"
                name="approvalNumber"
                type="text"
              >
              <FieldError v-model="commandServerErrors" field="approval_number" />
            </div>
          </div>

          <div v-for="(customDataRow, index) in customData" :key="index" class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline" :for="`custom-data-field-${index}`">{{ customDataRow.label }}</label>
            </div>
            <div class="fx-form-content">
              <input
                :id="`custom-data-field-${index}`"
                v-model="customDataRow.value"
                :name="`custom-data-field-${index}`"
                type="text"
              >
            </div>
          </div>
        </div>

        <!-- EINSATZORT -->
        <div v-if="$isTabInitialized('deployment-data')" v-show="$isTabActive('deployment-data')" style="min-height: 400px;">
          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline mandatory" for="thing_group">Gruppe</label>
            </div>
            <div id="select-grp" class="fx-form-content">
              <DBBasedSelect
                v-model="groupId"
                :readonly="isSubthing"
                i18n-base-key="groups"
                :default-label="$t('comp.select.no-selection')"
                :load-options-url="`/groups/for_select/?installation_id=${installationId}`"
                @changed="(selectedGroupId) => {groupId = selectedGroupId;}"
              />
            </div>
          </div>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline mandatory" for="thing_number">Nummer</label>
            </div>

            <div v-if="isSubthing" class="fx-form-content">
              <div style="display: inline-block; width: 49%; vertical-align: top;">
                <input
                  id="thing_number"
                  v-model="number"
                  name="number"
                  type="text"
                  readonly="true"
                  disabled="1"
                >
                <FieldError v-model="commandServerErrors" field="deployments.number" />
              </div>
              <div style="display: inline-block; width: 49%; vertical-align: top;">
                <input
                  id="thing_sub_number"
                  v-model="subNumber"
                  name="sub_number"
                  type="text"
                >
                <FieldError v-model="commandServerErrors" field="deployments.sub_number" />
              </div>
              <div class="clearfix" />
            </div>

            <div v-else class="fx-form-content">
              <input
                id="thing_number"
                v-model="number"
                name="number"
                type="text"
              >
              <FieldError v-model="commandServerErrors" field="deployments.number" />
            </div>
          </div>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline" for="thing_position">Standort</label>
            </div>
            <div class="fx-form-content">
              <input
                id="thing_position"
                v-model="position"
                name="position"
                type="text"
              >
              <FieldError v-model="commandServerErrors" field="deployments.position" />
            </div>
          </div>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline" for="thing_number">Inbetriebnahme-Datum</label>
            </div>
            <div v-if="!canEditDeployedAt" class="fx-form-text-content">
              {{ formatDate(deployed_at, 'DD.MM.YYYY') }}
              <div class="callout callout--info" style="margin-top: 1rem;">
                Das Inbetriebnahme-Datum kann über <strong>Inbetriebnahmedatum setzen</strong> im Drei-Punkte-Menü an der Komponente geändert werden.
              </div>
            </div>
            <div v-else class="fx-form-content">
              <VueDatePicker
                v-model="deployed_at"
                :locale="$i18n.locale"
                :auto-apply="true"
                model-type="yyyy-MM-dd"
                :enable-time-picker="false"
                :format="'dd.MM.yyyy'"
                :disabled-dates="isFutureDate"
              />
            </div>
            <FieldError v-model="commandServerErrors" field="deployments.deployed_at" />
          </div>
        </div>

        <div v-if="$isTabInitialized('intervals')" v-show="$isTabActive('intervals')" style="min-height: 400px;">
          <div class="fx-form-row">
            <div class="fx-form-content" style="padding-top: .3rem;">
              <input
                id="thing_inherit_check_interval_true"
                v-model="inheritCheckInterval"
                type="radio"
                :value="true"
              >
              <label for="thing_inherit_check_interval_true">Vom Typ übernehmen</label>

              <input
                id="thing_inherit_check_interval_false"
                v-model="inheritCheckInterval"
                type="radio"
                :value="false"
              >
              <label for="thing_inherit_check_interval_false">Eigenes Prüfintervall</label>
            </div>
          </div>
          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline">Prüfintervall</label>
            </div>
            <div class="fx-form-content">
              <div v-if="inheritCheckInterval">
                <div v-if="checkIntervalInfo">
                  {{ checkIntervalInfo }}
                </div>
                <span v-else class="secondary">
                  <i class="fas fa-circle" />
                  <span style="margin-left: .2rem;">Kein Prüfintervall am Typ hinterlegt</span>
                </span>
              </div>
              <div v-if="!inheritCheckInterval">
                <select v-model="checkIntervalQuantity" style="width: 80px;">
                  <option v-for="index in 30" :key="index" :value="index">
                    {{ index }}
                  </option>
                </select>
                <select v-model="checkIntervalFrequency" style="width: 150px;">
                  <option value="days">
                    Tag(e)
                  </option>
                  <option value="weeks">
                    Woche(n)
                  </option>
                  <option value="months">
                    Monat(e)
                  </option>
                  <option value="years">
                    Jahr(e)
                  </option>
                </select>
                <FieldError v-model="commandServerErrors" field="check_interval_quantity" />
                <FieldError v-model="commandServerErrors" field="check_interval_frequency" />
                <div style="margin-top: 1rem;" class="callout callout--info">
                  Verwenden Sie eigene Intervalle nur im Ausnahmefall. Wir empfehlen Intervalle stattdessen
                  zentral an der Wartungsanwendung zu hinterlegen.
                </div>
              </div>
            </div>
          </div>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline">Austauschintervall</label>
            </div>
            <div class="fx-form-content">
              <span v-if="replacementIntervalInfo">{{ replacementIntervalInfo }}</span>
              <span v-else class="secondary">
                <i class="fas fa-sync-alt" />
                <span style="margin-left: .2rem;">Kein Austauschintervall am Typ hinterlegt</span>
              </span>
            </div>
          </div>
        </div>
        <template v-if="!showAddThingType">
          <div class="clearfix" />
          <hr>
          <button type="submit" class="fx-btn-primary float-right" style="margin-bottom: 0.5rem; margin-left: 0.5rem;">
            Speichern
          </button>
          <button class="fx-btn-secondary float-right" @click.prevent="$emit('close')">
            Abbrechen
          </button>
          <div class="clearfix" />
        </template>
      </form>
    </template>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import axios from 'axios';
import { useCurrentUserStore } from 'stores/current_user';
import { DateFormatter } from 'mixins/formatters';

import Flash from 'flash/index';

import UserModel from 'apps/application/user_model';

import DBBasedSelect from 'components/select/db_based_select.vue';
import DbBasedAutocomplete from 'components/select/db_based_autocomplete.vue';
import ServerErrors from 'components/partials/server_errors.vue';
import ServerErrorsCommand from 'components/partials/server_errors_command.vue';
import FieldError from 'components/partials/field_error.vue';
import AssignFeatures from 'apps/things/show/assign_features.vue';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: 'EditThingModal',
  components: {
    DBBasedSelect,
    ServerErrors,
    ServerErrorsCommand,
    DbBasedAutocomplete,
    AssignFeatures,
    FieldError,
    VueDatePicker,
  },
  mixins: [DateFormatter],
  props: {
    installationId: {
      type: String,
      required: true,
    },
    installationTypeId: {
      type: String,
      required: true,
    },
    isCentralUnit: {
      type: Boolean,
      required: false,
    },
    customDataset: {
      type: Array,
      required: true,
    },
    thing: {
      type: Object,
      required: true,
    },
    initGroupId: {
      type: String,
      default: null,
    },
  },
  emits: ['created', 'updated', 'close'],
  setup() {
    return { currentUser: useCurrentUserStore().currentUser };
  },
  data() {
    return {
      thingId: null,
      thingTypeId: null,
      thingTypeCategory: 'thing',
      inheritCheckInterval: false,
      checkIntervalQuantity: 1,
      checkIntervalFrequency: 'months',
      groupId: null,
      number: 0,
      subNumber: 0,
      yearOfConstruction: null,
      approvalNumber: null,
      position: null,
      serialNumber: null,
      serverErrors: [],
      commandServerErrors: {},
      isSubthing: false,
      assignedFeatures: [],
      selectedFeatures: [],
      showAddThingType: false,
      newThingTypeName: null,
      newThingTypeManufacturer: null,
      newThingTypeModel: null,
      checkIntervalInfo: null,
      replacementIntervalInfo: null,
      thingType: null,
      activeTab: null,
      customData: [],
      deployed_at: null,
      initializedTabs: new Set(),
    };
  },
  computed: {
    thingLabel() {
      let thingLabel = 'Komponente';

      if (this.isSubthing) {
        thingLabel = 'Sub-Komponente';
      } else if (this.isCentralUnit) {
        thingLabel = 'Zentrale';
      }
      return thingLabel;
    },
    title() {
      if (this.isCreate) {
        return `${this.thingLabel} ${this.numberForTitle} hinzufügen`;
      }
      return `${this.thingLabel} ${this.numberForTitle} bearbeiten`;
    },
    groupReadOnlyState() {
      return (this.isCreate || this.isSubthing);
    },
    numberForTitle() {
      if (this.subNumber > 0) {
        return `${this.number}.${this.subNumber}`;
      }
      return this.number;
    },
    canEditDeployedAt() {
      return this.isCreate;
    },
    isCreate() {
      return !this.thingId;
    },
  },
  mounted() {
    const thing = this.thing;
    this.thingId = thing.id;
    this.groupId = this.initGroupId || thing.deployment.group.id;
    this.thingTypeId = thing.thing_type.id;
    this.number = thing.deployment.number;
    this.subNumber = thing.deployment.sub_number;
    this.position = thing.deployment.position;
    this.yearOfConstruction = thing.year_of_construction;
    this.serialNumber = thing.serial_number;
    this.approvalNumber = thing.approval_number;
    this.inheritCheckInterval = thing.inherit_check_interval;
    this.checkIntervalQuantity = thing.check_interval_quantity;
    this.checkIntervalFrequency = thing.check_interval_frequency;
    this.isSubthing = (this.subNumber > 0);
    this.thingType = thing.thing_type;
    this.customData = thing.custom_data || [];
    this.deployed_at = thing.deployment.deployed_at;

    if (thing.assigned_features) {
      this.assignedFeatures = thing.assigned_features.slice();
    }

    if (this.isSubthing) {
      this.thingTypeCategory = 'sub_thing';
    } else if (this.isCentralUnit) {
      this.thingTypeCategory = 'central_unit';
    }

    if (this.number == null) {
      this.loadNextFreeNumber();
    }

    this.prepareCustomData();
    this.$setActiveTab('data', false);
    this.loadThingTypeIntervals();
  },
  methods: {
    updateThingTypeName(name) {
      this.newThingTypeName = name;
    },
    updateThingTypeManufacturer(manufacturer) {
      this.newThingTypeManufacturer = manufacturer;
    },
    // --- used by date pick
    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },
    deployed(thing) {
      this.$emit('created', thing);
    },
    canAddThingTypes() {
      return UserModel.isAdminOrManage(this.currentUser);
    },
    tabClasses(tab) {
      if (tab === this.activeTab) {
        return 'fx-tab fx-tab__active';
      }
      return 'fx-tab';
    },
    loadNextFreeNumber() {
      const that = this;
      axios.get(`/groups/${this.groupId}/next_thing_number.json`)
        .then((response) => {
          that.number = response.data;
        }).catch(() => {
          that.number = 1;
        });
    },
    submit() {
      if (this.isCreate) {
        this.createViaAPI();
      } else {
        this.updateViaAPI();
      }
    },
    createViaAPI() {
      const that = this;
      that.commandServerErrors = {};

      let features = [];
      if (this.assignedFeatures == null || this.assignedFeatures.length === 0) {
        features = [' ']; // --- send empty element to trigger update
      } else {
        this.assignedFeatures.forEach((feature) => {
          features.push(feature.id);
        });
      }

      axios.post('/things.json', this.apiParams()).then((response) => {
        Flash.info('Komponente hinzugefügt');
        this.$emit('created', response.data.thing);
      }).catch((error) => {
        if (error.response && error.response.status === 400) {
          that.commandServerErrors = error.response.data;
        } else {
          Flash.error('Komponente konnte nicht gespeichert werden');
        }
      });
    },
    updateViaAPI() {
      const that = this;
      that.commandServerErrors = {};

      let features = [];
      if (this.assignedFeatures == null || this.assignedFeatures.length === 0) {
        features = [' ']; // --- send empty element to trigger update
      } else {
        this.assignedFeatures.forEach((feature) => {
          features.push(feature.id);
        });
      }

      axios.put(`/things/${this.thingId}.json`, this.apiParams()).then((response) => {
        Flash.info('Änderung gespeichert');
        this.$emit('updated', response.data.thing);
      }).catch((error) => {
        if (error.response && error.response.status === 400) {
          that.commandServerErrors = error.response.data;
        } else {
          Flash.error('Die Änderung konnte nicht gespeichert werden');
        }
      });
    },
    loadThingTypeIntervals() {
      if (this.thingTypeId == null) { return; }

      const that = this;
      axios.get(`/thing_types/${this.thingTypeId}/intervals`).then((response) => {
        that.checkIntervalInfo = response.data.check;
        that.replacementIntervalInfo = response.data.replacement;
      });
    },
    addThingTypeViaApi() {
      const that = this;
      that.serverErrors = [];
      axios.post('/thing_types.json', {
        thing_type: {
          installation_type_id: this.installationTypeId,
          category: this.thingTypeCategory,
          name: this.newThingTypeName,
          manufacturer: this.newThingTypeManufacturer,
          model: this.newThingTypeModel,
        },
      }).then((response) => {
        Flash.info('Typ hinzugefügt');
        that.thingTypeId = response.data.thing_type.id;
        that.showAddThingType = false;
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.thing_types;
        } else {
          Flash.error('Typ konnte nicht gespeichert werden');
        }
      });
    },
    isActiveTab(tab) {
      return (this.activeTab === tab);
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    prepareCustomData() {
      if (this.customDataset.length && !this.customData.length) {
        for (let i = 0; i < this.customDataset.length; i += 1) {
          this.customData.push({ label: this.customDataset[i].label, value: '' });
        }
      }
    },
    apiParams() {
      let features = [];
      if (this.assignedFeatures == null || this.assignedFeatures.length === 0) {
        features = [' ']; // --- send empty element to trigger update
      } else {
        this.assignedFeatures.forEach((feature) => {
          features.push(feature.id);
        });
      }

      const params = {
        thing: {
          installation_id: this.installationId,
          thing_type_id: this.thingTypeId,
          group_id: this.groupId,
          number: this.number,
          sub_number: this.subNumber,
          position: this.position,
          year_of_construction: this.yearOfConstruction,
          serial_number: this.serialNumber,
          approval_number: this.approvalNumber,
          inherit_check_interval: this.inheritCheckInterval,
          check_interval_quantity: this.checkIntervalQuantity,
          check_interval_frequency: this.checkIntervalFrequency,
          assigned_feature_ids: features,
          custom_data: this.customData,
        },
      };

      if (this.canEditDeployedAt) {
        params.deployed_at = this.deployed_at;
      }

      return params;
    },
  },
};
</script>
