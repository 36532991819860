<template>
  <aside class="fx-sidebar-layout-nav">
    <ul class="fx-sidebar">
      <SidebarNavItem
        icon="articles"
        href="/imports/articles/new"
        label-key="import.type.articles"
        :active-key="activeNav"
      />
      <SidebarNavItem
        icon="contact"
        href="/imports/contacts/new"
        label-key="import.type.contacts"
        :active-key="activeNav"
      />
    </ul>
    <ul class="fx-sidebar">
      <SidebarNavItem
        icon="customer"
        href="/imports/customers/new"
        label-key="import.type.customers"
        :active-key="activeNav"
      />
      <SidebarNavItem
        icon="location"
        href="/imports/locations/new"
        label-key="import.type.locations"
        :active-key="activeNav"
      />
      <SidebarNavItem
        icon="installation"
        href="/imports/installations/new"
        label-key="import.type.installations"
        :active-key="activeNav"
      />
      <SidebarNavItem
        icon="thing"
        href="/imports/things/new"
        label-key="import.type.things"
        :active-key="activeNav"
      />
      <SidebarNavItem
        icon="thing_type"
        href="/imports/thing_types/new"
        label-key="import.type.thing_types"
        :active-key="activeNav"
      />
    </ul>
    <ul class="fx-sidebar">
      <SidebarNavItem
        icon="job"
        href="/imports/jobs/new"
        label-key="import.type.jobs"
        :active-key="activeNav"
      />
      <SidebarNavItem
        icon="articles"
        href="/imports/job_articles/new"
        label-key="import.type.job_articles"
        :active-key="activeNav"
      />
    </ul>
    <ul v-if="features.totalImport" class="fx-sidebar">
      <SidebarNavItem
        icon="job"
        href="/imports/total_jobs/new"
        label-key="import.type.total_jobs"
        :active-key="activeNav"
      />
    </ul>

    <div v-if="currentUser.support">
      <span class="small secondary" style="margin-left: 1rem;">Nur für Kundenservice sichtbar</span>
      <ul class="fx-sidebar">
        <SidebarNavItem
          icon="inventory"
          href="/imports/inventory/new"
          label-key="import.type.inventory"
          :active-key="activeNav"
        />
      </ul>
    </div>
  </aside>
</template>

<script>
import { useCurrentUserStore } from 'stores/current_user';
import FeatureFlipper from 'mixins/feature_flipper';

import SidebarNavItem from './sidebar_nav_item.vue';
import Sidebar_nav_item from './sidebar_nav_item.vue';

export default {
  components: {
    SidebarNavItem,
  },
  mixins: [FeatureFlipper],
  props: {
    activeNav: {
      type: String,
      default: null,
    },
  },
  setup() {
    return { currentUser: useCurrentUserStore().currentUser };
  },
};
</script>
