import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';
import WaitForAsyncModal from 'components/wait_for_async_modal.vue';

const waitForAsyncModal = (attrs) => new Promise((resolve) => {
  const modalInstance = useModal({
    component: VueFinalModal,
    slots: {
      default: useModalSlot({
        component: WaitForAsyncModal,
        attrs: {
          title: attrs.title,
          message: attrs.message,
          sidekiqJobId: attrs.sidekiqJobId,
          onClose() {
            modalInstance.close();
          },
          onFinished() {
            modalInstance.close();
            resolve();
          },
        },
      }),
    },
  });
  modalInstance.open();
});

export default waitForAsyncModal;
