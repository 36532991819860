<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <h2 class="fx">
      Datei hochladen
    </h2>
    <hr>
    <ServerErrors v-model="serverErrors" title="Datei konnte nicht gespeichert werden" margin-bottom="1rem" />
    <div>
      <div class="fx-form-row">
        <div class="fx-form-label">
          <label for="file-upload" class="mandatory">Datei</label>
        </div>
        <div class="fx-form-content">
          <FileUploadButton :accept="accept" @change="setFileReference" />
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label for="file-name">Name</label>
        </div>
        <div class="fx-form-content">
          <input
            id="file-name"
            ref="filename"
            v-model="name"
            name="filename"
            type="text"
          >
        </div>
      </div>
      <hr>
      <button
        type="submit"
        :disabled="isSubmitButtonDisabled"
        class="fx-btn-primary float-right"
        style="margin-bottom: 0.5rem;"
        @click="onSubmit"
      >
        Datei hochladen
        <i v-if="isSubmitting" class="far fa-spinner fa-spin" aria-hidden="true" />
      </button>
      <div class="clearfix" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ServerErrors from 'components/partials/server_errors.vue';
import FileUploadButton from 'components/file_upload_button.vue';

export default {
  name: 'UploadAttachmentModal',
  components: {
    FileUploadButton,
    ServerErrors,
  },
  props: {
    modalName: {
      type: String,
      required: true,
    },
    installationId: {
      type: String,
      required: true,
    },
    accept: {
      type: String,
      required: true,
    },
  },
  emits: ['close', 'uploaded'],
  data() {
    return {
      serverErrors: [],
      name: '',
      isSubmitting: false,
      file: null,
      files: [],
    };
  },
  computed: {
    url() {
      return `/installations/${this.installationId}/attachments`;
    },
    isSubmitButtonDisabled() {
      return (this.isSubmitting || this.file == null);
    },
  },
  methods: {
    onSubmit() {
      this.isSubmitting = true;
      const that = this;
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('attachment_name', this.name);
      axios.post(this.url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        that.$emit('uploaded');
      }).catch((error) => {
        if (error.response && error.response.status === 400) {
          that.serverErrors = error.response.data.attachments;
        }
      });
    },
    setFileReference(event) {
      this.file = event.target.files[0];
    },
  },
};
</script>
