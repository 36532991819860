<template>
  <tr>
    <td class="icon">
      <input
        v-model="selected"
        type="checkbox"
        value="selected"
        @change="updateSelected"
      >
    </td>
    <td class="pprimary">
      <a class="no-color" :href="`/contacts/${contact.id}`">{{ contact.name }}</a>
    </td>
    <td>{{ contact.company }}</td>
    <td><a :href="`mailto:${contact.email}`" class="no-color">{{ contact.email }}</a></td>
    <td>
      <div v-if="contact.phone">
        <i class="fas fa-phone-alt fa-fw fx-inline-ico" /> <a
          :href="`tel:${contact.phone}`"
          class="no-color"
        >{{ contact.phone }}</a>
      </div>
      <div v-if="contact.mobile">
        <i class="fas fa-mobile-alt fa-fw fx-inline-ico" /> <a
          :href="`tel:${contact.mobile}`"
          class="no-color"
        >{{ contact.mobile }}</a>
      </div>
      <div v-if="contact.fax">
        <i class="fas fa-fax fa-fw fx-inline-ico" /> {{ contact.fax }} (Fax)
      </div>
    </td>
    <td>
      <a class="no-color" @click.prevent="$emit('open-references', contact)">
        <span v-tippy="customerCountTooltip" :class="customersCountClass"><i class="fas fa-briefcase" /> {{
          contact.customers_count }}</span>
        <span v-tippy="installationContactCountTooltip" :class="installationsContactCountClass" class="m-2"><i
          class="fas fa-sitemap"
        /> {{ contact.installation_contacts_count }}</span>
        <span v-tippy="installationReportRecipientCountTooltip" :class="installationsReportRecipientCountClass"><i
          class="far fa-file-export"
        /> {{
          contact.installation_report_recipients_count }}</span>

      </a>
      <div v-if="hasNote">
        <a v-tippy="noteTooltip" class="no-color"><i class="fas fa-comment" /> Notiz</a>
      </div>
    </td>

    <td class="controls">
      <div class="fx-btn-group">
        <a class="fx-btn-skeleton" @click.prevent="$emit('open-edit-contact', contact)">{{ $t('edit') }}</a>
        <ButtonDeleteIcon @click="$emit('open-confirm-delete', contact)" />
      </div>
    </td>
  </tr>
</template>

<script>
import ButtonDeleteIcon from 'components/button_delete_icon.vue';

export default {
  name: 'ContactListItem',
  components: {
    ButtonDeleteIcon,
  },
  props: {
    contact: {
      type: Object,
      required: true,
    },
    currentListSelectionType: {
      type: String,
      default: 'none',
    },
  },
  emits: ['open-edit-contact', 'open-confirm-delete', 'open-references', 'selected', 'unselected'],
  data() {
    return {
      selected: false,
    };
  },
  computed: {
    hasNote() {
      return (this.contact.note != null && this.contact.note.length > 0);
    },
    noteTooltip() {
      return {
        content: this.nl2br(`<div style='text-align: left;'>${this.contact.note.substring(0, 500)}</div>`),
        allowHTML: true,
        placement: 'top',
        duration: 100,
        delay: [200, 0],
        arrow: true,
      };
    },
    installationsContactCountClass() {
      if (this.contact.installation_contacts_count === 0) {
        return 'secondary';
      }
      return '';
    },
    installationsReportRecipientCountClass() {
      if (this.contact.installation_report_recipients_count === 0) {
        return 'secondary';
      }
      return '';
    },
    customersCountClass() {
      if (this.contact.customers_count === 0) {
        return 'secondary';
      }
      return '';
    },
    installationContactCountTooltip() {
      return {
        content: `Ansprechpartner für ${this.contact.installation_contacts_count} Anlagen`,
        placement: 'top',
        duration: 100,
        delay: [200, 0],
        arrow: true,
      };
    },
    installationReportRecipientCountTooltip() {
      return {
        content: `Berichtsempfänger für ${this.contact.installation_report_recipients_count} Anlagen`,
        placement: 'top',
        duration: 100,
        delay: [200, 0],
        arrow: true,
      };
    },
    customerCountTooltip() {
      return {
        content: `Kontakt für ${this.contact.customers_count} Kunden`,
        placement: 'top',
        duration: 100,
        delay: [200, 0],
        arrow: true,
      };
    },
  },
  watch: {
    currentListSelectionType: {
      handler(value) {
        if (value === 'none' && this.selected) {
          this.selected = false;
          this.updateSelected();
        }
        if (value === 'all' && !this.selected) {
          this.selected = true;
          this.updateSelected();
        }
      },
      deep: true,
    },
  },
  methods: {
    updateSelected() {
      if (this.selected) {
        this.$emit('selected', this.contact.id);
      } else {
        this.$emit('unselected', this.contact.id);
      }
    },
    nl2br(text) {
      return (`${text}`).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>' + '$2');
    },
  },
};
</script>

<style></style>
