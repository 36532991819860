<template>
  <div class="fx-card-notop">
    <table class="material top-align">
      <colgroup>
        <col>
        <col>
        <col style="width: 4rem;">
      </colgroup>
      <tbody>
        <tr v-if="incident.customer">
          <th style="width: 8rem;">
            Kunde
          </th>
          <td>
            <Labels v-if="incident.customer" :labels="incident.customer.labels" />
            <template v-if="showLinks">
              <a :href="customerUrl" class="no-color">
                <div class="secondary">{{ incident.customer.number }}</div>
                {{ incident.customer.name }}
              </a>
            </template>
            <template v-else>
              <span class="secondary">{{ incident.customer.number }}</span>
              <br>
              {{ incident.customer.name }}
            </template>
          </td>
          <td v-if="showLinks" style="text-align: right;">
            <drop-down classes="fx-context-circle" :disabled="false">
              <drop-down-item>
                <a @click.prevent="goToCustomersIncidents()"><i class="far fa-exclamation-triangle fw" />
                  Störungen des Kunden ansehen</a>
              </drop-down-item>
            </drop-down>
          </td>
        </tr>
        <tr>
          <th>Objekt</th>
          <td>
            <Location :location="incident.installation.location" :link="showLinks" />
          </td>
          <td v-if="showLinks" style="text-align: right;">
            <drop-down classes="fx-context-circle" :disabled="false">
              <drop-down-item>
                <a @click.prevent="goToLocationsIncidents()"><i class="far fa-exclamation-triangle fw" />
                  Störungen des Objekts ansehen</a>
              </drop-down-item>
            </drop-down>
          </td>
        </tr>
        <tr>
          <th>Anlage</th>
          <td colspan="2">
            <Labels :labels="incident.installation.labels" />
            <Installation :installation="incident.installation" :link="showLinks" />
          </td>
        </tr>
        <tr>
          <th>Gruppe</th>
          <td colspan="2">
            <div v-if="incident.thing">
              <template v-if="showLinks">
                <a :href="groupUrl" class="no-color">{{ groupLabel }}</a>
              </template>
              <template v-else>
                {{ groupLabel }}
              </template>
            </div>
            <div v-else class="secondary">
              keine Angabe
            </div>
          </td>
        </tr>
        <tr>
          <th>Komponente</th>
          <td colspan="2">
            <div v-if="incident.thing">
              <template v-if="showLinks">
                <i class="far fa-cube fx-inline-ico" />
                <a :href="thingUrl" class="no-color">{{ incident.thing.label }}</a>
                <div>{{ incident.thing.position }}</div>
              </template>
              <template v-else>
                {{ incident.thing.label }}
                <div>{{ incident.thing.position }}</div>
              </template>
            </div>

            <div v-else class="secondary">
              keine Angabe
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import Location from 'components/partials/location.vue';
import Installation from 'components/partials/installation.vue';
import Labels from 'components/partials/labels.vue';
import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';

import IncidentModel from '../incident_model';

export default {
  name: 'IncidentContext',
  components: {
    Location,
    Installation,
    Labels,
    DropDown,
    DropDownItem,
  },
  props: {
    incident: {
      type: Object,
      required: true,
    },
    showLinks: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    customerUrl() {
      return `/customers/${this.incident.customer.id}`;
    },
    installationUrl() {
      return `/installations/${this.incident.installation.id}`;
    },
    thingUrl() {
      return `/things/${this.incident.thing.id}`;
    },
    incidentStatus() {
      return IncidentModel.getCurrentStatus(this.incident);
    },
    groupLabel() {
      if (this.incident.thing == null) {
        return '';
      }
      const group = this.incident.thing.group;
      return `${group.number >= 0 ? group.number : ''} ${group.name}`;
    },
    groupUrl() {
      return `${this.installationUrl}#tab-things`;
    },
  },
  methods: {
    isStatus(status) {
      return this.incidentStatus != null && this.incidentStatus.status === status;
    },
    statusText(status) {
      return this.$t(`activerecord.attributes.incident_status.statuses.${status}`);
    },
    goToLocationsIncidents() {
      window.location.href = `/incidents/?location_id=${this.incident.installation.location.id}`;
    },
    goToCustomersIncidents() {
      window.location.href = `/incidents/?customer_id=${this.incident.customer.id}`;
    },
  },
};
</script>

<style></style>
