<template>
  <div>
    <div class="fx-form-row">
      <div class="fx-form-label">
        <label class="inline mandatory" for="number">Kundennummer</label>
      </div>
      <div class="fx-form-content">
        <input
          id="number"
          v-model="modelValue.number"
          name="number"
          type="text"
        >
      </div>
    </div>
    <div class="fx-form-row">
      <div class="fx-form-label">
        <label class="inline mandatory" for="customer_name">Name</label>
      </div>
      <div class="fx-form-content">
        <input
          id="customer_name"
          v-model="modelValue.name"
          name="customer_name"
          type="text"
        >
      </div>
    </div>

    <div v-if="currentUser.pro" class="fx-form-row">
      <div class="fx-form-label">
        <label class="inline" for="customer_link">Link</label>
      </div>
      <div class="fx-form-help">
        <HelpHover content="links" />
      </div>
      <div class="fx-form-content">
        <input
          id="customer_link"
          v-model="modelValue.link"
          class="has-form-field-hint"
          name="customer[link]"
          type="text"
        >
        <div class="form-field-hint">
          <ExternalLinkButton :href="modelValue.link" /> Link auf eine externe Webseite, Datei oder einen Speicherort.
        </div>
      </div>
    </div>

    <hr>
    <AddressForm v-model="modelValue.address" />
    <hr>

    <div v-if="withCoordinates" class="fx-form-row">
      <div class="fx-form-label">
        <label class="inline">Koordinaten</label>
      </div>
      <div class="fx-form-content">
        <input
          id="custom_coordinates_false"
          v-model="modelValue.address.custom_coordinates"
          type="radio"
          :value="false"
        >
        <label for="custom_coordinates_false">Automatisch ermitteln</label>
        <br>
        <input
          id="custom_coordinates_true"
          v-model="modelValue.address.custom_coordinates"
          type="radio"
          :value="true"
        >
        <label for="custom_coordinates_true">Eigene Koordinaten</label>
      </div>
    </div>

    <div v-if="!modelValue.address.custom_coordinates" class="fx-form-row">
      <div class="fx-form-content secondary" style="font-size: .825rem;">
        <div v-if="modelValue.address.geocoding_status === 'geocoded'">
          <i class="fas fa-map-marker-alt" style="margin-right: .2rem;" /> {{ $t('geocoding.address_is_geocoded') }}
        </div>
        <div v-if="modelValue.address.geocoding_status === 'failed'">
          <i class="far fa-ban" style="margin-right: .2rem;" /> {{ $t('geocoding.failed') }}
        </div>
        <div v-if="modelValue.address.geocoding_status === 'not_qualified'">
          <i class="far fa-ban" style="margin-right: .2rem;" /> {{
            $t('geocoding.address_does_not_qualify_for_geocoding') }}
        </div>
        <div v-if="modelValue.address.geocoding_status === 'ongoing'">
          <i class="far fa-ban" style="margin-right: .2rem;" /> {{ $t('geocoding.not_yet_geocoded') }}
        </div>
      </div>
    </div>

    <template v-if="modelValue.address.custom_coordinates">
      <div class="fx-form-row">
        <div class="fx-form-content">
          <help-article article-id="custom_coordinates" hide-modal-before-open="new-customer-modal">
            Hilfe zu eigenen
            Koordinaten
          </help-article>
        </div>
      </div>
      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline">Breitengrad (LAT)</label>
        </div>
        <div class="fx-form-content">
          <input
            id="address_lat"
            v-model="modelValue.address.lat"
            name="address_lat"
            type="text"
          >
          <div class="form-field-hint">
            Bitte als Fließkommazahl eingeben, bspw. 53.556556
          </div>
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline">Längengrad (LON)</label>
        </div>
        <div class="fx-form-content">
          <input
            id="address_lng"
            v-model="modelValue.address.lng"
            name="address_lng"
            type="text"
          >
          <div class="form-field-hint">
            Bitte als Fließkommazahl eingeben, bspw. 10.014156
          </div>
        </div>
      </div>
    </template>

    <div v-if="withAddLocation" class="fx-form-row">
      <div class="fx-form-label">
        <label class="inline">Erstes Objekt</label>
      </div>
      <div class="fx-form-content">
        <input
          id="add-location"
          v-model="modelValue.add_location"
          type="checkbox"
          name="add-location"
        > <label
          for="add-location"
          class="inline"
        >Anlagenstandort (Objekt) an der Adresse des Kunden anlegen</label>
      </div>
    </div>

    <div class="fx-form-row">
      <div class="fx-form-content">
        <button class="fx-btn-primary" :disabled="!canEdit" @click="$emit('submit', modelValue)">
          Kundendaten
          speichern
        </button>
        <!--<TransactionButton v-model="savingCustomer"
            class="fx-btn-primary" @click="$emit('submit', value)" title="Kundendaten speichern" />-->
      </div>
    </div>
  </div>
</template>

<script>
import AddressForm from 'components/address_form.vue';
import TransactionButton from 'components/transaction_button.vue';
import ExternalLinkButton from 'components/external_link_button.vue';
import FeatureFlipper from 'mixins/feature_flipper';
import HelpHover from 'components/help_hover.vue';
import { useCurrentUserStore } from 'stores/current_user';

export default {
  name: 'CustomerForm',
  components: {
    AddressForm,
    ExternalLinkButton,
    HelpHover,
  },
  mixins: [FeatureFlipper],
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    withCoordinates: {
      type: Boolean,
      default: true,
    },
    withAddLocation: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return { currentUser: useCurrentUserStore().currentUser };
  },
  data() {
    return {
      savingCustomer: false,
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  },
};
</script>
