<template>
  <section class="fx-page-content">
    <div class="fx-sidebar-layout-infobar">
      <h2 class="fx">
        <i class="fas fa-cog" /> Administration
      </h2>
    </div>
    <div v-if="initialDataLoaded" style="margin-bottom: 6rem;">
      <div class="fx-grid">
        <div class="fx-col">
          <ul class="fx-breadcrumps">
            <li><a href="/organizations/settings">Administration</a></li>
            <li><a href="/installation_types">Wartungsanwendungen</a></li>
            <li><a :href="`/installation_types/${installationTypeId}#tab-intervals`">{{ installationTypeName }}</a></li>
            <li>{{ intervalTypeLabel }}</li>
          </ul>

          <h2 class="fx" style="margin-top: 1rem;">
            {{ title }}
          </h2>
          <ServerErrors v-model="serverErrors" title="Intervall konnte nicht gespeichert werden" />

          <form @submit.prevent="submit">
            <div class="fx-grid">
              <div class="fx-col" style="padding: 0;">
                <!-- CARD -->
                <div class="fx-card fx-card-content">
                  <div class="fx-form-row">
                    <div class="fx-form-label">
                      <label class="inline mandatory">Prüfintervall</label>
                    </div>
                    <div class="fx-form-content">
                      <select v-model="interval.quantity" style="width: 80px; display: inline-block;">
                        <option v-for="index in 30" :key="index" :value="index">
                          {{ index }}
                        </option>
                      </select>&shy;
                      <select v-model="interval.frequency" style="width: 150px; display: inline-block;">
                        <option value="days">
                          Tag(e)
                        </option>
                        <option value="weeks">
                          Woche(n)
                        </option>
                        <option value="months">
                          Monat(e)
                        </option>
                        <option value="years">
                          Jahr(e)
                        </option>
                      </select>
                      <div v-if="showOkSeries" style="margin-top: .2rem;">
                        <input id="ok_series_enabled" v-model="interval.ok_series_enabled" type="checkbox">
                        <label for="ok_series_enabled">automatisch verlängern</label>
                        <div
                          v-if="interval.ok_series_enabled"
                          style="margin-top: .6rem; padding: .7rem; line-height: 48px; background-color: #f4f4f4"
                        >
                          nach <input
                            v-model="interval.ok_series_threshold"
                            type="text"
                            style="width: 70px; display: inline-block;"
                          > Prüfungen ohne Mangel<br> auf
                          <select v-model="interval.ok_series_factor" style="width: 270px; display: inline-block;">
                            <option value="2">
                              2-faches Intervall ({{ interval.quantity * 2 }} {{ humanFrequency(interval.frequency) }})
                            </option>
                            <option value="3">
                              3-faches Intervall ({{ interval.quantity * 3 }} {{ humanFrequency(interval.frequency) }})
                            </option>
                            <option value="4">
                              4-faches Intervall ({{ interval.quantity * 4 }} {{ humanFrequency(interval.frequency) }})
                            </option>
                            <option value="5">
                              5-faches Intervall ({{ interval.quantity * 5 }} {{ humanFrequency(interval.frequency) }})
                            </option>
                          </select>
                          verlängern
                        </div>
                      </div>
                    </div>
                  </div>

                  <template v-if="isCheckInterval">
                    <div class="fx-form-row">
                      <div class="fx-form-label">
                        <label class="inline mandatory">Status 'bald überfällig'</label>
                      </div>
                      <div class="fx-form-content">
                        <input
                          v-model="interval.warn_distance_days"
                          type="text"
                          style="width: 80px; display: inline-block;"
                        > Tage vor überfällig
                        <p style="font-size: .825rem;">
                          Komponenten in diesem Zeitraum werden als
                          <span style="color: #EDB200;"><i class="fas fa-circle color-near" /> bald überfällig</span>
                          angezeigt.
                        </p>
                      </div>
                    </div>

                    <!-- Start: Calculation Type -->
                    <template v-if="hasCalendric">
                      <div class="fx-form-row">
                        <div class="fx-form-label">
                          <label class="inline mandatory">Berechnung</label>
                        </div>
                        <div class="fx-form-content">
                          <select
                            v-model="interval.calculation_type"
                            style="width: 150px; display: inline-block;"
                            @change="setCalendricRootDefault()"
                          >
                            <option value="floating">
                              fließend
                            </option>
                            <option value="calendric">
                              kalendarisch
                            </option>
                          </select>
                        </div>
                      </div>

                      <div v-if="interval.calculation_type == 'calendric'" class="fx-form-row">
                        <div class="fx-form-label">
                          <label class="inline mandatory">Prüfzeitraum</label>
                        </div>
                        <div class="fx-form-content">
                          <select v-model="interval.check_period_quantity" style="width: 80px; display: inline-block;">
                            <option v-for="index in 31" :key="index" :value="index - 1">
                              {{ index - 1 }}
                            </option>
                          </select>&shy;
                          <select v-model="interval.check_period_frequency" style="width: 150px; display: inline-block;">
                            <option value="days">
                              Tag(e)
                            </option>
                            <option value="weeks">
                              Woche(n)
                            </option>
                            <option value="months">
                              Monat(e)
                            </option>
                            <option value="years">
                              Jahr(e)
                            </option>
                          </select>
                          <p style="font-size: .825rem;">
                            Der Prüfzeitraum ist die Zeitspanne, in der fällige Komponenten
                            geprüft werden sollen.
                            Komponenten im Prüfzeitraum werden <span class="color-unchecked"><i
                              class="fas fa-circle color-unchecked"
                            /> fällig</span> dargestellt. Nach Ablauf des
                            Prüfzeitraums sind Komponenten <span class="color-overdue"><i
                              class="fas fa-circle color-overdue"
                            /> überfällig</span>.
                          </p>
                        </div>
                      </div>

                      <div v-if="interval.calculation_type == 'calendric'" class="fx-form-row">
                        <div class="fx-form-label">
                          <label class="inline mandatory">Startdatum</label>
                        </div>
                        <div class="fx-form-content" style="max-width: 400px;">
                          <VueDatePicker
                            v-model="interval.calendric_root_at"
                            :locale="$i18n.locale"
                            :auto-apply="true"
                            model-type="yyyy-MM-dd"
                            :enable-time-picker="false"
                            :format="'dd.MM.yyyy'"
                            :disabled-dates="isFutureDate"
                          />
                          <p style="font-size: .825rem;">
                            Kalendarische Intervalle benötigen ein <strong>Startdatum</strong>
                            in der Vergangenheit, von dem aus die Intervalle berechnet werden.
                            Sie können dieses Startdatum an jeder Anlage überschreiben.
                          </p>
                        </div>
                      </div>
                    </template>

                    <!-- End: Calculation Type -->
                  </template>
                  <template v-else>
                    <div class="fx-form-row">
                      <div class="fx-form-label">
                        <label class="inline mandatory">Austausch ab</label>
                      </div>
                      <div class="fx-form-content" style="padding-top: 7px;">
                        <input
                          id="interval_replacement_type_installation"
                          v-model="interval.replacement_type"
                          type="radio"
                          :value="'installation'"
                        ><label
                          for="interval_replacement_type_installation"
                        >Inbetriebnahme</label>
                        <input
                          id="interval_replacement_type_yoc"
                          v-model="interval.replacement_type"
                          type="radio"
                          :value="'year_of_construction'"
                        ><label for="interval_replacement_type_yoc">Baujahr</label>
                      </div>
                    </div>
                  </template>
                </div>
                <!-- END CARD -->
              </div>
              <div v-if="hasCalendric && isCheckInterval" class="fx-col" style="max-width: 350px;">
                <div style="background-color: #f4f4f4; padding: .8rem .8rem 2rem; .8rem;">
                  <h3 class="fx">
                    Infoblatt Intervalle
                  </h3>
                  <a href="/Foxtag_Infoblatt_Intervalle.pdf" style="float: left; margin-right: 1rem;">
                    <img :src="intervalPdf" width="80px">
                  </a> Die wichtigsten Informationen auf einer Seite zusammengestellt.
                  <a href="/Foxtag_Infoblatt_Intervalle.pdf">Infoblatt herunterladen</a>
                </div>
              </div>
            </div>

            <div class="fx-card fx-card-content" style="margin-bottom: 1rem;">
              <h2 class="fx">
                Zuordnung zu den Komponenten
              </h2>
              <div class="fx-form-row">
                <div class="fx-form-label">
                  <label class="inline mandatory">Kategorie</label>
                </div>
                <div class="fx-form-content">
                  <p>Für welche Kategorie von Komponenten soll dieses Intervall verwendet werden?</p>
                  <input
                    id="interval_category_0"
                    v-model="interval.category"
                    type="radio"
                    :value="0"
                    @click="clearSelectedTypes"
                  >
                  <label for="interval_category_0">Komponenten</label>
                  <input
                    id="interval_category_1"
                    v-model="interval.category"
                    type="radio"
                    :value="1"
                    @click="clearSelectedTypes"
                  >
                  <label for="interval_category_1">Sub-Komponenten</label>
                  <input
                    id="interval_category_2"
                    v-model="interval.category"
                    type="radio"
                    :value="2"
                    @click="clearSelectedTypes"
                  >
                  <label for="interval_category_2">Zentralen</label>
                  <div style="font-size: .8rem; color: #0275d8;">
                    <HelpHover text="Was bedeuten die Kategorien" content="thingtype_categories" />
                  </div>
                </div>
              </div>
              <hr>
              <div class="fx-form-row">
                <div class="fx-form-label">
                  <label class="inline mandatory">Typen-Filter</label>
                </div>
                <div class="fx-form-content" style="width: 800px;">
                  <p>Hier können Sie dieses Intervall nur bestimmten Typen oder Herstellern zuordnen.</p>
                  <input
                    id="interval_all_types_true"
                    v-model="interval.all_types"
                    type="radio"
                    :value="true"
                  >
                  <label for="interval_all_types_true">Alle Typen der Kategorie
                    "{{ categoryLabels[interval.category] }}"</label><br>
                  <input
                    id="interval_all_types_false"
                    v-model="interval.all_types"
                    type="radio"
                    :value="false"
                  >
                  <label for="interval_all_types_false">Nur {{ categoryLabels[interval.category] }} bestimmter Typen oder
                    Hersteller</label>

                  <ThingTypeFilter
                    v-if="!interval.all_types"
                    v-model="interval.selected_types"
                    :installation-type-id="installationTypeId"
                    :category="interval.category"
                    @update:model-value="interval.selected_types = $event"
                  />
                </div>
              </div>
            </div>

            <div class="callout callout--clock" style="margin-bottom: .8rem;">
              Nach dem Speichern kann es mehrere Minuten dauern, bis die Fälligkeiten aller Komponenten neu berechnet sind.
            </div>
            <TransactionButton
              v-model="saving"
              class="fx-btn-primary float-right"
              style="margin-bottom: 0.5rem; margin-left: 0.5rem;"
              title="Speichern"
              @click="submit()"
            />

            <button class="fx-btn-secondary float-right" @click.prevent="backToIntervalsList()">
              abbrechen
            </button>
            <div class="clearfix" />
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';

import intervalPdf from 'images/interval-pdf.png';

import ServerErrors from 'components/partials/server_errors.vue';
import TransactionButton from 'components/transaction_button.vue';
import HelpHover from 'components/help_hover.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import ThingTypeFilter from './thing_type_filter.vue';

export default {
  name: 'EditInterval',
  components: {
    ThingTypeFilter,
    ServerErrors,
    TransactionButton,
    HelpHover,
    VueDatePicker,
  },
  props: {
    intervalId: {
      type: String,
      required: false,
      default: null,
    },
    intervalType: {
      type: String,
      default: null,
    },
    installationTypeName: {
      type: String,
      required: true,
    },
    installationTypeId: {
      type: String,
      required: true,
    },
    hasCalendric: {
      type: Boolean,
      default: false,
    },
    hasOkSeries: {
      type: Boolean,
      default: false,
    },
    afterSubmitTitle: {
      type: String,
      default: 'Neuberechnung gestartet ...',
    },
  },
  data() {
    return {
      initialDataLoaded: false,
      intervalPdf,
      interval: null,
      serverErrors: [],
      categoryLabels: ['Komponenten', 'Sub-Komponenten', 'Zentralen'],
      saving: false,
    };
  },
  computed: {
    title() {
      if (this.isCreate()) {
        return `Neues ${this.intervalTypeLabel}`;
      }
      return `${this.intervalTypeLabel} bearbeiten`;
    },
    isCheckInterval() {
      return this.interval.interval_type === 'check';
    },
    intervalTypeLabel() {
      if (this.isCheckInterval) {
        return 'Prüfintervall';
      }
      return 'Austauschintervall';
    },
    showOkSeries() {
      return (this.hasOkSeries && this.interval.calculation_type === 'floating');
    },
  },
  watch: {
    calculationType: {
      handler(newVal) {
        if ((newVal === 'calendric') && (this.calendricRootAt == null || this.calendricRootAt.length === 0)) {
          this.calendricRootAt = '2019-01-01';
        }
      },
    },
  },
  mounted() {
    this.loadInterval();
  },
  methods: {
    clearSelectedTypes() {
      this.interval.all_types = true;
      this.interval.selected_types = [{ type: '', manufacturer: '' }];
    },
    humanFrequency(frequency) {
      if (frequency === 'days') { return 'Tage'; }
      if (frequency === 'weeks') { return 'Wochen'; }
      if (frequency === 'months') { return 'Monate'; }
      return 'Jahre';
    },
    backToIntervalsList() {
      window.location.href = `/installation_types/${this.installationTypeId}#tab-intervals`;
    },
    isCreate() {
      return !this.intervalId;
    },
    setCalendricRootDefault() {
      if (this.interval.calendric_root_at == null) {
        this.interval.calendric_root_at = '2020-01-01';
      }
    },
    submit() {
      if (this.isCreate()) {
        this.createViaAPI();
      } else {
        this.updateViaAPI();
      }
    },
    createViaAPI() {
      this.saving = true;
      const that = this;
      that.serverErrors = [];
      axios.post(`/installation_types/${this.installationTypeId}/intervals.json`, {
        interval: this.interval,
      }).then(() => {
        this.backToIntervalsList();
      }).catch((error) => {
        this.saving = false;
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.intervals;
          Flash.info('Bitte überprüfen Sie Ihre Eingaben');
        } else {
          Flash.error('Interval konnte nicht gespeichert werden');
        }
      });
    },
    updateViaAPI() {
      this.saving = true;
      const that = this;
      that.serverErrors = [];
      axios.put(`/installation_types/${this.interval.installation_type_id}/intervals/${this.interval.id}`, {
        interval: this.interval,
      }).then(() => {
        this.backToIntervalsList();
      }).catch((error) => {
        this.saving = false;
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.intervals;
          window.scrollTo(0, 0);
          Flash.info('Bitte überprüfen Sie Ihre Eingaben');
        } else {
          Flash.error('Die Änderung konnte nicht gespeichert werden');
        }
      });
    },
    loadInterval() {
      const that = this;
      if (this.intervalId) {
        axios.get('edit.json')
          .then((response) => {
            that.interval = response.data.interval;
            that.initialDataLoaded = true;
          }).catch(() => {
            // Flash.error('Interval konnte nicht geladen werden');
          });
      } else {
        axios.get('new.json', {
          params: {
            interval_type: this.intervalType,
          },
        })
          .then((response) => {
            that.interval = response.data.interval;
            that.initialDataLoaded = true;
          }).catch(() => {
            // Flash.error('Interval konnte nicht geladen werden');
          });
      }
    },
  },
};
</script>
