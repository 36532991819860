<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <h2 class="fx">
      Notiz bearbeiten
    </h2>

    <hr style="margin-top:0;">
    <ServerErrors v-model="serverErrors" title="Notiz konnte nicht gespeichert werden" margin-bottom="1rem" />

    <form @submit.prevent="submit">
      <div class="fx-form-row">
        <textarea
          id="note_text"
          v-model="noteText"
          maxlength="1000"
          rows="5"
          class="fx-focus"
        />
        <input
          id="note_sticky"
          v-model="sticky"
          style="margin-left: .8rem;"
          type="checkbox"
          name="note_sticky"
        ><label for="note_sticky" style="color: rgb(102, 102, 102);">Anpinnen <HelpHover content="installation_note_pinned" /></label>
      </div>

      <hr>
      <button
        id="submit-edit-group"
        type="submit"
        class="fx-btn-primary float-right"
        style="margin-bottom: 0.5rem; margin-left: 0.5rem;"
      >
        Speichern
      </button>
      <button class="fx-btn-secondary float-right" @click.prevent="$emit('close')">
        Abbrechen
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import axios from 'axios';

import Flash from 'flash/index';

import HelpHover from 'components/help_hover.vue';
import ServerErrors from 'components/partials/server_errors.vue';

export default {
  name: 'EditNoteModal',
  components: {
    HelpHover,
    ServerErrors,
  },
  props: {
    note: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['close', 'updated'],
  data() {
    return {
      noteId: null,
      noteText: '',
      sticky: false,
      serverErrors: [],
    };
  },
  mounted() {
    this.noteId = this.note.id;
    this.noteText = this.note.note;
    this.sticky = this.note.sticky;
  },
  methods: {
    isCreate() {
      return !this.groupId;
    },
    submit() {
      const that = this;
      that.serverErrors = [];
      axios.put(`/installation_notes/${this.noteId}`, {
        installation_note: {
          note: this.noteText,
          sticky: this.sticky,
        },
      }).then((response) => {
        Flash.info('Notiz gespeichert');
        this.$emit('close');
        this.$emit('updated', { ...response.data.installation_note });
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.installation_notes;
        } else {
          Flash.error('Die Änderung konnte nicht gespeichert werden');
        }
      });
    },
  },
};
</script>
