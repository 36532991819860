<template>
  <div>
    <div class="repco_preview" style="border-top: 1px solid #bbb;">
      <template v-if="showForm">
        <div style="background-color: #eee; min-height: 16rem; border-radius: 8px; padding: 1rem;">
          <h3 class="fx">
            {{ name }}
          </h3>

          <div class="callout callout--info mb-4" style="margin-bottom: 1rem;">
            Damit Sie ein Layout möglichst oft wiederverwenden können, werden <strong>Bericht-Titel und Untertitel</strong> am
            jeweiligen <strong>Auftragstyp</strong> konfiguriert.
          </div>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline" for="customer_name">Durchgeführt von</label>
            </div>
            <div class="fx-form-content" style="width: 35rem;">
              <textarea
                v-model="localSection.service_by_introduction"
                rows="3"
                maxlength="250"
                type="text"
              />
              <div class="text-sm">
                Dieser Text steht im Feld "Durchgeführt von" über den Namen der beteiligten Techniker.
                Wenn Sie diesen Text leer lassen, wird Ihr Firmenname verwendet.
              </div>
            </div>
          </div>

          <div class="fx-form-row">
            <div class="fx-form-content">
              <input
                id="shows_report_date"
                v-model="localSection.shows_report_date"
                :disabled="layoutEngine === 'foxtag_report'"
                type="checkbox"
                :title="layoutEngine === 'foxtag_report' ? 'Diese Option steht der ausgewählten Layout-Engine nicht zur Verfügung' : ''"
              >
              <label
                class="inline"
                for="shows_report_date"
                :title="layoutEngine === 'foxtag_report' ? 'Diese Option steht der ausgewählten Layout-Engine nicht zur Verfügung' : ''"
              >
                Berichtsdatum abdrucken
              </label>
              <br>
              <input id="shows_job_number" v-model="localSection.shows_job_number" type="checkbox">
              <label class="inline" for="shows_job_number">Auftragsnummer abdrucken</label>
              <br>
              <input id="shows_customer_number" v-model="localSection.shows_customer_number" type="checkbox">
              <label class="inline" for="shows_customer_number">Kundennummer abdrucken</label>
              <br>
              <input id="shows_location_number" v-model="localSection.shows_location_number" type="checkbox">
              <label class="inline" for="shows_location_number">Objektnummer abdrucken</label>
              <br>
              <input id="shows_installation_number" v-model="localSection.shows_installation_number" type="checkbox">
              <label class="inline" for="shows_installation_number">Anlagennummer abdrucken</label>
            </div>
          </div>

          <button class="fx-btn-primary" @click="showForm=false">
            Zurück zur Vorschau
          </button>
        </div>
      </template>
      <template v-if="!showForm">
        <div :class="previewEngineClass">
          <div style="float: right">
            <img :src="companyLogoUrl" style="height: 110px; max-height: 110px;">
          </div>
          <div class="repco-h1 mt-4" style="margin-top: 2rem;" :style="headlineStyle">
            Bericht-Titel
          </div>
          <div class="repco-h2 clearfix" :style="headlineStyle">
            Bericht-Untertitel
          </div>

          <div style="margin:  2rem 0 .5rem 0; font-size: .76rem;">
            <span>{{ headerInformation }}</span>
          </div>
          <table class="preview">
            <tr>
              <th :style="thStyle">
                Kunde
              </th>
              <th :style="thStyle">
                Standort der Anlage
              </th>
              <th :style="thStyle">
                Durchgeführt von
              </th>
            </tr>
            <tr>
              <td style="width: 33%" :style="tdStyle">
                Kunde GmbH<br>Beispielstr. 1<br>10000 Beispielstadt
              </td>
              <td style="width: 33%" :style="tdStyle">
                Beispielstr. 2<br>20000 Beispielstadt
              </td>
              <td style="width: 34%" :style="tdStyle">
                <span style="white-space: pre-wrap;">{{ serviceByIntroduction }}</span><br>Mitarbeiter 1<br>Mitarbeiter 2
              </td>
            </tr>
          </table>
        </div>
      </template>
    </div>

    <Controls
      :index="index"
      :list-length="listLength"
      :name="name"
      :readonly="readonly"
      @settings="showForm = !showForm"
      @add="$emit('add')"
      @delete="$emit('delete')"
      @move-up="$emit('moveUp')"
      @move-down="$emit('moveDown')"
    />
  </div>
</template>

<script>
import Controls from './section_controls.vue';

export default {
  name: 'SectionHeader',
  components: {
    Controls,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      default: 'Kopfbereich',
    },
    index: {
      type: Number,
      required: true,
    },
    colors: {
      type: Object,
      required: true,
    },
    companyLogoUrl: {
      type: String,
      required: true,
    },
    organization: {
      type: String,
      default: 'Firmenname',
    },
    listLength: {
      type: Number,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    layoutEngine: {
      type: String,
      default: 'foxtag_report',
    },
  },
  emits: ['add', 'delete', 'moveUp', 'moveDown'],
  data() {
    return {
      showForm: false,
      localSection: {},
    };
  },
  computed: {
    previewEngineClass() {
      return `preview-${this.layoutEngine}`;
    },
    serviceByIntroduction() {
      if (this.localSection.service_by_introduction && this.localSection.service_by_introduction.length > 0) {
        return this.localSection.service_by_introduction;
      }
      return this.organization;
    },
    thStyle() {
      if (this.layoutEngine === 'exp_report') {
        return `background-color: transparent; border-color: #${this.colors.color_lines}; border: 0; border-bottom: 2px solid #${this.colors.color_lines}`;
      }

      return `color: #${this.colors.color_col_headline_text}; background-color: #${this.colors.color_lines}; border: 2px solid #${this.colors.color_lines}`;
    },
    tdStyle() {
      if (this.layoutEngine === 'exp_report') {
        return `color: #${this.colors.color_text}; border: 0;`;
      }

      return `color: #${this.colors.color_text}; border-color: #${this.colors.color_lines}`;
    },
    headlineStyle() {
      return `color: #${this.colors.color_headlines};`;
    },
    headerInformation() {
      const info = [];

      if (this.localSection.shows_report_date || this.layoutEngine === 'foxtag_report') {
        info.push('Bericht vom: 01.01.2000');
      }
      if (this.localSection.shows_job_number) {
        info.push('Auftragsnummer: A-XXXX');
      }
      if (this.localSection.shows_customer_number) {
        info.push('Kundennummer: K-XXXX');
      }
      if (this.localSection.shows_location_number) {
        info.push('Objektnummer: O-XXXX');
      }
      if (this.localSection.shows_installation_number) {
        info.push('Anlagennummer: ANL-XXXX');
      }
      return info.join(' | ');
    },
  },
  watch: {
    // ensure on change from exp_report to foxtag_report:
    // report_date checkbox is not shown as unchecked and disabled
    layoutEngine(newVal) {
      if (newVal === 'foxtag_report') {
        this.localSection.shows_report_date = true;
      }
    },
  },
  mounted() {
    this.localSection = this.section;
    this.checkAndAddDateAttribute();
  },
  methods: {
    checkAndAddDateAttribute() {
      if (typeof this.localSection.shows_report_date === 'undefined') {
        this.localSection.shows_report_date = true;
      }
    },
  },
};
</script>
