import VueAppFactory from 'helpers/vue_app_factory';

import InviteShow from 'apps/invites/show/index.vue';

import RegistrationIndex from 'apps/registration/index.vue';
import DefaultLayout from 'components/layouts/default_layout.vue';

import IncidentsIndex from 'apps/incidents/index/index.vue';
import NotificationsIndex from 'apps/notifications/index/index.vue';
import AnalysisIndex from 'apps/analysis/index/index.vue';

import CustomerShow from 'apps/customers/show/index.vue';
import LocationShow from 'apps/locations/show/index.vue';
import IncidentShow from 'apps/incidents/show/index.vue';
import InstallationShow from 'apps/installations/show/index.vue';
import ThingShow from 'apps/things/show/index.vue';

import JobsIndex from 'apps/jobs/index/index.vue';
import JobShow from 'apps/jobs/show/index.vue';
import JobEdit from 'apps/jobs/edit/index.vue';
import JobPlanning from 'apps/jobs/planning/index.vue';

import JobTypeEdit from 'apps/installation_types/job_types/edit/index.vue';

import InstallationTypeShow from 'apps/installation_types/show/index.vue';

import LayoutEdit from 'apps/report_layouts/edit/index.vue';

import MainMap from 'apps/maps/main/index.vue';

import ImportMapping from 'apps/imports/mapping/index.vue';
import LocationsIndex from 'apps/locations/index/index.vue';
import CustomersIndex from 'apps/customers/index/index.vue';
import Dashboard from 'apps/dashboard/index.vue';

import PortalLocationsIndex from 'apps/portal/locations/list.vue';
import PortalSharedInstallationShow from 'apps/portal/shared_installation/show.vue';
import PortalTopbar from 'apps/portal/topbar/topbar.vue';
import PortalIncidentShow from 'apps/portal/incidents/show/index.vue';
import PortalIncidentNew from 'apps/portal/incidents/new/index.vue';

import ChecklistEdit from 'apps/installation_types/checklists/edit/index.vue';
import IntervalEdit from 'apps/installation_types/intervals/edit/index.vue';

VueAppFactory({
  el: '#basic-page',
  components: {
    DefaultLayout,
    Dashboard,
    JobsIndex,
    CustomersIndex,
    LocationsIndex,
    IncidentsIndex,
    NotificationsIndex,
    AnalysisIndex,
    CustomerShow,
    LocationShow,
    LayoutEdit,
    JobShow,
    JobEdit,
    JobPlanning,
    JobTypeEdit,
    IncidentShow,
    InstallationShow,
    ImportMapping,
    MainMap,
    InviteShow,
    RegistrationIndex,
    InstallationTypeShow,
    ThingShow,
    PortalLocationsIndex,
    PortalTopbar,
    PortalSharedInstallationShow,
    PortalIncidentShow,
    PortalIncidentNew,
    ChecklistEdit,
    IntervalEdit,
  },
});
