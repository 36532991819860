<template>
  <div class="fx-flex-row">
    <div class="fx-flex-row-ico">
      <i class="fas fa-sitemap" />
    </div>
    <div class="fx-flex-row-main">
      <div class="fx-flex-row-title">
        <a class="no-color mr-2" :href="sharedInstallationPath">
          <span v-if="sharedInstallation.installation.number" class="fx-flex-row-title-number">{{ sharedInstallation.installation.number }}</span>
          {{ sharedInstallation.installation.full_name }}
        </a>
        <Labels :labels="sharedInstallation.installation.labels" />
      </div>

      <div class="fx-flex-row-subline">
        <!-- due dates -->
        <template v-if="portalRoles(sharedInstallation).dueDates">
          <NextCheckInfo v-if="sharedInstallation.installation.next_check_at" :next-date="sharedInstallation.installation.next_check_at" :next-status="sharedInstallation.installation.next_check_status" />
          <NextCheckInfo
            v-if="sharedInstallation.installation.next_replacement_at"
            :next-date="sharedInstallation.installation.next_replacement_at"
            :next-status="sharedInstallation.installation.next_replacement_status"
            :is-replacement="true"
          />
        </template>

        <!-- next job -->
        <template v-if="portalRoles(sharedInstallation).jobs">
          <span v-if="installation.next_job_id" v-tippy="lateTippyParams('Nächster geplanter Auftrag')" class="fx-ico-text fx-ico-text-job">
            <a :href="portalSharedInstallationJobPath(sharedInstallation.id, installation.next_job_id)" class="no-color">
              <strong>{{ truncate(installation.next_job_number, 12) }}</strong> {{ truncate(installation.next_job_type_name, 100) }} {{ formatDate(installation.next_job_planned_at, 'DD.MM.YYYY') }}
            </a>
          </span>
          <span v-else class="fx-ico-text fx-ico-text-job secondary">kein Auftrag geplant</span>
        </template>
      </div>
    </div>
    <div class="fx-flex-row-nav">
      <div style="width: 120px; color: #666;">
        <template v-if="portalRoles(sharedInstallation).inventory">
          <a v-tippy="lateTippyParams('Anzahl Komponenten')" class="no-color" :href="sharedInstallationPath + '#inventory'">
            <span><i class="far fa-cube" /> {{ installation.things_count }}</span>
          </a>
        </template>

        <a
          v-if="portalRoles(sharedInstallation).incidents"
          v-tippy="lateTippyParams('Anzahl offene Störungen')"
          class="no-color ml-2"
          :href="sharedInstallationPath + '#incidents'"
        >
          <template v-if="installation.open_incidents_count > 0">
            <i class="fas fa-exclamation-triangle color-warn" /> {{ installation.open_incidents_count }}
          </template>
          <template v-else>
            <i class="fas fa-exclamation-triangle" /> {{ installation.open_incidents_count }}
          </template>
        </a>
      </div>

      <a v-if="portalRoles(sharedInstallation).incidents" :href="newIncidentPath"><button class="fx-btn-skeleton" style="white-space: nowrap;">Störung melden</button></a> <a :href="sharedInstallationPath"><button class="fx-btn-skeleton">ansehen</button></a>

      <button
        v-if="installation.passive"
        class="fx-btn-skeleton"
        disabled="true"
        style="white-space: nowrap;"
      >
        Auftrag planen
      </button>
    </div>
  </div>
</template>
<script>
import FeatureFlipper from 'mixins/feature_flipper';
import PortalRoles from 'mixins/portal_roles';
import FoxtagPaths from 'mixins/foxtag_paths';
import { DateFormatter, TruncateFormatter } from 'mixins/formatters';
import { useCurrentUserStore } from 'stores/current_user';
import NextCheckInfo from 'components/partials/next_check_info.vue';
import Labels from 'components/partials/labels.vue';

export default {
  name: 'InstallationListItem',
  components: {
    NextCheckInfo,
    Labels,
  },
  mixins: [FeatureFlipper, PortalRoles, FoxtagPaths, DateFormatter, TruncateFormatter],
  props: {
    sharedInstallation: {
      type: Object,
      required: true,
    },
    showNextJobCol: {
      type: Boolean,
      required: true,
    },
    showNextCheckCol: {
      type: Boolean,
      required: true,
    },
    showInventoryCol: {
      type: Boolean,
      required: true,
    },
    showIncidentsCol: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    return {
      currentUser: useCurrentUserStore().currentUser,
    };
  },
  computed: {

    installation() {
      return this.sharedInstallation.installation;
    },
    newIncidentPath() {
      return `/portal/incidents/new/?installation_id=${this.sharedInstallation.installation.id}`;
    },
    sharedInstallationPath() {
      return `/portal/shared_installations/${this.sharedInstallation.id}`;
    },
  },
  mounted() {
  },
  methods: {
    sharedInstallationJobPath(jobId) {
      return `/portal/shared_installations/${this.sharedInstallation.id}?job=${jobId}`;
    },
    lateTippyParams(content) {
      return {
        content, placement: 'top', duration: 100, delay: [500, 0], arrow: true,
      };
    },
  },
};
</script>
