<template>
  <div class="timeline" style="padding-bottom: 2rem;">
    <img
      :src="avatarPath"
      width="40px"
      height="40px"
      style="vertical-align: middle; display: inline-block; float: left; position: absolute;"
    >
    <div class="timeline-content">
      <div v-if="showLinks" class="float-right" style="padding-top: 5px; fpadding-right: 10px;">
        <drop-down classes="fx-context-circle" :disabled="false">
          <drop-down-item>
            <a @click.prevent="$emit('editIncidentStatus', incidentStatus)"><i class="fas fa-pen fw" />
              Status bearbeiten</a>
          </drop-down-item>

          <template v-if="enableMoveMenu">
            <drop-down-item>
              <a @click.prevent="$emit('moveStatusTextToDescription', incidentStatus)"><i
                class="fas fa-sparkles fw"
              /> Hinweis in Beschreibung umwandeln</a>
            </drop-down-item>
          </template>

          <drop-down-item>
            <a @click.prevent="$emit('deleteIncidentStatus', incidentStatus)"><i
              class="far fa-trash-alt fw"
            /> Status löschen</a>
          </drop-down-item>
        </drop-down>
      </div>
      <span :class="'fx-status incident-' + incidentStatus.status">
        {{ $t('activerecord.attributes.incident_status.statuses.' + incidentStatus.status) }}
      </span>
      <span style="margin-left: .2rem;"><strong>{{ statusDate }}</strong> {{ statusDateTime }}</span>

      <div style="line-height: 25px; margin-top: .4rem;">
        <strong>{{ username }}</strong>
        <span v-if="hasJob">
          im Auftrag <a :href="calculatedjobUrl">{{ jobType }} {{ jobDate }}</a>
        </span>
      </div>
      <div style="margin-top: .2rem; font-size: .9rem; white-space: pre-wrap;">
        {{ note }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';

export default {
  name: 'IncidentStatus',
  components: {
    DropDown,
    DropDownItem,
  },
  props: {
    incidentStatus: {
      type: Object,
      required: true,
    },
    showLinks: {
      type: Boolean,
      default: true,
    },
    jobUrl: {
      type: String,
      default: '/jobs/',
    },
    enableMove: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    enableMoveMenu() {
      return (this.enableMove && this.incidentStatus.note != null && this.incidentStatus.note !== '');
    },
    statusDate() {
      return moment(this.incidentStatus.statusdate).format('DD.MM.YYYY');
    },
    statusDateTime() {
      return `${moment(this.incidentStatus.statusdate).format('HH:mm')} Uhr`;
    },
    status() {
      return this.incidentStatus.status;
    },
    note() {
      return this.incidentStatus.note;
    },
    username() {
      return this.incidentStatus.user.name;
    },
    avatarPath() {
      return this.incidentStatus.user.avatar_path;
    },
    hasJob() {
      return !!this.incidentStatus.job;
    },
    jobType() {
      if (!this.hasJob) {
        return null;
      }
      return this.incidentStatus.job.job_type_label;
    },
    jobDate() {
      if (!this.hasJob) {
        return null;
      }
      const date = this.incidentStatus.job.planned_at;
      return moment(date).format('DD.MM.YYYY');
    },
    calculatedjobUrl() {
      if (!this.hasJob) {
        return null;
      }
      return `${this.jobUrl}${this.incidentStatus.job.id}`;
    },
  },
  methods: {
    formatDatetime(string) {
      return `${moment(string).format('DD.MM.YYYY HH:mm')} Uhr`;
    },
  },
};
</script>
