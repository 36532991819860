<template>
  <div>
    <ServerErrors v-model="serverErrors" title="Notiz konnte nicht gespeichert werden" margin-bottom="1rem" />
    <div v-if="localNotes.length" id="sticky-notes" class="fx-card">
      <div class="fx-card-content">
        <span style="font-size: .825rem; font-weight: bold; color: #666;"><i class="fas fa-thumbtack" /> Angepinnt</span>
        <div
          v-for="note in localNotes"
          :key="note.id"
          style="margin-bottom: .2rem; margin-left:0; cursor: pointer;"
          class="v--hover timeline-content"
          @click.stop="openEditNoteModal(note)"
        >
          <div
            v-if="canEdit(note)"
            class="v--hover-target float-right"
            style="padding-top: 0px; padding-right: 10px;"
            >
            <button class="fx-context-circle" @click.stop="openEditNoteModal(note)">
              <i class="far fa-pencil fw" />
            </button>
            <button class="fx-context-circle" @click.stop="confirmDeleteNote(note)">
              <i class="far fa-trash-alt fw" />
            </button>
            <!-- <a @click.prevent="openEditNoteModal(note)">bearbeiten</a>
            &nbsp;&nbsp;
            <a @click.prevent="confirmDeleteNote(note)">löschen</a>-->
          </div>
          <div>
            <div style="font-size: 1rem;">
              <pre>{{ note.note }}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="jobId" class="secondary float-right" style="margin-bottom: .2rem;">
      {{ notesInJobLabel }}
    </div>

    <div id="notes" class="fx-card" style="clear: both;">
      <div class="fx-card-content">
        <div
          style="margin-bottom: .5rem; margin-left:0; border-radius: 6px;
    background-color: #f6f6f6;"
          class="timeline-content"
        >
          <img
            :src="currentUser.avatarPath"
            style="float: left;"
            width="30px"
            height="30px"
          >
          <div
            style="margin-left: 40px; vertical-align: middle;
            font-size: 0.8rem; border: 1px solid #ccc; border-radius: 4px;
            background-color: #fff; padding: 5px;"
          >
            <textarea
              id="new_note_text"
              ref="textarea"
              v-model="newNoteText"
              maxlength="1000"
              style="overflow: hidden; margin: 0; padding: 5px; border: 0; border-color: transparent; box-shadow: none; resize: none;"
              placeholder="Notiz hinzufügen ..."
              class="fx-focus"
              @focus="showSave = true"
              @input="resize()"
            />
            <div v-if="showSave" style="margin: .6rem .2rem .2rem .2rem;">
              <button :class="saveButtonClasses" @click.prevent="save()">
                Speichern
              </button>
              <input
                id="new_note_sticky"
                v-model="newNoteSticky"
                style="margin-left: .8rem;"
                type="checkbox"
                name="new_note_sticky"
              ><label for="new_note_sticky" style="color: rgb(102, 102, 102);">Anpinnen <HelpHover content="installation_note_pinned" /></label>
              <a style="margin-left: .8rem;" @click.prevent="cancel()">abbrechen</a>
            </div>
          </div>
        </div>

        <div
          v-for="note in installationNotes"
          :key="note.id"
          style="margin-bottom: .6rem;
            margin-left:0;"
          class="v--hover timeline-content"
        >
          <div v-if="canEdit(note)" class="v--hover-target float-right" style="padding-top: 5px; padding-right: 10px;">
            <button class="fx-context-circle" @click.stop="openEditNoteModal(note)">
              <i class="far fa-pencil fw" />
            </button>

            <button class="fx-context-circle" @click.stop="confirmDeleteNote(note)">
              <i class="far fa-trash-alt fw" />
            </button>
          </div>
          <div>
            <img
              :src="note.user_avatar_path"
              style="float: left;"
              width="30px"
              height="30px"
            >
            <div style="margin-left: 40px; vertical-align: middle; font-size: 0.8rem;">
              <strong class="mr-2">{{ note.user_name }}</strong>
              <span
                v-tippy="{ placement: 'top', duration: 100, arrow: true }"
                :title="formattedTimeWithMinute(note.note_taken_at)"
                style="text-decoration-color: #bbb; text-decoration-line: underline;"
              >{{ friendlyTime(note.note_taken_at) }}</span>
              <span v-if="note.revised_at" class="secondary">
                (bearbeitet
                <span
                  v-tippy="{ placement: 'top', duration: 100, arrow: true }"
                  :title="formattedTimeWithMinute(note.revised_at)"
                  style="text-decoration-color: #bbb;
                text-decoration-line: underline;"
                >{{ friendlyTime(note.revised_at) }}</span> von {{ note.revised_by_name }})
              </span>

              <div v-if="note.job_id" style="margin: 0 0 .2rem 0;">
                <div v-if="note.job_id == jobId">
                  <span style="color: #256FC5; font-weight: bold;">in diesem Auftrag</span>
                </div>
                <div v-if="note.job_id != jobId">
                  in <a :href="'/jobs/'+note.job_id">{{ note.job_label }}</a>
                </div>
              </div>

              <div class="cursor-pointer" style="margin-top: 0; font-size: 1rem;" @click.stop="openEditNoteModal(note)">
                <pre>{{ note.note }}</pre>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!installationNotes.length" class="secondary">
          Keine Notizen an dieser Anlage
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';
import Flash from 'flash/index';
import { ref } from 'vue';
import { useCurrentUserStore } from 'stores/current_user';
import confirmViaModal from 'helpers/confirm_modal';
import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';

import HelpHover from 'components/help_hover.vue';
import ServerErrors from 'components/partials/server_errors.vue';

import EditNoteModal from './modals/edit_note_modal.vue';

export default {
  name: 'InstallationNotesList',
  components: {
    HelpHover,
    ServerErrors,
  },
  props: {
    installationId: {
      type: String,
      required: true,
    },
    jobId: {
      type: String,
      default: null,
    },
  },
  emits: ['changed'],
  setup(props, { emit }) {
    const localNotes = ref([]);
    const installationNotes = ref([]);

    const load = () => {
      installationNotes.value = [];
      axios.get(`/installation_notes/?installation_id=${props.installationId}`)
        .then((response) => {
          installationNotes.value = response.data.installation_notes;
        })
        .catch(() => {
          installationNotes.value = [];
        });

      localNotes.value = [];
      axios.get(`/installation_notes/?sticky=1&installation_id=${props.installationId}`)
        .then((response) => {
          localNotes.value = response.data.installation_notes;
        })
        .catch(() => {
          localNotes.value = [];
        });
    };

    const deleteViaAPI = (note) => {
      axios.delete(`/installation_notes/${note.id}`).then(() => {
        Flash.info('Notiz gelöscht');
        if (note.sticky) {
          const index = _.findIndex(localNotes.value, { id: note.id });
          localNotes.value.splice(index, 1);
        } else {
          const index = _.findIndex(installationNotes.value, { id: note.id });
          installationNotes.value.splice(index, 1);
        }
        emit('changed');
      }).catch(() => {
        Flash.error('Notiz konnte nicht gelöscht werden');
      });
    };

    const confirmDeleteNote = (note) => {
      confirmViaModal({
        title: 'Notiz löschen?',
        message: 'Diese Aktion kann nicht rückgängig gemacht werden.',
      }).then(() => {
        deleteViaAPI(note);
      });
    };

    const openEditNoteModal = (note) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: EditNoteModal,
            attrs: {
              note,
              onClose() {
                modalInstance.close();
              },
              onUpdated() {
                load();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    return {
      load,
      localNotes,
      installationNotes,
      confirmDeleteNote,
      openEditNoteModal,
      currentUser: useCurrentUserStore().currentUser,
    };
  },
  data() {
    return {
      showSave: false,
      newNoteSticky: false,
      serverErrors: [],
    };
  },
  computed: {
    saveButtonClasses() {
      if (this.newNoteText && this.newNoteText.length) {
        return 'fx-btn-primary';
      }
      return 'fx-btn-secondary disabled';
    },
    notesInJobCount() {
      return _.filter(this.installationNotes, { job_id: this.jobId }).length;
    },
    notesInJobLabel() {
      const count = this.notesInJobCount;

      if (count === 1) {
        return '1 Notiz in diesem Auftrag';
      }
      return `${count} Notizen in diesem Auftrag`;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    resize() {
      const element = this.$refs.textarea;
      if (element) {
        element.style.height = '18px';
        element.style.height = `${element.scrollHeight}px`;
      }
    },
    cancel() {
      this.newNoteText = null;
      this.showSave = false;
      this.$nextTick(() => {
        this.resize();
      });
    },
    hideSaveIfEmpty() {
      if (!this.newNoteText || !this.newNoteText.length) {
        this.showSave = false;
      }
    },
    canEdit(note) {
      if (this.currentUser.roles.includes('admin') || this.currentUser.roles.includes('manage')) {
        return true;
      }
      return (note.user_id === this.currentUser.id);
    },
    friendlyTime(time) {
      moment.locale('de');
      return moment(time).fromNow();
    },
    formattedTimeWithMinute(time) {
      return moment(time).format('DD.MM.YYYY HH:mm');
    },
    save() {
      if (!this.newNoteText || !this.newNoteText.length) {
        return;
      }
      const that = this;
      axios.post('/installation_notes', {
        installation_note: {
          installation_id: this.installationId,
          job_id: this.jobId,
          note: this.newNoteText,
          sticky: this.newNoteSticky,
        },
      }).then((response) => {
        Flash.info('Notiz gespeichert');
        const note = response.data.installation_note;
        if (note.sticky) {
          that.localNotes.push(note);
        } else {
          that.installationNotes.unshift(note);
        }

        that.newNoteText = null;
        that.showSave = false;
        this.$emit('changed');
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.installation_notes;
        } else {
          Flash.error('Notiz konnte nicht gespeichert werden');
        }
      });
    },
  },
};
</script>
