<template>
 <td colspan="2" class="v--hover" :class="{'cursor-pointer': clickable}" @click.stop="clicked()">
    <div v-if="clickable" class="float-right v--hover-target">
      <button class="fx-context-circle" @click.stop="clicked()">
        <i :class="icon" class="fw" />
      </button>
    </div>
    <slot />
  </td>
</template>

<script>

export default {
  name: 'ClickableTd',

  props: {
    clickable: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: 'far fa-pencil',
    },
  },
  emits: ['clicked'],
  methods: {
    clicked() {
      if (this.clickable) {
        this.$emit('clicked');
      }
    },
  },
};
</script>
