<template>
  <span>
    <input v-model="localModelValue" type="text" @input="updateValue">
  </span>
</template>

<script>
export default {
  name: 'FloatingNumberInput',
  props: {
    // NOTE: this is expecting a string of float value (json stringified float value)
    modelValue: {
      type: String,
      required: true,
    },
  },
  emits: ['input', 'update:modelValue'],
  data() {
    return {
      localModelValue: null,
    };
  },
  mounted() {
    if (this.modelValue) {
      this.localModelValue = this.modelValue.replace('.', ',');
      // remove trailing zeros
      if (this.localModelValue.endsWith(',0')) {
        this.localModelValue = this.localModelValue.replace(',0', '');
      }
      this.localModelValue = this.localModelValue.replace('.', ',');
    }
  },
  methods: {
    updateValue() {
      const emitValue = this.localModelValue.replace(',', '.');

      this.$emit('input', emitValue);
      this.$emit('update:modelValue', emitValue);
    },
  },
};
</script>
