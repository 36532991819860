<template>
  <div>
    <EmptyState
      v-if="showEmptyState"
      icon="fas fa-file"
      title="Keine Dateien"
      hint="Speichern Sie beliebige Dateien, beispielsweise Pläne oder Handbücher, an der Anlage.
      Ihre Techniker haben beim Auftrag vor Ort Zugriff auf die gespeicherten Dateien."
    >
      <button class="fx-btn-primary" :disabled="!canEdit" @click="openUploadModal()">
        Datei hochladen
      </button>
    </EmptyState>

    <template v-if="!showEmptyState">
      <div style="display: flex; justify-content: space-between; margin-top: 0rem;">
        <h1 class="fx" style="flex: 1 1 0%;">
&nbsp;
        </h1>
        <div class="fx-btn-group">
          <button class="fx-btn-primary" :disabled="!canEdit" @click="openUploadModal()">
            Datei hochladen
          </button>
          <button
            v-tippy="{ placement: 'top', duration: 100, arrow: true }"
            class="fx-btn-secondary"
            title="Liste neu laden"
            @click="reset()"
          >
            <i class="fas fa-sync-alt" />
          </button>
        </div>
      </div>

      <div id="attachment-list" class="fx-card">
        <div id="attachment-header" class="fx-list-row">
          <div class="fx-400px-col">
            <strong>Name</strong>
          </div>
          <div class="fx-220px-col">
            <strong>Dateigröße</strong>
          </div>
          <div class="fx-220px-col">
            <strong>Typ</strong>
          </div>
        </div>

        <div v-for="attachment in localAttachments" :key="attachment.id" class="fx-list-row">
          <div class="fx-single-control-col">
            <div class="fx-btn-group">
              <a class="fx-btn-skeleton" :href="installationAttachmentDownloadPath(installationId, attachment.id)">download</a>

              <drop-down classes="fx-btn-skeleton" :disabled="!canEdit">
                <drop-down-item>
                  <a @click.prevent="openConfirmDeleteAttachmentModal(attachment)">
                    <i class="far fa-trash-alt fw" /> Datei löschen
                  </a>
                </drop-down-item>
              </drop-down>
            </div>
          </div>
          <div class="fx-400px-col">
            <a class="no-color" :href="installationAttachmentDownloadPath(installationId, attachment.id)">
              <strong>{{ attachment.name }}</strong>
              <div :class="filenameClasses(attachment)">{{ attachment.filename }}</div>
            </a>
          </div>
          <div class="fx-220px-col">
            {{ attachment.human_file_size }}
          </div>
          <div class="fx-220px-col">
            {{ attachment.content_type }}
          </div>
        </div>
        <div class="clearfix" />
      </div>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import FoxtagPaths from 'mixins/foxtag_paths';
import confirmViaModal from 'helpers/confirm_modal';
import { ref } from 'vue';
import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';

import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';
import EmptyState from 'components/empty_state.vue';

import UploadAttachmentModal from './attachments_upload_modal.vue';

export default {
  name: 'AttachmentsTab',
  components: {
    EmptyState,
    DropDownItem,
    DropDown,
  },
  mixins: [FoxtagPaths],
  props: {
    installationId: {
      type: String,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['changed'],
  setup(props, { emit }) {
    const localAttachments = ref([]);
    const loaded = ref(false);

    const loadAttachments = () => {
      loaded.value = false;
      localAttachments.value = [];
      axios.get(`/installations/${props.installationId}/attachments`).then((response) => {
        localAttachments.value = response.data.attachments;
        loaded.value = true;
      });
    };

    const deleteAttachment = (attachment) => {
      axios.delete(`/installations/${props.installationId}/attachments/${attachment.id}`).then(() => {
        loadAttachments();
        emit('changed');
      });
    };

    const openConfirmDeleteAttachmentModal = (attachment) => {
      confirmViaModal({
        title: 'Datei löschen?',
        action: 'Löschen',
      }).then(() => {
        deleteAttachment(attachment);
      });
    };

    const openUploadModal = () => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: UploadAttachmentModal,
            attrs: {
              installationId: props.installationId,
              onClose() {
                modalInstance.close();
              },
              onUploaded() {
                modalInstance.close();
                loadAttachments();
                emit('changed');
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    return {
      localAttachments,
      loaded,
      loadAttachments,
      openConfirmDeleteAttachmentModal,
      openUploadModal,
    };
  },
  data() {
    return {
      jobListKey: 0,
    };
  },
  computed: {
    showEmptyState() {
      return this.loaded && this.localAttachments.length === 0;
    },
  },
  mounted() {
    this.loadAttachments();
  },
  methods: {
    filenameClasses(attachment) {
      if (attachment.name && attachment.name.length > 0) {
        return 'secondary';
      }
      return '';
    },
    reset() {
      this.loadAttachments();
    },
    onUploaded() {
      this.loadAttachments();
      this.$emit('changed');
    },
  },
};
</script>
