<template>
  <div class="fx-list-row">
    <div class="fx-single-control-col">
      <div class="fx-btn-group">
        <a class="fx-btn-skeleton" @click.prevent="$emit('open-edit-thing-type', thingType)">{{ $t('edit') }}</a>
        <drop-down>
          <template v-if="thingType.things_count !== 0">
            <drop-down-item>
              <a @click.prevent="$emit('open-merge-thing-type', thingType)"><i class="far fa-code-merge fa-fw" /> Zusammenführen</a>
            </drop-down-item>
          </template>
          <template v-else>
            <drop-down-item><span class="disabled"><i class="far fa-code-merge fa-fw" /> Zusammenführen</span></drop-down-item>
          </template>
          <drop-down-item><hr></drop-down-item>
          <template v-if="thingType.things_count === 0">
            <drop-down-item><a @click.prevent="$emit('open-confirm-delete', thingType)"><i class="far fa-trash-alt fa-fw" /> Löschen</a></drop-down-item>
          </template>
          <template v-else>
            <drop-down-item><span class="disabled"><i class="far fa-trash-alt fa-fw" /> Löschen</span></drop-down-item>
          </template>
        </drop-down>
      </div>
    </div>

    <div class="fx-400px-col">
      <template v-if="hasManufacturerOrModell">
        <a class="no-color" @click="$emit('open-edit-thing-type', thingType)">
          {{ thingType.manufacturer }} {{ thingType.model }}</a>&shy;
          <span class="fx-label" style="color: #666; background-color: #eee;">
            {{ $t(`activerecord.attributes.thing_type.${thingType.category}.one`) }}
          </span>&shy;
          <ExternalLinkButton :href="thingType.link" />
        <div class="secondary">
          {{ thingType.name }}
        </div>
      </template>
      <template v-else>
        <a class="no-color" @click="$emit('open-edit-thing-type', thingType)">{{ thingType.name }}</a>&shy;
        <span class="fx-label" style="color: #666; background-color: #eee;">
          {{ $t(`activerecord.attributes.thing_type.${thingType.category}.one`) }}
        </span>&shy;
        <ExternalLinkButton :href="thingType.link" />
      </template>
    </div>

    <div class="fx-180px-col">
      <div v-if="thingType.check_interval_label">
        <i class="fas fa-circle color-lighter-gray" />
        <span style="margin-left: 0.3rem;">{{ thingType.check_interval_label }}</span>
      </div>
      <div v-if="thingType.replacement_interval_label">
        <i class="fas fa-sync-alt color-secondary" />
        <span style="margin-left: 0.3rem;">{{ thingType.replacement_interval_label }}</span>
      </div>
    </div>
    <div class="fx-300px-col">
      <div v-for="(name, index) in thingType.checklist_names" :key="index">
        <i class="fas fa-list color-secondary" />
        <span style="margin-left: 0.3rem;">{{ name }}</span>
      </div>
    </div>

    <div class="fx-50px-col" :class="thingsCountClass">
      <i class="far fa-cube" />
      <span style="margin-left: 0.3rem;">{{ thingType.things_count }}</span>
    </div>

    <div class="clearfix" />
  </div>
</template>

<script>
import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';
import ExternalLinkButton from 'components/external_link_button.vue';

export default {
  name: 'ThingTypeItem',
  components: {
    DropDown,
    DropDownItem,
    ExternalLinkButton,
  },
  props: {
    thingType: {
      type: Object,
      required: true,
    },
  },
  emits: ['open-edit-thing-type', 'open-merge-thing-type', 'open-confirm-delete'],
  computed: {
    thingsCountClass() {
      if (this.thingType.things_count === 0) {
        return 'secondary';
      }
      return '';
    },
    hasManufacturerOrModell() {
      return (this.thingType.manufacturer != null || this.thingType.model != null);
    },
  },
};
</script>
