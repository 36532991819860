<template>
  <div v-if="loaded">
    <section class="fx-page-content">
      <section class="fx-header">
        <div class="fx-grid">
          <div class="fx-col" style="padding: 0 1rem;">
            <ul class="fx-breadcrumps">
              <li><a href="/start">Start</a></li>
              <li><a href="/customers">Kunden</a></li>
              <li>{{ localCustomer.number }}</li>
            </ul>

            <div class="fx-header-title">
              <h1 class="fx-header-title">
                <span class="fx-header-title_number">{{ localCustomer.number }}</span>
                <span class="fx-header-title_name">{{ localCustomer.name }}</span>
              </h1>
            </div>

            <button :class="$tabClasses('overview')" @click="$setActiveTab('overview');">
              <i class="far fa-briefcase" /> Kundendaten
            </button>
            <button :class="$tabClasses('contacts')" @click="$setActiveTab('contacts');">
              <i class="fas fa-address-card" /> Kontakte
            </button>
            <button :class="$tabClasses('locations')" @click="$setActiveTab('locations');">
              <i class="fas fa-sitemap" /> Anlagen <span class="fx-tab-count">{{ localCustomer.installations_count
              }}</span>
            </button>
          </div>
        </div>
      </section>

      <section v-if="!localCustomer.deleted_at" class="list-content">
        <div v-if="$isTabInitialized('overview')" v-show="$isTabActive('overview')" class="tab-panel">
          <div class="fx-grid">
            <div class="fx-col" style="margin-bottom: 0; padding-bottom: 0">
              <div>
                <LabelsAssign
                  label-type="customer"
                  :preset-labels="localCustomer.labels"
                  :lablable-id="localCustomer.id"
                  :can-edit="canEdit"
                  :link="localCustomer.link"
                />
              </div>
            </div>
          </div>
          <div class="fx-grid">
            <div class="fx-col-notop">
              <div class="fx-card" style="max-width: 650px;">
                <div class="fx-card-content">
                  <h2 class="fx">
                    Kundendaten
                  </h2>
                  <div v-if="localCustomer.address">
                    <ServerErrors
                      v-model="serverErrors"
                      title="Kunde konnte nicht gespeichert werden"
                      margin-bottom="1rem"
                    />
                    <CustomerForm v-model="localCustomer" :can-edit="canEdit" @submit="updateCustomerDetails" />
                  </div>
                </div>
              </div>
            </div>
            <div class="fx-col-notop">
              <div class="fx-card" style="max-width: 650px;">
                <div class="fx-card-content">
                  <h2 class="fx">
                    Interne Notizen
                  </h2>
                  <ServerErrors
                    v-model="serverErrorsNote"
                    title="Notiz konnte nicht gespeichert werden"
                    margin-bottom="1rem"
                  />
                  <textarea
                    id="customer_note"
                    ref="textarea"
                    v-model="localCustomer.note"
                    style="resize: none;  overflow: hidden; margin-bottom:0; min-height: 100px;"
                    placeholder=""
                    class="fx-focus"
                    @input="resize()"
                  />
                  <div class="form-field-hint">
                    Wird nicht auf das Smartphone synchronisiert
                  </div>
                  <TransactionButton
                    v-model="savingNote"
                    class="fx-btn-primary"
                    title="Notiz speichern"
                    :disabled="!canEdit"
                    @click="updateCustomerNote"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="$isTabInitialized('contacts')" v-show="$isTabActive('contacts')" class="tab-panel">
          <div class="fx-grid">
            <div class="fx-col">
              <EmbeddedContactList
                :key="customerContactsListKey"
                :add-customer-id="localCustomer.id"
                :remove-customer-id="localCustomer.id"
                :contacts-url="`/customers/${localCustomer.id}/contacts.json`"
                empty-state-title="Keine Kontakte am Kunden"
                empty-state-hint="Fügen Sie dem Kunden einen ersten Kontakt hinzu."
                @refresh-sibling="refreshInstallationContacts"
              />

              <EmbeddedContactList
                :key="installationContactsListKey"
                title="Weitere Ansprechpartner an Anlagen des Kunden"
                :show-top-add-button="false"
                :show-bottom-add-button="false"
                :show-empty-state="false"
                :add-customer-id="localCustomer.id"
                :contacts-url="`/customers/${localCustomer.id}/installation_contacts.json`"
                @refresh-sibling="refreshCustomerContacts"
              />
            </div>
          </div>
        </div>

        <div v-if="$isTabInitialized('locations')" v-show="$isTabActive('locations')" class="tab-panel">
          <div class="fx-grid">
            <div class="fx-col">
              <LocationsList
                v-model="filter"
                no-results-title="Keine Objekte"
                no-results-hint="Keine Objekte für diesen Kunden gefunden"
                :show-add-location="true"
                :can-edit="canEdit"
                :has-due-replacement-export="false"
                :filter-info="filterInfo"
                @location-added="localCustomer.locations_count += 1"
                @location-deleted="localCustomer.locations_count -= 1"
                @reset-search="resetFilter"
                @filter-passive="filterPassive"
              />
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';
import { useCurrentUserStore } from 'stores/current_user';
import { ref } from 'vue';

import LocationsList from 'apps/locations/index/locations_list.vue';

import ServerErrors from 'components/partials/server_errors.vue';
import TransactionButton from 'components/transaction_button.vue';

import LabelsAssign from 'apps/labels/assign/index.vue';
import EmbeddedContactList from 'apps/contacts/embedded_list.vue';

import CustomerForm from '../form.vue';

export default {
  name: 'ShowCustomer',
  components: {
    TransactionButton,
    LocationsList,
    ServerErrors,
    CustomerForm,
    LabelsAssign,
    EmbeddedContactList,
  },
  props: {
    customerId: {
      type: String,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const filter = ref({
      customerId: props.customerId,
      passive: null,
    });
    return {
      filter,
      currentUser: useCurrentUserStore().currentUser,
    };
  },
  data() {
    return {
      loaded: false,
      localCustomer: null,
      activeTab: null,
      initializedTabs: new Set(),
      serverErrors: [],
      serverErrorsNote: [],
      savingNote: false,
      savingCustomer: false,
      customerContactsListKey: 1,
      installationContactsListKey: 10000,
    };
  },
  computed: {
    filterInfo() {
      if (this.filter.passive) {
        return 'Gefiltert nach: Passiv';
      }
      return null;
    },
  },
  mounted() {
    this.loadCustomer();
    this.$setActiveTabFromURL('overview');
  },
  methods: {
    resize() {
      const element = this.$refs.textarea;
      if (element) {
        element.style.height = '18px';
        element.style.height = `${element.scrollHeight}px`;
      }
    },
    refreshCustomerContacts() {
      this.customerContactsListKey += 1;
    },
    refreshInstallationContacts() {
      this.installationContactsListKey += 1;
    },
    updateCustomerNote() {
      const that = this;
      this.savingNote = true;
      this.serverErrorsNote = [];
      axios.put(`/customers/${this.localCustomer.id}.json`, {
        customer: {
          note: this.localCustomer.note,
        },
      }).then((response) => {
        that.localCustomer = response.data.customer;
        new Flash('Notiz gespeichert').show();
        that.savingNote = false;
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrorsNote = error.response.data.customers;
        } else {
          new Flash('Fehler beim Speichern', { isError: true }).show();
        }
        that.savingNote = false;
      });
    },
    updateCustomerDetails() {
      const that = this;
      this.savingCustomer = true;
      this.serverErrors = [];
      const customCoordinates = this.localCustomer.address.custom_coordinates;
      axios.put(`/customers/${this.localCustomer.id}.json`, {
        customer: {
          number: this.localCustomer.number,
          name: this.localCustomer.name,
          link: this.localCustomer.link,
          address_attributes: {
            line1: this.localCustomer.address.line1,
            line2: this.localCustomer.address.line2,
            zip: this.localCustomer.address.zip,
            city: this.localCustomer.address.city,
            country: this.localCustomer.address.country,
            custom_coordinates: customCoordinates,
            lat: (customCoordinates ? this.localCustomer.address.lat : null),
            lng: (customCoordinates ? this.localCustomer.address.lng : null),
          },
        },
      }).then((response) => {
        that.localCustomer = response.data.customer;
        new Flash('Kunde gespeichert').show();
        that.savingCustomer = false;
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.customers;
        } else {
          new Flash('Fehler beim Speichern', { isError: true }).show();
        }
        that.savingCustomer = false;
      });
    },
    loadCustomer() {
      const that = this;
      axios.get(`/customers/${this.customerId}.json`)
        .then((response) => {
          that.localCustomer = response.data.customer;
          that.loaded = true;
          this.$nextTick(() => {
            that.resize();
          });
        }).catch(() => {
          Flash.error('Kunde konnte nicht geladen werden');
        });
    },
    resetFilter() {
      this.filter.passive = null;
    },
    filterPassive() {
      this.filter.passive = true;
    },
  },
};
</script>
