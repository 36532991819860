<template>
  <div>
    <div class="repco_preview">
      <template v-if="showForm">
        <div style="background-color: #eee; min-height: 16rem; border-radius: 8px; padding: 1rem;">
          <h3 class="fx">
            {{ name }}
          </h3>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline mandatory" for="customer_name">Überschrift</label>
            </div>
            <div class="fx-form-content">
              <input
                id="customer_name"
                v-model="localSection.title"
                name="customer_name"
                type="text"
              >
            </div>
          </div>

          <div v-if="expReport" class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline" for="customer_name">Fotos</label>
            </div>
            <div class="fx-form-content">
              <select v-model="localSection.cols_count">
                <option :value="2">
                  2 Fotos pro Zeile
                </option>
                <option :value="3">
                  3 Fotos pro Zeile (Standard)
                </option>
              </select>
              <div class="form-field-hint">
                Es werden maximal 50 Fotos abgedruckt.
              </div>
            </div>
          </div>

          <button class="fx-btn-primary" @click="showForm=false">
            Zurück zur Vorschau
          </button>
        </div>
      </template>

      <template v-if="!showForm">
        <div :class="previewEngineClass">
          <div v-if="!expReport" class="divider mb-4">
            Neue Seite (Fotos)
          </div>

          <div class="repco-h2" :style="headlineStyle">
            {{ localSection.title }}
          </div>
          <table v-if="!expReport" class="photos">
            <tr>
              <td :style="tdStyleImage">
                <img src="/demo_job.jpg" width="100%">
              </td>
              <td style="width: 20px;">
&nbsp;
              </td>
              <td :style="tdStyleImage">
                <img src="/demo_job.jpg" width="100%">
              </td>
            </tr>
            <tr>
              <td>Optionale Beschreibung am Foto</td>
              <td />
              <td />
            </tr>
          </table>

          <table v-if="expReport" class="photos">
            <tr>
              <td>
                <img src="/demo_job.jpg" width="100%">
                <strong>03.01.2023</strong> Bildunterschrift
              </td>
              <td>
                <img src="/demo_job.jpg" width="100%">
                <strong>02.01.2023</strong> Bildunterschrift
              </td>
              <td v-if="localSection.cols_count > 2">
                <img src="/demo_job.jpg" width="100%">
                <strong>01.01.2023</strong> Bildunterschrift
              </td>
            </tr>
          </table>
        </div>
      </template>
    </div>

    <Controls
      :index="index"
      :list-length="listLength"
      :name="name"
      :readonly="readonly"
      @settings="showForm = !showForm"
      @add="$emit('add')"
      @delete="$emit('delete')"
      @move-up="$emit('moveUp')"
      @move-down="$emit('moveDown')"
    />
  </div>
</template>

<script>
import Controls from './section_controls.vue';

export default {
  name: 'SectionArticles',
  components: {
    Controls,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    colors: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      default: 'Fotos',
    },
    index: {
      type: Number,
      required: true,
    },
    listLength: {
      type: Number,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    layoutEngine: {
      type: String,
      default: 'foxtag_report',
    },
  },
  data() {
    return {
      showForm: false,
      localSection: {},
    };
  },
  computed: {
    previewEngineClass() {
      return `preview-${this.layoutEngine}`;
    },
    expReport() {
      return (this.layoutEngine == 'exp_report');
    },
    tdStyleImage() {
      if (this.layoutEngine == 'exp_report') {
        return 'background-color: transparent; height: auto;';
      }

      return `background-color: #${this.colors.color_bg_groups}; height: 500px;`;
    },
    headlineStyle() {
      return `color: #${this.colors.color_headlines};`;
    },
  },
  mounted() {
    this.localSection = this.section;

    if (this.localSection.cols_count == undefined) {
      this.localSection.cols_count = 2;
    }
  },
};
</script>
