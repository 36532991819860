<template>
  <section class="list-content">
    <div class="fx-grid">
      <div class="fx-col">
        <div id="intervals-index">
          <h1 class="fx">
            Prüfintervalle
          </h1>
          <p>
            Mit den Prüfintervallen steuern Sie die Prüffälligkeiten Ihrer Typen.
            <HelpArticle article-id="learn_more_check_intervals">
              mehr erfahren
            </HelpArticle>
          </p>
          <div class="fx-card">
            <table class="material">
              <colgroup>
                <col style="width: 200px;">
                <col style="width: 260px;">
                <col>
                <col style="width: 180px;">
              </colgroup>

              <tr class="material__controls">
                <th colspan="4" style="text-align: right;">
                  <a :href="`/installation_types/${installationTypeId}/intervals/new?interval_type=check`" class="fx-btn-ico-primary">
                    <i class="fas fa-plus" />
                    Prüfinterval hinzufügen
                  </a>
                </th>
              </tr>

              <tr>
                <th>
                  Prüfintervall
                </th>
                <th>Status "bald überfällig"</th>
                <th>{{ $t('words.applies_to') }}</th>
                <th class="controls_secondary">
                  &nbsp;
                </th>
              </tr>

              <tr v-for="interval in localIntervalsCheck" :key="interval.id">
                <td>
                  <i class="fas fa-circle color-secondary" />&shy;
                  <a class="no-color" :href="editLink(interval)">
                    <span v-if="interval.quantity === 1">
                      {{ $t(`activerecord.attributes.thing.interval_frequencies_pluralized.${interval.frequency}.one`) }}
                    </span>
                    <span v-else>
                      {{ $tc(`activerecord.attributes.thing.interval_frequencies_pluralized.${interval.frequency}.other`, interval.quantity) }}
                    </span>

                    <span v-if="features.calendric_intervals" class="secondary">
                      &shy;{{ $t(`activerecord.attributes.interval.calculation_type_${interval.calculation_type}`) }}
                    </span>
                  </a>
                  <div v-if="interval.ok_series_enabled" style="padding-left: 1rem; margin-top: 4px;">
                    <span class="fx-label" style="background-color: #bbb;">
                      <i class="fas fa-forward" />
                      {{ interval.ok_series_factor }}-fach ab {{ interval.ok_series_threshold }} x OK
                    </span>
                  </div>
                </td>
                <td>
                  <i class="fas fa-circle color-near" />
                  {{ interval.warn_distance_days }} Tage vor überfällig
                </td>
                <td>
                  <span v-if="interval.all_types">Alle {{ categoryLabels[interval.category] }}</span>
                  <TruncatedTextList :limit="3" :list="interval.selected_types.map((f) => `${f.manufacturer} ${f.type}`)" />
                </td>
                <td style="text-align: right;">
                  <div class="fx-btn-group">
                      <a class="fx-btn-skeleton" :href="editLink(interval)">{{ $t('edit') }}</a>
                      <ButtonDeleteIcon @click="confirmDeleteChecklist(interval)" />
                    </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div style="margin-top: 2rem;">
          &nbsp;
        </div>
        <div>
        <h1 class="fx">
          Austauschintervalle
        </h1>
          <p>
            Mit den Austauschintervallen steuern Sie, wann Ihre Typen getauscht werden müssen.
            <HelpArticle article-id="learn_more_replacement_intervals">
              mehr erfahren
            </HelpArticle>
          </p>

          <div class="fx-card">
            <table class="material">
              <colgroup>
                <col>
                <col>
                <col style="width: 180px;">
              </colgroup>

              <tr class="material__controls">
                <th colspan="3" style="text-align: right;">
                  <a :href="`/installation_types/${installationTypeId}/intervals/new?interval_type=replacement`" class="fx-btn-ico-primary">
                    <i class="fas fa-plus" />
                    Austauschinterval hinzufügen
                  </a>
                </th>
              </tr>

              <tr>
                <th>Name</th>
                <th>Gilt für</th>
                <th class="controls_secondary">
                  &nbsp;
                </th>
              </tr>

              <tr v-for="interval in localIntervalsReplacement" :key="interval.id">
                <td>
                    <i class="fas fa-sync-alt fa-fw" style="color: #888" />
                    &shy;
                    <a class="no-color" :href="editLink(interval)">
                      <span v-if="interval.quantity === 1">
                        {{ $t(`activerecord.attributes.thing.interval_frequencies_pluralized.${interval.frequency}.one`) }}
                      </span>
                      <span v-else>
                        {{ $tc(`activerecord.attributes.thing.interval_frequencies_pluralized.${interval.frequency}.other`, interval.quantity) }}
                      </span>
                      <span class="secondary">
                        ab {{ $t(`activerecord.attributes.interval.replacement_type_${interval.replacement_type}`) }}
                      </span>
                    </a>
                  </td>
                <td>
                  <span v-if="interval.all_types">Alle {{ categoryLabels[interval.category] }}</span>
                  <TruncatedTextList :limit="3" :list="interval.selected_types.map((f) => `${f.manufacturer} ${f.type}`)" />
                </td>
                <td style="text-align: right;">
                  <div class="fx-btn-group">
                      <a class="fx-btn-skeleton" :href="editLink(interval)">{{ $t('edit') }}</a>
                      <ButtonDeleteIcon @click="confirmDeleteChecklist(interval)" />
                    </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';
import EntityList from 'helpers/entity_list';
import confirmViaModal from 'helpers/confirm_modal';
import FeatureFlipper from 'mixins/feature_flipper';
import ButtonDeleteIcon from 'components/button_delete_icon.vue';
import TruncatedTextList from 'components/truncated_text_list.vue';
import { useCurrentUserStore } from 'stores/current_user';

export default {
  name: 'IntervalsList',
  components: {
    ButtonDeleteIcon,
    TruncatedTextList,
  },
  mixins: [FeatureFlipper],
  props: {
    installationTypeId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const localIntervalsCheck = new EntityList();
    const localIntervalsReplacement = new EntityList();

    const loadIntervalsCheck = () => {
      axios.get(`/installation_types/${props.installationTypeId}/intervals.json`, {
        params: {
          interval_type: 'check',
          page: 1,
          limit: 1000,
        },
      }).then((response) => {
        localIntervalsCheck.value = response.data.intervals;
      }).catch((err) => {
        if (err.message !== 'Request aborted') {
          Flash.error(err.message);
        }
      });
    };

    const loadIntervalsReplacement = () => {
      axios.get(`/installation_types/${props.installationTypeId}/intervals.json`, {
        params: {
          interval_type: 'replacement',
          page: 1,
          limit: 1000,
        },
      }).then((response) => {
        localIntervalsReplacement.value = response.data.intervals;
      }).catch((err) => {
        if (err.message !== 'Request aborted') {
          Flash.error(err.message);
        }
      });
    };

    const deleteViaApi = (interval) => {
      axios.delete(`/installation_types/${props.installationTypeId}/intervals/${interval.id}.json`).then(() => {
        loadIntervalsCheck();
        loadIntervalsReplacement();
        Flash.info('Intervall gelöscht');
      });
    };

    // --- modals

    const confirmDeleteChecklist = (interval) => {
      confirmViaModal({
        title: 'Intervall löschen?',
        message: 'Diese Aktion kann nicht rückgängig gemacht werden.',
      }).then(() => {
        deleteViaApi(interval);
      });
    };

    const editLink = (interval) => `/installation_types/${props.installationTypeId}/intervals/${interval.id}/edit`;

    return {
      currentUser: useCurrentUserStore().currentUser,
      confirmDeleteChecklist,
      loadIntervalsCheck,
      loadIntervalsReplacement,
      editLink,
      localIntervalsCheck: localIntervalsCheck.reactiveList,
      localIntervalsReplacement: localIntervalsReplacement.reactiveList,
      thingType: {},
      categoryLabels: ['Komponenten', 'Sub-Komponenten', 'Zentralen'],
    };
  },
  computed: {
    showNoResults() {
      return (this.localIntervalsCheck.length === 0);
    },
  },
  mounted() {
    this.loadIntervalsCheck();
    this.loadIntervalsReplacement();
  },
};
</script>
