<template>
  <div class="fx-filter">
    <OnClickOutside @trigger="cancel">
      <span v-if="showLink">
        <span :class="classes" class="link" style="background-color: #E9EEFA;"><a :href="href_url" :title="href_url" target="_blank"><i
          class="fas fa-external-link"
          style="margin-right: .3rem"
        />{{ labelText }}</a><span style="margin-left: 15px; cursor: pointer;" @click="open()"><i class="far fa-pencil" /></span></span>
      </span>
      <span v-if="!showLink" class="fx-label-btn" style="cursor: pointer;" @click="toggleOpen()"><i class="fas fa-plus fa-xs" /> <Icon id="link" /></span>

      <div class="fx-filter-dropdown" :class="{ 'show': isOpen }" style="padding: 10px; margin-top: 5px; min-width: 600px;">
        <ServerErrors v-model="serverErrors" title="Link konnte nicht gespeichert werden" margin-bottom="1rem" />
        <input v-model="newHref" type="text" placeholder="https://..." style="width: 100%; padding: 5px 15px; margin-bottom: 0;">
        <div class="form-field-hint">
          Link auf eine externe Webseite, Datei oder einen Speicherort.
          <help-article article-id="learn_more_links">
            Mehr erfahren
          </help-article>
        </div>
        <div class="text-right">
          <div class="fx-btn-group">
            <button class="fx-btn-primary" @click="updateLink">
              Speichern
            </button>
            <button class="fx-btn-secondary" @click="cancel()">
              Abbrechen
            </button>
          </div>
        </div>
      </div>
    </OnClickOutside>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';
import Icon from 'components/icon.vue';

import { ref } from 'vue';
import { TruncateFormatter } from 'mixins/formatters';
import ServerErrors from 'components/partials/server_errors.vue';
import { OnClickOutside } from '@vueuse/components';

export default {
  name: 'AssignLink',
  components: {
    Icon,
    OnClickOutside,
    ServerErrors,
  },
  mixins: [TruncateFormatter],
  props: {
    canEdit: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: '',
    },
    defaultLabelText: {
      type: String,
      default: 'Link',
    },
    classes: {
      type: String,
      default: 'fx-label-btn',
    },
    linkableType: {
      type: String,
      default: 'job',
    },
    linkableId: {
      type: String,
      default: null,
    },
  },
  emits: ['changed'],
  setup(props) {
    const serverErrors = ref([]);
    const isOpen = ref(false);
    const localHref = ref(props.href);
    const newHref = ref(props.href);

    const open = () => {
      isOpen.value = true;

      // preset with https:// to make it easier to enter a new link (reset on cancel!)
      if (newHref.value == null || newHref.value.length === 0) {
        newHref.value = 'https://';
      }
    };

    const close = () => {
      isOpen.value = false;
    };

    const toggleOpen = () => {
      if (isOpen.value) {
        close();
      } else {
        open();
      }
    };

    const cancel = () => {
      isOpen.value = false;
      newHref.value = localHref.value; // reset to latest saved value!
    };

    const updateJob = (newLink) => {
      serverErrors.value = [];
      axios.put(`/jobs/${props.linkableId}`, {
        job: {
          link: newLink,
        },
      }).then(() => {
        close();
        localHref.value = newLink;
      }).catch((error) => {
        if (error.response.status === 400) {
          serverErrors.value = error.response.data.jobs;
        } else {
          Flash.error('Auftrag konnte nicht gespeichert werden');
        }
      });
    };

    const updateIncident = (newLink) => {
      serverErrors.value = [];
      axios.put(`/incidents/${props.linkableId}`, {
        link: newLink,
      }).then(() => {
        close();
        localHref.value = newLink;
      }).catch((error) => {
        if (error.response.status === 400) {
          serverErrors.value = error.response.data.details.link;
        } else {
          Flash.error('Auftrag konnte nicht gespeichert werden');
        }
      });
    };

    const updateInstallation = (newLink) => {
      serverErrors.value = [];
      axios.put(`/installations/${props.linkableId}`, {
        link: newLink,
      }).then(() => {
        close();
        localHref.value = newLink;
      }).catch((error) => {
        if (error.response.status === 400) {
          serverErrors.value = error.response.data.installations;
        } else {
          Flash.error('Auftrag konnte nicht gespeichert werden');
        }
      });
    };

    const updateLink = () => {
      if (props.linkableType === 'job') {
        updateJob(newHref.value);
      }
      if (props.linkableType === 'incident') {
        updateIncident(newHref.value);
      }
      if (props.linkableType === 'installation') {
        updateInstallation(newHref.value);
      }
    };

    return {
      serverErrors,
      updateLink,
      isOpen,
      localHref,
      newHref,
      cancel,
      open,
      close,
      toggleOpen,
    };
  },
  computed: {
    showLink() {
      return this.localHref != null && this.localHref.length;
    },
    href_url() {
      if (this.localHref == null) { return ''; }
      return this.localHref.split('|')[0];
    },
    labelText() {
      if (this.localHref == null) { return this.defaultLabelText; }

      const customLabel = this.localHref.split('|')[1];
      if ((customLabel != null) && (customLabel.length)) {
        return customLabel.substring(0, 15);
      }
      try {
        return this.domainFromURL(this.localHref);
      } catch (error) {
        return this.defaultLabelText;
      }
    },
  },
  methods: {
    domainFromURL(url) {
      let domain = (new URL(url));
      domain = domain.hostname.replace('www.', '');

      return domain;
    },
  },
};
</script>
