<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <h2 class="fx">
      Komponente in Betrieb nehmen
    </h2>
    <p>Fügt eine Komponente aus dem Lager zu einer Anlage hinzu.</p>
    <hr>

    <div>
      <ServerErrorsCommand v-model="commandServerErrors" title="Änderungen konnten nicht gespeichert werden" />
      <form @submit.prevent="submit">
        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline mandatory" for="thing_number">Komponente</label>
          </div>
          <div class="fx-form-content">
            <DBBasedSelect
              v-model="thingId"
              icon-id="thing"
              :default-label="$t('comp.select.no-selection')"
              i18n-base-key="things"
              :load-options-url="`/select/things_from_stock?installation_type_id=${installationTypeId}`"
              @changed="(value) => { thingId = value; }"
            />
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline mandatory" for="thing_number">Inbetriebnahme-Datum</label>
          </div>
          <div class="fx-form-content">
            <VueDatePicker
              v-model="date"
              :locale="$i18n.locale"
              :auto-apply="true"
              model-type="yyyy-MM-dd"
              :enable-time-picker="false"
              :format="'dd.MM.yyyy'"
              :disabled-dates="isFutureDate"
            />
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline" for="thing_number">Auftrag</label>
          </div>
          <div class="fx-form-content">
            <DBBasedSelect
              v-model="jobId"
              icon-id="job"
              :default-label="$t('comp.select.no-selection')"
              i18n-base-key="jobs"
              :load-options-url="`/select/jobs?installation_id=${installationId}&only_open_jobs=true`"
              @changed="(value) => { jobId = value; }"
            />
            <span class="small secondary">Optional. Wählen Sie einen offenen Auftrag für diese Anlage.</span>
          </div>
        </div>
        <hr>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline mandatory" for="thing_group">Gruppe</label>
          </div>
          <div class="fx-form-content">
            <DBBasedSelect
              v-model="groupId"
              i18n-base-key="groups"
              :default-label="$t('comp.select.no-selection')"
              :load-options-url="`/groups/for_select/?installation_id=${installationId}`"
              :readonly="true"
            />
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline mandatory" for="thing_number">Nummer</label>
          </div>

          <div v-if="isSubthing" class="fx-form-content">
            <div style="display: inline-block; width: 49%; vertical-align: top;">
              <input
                id="thing_number"
                v-model="localNumber"
                name="number"
                type="text"
                readonly="true"
                disabled="1"
              >
              <FieldError v-model="commandServerErrors" field="deployments.number" />
            </div>
            <div style="display: inline-block; width: 49%; vertical-align: top;">
              <input
                id="thing_sub_number"
                v-model="localSubNumber"
                name="sub_number"
                type="text"
              >
              <FieldError v-model="commandServerErrors" field="deployments.sub_number" />
            </div>
            <div class="clearfix" />
          </div>

          <div v-else class="fx-form-content">
            <input
              id="thing_number"
              v-model="localNumber"
              name="number"
              type="text"
            >
            <FieldError v-model="commandServerErrors" field="deployments.number" />
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline" for="thing_position">Standort</label>
          </div>
          <div class="fx-form-content">
            <input
              id="thing_position"
              v-model="position"
              name="position"
              type="text"
            >
            <FieldError v-model="commandServerErrors" field="deployments.position" />
          </div>
        </div>

        <hr>
        <button
          type="submit"
          class="fx-btn-primary float-right"
          :disabled="submitDisabled"
          style="margin-bottom: 0.5rem; margin-left: 0.5rem;"
        >
          Speichern
        </button>
        <button class="fx-btn-secondary float-right" @click.prevent="$emit('close')">
          Abbrechen
        </button>
        <div class="clearfix" />
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import DBBasedSelect from 'components/select/db_based_select.vue';

import ServerErrorsCommand from 'components/partials/server_errors_command.vue';
import FieldError from 'components/partials/field_error.vue';

export default {
  name: 'DeployThingModal',
  components: {
    VueDatePicker,
    ServerErrorsCommand,
    DBBasedSelect,
    FieldError,
  },
  props: {
    installationTypeId: {
      type: String,
      required: true,
    },
    installationId: {
      type: String,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
    number: {
      type: Number,
      required: true,
    },
    subNumber: {
      type: Number,
      default: 0,
    },
  },
  emits: ['deployed', 'close'],
  data() {
    return {
      thingId: null,
      jobId: null,
      commandServerErrors: {},
      date: new Date().toISOString().split('T')[0],
      groupId: null,
      localNumber: 0,
      localSubNumber: 0,
      isSubthing: false,
      position: null,
      closeModal: null,
    };
  },
  computed: {
    submitDisabled() {
      return (this.thingId == null);
    },
  },
  mounted() {
    this.groupId = this.group.id;
    this.localNumber = this.number;
    this.localSubNumber = (this.subNumber || 0);
    this.isSubthing = (this.subNumber > 0);

    this.loadNextFreeNumber();
  },
  methods: {
    loadNextFreeNumber() {
      const that = this;
      axios.get(`/groups/${this.groupId}/next_thing_number.json`)
        .then((response) => {
          that.localNumber = response.data;
        }).catch(() => {
          that.localNumber = 1;
        });
    },
    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },
    submit() {
      this.deployViaAPI();
    },
    deployViaAPI() {
      const that = this;
      that.commandServerErrors = {};

      axios.put(
        `/things/${this.thingId}/deploy.json`,
        {
          deployed_at: this.date,
          deployed_job_id: this.jobId,
          number: this.localNumber,
          sub_number: this.subNumber,
          position: this.position,
          group_id: this.groupId,
          installation_id: this.installationId,
        },
      ).then((response) => {
        this.$emit('deployed', response.data.thing);
        Flash.info('Komponente wurde in Betrieb genommen');
      }).catch((error) => {
        if (error.response && error.response.status === 400) {
          that.commandServerErrors = error.response.data;
        } else {
          Flash.error('Unerwarteter Fehler');
        }
      });
    },
  },
};
</script>
