<template>
  <div style="margin-top: .8rem; display: grid; justify-content: stretch; grid-template-columns: 1fr 3fr 1fr;">
    <div sstyle="float: left;font-size: .9rem;padding-left: 1rem;" style="justify-self: start; align-self: start; font-size: .9rem;">
      <span id="total-record-count">{{ pagination.totalCount }}</span> {{ $t(i18nFullKey) }}
      <span v-if="pagination.pageCount > 1">{{ $tc("comp.pagination.on-pages", pagination.pageCount) }}</span>
    </div>

    <ul v-if="pagination.pageCount > 1" class="pagination" style="justify-self: center;">
      <li id="prev-page" :class="{ disabled: pagination.page === 1 }">
        <a @click="prev()">{{ $t('comp.pagination.previous') }}</a>
      </li>
      <li v-if="showFirstPage">
        <a @click="goto(1)">1</a>
      </li>
      <li v-if="showRangeStartDots">
        <a @click="goto(pageRangeStart - 1)">...</a>
      </li>

      <!-- page range -->
      <li v-for="pageIndex in pagination.pageCount" :key="pageIndex" :class="{ active: pageIndex === pagination.page }">
        <a v-if="show(pageIndex)" @click="goto(pageIndex)">{{ pageIndex }}</a>
      </li>
      <!-- page range -->

      <li v-if="showRangeEndDots">
        <a @click="goto(pageRangeEnd + 1)">...</a>
      </li>
      <li v-if="showLastPage">
        <a @click="goto(pagination.pageCount)">{{ pagination.pageCount }}</a>
      </li>
      <li id="next-page" :class="{ disabled: pagination.page === pagination.pageCount }">
        <a @click="next()">{{ $t('comp.pagination.next') }}</a>
      </li>
    </ul>
    <span v-else>&shy;</span>

    <div id="pagination-limit-wrapper" style="justify-self: end;">
      <drop-down :text="`${pagination.limit} pro Seite`" classes="pagination-limit">
        <drop-down-item v-for="limit in limitOptions" :key="limit">
          <a @click.prevent="changedPageLimit(limit)">{{ limit }} pro Seite</a>
        </drop-down-item>
      </drop-down>
    </div>
  </div>
</template>

<script>
import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';

export default {
  components: {
    DropDown,
    DropDownItem,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({
        page: 1,
        pageCount: 1,
        totalCount: 0,
        limit: 25,
      }),
    },
    pageRange: {
      type: Number,
      default: 3,
    },
    i18nKey: {
      type: String,
      default: 'comp.pagination.elements',
    },
    limitOptions: {
      type: Array,
      default: () => [25, 50, 100],
    },
  },
  emits: ['navigate'],
  data() {
    return {
      pagination: JSON.parse(JSON.stringify(this.modelValue)),
    };
  },
  computed: {
    i18nFullKey() {
      return this.pagination.totalCount === 1 ? `${this.i18nKey}.one` : `${this.i18nKey}.other`;
    },
    showFirstPage() {
      return this.pageRangeStart > 1;
    },
    showLastPage() {
      return this.pageRangeEnd < this.pagination.pageCount;
    },
    showRangeStartDots() {
      return this.pageRangeStart > 2;
    },
    showRangeEndDots() {
      return this.pageRangeEnd < this.pagination.pageCount - 1;
    },
    pageRangeStart() {
      return Math.max(1, this.pagination.page - this.pageRange);
    },
    pageRangeEnd() {
      return Math.min(this.pagination.pageCount, this.pagination.page + this.pageRange);
    },
  },
  watch: {
    modelValue: {
      handler() {
        this.pagination = JSON.parse(JSON.stringify(this.modelValue));
      },
      deep: true,
    },
  },
  mounted() {
    this.$emit('navigate', this.pagination);
  },
  methods: {
    changedPageLimit(limit) {
      this.pagination.limit = limit;
      this.pagination.page = 1;
      this.$emit('navigate', this.pagination);
    },
    show(pageIndex) {
      return pageIndex >= this.pageRangeStart && pageIndex <= this.pageRangeEnd;
    },
    goto(page) {
      this.pagination.page = page;
      this.$emit('navigate', this.pagination);
    },
    prev() {
      if (this.pagination.page > 1) {
        this.goto(this.pagination.page - 1);
      }
    },
    next() {
      if (this.pagination.page < this.pagination.pageCount) {
        this.goto(this.pagination.page + 1);
      }
    },
  },
};

</script>
