<template>
  <div class="fx-card-notop">
    <!-- <QuickViewModal modal-name="job-quick-view-modal" /> -->
    <table class="material top-align">
      <colgroup>
        <col>
        <col>
        <col style="width: 4rem;">
      </colgroup>
      <tbody>
        <tr class="middle-align">
          <th style="width: 8rem;">
            Status
          </th>
          <td colspan="2">
            <span v-if="isStatus('reported')" class="fx-status incident-reported">{{ statusText('reported') }}</span>
            <span v-if="isStatus('opened')" class="fx-status incident-opened">{{ statusText('opened') }}</span>
            <span v-if="isStatus('closed')" class="fx-status incident-closed">{{ statusText('closed') }}</span>
          </td>
        </tr>
        <tr>
          <th>Schweregrad</th>
          <td colspan="2">
            <span :class="'severity-' + incident.severity">
              {{ $t('activerecord.attributes.incident.severities.' + incident.severity) }}
            </span>
          </td>
        </tr>
        <tr v-if="incidentStatus">
          <th>Lösung</th>
          <td colspan="2">
            <span :class="'resolution-' + incidentStatus.resolution">
              {{ $t('activerecord.attributes.incident_status.resolutions.' + incidentStatus.resolution) }}
            </span>
          </td>
        </tr>
        <tr>
          <th>Gemeldet von</th>
          <td colspan="2">
            <div v-if="incident.reported_by">
              {{ incident.reported_by }}
            </div>
            <div v-else class="secondary">
              keine Angabe
            </div>
          </td>
        </tr>
        <tr>
          <th>Zielauftrag</th>
          <template v-if="!loading && assignedJob">
            <td>
              <Labels :labels="assignedJob.labels" />
              <div>
                <strong><a
                  mhref="assignedJobUrl"
                  class="no-color"
                  @click.prevent="jobQuickView(assignedJob.id)"
                >{{ assignedJob.label }}</a></strong>
                <span v-if="assignedJob.days_away > 0" style="white-space: nowrap;" class="secondary">(in
                  {{ assignedJob.days_away }} Tagen)</span>
                <span v-if="assignedJob.days_away == 0" class="secondary">heute</span>
                <br>
                Status <span class="fx-status" :class="assignedJob.status">{{ jobStatusText(assignedJob.status) }}</span>
              </div>
            </td>
            <td v-if="canEdit" class="text-right">
              <drop-down classes="fx-context-circle" :disabled="false">
                <drop-down-item>
                  <a @click.prevent="unAssignJob()"><i class="far fa-times fw" /> Aus Zielauftrag
                    entfernen</a>
                </drop-down-item>
              </drop-down>
            </td>
          </template>
          <template v-if="!loading && !assignedJob">
            <td colspan="2">
              <span class="secondary">kein Zielauftrag</span>
            </td>
          </template>
          <template v-if="loading">
            <td colspan="2">
              <span class="secondary">Daten werden geladen...</span>
            </td>
          </template>
        </tr>
        <tr v-if="offerAssignTargetJob">
          <td colspan="3">
            <div v-if="nextJob">
              <div>Nächster möglicher Zielauftrag in dieser Anlage:</div>
              <div style="margin: .6rem 0;">
                <span class="fx-status" :class="nextJob.status">{{ jobStatusText(nextJob.status) }}</span>
                <a class="no-color" @click.prevent="jobQuickView(nextJob.id)">&nbsp;{{ nextJob.label }}</a>
                <span v-if="nextJob.days_away > 0" style="white-space: nowrap;" class="secondary">(in
                  {{ nextJob.days_away }} Tagen)</span>
                <span v-if="nextJob.days_away == 0" class="secondary">heute</span>

                <div class="mt-2 fx-btn-group">
                  <a href="#" class="fx-btn-skeleton" @click.prevent="assignJob">In diesen Auftrag einplanen</a>
                  <a class="fx-btn-skeleton" @click.prevent="openNewJobModal(incident.installation.id)">Neuen Auftrag planen</a>
                </div>
              </div>
            </div>
            <div v-else>
              Es sind keine Aufträge für diese Anlagen geplant.
              <div class="mt-2">
                <a class="fx-btn-skeleton" @click.prevent="openNewJobModal(incident.installation.id)">Neuen Auftrag planen</a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import Flash from 'flash/index';
import Labels from 'components/partials/labels.vue';
import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';
import openNewJobModal from 'helpers/open_new_job_modal';
import { jobQuickView } from 'helpers/quickviews';
import IncidentModel from '../incident_model';
import Api from '../api';

export default {
  name: 'IncidentDetails',
  components: {
    DropDown,
    DropDownItem,
    Labels,
  },
  props: {
    incident: {
      type: Object,
      required: true,
    },
    jobUrl: {
      type: String,
      default: '/jobs',
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {
      openNewJobModal,
      jobQuickView,
    };
  },
  data() {
    return {
      nextJob: null,
      assignedJob: null,
      loading: false,
    };
  },
  computed: {
    customerUrl() {
      return `/customers/${this.incident.customer.id}`;
    },
    installationUrl() {
      return `/installations/${this.incident.installation.id}`;
    },
    thingUrl() {
      return `/things/${this.incident.thing.id}`;
    },
    incidentStatus() {
      return IncidentModel.getCurrentStatus(this.incident);
    },
    groupLabel() {
      if (this.incident.thing == null) {
        return '';
      }
      const group = this.incident.thing.group;
      return `${group.number >= 0 ? group.number : ''} ${group.name}`;
    },
    groupUrl() {
      return `${this.installationUrl}#tab-things`;
    },
    offerAssignTargetJob() {
      return this.canEdit && !this.loading && !this.assignedJob && this.isOpenStatus();
    },
    nextJobUrl() {
      return `${this.jobUrl}/${this.nextJob.id}`;
    },
    assignedJobUrl() {
      return `${this.jobUrl}/${this.assignedJob.id}`;
    },
    newJobUrl() {
      return `/jobs/new?installation_id=${this.incident.installation.id}`;
    },
  },
  created() {
    this.assignedJob = this.incident.assigned_job;
    if (this.canEdit) {
      Api.getAssignedJob({ incidentId: this.incident.id })
        .then((response) => {
          this.assignedJob = response.data.job;

          Api.getNextJob({ incidentId: this.incident.id })
            .then((nextJobResponse) => {
              this.nextJob = nextJobResponse.data.job;
              this.loading = false;
            });
        });
    }
  },
  methods: {
    isStatus(status) {
      return this.incidentStatus != null && this.incidentStatus.status === status;
    },
    statusText(status) {
      return this.$t(`activerecord.attributes.incident_status.statuses.${status}`);
    },
    assignJob() {
      Api.assignIncidentToJob({ incidentId: this.incident.id, assignedJobId: this.nextJob.id })
        .then(() => {
          this.assignedJob = this.nextJob;
          Flash.info('Störung in Zielauftrag eingeplant');
        })
        .catch((err) => {
          Flash.error(err);
        });
    },
    unAssignJob() {
      Api.assignIncidentToJob({ incidentId: this.incident.id, assignedJobId: null })
        .then(() => {
          this.assignedJob = null;
          Flash.info('Störung aus Zielauftrag entfernt');
        })
        .catch((err) => {
          Flash.error(err);
        });
    },
    isOpenStatus() {
      const currentStatus = IncidentModel.getCurrentStatus(this.incident);
      return (currentStatus && (currentStatus.status === 'opened' || currentStatus.status === 'reported'));
    },
    jobStatusText(status) {
      return this.$t(`job.status.${status}`);
    },
  },
};
</script>

<style></style>
