<template>
  <nav :class="navClass">
    <a href="/portal/locations">
      <button class="fx-nav-logo">
        <img :src="logoLarge" style="height: 40px; margin-top: 10px;">
      </button>
    </a>

    <div class="fx-nav-settings-area">
      <div class="fx-nav-magic">
        &nbsp;
      </div>

      <a class="fx-hide-for-medium-or-smaller" @click.prevent="openHelp()">
        <button
          v-tippy="{ placement: 'left', duration: 100, arrow: true }"
          class="fx-nav-personal"
          title="Hilfe zum Auftraggeberportal"
        >

          <img :src="iconHelp" class="fx-nav-icon">
        </button>
      </a>

      <a id="topbar-link-notifications" href="/notifications" class="fx-nav-link fx-hide-for-medium-or-smaller">
        <img :src="iconNotifications" class="fx-nav-icon">
        <i
          v-if="unreadNotifications"
          class="fas fa-circle"
          style="font-size: 10px; color: red; position: relative; right: 10px; top: -10px; width: 0px;"
        />
      </a>

      <div class="fx-nav-personal">
        <div class="fx-nav-personal fx-hide-for-large-or-larger">
          <button
            title="Hauptmenü"
            class="fx-nav-personal"
            style="font-size: 0.9rem;"
            @click.stop.prevent="toggleDropdown('main')"
          >
            <i class="far fa-bars" /> Menü
          </button>

          <div v-if="dropdowns['main']" class="fx-nav-personal-dropdown" style="overflow: hidden;">
            <a class="fx-nav-dropdown-link" @click.prevent="openHelp()">
              <i class="far fa-question-circle" /> Hilfe</a>
            <a id="topbar-link-alt-notifications" href="/notifications" class="fx-nav-dropdown-link">
              <i class="far fa-bell" />
              {{ $t('nav.main.notifications') }}
            </a>
          </div>
        </div>
      </div>

      <PersonalMenu :active-nav="activeNav" />
    </div>
  </nav>
</template>

<script>
import iconCog from 'images/cog.svg';
import iconHelp from 'images/help.svg';
import logoLarge from 'images/logo_portal.svg';
import iconNotifications from 'images/notifications.svg';

import axios from 'axios';
import _ from 'lodash';

import HelpModal from 'components/help_modal.vue';
import PersonalMenu from 'components/topbar/personal_menu.vue';

import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';

import { ref, onMounted } from 'vue';
import { useCurrentUserStore } from 'stores/current_user';

const keyCodes = {
  enter: 13,
  esc: 27,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
};

const buttonCodes = {
  left: 0,
};

export default {
  components: {
    PersonalMenu,
  },
  props: {
    activeNav: {
      type: String,
      required: true,
    },
    fixedPosition: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const unreadNotifications = ref(false);
    const currentUser = useCurrentUserStore().currentUser;

    const openHelp = () => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: HelpModal,
            attrs: {
              initTopicId: null,
              onClose() {
                modalInstance.close();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const updateNotifcationState = () => {
      unreadNotifications.value = !!localStorage.getItem('unreadNotifications');
    };

    const queryNotificationState = () => {
      axios.get('/notifications/pending.json', {
      }).then((response) => {
        if (response.data.pending) {
          localStorage.setItem('unreadNotifications', true);
        } else {
          localStorage.removeItem('unreadNotifications');
        }
        updateNotifcationState();
      });
    };

    onMounted(() => {
      updateNotifcationState();
      queryNotificationState();
    });

    return {
      unreadNotifications,
      openHelp,
      currentUser,
    };
  },
  data() {
    return {
      query: '',
      logoLarge,
      iconCog,
      iconHelp,
      iconNotifications,
      dropdowns: {
        admin: false,
        main: false,
        personal: false,
      },
      searchWaitingForResponse: true,
      searchActive: false,
      searchResults: [],
      highlightIndex: 0,
    };
  },
  computed: {
    userId() {
      return this.currentUser.id;
    },
    userOrganization() {
      return this.currentUser.organization;
    },
    userBranch() {
      return this.currentUser.branch;
    },
    userFullname() {
      return this.currentUser.fullname;
    },
    userEmail() {
      return this.currentUser.email;
    },
    userRoles() {
      return this.currentUser.roles;
    },
    profilePath() {
      return '/accounts/my';
    },
    avatarPath() {
      return this.currentUser.avatarPath;
    },
    navClass() {
      if (this.fixedPosition) {
        return 'fx-nav';
      }
      return 'fx-nav-not-fixed';
    },
  },
  methods: {
    cl() {
      const res = this.searchResults[this.highlightIndex];
      document.location.href = res.url;
    },
    // Normally when pressing the up or down key in an input fields, the cursor
    // would jump to the beginning or end of text. Since we’re using the up and
    // down keys for search result navigation, this is not really helpful.
    preventUpAndDownKeyBehavior(event) {
      if (event.keyCode === keyCodes.up || event.keyCode === keyCodes.down) {
        event.preventDefault();
      }
    },
    handleSearchInput(event) {
      if (event.keyCode === keyCodes.left || event.keyCode === keyCodes.right) {
        // ?
      } else if (event.keyCode === keyCodes.up) {
        if (this.highlightIndex > 0) {
          this.highlightIndex -= 1;
        }
      } else if (event.keyCode === keyCodes.down) {
        if (this.highlightIndex < this.searchResults.length - 1) {
          this.highlightIndex += 1;
        }
      } else if (event.keyCode === keyCodes.enter) {
        this.cl();
      } else if (event.keyCode === keyCodes.esc) {
        this.resetSearch();
      } else {
        this.debouncedSearch();
      }
    },
    // beware: do not use ES6 style "() => {}" here, it messes the this reference!
    debouncedSearch: _.debounce(function () {
      this.search();
    }, 250),
    search() {
      if (this.query.length > 1) {
        this.searchActive = true;

        axios.get(`/multisearch?query=${encodeURIComponent(this.query)}`)
          .then((response) => {
            this.searchResults = response.data;
          })
          .catch(() => {
            this.searchResults = [];
          });
      } else {
        this.searchActive = false;
      }
    },
    resetSearch() {
      this.highlightIndex = 0;
      this.searchActive = false;
      this.query = '';
    },
    documentClick(event) {
      if (event.button === buttonCodes.left) {
        this.hideDropdowns(null);
        this.resetSearch();
      }
    },
    hideDropdowns(notThisOne) {
      const drops = this.dropdowns;

      Object.keys(drops).forEach((property) => {
        if (property !== notThisOne) {
          drops[property] = false;
        }
      });
    },
    toggleDropdown(selected) {
      this.hideDropdowns(selected);
      this.dropdowns[selected] = !this.dropdowns[selected];
    },
    isActiveNav(nav) {
      return nav === this.activeNav;
    },
    checkActiveUser(event) {
      axios.get(`/accounts/status?user_id=${this.currentUser.id}`).catch((error) => {
        if (error.response.status === 410) {
          window.location.href = '/logged_out_info';
        }
      });
    },
  },
  created() {
    document.addEventListener('click', this.documentClick);
    window.addEventListener('focus', this.checkActiveUser);
  },
  unmounted() {
    document.removeEventListener('click', this.documentClick);
    window.removeEventListener('focus', this.checkActiveUser);
  },

};
</script>

<style></style>
