<template>
  <div id="dashboard-card-stats" ref="target">
    <div
      v-for="(stats, index) in statsList"
      :key="index"
      style="display: inline-block; width: 200px; margin-right: 1rem;"
    >
      <div :id="`stats-card-${stats.name}`" style="border: 1px solid #ccc; background: white; height: 180px; padding: 1rem; font-size: 0.8rem;">
        {{ stats.name }}
        <div class="text-center" style="font-size: 40px; line-height: 0.9; color: #2C556E; margin-top: 34px;">
          {{ stats.count }}
        </div>
      </div>
    </div>
    <div style="margin-bottom: 3rem;" />
  </div>
</template>

<script>
import axios from 'axios';
import { ref } from 'vue';
import { useElementVisibility } from '@vueuse/core';

export default {
  name: 'StatsCard',
  props: {
  },
  setup() {
    const target = ref(null);
    const targetIsVisible = useElementVisibility(target);

    return { target, targetIsVisible };
  },
  data() {
    return {
      waitingForPageIsRendered: true, // waiting for the page to be renderend before checking visibility
      loaded: false,
      jobs_count: 0,
      things_count: 0,
      installations_count: 0,
      locations_count: 0,
      customers_count: 0,
    };
  },
  computed: {
    statsList() {
      return [
        { name: 'Kunden', count: this.customers_count },
        { name: 'Objekte', count: this.locations_count },
        { name: 'Anlagen', count: this.installations_count },
        { name: 'Komponenten', count: this.things_count },
        { name: 'Aufträge', count: this.jobs_count },
      ];
    },

  },
  watch: {
    targetIsVisible: {
      handler(isVisible) {
        if (isVisible && !this.loaded && !this.waitingForPageIsRendered) {
          // load stats if scroll down to the element
          this.loadStats();
        }
      },
    },
  },
  mounted() {
    setTimeout(() => {
      this.waitingForPageIsRendered = false;
      if (this.targetIsVisible) {
        // load stats immediately if the element is visible after page is rendered
        this.loadStats();
      }
    }, 1000);
  },
  methods: {
    loadStats() {
      axios.get('/dashboard/stats.json').then((response) => {
        this.jobs_count = response.data.jobs_count;
        this.things_count = response.data.things_count;
        this.installations_count = response.data.installations_count;
        this.locations_count = response.data.locations_count;
        this.customers_count = response.data.customers_count;
        this.loaded = true;
      });
    },
  },
};
</script>
