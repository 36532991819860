<template>
  <div>
    <form @submit.prevent="enqueue">
      <div class="fx-form-row">
      <div class="fx-form-label">
        <label class="inline mandatory">Jahr</label>
      </div>
      <div class="fx-form-content">
        <select v-model="year" style="width: 80px; display: inline-block;">
          <option v-for="(singleYear, index) in years()" :key="index" :value="singleYear">
            {{ singleYear }}
          </option>
        </select>
      </div>
    </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory">Format</label>
        </div>
        <div class="fx-form-content">
          <input
              id="file_type_xlsx"
              v-model="fileType"
              name="file_type"
              type="radio"
              value="xlsx"
            >
            <label for="file_type_xlsx">Excel</label>

            <input
              id="file_type_csv"
              v-model="fileType"
              name="file_type"
              type="radio"
              value="csv"
            >
            <label for="file_type_csv">CSV</label>
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-content">
          <input
            type="submit"
            style="margin-bottom: 7px;"
            class="fx-btn-primary"
            value="Export starten"
          >
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';

export default {
  name: 'EnqueueJobArticleSums',
  props: {
    exporttype: {
      type: String,
      required: true,
    },
    exportableId: {
      type: String,
      default: null,
    },
    config: {
      type: Object,
      default: () => { },
    },
    label: {
      type: String,
      default: 'Export starten',
    },
    intro: {
      type: String,
      default: null,
    },
    defaultIntro: {
      type: String,
      default: '',
    },
  },
  emits: ['enqueued'],
  data() {
    return {
      year: new Date().getFullYear(),
      fileType: 'xlsx',
    };
  },
  computed: {
    introText() {
      if (this.intro && this.intro.length > 0) {
        return this.intro;
      }
      return this.defaultIntro;
    },
  },
  methods: {
    years() {
      let year = new Date().getFullYear();
      const years = [];
      while (year > 2015) {
        years.push(year);
        year -= 1;
      }
      return years;
    },
    installationId() {
      if (this.config) {
        return this.config.installationId;
      }
      return null;
    },
    enqueue() {
      axios.post('/exports/enqueue', {
        exportable_id: this.exportableId,
        exporttype: this.exporttype,
        filter: {
          year: this.year,
          installationId: this.installationId(),
        },
        file_type: this.fileType,
      }).then((response) => {
        this.$emit('enqueued', response.data.export_id);
      }).catch(() => {
        Flash.error('Export konnte nicht erzeugt werden');
      });
    },
  },
};
</script>
