<template>
  <div>
    <div class="fx-card">
      <div class="fx-card-content">
        <h2 class="fx">
          Standard Stammdaten-Felder
        </h2>
        <p>
          Alle Komponenten tragen eine Reihe von Standard-Stammdaten, die die Komponente
          oder ihren aktuellen Einsatzort
          beschreiben
        </p>
      </div>

      <div class="fx-list-row" style="padding-left: .5rem;">
        <div class="fx-220px-col">
          <strong>AUF DIE KOMPONENTE BEZOGEN</strong>
        </div>
        <div class="fx-150px-col" />
        <div class="fx-150px-col" />
        <div class="fx-300px-col">
          <strong>AUF DEN EINSATZ IN EINER ANLAGE BEZOGEN</strong>
        </div>
      </div>

      <div class="fx-list-row" style="padding-left: .5rem;">
        <div class="fx-220px-col">
          Typ, Hersteller, Modell
        </div>
        <div class="fx-150px-col" />
        <div class="fx-150px-col" />
        <div class="fx-220px-col">
          Anlage
        </div>
        <div class="fx-150px-col" />
      </div>

      <div class="fx-list-row" style="padding-left: .5rem;">
        <div class="fx-220px-col">
          Seriennummer
        </div>
        <div class="fx-150px-col" />
        <div class="fx-150px-col" />
        <div class="fx-220px-col">
          Gruppe
        </div>
        <div class="fx-150px-col" />
      </div>

      <div class="fx-list-row" style="padding-left: .5rem;">
        <div class="fx-220px-col">
          Foxtag-ID
        </div>
        <div class="fx-150px-col" />
        <div class="fx-150px-col" />
        <div class="fx-220px-col">
          Nummer
        </div>
        <div class="fx-150px-col" />
      </div>

      <div class="fx-list-row" style="padding-left: .5rem;">
        <div class="fx-220px-col">
          Baujahr
        </div>
        <div class="fx-150px-col" />
        <div class="fx-150px-col" />
        <div class="fx-220px-col">
          Standort
        </div>
        <div class="fx-150px-col" />
      </div>

      <div class="fx-list-row" style="padding-left: .5rem;">
        <div class="fx-220px-col">
          Zulassungsnummer
        </div>
        <div class="fx-150px-col" />
        <div class="fx-150px-col" />
        <div class="fx-220px-col" />
        <div class="fx-150px-col" />
      </div>

      <div class="fx-list-row" style="padding-left: .5rem;">
        <div class="fx-220px-col">
          Labels
        </div>
        <div class="fx-150px-col" />
        <div class="fx-150px-col" />
        <div class="fx-220px-col" />
        <div class="fx-150px-col" />
      </div>
    </div>

    <div id="custom-dataset-card" class="fx-card">
      <div class="fx-card-content">
        <h2 class="fx">
          Erweiterte Stammdaten-Felder
        </h2>
        <p>Diese Felder ergänzen die Standard Stammdaten-Felder für die jeweilige Wartungsanwendung.</p>

        <ServerErrors
          v-model="serverErrors"
          title="Änderungen konnten nicht gespeichert werden"
          margin-bottom="1rem"
        />
      </div>

      <div v-if="localCustomDataset.length" class="fx-list-row">
        <div v-if="canEdit" class="fx-50px-col" />
        <div class="fx-220px-col">
          <strong>Feldname</strong>
        </div>
        <div class="fx-150px-col">
          <strong>Feldtyp</strong>
        </div>
        <div class="fx-150px-col">
&nbsp;
        </div>
      </div>
      <div v-for="(row, index) in localCustomDataset" :key="index" class="fx-list-row">
        <div v-if="canEdit" class="fx-50px-col" style="line-height: 40px;">
          <a v-if="index > 0" @click="moveUp(index)"><i class="fas fa-arrow-up" style="margin-right: .5rem;" /></a>
          <i v-else class="fas fa-arrow-up secondary" style="margin-right: .5rem;" />
          <a v-if="index < localCustomDataset.length -1" @click="moveDown(index)"><i class="fas fa-arrow-down" /></a>
          <i v-else class="fas fa-arrow-down secondary" />
        </div>
        <div class="fx-220px-col">
          <input
            v-model="row.label"
            type="text"
            :disabled="!canEdit"
            style="margin: 0;"
            @keyup="destructiveChanges = true"
          >
        </div>
        <div class="fx-150px-col">
          <input
            v-model="row.type"
            type="text"
            style="margin: 0;"
            disabled
          >
        </div>
        <div class="fx-250px-col" style="line-height: 40px;">
          <input :id="`cb-${index}`" v-model="row.report" :disabled="!canEdit" type="checkbox">
          <label :for="`cb-${index}`" class="inline">im Bericht drucken</label>
        </div>
        <div class="fx-150px-col" style="line-height: 40px;">
          <a v-if="canEdit" @click="removeRow(index)">löschen</a>
        </div>
      </div>

      <div v-if="localCustomDataset.length < 8" class="fx-list-row" style="line-height: 40px;">
        <div class="fx-50px-col" />
        <div class="fx-220px-col">
          <strong><a v-if="currentUser.pro" id="add-custom-data-field" @click="addRow()">
            <i class="fas fa-plus" /> Feld hinzufügen</a></strong>
        </div>
      </div>

      <div class="fx-card-content">
        <div
          v-if="hasDestructiveChanges"
          class="callout callout--danger"
          style="margin-bottom: 1rem;"
        >
          <strong>Achtung möglicher Datenverlust!</strong><br>Sie haben Felder umbenannt oder gelöscht.<br>
          Diese Felder werden von allen Komponenten entfernt und die darin gespeicherten
          <strong>Daten werden unwiderruflich gelöscht!</strong>
        </div>

        <template v-if="currentUser.pro">
          <div class="callout callout--clock" style="margin-bottom: 1rem;">
            Es kann einige Minuten dauern, bis die geänderten Felder an Ihren Komponenten verfügbar sind.
          </div>

          <div class="fx-btn-group">
            <button id="reset" class="fx-btn-secondary" @click="reset()">
              zurücksetzen
            </button>
            <button id="save-field-configuration" class="fx-btn-primary" @click="confirmUpdateCustomDataset()">
              Feldkonfiguration speichern
            </button>
          </div>
        </template>

        <ProFeatureCallout v-if="!currentUser.pro">
          Wenn Sie auf <strong>Foxtag Pro</strong> wechseln, können Sie an dieser Stelle eigene Stammdaten-Felder für Ihre
          Wartungsanwendungen anlegen.
        </ProFeatureCallout>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import axios from 'axios';
import _ from 'lodash';
import Flash from 'flash/index';
import { ref } from 'vue';
import { useCurrentUserStore } from 'stores/current_user';
import ServerErrors from 'components/partials/server_errors.vue';

import ProFeatureCallout from 'components/pro_feature_callout.vue';
import confirmViaModal from 'helpers/confirm_modal';

export default {
  name: 'CustomDataset',
  components: {
    ServerErrors,
    ProFeatureCallout,
  },
  props: {
    installationTypeId: {
      type: String,
      required: true,
    },
    initCustomDataset: {
      type: Array,
      required: true,
    },
    installationsCount: {
      type: Number,
      required: true,
    },
    featureEnabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const serverErrors = ref([]);
    const localCustomDataset = ref([]);
    const unchangedCustomDataset = ref([]);
    const destructiveChanges = ref(false);

    const hasDestructiveChanges = () => {
      let destructive = false;
      unchangedCustomDataset.value.forEach((element) => {
        if (_.findIndex(localCustomDataset.value, { label: element.label }) < 0) {
          destructive = true;
        }
      });
      return destructive;
    };

    const loadCustomDataset = () => {
      axios.get(`/installation_types/${props.installationTypeId}/custom_dataset.json`).then((response) => {
        localCustomDataset.value = response.data.custom_dataset;
        unchangedCustomDataset.value = _.cloneDeep(localCustomDataset.value);
      });
    };

    const updateViaAPI = () => {
      serverErrors.value = [];
      axios.put(`/installation_types/${props.installationTypeId}/update_custom_dataset.json`, {
        custom_dataset: localCustomDataset.value,
      }).then((response) => {
        localCustomDataset.value = response.data.custom_dataset;
        unchangedCustomDataset.value = _.cloneDeep(localCustomDataset.value);
        destructiveChanges.value = false;
        Flash.info('Änderung gespeichert');
      }).catch((error) => {
        if (error.response.status === 400) {
          serverErrors.value = error.response.data.installation_types;
          Flash.info('Bitte überprüfen Sie Ihre Eingaben');
        } else {
          Flash.error('Die Änderung konnte nicht gespeichert werden');
        }
      });
    };

    const confirmUpdateCustomDataset = () => {
      let extraConfirmation = false;
      let message = 'Bitte bestätigen Sie, dass die Feldkonfiguration verändert werden soll.';

      if (hasDestructiveChanges()) {
        extraConfirmation = true;
        message = 'Bitte bestätigen Sie: Gelöschte oder umbenannte Felder werden von allen Komponenten gelöscht.';
      }

      confirmViaModal({
        title: 'Erweiterte Stammdaten ändern?',
        message,
        requireInputConfirmation: extraConfirmation,
        action: 'Feldkonfiguration ändern',
        confirmationWord: 'ändern',
      }).then(() => {
        updateViaAPI();
      });
    };

    const reset = () => {
      localCustomDataset.value = _.cloneDeep(unchangedCustomDataset.value);
      destructiveChanges.value = false;
    };

    loadCustomDataset();

    return {
      currentUser: useCurrentUserStore().currentUser,
      confirmUpdateCustomDataset,
      serverErrors,
      localCustomDataset,
      unchangedCustomDataset,
      destructiveChanges,
      reset,
    };
  },
  computed: {
    hasDestructiveChanges() {
      let destructive = false;
      this.unchangedCustomDataset.forEach((element) => {
        if (_.findIndex(this.localCustomDataset, { label: element.label }) < 0) {
          destructive = true;
        }
      });
      return destructive;
    },
    canEdit() {
      return this.currentUser.pro;
    },
  },
  methods: {
    moveUp(index) {
      if (index < 1) { return; }

      const tmp = this.localCustomDataset.slice();
      this.localCustomDataset[index - 1] = tmp[index];
      this.localCustomDataset[index] = tmp[index - 1];
      this.$forceUpdate();
    },
    moveDown(index) {
      if (index >= this.localCustomDataset.length - 1) { return; }

      const tmp = this.localCustomDataset.slice();
      this.localCustomDataset[index + 1] = tmp[index];
      this.localCustomDataset[index] = tmp[index + 1];
      this.$forceUpdate();
    },
    addRow() {
      this.localCustomDataset.push({ label: '', type: 'text', report: true });
    },
    removeRow(index) {
      this.localCustomDataset.splice(index, 1);
      this.destructiveChanges = true;
    },
  },
};
</script>
