<template>
  <span>
    <a
      ref="popoverReference"
      class="no-color"
      style="line-height: 2.5rem;"
      @mouseover="startShowing"
      @mouseleave="destroyPopover"
    >
      {{ text }} <span :class="iconClass"><i class="far fa-question-circle" /></span></a>
    <div v-show="isPopoverVisible" ref="basePopoverContent" class="base-popover">
      <div class="base-popover-content">
        <div class="base-popover-content__arrow" x-arrow />
        <div ref="popoverBody" class="base-popover-content__body" />
      </div>
    </div>
  </span>
</template>

<script>
import _ from 'lodash';
import Popper from 'popper.js';
import axios from 'axios';

export default {
  name: 'HelpHover',
  props: {
    text: {
      type: String,
      default: null,
    },
    content: {
      type: String,
      required: true,
    },
    popoverOptions: {
      type: Object,
      default: () => ({
        popoverReference: null,
        placement: 'top',
        offset: '0,10',
      }),
    },
  },
  data() {
    return {
      isPopoverVisible: false,
      popperInstance: null,
      cachedContent: null,
      mouseStillHovering: false,
    };
  },
  computed: {
    iconClass() {
      if (this.text == null) {
        return 'secondary';
      }
      return '';
    },
  },
  mounted() {
    this.popoverOptions.popoverReference = this.$refs.popoverReference;
  },
  methods: {
    startShowing() {
      this.mouseStillHovering = true;
      this.debouncedShow();
    },
    debouncedShow: _.debounce(function () {
      this.showPopper();
    }, 250),
    showPopper() {
      //
      if (this.mouseStillHovering === false) {
        return;
      }

      this.isPopoverVisible = true;

      if (this.cachedContent) {
        this.setPopperContent(this.cachedContent);
      } else {
        this.loadPopperContent();
      }
    },
    loadPopperContent() {
      const that = this;
      axios.get(`/tooltip.html?tip=${this.content}`).then((reponse) => {
        that.cachedContent = reponse.data;
        that.setPopperContent(reponse.data);
      });
    },
    setPopperContent(content) {
      if (this.$refs.popoverBody !== undefined) {
        this.$refs.popoverBody.innerHTML = content;

        this.$nextTick(() => {
          this.initPopper();
        });
      }
    },
    initPopper() {
      const modifiers = {};
      const { popoverReference, offset, placement } = this.popoverOptions;

      if (offset) {
        modifiers.offset = {
          offset,
        };
      }

      if (placement) {
        modifiers.placement = placement;
      }

      this.popperInstance = new Popper(
        popoverReference,
        this.$refs.basePopoverContent,
        {
          placement,
          modifiers: {
            ...modifiers,
            preventOverflow: {
              boundariesElement: 'viewport',
            },
          },
        },
      );
    },
    destroyPopover() {
      this.mouseStillHovering = false; // do not show popup via debounced method
      if (this.popperInstance) {
        this.popperInstance.destroy();
        this.popperInstance = null;
        this.isPopoverVisible = false;
      }
    },
  },

};
</script>
