<template>
  <div v-if="showGroup" class="fx-card group-header" :class="`t-group-${group.number}`">
    <div class="fx-card-content fx-card-content--header">
      <div v-if="editable && showThings" class="float-right">
        <drop-down text="bearbeiten" classes="fx-btn-no-decoration">
          <drop-down-item>
            <a @click.prevent="$emit('open-edit-group-modal', group)"><i class="far fa-pencil-alt fa-fw" /> Gruppe bearbeiten</a>
          </drop-down-item>
          <drop-down-item>
            <a @click.prevent="openSetDeploymentDateGroupModal(group)"><i class="far fa-calendar-alt fa-fw" /> Inbetriebnahme-Datum setzen</a>
          </drop-down-item>
          <drop-down-item v-if="!isDefaultGroup()">
            <hr>
          </drop-down-item>
          <drop-down-item v-if="!isDefaultGroup()">
            <a @click.prevent="$emit('open-confirm-delete-group-modal', group)"><i class="far fa-trash-alt fa-fw" /> Gruppe löschen</a>
          </drop-down-item>
        </drop-down>
      </div>

      <span style="display: block; width: 100%; cursor: pointer; color: #2C556E;" @click="toggleShowThings">
        <i
          v-if="showThings"
          class="fas fa-fw fa-chevron-down group-header-status-icon no-print"
          style="display: inline-block;"
        />
        <i
          v-else
          class="fas fa-fw fa-chevron-right group-header-status-icon no-print"
          style="display: inline-block;"
        />
        <h2 class="fx" style="font-size: 1rem; margin: 0; display: inline-block;">
          <span v-if="!isDefaultGroup()">
            <span style="font-weight: 400;">{{ groupLabel }}</span>&nbsp;
            <span class="t-group-number" style="font-weight: 400;">{{ group.number }}</span>
            <span class="t-group-name" style="margin-left: 0.5rem">{{ group.name }}</span>
          </span>
          <span v-else>
            <span>{{ group.name }}</span>
          </span>
          <span class="things-count" style=" margin-right: 0.3rem;margin-left: 0.3rem;" title="Anzahl Komponenten">{{ count }}</span>
          <ExternalLinkButton :href="group.link" />

        </h2>
      </span>
    </div>
    <table v-if="showThings" class="material" style="table-layout:fixed;width:100%;">
      <tr v-if="!loading && count > 0" class="material__title">
        <th style="width: 8%">
          {{ $t('activerecord.attributes.thing.number') }}
        </th>
        <th style="width: 20%">
          {{ $t('activerecord.attributes.thing.thing_type') }}
        </th>
        <th>Details</th>
        <th v-if="showDueDates" style="width: 18%">
          Fälligkeiten
        </th>
        <th class="no-print" style="text-align: right; width: 18%">
&nbsp;
        </th>
      </tr>
      <SingleThing
        v-for="thing in sortedLocalThings"
        :key="thing.id"
        :thing="thing"
        :installation-id="installationId"
        :editable="editable"
        :group-separator="groupSeparator"
        :tag-label="tagLabel"
        :has-stock="hasStock"
        :show-due-dates="showDueDates"
        :show-portal-add-incident-buttons="showPortalAddIncidentButtons"
        @open-edit-thing-modal="openEditThingModal"
        @open-confirm-delete-thing-modal="confirmDeleteThing"
        @open-new-subthing-modal="openNewSubThingModal"
        @open-add-installation-date-modal="openSetDeploymentDateThingModal"
        @open-remove-modal="openRemoveThingModal"
        @open-replace-modal="openReplaceThingModal"
        @duplicate="duplicateViaAPI"
      />
      <tr v-if="loading">
        <td colspan="6" style="text-align: left;">
          loading ...
        </td>
      </tr>
      <tr v-if="!loading && editable">
        <td colspan="5">
          <div class="fx-btn-group">
            <a class="fx-btn-secondary fx-btn-lighter" style="padding: .4rem .8rem;" @click.prevent="openNewThingModal()">
              <i class="fas fa-plus" /> {{ addThingLabel }}</a>
            <a
              v-if="hasStock"
              class="fx-btn-secondary fx-btn-lighter"
              style="padding: .4rem .8rem;"
              @click.prevent="openDeployThingModal()"
            ><i class="far fa-warehouse-alt" /> Aus Lager hinzufügen</a>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import _ from 'lodash';
import axios from 'axios';
import Flash from 'flash/index';
import confirmViaModal from 'helpers/confirm_modal';
import { ref } from 'vue';
import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';
import EntityList from 'helpers/entity_list';

import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';
import ExternalLinkButton from 'components/external_link_button.vue';

import RemoveModal from 'apps/things/remove_modal.vue';
import ReplaceModal from 'apps/things/replace_modal.vue';
import DeployModal from 'apps/things/deploy_modal.vue';
import SingleThing from './single_thing.vue';
import EditThingModal from './modals/edit_thing_modal.vue';
import SetDeploymentDateModal from './modals/set_deployment_date_modal.vue';

export default {
  name: 'SingleGroup',
  components: {
    ExternalLinkButton,
    SingleThing,
    DropDown,
    DropDownItem,
  },
  props: {
    installationId: {
      type: String,
      required: true,
    },
    installationTypeId: {
      type: String,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
    groupLabel: {
      type: String,
      default: 'Gruppe',
    },
    groupSeparator: {
      type: String,
      default: '/',
    },
    tagLabel: {
      type: String,
      default: 'Foxtag-ID',
    },
    thingLabel: {
      type: String,
      default: 'Komponente',
    },
    hasCentralUnit: {
      type: Boolean,
      default: false,
    },
    hasStock: {
      type: Boolean,
      default: false,
    },
    startCollapsed: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    showDueDates: {
      type: Boolean,
      default: true,
    },
    showPortalAddIncidentButtons: {
      type: Boolean,
      default: false,
    },
    customDataset: {
      type: Array,
      required: true,
    },
  },
  emits: ['changed', 'siblingNeedsRefresh', 'open-edit-group-modal', 'open-confirm-delete-group-modal'],
  setup(props, { emit }) {
    const localThings = new EntityList();
    const loading = ref(false);
    const count = ref(0);
    const showThings = ref(false);

    const isParent = (thing) => {
      if (thing.deployment.sub_number > 0) {
        return false; // --- thing _is_ sub thing, so it cannot be a parent
      }
      return _.size(_.filter(localThings.value, (i) => i.deployment.parent_id === thing.deployment.id)) > 0;
    };

    const isDefaultGroup = () => (props.group.number < 0);
    const isCentralUnit = () => (isDefaultGroup() && props.hasCentralUnit);

    const updateCount = () => {
      if (showThings.value) {
        count.value = localThings.value.length;
      } else {
        count.value = props.group.things_count; // init count with value from group
      }
    };

    const nextFreeSubNumber = (parentThing) => {
      const parentThingsList = _.filter(localThings.value, ['deployment.number', parentThing.deployment.number]);
      if (parentThingsList && parentThingsList.length) {
        const thingWithMaxSubNumber = _.maxBy(parentThingsList, 'deployment.sub_number');
        if (thingWithMaxSubNumber) {
          return thingWithMaxSubNumber.deployment.sub_number + 1;
        }
      }
      return 1;
    };

    const loadThings = () => {
      localThings.value = [];
      loading.value = true;
      axios.get(`/installations/${props.installationId}/deployments.json`, {
        params: {
          group_id: props.group.id,
          page: 1,
          limit: 1500,
        },
      }).then((response) => {
        loading.value = false;
        localThings.value = localThings.value.concat(response.data.installation_deployments);
        updateCount();
        emit('changed');
      }).catch(() => {
        loading.value = false;
      });
    };

    const createdThingEvent = (thing) => {
      localThings.append(thing);
      updateCount();
      emit('changed'); // triggers count updates in parents
    };

    const updatedThingEvent = (thing) => {
      const index = localThings.indexOf(thing);

      // Note: checking unchanged local thing!
      const hasSubThings = isParent(localThings.value[index]);

      if (thing.deployment.group.id !== props.group.id) {
        // thing has been moved out of this group -> remove thing
        localThings.remove(thing);
        emit('siblingNeedsRefresh', thing.deployment.group.id);
        updateCount();
      } else {
        // thing is still in this group -> update thing
        localThings.update(thing);
      }

      // --- important when group has been changed, or number has been changed
      if (hasSubThings) {
        loadThings();
      }
    };

    // --- API

    const deleteViaAPI = (thing) => {
      axios.delete(`/things/${thing.id}`).then(() => {
        Flash.info('Komponente gelöscht');
        if (isParent(thing)) {
          loadThings();
        } else {
          localThings.remove(thing);
          updateCount();
          emit('changed'); // triggers count updates in parents
        }
      }).catch(() => {
        Flash.error('Komponente konnte nicht gelöscht werden');
      });
    };

    const duplicateViaAPI = (thing) => {
      axios.put(`/things/${thing.id}/duplicate`).then((response) => {
        Flash.info('Komponente dupliziert');
        if (isParent(thing)) {
          loadThings();
          emit('changed');
        } else {
          createdThingEvent(response.data.thing);
        }
      }).catch(() => {
        Flash.error('Komponente konnte nicht dupliziert werden');
      });
    };

    // --- MODALS

    const confirmDeleteThing = (thing) => {
      confirmViaModal({
        title: 'Komponente löschen?',
        message: 'Löscht die Komponente, die vorhandenen Sub-Komponenten und alle mit der Komponente verbundenen Daten.',
      }).then(() => {
        deleteViaAPI(thing);
      });
    };

    const openDeployThingModal = () => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: DeployModal,
            attrs: {
              group: props.group,
              installationTypeId: props.installationTypeId,
              installationId: props.installationId,
              number: 0,
              sub_number: 0,
              onClose() {
                modalInstance.close();
              },
              onDeployed(deployedThing) {
                modalInstance.close();
                createdThingEvent(deployedThing);
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openRemoveThingModal = (thing) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: RemoveModal,
            attrs: {
              thing,
              hasStock: props.hasStock,
              onClose() {
                modalInstance.close();
              },
              onRemoved() {
                modalInstance.close();
                localThings.remove(thing);
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openReplaceThingModal = (thing) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: ReplaceModal,
            attrs: {
              thing,
              hasStock: props.hasStock,
              onClose() {
                modalInstance.close();
              },
              onReplaced() {
                modalInstance.close();
                loadThings();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openSetDeploymentDateThingModal = (thing) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: SetDeploymentDateModal,
            attrs: {
              scope: 'thing',
              thing,
              message: 'Setzen Sie ein neues Inbetriebnahme-Datum für die ausgewählte Komponente.',
              onClose() {
                modalInstance.close();
              },
              onUpdated(updatedThing) {
                modalInstance.close();
                updatedThingEvent(updatedThing);
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openSetDeploymentDateGroupModal = (group) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: SetDeploymentDateModal,
            attrs: {
              scope: 'group',
              group,
              message: 'Setzen Sie ein neues Inbetriebnahme-Datum für alle Komponenten der ausgewählten Gruppe.',
              onClose() {
                modalInstance.close();
              },
              onUpdated() {
                modalInstance.close();
                loadThings();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openEditThingModal = (thing) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: EditThingModal,
            attrs: {
              thing,
              installationTypeId: props.installationTypeId,
              installationId: props.installationId,
              isCentralUnit: isCentralUnit(),
              hasStock: props.hasStock,
              customDataset: props.customDataset,
              onClose() {
                modalInstance.close();
              },
              onCreated(createdThing) {
                modalInstance.close();
                createdThingEvent(createdThing);
              },
              onUpdated(updatedThing) {
                modalInstance.close();
                updatedThingEvent(updatedThing);
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openNewThingModal = () => {
      openEditThingModal({
        id: null,
        deployment: {
          number: null,
          group: props.group,
        },
        thing_type: { id: null },
        inherit_check_interval: true,
        assigned_features: [],
      });
    };

    const openNewSubThingModal = (parentThing) => {
      openEditThingModal({
        id: null,
        deployment: {
          number: parentThing.deployment.number,
          sub_number: nextFreeSubNumber(parentThing),
          group: props.group,
        },
        thing_type: { id: null },
        inherit_check_interval: true,
        assigned_features: [],
      });
    };

    return {
      count,
      showThings,
      localThings: localThings.reactiveList,
      loadThings,
      isDefaultGroup,
      isCentralUnit,
      confirmDeleteThing,
      openSetDeploymentDateThingModal,
      openSetDeploymentDateGroupModal,
      openEditThingModal,
      openNewThingModal,
      openNewSubThingModal,
      openRemoveThingModal,
      openReplaceThingModal,
      openDeployThingModal,
      updateCount,
      duplicateViaAPI,
    };
  },
  data() {
    return {
      loaded: false,
      loading: false,
    };
  },
  computed: {
    addThingLabel() {
      return (this.isCentralUnit()) ? 'Zentrale hinzufügen' : `${this.thingLabel} hinzufügen`;
    },
    showGroup() {
      return !(this.isDefaultGroup() && this.count === 0);
    },
    sortedLocalThings() {
      return _.sortBy(this.localThings, (thing) => thing.deployment.number * 10000 + thing.deployment.sub_number);
    },
    showNoResults() {
      return (this.loaded && (this.localThings.length === 0));
    },
  },
  mounted() {
    this.updateCount();

    this.showThings = !this.startCollapsed;
    if (this.showThings) {
      this.loadThings();
    }
  },
  methods: {
    toggleShowThings() {
      this.showThings = !this.showThings;
      if (this.showThings) {
        this.loadThings();
      }
    },
  },
};
</script>

<style>
span.things-count {
  border-radius: 0.8rem;
  background-color: #f6f6f6;
  color: #222;
  padding: 0.2rem 0.4rem;
  font-weight: 600;
  font-size: 0.825rem;
}
</style>
