<template>
  <div>
    <a v-for="attachment in attachments" :key="attachment.id" :href=" attachment.download_url">
      <AttachmentPanel :attachment="attachment" :can-delete="attachment.can_delete" @delete="confirmDeleteAttachment" />
    </a>
    <div v-if="attachments.length == 0" class="secondary">
      <span>
        Keine Berichte verfügbar. <a @click="loadAttachments(false);">Liste aktualisieren</a>
      </span>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';
import { ref, onMounted } from 'vue';
import confirmViaModal from 'helpers/confirm_modal';

import AttachmentPanel from 'components/job_attachments/attachment_panel.vue';

export default {
  name: 'AttachmentsList',
  components: {
    AttachmentPanel,
  },
  props: {
    job: {
      type: Object,
      required: true,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['can-create-changed'],
  setup(props, { emit }) {
    const attachments = ref([]);

    const loadAttachments = (quiet = true) => {
      axios.get(`/jobs/${props.job.id}/job_attachments`).then((response) => {
        attachments.value = response.data.job_attachments;
        emit('can-create-changed', response.data.can_create);
        if (!quiet) {
          Flash.info('Liste der Berichte aktualisiert');
        }
      });
    };

    const deleteViaApi = (attachment) => {
      axios.delete(`/jobs/${props.job.id}/job_attachments/${attachment.id}`).then(() => {
        Flash.info('Datei gelöscht');
        loadAttachments();
      }).catch(() => {
        Flash.error('Fehler beim Löschen');
      });
    };

    const confirmDeleteAttachment = (attachment) => {
      confirmViaModal({
        title: 'Datei löschen?',
        message: 'Diese Aktion kann nicht rückgängig gemacht werden.',
      }).then(() => {
        deleteViaApi(attachment);
      });
    };

    onMounted(() => {
      loadAttachments();
    });

    return {
      attachments,
      loadAttachments,
      confirmDeleteAttachment,
    };
  },
};
</script>
