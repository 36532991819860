<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <h2 class="fx">
      Komponente außer Betrieb nehmen
    </h2>
    <p>
      Entfernt die Komponente aus der Anlage. Nimmt auch alle Sub-Komponenten
      außer Betrieb und schließt alle offenen Störungen.
    </p>
    <hr>

    <div v-if="localThing">
      <ServerErrorsCommand v-model="commandServerErrors" title="Änderungen konnten nicht gespeichert werden" />
      <form @submit.prevent="submit">
        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline mandatory" for="thing_number">Außerbetriebnahme</label>
          </div>
          <div class="fx-form-content">
            <VueDatePicker
              v-model="date"
              :locale="$i18n.locale"
              :auto-apply="true"
              model-type="yyyy-MM-dd"
              :enable-time-picker="false"
              :format="'dd.MM.yyyy'"
              :disabled-dates="isFutureDate"
            />
          </div>
        </div>

        <div v-if="hasStock" class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline mandatory" for="thing_status">Neuer Status</label>
          </div>
          <div class="fx-form-content" style="padding-top: 7px;">
            <input
              id="thing_status_out_of_order"
              v-model="status"
              type="radio"
              value="out_of_order"
            >
            <label for="thing_status_out_of_order">Außer Betrieb</label>
            <input
              id="thing_status_stocked"
              v-model="status"
              type="radio"
              value="stocked"
            >
            <label for="thing_status_stocked">Im Lager</label>
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline" for="removed_job">Auftrag</label>
          </div>
          <div class="fx-form-content">
            <DBBasedSelect
              v-model="jobId"
              icon-id="job"
              :default-label="$t('comp.select.no-selection')"
              i18n-base-key="jobs"
              :load-options-url="`/select/jobs?installation_id=${localThing.deployment.installation_id}&only_open_jobs=true`"
              @changed="(value) => { jobId = value; }"
            />
            <span class="secondary small">Optional. Zuordnung zu einem offenen Auftrag.</span>
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline" for="thing_reason">Grund</label>
          </div>
          <div class="fx-form-content">
            <StaticAutocomplete v-model="reason" :options="removeReasons" @input="(val) => reason = val" />
            <span class="secondary small">Optional. Grund für die Außerbetriebnahme.</span>
          </div>
        </div>

        <hr>
        <button type="submit" class="fx-btn-primary float-right" style="margin-bottom: 0.5rem; margin-left: 0.5rem;">
          Speichern
        </button>
        <button class="fx-btn-secondary float-right" @click.prevent="$emit('close')">
          Abbrechen
        </button>
        <div class="clearfix" />
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import ServerErrorsCommand from 'components/partials/server_errors_command.vue';

import DBBasedSelect from 'components/select/db_based_select.vue';
import StaticAutocomplete from 'components/select/static_autocomplete.vue';

export default {
  name: 'RemoveThingModal',
  components: {
    VueDatePicker,
    ServerErrorsCommand,
    DBBasedSelect,
    StaticAutocomplete,
  },
  props: {
    hasStock: {
      type: Boolean,
      default: false,
    },
    thing: {
      type: Object,
      required: true,
    },
  },
  emits: ['removed', 'close'],
  data() {
    return {
      localThing: null,
      jobId: null,
      commandServerErrors: {},
      date: new Date().toISOString().split('T')[0],
      status: 'out_of_order',
      reason: null,
      removeReasons: [
        'Regelmäßiger Austausch',
        'Zulässiges Höchstalter überschritten',
        'Zulässige Betriebsstunden überschritten',
        'Komponente defekt',
        'Komponente wird nicht mehr gebraucht',
      ],
    };
  },
  mounted() {
    this.localThing = this.thing;
  },
  methods: {
    // --- used by date pick
    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },
    submit() {
      this.removeViaAPI();
    },
    removeViaAPI() {
      const that = this;
      that.commandServerErrors = {};

      axios.put(
        `/things/${this.localThing.id}/remove.json`,
        {
          removed_at: this.date,
          removed_job_id: this.jobId,
          removed_reason: this.reason,
          status: this.status,
        },
      ).then(() => {
        this.$emit('removed');
        Flash.info('Komponente wurde außer Betrieb genommen');
      }).catch((error) => {
        if (error.response && error.response.status === 400) {
          that.commandServerErrors = error.response.data;
        } else {
          Flash.error('Unerwarteter Fehler');
        }
      });
    },
  },
};
</script>
