<template>
  <div class="fx-modal-content-medium">
  <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <h2 class="fx">
      {{ title }}
    </h2>

    <hr style="margin-top:0;">

    <ServerErrorsCommand v-model="commandServerErrors" title="Foxtag-ID konnte nicht gespeichert werden" margin-bottom="1rem" />

    <form @submit.prevent="submit">
      <input id="id-type-foxtag" v-model="idType" type="radio" name="id-type" value="foxtag">
      <label for="id-type-foxtag">ID-Aufkleber von Foxtag</label>
      &nbsp;&nbsp;&nbsp;
      <input id="id-type-custom" v-model="idType" type="radio" name="id-type" value="custom">
      <label for="id-type-custom">Anderer QR-Code / Barcode</label>

      <div v-if="idType == 'foxtag'" class="mt-8">
        <p>Für Foxtag-IDs oder Aufkleber mit eigenem Logo, die Foxtag produziert hat.</p>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline mandatory" for="group_number">6-stelliger Code</label>
          </div>
          <div class="fx-form-content">
            <div style="display: inline-block; color: #888;">
              {{ foxtagIdBaseUrl }}
            </div>
            <div style="display: inline-block; width: 90px; margin-left: 8px;">
              <input v-model="foxtagCode" type="text" maxlength="6">
            </div>
          </div>
        </div>
      </div>

      <div v-if="idType == 'custom'" class="mt-8">
        <p>Für Codes die nicht von Foxtag produziert wurden.</p>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline mandatory" for="group_number">Code</label>
          </div>
          <div class="fx-form-content">
            <input v-model="customCode" type="text">
          </div>
        </div>
      </div>

      <hr>
      <button
        id="submit-edit-group"
        type="submit"
        class="fx-btn-primary float-right"
        style="margin-bottom: 0.5rem; margin-left: 0.5rem;"
      >
        Speichern
      </button>
      <button class="fx-btn-secondary float-right" @click.prevent="$emit('close')">
        Abbrechen
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';

import ThingPresenter from 'mixins/thing_presenter';
import ServerErrorsCommand from 'components/partials/server_errors_command.vue';

import { ref } from 'vue';

export default {
  name: 'EditFoxtagIdModal',
  components: {
    ServerErrorsCommand,
  },
  mixins: [ThingPresenter],
  props: {
    thing: {
      type: Object,
      required: true,
    },
    foxtagIdBaseUrl: {
      type: String,
      default: 'https://www.foxtag.io/id/',
    },
    tagLabel: {
      type: String,
      default: 'Foxtag-ID',
    },
  },
  emits: ['updated', 'close'],
  setup(props, { emit }) {
    const idType = ref('foxtag');
    const foxtagCode = ref(null);
    const customCode = ref(null);
    const commandServerErrors = ref({});

    const updateViaAPI = (newActiveTagSerial) => {
      commandServerErrors.value = {};

      axios.put(`/things/${props.thing.id}.json`, {
        thing: {
          active_tag_serial: newActiveTagSerial,
        },
      }).then(() => {
        emit('updated', newActiveTagSerial);
      }).catch((error) => {
        if (error.response && error.response.status === 400) {
          commandServerErrors.value = error.response.data;
        } else {
          Flash.error('Die Änderung konnte nicht gespeichert werden');
        }
      });
    };

    const submit = () => {
      if (idType.value === 'foxtag') {
        if (foxtagCode.value == null || foxtagCode.value.trim().length === 0) {
          updateViaAPI(''); // delete active tag serial
        } else {
          // prepend base url and update active tag serial
          updateViaAPI(`${props.foxtagIdBaseUrl}${foxtagCode.value.toLowerCase()}`);
        }
      } else {
        updateViaAPI(customCode.value);
      }
    };

    return {
      commandServerErrors,
      foxtagCode,
      customCode,
      idType,
      submit,
    };
  },
  computed: {
    title() {
      if (this.thing.active_tag_serial == null) {
        return `${this.tagLabel} hinzufügen`;
      }
      return `${this.tagLabel} bearbeiten`;
    },
  },
  mounted() {
    if (this.thing.active_tag_serial == null || this.hasFoxtagId(this.thing)) {
      this.idType = 'foxtag';
      this.foxtagCode = this.humanTagSerial(this.thing);
    } else {
      this.idType = 'custom';
      this.customCode = this.humanTagSerial(this.thing);
    }
  },
};
</script>
