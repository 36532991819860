<template>
  <div>
    <div v-if="photosLoaded" class="fx-grid mt-4" style="display: block;">
      <PhotoGrid
        :photos="localPhotos"
        :photoable-id="modelValue.id"
        photoable-type="Job"
        :col-count="4"
        :add-photos-button="canAddPhotos"
        :installation-id="modelValue.installation_id"
        :empty-state-hint="emptyStateHint"
        @changed="$emit('changed')"
      />

      <div v-if="localPhotos.length && localAttachments.length" class="callout callout--info mt-4">
        <strong>Hinweis alte und neue Foto-Technik</strong><br>
        Dieser Auftrag enthält Fotos im alten und im neuen technischen Format, die in separaten Listen angezeigt werden.
        Wir unterstützen für eine Übergangszeit die Nutzung von alten und neuen Apps parallel, danach überführen wir
        alle alten Fotos für Sie in das neue Format.
      </div>
    </div>

    <div v-if="localAttachments.length" class="fx-grid" style="flex-wrap: wrap">
      <div
        v-for="attachment in localAttachments"
        :key="attachment.id"
        class="fx-col"
        style="flex: 0 1 360px;"
      >
        <div class="fx-card" style="padding: 5px;">
          <a :href="downloadPath(attachment.id)">
            <img
              :ref="attachment.id"
              :src="attachment.preview_url"
              style="object-fit: cover; width: 350px; height: 250px;"
            >
          </a>

          <div style="padding: 5px; overflow-wrap: break-word;">
            <div>{{ attachment.name }}</div>
            <div class="secondary" style="font-size: 0.825rem;">
              <div class="float-right">
                <a @click="confirmDeleteAttachment(attachment)">löschen</a>
              </div>
              {{ formatDate(attachment.attached_at, "DD.MM.YYYY HH:mm") }} Uhr
              <div>{{ attachment.user.full_name }}, {{ attachment.human_file_size }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import _ from 'lodash';
import axios from 'axios';
import { ref } from 'vue';
import confirmViaModal from 'helpers/confirm_modal';

import Flash from 'flash/index';
import FoxtagPaths from 'mixins/foxtag_paths';
import PhotoGrid from 'apps/photos/photo_grid.vue';
import { DateFormatter } from 'mixins/formatters';

export default {
  name: 'TabPhotos',
  components: {
    PhotoGrid,
  },
  mixins: [FoxtagPaths, DateFormatter],
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ['changed'],
  setup(props, { emit }) {
    const localAttachments = ref([]); // legacy photos

    const loadAttachments = () => {
      axios.get(`/jobs/${props.modelValue.id}/attachments.json`).then((response) => {
        localAttachments.value = response.data.attachments;
      }).catch(() => {
        Flash.error('Fehler beim Laden der Fotos (legacy)');
      });
    };

    const deleteAttachmentViaAPI = (attachment) => {
      axios.delete(`/jobs/${props.modelValue.id}/attachments/${attachment.id}.json`).then(() => {
        loadAttachments();
        emit('changed');
      }).catch(() => {
        Flash.error('Foto konnte nicht gelöscht werden');
      });
    };

    const confirmDeleteAttachment = (attachment) => {
      confirmViaModal({
        title: 'Foto löschen?',
      }).then(() => {
        deleteAttachmentViaAPI(attachment);
      });
    };

    return {
      localAttachments,
      loadAttachments,
      confirmDeleteAttachment,
    };
  },
  data() {
    return {
      localPhotos: [],
      photosLoaded: false,
    };
  },
  computed: {
    emptyStateHint() {
      // --- photo grid should not show empty state if there are legacy photos (=Attachments)
      if (this.localAttachments.length) {
        return null;
      }
      return 'In diesem Auftrag wurden keine Fotos gemacht, oder sie haben eine Filtereinstellung, die keine Fotos findet.';
    },
    canAddPhotos() {
      return _.includes(['unplanned', 'planned', 'active', 'closed'], this.modelValue.status);
    },
  },
  mounted() {
    this.loadAttachments();
    this.loadPhotos();
  },
  methods: {
    downloadPath(photoId) {
      return `/jobs/${this.modelValue.id}/attachments/${photoId}/download`;
    },
    loadPhotos() {
      axios.get(`/photos.json?job_id=${this.modelValue.id}`).then((response) => {
        this.localPhotos = response.data.photos;
        this.photosLoaded = true;
      }).catch(() => {
        Flash.error('Fehler beim Laden der Fotos (shrine)');
      });
    },
  },
};
</script>
