<template>
  <div class="fx-file-pane">
    <div v-if="canDelete" class="fx-file-pane-icon">
      <button
        class="fx-file-pane-icon"
        @click.prevent="$emit('delete', attachment)"
      >
        <i class="fas fa-trash-alt" />
      </button>
    </div>
    <div style="margin-top: .5rem;">
      <img :src="attachment.preview_url" class="fx-file-pane"><br>
      <span class="fx-file-pane-label" :title="attachment.label">{{ attachment.label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AttachmentPanel',
  components: {
  },
  props: {
    attachment: {
      type: Object,
      required: true,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>
