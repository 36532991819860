import VueAppFactory from 'helpers/vue_app_factory';
import SidebarLayout from 'components/layouts/sidebar_layout.vue';
import AdminSidebar from 'components/sidebar/admin_sidebar.vue';
import ImportsSidebar from 'components/sidebar/imports_sidebar.vue';
import OrganizationApikeys from 'apps/organizations/api_keys/index.vue';
import OrganizationEvents from 'apps/organizations/events/index.vue';
import Layouts from 'apps/report_layouts/index/index.vue';
import UsersIndex from 'apps/users/index/index.vue';
import UserShow from 'apps/users/show/index.vue';
import ArticlesIndex from 'apps/articles/index/index.vue';
import InstallationTemplatesIndex from 'apps/installation_templates/index.vue';
import InstallationTypesIndex from 'apps/installation_types/index/index.vue';
import ContactsIndex from 'apps/contacts/index/index.vue';
import ContactShow from 'apps/contacts/show/index.vue';
import LabelsIndex from 'apps/labels/index/index.vue';
import ImportsIndex from 'apps/imports/index.vue';

VueAppFactory({
  el: '#sidebar-page',
  components: {
    Layouts,
    SidebarLayout,
    AdminSidebar,
    ImportsSidebar,
    ArticlesIndex,
    InstallationTypesIndex,
    InstallationTemplatesIndex,
    ContactsIndex,
    ImportsIndex,
    LabelsIndex,
    ContactShow,
    OrganizationApikeys,
    OrganizationEvents,
    UsersIndex,
    UserShow,
  },
});
