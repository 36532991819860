import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';
import ExportsModal from 'apps/exports/modals/exports_modal.vue';

const openExportModal = (attr) => {
  const modalInstance = useModal({
    component: VueFinalModal,
    slots: {
      default: useModalSlot({
        component: ExportsModal,
        attrs: {
          exportableId: attr.exportableId,
          title: attr.title,
          exporttype: attr.exporttype,
          config: attr.config,
          intro: attr.intro,
          onClose() {
            modalInstance.close();
          },
        },
      }),
    },
  });
  modalInstance.open();
};

export default openExportModal;
