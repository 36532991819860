<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <h2 class="fx">
      Wartungsanwendung hinzufügen
    </h2>
    <hr>
    <ServerErrors v-model="serverErrors" title="Wartungsanwendung konnte nicht gespeichert werden" margin-bottom="1rem" />
    <form>
      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory">Name</label>
        </div>
        <div class="fx-form-content">
          <input id="name-input" v-model="name" type="text">
        </div>
      </div>
    </form>
    <hr>
    <input
      id="submit-input"
      type="submit"
      value="Wartungsanwendung hinzufügen"
      class="fx-btn-primary float-right"
      style="margin-bottom: 0.5rem;"
      @click.prevent="createViaAPI"
    >
    <div class="clearfix" />
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';

import ServerErrors from 'components/partials/server_errors.vue';

export default {
  components: {
    ServerErrors,
  },
  data() {
    return {
      name: null,
      serverErrors: [],
    };
  },
  methods: {
    createViaAPI() {
      const that = this;
      that.serverErrors = [];
      axios.post('/installation_types.json', {
        installation_type: {
          name: this.name,
        },
      }).then((response) => {
        that.$emit('close');
        window.location.href = `/installation_types/${response.data.installation_type.id}`;
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.installation_types;
        } else {
          Flash.error('Typ konnte nicht gespeichert werden');
        }
      });
    },
  },
};
</script>
