<template>
  <div class="fx-list-row">
    <div class="fx-single-control-col">
      <div class="fx-btn-group">
        <a class="fx-btn-skeleton" @click.prevent="$emit('open-edit-article', article)">bearbeiten</a>
        <ButtonDeleteIcon :enabled="!article.has_jobs" @click="$emit('open-confirm-delete', article)" />
      </div>
    </div>

    <div class="fx-50px-col secondary">
      {{ article.position }}
    </div>
    <div class="fx-150px-col" :class="activeClass">
      {{ article.number }}
      <span v-if="!article.active">(passiv)</span>
    </div>
    <div class="fx-220px-col" :class="activeClass">
      {{ article.group }}
    </div>
    <div class="fx-220px-col" :class="activeClass">
      {{ article.name }}
    </div>
    <div class="fx-50px-col" :class="activeClass">
      {{ article.unit }}
    </div>
    <div class="clearfix" />
  </div>
</template>

<script>
import ButtonDeleteIcon from 'components/button_delete_icon.vue';

export default {
  name: 'ArticleItem',
  components: {
    ButtonDeleteIcon,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
  emits: ['open-edit-article', 'open-confirm-delete'],
  computed: {
    thingsCountClass() {
      if (this.article.things_count === 0) {
        return 'secondary';
      }
      return '';
    },
    hasManufacturerOrModell() {
      return (this.article.manufacturer != null || this.article.modell != null);
    },
    activeClass() {
      if (this.article.active === false) {
        return 'secondary';
      }
      return '';
    },
  },
};
</script>
