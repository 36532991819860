<template>
  <div>
    <div class="fx-control-bar">
      <button class="fx-btn-secondary" style="padding: .5rem .5rem .4rem .5rem; margin-right: .8rem;" @click.prevent="$emit('back','active')">
        <i class="fas fa-arrow-left" />
      </button>
      <h3 class="fx">
        Komponenten im Lager
      </h3>
      <div class="fx-control-bar-buttons">
        <drop-down classes="fx-btn-secondary">
          <drop-down-item>
            <a @click.prevent="$emit('back','active')"><i class="far fa-cube fa-fw" /> Aktive Komponenten ansehen</a>
          </drop-down-item>
          <drop-down-item>
            <a @click.prevent="$emit('back','outoforder')"><i class="far fa-recycle fa-fw" /> Außerbetriebnahme-Historie</a>
          </drop-down-item>
        </drop-down>
        <button
          v-tippy="{ placement: 'top', duration: 100, arrow: true }"
          class="fx-btn-secondary"
          title="Liste neu laden"
          @click.prevent="search()"
        >
          <i class="fas fa-sync-alt" />
        </button>
      </div>
    </div>
    <div class="fx-card group-header">
      <div style="background-color: rgb(242, 242, 242); padding: 1rem;">
        <input
          v-model="filter.query"
          type="text"
          style="margin: 0; width: 340px; display: inline-block;"
          placeholder="Nach Foxtag-ID oder Seriennummer suchen"
        >
        <a id="reset-search" style="margin-left: .5rem; font-size: .8rem; line-height: 2.4rem;" @click.prevent="resetSearch()">&nbsp;zurücksetzen</a>
      </div>

      <table id="stocked-things-table" class="material" style="table-layout:fixed;width:100%;">
        <tr v-if="localThings.length > 0" class="material__title">
          <th style="width: 15%">
            {{ tagLabel }}
          </th>
          <th style="width: 15%">
            Seriennummer
          </th>
          <th style="width: 20%">
            Typ
          </th>
          <th style="width: 23%">
            Details
          </th>
          <th class="no-print" style="text-align: right;">
&nbsp;
          </th>
        </tr>

        <tr v-for="thing in localThings" :key="thing.id">
          <td>{{ humanTagSerial(thing) }}</td>
          <td>{{ thing.serial_number }}</td>
          <td>
            <div class="fx_thing_type_with_icon">
              {{ thing.thing_type.name }}
              <div class="secondary">
                {{ thingMake(thing) }}
              </div>
            </div>
          </td>
          <td>
            <div>
              <span
                v-for="(feature, index) in thing.assigned_features"
                :key="index"
              ><span
                class="fx-label mb-2"
                style="background-color: #2C556E; color: #fff;"
              >{{ feature.symbol }}</span>&shy;
              </span>
            </div>
            <ThingDetails :thing="thing" :tag-label="tagLabel" :show-ids="false" />
          </td>
          <td style="text-align: right;">
            <div class="fx-btn-group">
              <a class="fx-btn-skeleton" :href="thingUrl(thing)">details</a>
              <drop-down>
                <drop-down-item>
                  <a @click.prevent="confirmDeleteThing(thing)">
                    <i class="far fa-trash-alt fa-fw" /> Komponente löschen</a>
                </drop-down-item>
              </drop-down>
            </div>
          </td>
        </tr>
        <tr>
          <td v-if="pagination.totalCount === 0" colspan="6" style="text-align: center;">
            Keine Komponenten gefunden
          </td>
        </tr>
      </table>
    </div>
    <Pagination
      v-model="pagination"
      i18n-key="activerecord.models.thing"
      :init-from-url="false"
      :update-url="false"
      @navigate="loadPage"
    />
  </div>
</template>

<script>

import _ from 'lodash';
import axios from 'axios';
import Flash from 'flash/index';

import ThingPresenter from 'mixins/thing_presenter';
import { ref, watch } from 'vue';
import EntityList from 'helpers/entity_list';
import confirmViaModal from 'helpers/confirm_modal';
import Pagination from 'components/pagination.vue';

import ThingDetails from 'components/partials/thing_details.vue';
import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';

export default {
  name: 'StockedList',
  components: {
    ThingDetails,
    DropDownItem,
    DropDown,
    Pagination,
  },
  mixins: [ThingPresenter],
  props: {
    installationId: {
      type: String,
      required: true,
    },
    tagLabel: {
      type: String,
      default: 'Foxtag-ID',
    },
    installationTypeName: {
      type: String,
      required: true,
    },
    installationTypeId: {
      type: String,
      required: true,
    },
  },
  emits: ['back'],
  setup(props) {
    const localThings = new EntityList();
    const filter = ref({
      query: null,
    });
    const pagination = ref({
      page: 1,
      pageCount: 1,
      totalCount: 0,
      limit: 25,
    });

    const load = () => {
      axios.get(`/installation_types/${props.installationTypeId}/stocked_things`, {
        params: {
          query: filter.value.query,
          page: pagination.value.page,
          limit: pagination.value.limit,
        },
      }).then((response) => {
        localThings.value = response.data.things;
        pagination.value.page = response.data.meta.current_page;
        pagination.value.pageCount = response.data.meta.total_pages;
        pagination.value.totalCount = response.data.meta.total_count;
      }).catch((err) => {
        if (err.message !== 'Request aborted') {
          Flash.error(err.message);
        }
      });
    };

    const loadPage = (changedPagination) => {
      pagination.value = { ...changedPagination };
      load();
    };

    const search = () => {
      localThings.value = [];
      pagination.value.page = 1;
      load();
    };

    const resetSearch = () => {
      filter.value = {
        query: null,
      };
    };

    const debouncedSearch = _.debounce(() => {
      search();
    }, 250);

    watch(filter, () => {
      debouncedSearch();
    }, { deep: true });

    const deleteViaAPI = (thing) => {
      axios.delete(`/things/${thing.id}`).then(() => {
        localThings.remove(thing);
        Flash.info('Komponente gelöscht');
      }).catch(() => {
        Flash.error('Komponente konnte nicht gelöscht werden');
      });
    };

    const confirmDeleteThing = (thing) => {
      confirmViaModal({
        title: 'Komponente löschen?',
        message: 'Löscht die Komponente und alle verbundenen Daten aus dem System.',
      }).then(() => {
        deleteViaAPI(thing);
      });
    };
    return {
      localThings: localThings.reactiveList,
      confirmDeleteThing,
      filter,
      pagination,
      loadPage,
      search,
      resetSearch,
    };
  },
  methods: {
    thingMake(thing) {
      return [thing.thing_type.manufacturer, thing.thing_type.model].join(' ');
    },
    thingUrl(thing) {
      return `/things/${thing.id}`;
    },
  },
};
</script>
