<template>
  <form @submit.prevent="enqueue">
    <div>
      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory">Wartungsanwendung</label>
        </div>
        <div class="fx-form-content">
          <DBBasedSelect
            v-model="installationTypeId"
            i18n-base-key="installation_types"
            load-options-url="/installation_types/for_select"
            icon-id="installation_type"
            @changed="onSelectInstallationType"
          />
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory">Jahr</label>
        </div>
        <div class="fx-form-content">
          <select v-model="year" style="width: 80px; display: inline-block;">
            <option v-for="(singleYear, index) in years()" :key="index" :value="singleYear">
              {{ singleYear }}
            </option>
          </select>
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory">Format</label>
        </div>
        <div class="fx-form-content">
          <input
              id="export_type_pdf"
              v-model="exporttype"
              name="export_type"
              type="radio"
              value="installation_type_year_progress"
            >
            <label for="export_type_pdf">PDF</label>

            <input
              id="export_type_csv"
              v-model="exporttype"
              name="export_type"
              type="radio"
              value="installation_type_year_progress_csv"
            >
            <label for="export_type_csv">CSV</label>
        </div>
      </div>

      <div class="fx-form-row">
        <div class="fx-form-content">
          <input
            type="submit"
            style="margin-bottom: 7px;"
            class="fx-btn-primary"
            value="Bericht erzeugen"
          >
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';

import DBBasedSelect from 'components/select/db_based_select.vue';

export default {
  name: 'EnqueueInstallationTypeYearProgress',
  components: {
    DBBasedSelect,
  },
  emits: ['enqueued'],
  data() {
    return {
      year: new Date().getFullYear(),
      installationTypeId: null,
      installationTypeOptions: [],
      exporttype: 'installation_type_year_progress',
    };
  },
  computed: {
  },
  methods: {
    years() {
      let year = new Date().getFullYear(); const
        years = [];
      while (year > 2015) {
        years.push(year);
        year -= 1;
      }
      return years;
    },
    onSelectInstallationType(installationTypeId) {
      this.installationTypeId = installationTypeId;
    },
    enqueue() {
      axios.post('/analysis/year_progress_installation_type_report', {
        exporttype: this.exporttype,
        installation_type_id: this.installationTypeId,
        year: this.year,
      }).then((response) => {
        this.$emit('enqueued', response.data.export_id);
      }).catch(() => {
        Flash.error('Bericht konnte nicht erzeugt werden');
      });
    },
  },
};
</script>
