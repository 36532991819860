<template>
  <div :class="previewEngineClass">
    <div class="repco_preview">
      <table class="preview">
        <tr>
          <th colspan="2" class="repco" :style="thStyle">
            Vorarbeiten
          </th>
        </tr>
        <tr>
          <td class="repco" style="width: 50%" :style="tdStyle">
            <table class="no-lines">
              <tr>
                <td class="repco_cl_title">
                  Leitstelle informiert:
                </td>
                <td>Ja</td>
              </tr>
              <tr>
                <td class="repco_cl_title">
                  Messwert:
                </td>
                <td>12 V</td>
              </tr>
            </table>
          </td>
          <td class="repco" style="width: 50%" :style="tdStyle">
            <table class="no-lines">
              <tr>
                <td class="repco_cl_title">
                  Weiteres Feld:
                </td>
                <td>Wert</td>
              </tr>
              <tr>
                <td class="repco_cl_title">
                  Weiteres Feld:
                </td>
                <td>Wert</td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <br><br>
      <table class="preview">
        <tr>
          <th colspan="2" class="repco" :style="thStyle">
            Nacharbeiten
          </th>
        </tr>
        <tr>
          <td class="repco" style="width: 50%" :style="tdStyle">
            <table class="no-lines">
              <tr>
                <td class="repco_cl_title">
                  Prüfbuch aktualisiert:
                </td>
                <td>Ja</td>
              </tr>
              <tr>
                <td class="repco_cl_title">
                  Anmerkungen:
                </td>
                <td>keine</td>
              </tr>
            </table>
          </td>
          <td class="repco" style="width: 50%" :style="tdStyle">
            <table class="no-lines">
              <tr>
                <td class="repco_cl_title">
                  Weiteres Feld:
                </td>
                <td>Wert</td>
              </tr>
              <tr>
                <td class="repco_cl_title">
                  Weiteres Feld:
                </td>
                <td>Wert</td>
              </tr>
            </table>
          </td>
        </tr>
      </table>&nbsp;
    </div>

    <Controls
      :index="index"
      :list-length="listLength"
      :name="name"
      :has-settings="false"
      :readonly="readonly"
      @settings="showForm = !showForm"
      @add="$emit('add')"
      @delete="$emit('delete')"
      @move-up="$emit('moveUp')"
      @move-down="$emit('moveDown')"
    />
  </div>
</template>

<script>
import Controls from './section_controls.vue';

export default {
  name: 'SectionChecklists',
  components: {
    Controls,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      default: 'Allgemeine Checklisten',
    },
    index: {
      type: Number,
      required: true,
    },
    listLength: {
      type: Number,
      required: true,
    },
    colors: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    layoutEngine: {
      type: String,
      default: 'foxtag_report',
    },
  },
  computed: {
    previewEngineClass() {
      return `preview-${this.layoutEngine}`;
    },
    thStyle() {
      if (this.layoutEngine == 'exp_report') {
        return `background-color: transparent; border-color: #${this.colors.color_lines}; border: 0; border-bottom: 2px solid #${this.colors.color_lines}`;
      }

      return `color: #${this.colors.color_col_headline_text}; background-color: #${this.colors.color_lines}; border: 2px solid #${this.colors.color_lines}`;
    },
    tdStyle() {
      if (this.layoutEngine == 'exp_report') {
        return `color: #${this.colors.color_text}; border: 0;`;
      }

      return `color: #${this.colors.color_text}; border: 2px solid #${this.colors.color_lines}`;
    },
    headlineStyle() {
      return `color: #${this.colors.color_headlines};`;
    },
  },
};
</script>
