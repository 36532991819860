<template>
  <div>
    <NavBreadcrumps path="administration/installation-templates" />

    <h1 class="fx">
      Vorlagen für Anlagen
    </h1>
    <p>
      Liste Ihrer Vorlagen, aus denen Sie auf Knopfdruck Anlagen erstellen können.
      <help-article article-id="learn_more_templates">
        Mehr erfahren
      </help-article>
    </p>

    <div class="fx-card">
      <table id="organizations-installation_templates-index" class="material">
        <colgroup>
          <col>
          <col>
          <col style="width: 180px;">
        </colgroup>

        <tr class="material__title">
          <th>Wartungsanwendung</th>
          <th>Name</th>
          <th class="controls_secondary">
            &nbsp;
          </th>
        </tr>

        <tr v-for="template in localTemplates" :key="template.id">
          <td>
            <Icon id="installation_type" /> {{ template.installation_type_name }}
          </td>
          <td>{{ template.name }}</td>
          <td style="text-align: right;">
            <ButtonDeleteIcon @click="confirmDeleteTemplate(template)" />
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';

import { ref } from 'vue';
import { useCurrentUserStore } from 'stores/current_user';
import Icon from 'components/icon.vue';

import NavBreadcrumps from 'components/nav_breadcrumps.vue';
import confirmViaModal from 'helpers/confirm_modal';
import ButtonDeleteIcon from 'components/button_delete_icon.vue';

export default {
  name: 'InstallationTemplatesIndex',
  components: {
    ButtonDeleteIcon,
    NavBreadcrumps,
    Icon,
  },
  props: {
    organizationId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const localTemplates = ref([]);

    const loadTemplates = () => {
      axios.get('installation_templates.json')
        .then((response) => {
          localTemplates.value = response.data.installation_templates;
        })
        .catch((error) => {
          Flash.error(error);
        });
    };

    const deleteTemplate = (template) => {
      axios.delete(`/installation_templates/${template.id}.json`)
        .then(() => {
          Flash.info('Vorlage gelöscht');
          loadTemplates();
        })
        .catch((error) => {
          Flash.error(error);
        });
    };

    const confirmDeleteTemplate = (template) => {
      confirmViaModal({
        title: `Vorlage '${template.name}' löschen?`,
        message: 'Diese Aktion kann nicht rückgängig gemacht werden.',
        requireInputConfirmation: false,
      }).then(() => {
        deleteTemplate(template);
      });
    };

    return {
      currentUser: useCurrentUserStore().currentUser,
      confirmDeleteTemplate,
      localTemplates,
      loadTemplates,
    };
  },
  computed: {
  },
  mounted() {
    this.loadTemplates();
  },
};
</script>
