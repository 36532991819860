<template>
  <section class="fx-page-content">
    <div style="background-color: #DEE5E9;">
      <div style="display: inline-block; padding: .3rem;">
        <span
          style="display: inline-block; background: white; height: 31px; padding: 2px 10px; border: 1px solid #c6c6c6; border-radius: 4px; margin: 2px;"
        >
          <nav class="letter-pagination">
            <span :class="filterClass(null)" class="all"><a @click.prevent="onFilterLetter(null)">{{
              $t('comp.filter.letter-filter.all') }}</a></span>
            <span :class="filterClass('123')" class="numbers"><a @click.prevent="onFilterLetter('123')">123</a></span>
            <span
              v-for="letter in letters"
              :ref="letter"
              :key="letter"
              :class="filterClass(letter)"
              class="letter"
            ><a @click.prevent="onFilterLetter(letter)">{{ letter }}</a></span>
          </nav>
        </span>

        <FilterFulltext
          v-model="filter.query"
          icon="filter"
          :placeholder="$t('comp.filter.customer-number-name.title')"
          width="250px"
          @changed="onFilterQuery"
        />

        <DBBasedFilter
          v-model="filter.labelId"
          i18n-base-key="labels"
          :default-label="$t('comp.filter.labels.default-label')"
          :allow-multi-select="true"
          load-options-url="/labels/for_select?label_type=customer"
          icon-id="label"
          @changed="onFilterLabel"
        />
      </div>
    </div>

    <div class="fx-grid">
      <div class="fx-col">
        <EmptyState
          v-if="showEmptyState"
          icon="fas fa-briefcase"
          title="Keine Kunden"
          hint="Legen Sie einen Kunden an, oder importieren Sie Daten aus einer Excel-Datei."
        >
          <div class="fx-btn-group">
            <button :disabled="!canCustomerNew" class="fx-btn-primary" @click.prevent="openNewCustomerModal()">
              Ersten
              Kunden anlegen
            </button>
            <a v-if="canCustomerNew" href="/imports/customers/new" class="fx-btn-secondary">Kunden importieren</a>
          </div>
        </EmptyState>
        <template v-if="!showEmptyState">
          <div style="display:flex; justify-content: space-between; margin-top: 2rem;">
            <h1 class="fx" style="flex: 1;">
              Kunden
            </h1>
            <div class="fx-btn-group">
              <button id="add-customer-btn" class="fx-btn-ico-primary" :disabled="!canCustomerNew" @click.prevent="openNewCustomerModal()">
                <i class="fas fa-plus" /> Kunden hinzufügen
              </button>

              <drop-down id="export-import-customer-btn" text="Import/Export" classes="fx-btn-secondary" :disabled="!canCustomerNew">
                <drop-down-item>
                  <a href="/imports/customers/new"><i class="far fa-file-import fa-fw" /> Kunden
                    importieren</a>
                </drop-down-item>
                <drop-down-item>
                  <a @click.prevent="openExportModalCustomers"><i class="far fa-file-export fa-fw" />
                    Kunden
                    exportieren</a>
                </drop-down-item>
              </drop-down>

              <drop-down id="more-options-btn" classes="fx-btn-secondary" :disabled="!canCustomerNew">
                <drop-down-item>
                  <a @click.prevent="confirmDeleteUnusedCustomers"><i class="far fa-trash-alt fa-fw" />
                    Kunden ohne Objekte löschen</a>
                </drop-down-item>
              </drop-down>
            </div>
          </div>

          <div v-if="loaded" class="fx-card">
            <div
              class="fx-list-row"
              style="background-color: #f2f2f2; padding: .8rem 1rem; color: #888; font-size: 1rem;"
            >
              <template v-if="filterInfo">
                {{ filterInfo }}
                <a
                  v-tippy="{ content: 'Filter zurücksetzen', placement: 'top', duration: 100, delay: [300, null], arrow: true }"
                  class="m-4 no-color"
                  @click="resetSearch()"
                ><i
                  class="fas fa-times-circle"
                /></a>
              </template>
              <template v-else>
                Alle Kunden
              </template>
            </div>
            <ListItem
              v-for="customer in localCustomers"
              :key="customer.id"
              :customer="customer"
              :can-customer-delete="canCustomerNew"
              @open-confirm-delete="confirmDeleteCustomer"
            />
            <EmptyState v-if="localCustomers.length === 0" :title="noResultsTitle" :hint="noResultsHint" :no-border="true">
              <button v-if="filterApplied" class="fx-btn-secondary" @click="resetSearch()">
                <i
                  class="fas fa-times-circle"
                />&nbsp;&nbsp;Filter
                zurücksetzen
              </button>
            </EmptyState>
          </div>
          <Pagination
            v-model="pagination"
            i18n-key="activerecord.models.customer"
            :update-url="false"
            :init-from-url="false"
            @navigate="loadPage"
          />
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { ref, computed } from 'vue';
import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';
import { useCurrentUserStore } from 'stores/current_user';
import openExportModal from 'helpers/open_export_modal';
import confirmViaModal from 'helpers/confirm_modal';
import Flash from 'flash/index';

import FilterInfo from 'mixins/filter_info';

import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';

import EmptyState from 'components/empty_state.vue';
import FilterFulltext from 'components/filter/filter_fulltext.vue';
import DBBasedFilter from 'components/filter/db_based_filter.vue';

import Pagination from 'components/pagination.vue';

import NewCustomerModal from './new_customer_modal.vue';
import ListItem from './list_item.vue';

export default {
  name: 'CustomerList',
  components: {
    ListItem,
    Pagination,
    DropDown,
    DropDownItem,
    EmptyState,
    FilterFulltext,
    DBBasedFilter,
  },
  mixins: [FilterInfo],
  props: {
    canCustomerNew: {
      type: Boolean,
    },
    initLetterCounts: {
      type: Object,
      required: true,
    },
    startShowEmptyState: {
      type: Boolean,
      default: false,
    },
    noResultsTitle: {
      type: String,
      default: 'Keine Kunden gefunden',
    },
    noResultsHint: {
      type: String,
      default: 'Mit den aktuellen Filtereinstellungen wurden keine Kunden gefunden',
    },
    loadFlag: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const userStore = useCurrentUserStore();
    const pagination = ref({ ...userStore.pagination('customers') });
    const filter = ref({ ...userStore.filter('customers') });
    const defaultFilter = {
      labelId: null,
      letter: null,
      query: null,
    };

    const localCustomers = ref([]);
    const letterCounts = ref({});
    const showEmptyState = ref(false);

    const loaded = ref(false);

    const findIndexInList = (id) => _.findIndex(localCustomers.value, { id });

    const load = () => {
      loaded.value = false;
      axios.get('/customers.json', {
        params: {
          letter: filter.value.letter,
          query: filter.value.query,
          label: filter.value.labelId,
          page: pagination.value.page,
          limit: pagination.value.limit,
        },
      }).then((response) => {
        localCustomers.value = response.data.customers;
        pagination.value.page = response.data.meta.current_page;
        pagination.value.pageCount = response.data.meta.total_pages;
        pagination.value.totalCount = response.data.meta.total_count;
        pagination.value.limit = response.data.meta.limit;

        userStore.updatePaginationFromMeta('customers', response.data.meta);
        userStore.updateFilter('customers', filter.value);
        loaded.value = true;
      }).catch((err) => {
        if (err.message !== 'Request aborted') {
          Flash.error(err.message);
        }
      });
    };

    const loadPage = (changedPagination) => {
      pagination.value = { ...changedPagination };
      load();
    };

    const search = () => {
      localCustomers.value = [];
      pagination.value.page = 1;
      load();
    };

    const debouncedSearch = _.debounce(() => {
      search();
    }, 250);

    const onFilterQuery = (query) => {
      filter.value.query = query;
      debouncedSearch();
    };

    const onFilterLabel = (labelId) => {
      filter.value.labelId = labelId;
      debouncedSearch();
    };

    const onFilterLetter = (letter) => {
      filter.value.letter = letter;
      debouncedSearch();
    };

    const resetSearch = () => {
      filter.value = { ...defaultFilter };
      debouncedSearch();
    };

    const customerAdded = (customer) => {
      showEmptyState.value = false; // just in case empty state is shown
      const letter = customer.name.charAt(0).toUpperCase();
      letterCounts.value[letter] = 1;
      onFilterLetter(letter);
      Flash.info('Kunde hinzugefügt');
    };

    const deleteUnusedViaApi = () => {
      axios.delete('/customers/destroy_all_unassigned.json').then(() => {
        search();
        Flash.info('Kunden gelöscht');
      });
    };

    const deleteViaApi = (customer) => {
      axios.delete(`/customers/${customer.id}.json`).then(() => {
        localCustomers.value.splice(findIndexInList(customer.id), 1);
        Flash.info('Kunde gelöscht');
      });
    };

    // -- Computed

    const filterApplied = computed(() => {
      let filtered = false;

      Object.keys(defaultFilter).forEach((key) => {
        if (filter.value[key] !== defaultFilter[key]) {
          filtered = true;
        }
      });

      return filtered;
    });

    // --- MODALS

    const openExportModalCustomers = () => openExportModal({
      title: 'Kunden-Export',
      exporttype: 'customers',
      config: filter,
    });

    const openNewCustomerModal = () => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: NewCustomerModal,
            attrs: {
              onClose() {
                modalInstance.close();
              },
              onCreated(xCustomer) {
                customerAdded(xCustomer);
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const confirmDeleteUnusedCustomers = () => {
      confirmViaModal({
        title: 'Alle Kunden ohne Objekte löschen?',
        message: 'Diese Aktion löscht alle Kunden, denen keine Objekte zugeordnet sind.',
      }).then(() => {
        deleteUnusedViaApi();
      });
    };

    const confirmDeleteCustomer = (customer) => {
      confirmViaModal({
        title: `Kunden ${customer.number} löschen?`,
      }).then(() => {
        deleteViaApi(customer);
      });
    };

    return {
      pagination,
      filter,
      filterApplied,
      load,
      loadPage,
      loaded,
      resetSearch,
      letterCounts,
      localCustomers,
      showEmptyState,
      openExportModalCustomers,
      openNewCustomerModal,
      confirmDeleteUnusedCustomers,
      confirmDeleteCustomer,
      onFilterQuery,
      onFilterLabel,
      onFilterLetter,
    };
  },
  data() {
    return {
      letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
    };
  },
  mounted() {
    this.letterCounts = this.initLetterCounts;
    this.showEmptyState = this.startShowEmptyState;
  },
  methods: {
    filterClass(letter) {
      if (this.filter.letter == null && letter == null) {
        return 'current';
      }
      if (this.filter.letter === letter) {
        return 'current';
      }

      if (letter != null && this.letterCounts[letter] == null) {
        return 'empty';
      }

      return '';
    },
  },
};
</script>
