<template>
  <div>
    <section class="fx-page-content">
      <section class="fx-header">
        <div class="fx-grid">
          <div class="fx-col" style="padding: 0 1rem;">
            <ul class="fx-breadcrumps">
              <li><a href="/start">Start</a></li>
              <li>{{ breadcrumpLabel }}</li>
            </ul>

            <div class="fx-header-title">
              <h1 class="fx-header-title">
                <span class="fx-header-title_number">{{ location.number }}</span>
                <span class="fx-header-title_name">{{ activeName }}</span>
              </h1>
            </div>

            <button :class="$tabClasses('overview')" @click="$setActiveTab('overview')">
              <i class="fas fa-building" /> Objektdaten
            </button>
            <button :class="$tabClasses('installations')" @click="$setActiveTab('installations')">
              <i class="fas fa-sitemap" /> Anlagen
              <span class="fx-tab-count">{{ location.installations_count }}</span>
            </button>
          </div>
        </div>
      </section>

      <section class="list-content">
        <div class="fx-grid" style="margin-top: 0rem;">
          <div class="fx-col">
            <div v-if="$isTabInitialized('overview')" v-show="$isTabActive('overview')" class="tab-panel">
              <div class="fx-card" style="max-width: 650px;">
                <table class="fx-card">
                  <tr>
                    <th>Kunde</th>
                    <td colspan="2">
                      <Labels
                        v-if="location.customer"
                        :labels="location.customer.labels"
                        :link="location.customer.link"
                      />
                      <Customer :customer="location.customer" :link="true" />
                    </td>
                  </tr>
                </table>
              </div>

              <!-- do only show form if location is initialized -->
              <div v-if="location.id" class="fx-card" style="max-width: 650px;">
                <div class="fx-card-content">
                  <h2 class="fx">
                    Objektdaten
                  </h2>
                  <ServerErrors
                    v-model="serverErrors"
                    title="Objekt konnte nicht gespeichert werden"
                    margin-bottom="1rem"
                  />

                  <div class="fx-form-row">
                    <div class="fx-form-label">
                      <label class="inline" for="customer">Kunde</label>
                    </div>
                    <div class="fx-form-content">
                      <DBBasedSelect
                        v-model="selectedCustomerId"
                        icon-id="customer"
                        i18n-base-key="customers"
                        :default-label="$t('comp.select.no-selection')"
                        load-options-url="/customers/for_select"
                        @changed="onCustomerSelected"
                      />
                    </div>
                  </div>

                  <div class="fx-form-row">
                    <div class="fx-form-label">
                      <label class="inline" for="location_number">Objektnummer</label>
                    </div>
                    <div class="fx-form-content">
                      <input
                        id="location_number"
                        v-model="location.number"
                        name="location_number"
                        type="text"
                      >
                    </div>
                  </div>
                  <div class="fx-form-row">
                    <div class="fx-form-label">
                      <label class="inline" for="location_name">Name</label>
                    </div>
                    <div class="fx-form-content">
                      <input
                        id="location_name"
                        v-model="location.name"
                        name="location_name"
                        type="text"
                      >
                    </div>
                  </div>

                  <div class="fx-form-row">
                    <div class="fx-form-label">
                      <label class="inline" for="location_time_zone">Zeitzone</label>
                    </div>
                    <div class="fx-form-content">
                      <SelectTimezone v-model="location.time_zone" />
                    </div>
                  </div>

                  <hr>

                  <div class="fx-form-row">
                    <div class="fx-form-content">
                      <input
                        id="shares_customer_address_true"
                        v-model="location.shares_customer_address"
                        type="radio"
                        :value="true"
                      >
                      <label for="shares_customer_address_true">Objektadresse ist Kundenadresse</label>
                      <br>
                      <input
                        id="shares_customer_address_false"
                        v-model="location.shares_customer_address"
                        type="radio"
                        :value="false"
                      >
                      <label for="shares_customer_address_false">Objekt hat eigene Adresse</label>
                    </div>
                  </div>

                  <template v-if="!location.shares_customer_address && localAddress">
                    <AddressForm v-model="localAddress" />
                    <hr>
                    <div class="fx-form-row">
                      <div class="fx-form-label">
                        <label class="inline">Koordinaten</label>
                      </div>
                      <div class="fx-form-content">
                        <input
                          id="custom_coordinates_false"
                          v-model="localAddress.custom_coordinates"
                          type="radio"
                          :value="false"
                        >
                        <label for="custom_coordinates_false">Automatisch ermitteln</label>
                        <br>
                        <input
                          id="custom_coordinates_true"
                          v-model="localAddress.custom_coordinates"
                          type="radio"
                          :value="true"
                        >
                        <label for="custom_coordinates_true">Eigene Koordinaten</label>
                      </div>
                    </div>

                    <div v-if="!localAddress.custom_coordinates" class="fx-form-row">
                      <div class="fx-form-content secondary" style="font-size: .825rem;">
                        <div v-if="localAddress.geocoding_status === 'geocoded'">
                          <i class="fas fa-map-marker-alt" style="margin-right: .2rem;" />
                          {{ $t('geocoding.address_is_geocoded') }}
                        </div>
                        <div v-if="localAddress.geocoding_status === 'failed'">
                          <i class="fas fa-ban" style="margin-right: .2rem;" />
                          {{ $t('geocoding.failed') }}
                        </div>
                        <div v-if="localAddress.geocoding_status === 'not_qualified'">
                          <i class="fas fa-ban" style="margin-right: .2rem;" />
                          {{ $t('geocoding.address_does_not_qualify_for_geocoding') }}
                        </div>
                        <div v-if="localAddress.geocoding_status === 'ongoing'">
                          <i class="fas fa-ban" style="margin-right: .2rem;" /> {{ $t('geocoding.not_yet_geocoded') }}
                        </div>
                      </div>
                    </div>

                    <template v-if="localAddress.custom_coordinates">
                      <div class="fx-form-row">
                        <div class="fx-form-content">
                          <help-article article-id="custom_coordinates">
                            Hilfe zu eigenen Koordinaten
                          </help-article>
                        </div>
                      </div>
                      <div class="fx-form-row">
                        <div class="fx-form-label">
                          <label class="inline">Breitengrad (LAT)</label>
                        </div>
                        <div class="fx-form-content">
                          <input
                            id="address_lat"
                            v-model="localAddress.lat"
                            name="address_lat"
                            type="text"
                          >
                          <div class="form-field-hint">
                            Bitte als Fließkommazahl eingeben, bspw. 53.556556
                          </div>
                        </div>
                      </div>

                      <div class="fx-form-row">
                        <div class="fx-form-label">
                          <label class="inline">Längengrad (LON)</label>
                        </div>
                        <div class="fx-form-content">
                          <input
                            id="address_lng"
                            v-model="localAddress.lng"
                            name="address_lng"
                            type="text"
                          >
                          <div class="form-field-hint">
                            Bitte als Fließkommazahl eingeben, bspw. 10.014156
                          </div>
                        </div>
                      </div>
                    </template>
                  </template>

                  <div class="fx-form-row">
                    <div class="fx-form-content">
                      <TransactionButton
                        v-if="canEdit"
                        v-model="savingLocation"
                        class="fx-btn-primary"
                        title="Objektdaten speichern"
                        @click="updateLocationDetails"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="canEdit" class="fx-card" style="max-width: 650px;">
                <div class="fx-card-content">
                  <h2 class="fx">
                    Objekt löschen
                  </h2>
                  <button
                    v-if="location.installations_count === 0"
                    class="fx-btn-primary"
                    @click="confirmDeleteLocation(location)"
                  >
                    Objekt löschen
                  </button>
                  <div v-if="location.installations_count > 0" class="secondary">
                    Sie können ein Objekt nur löschen,
                    wenn Sie vorher alle Anlagen gelöscht haben.
                  </div>
                </div>
              </div>
            </div>

            <div v-if="$isTabInitialized('installations')" v-show="$isTabActive('installations')" class="tab-panel">
              <button
                v-if="canEdit"
                class="fx-btn-primary"
                style="float: right"
                @click="openAddInstallationModal"
              >
                <i
                  class="fas fa-plus"
                /> Anlage hinzufügen
              </button>
              <br clear="both">
              <div class="fx-card">
                <InstallationRow
                  v-for="installation in localInstallations"
                  :key="installation.id"
                  :installation="installation"
                  :has-incidents-pro="hasIncidentsPro"
                  @quick-view="openJobQuickView"
                  @changed="loadInstallations()"
                />

                <EmptyState
                  v-if="loaded && !localInstallations.length"
                  title="Keine Anlagen"
                  hint="Keine Anlagen in diesem Objekt"
                />
              </div>

              <Pagination v-model="pagination" i18n-key="activerecord.models.installation" @navigate="loadPage" />
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';
import { ref, computed, onMounted, getCurrentInstance } from 'vue';
import confirmViaModal from 'helpers/confirm_modal';
import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';
import { jobQuickView } from 'helpers/quickviews';

import EmptyState from 'components/empty_state.vue';
import Customer from 'components/partials/customer.vue';
import DBBasedSelect from 'components/select/db_based_select.vue';
import SelectTimezone from 'components/select/select_timezone.vue';
import AddressForm from 'components/address_form.vue';
import TransactionButton from 'components/transaction_button.vue';
import ServerErrors from 'components/partials/server_errors.vue';
import Labels from 'components/partials/labels.vue';

import Pagination from 'components/pagination.vue';
import InstallationRow from '../index/installation_row.vue';
import AddInstallationModal from '../index/modals/add_installation_modal.vue';

export default {
  name: 'LocationShow',
  components: {
    DBBasedSelect,
    AddressForm,
    TransactionButton,
    Customer,
    InstallationRow,
    EmptyState,
    SelectTimezone,
    ServerErrors,
    Labels,
    Pagination,
  },
  props: {
    locationId: {
      type: String,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    hasIncidentsPro: {
      type: Boolean,
      default: true,
    },
    rowLimit: {
      type: Number,
      default: 10,
    },
  },
  setup(props) {
    const location = ref({});
    const localAddress = ref({});
    const localInstallations = ref([]);
    const initializedTabs = ref(new Set());
    const selectedCustomerId = ref(null);
    const serverErrors = ref([]);
    const loaded = ref(false);
    const savingLocation = ref(false);
    const activeTab = ref(null);
    const pagination = ref({
      page: 1,
      pageCount: 1,
      totalCount: 0,
      limit: 25,
    });

    const updateLocalAddress = () => {
      if (location.value.shares_customer_address) {
        localAddress.value = {
          line1: null,
          line2: null,
          line3: null,
          city: null,
          country: null,
          lat: null,
          lng: null,
          custom_coordinates: false,
        };
      } else {
        localAddress.value = location.value.address;
      }
    };

    const loadLocation = () => {
      axios.get(`/locations/${props.locationId}`).then((response) => {
        location.value = response.data.location;
        if (location.value.customer != null) {
          selectedCustomerId.value = location.value.customer.id;
        }
        updateLocalAddress();
      }).catch(() => {
        Flash.error('Object konnte nicht geladen werden');
      });
    };

    const loadInstallations = () => {
      loaded.value = false;
      axios.get('/installations/filtered.json', {
        params: {
          location_id: props.locationId,
          limit: pagination.value.limit,
          page: pagination.value.page,
        },
      }).then((response) => {
        localInstallations.value = response.data.installations;
        location.value.installations_count = response.data.meta.total_count;

        pagination.value.page = response.data.meta.current_page;
        pagination.value.pageCount = response.data.meta.total_pages;
        pagination.value.totalCount = response.data.meta.total_count;

        loaded.value = true;
      }).catch((err) => {
        if (err.message !== 'Request aborted') {
          Flash.error(err.message);
        }
      });
    };

    const updateLocationDetails = () => {
      serverErrors.value = [];
      savingLocation.value = true;

      const customCoordinates = localAddress.value.custom_coordinates;

      axios.put(`/locations/${location.value.id}.json`, {
        location: {
          // axios does not send params with null values, so we need to explicitly send an empty string to remove customer
          customer_id: selectedCustomerId.value || '',
          number: location.value.number,
          name: location.value.name,
          shares_customer_address: location.value.shares_customer_address,
          time_zone: location.value.time_zone,
          address_attributes: {
            line1: localAddress.value.line1,
            line2: localAddress.value.line2,
            zip: localAddress.value.zip,
            city: localAddress.value.city,
            country: localAddress.value.country,
            custom_coordinates: customCoordinates,
            lat: (customCoordinates ? localAddress.value.lat : null),
            lng: (customCoordinates ? localAddress.value.lng : null),
          },
        },
      }).then((response) => {
        location.value = response.data.location;
        updateLocalAddress();

        Flash.info('Objektdaten gespeichert');
        savingLocation.value = false;
      }).catch((error) => {
        if (error.response.status === 400) {
          serverErrors.value = error.response.data.locations;
        } else {
          Flash.error('Objekt konnte nicht gespeichert werden');
        }
        savingLocation.value = false;
      });
    };

    const loadPage = (changedPagination) => {
      pagination.value = { ...changedPagination };
      loadInstallations();
    };

    const onCustomerSelected = (customerId) => {
      selectedCustomerId.value = customerId;
    };

    const deleteViaApi = (xLocation) => {
      axios.delete(`/locations/${xLocation.id}.json`).then(() => {
        Flash.info('Objekt gelöscht');
        window.location.href = '/locations';
      }).catch(() => {
        Flash.error('Objekt konnte nicht gelöscht werden');
      });
    };

    const confirmDeleteLocation = (xLocation) => {
      confirmViaModal({
        title: `Objekt ${location.value.number || ''} löschen?`,
        message: 'Diese Aktion kann nicht rückgängig gemacht werden.',
      }).then(() => {
        deleteViaApi(xLocation);
      });
    };

    const openAddInstallationModal = () => {
      let customerId = null;
      if (location.value.customer) { // customer is optional for locations
        customerId = location.value.customer.id;
      }

      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: AddInstallationModal,
            attrs: {
              customerId,
              locationId: location.value.id,
              onClose() {
                modalInstance.close();
              },
              onCreated() {
                loadInstallations();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openJobQuickView = (jobId) => {
      jobQuickView(jobId);
    };

    const activeName = computed(() => {
      const name = location.value.name;
      if ((name == null || name.length < 1) && location.value.customer != null) {
        return location.value.customer.name;
      }
      return name;
    });

    const breadcrumpLabel = computed(() => {
      if (location.value.number == null || location.value.number.length < 1) {
        return activeName;
      }
      return location.value.number;
    });

    onMounted(() => {
      loadLocation();
      getCurrentInstance().proxy.$setActiveTabFromURL('overview');
    });

    return {
      location,
      localAddress,
      selectedCustomerId,
      localInstallations,
      initializedTabs,
      serverErrors,
      loaded,
      pagination,
      savingLocation,
      activeTab,

      activeName,
      breadcrumpLabel,

      onCustomerSelected,
      confirmDeleteLocation,
      openAddInstallationModal,
      openJobQuickView,
      loadPage,
      loadInstallations,
      updateLocationDetails,
    };
  },
};
</script>
