<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <h2 class="fx">
      Verknüpfungen von {{ truncate(contact.name, 30) }}
    </h2>
    <hr>
    <div
      v-if="!customers.length && !installations_as_contact.length && !installations_as_report_recipient.length"
      class="secondary"
    >
      Keine Verknüpfungen
    </div>

    <div style="max-height: 800px; overflow-y: auto;">
      <table class="material">
        <tr v-for="customer in customers" :key="customer.id">
          <td style="width: 120px; padding-left: 0;">
            Kunde
          </td>
          <td>
            <i class="fas fa-briefcase fa-fw fx-inline-ico" /> <a
              :href="`/customers/${customer.id}`"
              class="no-color"
            >
              <strong>{{ customer.number }}</strong> {{ customer.name }}</a>
          </td>
        </tr>
        <tr v-for="installation in installations_as_contact" :key="installation.id">
          <td style="width: 120px; padding-left: 0;">
            Ansprechpartner
          </td>
          <td>
            <i class="fas fa-sitemap fa-fw fx-inline-ico" /> <a
              :href="`/installations/${installation.id}`"
              class="no-color"
            ><strong>{{ installation.number }}</strong> {{ installation.name }}</a>
          </td>
        </tr>
        <tr v-for="installation in installations_as_report_recipient" :key="installation.id">
          <td style="width: 120px; padding-left: 0;">
            Berichtsempfänger
          </td>
          <td>
            <i class="far fa-file-export fa-fw fx-inline-ico" /> <a
              :href="`/installations/${installation.id}#tab-sharing`"
              class="no-color"
            ><strong>{{ installation.number
            }}</strong> {{ installation.name }}</a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import { TruncateFormatter } from 'mixins/formatters';

export default {
  name: 'ReferencesModal',
  mixins: [TruncateFormatter],
  props: {
    contact: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      customers: [],
      installations_as_contact: [],
      installations_as_report_recipient: [],
      serverErrors: [],
    };
  },
  mounted() {
    const that = this;
    axios.get(`/contacts/${this.contact.id}/references.json`).then((response) => {
      that.customers = response.data.customers;
      that.installations_as_contact = response.data.installations_as_contact;
      that.installations_as_report_recipient = response.data.installations_as_report_recipient;
    }).catch(() => {
    });
  },
};
</script>
