<template>
  <div v-if="localInstallation">
    <LinkAssign
      v-if="currentUser.pro"
      :href="localInstallation.link"
      linkable-type="installation"
      :linkable-id="localInstallation.id"
    />
    <LabelsAssign
      :preset-labels="presetLabels"
      :lablable-id="localInstallation.id"
      :can-edit="canEdit"
      :link="localInstallation.link"
    />

    <div class="fx-card" style="max-width: 750px;">
      <table class="material top-align">
        <tbody>
          <tr>
            <th>Kunde</th>
            <td colspan="2">
              <Labels v-if="customer" :labels="customer.labels" :link="customer.link" />
              <Customer :customer="customer" :link="true" />
            </td>
          </tr>
          <tr>
            <th>Objekt</th>
            <td colspan="2">
              <Location :location="localInstallation.location" :link="true" />
            </td>
          </tr>
          <tr>
            <th>Wartungsanwendung</th>
            <td>
              {{ installationType.name }}
            </td>
            <td v-if="canEdit" style="text-align: right;">
              <a :href="`/installation_types/${installationType.id}`"><i class="fas fa-cog" /> Anwendung
                konfigurieren</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="fx-card fx-card-content" style="max-width: 750px;">
      <form>
        <ServerErrors v-model="serverErrors" title="Anlage konnte nicht gespeichert werden" margin-bottom="1rem" />
        <div class="fx_form_boundary">
          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline" for="installation_number">Anlagennummer</label>
            </div>
            <div class="fx-form-content">
              <input
                id="installation_number"
                v-model="localInstallation.number"
                type="text"
                placeholder="optional"
                class="has-form-field-hint"
                :disabled="!canEdit"
                name="installation[number]"
              >
            </div>
          </div>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline " for="installation_name">Name</label>
            </div>
            <div class="fx-form-content">
              <input
                id="installation_name"
                v-model="localInstallation.name"
                type="text"
                placeholder="optional"
                class="has-form-field-hint"
                :disabled="!canEdit"
                name="installation[name]"
              >
            </div>
          </div>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline " for="installation_assigned_user_id">Techniker</label>
            </div>
            <div class="fx-form-help">
              <HelpHover content="installation_technician" />
            </div>

            <div class="fx-form-content">
              <select v-model="localInstallation.assigned_user_id" class="fx-focus" :disabled="!canEdit">
                <option value="" />
                <option
                  v-for="technician in localInstallation.assignable_technicians"
                  :key="technician.id"
                  :value="technician.id"
                >
                  {{ technician.label }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="fx-form-row">
          <div class="fx-form-label">
            <label class="inline " for="installation_note">Hinweise</label>
          </div>
          <div class="fx-form-content">
            <textarea
              id="installation_note"
              v-model="localInstallation.note"
              rows="5"
              :disabled="!canEdit"
              class="has-form-field-hint"
              name="installation[note]"
            />
            <div class="form-field-hint">
              Hinweise für den Techniker vor Ort
            </div>
          </div>
        </div>

        <div v-if="canEdit" class="fx-form-row" style="margin-bottom: 0">
          <div class="fx-form-content">
            <div style="margin-bottom: 1rem;">
              <input
                type="submit"
                name="commit"
                value="Daten aktualisieren"
                class="fx-btn-primary"
                @click.prevent="updateInstallationViaAPI()"
              >
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="fx-card fx-card-content" style="max-width: 750px;">
      <h2 class="fx">
        Ansprechpartner vor Ort
      </h2>
      <p>Ansprechpartner werden Ihnen in der App angezeigt</p>

      <EmbeddedContactList
        :init-contacts="localInstallation.contacts"
        :add-installation-id="localInstallation.id"
        :remove-installation-id="localInstallation.id"
        :show-top-add-button="false"
        :show-bottom-add-button="true"
        :show-empty-state="false"
        list-classes="grid-2-col"
      />
    </div>

    <div class="fx-card fx-card-content" style="max-width: 750px;">
      <h2 class="fx">
        Weitere Einstellungen
      </h2>

      <DropdownSection v-if="features.integrations" title="Integrationen" :enabled="canEdit">
        <Integrations :installation-id="localInstallation.id" />
      </DropdownSection>

      <DropdownSection v-if="featureCalendricIntervals" :enabled="canEdit" title="Kalendarische Prüfintervalle">
        <p>
          Wenn Sie kalendarische Intervalle verwenden, können Sie für diese Anlage ein eigenes Startdatum wählen,
          das vom Startdatum des Intervalls abweicht.
        </p>
        <div class="fx_form_boundary">
          <form>
            <div class="fx-form-row">
              <div class="fx-form-label">
                <label class="inline" for="interval-root">Intervall-Startdatum</label>
              </div>
              <div class="fx-form-content">
                <VueDatePicker
                  v-model="localInstallation.calendric_interval_root_at"
                  :locale="$i18n.locale"
                  :auto-apply="true"
                  model-type="yyyy-MM-dd"
                  :enable-time-picker="false"
                  :format="'dd.MM.yyyy'"
                />
                <div class="form-field-hint">
                  <i class="far fa-clock" />
                  Das Aktualisieren der Anlagenfälligkeit kann einige Minuten dauern
                </div>
              </div>
            </div>
            <div v-if="canEdit" class="fx-form-row">
              <div class="fx-form-content">
                <button class="fx-btn-primary" @click.prevent="updateCalendricRootViaAPI()">
                  Startdatum
                  aktualisieren
                </button>
              </div>
            </div>
          </form>
        </div>
      </DropdownSection>

      <DropdownSection v-if="!localInstallation.passive" title="Anlage passiv schalten" :enabled="canEdit">
        <p>
          Wenn Sie die Anlage passiv schalten, wird diese nicht weiter bei der Berechnung von Fälligkeiten
          berücksichtigt.
        </p>
        <button class="fx-btn-primary" @click="deactivateViaAPI()">
          Anlage passiv schalten
        </button>
      </DropdownSection>

      <DropdownSection v-if="localInstallation.passive" title="Passive Anlage reaktivieren" :enabled="canEdit">
        <p>Die Anlage ist aktuell passiv und wird deshalb nicht bei der Berechnung von Fälligkeiten berücksichtigt.</p>
        <button class="fx-btn-primary" @click="activateViaAPI()">
          Anlage reaktivieren
        </button>
      </DropdownSection>

      <DropdownSection title="Anlage verschieben" :enabled="canEdit">
        <p>Geben Sie zum Verschieben der Anlage die Objektnummer des neuen Objekts an.</p>

        <DBBasedSelect
          v-model="moveToLocationId"
          icon-id="location"
          i18n-base-key="locations"
          :default-label="$t('comp.select.no-selection')"
          load-options-url="/select/locations"
          :readonly="false"
          @changed="(selectedLocationId) => {moveToLocationId = selectedLocationId;}"
        />

        <input id="delete-empty-location" v-model="deleteEmptyLocation" type="checkbox">
        <label for="delete-empty-location">Aktuelles Objekt nach dem Verschieben löschen, falls es keine weitere Anlage
          enthält.</label>
        <br>
        <button
          :disabled="moveToLocationId == null"
          class="fx-btn-primary"
          style="margin-top: .5rem;"
          @click="moveToViaAPI()"
        >
          Anlage verschieben
        </button>
      </DropdownSection>

      <DropdownSection title="Anlage löschen" :enabled="canEdit">
        <p>Diese Aktion löscht die Anlage und alle historischen Daten wie Aufträge und deren Berichte.</p>

        <div v-if="hasOpenJobs" class="callout callout--info">
          Sie können diese Anlage nicht löschen, da es noch geplante oder laufende Aufträge gibt.
          Beenden Sie zuerst alle Aufträge, oder lassen sie die Aufträge entfallen.
        </div>

        <button
          v-if="!hasOpenJobs"
          class="fx-btn-primary fx-btn-red"
          @click="confirmDeleteInstallation(localInstallation)"
        >
          Anlage löschen
        </button>
      </DropdownSection>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useCurrentUserStore } from 'stores/current_user';
import confirmViaModal from 'helpers/confirm_modal';
import waitForAsyncModal from 'helpers/wait_for_async_modal';

import Flash from 'flash/index';
import FoxtagPaths from 'mixins/foxtag_paths';
import LabelsAssign from 'apps/labels/assign/index.vue';
import Location from 'components/partials/location.vue';
import Customer from 'components/partials/customer.vue';

import ServerErrors from 'components/partials/server_errors.vue';
import Labels from 'components/partials/labels.vue';
import DBBasedSelect from 'components/select/db_based_select.vue';
import LinkAssign from 'components/link_assign.vue';

import HelpHover from 'components/help_hover.vue';
import FeatureFlipper from 'mixins/feature_flipper';
import EmbeddedContactList from 'apps/contacts/embedded_list.vue';
import DropdownSection from 'components/dropdown_section.vue';

import Integrations from 'apps/installations/show/integrations.vue';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: 'OverviewTab',
  components: {
    LabelsAssign,
    Location,
    Customer,
    ServerErrors,
    DBBasedSelect,
    HelpHover,
    Labels,
    EmbeddedContactList,
    DropdownSection,
    Integrations,
    VueDatePicker,
    LinkAssign,
  },
  mixins: [FoxtagPaths, FeatureFlipper],
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    customer: {
      type: Object,
      default: null,
    },
    installationType: {
      type: Object,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    presetLabels: {
      type: Array,
      required: true,
    },
    hasOpenJobs: {
      type: Boolean,
      required: true,
    },
    featureCalendricIntervals: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['changed'],
  setup() {
    const waitForInstallationDeleted = (sidekiqJobId) => {
      waitForAsyncModal({
        title: 'Anlage wird gelöscht ...',
        message: 'Diese Aktion kann, je nach Größe der Anlage, einige Minuten dauern.',
        sidekiqJobId,
      }).then(() => {
        window.location.href = '/locations';
      });
    };

    const deleteInstallationViaAPI = (installation) => {
      axios.delete(`/installations/${installation.id}`).then((response) => {
        waitForInstallationDeleted(response.data.sidekiq_job_id);
      }).catch(() => {
        Flash.error('Anlage konnte nicht gelöscht werden');
      });
    };

    const confirmDeleteInstallation = (installation) => {
      confirmViaModal({
        title: `Anlage ${installation.number || installation.name || ''} löschen?`,
        message: 'Es werden auch alle Aufträge, Störungen und die dazugehörigen Berichte und Dokumente gelöscht!',
        requireInputConfirmation: true,
      }).then(() => {
        deleteInstallationViaAPI(installation);
      });
    };

    return {
      confirmDeleteInstallation,
      currentUser: useCurrentUserStore().currentUser,
    };
  },
  data() {
    return {
      localInstallation: null,
      jobListKey: 0,
      moveToLocationId: null,
      deleteEmptyLocation: false,
      serverErrors: [],
      serverErrorsContact: [],
    };
  },
  computed: {
  },
  mounted() {
    this.localInstallation = JSON.parse(JSON.stringify(this.modelValue)); // deep clone
  },
  methods: {
    activateViaAPI() {
      const that = this;
      axios.put(`/installations/${this.localInstallation.id}/activate`).then(() => {
        that.localInstallation.passive = false;
        Flash.info('Anlage wurde reaktiviert');
        this.$emit('changed', this.localInstallation);
      }).catch(() => {
        Flash.error('Anlage konnte nicht aktiviert werden.');
      });
    },
    deactivateViaAPI() {
      const that = this;
      axios.put(`/installations/${this.localInstallation.id}/deactivate`).then(() => {
        that.localInstallation.passive = true;
        Flash.info('Anlage wurde passiv gesetzt');
        this.$emit('changed', this.localInstallation);
      }).catch(() => {
        Flash.error('Anlage konnte nicht deaktiviert werden.');
      });
    },
    moveToViaAPI() {
      axios.post(`/installations/${this.localInstallation.id}/move`, {
        location_id: this.moveToLocationId,
        delete_empty_location: this.deleteEmptyLocation,
      }).then(() => {
        Flash.info('Anlage wurde verschoben');
        window.location.reload();
      }).catch(() => {
        Flash.error('Anlage konnte nicht verschoben werden');
      });
    },
    updateInstallationViaAPI() {
      this.serverErrors = [];
      const that = this;
      axios.put(`/installations/${this.localInstallation.id}`, {
        name: this.localInstallation.name,
        number: this.localInstallation.number,
        note: this.localInstallation.note,
        assigned_user_id: this.localInstallation.assigned_user_id,
      }).then(() => {
        Flash.info('Anlage wurde aktualisiert');
        this.$emit('changed', this.localInstallation);
      }).catch((error) => {
        that.serverErrors = error.response.data.installations;
      });
    },
    updateCalendricRootViaAPI() {
      this.serverErrors = [];
      axios.put(`/installations/${this.localInstallation.id}`, {
        calendric_interval_root_at: this.localInstallation.calendric_interval_root_at,
      }).then(() => {
        Flash.info('Startdatum wurde aktualisiert');
      }).catch(() => {
        Flash.error('Startdatum konnte nicht aktualisiert werden');
      });
    },
  },
};
</script>
