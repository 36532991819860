<template>
  <span>
    <!-- note: OnClickOutside renders a <div>, we dont want it to do line break -->
    <OnClickOutside style="display: inline-block;" @trigger="onClickOutside">
      <div style=" display: inline-block; position: relative;">

        <template v-if="text">
          <button
            :class="allClasses"
            :disabled="disabled"
            type="button"
            @click.prevent="show = !show"
          >
            {{ text }}
            <i class="fas fa-caret-down" style="margin-left: .3rem;" />
          </button>
        </template>

        <template v-if="icon">
          <button
            :class="allClasses"
            :disabled="disabled"
            type="button"
            @click.prevent="show = !show"
          >
            <i :class="icon" />
            <i class="fas fa-caret-down" style="margin-left: .3rem;" />
          </button>
        </template>

        <template v-if="isMore">
          <button
            :class="allClasses"
            :disabled="disabled"
            type="button"
            vv-tippy="{ placement: 'top', duration: 100, delay: [500,null], arrow: true }"
            title="mehr Optionen"
            @click.prevent="show = !show"
          >
            <i class="fas fa-ellipsis-h" />
          </button>
        </template>

        <div
          v-if="show"
          class="dropdown-menu-wrapper"
          :class="wrapperClasses"
          @click="show = false"
        >
          <ul class="dropdown-menu-list">
            <slot />
          </ul>
        </div>
      </div>
    </OnClickOutside>
  </span>
</template>

<script>
import { OnClickOutside } from '@vueuse/components';

export default {
  name: 'DropdownMenu',
  components: {
    OnClickOutside,
  },
  props: {
    text: {
      type: String,
      default: null,
    },
    classes: {
      type: String,
      default: 'fx-btn-skeleton',
    },
    wrapperClasses: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    allClasses() {
      return `dropdown-menu ${this.classes}`;
    },
    isMore() {
      return (this.text == null && this.icon == null);
    },
  },
  methods: {
    onClickOutside() {
      if (this.show) {
        this.show = false;
      }
    },
  },
};
</script>
