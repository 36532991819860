<template>
  <div>
    <a name="import-status" />
    <div class="fx-card">
      <div class="fx-card-content">
        <div class="float-right secondary" style="font-size: .825rem;">
          Letzte Aktualisierung: {{ lastUpdatedLabel }} Uhr
        </div>
        <h2 class="fx">
          {{ title }} ({{ modelValue.label }})
        </h2>
      </div>
      <table v-if="localImports.length > 0" class="material">
        <tr class="material__title">
          <th style="width: 180px;">
            Erstellt
          </th>
          <th style="width: 40px;">
&nbsp;
          </th>
          <th style="width: 240px;">
            Status
          </th>
          <th>Dateiname</th>
          <th style="width: 200px;">
&nbsp;
          </th>
        </tr>
        <tr v-for="(imprt, index) in localImports" :key="index" style="border-top: 1px solid #eee;">
          <template v-if="imprt.status == 'mapped'">
            <td class="active">
              {{ createdDateTime(imprt) }}<br>{{ imprt.username }}
            </td>
            <td style="background-color: #9CCC65; color: white; padding: 2px 2px 2px 2px;">
              <i class="fas fa-circle-notch fa-spin fa-fw fa-2x" />
            </td>
            <td class="active">
              Import läuft ...
            </td>
            <td class="active">
              <span :title="imprt.filename">{{ truncated(imprt.filename,
                                                         40) }}</span>
            </td>
            <td class="active">
&nbsp;
            </td>
          </template>
          <template v-else>
            <td>{{ createdDateTime(imprt) }}<br>{{ imprt.username }}</td>
            <td>
              <template v-if="imprt.status=='success'">
                <i class="fas fa-check-circle fa-fw fa-fw color-success" />
              </template>
              <template v-if="imprt.status=='failure'">
                <i class="fas fa-exclamation-triangle fa-fw fa-fw color-warn" />
              </template>
            </td>
            <td>
              <template v-if="imprt.status=='uploaded'">
                in Vorbereitung
              </template>
              <template v-else>
                <div>{{ imprt.total_rows }} Zeilen verarbeitet</div>
                <div v-if="imprt.import_mode != 'update'">
                  {{ imprt.created_records }} {{ modelValue.label }} hinzugefügt
                </div>
                <div v-if="imprt.import_mode != 'insert'">
                  {{ imprt.updated_records }} {{ modelValue.label }} aktualisiert
                </div>
                <div v-if="imprt.status=='failure'" style="color: rgb(247, 141, 0);">
                  <strong>{{ imprt.invalid_rows }} Zeilen mit Fehlern</strong>
                </div>
              </template>
            </td>
            <td>
              <span :title="imprt.filename">{{ truncated(imprt.filename, 40) }}<br><span
                class="secondary"
              >{{ imprt.import_mode }}</span></span>
            </td>

            <td class="text-right">
              <template v-if="imprt.status=='uploaded'">
                <a :href="mappingUrl(imprt)" class="fx-btn-skeleton">Felder zuordnen</a>
              </template>
              <template v-if="imprt.status=='success'">
                <a v-if="imprt.installation_type_id" :href="installationTypeUrl(imprt)"> Zur Wartungsanwendung</a>
                <a v-if="imprt.installation_id" :href="installationUrl(imprt)">Zur Anlage</a>
                <a v-if="imprt.job_id" :href="jobUrl(imprt)">Zum Auftrag</a>
                <a v-if="isType('articles')" :href="articlesPath()">Zu den Artikeln</a>
                <a v-if="isType('customers')" :href="customersPath()">Zu den Kunden</a>
                <a v-if="isType('locations')" :href="locationsPath()">Zu den Objekten</a>
                <a v-if="isType('installations')" :href="locationsPath()">Zu den Anlagen</a>
                <a v-if="isType('jobs')" :href="jobsPath()">Zu den Aufträgen</a>
                <a v-if="isType('contacts')" href="/contacts">Zu den Kontakten</a>
              </template>
              <template v-if="imprt.status=='failure'">
                <a class="fx-btn-skeleton" :href="resultsUrl(imprt)">Fehler ansehen</a>
              </template>
            </td>
          </template>
        </tr>
      </table>
      <div v-if="localImports.length == 0" class="fx-card-content secondary">
        <div v-if="initialized">
          Keine Importe
        </div>
        <div v-if="!initialized">
          loading ...
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import Flash from 'flash';

import FoxtagPaths from 'mixins/foxtag_paths';

export default {
  name: 'ImportsStatus',
  mixins: [FoxtagPaths],
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: 'Letzte Importe',
    },
  },
  data() {
    return {
      localImports: [],
      pollingTimeout: null,
      lastUpdated: null,
      initialized: false,
    };
  },
  computed: {
    lastUpdatedLabel() {
      if (this.lastUpdated == null) {
        return '';
      }
      return this.lastUpdated.format('HH:mm:ss');
    },
  },
  watch: {
    modelValue: {
      handler() {
        this.localImports = [];
        this.loadImports();
      },
    },
  },
  mounted() {
    this.loadImports();
  },
  methods: {
    needsPolling() {
      for (let i = 0; i < this.localImports.length; i += 1) {
        if (this.localImports[i].status === 'mapped') {
          return true;
        }
      }
      return false;
    },
    isType(importType) {
      return (this.modelValue.type === importType);
    },
    mappingUrl(imprt) {
      return `/imports/${this.modelValue.type}/${imprt.id}/mapping?`
       + `installation_type=${imprt.installation_type_id}`
       + `&installation=${imprt.installation_id}`
       + `&job=${imprt.job_id}`;
    },
    resultsUrl(imprt) {
      return `/imports/${this.modelValue.type}/${imprt.id}?`
      + `installation_type=${imprt.installation_type_id}`
      + `&installation=${imprt.installation_id}`
      + `&job=${imprt.job_id}`;
    },
    installationTypeUrl(imprt) {
      return `/installation_types/${imprt.installation_type_id}#tab-thing-types`;
    },
    installationUrl(imprt) {
      return `/installations/${imprt.installation_id}#tab-things`;
    },
    jobUrl(imprt) {
      return `/jobs/${imprt.job_id}#tab-articles`;
    },
    truncated(text, limit) {
      if (text == null) {
        return '';
      }

      if (text.length > limit) {
        return `${text.slice(0, limit)}...`;
      }
      return text;
    },
    processedRowsCount(imprt) {
      return imprt.updated_rows + imprt.created_rows;
    },
    createdDateTime(imprt) {
      if (!imprt.created_at) { return null; }
      return `${moment(imprt.created_at).format('DD.MM.YYYY HH:mm')} Uhr`;
    },
    loadImports() {
      const that = this;
      axios.get(`/imports/${this.modelValue.type}.json`, {
      }).then((response) => {
        that.initialized = true;
        that.localImports = response.data.imports;
        that.lastUpdated = moment();
        if (that.needsPolling()) {
          that.pollForImports();
        }
      }).catch(() => {
        that.loading = false;
        Flash.error('Fehler beim Laden der Import-Historie');
      });
    },
    pollForImports() {
      this.pollingTimeout = setTimeout(() => {
        this.loadImports();
      }, 5000);
    },
  },
};
</script>
