<template>
  <div>
    <div v-if="showTopAddButton" style="text-align: right;">
      <button class="fx-btn-primary" @click="openAddContactModal">
        Kontakt hinzufügen
      </button>
    </div>
    <h3 v-if="title && (localContacts.length > 0 || showEmptyState)" class="fx" style="margin-top: 2rem;">
      {{ title }}
    </h3>

    <EmptyState v-if="localContacts.length == 0 && showEmptyState" :title="emptyStateTitle" :hint="emptyStateHint" />

    <div :class="listClasses">
      <ContactCard
        v-for="contact in localContacts"
        :key="contact.id"
        :contact="contact"
        :remove-customer-id="removeCustomerId"
        :remove-installation-id="removeInstallationId"
        :add-customer-id="addCustomerId"
        @remove-customer="removeContactViaAPI"
        @remove-installation="removeContactViaAPI"
        @add-customer="addContactToCustomerViaAPI"
        @open-edit-contact="openEditContactModal"
        @open-confirm-delete="confirmDeleteContact"
        @open-references="openContactReferencesModal"
      />
    </div>

    <button v-if="showBottomAddButton" class="fx-btn-primary mt-4" @click="openAddContactModal">
      {{ addButtonText() }}
    </button>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';
import confirmViaModal from 'helpers/confirm_modal';
import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';
import { ref, onMounted } from 'vue';

import EntityList from 'helpers/entity_list';
import EmptyState from 'components/empty_state.vue';
import ContactCard from 'apps/contacts/card.vue';
import EditContactModal from 'apps/contacts/edit_modal.vue';
import AddCreateContactModal from 'apps/contacts/add_create_modal.vue';
import ContactReferencesModal from 'apps/contacts/references_modal.vue';

export default {
  name: 'EmbeddedContactsList',
  components: {
    ContactCard,
    EmptyState,
  },
  props: {
    /* alternative to contacts url */
    initContacts: {
      type: Array,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    emptyStateHint: {
      type: String,
      default: null,
    },
    emptyStateTitle: {
      type: String,
      default: 'Keine Kontakte',
    },
    showEmptyState: {
      type: Boolean,
      default: true,
    },
    showTopAddButton: {
      type: Boolean,
      default: true,
    },
    showBottomAddButton: {
      type: Boolean,
      default: false,
    },
    removeCustomerId: {
      type: String,
      default: null,
    },
    addCustomerId: {
      type: String,
      default: null,
    },
    removeInstallationId: {
      type: String,
      default: null,
    },
    addInstallationId: {
      type: String,
      default: null,
    },
    /* alternative to passing a list with initContacts */
    contactsUrl: {
      type: String,
      default: null,
    },
    listClasses: {
      type: String,
      default: 'grid-4-col',
    },
    /* used to determine the relation_type of contacts (contact or report_recipient) */
    relationType: {
      type: String,
      default: 'contact',
    },
  },
  emits: ['refresh-sibling'],
  setup(props, { emit }) {
    const localContacts = new EntityList();
    const serverErrors = ref([]);

    const contactIds = (contacts) => contacts.map((contact) => contact.id);

    const loadContacts = () => {
      axios.get(props.contactsUrl).then((response) => {
        localContacts.value = response.data.contacts;
      }).catch(() => {
        Flash.error('Kontakte konnte nicht geladen werden');
      });
    };

    const deleteContactViaApi = (contact) => {
      serverErrors.value = [];
      axios.delete(`/contacts/${contact.id}`).then(() => {
        localContacts.remove(contact);
        Flash.info('Kontakt wurde gelöscht');
      }).catch((error) => {
        serverErrors.value = error.response.data.contacts;
      });
    };

    const removeContactViaAPI = (contact) => {
      serverErrors.value = [];
      axios.post(`/contacts/${contact.id}/remove`, {
        customer_id: props.removeCustomerId, // only customer or installation_id is set, axios does not send null params
        installation_id: props.removeInstallationId, // only customer or installation_id is set, axios does not send null params
        relation_type: props.relationType,
      }).then(() => {
        localContacts.remove(contact);
        emit('refresh-sibling');
        Flash.info('Kontakt wurde entfernt');
      }).catch((error) => {
        serverErrors.value = error.response.data.contacts;
      });
    };

    const addContactToCustomerViaAPI = (contact) => {
      serverErrors.value = [];
      axios.post(`/contacts/${contact.id}/add`, {
        customer_id: props.addCustomerId,
      }).then(() => {
        localContacts.remove(contact);
        emit('refresh-sibling');
        Flash.info('Kontakt wurde verschoben');
      }).catch((error) => {
        serverErrors.value = error.response.data.contacts;
      });
    };

    const confirmDeleteContact = (contact) => {
      confirmViaModal({
        message: 'Der Kontakt wird von allen Anlagen und Kunden entfernt und gelöscht.',
        title: 'Kontakt löschen?',
      }).then(() => {
        deleteContactViaApi(contact);
      });
    };

    const openAddContactModal = () => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: AddCreateContactModal,
            attrs: {
              customerId: props.addCustomerId,
              installationId: props.addInstallationId,
              relationType: props.relationType,
              contactIdList: contactIds(localContacts.value),

              onClose() {
                modalInstance.close();
              },
              onAdded(xContact) {
                modalInstance.close();
                localContacts.append(xContact);
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openEditContactModal = (contact) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: EditContactModal,
            attrs: {
              contact,

              onClose() {
                modalInstance.close();
              },
              onUpdated(xContact) {
                modalInstance.close();
                localContacts.update(xContact);
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openContactReferencesModal = (contact) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: ContactReferencesModal,
            attrs: {
              contact,

              onClose() {
                modalInstance.close();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const addButtonText = () => {
      if (props.relationType === 'contact') {
        return 'Kontakt hinzufügen';
      }
      return 'Empfänger hinzufügen';
    };

    onMounted(() => {
      if (props.initContacts != null) {
        localContacts.value = props.initContacts;
      } else if (props.contactsUrl) {
        // load from url if contacts url is giving as alternative to init contacts
        loadContacts();
      }
    });

    return {
      addButtonText,
      loadContacts,
      localContacts: localContacts.reactiveList,
      serverErrors,
      confirmDeleteContact,
      openAddContactModal,
      openEditContactModal,
      openContactReferencesModal,
      removeContactViaAPI,
      addContactToCustomerViaAPI,
    };
  },
};
</script>
