<template>
  <div>
    <div class="fx-grid">
      <div class="fx-col">
        <EmptyState
          v-if="showEmptyState"
          title="Keine Prüfungen"
          hint="Es wurden keine Prüfungen dokumentiert und keine allgemeinen Checklisten ausgefüllt."
        />

        <div v-for="checklist in localChecklists" :key="checklist.id">
          <div class="fx-card">
            <DataTable :data="checklist.data" />
          </div>
        </div>

        <DataAndChecks
          :job-id="modelValue.id"
          :group-label="groupLabel"
          :group-separator="groupSeparator"
          :thing-label="thingLabel"
          @count="updateChecksCount"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import axios from 'axios';

import Flash from 'flash/index';
import FoxtagPaths from 'mixins/foxtag_paths';
import ThingPresenter from 'mixins/thing_presenter';

import EmptyState from 'components/empty_state.vue';
import DataTable from 'components/partials/data_table.vue';

import DataAndChecks from 'apps/jobs/data_and_checks/list.vue';

export default {
  name: 'TabChecks',
  components: {
    EmptyState,
    DataAndChecks,
    DataTable,
  },
  mixins: [FoxtagPaths, ThingPresenter],
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    thingLabel: {
      type: String,
      default: 'Komponente',
    },
    groupLabel: {
      type: String,
      default: 'Gruppe',
    },
    groupSeparator: {
      type: String,
      default: '/',
    },
  },
  data() {
    return {
      localChecklists: [],
      localLifecycleEvents: [],
      checklistsLoaded: false,
      lifecycleEventsLoaded: false,
      checksCount: 0,
    };
  },
  computed: {
    showEmptyState() {
      return (this.checksCount === 0) && (this.checklistsLoaded && !this.localChecklists.length);
    },
  },
  mounted() {
    this.loadChecklists();
  },
  methods: {
    updateChecksCount(count) {
      this.checksCount = count;
    },
    loadChecklists() {
      axios.get(`/jobs/${this.modelValue.id}/checklists.json`).then((response) => {
        this.localChecklists = response.data;
        this.checklistsLoaded = true;
      }).catch(() => {
        Flash.error('Fehler beim Laden der Daten');
      });
    },
  },
};
</script>
