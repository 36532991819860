<template>
  <div>
    <NavBreadcrumps path="administration/integrations/event-logs" />

    <h1 class="fx">
      Event Logs
    </h1>
    Shows latest events received from system integrations.

    <div class="fx-control-bar mb-4">
      <div class="fx-control-bar-buttons">
        <button
          v-tippy="{ placement: 'top', duration: 100, arrow: true }"
          class="fx-btn-secondary"
          title="Reload events"
          @click="loadEvents()"
        >
          <i class="fas fa-sync-alt" />
        </button>
      </div>
    </div>
    <div class="fx-card">
      <table class="material">
        <colgroup>
          <col style="width: 100px;">
          <col style="width: 120px;">
          <col style="width: 120px;">
          <col>
          <col style="width: 150px;">
          <col style="width: 150px;">
          <col style="width: 120px;">
        </colgroup>
        <tr>
          <th>EventID</th>
          <th>Created</th>
          <th>Reference</th>
          <th>Message</th>
          <th>User</th>
          <th>Channel / Handler</th>
          <th>&nbsp;</th>
        </tr>
        <tr v-for="event in localEvents" :key="event.id">
          <td :title="event.id">
            <a @click="openRawEventModal(event)">{{ short_uuid(event.id) }}</a>
          </td>
          <td><b>{{ formatDate(event.created_at, 'DD.MM.YYYY') }}</b><br>{{ formatDate(event.created_at, 'HH:mm:ss') }}</td>
          <td>
            <span :title="event.data.reference">{{ short_uuid(event.data.reference) }}</span>
          </td>
          <td>
            <div style="font-weight: bold;">
              {{ event.data.event_code_label }}
            </div>
            <div v-if="event.noop">
              <span class="fx-status warning">NOOP</span>
            </div>
            {{ event.data.message }}
          </td>
          <td>
            {{ event.user.name }}<br>
            {{ event.metadata.api_key_prefix }}
            <br>
          </td>
          <td>
            <span class="secondary"><i :class="channelIconClasses(event.metadata.channel)" /></span>&nbsp;{{
              event.metadata.channel }}
            <div>{{ event.data.handler }}</div>
          </td>
          <td><a class="fx-btn-skeleton" @click="openRawEventModal(event)">details</a></td>
        </tr>
      </table>
    </div>

    <Pagination v-model="pagination" @navigate="loadPage" />
    <br>
    <br>
  </div>
</template>

<script>
import axios from 'axios';
import { ref } from 'vue';
import Flash from 'flash/index';
import NavBreadcrumps from 'components/nav_breadcrumps.vue';
import RawEventModal from 'components/raw_event_modal.vue';
import { DateFormatter } from 'mixins/formatters';
import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';

import Pagination from 'components/pagination.vue';

export default {
  name: 'OrganizationEvents',
  components: {
    NavBreadcrumps,
    Pagination,
  },
  mixins: [DateFormatter],
  props: {
    organizationId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const localEvents = ref([]);
    const pagination = ref({
      page: 1,
      pageCount: 1,
      totalCount: 0,
      limit: 25,
    });

    const load = () => {
      axios.get(`/organizations/${props.organizationId}/events.json`, {
        params: {
          page: pagination.value.page,
          limit: pagination.value.limit,
        },
      }).then((response) => {
        localEvents.value = response.data.events;
        pagination.value.page = response.data.meta.current_page;
        pagination.value.pageCount = response.data.meta.total_pages;
        pagination.value.totalCount = response.data.meta.total_count;
      }).catch((err) => {
        if (err.message !== 'Request aborted') {
          Flash.error(err.message);
        }
      });
    };

    const loadPage = (changedPagination) => {
      pagination.value = { ...changedPagination };
      load();
    };

    const openRawEventModal = (rawEvent) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: RawEventModal,
            attrs: {
              rawEvent,
              onClose() {
                modalInstance.close();
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    return {
      pagination,
      localEvents,
      loadPage,
      openRawEventModal,
    };
  },
  mounted() {
    // this.loadEvents();
  },
  methods: {
    short_uuid(uuid) {
      if (uuid == null) { return ''; }

      return `${uuid.toString().substring(0, 5)}...`;
    },
    eventType(event) {
      if (event.type == null) { return ''; }
      // strip "Events::" prefix
      return event.type.substring(8);
    },
    loadEvents() {
      this.localEvents = [];
      this.loading = true;

      axios.get(`/organizations/${this.organizationId}/events.json`, {
        params: {
          page: this.pagination.page,
          limit: this.pagination.limit,
        },
      }).then((response) => {
        this.localEvents = response.data.events;
      }).catch();
    },
    channelIconClasses(channel) {
      switch (channel) {
        case 'web':
          return 'fas fa-desktop';
        case 'private_api':
          return 'fas fa-mobile-alt';
        case 'public_api':
          return 'fas fa-brackets-curly';
        case 'import':
          return 'fas fa-file-excel';
        default:
          return 'fas fa-question';
      }
    },
  },
};
</script>
