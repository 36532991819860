<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <h2 class="fx">
      Typen zusammenführen
    </h2>
    <hr>
    <p>
      Diese Aktion <strong>löscht</strong> den ausgewählten Typ <strong>{{ localThingType.name }} {{ localThingType.manufacturer }} {{ localThingType.model }}</strong> und kann nicht rückgängig gemacht werden!
    </p>
    <p>
      Bitte wählen Sie einen neuen Typ für <strong>{{ localThingType.things_count }} Komponente(n)</strong>, die momentan {{ localThingType.name }} {{ localThingType.manufacturer }} {{ localThingType.model }} zugewiesen sind.
    </p>
    <hr>
    <ServerErrors v-model="serverErrors" title="Typ konnte nicht gespeichert werden" margin-bottom="1rem" />
    <form>
      <div class="fx-form-row">
        <div class="fx-form-label">
          <label class="inline mandatory">Neuer Typ</label>
        </div>
        <div class="fx-form-content">
          <DBBasedSelect
            v-model="destinationThingTypeId"
            icon-id="thing_type"
            i18n-base-key="thing_types"
            :default-label="$t('comp.select.no-selection')"
            :load-options-url="`/thing_types/for_select/?installation_type_id=${installationTypeId}&category=${localThingType.category}`"
            @changed="(selectedThingTypeId) => {destinationThingTypeId = selectedThingTypeId;}"
          />
          <div class="form-field-hint">
            Es stehen Typen der selben Kategorie zur Verfügung
          </div>
        </div>
      </div>
    </form>
    <hr>
    <input
      type="submit"
      value="Typen zusammenführen"
      :disabled="noDestinationThingTypeChosen"
      class="fx-btn-primary fx-btn-red float-right"
      style="margin-bottom: 0.5rem;"
      @click.prevent="mergeViaAPI"
    >
    <div class="clearfix" />
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';

import DBBasedSelect from 'components/select/db_based_select.vue';
import ServerErrors from 'components/partials/server_errors.vue';

export default {
  name: 'MergeThingTypeModal',
  components: {
    ServerErrors,
    DBBasedSelect,
  },
  props: {
    installationTypeId: {
      type: String,
      required: true,
    },
    thingType: {
      type: Object,
      required: true,
    },
  },
  emits: ['started', 'close'],
  data() {
    return {
      localThingType: { ...this.thingType },
      serverErrors: [],
      destinationThingTypeId: null,
    };
  },
  computed: {
    noDestinationThingTypeChosen() {
      return this.destinationThingTypeId == null;
    },
  },
  methods: {
    mergeViaAPI() {
      if (this.noDestinationThingTypeChosen) {
        return;
      }

      const that = this;
      that.serverErrors = [];

      axios.post(`/thing_types/${this.localThingType.id}/merge.json`, {
        destination_thing_type_id: this.destinationThingTypeId,
      }).then((response) => {
        this.$emit('started', response.data.sidekiq_job_id);
        this.$emit('close');
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.events;
        } else {
          Flash.error('Typ konnte nicht zusammengeführt werden');
        }
      });
    },
  },
};
</script>
