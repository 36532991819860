<template>
  <div>
    <div class="fx-form-row" style="margin-bottom: .5rem">
      <div class="fx-form-label">
        <label class="inline">Status</label>
      </div>
      <div class="fx-form-content" style="font-size: .9rem;padding-top: .4rem;">
        <strong>{{ $t(`activerecord.attributes.thing.statuses.${status}`) }}</strong>
      </div>
    </div>

    <div v-if="lastDeployment && lastDeployment.id">
      <div class="fx-form-row" style="margin-bottom: .5rem">
        <div class="fx-form-label">
          <label class="inline">Außerbetriebnahme am</label>
        </div>
        <div class="fx-form-text-content">
          <span v-if="lastDeployment.removed_at">
            {{ formatDate(lastDeployment.removed_at, 'DD.MM.YYYY') }}
          </span>
          <span v-else class="secondary">Unbekannt</span>
        </div>
      </div>

      <div class="fx-form-row" style="margin-bottom: .5rem">
        <div class="fx-form-label">
          <label class="inline">Grund</label>
        </div>
        <div class="fx-form-text-content">
          <span v-if="lastDeployment.removed_reason">{{ lastDeployment.removed_reason }}</span>
          <span v-else class="secondary">Keine Angabe</span>
        </div>
      </div>

      <hr>

      <div class="fx-form-row" style="margin-bottom: .5rem">
        <div class="fx-form-label">
          <label class="inline">Letzte Anlage</label>
        </div>
        <div class="fx-form-text-content">
          <Labels :labels="lastDeployment.installation.labels" />
          <Installation :installation="lastDeployment.installation" :link="true" />
        </div>
      </div>

      <div class="fx-form-row" style="margin-bottom: .5rem">
        <div class="fx-form-label">
          <label class="inline">Gruppe</label>
        </div>
        <div class="fx-form-text-content">
          <span v-if="lastDeployment.group.number > -1">{{ lastDeployment.group.number }}</span> {{ lastDeployment.group.name }}
        </div>
      </div>

      <div class="fx-form-row" style="margin-bottom: .5rem">
        <div class="fx-form-label">
          <label class="inline">Nummer</label>
        </div>
        <div class="fx-form-text-content">
          {{ lastDeployment.number }}<span v-if="lastDeployment.sub_number">.{{ lastDeployment.sub_number }}</span>
        </div>
      </div>

      <div class="fx-form-row" style="margin-bottom: .5rem">
        <div class="fx-form-label">
          <label class="inline">Standort</label>
        </div>
        <div class="fx-form-text-content">
          {{ lastDeployment.position }}
        </div>
      </div>

      <div class="fx-form-row" style="margin-bottom: .5rem">
        <div class="fx-form-label">
          <label class="inline">Inbetriebnahme am</label>
        </div>
        <div class="fx-form-text-content">
          <span v-if="lastDeployment.deployed_at">
            {{ formatDate(lastDeployment.deployed_at, 'DD.MM.YYYY') }}
          </span>
          <span v-else class="secondary">Unbekannt</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';

import { DateFormatter } from 'mixins/formatters';
import Installation from 'components/partials/installation.vue';
import Labels from 'components/partials/labels.vue';

export default {
  name: 'LastRemoval',
  components: {
    Installation,
    Labels,
  },
  mixins: [DateFormatter],
  props: {
    thingId: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      lastDeployment: null,
    };
  },
  mounted() {
    this.loadLastDeployment();
  },
  methods: {
    loadLastDeployment() {
    },
    loadLastDeployment() {
      if (this.thingId == null) { return; }

      const that = this;
      axios.get(`/things/${this.thingId}/last_removal.json?`)
        .then((response) => {
          that.lastDeployment = response.data.deployment;
        }).catch(() => {
          // Flash.error('Anlage konnte nicht geladen werden');
        });
    },
  },
};
</script>

<style>
</style>
