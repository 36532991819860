<template>
  <div>
    <div v-if="!loaded || localEvents.length" class="fx-card">
      <div class="fx-card-content">
        <h2 class="fx">
          {{ title }}
        </h2>
      </div>
      <div class="fx-list-row">
        <div class="fx-10px-col">
          &nbsp;
        </div>
        <div class="fx-100px-col">
          <strong>Zeitpunkt</strong>
        </div>
        <div class="fx-150px-col">
          <strong>{{ groupLabel }}</strong>
        </div>
        <div class="fx-100px-col">
          <strong>{{ thingLabel }}</strong>
        </div>
        <div class="fx-220px-col">
          <strong>Typ</strong>
        </div>
        <div class="fx-300px-col">
          <strong>Details</strong>
        </div>
        <div class="fx-10px-col">
          &nbsp;
        </div>
        <div class="fx-100px-col">
          <strong>Ergebnis</strong>
        </div>
      </div>
      <CheckEvent
        v-for="checkEvent in localEvents"
        :key="checkEvent.id"
        :check-event="checkEvent"
        :group-separator="groupSeparator"
      />
    </div>
    <Pagination v-model="pagination" @navigate="loadPage" />
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { ref, watch } from 'vue';
import Flash from 'flash/index';
import Pagination from 'components/pagination.vue';
import CheckEvent from './check_event.vue';

export default {
  name: 'DataAndChecksList',
  components: {
    CheckEvent,
    Pagination,
  },
  props: {
    jobId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: 'Prüfungen',
    },
    thingLabel: {
      type: String,
      default: 'Komponente',
    },
    groupLabel: {
      type: String,
      default: 'Gruppe',
    },
    groupSeparator: {
      type: String,
      default: '/',
    },
  },
  emits: ['count'],
  setup(props, { emit }) {
    const loaded = ref(false); // TODO: look for a better way to handle this
    const localEvents = ref([]);
    const filter = ref({
      query: null,
    });
    const pagination = ref({
      page: 1,
      pageCount: 1,
      totalCount: 0,
      limit: 25,
    });

    const load = () => {
      axios.get(`/jobs/${props.jobId}/check_events.json`, {
        params: {
          query: filter.value.query,
          page: pagination.value.page,
          limit: pagination.value.limit,
        },
      }).then((response) => {
        loaded.value = true;

        localEvents.value = response.data.check_events;
        pagination.value.page = response.data.meta.current_page;
        pagination.value.pageCount = response.data.meta.total_pages;
        pagination.value.totalCount = response.data.meta.total_count;

        emit('count', pagination.value.totalCount); // this is used to show empty state in parent component, maybe uncool
      }).catch((err) => {
        if (err.message !== 'Request aborted') {
          Flash.error(err.message);
        }
      });
    };

    const loadPage = (changedPagination) => {
      pagination.value = { ...changedPagination };
      load();
    };

    const search = () => {
      localEvents.value = [];
      pagination.value.page = 1;
      load();
    };

    const resetSearch = () => {
      filter.value = {
        query: null,
      };
    };

    const debouncedSearch = _.debounce(() => {
      search();
    }, 250);

    watch(filter, () => {
      debouncedSearch();
    }, { deep: true });

    return {
      localEvents,
      filter,
      pagination,
      loaded,
      load,
      loadPage,
      search,
      resetSearch,
    };
  },
};
</script>
